export function mountainBike (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      className={outerLayer}
      viewBox="0 0 1200 1200"
      version="1.1"
      x="0px"
      y="0px"
    >
      <g fill="#273906">
        <path className={className} d="m953.83 533.65c-136.13 0-246.61 112.84-246.61 252.5 0 139.69 110.48 252.61 246.61 252.61 135.76 0 246.2-112.95 246.2-252.61 0.035156-139.63-110.42-252.5-246.2-252.5m-0.10938 436.29c-98.93 0-179.21-82.199-179.21-183.74 0-101.44 80.27-183.62 179.21-183.62 98.746 0 179.22 82.188 179.22 183.62-0.058594 101.55-80.504 183.74-179.22 183.74"/>
        <path className={className} d="m319.07 245.09c-135.68 0-246.17 112.92-246.17 252.5 0 139.63 110.48 252.62 246.17 252.62 136.26 0 246.61-112.99 246.61-252.62 0-139.59-110.38-252.5-246.61-252.5m0.074219 436.28c-98.785 0-179.07-82.246-179-183.82 0-101.33 80.219-183.64 179-183.64 98.988 0 179.36 82.262 179.36 183.64 0 101.52-80.352 183.82-179.36 183.82"/>
        <path className={className} d="m941.76 209.34c41.617 27.516 98.148 15.445 126.42-27.277 28.176-42.625 17.316-99.637-24.254-127.15-41.555-27.492-98.375-15.203-126.56 27.422-28.215 42.695-17.129 99.527 24.395 127.01"/>
        <path className={className} d="m1033.9 459.95-114.39-134.43s-35.617-171.57-35.785-172.75c-3.1211-20.605-21.961-40.262-46.656-50.004-56.629-22.332-249.73-88.055-336.16-72.359-27.238 4.9453-91.906 40.848-50.629 114.96l171.73 256.84-33.984 247.7c-3.625 27.336 15.613 52.414 42.938 56.039s52.414-15.625 56.039-42.938l36.445-266.3c1.2852-9.625-0.25391-19.703-5.0625-28.969l-2.0391-3.9492-96.18-185.98c49.715 12.156 202.57 63.539 202.57 63.539l32.797 109.7c1.2969 4.2734 3.4805 8.3633 6.5742 11.988l120 141c12.168 14.305 33.637 16.043 47.941 3.8516 14.305-12.156 16.047-33.633 3.8516-47.938z"/>
        <path className={className} d="m1041 1194c-2.7852 0-5.6289-0.33594-8.4492-1.0664l-258-66c-4.7266-1.2227-9.1211-3.4062-12.898-6.457l-130.64-105.23-121.91-16.57c-5.0625-0.70703-9.8984-2.5078-14.172-5.3164l-234.1-154.62-157.91-20.008c-5.543-0.70703-10.824-2.7461-15.383-5.9766l-77.23-54.719c-15.324-10.859-18.949-32.102-8.1016-47.426 10.871-15.324 32.102-18.938 47.426-8.1016l70.273 49.789 157.33 19.922c5.1719 0.66016 10.129 2.4844 14.473 5.3516l234.22 154.73 123.61 16.824c6.1445 0.81641 11.93 3.3125 16.754 7.1992l132.25 106.52 250.84 64.176c18.203 4.668 29.184 23.184 24.527 41.375-3.918 15.363-17.754 25.598-32.922 25.598z"/>
      </g>
    </svg>
  );
}