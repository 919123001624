import merge from "lodash/merge";
import {
  RECEIVE_USER_INCOMING_NOTIFICATION,
  RECEIVE_USER_RECENT_BLOCK_UPDATE,
  RECEIVE_DISPLAY_NOTIFICATION_BADGE,
} from "../../constants/index";
const FriendsReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_USER_INCOMING_NOTIFICATION:
      newState["userIncomingNotifications"] = action.data.userNotification;
      return newState;
    case RECEIVE_USER_RECENT_BLOCK_UPDATE:
      newState["userRecentBlockUpdate"] = action.data.userRecentBlockUpdate;
      return newState;
    case RECEIVE_DISPLAY_NOTIFICATION_BADGE:
      newState["notificationToDisplay"] = action.data;
      return newState;
    default:
      return state;
  }
};

export default FriendsReducer;
