
export const svgFolder = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      height="100px"
      width="100px"
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path
        className={className}
        d="M94.6,31.8c-0.4-0.5-1-0.8-1.6-0.8H81V19c0-1.1-0.9-2-2-2H39.7l-7.5-5.6C31.8,11.1,31.4,11,31,11H7c-1.1,0-2,0.9-2,2v74  c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0  c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1C5.9,88.6,6,88.7,6,88.7c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1  c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0h72c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1,0,0.1-0.1  c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0  c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0-0.1s0-0.1,0.1-0.2l14-54C95.1,32.9,95,32.3,94.6,31.8z M9,15h21.3l7.5,5.6  c0.4,0.3,0.8,0.4,1.2,0.4h38v10H21c-0.9,0-1.7,0.6-1.9,1.5L9,71.3V15z M77.5,85H9.6l13-50h67.9L77.5,85z"
      ></path>
    </svg>
  );
};