import React from 'react';
import { SliderPicker, SwatchesPicker } from 'react-color';
import { SectionSubTitle } from '../../../common/section-title';

const ColorPicker = ({ uiColor, setUiColor }) => {
  return (
    <>
      <SectionSubTitle>COLOR</SectionSubTitle>
      <SwatchesPicker
        color={uiColor || '#ffffff'}
        name="ui_color"
        width="80%"
        height="295px"
        id="block-adder-color-picker"
        style={{ width: '100%' }}
        className="block-adder-color-picker"
        onChange={(e) => {
          setUiColor(e.hex);
        }}
        onChangeComplete={(color) => {
          setUiColor(color.hex);
        }}
        colors={[
          ['#80aea8', '#2a8e7f', '#0e5144', '#08463a', '#364301'],
          ['#eae094', '#fcce13', '#cea502', '#de8d0e', '#be6700'],
          ['#62819e', '#51657a', '#3f566e', '#3c4a59', '#002249'],
          ['#f5eddf', '#fcedd6', '#e8d5b9', '#dfd5c1', '#fcedd6bb'],
          ['#d10366', '#c5a9ff', '#b9cd3e', '#9ddbf0', '#827700'],
        ]}
      />
    </>
  );
};

export default ColorPicker;
