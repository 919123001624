import React, { useState } from "react";
import { Input, DropDownSelect, TextArea } from "../../../modal_modules";
import TextBox from '../../../../common/text-box';

const UserFriendsAndGroups = ({
  handleOnChange,
  inputValues,
  accountRoleRid,
  handleAccountRoleSelect,
  accountRoles,
  renderEmailErrors,
}) => {
  return (
    <>
      <DropDownSelect
        values={accountRoleRid}
        placeholder="Role*"
        className="create_edit_block_modal_drop_down_select edit-account-explorer-dropdown-select edit-account-explorer-dropdown-select--left"
        onChange={handleAccountRoleSelect}
        options={accountRoles}
        parentClassName=" create_edit_block_modal_drop_down_select_parent edit-account-explorer-dropdown-parent"
        labelField={"account_role_name"}
        valueField={"account_role_rid"}
        clearable
      />
      <TextBox
        className="text-field"
        name="email"
        label="Email"
        variant="outlined"
        fullWidth
        onChange={handleOnChange}
        value={inputValues.email}
        size="small"
      />
      {/* <Input
        label="Email"
        name="email"
        onChangeHandler={handleOnChange}
        value={inputValues.email}
      /> */}
      {renderEmailErrors()}
      <TextBox
        className="text-field"
        name="first_name"
        label="First Name"
        variant="outlined"
        fullWidth
        onChange={handleOnChange}
        value={inputValues.first_name}
        size="small"
      />
      <TextBox
        className="text-field"
        name="last_name"
        label="Last Name"
        variant="outlined"
        fullWidth
        onChange={handleOnChange}
        value={inputValues.last_name}
        size="small"
      />
      {/* <Input
        label="First Name"
        name="first_name"
        onChangeHandler={handleOnChange}
        value={inputValues.first_name}
      /> */}
      {/* <Input
        label="Last Name"
        name="last_name"
        onChangeHandler={handleOnChange}
        value={inputValues.last_name}
      /> */}
      {/* <TextArea
        label="message"
        name="message"
        onChangeHandler={handleOnChange}
        value={inputValues.message}
      /> */}
    </>
  );
};

export default UserFriendsAndGroups;
