export function chill (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1554 810"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path
        className={className}
        fill={color}
        d="M1536.32 319.691C1531.57 314.128 1484.53 282.035 1433.61 244.587C1141.05 32.4399 1136.72 -45.2268 956.612 25.4399C954.216 26.3565 865.081 69.2679 865.081 69.2679C825.388 87.8412 808.138 135.137 826.764 174.883C861.665 249.476 973.457 227.795 978.097 145.445C978.097 145.445 1009.37 86.8719 1046.83 86.3626C1110.6 85.5449 1092.19 68.0973 1303.11 286.309C1266.83 283.606 1227.64 282.84 1186.57 282.84C1081.36 282.84 987.944 310.543 927.424 361.772C892.424 331.871 832.372 313.251 776.904 311.819C721.44 313.246 661.388 331.871 626.33 361.772C565.872 310.595 472.397 282.892 367.237 282.892C326.164 282.892 286.982 283.658 250.701 286.361C461.634 68.0946 443.208 85.5346 506.981 86.4146C544.382 86.8729 575.71 145.497 575.71 145.497C580.304 227.847 692.142 249.533 726.99 174.935C745.616 135.189 728.365 87.8932 688.673 69.3199C688.673 69.3199 599.537 26.4079 597.189 25.4919C417.082 -45.2268 412.736 32.4293 120.189 244.585C69.2675 282.033 22.2249 314.127 17.5329 319.689C-9.05112 351.22 -4.91512 398.367 26.6162 424.949C30.0798 427.803 33.7047 430.251 37.5329 432.397C37.3766 435.46 36.9183 438.314 36.9183 441.527C36.9183 586.994 219.732 809.754 402.132 809.754C584.638 809.754 697.598 656.887 697.598 511.381C697.598 486.479 692.958 463.521 684.895 442.397C702.089 424.438 743.317 411.886 776.89 410.355C810.463 411.887 851.687 424.438 868.931 442.397C860.817 463.522 856.228 486.427 856.228 511.381C856.228 656.901 969.192 809.754 1151.64 809.754C1334.09 809.754 1516.91 586.994 1516.91 441.527C1516.91 438.314 1516.4 435.454 1516.25 432.345C1520.02 430.204 1523.69 427.751 1527.16 424.897C1558.7 398.367 1562.83 351.22 1536.3 319.689L1536.32 319.691Z"
      />
    </svg>
  );
}