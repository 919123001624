export const svgTripThisYear = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="39.411"
      viewBox="0 0 45 39.411"
    >
      <g id="trip" transform="translate(-2.5 -8.4)">
        <g id="Group_471" data-name="Group 471" transform="translate(2.5 8.4)">
          <g id="Group_470" data-name="Group 470" transform="translate(0 0)">
            <path
              className={className}
              id="Path_339"
              d="M41.958,52.558l9.189-2.605c-.568-.616-1.326-1.516-2.179-2.653l-7.863,2.226a4.281,4.281,0,0,0-3.032,3.221,4.226,4.226,0,0,0,1.468,4.168l7.674,6.253a1,1,0,0,1,.332,1.232,1.04,1.04,0,0,1-1.042.711H31.063c-.9,1.279-1.753,2.321-2.463,3.174H46.505a4.27,4.27,0,0,0,2.7-7.579L41.532,54.5a1.067,1.067,0,0,1-.379-1.089A1.127,1.127,0,0,1,41.958,52.558Z"
              transform="translate(-16.237 -28.874)"
              fill={color}
            />
            <path
              className={className}
              id="Path_340"
              d="M11.026,39.8A8.551,8.551,0,0,0,2.5,48.326c0,7.342,7.863,15.347,8.195,15.679a.458.458,0,0,0,.663,0c.332-.332,8.195-8.384,8.195-15.679A8.521,8.521,0,0,0,11.026,39.8Zm0,12.837a4.311,4.311,0,1,1,4.311-4.311A4.326,4.326,0,0,1,11.026,52.637Z"
              transform="translate(-2.5 -24.926)"
              fill={color}
            />
            <path
              className={className}
              id="Path_341"
              d="M74.911,8.4A7,7,0,0,0,67.9,15.411c0,6.016,6.442,12.647,6.726,12.932a.348.348,0,0,0,.284.095.673.673,0,0,0,.284-.095c.284-.284,6.726-6.868,6.726-12.932A7,7,0,0,0,74.911,8.4Zm0,10.563a3.553,3.553,0,1,1,3.553-3.553A3.578,3.578,0,0,1,74.911,18.963Z"
              transform="translate(-36.921 -8.4)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};