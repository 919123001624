import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.min.css';

import './swiper-carousel.scss';

// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper';

const SwiperCarousel = ({ className, prev, next, children, ...rest }) => {
  const renderBullet = (index, className) => {
    return renderToString(
      <span
        key={index}
        className={className}
        onClick={(e) => e.stopPropagation()}
      ></span>,
    );
  };

  const handleButtonClicked = (event) => {
    event.stopPropagation();
  };

  return (
    <Box className={className}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        className="mySwiper"
        pagination={{
          clickable: true,
          renderBullet: (index, cName) => renderBullet(index, `${cName} test`),
        }}
        {...rest}
        onActiveIndexChange={(index) =>
          rest?.onActiveIndexChange && rest.onActiveIndexChange(index)
        }
      >
        {children.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>
      <div
        className={`swiper-button-prev ${prev}`}
        onClick={handleButtonClicked}
      ></div>
      <div
        className={`swiper-button-next ${next}`}
        onClick={handleButtonClicked}
      ></div>
    </Box>
  );
};

export const useResize = () => {
  const [deviceSize, setDeviceSize] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 450) {
        setDeviceSize('xs');
      } else if (width < 768) {
        setDeviceSize('sm');
      } else if (width >= 768 && width < 1024) {
        setDeviceSize('md');
      } else {
        setDeviceSize('lg');
      }
    };

    handleResize(); // Initial call to set the value on component mount

    // Event listener to update the value on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { deviceSize };
};

export default SwiperCarousel;
