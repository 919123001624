import React, { useState, useEffect } from 'react';
import FsLightbox from 'fslightbox-react';
import { Box } from '@mui/material';

const TripFsLightBox = ({ onClose, classes, images, activeIndex }) => {
  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    setToggler(true);
  }, []);

  return (
    <FsLightbox
      toggler={toggler}
      sources={images?.map((image) => (
        <>
          {image['media_type'] === 'video' ? (
            <video
              className="fslightboxs fslightbox-opacity-1"
              style={{ maxHeight: '80vh', maxWidth: '80vw' }}
              controls
              preload="none"
            >
              <source src={image['file_location'] ? image['file_location'] : image} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              className="fslightboxs fslightbox-opacity-1"
              style={{ maxHeight: '80vh', maxWidth: '80vw' }}
              src={image['file_location'] ? image['file_location'] : image}
              loading="lazy"
            />
          )}
          <Box
            className={classes.contentFontSize}
            sx={{ textAlign: 'center', color: 'white', marginTop: '16px', maxWidth: '80vw' }}
          >
            {image['file_note'] ? image['file_note'] : ''}
          </Box>
        </>
      ))}
      captions={images?.map((image) => (
        <div className={[classes.font, classes.contentFontSize].join(' ')}>
          {image['file_note'] ? image['file_note'] : ''}
        </div>
      ))}
      types={images?.map((image) =>
        image['media_type'] ? image['media_type'] : 'image',
      )}
      sourceIndex={activeIndex}
      keys={images.length}
      disableBackgroundClose={true}
      onClose={() => {
        setToggler(false);
        onClose();
      }}
    />
  );
};

export default TripFsLightBox;
