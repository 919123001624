export function meal (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      className={outerLayer}
    >
      <path
        className={className}
        d="M16.38,6.24c-5.391,0-9.761,4.37-9.761,9.761c0,5.391,4.37,9.76,9.761,9.76s9.762-4.369,9.762-9.76  C26.142,10.61,21.771,6.24,16.38,6.24z M16.38,21.873c-3.243,0-5.872-2.629-5.872-5.872c0-3.244,2.629-5.873,5.872-5.873  c3.244,0,5.873,2.629,5.873,5.873C22.253,19.244,19.624,21.873,16.38,21.873z"
      ></path>
      <path
        className={className}
        d="M5.738,9.746C5.644,8.646,5.542,6.797,5.478,6.339c-0.063-0.458-0.726-0.491-0.73-0.036  c-0.002,0.458-0.014,4.001-0.014,4.001H3.63c0,0-0.002-2.474,0.002-3.695c0-0.321-0.039-0.637-0.443-0.631  c-0.002,0-0.003,0.001-0.004,0.001c-0.002,0-0.002-0.001-0.004-0.001C2.775,5.972,2.736,6.289,2.737,6.609  c0.003,1.221,0,3.695,0,3.695H1.636c0,0-0.012-3.543-0.016-4.001C1.616,5.848,0.954,5.881,0.89,6.339  C0.826,6.797,0.724,8.646,0.63,9.746c-0.047,0.542-0.098,1.085-0.121,1.628c-0.059,1.362,0.162,1.929,1.41,2.479  c0.146,0.065,0.287,0.354,0.277,0.531c-0.013,0.285-0.312,7.812-0.377,9.36c-0.039,0.918-0.074,2.272,1.365,2.277  c1.438-0.005,1.404-1.359,1.365-2.277c-0.066-1.549-0.365-9.075-0.379-9.36c-0.008-0.178,0.132-0.466,0.278-0.531  c1.248-0.55,1.47-1.117,1.411-2.479C5.837,10.831,5.784,10.289,5.738,9.746z"
      ></path>
      <path
        className={className}
        d="M31.487,23.745c-0.029-0.705,0.01-15.221,0.01-17.015c0-1.085-0.91-0.764-1.293-0.458c-1.756,1.403-2.328,6.527-2.617,8.417  c-0.25,1.635-0.041,2.217,0.822,2.475c0.727,0.218,0.629,0.771,0.629,0.771h0.002c-0.098,2.428-0.24,4.803-0.283,5.811  c-0.037,0.918-0.072,2.272,1.365,2.277C31.562,26.018,31.526,24.663,31.487,23.745z"
      ></path>
    </svg>
  );
}