export function snorkeling (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M58.747,42.496H38.298c-5.304,0-9.618,4.314-9.618,9.618v5.853c0,3.478,2.83,6.308,6.309,6.308h4.096  c1.916,0,3.076-1.291,4.34-2.913l0.291-0.369c1.133-1.402,2.886-2.207,4.808-2.207s3.675,0.805,4.81,2.211  c0.106,0.13,0.218,0.273,0.337,0.427c1.216,1.561,2.378,2.852,4.292,2.852h4.096c3.479,0,6.309-2.83,6.309-6.308v-5.853  C68.365,46.811,64.051,42.496,58.747,42.496z M35.514,45.433l1.063,1.063l-3.897,3.897l-1.063-1.063L35.514,45.433z M33.117,54.747  l-1.063-1.063l7.814-7.814l1.063,1.063L33.117,54.747z M72.319,17.446c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3  S73.974,17.446,72.319,17.446z M60.747,38.659V29.13c0-1.242,1.011-2.254,2.253-2.254v-4c-3.448,0-6.253,2.806-6.253,6.254v9.366  H38.298c-7.509,0-13.618,6.108-13.618,13.618v5.853c0,5.684,4.625,10.308,10.309,10.308h4.096c4.025,0,6.336-2.967,7.443-4.388  l0.299-0.38c0.37-0.459,0.988-0.722,1.696-0.722s1.326,0.264,1.69,0.714l0.301,0.382c0.994,1.275,2.951,3.778,6.233,4.293v7.235  c0,1.734-1.41,3.145-3.144,3.145c-1.734,0-3.145-1.41-3.145-3.145v-0.849c1.312-0.348,2.517-1.027,3.501-2.013l1.414-1.415  l-2.829-2.827l-1.414,1.414c-1.428,1.428-3.917,1.428-5.346,0l-1.414-1.414l-2.828,2.828l1.414,1.414  c0.985,0.985,2.19,1.665,3.502,2.013v0.849c0,3.939,3.205,7.145,7.145,7.145s7.144-3.205,7.144-7.145v-7.135h1.31  c5.685,0,10.309-4.624,10.309-10.308v-5.853C72.365,45.286,67.309,39.632,60.747,38.659z M70.365,57.967  c0,4.581-3.728,8.308-8.309,8.308h-4.096c-3.048,0-4.814-2.268-5.873-3.627l-0.313-0.396c-0.752-0.933-1.938-1.467-3.253-1.467  s-2.501,0.534-3.261,1.475l-0.309,0.394c-1.055,1.354-2.821,3.621-5.869,3.621h-4.096c-4.582,0-8.309-3.727-8.309-8.308v-5.853  c0-6.406,5.212-11.618,11.618-11.618h20.449c6.406,0,11.618,5.212,11.618,11.618V57.967z M69.72,27.265c-2.205,0-4,1.795-4,4  s1.795,4,4,4s4-1.795,4-4S71.925,27.265,69.72,27.265z"
        ></path>
      </g>
    </svg>
  );
}