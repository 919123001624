import {
  RECEIVE_UNSPLASH_IMAGES,
  RECEIVE_PEXEL_IMAGES,
  RECEIVE_FLUSH_FETCHED_IMAGES,
} from '../constants';
import * as ThirdPartyApi from '../util/api_util/third_party_api_util';
const receiveUnsplashImages = (data) => {
  return {
    type: RECEIVE_UNSPLASH_IMAGES,
    data,
  };
};
const receiveFlushFetchedImages = () => {
  return {
    type: RECEIVE_FLUSH_FETCHED_IMAGES,
  };
};
const receivePexelImages = (data, searchTerm) => {
  return {
    type: RECEIVE_PEXEL_IMAGES,
    data,
    searchTerm,
  };
};
export const fetchUnsplashImages = (searchTerm) => (dispatch) => {
  return ThirdPartyApi.fetchImagesFromUnsplash(searchTerm).then((res) => {
    return dispatch(receiveUnsplashImages(res.data));
  });
};

export const fetchPexelImages = (query) => (dispatch) => {
  return ThirdPartyApi.fetchPexelImages(query).then((res) => {
    return dispatch(receivePexelImages(res.data, query.search_term));
  });
};

export const flushFetchedImages = () => (dispatch) => {
  dispatch(receiveFlushFetchedImages());
};
