module.exports = {
  friendRequest: function (sender, receiver) {
    const { account_rid, explorer_rid, ui_image_location } = sender;
    return {
      type: 'friendRequest',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {
        title: 'You got a friend request',
        img: ui_image_location,
      },
      emailPayload: {
        var_recipientFirstName: receiver.full_name.split(' ')[0],
        var_senderFullName: `${sender.first_name} ${sender.last_name}`,
        var_senderFirstName: sender.first_name,
      },
      recipientExplorerRid: receiver.explorer_rid,
    };
  },
  acceptFriendRequest: function (sender) {
    const {
      first_name,
      last_name,
      account_rid,
      explorer_rid,
      ui_image_location,
    } = sender;
    return {
      type: 'acceptFriendRequest',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {
        title: `${first_name} ${last_name} has accepted your friend request.`,
        img: ui_image_location,
      },
    };
  },
  denyFriendRequest: function () {
    return {
      type: 'denyFriendRequest',
      action: { clickToLoad: null },
      badgePayload: {
        title: 'dont display',
      },
    };
  },
  adventureInviteRequest: function (sender, trip) {
    const { first_name, last_name, account_rid, explorer_rid } = sender;
    const { adventure_name } = trip;
    return {
      type: 'adventureInviteRequest',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {
        title: `You've been invited on a trip '${adventure_name}' by ${first_name} ${last_name}`,
        img: sender.ui_image_location,
        adventureImg: trip.ui_image_location,
        tripData: trip,
      },
      var_adventureName: adventure_name,
    };
  },
  acceptAdventureInviteRequest: function (sender, trip) {
    const { first_name, last_name, account_rid, explorer_rid } = sender;
    const { adventure_name } = trip;
    return {
      type: 'acceptAdventureInviteRequest',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {
        title: `${first_name} ${last_name} has joined '${adventure_name}'!`,
        img: sender.ui_image_location,
        adventureImg: trip.ui_image_location,
        tripData: trip,
      },
    };
  },
  adventureGroupRequest: function (account_rid, explorer_rid) {
    return {
      type: 'adventureGroupRequest',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {},
    };
  },
  accountGroup: function (account_rid, explorer_rid) {
    return {
      type: 'accountGroup',
      action: { clickToLoad: `/hub/profile/${account_rid}/${explorer_rid}` },
      badgePayload: {},
    };
  },

  // 'accountGroupRequest':
};
