import React from 'react';
import { MemberBlock } from 'components/global_modal/modal_modules';
import moment from 'moment';
const AddAndEditAccountMembers = ({
  allAccountExplorers,
  accountRoles,
  countries,
  statesProvences,
  fetchAccountRoles,
  fetchCountries,
  fetchStates,
  openAccountExplorerEditModal,
  accountDetails,
  history,
  openInviteNewMemberModal,
  userInfo,
}) => {
  const {
    auto_renew_flag,
    explorer_count,
    explorer_limit,
    plan_name,
    plan_description,
    remaining_explorer_count,
    ui_image_location,
    subscription_end_date,
    plan_rid,
  } = accountDetails;
  return (
    <>
      <header className="general-header">MEMBERS</header>
      <hr />
      <section className="add-edit-account-members-section-wrapper">
        <section className="add-edit-account-member-section add-edit-account-member-section--add">
          <div className="edit-account-memeber-section-header-wrappers">
            <h3>Add Members</h3>
            <hr />
          </div>
          <div className="add-account-member-section-contents-wrapper">
            <div className="addacount-member-contents addacount-member-contents--summary">
              <div className="add-account-member-summary-top">
                {/* <img
                  className="add-account-member-summary-lodestar-log"
                  src="/svg_logos/nav_bar_logo_navy.svg"
                /> */}
              </div>
              {/* <hr className="add-account-member-summary-divider" /> */}
              <div className="add-account-member-summary-middle">
                <div className="add-account-member-summary-middle-content-wrapper add-account-member-summary-middle-content-wrapper--plan-name">
                  <span className="add-account-member-summary-middle-content-plan-name">
                    {plan_name}
                  </span>
                </div>
                <div className="add-account-member-summary-middle-content-wrapper add-account-member-summary-middle-content-wrapper--plan-description">
                  <span className="add-account-member-summary-middle-content-plan-description">
                    {plan_description}
                  </span>
                </div>
                <div className="add-account-member-summary-middle-content-wrapper add-account-member-summary-middle-content-wrapper--plan-renewal-date">
                  <span className="add-account-member-summary-middle--plan-renewal-date">
                    Renewal Date:{' '}
                    {moment(subscription_end_date).format('DD-MMM-YYYY')}
                  </span>
                </div>
              </div>
              {/* <hr className="add-account-member-summary-divider" /> */}
              <div className="add-edit-account-member-bottom">
                {/* <div className="add-edit-account-explorer-count-wrapper">
                  <div className="add-edit-account-explorer-count-content-wrapper add-edit-account-explorer-count-content-wrapper--limit ">
                    <span className="add-edit-account-explorer-count--count">
                      {explorer_limit}
                    </span>
                    <div className="explorer-count-label-wrapper">
                      <span className="add-edit-account-explorer-count--label">
                        Explorer Limit
                      </span>
                    </div>
                  </div>
                  <div className="add-edit-account-explorer-count-content-wrapper add-edit-account-explorer-count-content-wrapper--count ">
                    <span className="add-edit-account-explorer-count--count">
                      {explorer_count}
                    </span>
                    <div className="explorer-count-label-wrapper">
                      <span className="add-edit-account-explorer-count--label">
                        Current Explorer Count
                      </span>
                    </div>
                  </div>
                  <div className="add-edit-account-explorer-count-content-wrapper add-edit-account-explorer-count-content-wrapper--remaining ">
                    <span className="add-edit-account-explorer-count--count">
                      {remaining_explorer_count}
                    </span>
                    <div className="explorer-count-label-wrapper">
                      <span className="add-edit-account-explorer-count--label">
                        Explorer Remaining
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="add-edit-account-buttons-wrapper">
                  <button
                    className="add-edit-subscription-button--edit"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push('manage-subscription');
                    }}
                  >
                    MANAGE MEMBERSHIP
                  </button>
                  <button
                    className="add-edit-subscription-button--upgrade"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push('subscription');
                    }}
                  >
                    UPGRADE
                  </button>
                  <button
                    className="add-edit-subscription-button--upgrade"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openInviteNewMemberModal();
                    }}
                    // className="addaccount-member-add-button"
                    disabled={remaining_explorer_count > 5 || plan_rid === 35}
                  >
                    ADD MEMBER
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="addacount-member-contents addacount-member-contents--addmember">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openInviteNewMemberModal();
                }}
                className="addaccount-member-add-button"
                disabled={remaining_explorer_count === 0}
              >
                <div className="addaccount-member-add-button-inner-wrapper">
                  <span>
                    {remaining_explorer_count === 0
                      ? 'No explorer spaces remaining, please delete explorers to add more'
                      : 'Click to invite new explorer to your account'}
                  </span>
                </div>
              </button>
            </div> */}
          </div>
        </section>

        <section className="add-edit-account-member-section add-edit-account-member-section--edit">
          <div className="edit-account-memeber-section-header-wrappers">
            <h3>Member Profiles</h3>
            <hr />
          </div>
          <div className="add-edit-account-member-section-contents-wrapper">
            {allAccountExplorers &&
              allAccountExplorers.map((ele, idx) => {
                return (
                  ele.explorer_rid !== userInfo.explorer_rid && (
                    <MemberBlock
                      accountExplorerClickHandler={() => {
                        if (!accountRoles) fetchAccountRoles();
                        if (!countries) fetchCountries();
                        if (!statesProvences) fetchStates();
                        openAccountExplorerEditModal(ele);
                      }}
                      isAccountExplorers
                      member={ele}
                    />
                  )
                );
              })}
          </div>
        </section>
      </section>
    </>
  );
};

export default AddAndEditAccountMembers;
