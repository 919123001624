export const svgAddNew = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      width={width ? width : '1200pt'}
      height={height ? height : '1200pt'}
      viewBox={viewBox ? viewBox : '0 0 1200 1200'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1">
        <path
          fill={color ? color : '#273906'}
          className={className}
          d="m1170 600c0-314.4-255.6-570-570-570s-570 255.6-570 570 255.6 570 570 570 570-255.6 570-570zm-264 16.801c0 34.801-28.801 63.602-63.602 63.602h-162v162c0 34.801-28.801 63.602-63.602 63.602h-33.602c-34.801 0-63.602-28.801-63.602-63.602v-162h-162c-34.801 0-63.602-28.801-63.602-63.602v-33.602c0-34.801 28.801-63.602 63.602-63.602h162v-162c0-34.801 28.801-63.602 63.602-63.602h33.602c34.801 0 63.602 28.801 63.602 63.602v162h162c34.801 0 63.602 28.801 63.602 63.602z"
        />
      </g>
    </svg>
  );
};