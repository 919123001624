
export const svgUnnest = (
  color,
  className,
  outerLayer,
  width = 15,
  height = 15,
) => {
  return (
    <svg
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 35.88, 35.88"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M1.38,0 L34.5,0 C35.262,0 35.88,0.618 35.88,1.38 L35.88,34.5 C35.88,35.262 35.262,35.88 34.5,35.88 L1.38,35.88 C0.618,35.88 0,35.262 0,34.5 L0,1.38 C0,0.618 0.618,0 1.38,0 z M11.444,12.016 C11.714,12.285 12.067,12.42 12.42,12.42 C12.773,12.42 13.126,12.285 13.396,12.016 L15.18,10.232 L15.18,28.98 C15.18,29.742 15.798,30.36 16.56,30.36 L23.46,30.36 C24.222,30.36 24.84,29.742 24.84,28.98 C24.84,28.218 24.222,27.6 23.46,27.6 L17.94,27.6 L17.94,10.232 L19.724,12.016 C20.263,12.555 21.137,12.555 21.676,12.016 C22.215,11.477 22.215,10.603 21.676,10.064 L17.536,5.924 C16.997,5.385 16.123,5.385 15.584,5.924 L11.444,10.064 C10.905,10.603 10.905,11.477 11.444,12.016 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};