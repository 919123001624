export function standupPaddle (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 64 64"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <path
        className={className}
        d="M57.64,49.92a6.192,6.192,0,0,1,1.882.962A.889.889,0,0,0,60,50.1V49.9a.9.9,0,0,0-.9-.9H54.65Z"
      ></path>
      <path
        className={className}
        d="M57.052,51.831l-7.54-2.32,2.32,7.541a4.177,4.177,0,1,0,5.22-5.221Z"
      ></path>
      <path
        className={className}
        d="M55.823,62H62V55.823A6.184,6.184,0,0,1,55.823,62Z"
      ></path>
      <path
        className={className}
        d="M50.145,58.206c-.052,0-.1-.006-.149-.006a9.143,9.143,0,0,0-5.488,1.658A11.208,11.208,0,0,1,37.991,61.8a11.182,11.182,0,0,1-6.512-1.943A9.143,9.143,0,0,0,26,58.2a9.142,9.142,0,0,0-5.485,1.657A11.192,11.192,0,0,1,14,61.8a11.188,11.188,0,0,1-6.513-1.943A9.138,9.138,0,0,0,2,58.2V62H55.823A6.129,6.129,0,0,1,50.145,58.206Z"
      ></path>
      <path
        className={className}
        d="M14,53.8a8.719,8.719,0,0,0,4.947-1.338,41.026,41.026,0,0,1-6.4-1.561l-4.56-1.52A2.894,2.894,0,0,1,6.347,48H2v2.2a11.188,11.188,0,0,1,6.513,1.943A9.138,9.138,0,0,0,14,53.8Z"
      ></path>
      <path
        className={className}
        d="M7,17H24.2a1,1,0,0,0,.866-.5A7,7,0,0,0,19.668,6.032a6,6,0,0,0-11.6,1.087A4.895,4.895,0,0,0,7,7,5,5,0,0,0,7,17Z"
      ></path>
      <rect x="48" y="13" width="7" height="2" rx="1" ry="1"></rect>
      <rect x="57" y="13" width="2" height="2" rx="1" ry="1"></rect>
      <rect x="55" y="17" width="3" height="2" rx="1" ry="1"></rect>
      <rect x="60" y="17" width="2" height="2" rx="1" ry="1"></rect>
      <path
        className={className}
        d="M32.783,49H25.475c-.689,0-1.375-.023-2.06-.058l-.133-.007c-.6-.035-1.587-.112-2.187-.177l-.185-.018A41.289,41.289,0,0,1,16.52,48a40.867,40.867,0,0,1-3.979-1.1l-3.36-1.12A.9.9,0,0,0,8,46.632a.9.9,0,0,0,.614.852L10.162,48l3.012,1a38.994,38.994,0,0,0,8.863,1.843A11.214,11.214,0,0,1,26,50.2c.446,0,.859.022,1.251.058.1.009.194.025.293.037.288.033.569.071.829.121.083.016.16.035.241.053.272.059.535.123.781.2l.13.04c.284.091.555.188.81.293a16.1,16.1,0,0,1,2.178,1.143A9.14,9.14,0,0,0,37.991,53.8a9.145,9.145,0,0,0,5.488-1.658,16.522,16.522,0,0,1,2.134-1.134l.774,1.845a14.548,14.548,0,0,0-1.879,1A11.2,11.2,0,0,1,37.991,55.8a11.182,11.182,0,0,1-6.512-1.943A9.143,9.143,0,0,0,26,52.2a9.142,9.142,0,0,0-5.485,1.657A11.192,11.192,0,0,1,14,55.8a11.188,11.188,0,0,1-6.513-1.943A9.138,9.138,0,0,0,2,52.2v4a11.188,11.188,0,0,1,6.513,1.943A9.138,9.138,0,0,0,14,59.8a9.141,9.141,0,0,0,5.484-1.657A11.193,11.193,0,0,1,26,56.2a11.188,11.188,0,0,1,6.513,1.943A9.14,9.14,0,0,0,37.991,59.8a9.154,9.154,0,0,0,5.488-1.657,11.232,11.232,0,0,1,6-1.922L47.877,51l-.616-2H41V37.937L47.135,48.5l1.73-1L41.988,35.654l.258-.33c.9-1.146,1.753-2.23,1.753-3.344-.015-.741-.072-3.211-.192-3.806l-.643-4.45A8.585,8.585,0,0,1,43,22.062V17a4.971,4.971,0,0,0-1.887-3.881,6,6,0,1,0-5.1.535,15.615,15.615,0,0,0-3.352,5.931L30.865,16.5l-1.73,1,2.94,5.063C32.026,23.073,32,23.586,32,24.1v1.79a15.827,15.827,0,0,0,2.241,8.12l.193.324A3.984,3.984,0,0,1,35,36.385V47Z"
      ></path>
    </svg>
  );
}