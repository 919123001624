import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import  Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HubModal from '../../modal';
import { SectionSubTitle } from '../../../common/section-title';
import {
  CampgroundIcon,
  CampsiteIcon,
  ItineraryIcon,
  AllLocationIcon,
  LocationIcon,
  MessageIcon,
  MapRoad,
  MapSattelite,
  MapTopo
} from '../../../svg_module';
import Switch from '../../../common/switch';
import { IconTitle } from '../../../common/icon-title';
import {
  MAP_OPTION_ITINERARY_ONLY,
  MAP_OPTION_TRIP_BOARD,
  MAP_OPTION_MY_ROUTE,
  MAP_STYLE_NAVIGATION,
  MAP_STYLE_SATELLITE,
  MAP_STYLE_TERRAIN,
} from '../../../../constants';
import { Typography } from '@mui/material';

const MenuItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckIcon = styled.div`
  background-color: #f8eddb;
  border-radius: 5px;
  padding: 7px 10px;
  color: #273a07;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
`;

const CheckedIcon = styled.div`
  background-color: #273a07;
  border-radius: 5px;
  padding: 7px 10px;
  color: #fcedd6;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
`;

const MapStyleGroupItemRadioButton = styled(Radio)`
  flex-grow: 1;
  padding: 0px !important;
  margin: 0 0 0 10px !important;
  width: 75px;
  height: 40px;
  min-width: 100px;
  max-width: 100px;
`;

const MapStyleGroupItemDescription= styled.div`
  font-size: 14px;
  font-weight: 700;
  flex-grow: 3
`

const MapStyleGroupItem = styled(Box)`
  display: flex;
`

const MapStyleGroup = styled(RadioGroup)`
  gap: 7px;
`;

const MapFilter = ({ closeModal }) => {
  const actionPayload = useSelector((state) => state.ui.modal.actionPayload);
  const { options, controls, onSave } = actionPayload;

  const defaultOptions = {
    campground: true,
    campsite: true,
    route: true,
    poi: MAP_OPTION_ITINERARY_ONLY,
    detail: false,
    mapStyle: MAP_STYLE_TERRAIN,
  };
  const [values, setValues] = useState({ ...(options || defaultOptions) });
  const { campground, campsite, route, poi, detail, mapStyle } = values;
  const {
    showRoute,
    showItineraryOnly,
    showTripBlock,
    showDetail,
    showMapStyle,
  } = controls;
  const onSubmit = () => {
    if (onSave) onSave(values);
    console.log(values, `updated option values`);
    closeModal();
  };

  const onShowCampgroundChange = (value) => {
    setValues({
      ...values,
      campground: event.target.checked,
      campsite: event.target.checked,
    });
  };

  const onShowRouteChange = (value) => {
    setValues({ ...values, route: value });
  };

  const onMapOptionChange = (value) => {
    // if toggling to show trip board values, disable routing
    const useRoutes = value === MAP_OPTION_TRIP_BOARD ? false : route 
    setValues({ ...values, poi: value, route: useRoutes });
  };

  const onMapStyleChange = (event) => {
    setValues({ ...values, mapStyle: event.target.value });
  };

  const onDetailChange = (event) => {
    setValues({ ...values, detail: event.target.checked });
  };

  return (
    <HubModal
      show={true}
      title="Map Options"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={onSubmit}
    >
      <Box className="create-adventure-wrapper">
        <Stack spacing={2}>
          <SectionSubTitle>Lodging</SectionSubTitle>
          <MenuItemContainer>
            <IconTitle icon={<CampgroundIcon />}>Show public campsites</IconTitle>
            <Switch checked={campground} onChange={onShowCampgroundChange} />
          </MenuItemContainer>
          {showRoute && <SectionSubTitle>Routing</SectionSubTitle>}
          {showRoute && (
            <MenuItemContainer>
              <IconTitle icon={<ItineraryIcon />}>Show route</IconTitle>
              <Switch
                checked={route}
                onChange={(e) => onShowRouteChange(e.target.checked)}
                disabled={poi === MAP_OPTION_TRIP_BOARD}
              />
            </MenuItemContainer>
          )}
          {(showItineraryOnly || showTripBlock || showDetail) && (
            <SectionSubTitle>Activities & Points of Interest</SectionSubTitle>
          )}
          {showItineraryOnly && (
            <MenuItemContainer>
              <IconTitle icon={<LocationIcon />}>Show itinerary items only</IconTitle>
              <Switch
                checked={poi === MAP_OPTION_ITINERARY_ONLY}
                onChange={(e) =>
                  e.target.checked &&
                  onMapOptionChange(MAP_OPTION_ITINERARY_ONLY)
                }
              />
            </MenuItemContainer>
          )}
          {showTripBlock && (
            <MenuItemContainer>
              <IconTitle icon={<AllLocationIcon />}>Show all trip board items</IconTitle>
              <Switch
                checked={poi === MAP_OPTION_TRIP_BOARD}
                onChange={(e) =>
                  e.target.checked && onMapOptionChange(MAP_OPTION_TRIP_BOARD)
                }
              />
            </MenuItemContainer>
          )}
          {/* {showDetail && (
            <MenuItemContainer>
              <IconTitle icon={<MessageIcon />}>Display details</IconTitle>
              <Switch checked={detail} onChange={onDetailChange} />
            </MenuItemContainer>
          )} */}
          {showMapStyle && <SectionSubTitle>Map Views</SectionSubTitle>}
          {showMapStyle && (
            <MapStyleGroup value={mapStyle} onChange={onMapStyleChange} column>
              <MenuItemContainer>
                <IconTitle icon={<MapTopo/>}>See terrain, all public lands, natl. & state parks, trails, & more</IconTitle>
                <MapStyleGroupItemRadioButton
                  value={MAP_STYLE_TERRAIN}
                  label="Topo"
                  checkedIcon={<CheckedIcon>Topo</CheckedIcon>}
                  icon={<CheckIcon>Topo</CheckIcon>}
                />
            </MenuItemContainer>
              <MenuItemContainer>
                <IconTitle icon={<MapRoad/>}>See road networks</IconTitle>
                <MapStyleGroupItemRadioButton
                  value={MAP_STYLE_NAVIGATION}
                  label="Roads"
                  checkedIcon={<CheckedIcon>Roads</CheckedIcon>}
                  icon={<CheckIcon>Roads</CheckIcon>}
                />
            </MenuItemContainer>
              <MenuItemContainer>
                <IconTitle icon={<MapSattelite/>}>See satellite imagery</IconTitle>
                <MapStyleGroupItemRadioButton
                  value={MAP_STYLE_SATELLITE}
                  label="Satellite"
                  checkedIcon={<CheckedIcon>Satellite</CheckedIcon>}
                  icon={<CheckIcon>Satellite</CheckIcon>}
                />
            </MenuItemContainer>
            </MapStyleGroup>
          )}
        </Stack>
      </Box>
    </HubModal>
  );
};

MapFilter.defaultProps = {
  actionPayload: {},
};

export default MapFilter;
