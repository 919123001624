import {
  RECEIVE_OPEN_LIST_EDIT_POPUP,
  RECEIVE_CLOSE_POP_UP,
} from '../../constants';

import { merge } from 'lodash';
const defaultState = { action: RECEIVE_CLOSE_POP_UP, isLoading: false };

export default function modalReducer(state = defaultState, action) {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_OPEN_LIST_EDIT_POPUP:
      newState['action'] = action.type;
      newState['popupContent'] = action.data;
      return newState;
    default:
      return defaultState;
  }
}
