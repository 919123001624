export function rafting (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M8.54722,64.43867L8.19725,64.7887c-1.09998,1.09998-2.88,1.09998-3.97998,0c-1.10004-1.10003-1.10004-2.88,0-3.98999   l0.33997-0.34003c0.55005,0.76001,1.17004,1.47998,1.84003,2.15002C7.09727,63.31868,7.81724,63.93867,8.54722,64.43867z"
        ></path>
        <path
          className={className}
          d="M64.79722,8.19868l-0.33997,0.34003c-0.59002-0.80005-1.20001-1.51001-1.84002-2.14002   c-0.69-0.69-1.40997-1.31-2.14996-1.84003l0.33997-0.33997c1.11005-1.10999,2.89001-1.10999,3.98999,0   C65.89725,5.31867,65.89725,7.0987,64.79722,8.19868z"
        ></path>
        <g>
          <polygon points="36.00377,17.623 35.99559,17.63117 35.99235,17.63441   "></polygon>
          <path
            className={className}
            d="M36.01335,17.61347c-0.00104,0.00104-0.00256,0.00256-0.00366,0.00366    C36.01085,17.61598,36.01207,17.61476,36.01335,17.61347z"
          ></path>
          <path
            className={className}
            d="M36.07145,17.55537c-0.0025,0.0025-0.00507,0.005-0.00806,0.00806c0.00482-0.00488,0.00964-0.00964,0.01508-0.01508    C36.0767,17.55012,36.07414,17.55268,36.07145,17.55537z"
          ></path>
          <path
            className={className}
            d="M12.79112,56.21437c3.02893,3.02893,7.79236,3.5769,11.40991,1.42859L11.36314,44.80775    C9.21244,48.43538,9.76463,53.18879,12.79112,56.21437z"
          ></path>
          <path
            className={className}
            d="M36.08104,17.54579c-0.00079,0.00079-0.00183,0.00183-0.00256,0.00256    C36.07945,17.54737,36.0803,17.54652,36.08104,17.54579z"
          ></path>
          <polygon points="36.00969,17.61714 36.00828,17.61848 36.00773,17.61909   "></polygon>
          <path
            className={className}
            d="M44.80265,11.36244l12.84943,12.8468c2.14832-3.62842,1.59888-8.39532-1.42834-11.42163    C53.2216,9.78547,48.4705,9.19294,44.80265,11.36244z"
          ></path>
          <path
            className={className}
            d="M12.79112,42.95362c-0.19238,0.19147-0.36578,0.38306-0.52795,0.57629c-0.00867,0.01038-0.01569,0.02118-0.02435,0.03156    L25.44529,56.766c0.00928-0.00769,0.0191-0.01416,0.02832-0.02191c0.19421-0.16284,0.38684-0.33728,0.58118-0.5307l4.6084-4.6084    L17.4008,38.34455L12.79112,42.95362z"
          ></path>
          <path
            className={className}
            d="M61.55723,7.45869c-6.63-6.63-17.31-6.63-23.92999,0l-13.5,13.48999l4.27002,4.27002    c3.70001-3.70001,7.82001-7.81,13.5-13.48999c4.27997-4.28003,11.23999-4.15002,15.39001,0c4.13,4.13,4.28998,11.07996,0,15.38    c-5.42004,5.41998-9.86005,9.85999-13.49005,13.5l4.27002,4.26996l13.48999-13.48999    C68.30723,24.61866,68.03727,13.8487,61.55723,7.45869z M40.60728,43.78871C25.44725,58.93867,27.54722,56.8387,27.11723,57.2787    c-4.32001,4.31-11.25995,4.13-15.38995,0c-4.21002-4.21002-4.22003-11.16998,0-15.39001    c5.51996-5.52002,9.45996-9.46002,13.48999-13.48999l-4.27002-4.27002L7.45726,37.61866    c-6.59998,6.59003-6.66998,17.26001,0,23.93005c6.56,6.55999,17.25,6.67999,23.92999,0l13.5-13.49005L40.60728,43.78871z"
          ></path>
          <path
            className={className}
            d="M50.54722,31.72871l-7.81,7.82001l-2.79999-2.80005c0.85999-0.90997,0.79999-2.32001-0.04999-3.13    c-0.83002-0.82996-2.22998-0.91998-3.17999,0c-0.21002,0.21002-2.96002,2.97003-3.06,3.07001    c-0.92999,0.89001-0.88,2.38-0.06,3.16003c0.81,0.88,2.25,0.95996,3.16998,0.08997l2.78998,2.79004l-7.81995,7.82001    L18.45726,37.28871l7.83002-7.82001l2.77997,2.77997c-0.82001,0.88-0.79999,2.28003,0.04999,3.13    c0.94,0.94,2.42004,0.82001,3.17999,0l3.11005-3.09998c0.84998-0.88001,0.83997-2.29999,0-3.14002    c-0.87-0.90002-2.29999-0.90002-3.16003-0.07001l-2.77997-2.77997l7.82001-7.82001L50.54722,31.72871z"
          ></path>
          <path
            className={className}
            d="M42.96104,12.78761l-4.617,4.61639l13.26318,13.26025c4.85199-4.86932,4.65967-4.61786,5.16339-5.21552L43.57817,12.25923    C43.37169,12.43269,43.15489,12.59376,42.96104,12.78761z"
          ></path>
        </g>
        <g>
          <path d="M65.32725,59.55867c0.06,0.89001-0.27002,1.77002-0.90997,2.40002l-2.45001,2.45001    c-0.59003,0.58997-1.38,0.91998-2.20001,0.91998c-0.07001,0-0.13-0.01001-0.20001-0.01001    c-0.89001-0.06-1.71997-0.5-2.26001-1.20001l-4.75-6.13995c-0.53998-0.69-0.79999-1.53003-0.79999-2.36005    c0-0.75995,0.22003-1.50995,0.65002-2.15997L36.75724,37.80867l-1.01001,1.02002c-0.31003,0.31016-0.80235,0.27607-1.06,0    c-0.29999-0.28998-0.29999-0.77002,0-1.06l1.51001-1.52002l0.04999-0.03998l1.52002-1.53003    c0.29999-0.28998,0.77002-0.28998,1.06,0c0.29999,0.29004,0.29999,0.77002,0,1.06l-1.01001,1.01001l15.65002,15.65002    c1.35999-0.90002,3.17999-0.87,4.51996,0.15002l6.14001,4.75C64.82725,57.8387,65.26725,58.66871,65.32725,59.55867z"></path>
          <path d="M34.32725,31.23866l-1.54999,1.54004l-1.54004,1.53998c-0.2561,0.27452-0.74833,0.31167-1.06,0    c-0.28998-0.28998-0.28998-0.76001,0-1.06l1.01001-1.01001L15.54722,16.60871c-0.64996,0.42999-1.39996,0.64996-2.15997,0.64996    c-0.83002,0-1.66998-0.27002-2.37-0.81l-6.13-4.73999c-0.71002-0.54999-1.15002-1.37-1.20001-2.26001    c-0.06-0.89996,0.27002-1.77002,0.90002-2.39996l2.45996-2.46002c0.63-0.63,1.51001-0.97003,2.40002-0.90002    c0.89001,0.04999,1.71002,0.48999,2.26001,1.20001l4.73999,6.13001c1.03003,1.34003,1.06,3.15997,0.16003,4.53003    l15.63995,15.63995l1.02002-1.01001c0.28998-0.29999,0.77002-0.29999,1.06,0    C34.61723,30.4687,34.61723,30.93867,34.32725,31.23866z"></path>
        </g>
      </g>
    </svg>
  );
}