
export const svgUnnestInverted = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0, 0, 45.801, 61.452"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            d="M12.012,60.44 L12.012,14.471 L5.817,20.666 C5.423,21.06 4.778,21.06 4.384,20.666 L0.295,16.577 C-0.098,16.183 -0.098,15.539 0.295,15.145 L15.148,0.292 C15.359,0.081 15.64,-0.012 15.917,0.001 C16.194,-0.013 16.475,0.081 16.686,0.292 L31.538,15.144 C31.932,15.538 31.932,16.183 31.538,16.577 L27.449,20.666 C27.055,21.06 26.411,21.06 26.017,20.666 L19.821,14.47 L19.821,53.643 L44.7,53.643 C44.7,53.643 45.148,53.641 45.516,54.033 C45.801,54.336 45.801,54.768 45.801,54.768 L45.801,60.428 C45.801,60.428 45.8,60.75 45.489,61.095 C45.168,61.452 44.754,61.452 44.754,61.452 L13.025,61.452 C12.468,61.453 12.012,60.997 12.012,60.44 z"
            fill="#000000"
            className={className}
          />
        </g>
      </g>
    </svg>
  );
};
