export function suvTravel (color, className, outerLayer) {
  return (
    <svg viewBox="0 0 512 512" x="0px" y="0px" className={outerLayer}>
      <g data-name="11. SUV car">
        <path
          className={className}
          d="M144,347a16,16,0,1,1-16-16A16,16,0,0,1,144,347Zm216-16a16,16,0,1,0,16,16A16,16,0,0,0,360,331Zm152-45.26V322.9A32.08,32.08,0,0,1,480,355H423.48a64,64,0,0,1-127,0H216a8,8,0,0,1-8-8H192a64,64,0,0,1-127.57,7.31l-32.54-.82A32.08,32.08,0,0,1,0,321.39V277.82a323.52,323.52,0,0,1,32.52-141A64.11,64.11,0,0,1,89.77,101H123.5a8,8,0,0,1,8,8H147a7.44,7.44,0,0,1,.16-1.56,7.85,7.85,0,0,1,.45-1.5,8,8,0,0,1,.74-1.38,7.59,7.59,0,0,1,2.21-2.21,8,8,0,0,1,1.38-.74,8.08,8.08,0,0,1,6.12,0,8,8,0,0,1,1.38.74,7.59,7.59,0,0,1,2.21,2.21,8,8,0,0,1,.74,1.38,7.85,7.85,0,0,1,.45,1.5A7.44,7.44,0,0,1,163,109h13a8,8,0,0,1,8-8H316a83.41,83.41,0,0,1,70.71,39.54l.06.1L425.56,206H432C476.11,206,512,241.77,512,285.74ZM472,291h24v-5.26a63.45,63.45,0,0,0-.93-10.74H472a8,8,0,0,0,0,16ZM184,206H294V150H184Zm-89.11-3a7.91,7.91,0,0,0,6.2,3H168V150H110.24a7.92,7.92,0,0,0-7.74,6.2l-9.15,40A8,8,0,0,0,94.89,203ZM256,246H240a8,8,0,0,0,0,16h16a8,8,0,0,0,0-16Zm117.72-96H310v56H407ZM176,347a48,48,0,1,0-48,48A48.05,48.05,0,0,0,176,347Zm232,0a48,48,0,1,0-48,48A48.05,48.05,0,0,0,408,347Z"
          fill={color}
        ></path>
      </g>
    </svg>
  );
}