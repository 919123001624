import React from 'react';
import { UserBarContainer, UserNameBar } from './components/user-bar.styled';
import { makeStyles, Box } from '@material-ui/core';
import UserAvatar from './components/user-avatar';
import { styleValues } from '../../pages/pwa_hub/adventure/components/constants';

const useStyles = makeStyles((theme) => styleValues(theme));

const UserBar = (props) => {
  const classes = useStyles();
  let nameSplit = props.user.name.split(' ');
  return (
    <UserBarContainer {...props}>
      {props.avatarposition === 'left' ? (
        <>
          <UserAvatar {...props}></UserAvatar>
          <Box>
            <Box
              className={[classes.font, classes.font_18_14, classes.bold].join(' ')}
              {...props}
            >
              {nameSplit.length > 1
                ? `${nameSplit[0]} ${nameSplit[1].slice(0, 1).toUpperCase()}.`
                : props.user?.name}
            </Box>
            <Box className={classes.span}>
              {props?.user?.friend_role_name}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Box
              className={[classes.font, classes.contentFontSize, classes.bold].join(' ')}
              {...props}
            >
              {nameSplit.length > 1
                ? `${nameSplit[0]} ${nameSplit[1].slice(0, 1).toUpperCase()}.`
                : props.user?.name}
            </Box>
            <Box className={classes.span}>
              {props?.user?.friend_role_name}
            </Box>
          </Box>
          <UserAvatar {...props}></UserAvatar>
        </>
      )}
    </UserBarContainer>
  );
};

export default UserBar;
