import React, {
  useState,
  useEffect,
  createRef,
  useRef,
  useCallback,
} from 'react';
import { Input, TextArea, Toggle, DropDownSelect } from '../../modal_modules';
import { merge } from 'lodash';
import { SectionSubTitle } from '../../../common/section-title';
import TextBox from '../../../common/text-box';

const Summary = ({
  handleOnChange,
  inputValues,
  uiToggle,
  uiToggleHandler,
  isAdventureBlock,
  openType,
  blockType,
  actionPayload,
  userInfo,
  copyToTripBoard,
  triggerNext,
  uiUpdatePayload,
  user_block_categories,
  handleCategorySelect,
  ...props
}) => {
  const [blockCategories, setBlockCategories] = useState([]);

  useEffect(() => {
    if (user_block_categories && inputValues.block_category_rid) {
      setBlockCategories(
        user_block_categories.filter(
          (ele) => ele.block_category_rid === inputValues.block_category_rid,
        ),
      );
    }
  }, [user_block_categories]);
  return (
    <>
      <SectionSubTitle>SUMMARY</SectionSubTitle>
      <TextBox
        className="text-field required"
        name="block_name"
        label="Name*"
        variant="outlined"
        fullWidth
        onChange={handleOnChange}
        onFocus={(e) => e.stopPropagation()}
        value={inputValues.block_name}
        size="small"
      />
      <DropDownSelect
        values={blockCategories}
        placeholder="Select a category..."
        className="create_edit_block_modal_drop_down_select --activity-categories"
        onChange={handleCategorySelect}
        options={user_block_categories}
        parentClassName="create_edit_block_modal_drop_down_select_parent "
        labelField="block_category_name"
        valueField="block_category_rid"
        clearable
        required
      />
      {/* <TextBox
        name="block_description"
        label="Description"
        variant="outlined"
        multiline
        maxRows={4}
        onChange={handleOnChange}
        onFocus={(e) => e.stopPropagation()}
        value={inputValues.block_description}
        size="small"
      /> */}
    </>
  );
};

export default Summary;
