import { Button, useMediaQuery } from "@mui/material"

const ResponsiveIconButton = ({ icon, text, onClick, sx = {}, ...rest }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (

    <Button startIcon={icon} onClick={onClick} {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center the icon/text horizontally
        alignItems: 'center', // Center the icon/text vertically
        '& .MuiButton-startIcon': {
          mr: {
            xs: 0,
            sm: 1
          }
        },
        ...sx
      }}
    >{!isSmallScreen && text}</Button>
  )
}

export default ResponsiveIconButton
