export function neighborhood (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                className={className}
                fill={color}
                d="M80.539,56.692v-3.059h-2.655v3.059H80.539z M80.539,60.213v-3.059h-2.655v3.059H80.539z M81.309,60.213h2.654v-3.059      h-2.654V60.213z M83.963,56.692v-3.059h-2.654v3.059H83.963z M89.214,65.561H72.671V50.614v-0.616v-2.75l7.868-5.617l4.02,2.809      h0.231v-2.771h3.347l-0.038,5.232l7.194,5.021l-3.405,0.385v13.254H89.214z"
              ></path>
              <path
                className={className}
                fill={color}
                d="M49.473,49.671V44.17h-4.771v5.501H49.473z M49.473,56v-5.502h-4.771V56H49.473z M50.839,50.498V56h4.751v-5.502H50.839      z M55.59,49.671V44.17h-4.751v5.501H55.59z M75.961,41.092l-6.117,0.692v23.776h-4.79h-29.7h-4.771V41.092h-6.944l25.834-18.485      l7.214,5.059v-5.059h6.482v9.561L75.961,41.092z"
              ></path>
              <path
                className={className}
                fill={color}
                d="M18.811,56.712v-3.059h-2.654v3.059H18.811z M18.811,60.231v-3.059h-2.654v3.059H18.811z M22.234,60.231v-3.059H19.58      v3.059H22.234z M22.234,56.712v-3.059H19.58v3.059H22.234z M27.447,50.633v14.946H10.904H8.23V52.326l-3.404-0.385l7.194-5.021      l-0.038-5.232h3.347v2.77h0.23l4.021-2.808l7.867,5.616v2.751V50.633z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}