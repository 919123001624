import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ItineraryStore
 * @prop {Object} itineraryDetails
 * @prop {Object} itinerary_info
 * @prop {Object} locationBlocks
 * @prop {Object} adventure_blocks
 */

/**
 *
 * @returns {ItineraryStore}
 */
const useCurrentItinerary = () => {
  return useSelector((state) => state.entities?.itinerary ?? null);
};

export default useCurrentItinerary;
