export const svgFolder2 = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      x="0"
      y="0"
      width={width ? width : '100'}
      height={height ? height : '100'}
      viewBox={viewBox ? viewBox : '0 0 100 100'}
    >
      <g id="Layer_1">
        <path
          className={className}
          fill={color || '#000'}
          d="M94.6,31.8 C94.2,31.3 93.6,31 93,31 L81,31 L81,19 C81,17.9 80.1,17 79,17 L39.7,17 L32.2,11.4 C31.8,11.1 31.4,11 31,11 L7,11 C5.9,11 5,11.9 5,13 L5,87 C5,87 5,87 5,87.1 C5,87.2 5,87.3 5,87.4 C5,87.4 5,87.5 5,87.5 C5,87.6 5.1,87.7 5.1,87.9 C5.1,87.9 5.1,88 5.2,88 C5.3,88.1 5.3,88.2 5.4,88.3 C5.4,88.3 5.4,88.3 5.4,88.3 C5.4,88.3 5.4,88.3 5.5,88.4 C5.6,88.5 5.6,88.5 5.7,88.6 C5.7,88.6 5.8,88.7 5.8,88.7 C5.9,88.6 6,88.7 6,88.7 C6,88.7 6.1,88.7 6.1,88.8 C6.2,88.8 6.3,88.9 6.4,88.9 C6.4,88.9 6.5,88.9 6.5,88.9 C6.6,88.9 6.8,88.9 6.9,88.9 L78.9,88.9 C79,88.9 79.1,88.9 79.3,88.9 C79.3,88.9 79.4,88.9 79.4,88.9 C79.5,88.9 79.6,88.9 79.6,88.8 C79.7,88.8 79.7,88.8 79.7,88.7 C79.8,88.7 79.8,88.6 79.9,88.6 C79.9,88.6 80,88.5 80,88.5 C80.1,88.5 80.1,88.4 80.2,88.3 C80.2,88.3 80.3,88.3 80.3,88.2 C80.4,88.1 80.4,88 80.5,88 C80.5,88 80.5,88 80.5,88 C80.6,87.9 80.6,87.8 80.7,87.7 C80.7,87.7 80.7,87.6 80.7,87.6 C80.7,87.6 80.7,87.5 80.8,87.4 L94.8,33.4 C95.1,32.9 95,32.3 94.6,31.8 z M9,15 L30.3,15 L37.8,20.6 C38.2,20.9 38.6,21 39,21 L77,21 L77,31 L21,31 C20.1,31 19.3,31.6 19.1,32.5 L9,71.3 L9,15 z M77.5,85 L9.6,85 L22.6,35 L90.5,35 L77.5,85 z"
        />
        <path
          className={className}
          fill={color || '#000'}
          d="M20.702,32.895 L90.856,33.697 L77.943,87.456 L7.789,86.654 L20.702,32.895 z"
        />
      </g>
    </svg>
  );
};