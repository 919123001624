const baseHeaderSizes = {
  h1: '3rem',
  h2: '2.5rem',
  h3: '2rem',
  h4: '1.5rem',
  h5: '1.25rem',
  h6: '1rem',
};

const scalingFactor = 1;

const scaleSize = (size, factor) => {
  const value = parseFloat(size);
  const unit = size.replace(value, '');
  return `${value * factor}${unit}`;
};

const muiTypographyStyles = {
  // fontFamily: "Lato, Arial, sans-serif", // Default global font family
  // h1: { fontSize: scaleSize(baseHeaderSizes.h1, scalingFactor) },
  // h2: { fontSize: scaleSize(baseHeaderSizes.h2, scalingFactor) },
  // h3: { fontSize: scaleSize(baseHeaderSizes.h3, scalingFactor) },
  // h4: { fontSize: scaleSize(baseHeaderSizes.h4, scalingFactor) },
  // h5: { fontSize: scaleSize(baseHeaderSizes.h5, scalingFactor) },
  // h6: { fontSize: scaleSize(baseHeaderSizes.h6, scalingFactor) },
  h1: {
    fontWeight: 700,
    // fontSize: "2.5rem",
  },
  h2: {
    fontWeight: 700,
    // fontSize: "2.25rem",
  },
  h3: {
    // fontSize: "1.75rem",
    fontWeight: 700,
  },
  h4: {
    fontWeight: 600,
    // fontSize: "1.5rem",
  },
  h5: {
    fontWeight: 600,
    // fontSize: "1.35rem",
  },
  h6: {
    fontWeight: 600,
    // fontSize: "1.2rem",
  },
  subtitle1: {
    fontWeight: 600,
  },
  subtitle2: {
    fontWeight: 600,
  },
  body1: {
    fontWeight: 300,
  },
  body2: {
    fontWeight: 300,
  },
  button: {
    // fontSize: "1rem",
    // fontWeight: 600,
  },
  caption: {
    fontWeight: 300,
  },
  overline: {
    fontWeight: 300,
  },
};

export default muiTypographyStyles;
