import Button from '../button';

const getProps = (props) => {
  let params = undefined;
  switch (props.mode) {
    case 'dark':
      params = {
        ...props,
        background: '#273A07',
        foreground: '#ffffff',
        align: props.align || 'center',
      };
      break;
    case 'red':
      params = {
        ...props,
        background: '#B34104',
        foreground: '#ffffff',
        align: props.align || 'center',
      };
      break;
    case 'yellow':
      params = {
        ...props,
        background: '#F2A93B',
        align: props.align || 'center',
      };
      break;
    case 'icon':
      params = {
        ...props,
        background: 'rgba(0,0,0,0)',
        hoverBackground: 'rgba(0,0,0,0)',
        foreground: '#273A07',
        align: props.align || 'right',
        padding: '0px',
      };
      break;
    case 'transparent':
      params = {
        ...props,
        background: 'rgba(0,0,0,0)',
        border: props.border || 'solid 1px #273A07',
        color: '#273A07',
      };
      break;
    case 'trans-divider':
      params = {
        ...props,
        background: '#F9EED8',
        hoverBackground: 'rgb(224, 213, 195)',
        border: props.border || 'solid 1px #273A07',
        color: '#273A07',
        divider: true,
        round: '8px',
      };
      break;
    case 'dot':
      params = {
        ...props,
        background: props.active ? '#B34104' : '#273A07',
        hoverBackground: props.active ? '#B34104' : '#273A07',
        width: props.width || props.height || '20px',
        height: props.width || props.height || '20px',
        round: `50%`,
        margin: '5px',
        padding: '0px',
        border: 'none',
      };
      break;
    case 'lightDot':
      params = {
        ...props,
        background: props.active ? '#B34104' : 'rgba(255, 255, 255, 0.75)',
        hoverBackground: props.active ? '#B34104' : 'rgba(255, 255, 255, 0.75)',
        width: props.width || props.height || '20px',
        height: props.width || props.height || '20px',
        round: `50%`,
        margin: '5px',
        padding: '0px',
        border: 'none',
      };
      break;
    case 'profile-select':
      params = {
        ...props,
        background: '#999C9B',
        hoverBackground: '#999C9B',
        foreground: '#FCEDD6',
        hoverForeground: '#FCEDD6',
        margin: '5px',
        bold: true,
        border: 'none',
        width: '130px',
      };
      break;

    case 'profile-remove':
      params = {
        ...props,
        background: '#3F566E',
        hoverBackground: '#3F566E',
        foreground: '#FCEDD6',
        hoverForeground: '#FCEDD6',
        margin: '5px',
        bold: true,
        border: 'none',
        width: '130px',
      };
      break;
    case 'activity':
      params = {
        ...props,
        background: '#F9EED8',
        hoverBackground: '#F2A93B',
        foreground: '#273A07',
        hoverForeground: '#273A07',
        margin: '0px',
        padding: '0px',
        bold: true,
        border: 'none',
        width: '130px',
        divider: true,
      };
      break;

    default:
      params = { ...props };
      break;
  }
  return params;
};

const HubButton = (props) => {
  const { title, onClick, style, icon, className, ...rest } = props;
  let params = getProps(props);

  return (
    <Button
      className={className}
      onClick={(e) => onClick && onClick(e)}
      {...params}
      style={{ ...style }}
      {...rest}
    >
      {icon}
      {params.divider && <br />}
      {title ? (
        <span dangerouslySetInnerHTML={{ __html: title ?? '' }}></span>
      ) : (
        props.children
      )}
    </Button>
  );
};

export default HubButton;
