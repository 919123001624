import { Skeleton } from "@mui/material";

function CardSkeleton({ height = 330 }) {
  return (
    <Skeleton
      variant="rounded"
      animation="wave"
      height={height}
      sx={{ width: 1 }}
    />
  );
}

export default CardSkeleton;
