export function crossCountrySkiing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      className={outerLayer}
    >
      <g>
        <g>
          <path
            className={className}
            d="M71.14,28.183c3.266-0.256,5.716-3.146,5.456-6.447c-0.26-3.308-3.13-5.771-6.396-5.514    c-3.272,0.257-5.712,3.136-5.453,6.445C65.008,25.967,67.869,28.439,71.14,28.183z"
          ></path>
          <path
            className={className}
            d="M94.185,77H92v1.57c0,1.228-1.235,1.43-1.235,1.43H66.702l14.847-41.936c1.361-1.395,2.356-2.259,2.356-2.259    c1.165-1.132,1.191-2.909,0.059-4.074c-1.132-1.165-2.994-1.149-4.158-0.017l-6.948,6.774l-4.716-8.274    c-0.01-0.024-0.015-0.031-0.015-0.031c-0.081-0.194-0.178-0.376-0.285-0.555c-0.923-0.358-1.78-0.89-2.524-1.581    c-0.312-0.29-0.597-0.603-0.856-0.935c-0.272-0.09-0.55-0.164-0.83-0.225l-13.863-4.121c0,0-2.365-0.83-3.832,1.48    c-0.866,1.364-4.929,12.332-4.929,12.332c-0.314,0.754-0.29,1.564,0.001,2.262L4.133,51.16l0.435,1.302l37.308-12.465    c0.212,0.171,0.45,0.316,0.714,0.426c1.499,0.625,3.221-0.085,3.845-1.584l3.718-9.603l5.545,1.912    c-1.06,1.219-1.978,2.27-1.978,2.27l-8.007,9.43c-1.142,1.39-1.66,2.887-1.511,4.774c0.143,1.818,0.987,3.39,2.273,4.478    l14.02,12.223l-3.738,12.271C56.241,77.864,56.499,79,57.379,80H4v2h87.612C94.707,81.465,94.185,77,94.185,77z M66.894,59.736    L56.89,48.967l9.06-10.722l4.462,6.972c0,0,1.426,2.02,3.683,0.475c0.646-0.442,2.967-3.073,5.189-5.321L65.247,80h-2.378    c0.087,0,0.17,0.127,0.247,0.012c0.146-0.23,0.265-0.305,0.364-0.549l4.554-14.894C68.034,64.57,68.927,61.732,66.894,59.736z"
          ></path>
          <path
            className={className}
            d="M29.177,79.507c0.248-0.114,0.481-0.253,0.696-0.404l11.976-10.082c0,0,4.536-7.609,7.065-12.194l-3.718-3.241    c-1.259-1.07-2.155-2.461-2.618-4.024l-6.865,15.069l-10.009,8.546c-1.387,0.97-1.933,2.804-1.264,4.419    C25.223,79.441,27.347,80.299,29.177,79.507z"
          ></path>
        </g>
      </g>
    </svg>
  );
}