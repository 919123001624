export const svgInvitation = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45.005"
      viewBox="0 0 45 45.005"
    >
      <g id="Layer_1" transform="translate(0 -0.344)">
        <path
          className={className}
          id="Path_326"
          data-name="Path 326"
          d="M53.435,52.342V27.23a1.091,1.091,0,0,0-.092-.438l-.032-.06a1.033,1.033,0,0,0-.081-.141l-.054-.065-.1-.1-.032-.032-5.77-4.774V14.693a1.082,1.082,0,0,0-1.083-1.083H37.6L31.641,8.679a1.081,1.081,0,0,0-1.38,0l-5.982,4.931H15.689a1.082,1.082,0,0,0-1.083,1.083v6.923L8.83,26.4l-.032.032-.1.1-.054.065a1.033,1.033,0,0,0-.081.141l-.032.06a1.082,1.082,0,0,0-.092.438V52.353a1.083,1.083,0,0,0,1.083,1.083H52.352a1.083,1.083,0,0,0,1.083-1.093ZM50.6,27.187l-3.335,2.485V24.437ZM30.938,10.9l3.248,2.707h-6.5ZM45.1,15.776V31.285L30.938,41.846,16.771,31.285V15.776Zm-30.5,8.661v5.245L11.272,27.2Z"
          transform="translate(-8.435 -8.086)"
          fill={color}
        />
        <g id="Group_463" data-name="Group 463" transform="translate(0.124)">
          <path
            className={className}
            id="Path_327"
            data-name="Path 327"
            d="M54.034,34.967,46.6,46.412l-3.828-5.88-.917,1.412,4.746,7.291,9.263-14.269Z"
            transform="translate(-24.333 -21.31)"
            fill={color}
          />
          <path
            className={className}
            id="Path_328"
            data-name="Path 328"
            d="M43.351,26.973,34.088,41.241h1.835L43.353,29.8l3.828,5.88.917-1.412Z"
            transform="translate(-21.088 -17.973)"
            fill={color}
          />
          <path
            className={className}
            id="Path_329"
            data-name="Path 329"
            d="M47.876,37.391l.8,2.5,2.146.938-2.146.938-.8,2.5-.8-2.5-2.146-.938,2.146-.938Z"
            transform="translate(-25.613 -22.323)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};