import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { postItinerary } from 'actions/itinerary_actions';
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
  PermissionPicker,
  TagsPicker,
  SquareBlock,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
const BlockFileViewer = ({
  inspirations,
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
}) => {
  const [inputValues, setInputValues] = useState({
    hub_share_rid: null,
    block_rid: null,
    itinerary_rid: null,
    share_start_date: null,
    share_end_date: null,
    share_description: null,
    share_permission_rid: null,
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');
  const [blockFilePic, setBlockFilePic] = useState(null);
  const [domainLink, setDomainLInk] = useState({
    url: '',
    domain: '',
    screenShot: '',
  });

  const handleLink = useCallback((event) => {
    const { name, value } = event.target;
    setDomainLInk({ ...domainLink, [name]: value });
  });
  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const triggerPersistData = () => {
    persistData(inputValues);
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    if (startDate || endDate) {
      inputValues.start_date = startDate;
      inputValues.end_date = endDate;
    }
    postItinerary(adventure_rid, inputValues);
  };

  const renderLodestar = () => (
    <div className="mod-share-lodestar-wrapper">
      <TextArea
        label="Summary"
        name="share_desription"
        onChangeHandler={handleOnChange}
        value={inputValues.share_desription}
      />
      <PermissionPicker spacingTop="10px" />
      <TagsPicker spacingTop="10px" spacingBottom="10px" />
    </div>
  );

  const renderSocialMedia = () => (
    <div className="mod-share-wrapper">
      <TextArea
        label="Summary"
        name="share_desription"
        onChangeHandler={handleOnChange}
        value={inputValues.share_desription}
      />
      <div className="mod-share-socmed-icon-wrapper">
        <SquareBlock type={1} icon="instagram" bottomText="Instagram" />
        <SquareBlock type={1} icon="facebook" bottomText="Facebook" />
        <SquareBlock type={1} icon="linkedIn" bottomText="LinkedIn" />
        <SquareBlock type={1} icon="twitter" bottomText="Twitter" />
      </div>
    </div>
  );

  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="SHARE" />

        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'LODESTAR'}
                text="LODESTAR"
                type={2}
              />
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'SOCIAL MEDIA'}
                text="SOCIAL MEDIA"
                type={2}
              />
            </div>
            {topLabel === 'LODESTAR' ? renderLodestar() : renderSocialMedia()}

            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlockFileViewer;
