export function hotel (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      className={outerLayer}
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 80 80"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M75.954,21.896 C75.472,21.896 74.982,21.809 74.505,21.625 L40,8.377 L5.495,21.625 C3.409,22.427 1.07,21.385 0.27,19.299 C-0.531,17.215 0.511,14.875 2.596,14.075 L38.55,0.269 C39.483,-0.09 40.516,-0.09 41.45,0.269 L77.404,14.075 C79.489,14.875 80.531,17.215 79.73,19.299 C79.112,20.909 77.579,21.896 75.954,21.896 z"
          fill="#000000"
        />
        <g>
          <path
            className={className}
            d="M24.074,38.35 C24.074,42.537 20.679,45.933 16.491,45.933 C12.303,45.933 8.908,42.537 8.908,38.35 C8.908,34.162 12.303,30.767 16.491,30.767 C20.679,30.767 24.074,34.162 24.074,38.35 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M30.647,56.803 L12.447,56.803 C10.213,56.803 8.403,54.992 8.403,52.759 C8.403,50.526 10.214,48.715 12.447,48.715 L26.603,48.715 L26.603,34.559 C26.603,32.325 28.414,30.515 30.647,30.515 C32.881,30.515 34.692,32.326 34.692,34.559 L34.692,52.759 C34.692,54.993 32.881,56.803 30.647,56.803 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M71.092,47.577 L71.092,56.803 L37.472,56.803 L37.472,38.35 L58.579,38.35 C65.531,38.35 71.092,39.867 71.092,47.577 z"
            fill="#000000"
          />
        </g>
        <path
          className={className}
          d="M72.807,69.948 L7.193,69.948 C4.959,69.948 3.149,68.136 3.149,65.903 C3.149,63.67 4.96,61.86 7.193,61.86 L72.807,61.86 C75.041,61.86 76.851,63.67 76.851,65.903 C76.851,68.136 75.041,69.948 72.807,69.948 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}