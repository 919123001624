
import merge from 'lodash/merge';
import {RECEIVE_BLOCK_VOTES_ALL} from '../../constants'
const VotesReducer = (state = { adventureData: [] }, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_BLOCK_VOTES_ALL:
      newState["all_block_votes"] = action.data.blockVoters
      return newState

    default:
            return state;
    }
}


export default VotesReducer