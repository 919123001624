import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 *
 * @param {string} rootPublicationType
 * @returns
 */
function useNavMenuGroup(rootPublicationType) {
  return useQuery({
    queryKey: ['menu-item', rootPublicationType],
    queryFn: () =>
      axios
        .get(`/api/cms/nav_menu_items/${rootPublicationType}`)
        .then((res) => res.data),
  });
}

export default useNavMenuGroup;
