import React from 'react';
import { Title } from '../../modal_modules';
import { ChatInput, ChatMessages } from './modules';
import { mapStateToProps, mapDispatchToProps } from './container';
import { connect } from 'react-redux';
const ChatModal = ({ closeModal, receivingUser, sendingUser, ...rest }) => {
  return (
    <div className="mod-type1-wrapper" id="cre-ad" style={{ height: '460px' }}>
      <div className="mod-type1-padder chat-modal">
        <Title closeModal={closeModal} title="CHAT" />

        <div className="mod-cnt-def">
          <div className="chat-info-container">
            <img
              src={receivingUser.ui_image_location}
              className="chat-user-img"
            />
            <span>
              Chatting with: {receivingUser.first_name}{' '}
              {receivingUser.last_name}
            </span>
          </div>
          <ChatMessages />
          <ChatInput />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
