import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { postItinerary } from 'actions/itinerary_actions';
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
const BlockFileViewer = ({
  inspirations,
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
}) => {
  const [inputValues, setInputValues] = useState({
    block_file_rid: null,
    block_rid: null,
    domain_url: null,
    file_location: null,
    file_name: null,
    file_note: null,
    full_url: null,
    media_type: null,
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');
  const [blockFilePic, setBlockFilePic] = useState(null);
  const [domainLink, setDomainLInk] = useState({
    url: '',
    domain: '',
    screenShot: '',
  });

  const handleLink = useCallback((event) => {
    const { name, value } = event.target;
    setDomainLInk({ ...domainLink, [name]: value });
  });
  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const triggerPersistData = () => {
    persistData(inputValues);
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    if (startDate || endDate) {
      inputValues.start_date = startDate;
      inputValues.end_date = endDate;
    }
    postItinerary(adventure_rid, inputValues);
  };

  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="SHARE TAGS" />

        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlockFileViewer;
