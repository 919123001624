import React, { useEffect, useState } from 'react';

import { Input } from 'components/global_modal/modal_modules';
const General = ({
  values,
  onChangeHandler,
  saved,
  saveHandler,
  cancelHandler,
  signUpErrors,
}) => {
  const renderEmailErrors = () => {
    if (values.email) {
      if (values.email.length > 0) {
        return (
          <div className="email-error-container">
            {signUpErrors.emailErrors.length === 0 && (
              <div className="email-error-wrapper">
                {/* <img alt="alert-pic" src="/symbols/bluecheck.png" />
                <span>Valid Email</span> */}
              </div>
            )}
            {signUpErrors.emailErrors.length > 0 && (
              <div className="email-error-wrapper">
                <img alt="alert-pic" src={'/symbols/red-alert.png'} />
                <span>{signUpErrors.emailErrors}</span>
              </div>
            )}
          </div>
        );
      } else {
        // return (<div className="error-placeholder" />)
        return <div className="email-error-container" />;
      }
    } else {
      // return (<div className="error-placeholder" />)
      return <div className="email-error-container" />;
    }
  };

  const renderUsernameErrors = () => {
    if (values.username) {
      if (
        values.username.length > 0 &&
        signUpErrors.usernameError !== 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container">
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.usernameError === 'Username already exists'
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else if (
        values.username.length > 0 &&
        values.username.length < 5 &&
        signUpErrors.usernameError === 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container">
            <div className="email-error-wrapper">
              <img alt="alert-pic" src="/symbols/red-alert.png" />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else {
      }
    } else {
      return <div className="email-error-container" />;
    }
  };
  return (
    <div
      class="general-section-container"
      id="general-section-container-mobile"
    >
      <header className="general-header">NAME</header>
      <hr />
      <section className="account-info-contents-wrapper">
        <div className="general-section-body">
          <Input
            className="general-fist-name"
            label="first name"
            name="first_name"
            value={values.first_name}
            onChangeHandler={onChangeHandler}
            id="general"
          />
          <Input
            className="general-last-name"
            label="last name"
            name="last_name"
            value={values.last_name}
            onChangeHandler={onChangeHandler}
            id="general"
          />
          <div className="validated-input-wrappers">
            <Input
              parentClassName="general-user-name" // classname for div tag that wraps the input and label
              className="" // class name for the inputbar
              label="username" // placeholder
              name="username" // key
              value={values.username}
              onChangeHandler={onChangeHandler}
              id="general"
            />
            {renderUsernameErrors()}
          </div>
          <div className="validated-input-wrappers">
            <Input
              className="general-email"
              name="email"
              label="email"
              value={values.email}
              onChangeHandler={onChangeHandler}
              id="general"
            />
            {renderEmailErrors()}
          </div>
        </div>
        <div className="general-section-footer-container">
          <div className="general-section-footer">
            <button
              className="general-button general-button--cancel"
              onClick={cancelHandler}
            >
              Cancel
            </button>
            <button
              className="general-button general-button--save"
              onClick={saveHandler}
            >
              Save
            </button>
            {saved ? '' : <div className="general-not-saved">NOT SAVED</div>}
          </div>
        </div>
      </section>
    </div>
  );
};

export default General;
