import {
  openBlockModal,
  openCheckListModal,
  openFolderModal,
} from 'actions/modal_actions';
import category from 'components/global_modal/hub_modal_pages/create_edit_block/category';
import { useDispatch, useSelector } from 'react-redux';

const useEditActivityModalButton = () => {
  const userInfo = useSelector((state) => state?.session?.user);
  const { itinerary_info, itineraryDetails } = useSelector(
    (state) => state?.entities?.itinerary,
  );
  const adventure_rid = useSelector(
    (state) => state?.entities?.adventure?.selectedAdventure?.adventure_rid,
  );

  const dispatch = useDispatch();
  const openModal = async (block) => {
    let openType = {
      isEditAndView: true,
      isFolder: Boolean(block?.folder_flag),
      isListFolder: Boolean(block?.list_flag && block.folder_flag),
    };
    let [activityModalTypeRid, uiUpdatePayload] = returnActivityModalTypeRid(
      userInfo,
      block,
      adventure_rid,
      itinerary_info,
    );
    let blockType = {
      isItineraryItemBlock: true,
      isNestedItineraryItemBlock: Boolean(block.parent_block_rid),
    };
    let actionPayload = {
      itineraryItemRid: block.itinerary_item_rid,
      parentBlockRid:
        blockType.isNestedItineraryItemBlock && block.parent_block_rid,
      adventureRid: adventure_rid,
      itineraryInfo: itinerary_info,
    };

    if (block.folder_flag)
      return dispatch(
        openFolderModal(openType, blockType, actionPayload, block, userInfo),
      );
    block.list_flag
      ? dispatch(
          openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          ),
        )
      : dispatch(
          openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          ),
        );
    return;
  };

  return { openModal };
};

function returnActivityModalTypeRid(
  userInfo,
  block,
  adventure_rid,
  itinerary_info,
) {
  let uiQuery = {
    account_rid: userInfo.account_rid,
    adventure_rid:
      block?.adventure_block_rid || block.itinerary_block_rid
        ? adventure_rid
        : null,
    adventure_rid_exist_flag: adventure_rid ? adventure_rid : null,
    inspiration_rid: null,
    itinerary_item_rid: null,
    parent_block_rid: block.itinerary_item_rid ? null : block.parent_block_rid,
    explorer_rid: userInfo.explorer_rid,
    itineraryInfo: itinerary_info,
  };
  if (block.itinerary_block_rid) {
    if (block.parent_block_rid) {
      return ['13a', uiQuery];
    }
    return ['14a', uiQuery];
  }
  if (block?.adventure_block_rid) {
    if (block.parent_block_rid) {
      return ['12a', uiQuery];
    }
    return ['11a', uiQuery];
  }
  if (block.parent_block_rid) {
    return ['10a', uiQuery];
  }
  return ['9a', uiQuery];
}

export default useEditActivityModalButton;
