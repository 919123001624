import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@mui/icons-material/Close';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { makeStyles } from '@material-ui/core';
import TextBox from '../text-box';

const useStyles = makeStyles(() => ({
  imageList: {
    minHeight: '140px',
    display: 'flex',
    gap: '4px',
    marginTop: '4px',
    paddingBottom: '5px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px !important',
      height: '4px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#827700',
    },
    '&::-webkit-scrollbar-track': {
      position: 'relative',
      borderRight: '1px solid #273A07',
      borderBottom: '1px solid #273A07',
    }
  },
  imageItem: {
    position: 'relative',
    width: '50%',
    height: '100%',
    flexShrink: 0,
    boxSizing: 'border-box',
    outline: '1px solid #827700',
    outlineOffset: '-1px',
    borderRadius: '4px',
  },
  primaryImage: {
    outline: '2px solid #827700',
    outlineOffset: '-2px',
  },
  image: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
  },
  closeButton: {
    color: 'rgba(255, 255, 255, 0.54) !important',
    position: 'absolute !important',
    top: 2,
    right: 2,
  },
  uploadButton: {
    width: '40px !important',
    height: '100%',
    padding: '5px !important',
    minWidth: '40px !important',
    borderColor: '#827700 !important',
    color: '#827700 !important',
    border: '1px solid',
    borderRadius: '4px',
    '&:hover': {
      borderColor: '#827700 !important',
    }
  },
  captionButton: {
    padding: '5px',
    color: '#827700 !important',
  },
  captionText: {
    fontSize: '0.8rem !important',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    color: '#827700',
  },
}));

const ImageUpload = ({ onChange, defaultFiles }) => {
  const styles = useStyles();
  const [file, setFile] = useState(defaultFiles);

  useEffect(() => {
    setFile(defaultFiles);
  }, [defaultFiles])

  function uploadSingleFile(e) {
    const ImagesArray = Object.entries(e.target.files).map((e) => {
      const fileType = e[1].type;
      let type = 'image';
      if (fileType.startsWith('video/')) type = 'video';

      return { name: e[1].name, url: URL.createObjectURL(e[1]), primary: false, type };
    });

    const newFiles = [...ImagesArray, ...file];
    onChange(newFiles);
    setFile(newFiles);
  }

  function deleteFile(e) {
    const s = file.filter((_, index) => index !== e);
    onChange(s);
    setFile(s);
  }

  function makePrimary(id) {
    const newFiles = [...file];

    newFiles.forEach((item, index) => {
      if (index != id) item.primary = false;
      else item.primary = !item.primary;
    })

    onChange(newFiles);
    setFile(newFiles);
  }

  function onEdit(event, id) {
    event.stopPropagation();
    const newFiles = [...file];

    newFiles.forEach((item, index) => {
      if (index === id) item.isEdit = true;
    })

    onChange(newFiles);
    setFile(newFiles);
  }

  function onApply(event, id) {
    event.stopPropagation();
    const newFiles = [...file];

    newFiles.forEach((item, index) => {
      if (index === id) item.isEdit = false;
    })

    onChange(newFiles);
    setFile(newFiles);
  }

  function onChangeCaption(id, value) {
    const newFiles = [...file];

    newFiles.forEach((item, index) => {
      if (index === id) item.caption = value;
    })

    onChange(newFiles);
    setFile(newFiles);
  }

  return (
    <Box className={styles.imageList}>
      <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={uploadSingleFile}
          multiple
          accept="image/*, video/*"
        />
        <Button
          className={styles.uploadButton}
          variant="outlined"
          component="span"
        >
          <AddIcon />
        </Button>
      </label>
      {file?.map((item, index) => {
        return (
          <Box
            key={index}
            className={`${styles.imageItem} ${item.primary && styles.primaryImage}`}
            onClick={() => makePrimary(index)}
          >
            {item.type === 'video' ? <video className={styles.image} src={item.url} loading="lazy" controls={true} />
              : <img className={styles.image} src={item.url} loading="lazy" /> }
            <IconButton
              className={styles.closeButton}
              aria-label="info about"
              onClick={(e) => {
                e.stopPropagation();
                deleteFile(index)
              }}
            >
              <CloseIcon />
            </IconButton>
            <Stack direction="row" sx={{ paddingBottom: '4px', paddingLeft: '4px' }}>
              {!item.isEdit && (
                <Typography
                  className={styles.captionText}
                  onClick={(e) => e.stopPropagation()}
                >
                  {item.caption || "Add caption"}
                </Typography>
              )}
              {item.isEdit && (
                <TextBox
                  className="text-field"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onChangeCaption(index, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  value={item.caption}
                  size="small"
                />
              )}
              {!item.isEdit && (
                <IconButton className={styles.captionButton} onClick={(e) => onEdit(e, index)}>
                  <EditNoteOutlinedIcon />
                </IconButton>
              )}
              {item.isEdit && (
                <IconButton className={styles.captionButton} onClick={(e) => onApply(e, index)}>
                  <CheckCircleOutlineRoundedIcon />
                </IconButton>
              )}
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

ImageUpload.defaultProps = {
  onChange: () => { },
  defaultFiles: [],
}

export default ImageUpload;
