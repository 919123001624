export function backpack (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path
          fill={color}
          className={className}
          d="M27.43519,48.04508c0.14242,0,0.2623-0.11988,0.2623-0.26229v-4.98361c0-0.14242-0.11988-0.26229-0.2623-0.26229h-2.0251   h-5.71772h-2.0251c-0.14703,0-0.26229,0.11527-0.26229,0.26229v4.98361c0,0.14703,0.11526,0.26229,0.26229,0.26229h2.0251h5.71772   H27.43519z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M18.90548,49.61885h-1.23822c-1.01229,0-1.83607-0.82377-1.83607-1.83606v-4.98361   c0-1.01229,0.82377-1.83606,1.83607-1.83606h1.23822v-4.91035h-0.92367c-2.7295,0-5.35302-1.35778-6.93494-3.5794v29.30533   c0,2.60348,2.12244,4.72131,4.73156,4.72131h3.13729V52.22746c-0.00115-0.03426-0.01025-0.06634-0.01025-0.10092V49.61885z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M45.79585,55.11629c-1.64907,0-2.99026-1.34119-2.99026-2.98975v-2.50769h-1.24846   c-1.01229,0-1.83607-0.82377-1.83607-1.83606v-4.98361c0-1.01229,0.82377-1.83606,1.83607-1.83606h1.24846v-4.91035H26.19698   v4.91035h1.23822c1.0123,0,1.83607,0.82377,1.83607,1.83606v4.98361c0,1.01229-0.82377,1.83606-1.83607,1.83606h-1.23822v2.50769   c0,1.64856-1.34119,2.98975-2.99027,2.98975h-1.30072c-0.51499,0-0.99264-0.14184-1.4165-0.37045V66.5h28.03176V54.74456   c-0.42296,0.22938-0.89978,0.37173-1.41393,0.37173H45.79585z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M21.90599,53.54252h1.30072c0.78125,0,1.4165-0.63524,1.4165-1.41598v-2.50769H20.4895v2.60835   C20.54265,52.9603,21.15388,53.54252,21.90599,53.54252z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M14.48233,2.5H9.28458c-1.39447,0-2.52869,1.12961-2.52869,2.51793v10.09324c0,1.38832,1.13422,2.51793,2.52869,2.51793   c0.11363,0,5.42669,0,5.19775,0V2.5z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M3.41931,43.41547v12.92521c0,2.05738,1.67674,3.73156,3.73719,3.73156h2.31352V39.67264H7.15651   C5.09606,39.67264,3.41931,41.35143,3.41931,43.41547z"
        ></path>
        <rect
          fill={color}
          className={className}
          x="48.59145"
          y="2.50512"
          width="4.33299"
          height="15.12398"
        ></rect>
        <rect
          fill={color}
          className={className}
          x="21.97362"
          y="2.5"
          width="25.04406"
          height="15.1291"
        ></rect>
        <rect
          fill={color}
          className={className}
          x="16.0561"
          y="2.50512"
          width="4.34375"
          height="15.12398"
        ></rect>
        <path
          fill={color}
          className={className}
          d="M24.62321,27.21875c0-1.13935-0.92725-2.0666-2.0666-2.0666c-1.1501,0-2.07736,0.92725-2.07736,2.0666v8.04713v5.69724   h4.14396v-5.69724V27.21875z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M11.04688,19.20287v8.35195c0,3.81814,3.11117,6.92418,6.93494,6.92418h0.92367v-7.26025   c0-2.00717,1.6332-3.64037,3.64037-3.64037c2.01793,0,3.65113,1.6332,3.65113,3.64037V34.479h16.60861v-7.26025   c0-2.00717,1.6332-3.64037,3.64088-3.64037c2.01793,0,3.65113,1.6332,3.65113,3.64037V34.479h0.92316   c3.82377,0,6.93494-3.10604,6.93494-6.92418v-8.35195C44.10238,19.20986,34.20806,19.20484,11.04688,19.20287z M39.67341,28.76639   c0,1.01229-0.82377,1.83607-1.83607,1.83607H31.1647c-1.01229,0-1.83607-0.82377-1.83607-1.83607v-4.10246   c0-1.01229,0.82377-1.83607,1.83607-1.83607h6.67264c1.01229,0,1.83607,0.82377,1.83607,1.83607V28.76639z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M44.37936,52.12654c0,0.78074,0.63524,1.41598,1.41649,1.41598h1.31148c0.77677,0,1.4067-0.62871,1.41393-1.4033v-2.52037   h-4.1419V52.12654z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M37.83735,24.40164H31.1647c-0.14703,0-0.26229,0.11526-0.26229,0.26229v4.10246   c0,0.14242,0.11988,0.26229,0.26229,0.26229h6.67264c0.14242,0,0.26229-0.11988,0.26229-0.26229v-4.10246   C38.09964,24.5169,37.98438,24.40164,37.83735,24.40164z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M61.84349,39.67264h-2.31353v20.39959h2.31353c2.06045,0,3.73719-1.67418,3.73719-3.73156V43.41547   C65.58069,41.35143,63.90395,39.67264,61.84349,39.67264z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M62.22618,15.11117V5.01793c0-1.38832-1.13422-2.51793-2.52869-2.51793h-5.19928v15.1291c0.19426,0,5.48185,0,5.19928,0   C61.09196,17.6291,62.22618,16.49949,62.22618,15.11117z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M51.58735,47.78279v-4.98361c0-0.14242-0.11988-0.26229-0.26229-0.26229h-2.01434h-5.71824h-2.03535   c-0.14242,0-0.26229,0.11988-0.26229,0.26229v4.98361c0,0.14242,0.11988,0.26229,0.26229,0.26229h2.03535h5.71824h2.01434   C51.46747,48.04508,51.58735,47.92521,51.58735,47.78279z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M48.52382,27.21875c0-1.13935-0.92725-2.0666-2.06711-2.0666c-1.1501,0-2.07735,0.92725-2.07735,2.0666v8.04713v5.69724   h4.14447v-5.69724V27.21875z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M51.02075,36.05276h-0.92316v4.91035h1.22746c1.01229,0,1.83607,0.82377,1.83607,1.83606v4.98361   c0,1.01229-0.82377,1.83606-1.83607,1.83606h-1.22746v2.50769c0,0.00864-0.00249,0.01671-0.00256,0.02536V66.5h3.1291   c2.60912,0,4.73156-2.11783,4.73156-4.72131V32.47336C56.39278,34.66829,53.77216,36.05276,51.02075,36.05276z"
        />
      </g>
    </svg>
  );
}