import { STOP_MODAL, IMPACT_MODAL } from '../constants';

const recieveModal = (type, data) => ({
  type,
  data,
});

export const useImpactModal = (data) => (dispatch) =>
  dispatch(IMPACT_MODAL, data);

export const closeModal = () => (dispatch) => dispatch(STOP_MODAL);
