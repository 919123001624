import React, { useState, useEffect, Fragment } from 'react';
import {
  Container,
  ProgressBar,
  VerticalContainer,
  VerticalProgressBar,
} from './style_components';
const ScrollIndicator = ({
  documentTargeted,
  direction,
  position,
  trackContainerColor,
  progressBarColor,
}) => {
  const [scroll, setScroll] = useState(0);

  const onScroll = () => {
    const Scrolled = documentTargeted.scrollLeft;
    const MaxHeight =
      documentTargeted.scrollWidth - documentTargeted.clientWidth;
    const ScrollPercent = (Scrolled / MaxHeight) * 100;
    setScroll(ScrollPercent);
  };
  const onScrollVertical = () => {
    const Scrolled = documentTargeted.scrollTop;
    const MaxHeight =
      documentTargeted.scrollHeight - documentTargeted.clientHeight;
    const ScrollPercent = (Scrolled / MaxHeight) * 100;
    setScroll(ScrollPercent);
  };

  useEffect(() => {
    if (documentTargeted && direction !== 'vertical') {
      documentTargeted.addEventListener('scroll', onScroll);
      const Scrolled = documentTargeted.scrollLeft;
      const MaxHeight =
        documentTargeted.scrollWidth - documentTargeted.clientWidth;
      const ScrollPercent = (Scrolled / MaxHeight) * 100;
      setScroll(ScrollPercent);
    } else if (documentTargeted && direction === 'vertical') {
      documentTargeted.addEventListener('scroll', onScrollVertical);
      const Scrolled = documentTargeted.scrollTop;
      const MaxHeight =
        documentTargeted.scrollHeight - documentTargeted.clientHeight;
      const ScrollPercent = (Scrolled / MaxHeight) * 100;
      setScroll(ScrollPercent);
    }
    return () => {
      documentTargeted.removeEventListener('scroll', onScroll);
      documentTargeted.removeEventListener('scroll', onScrollVertical);
    };
  }, [documentTargeted]);

  return (
    <Fragment>
      {direction === 'vertical' ? (
        <VerticalContainer
          position={position}
          backgroundColor={trackContainerColor}
        >
          <VerticalProgressBar
            position={position}
            backgroundColor={progressBarColor}
            style={{ height: `${scroll + 15}%` }}
          />
        </VerticalContainer>
      ) : (
        <Container>
          <ProgressBar style={{ width: `${scroll + 20}%` }}></ProgressBar>
        </Container>
      )}
    </Fragment>
  );
};

export default ScrollIndicator;
