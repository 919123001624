import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchStates: () => dispatch(fetchStates()),
  fetchCountries: () => dispatch(fetchCountries()),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  return { userInfo, currentExplorerDetails, countries, statesProvences };
};
function pause(size = '200px') {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 473.931 473.931"
      enableBackground="new 0 0 473.931 473.931;"
      xmlSpace="preserve"
    >
      <circle fill="#F28400" cx="236.966" cy="236.966" r="236.966" />
      <path
        fill="#ECA448;"
        d="M324.149,147.755c0-6.514-5.283-11.801-11.802-11.801h-45.646c-6.526,0-11.809,5.287-11.809,11.801
	v116.713l69.256-69.26V147.755z"
      />
      <g>
        <path
          fill="#FFFFFF;"
          d="M324.149,326.151c0,6.518-5.283,11.802-11.802,11.802h-45.646c-6.526,0-11.809-5.283-11.809-11.802
		V147.755c0-6.514,5.283-11.801,11.809-11.801h45.646c6.518,0,11.802,5.287,11.802,11.801L324.149,326.151L324.149,326.151z"
        />
        <path
          fill="#FFFFFF;"
          d="M219.016,326.151c0,6.518-5.283,11.802-11.802,11.802h-45.657c-6.518,0-11.802-5.283-11.802-11.802
		V147.755c0-6.514,5.283-11.801,11.802-11.801h45.653c6.518,0,11.801,5.287,11.801,11.801v178.396H219.016z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
const ComingSoonPage = (props) => {
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    fetchCountries();
    fetchStates();
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  const [pauseOptions, setPauseOptions] = useState(null);
  const handleToggleSelect = useCallback((event) => {
    const { value } = event.target;
    if (pauseOptions !== value) {
      setPauseOptions(value);
    }
  });
  // const [objectProfile, setObjectProfile] = useState({
  // })

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  const options = ['One month', 'Three months', 'Six months'];
  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <div className="CancelSubscribePage">
            <div className="CancelSubscribePage_nav">
              PAUSE YOUR SUBSCRIPTION
            </div>
            <div className="CancelSubscribePage__body3">
              <div className="CancelSubscribePage__imgcont">{pause()}</div>
              <div className="CancelSubscribePage__header3">
                Pause Your Subscription
              </div>
              <div className="CancelSubscribePage__body3__font1">
                Sometimes, you just need a break.
              </div>
              <div className="pause-subscription-options-wrapper">
                {options.map((ele) => (
                  <Item>
                    <RadioButton
                      type="radio"
                      name="radio"
                      value={ele}
                      checked={pauseOptions === ele}
                      onChange={handleToggleSelect}
                    />
                    <RadioButtonLabel />
                    <span className="toggle-span">{ele}</span>
                  </Item>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonPage);
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  position: relative;
  flex-direction: row;
`;
const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 9px;
      height: 9px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    `
  &:checked + ${RadioButtonLabel} {
    background: #09132b;
    border: 1px solid #09132b;
    &::after{
      content: "";
      display: block;
      border-radius: 50%;
      width: 9px;
      height: 9px;
      margin: 6px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  `}
`;
