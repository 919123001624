import React, { useRef, useEffect } from 'react';
import { TextArea, Comment } from '../../modal_modules';
import { svgThumbsDown, svgThumbsUp } from 'components/svg_module';
import {
  deleteBlockComment,
  deleteAdventureBlockComment,
} from 'actions/comment_actions';
import { connect } from 'react-redux';
const mapDispatchToProps = (dispatch) => ({
  deleteBlockComment: (itinerary_block_rid, explorer_comment_rid) =>
    dispatch(deleteBlockComment(itinerary_block_rid, explorer_comment_rid)),
  deleteAdventureBlockComment: (
    adventure_block_rid,
    adventure_block_comment_rid,
  ) =>
    dispatch(
      deleteAdventureBlockComment(
        adventure_block_rid,
        adventure_block_comment_rid,
      ),
    ),
});
const Comments = ({
  currentBlock,
  blockComments,
  comments,

  handleOnChangeComments,
  newBlock,
  deleteBlockComment,
  deleteAdventureBlockComment,
}) => {
  let block = newBlock ? newBlock : currentBlock;
  let bottomRef = useRef(null);
  // useEffect(() => {
  //   if (bottomRef) {
  //     bottomRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  const deleteHandler = (comment) => {
    if (currentBlock.itineriary_block_rid) {
      deleteBlockComment(
        currentBlock.itinerary_block_rid,
        comment.explorer_comment_rid,
      );
    } else {
      deleteAdventureBlockComment(
        currentBlock.adventure_block_rid,
        comment.adventure_block_comment_rid,
      );
    }
  };
  return (
    <div className="comments-wrapper">
      <div className="comment-view-wrapper">
        {/* <div className="comment-thumbs-wrapper">
          <div className="comment-thumbs-content">
            {svgThumbsUp(
              "",
              "block-modal-comment-svg",
              "block-modal-comment-svg-wrapper"
            )}
            <span>{block.total_vote_count_up}</span>
          </div>
          <div className="comment-thumbs-content">
            {svgThumbsDown(
              "",
              "block-modal-comment-svg",
              "block-modal-comment-svg-wrapper"
            )}
            <span>{block.total_vote_count_down}</span>
          </div>
        </div> */}
        <div className="comments-comment-wrapper">
          {blockComments &&
            blockComments.map((ele, idx) => {
              return (
                <Comment
                  comment={ele}
                  deleteHandler={() => deleteHandler(ele)}
                  key={`adventure-commet-${ele.adventure_block_comment_rid}`}
                />
              );
            })}
        </div>
      </div>

      <TextArea
        label="Comment.."
        name="explorer_comment"
        onChangeHandler={handleOnChangeComments}
        value={comments.explorer_comment}
        spacingTop="10px"
        spacingBottom="20px"
      />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Comments);
