export function wildlifeViewing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      className={outerLayer}
    >
      <path
        className={className}
        d="M19.139,50.83c-5.768-3.415-7.668-10.851-4.258-16.607c3.415-5.763,10.847-7.67,16.614-4.255  c5.756,3.411,7.662,10.848,4.248,16.611C32.334,52.335,24.895,54.242,19.139,50.83z"
      ></path>
      <path
        className={className}
        d="M83.229,33.286l-2.337-5.507c10.824-2.983,6.268-17.37,8.313-19.756c1.015-1.777,4.485-2.498,4.865-2.498  c-1.902-1.523-4.488-3.317-8.049-3.171c-5.708,0.235-7.225,6.43-8.62,9.729c-4.062,6.727-20.244,1.968-20.244,1.968  s6.127,12.53,17.024,14.113l2.172,5.12h-8.832v3.417H40.884v6.8h4.332v20.953c0,0-23.933-9.377-23.989-9.396  c-3.029-1.153-7.302-1.208-10.646,1.097C0.185,63.679-0.784,83.161,2.392,96.436h35.532l-6.271-22.74l-11.071-4.304  c-0.531-0.205-0.793-0.802-0.587-1.331c0.205-0.531,0.805-0.794,1.332-0.587L48.865,78.2c0.675,0.264,1.383,0.395,2.088,0.395  c1.137,0,2.269-0.34,3.236-1c1.565-1.068,2.501-2.842,2.501-4.738V43.502h10.831v3.416h31.721V33.286H83.229z M85.653,5.038  c0.727,0,1.319,0.591,1.319,1.32c0,0.728-0.593,1.319-1.319,1.319c-0.73,0-1.32-0.591-1.32-1.319  C84.333,5.629,84.923,5.038,85.653,5.038z M78.459,33.286l-2.105-4.959c0.032,0,0.062,0.003,0.096,0.003  c0.904,0,1.729-0.062,2.504-0.163l2.172,5.119H78.459z"
      ></path>
    </svg>
  );
}