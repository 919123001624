import React from 'react';

const LabelTextArea = ({
  onChangeHandler,
  name,
  value,
  label,
  spacingTop,
  parentClassName,
  className,
  spacingBottom,
  id,
  ...rest
}) => {
  return (
    <div
      className={`mod-fl-ta-wrapper ${parentClassName && parentClassName}`}
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
      id={id}
    >
      <textarea
        id={id}
        label={label}
        onChange={onChangeHandler}
        name={name}
        value={value || ''}
        type="textarea"
        className={`mod-fl-ta ${className && className}`}
        placeholder={label}
      />
    </div>
  );
};

export default LabelTextArea;
