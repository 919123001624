import { ButtonBase } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { useState } from 'react';
import useDeleteItineraryItemModalButton from '../hooks/use-delete-itinerary-day-modal-button';
import useEditItineraryDayJournalModalButton from '../hooks/use-edit-itinerary-day-journal-modal-button';
import { JournalIcon } from './edit-itinerary-day-journal-modal-button';

/**
 * Unstyled wrapper for Itinerary Item Menu Functionality
 * @param {object} props
 * @param {?} props.itineraryItemData
 * @param {boolean} [props.hideDelete]
 * @param {React.ReactNode} props.children
 */
const ItineraryItemActionMenuBase = ({
  itineraryItemData,
  hideDelete = false,
  children,
}) => {
  const { openModal: openDeleteModal } = useDeleteItineraryItemModalButton(
    itineraryItemData,
  );
  const { openModal: openJournalModal } = useEditItineraryDayJournalModalButton(
    itineraryItemData,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    openDeleteModal();
  };

  const handleJournal = () => {
    handleClose();
    openJournalModal();
  };

  const id = `itinerary-item-action-menu-${itineraryItemData.itinerary_item_rid}`;
  return (
    <>
      <ButtonBase
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {children}
      </ButtonBase>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleJournal} disableRipple>
          <JournalIcon />
          Journal
        </MenuItem>
        {!hideDelete && (
          <MenuItem onClick={handleDelete} disableRipple>
            <DeleteIcon />
            Delete
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default ItineraryItemActionMenuBase;
