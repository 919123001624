export function tree (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <g>
          <path
            className={className}
            fill={color}
            d="M58.57001,52.27499c-0.12,0.27002-0.39001,0.45001-0.67999,0.45001H11.12c-0.29999,0-0.56-0.17999-0.67999-0.45001    c-0.13-0.27002-0.07996-0.58002,0.12-0.81l11.14001-12.40997h-7.28998c-0.29004,0-0.54999-0.17004-0.67999-0.42999    c-0.12-0.27002-0.08002-0.58002,0.10999-0.80005l10.60999-12.52997h-5.54999c-0.28003,0-0.53998-0.16003-0.66998-0.41003    c-0.12-0.25-0.10004-0.56,0.07001-0.78998L33.90002,2.83499c0.15002-0.20001,0.37-0.31,0.60999-0.31s0.46002,0.12,0.60004,0.31    L50.71002,24.095c0.16998,0.22998,0.19,0.53998,0.06,0.78998s-0.38,0.41003-0.66998,0.41003H44.56l10.60004,12.52997    c0.19,0.22003,0.23999,0.53003,0.10999,0.80005c-0.12,0.25995-0.39001,0.42999-0.67999,0.42999H47.31L58.44,51.465    C58.64001,51.69498,58.69,52.00497,58.57001,52.27499z"
          ></path>
        </g>
        <g>
          <path
            className={className}
            fill={color}
            d="M66.5,65.72501c0,0.41998-0.32996,0.75-0.75,0.75H3.25c-0.40997,0-0.75-0.33002-0.75-0.75    c0-0.40997,0.34003-0.75,0.75-0.75h22.24005l2.21997-10.75h13.58002l2.21997,10.75H65.75    C66.17004,64.97501,66.5,65.31503,66.5,65.72501z"
          ></path>
        </g>
      </g>
    </svg>
  );
}