export function sunBed (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M16.69,4.19004l0.96997,2.20001C17.13,6.46006,16.62,6.60001,16.13,6.81004c-0.48999,0.22003-0.94,0.5-1.34998,0.84998   L13.81,5.46006c-0.34998-0.80005,0.02002-1.73004,0.81-2.08002C15.42488,3.03502,16.35549,3.40057,16.69,4.19004z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M36.35999,49.02006h-3.44l-11.09998-25.31c1.27997,0.48999,2.35999,1.46997,2.95001,2.81   c-0.59003-1.35004-0.57001-2.81-0.07001-4.09003L36.35999,49.02006z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M40.04999,61.35001c-0.79999,0.35004-1.71997-0.00995-2.07001-0.81l-1.31-3h3.42999l0.76001,1.74005   C41.21002,60.07005,40.84998,61.00004,40.04999,61.35001z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M12.78998,57.54002l-3.50995,7.17004C8.83002,65.63004,7.72998,66.01005,6.81,65.56004   c-0.65002-0.32001-1.02997-0.97998-1.02997-1.65997c0-0.27002,0.04999-0.54004,0.17999-0.80005l2.71997-5.56H12.78998z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M62.19,65.56004c-0.26001,0.13-0.53998,0.19-0.82001,0.19c-0.67999,0-1.33002-0.39001-1.64996-1.03998l-3.51001-7.17004   h4.10999l2.71997,5.56C63.47998,64.01005,63.09998,65.12003,62.19,65.56004z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M63.90997,50.52006h-42.37l-9.81-14.77002c-0.57001-0.84998-1.70996-1.09003-2.56-0.53003l-1.52997,1.01001   c-0.85004,0.57001-1.09003,1.71002-0.53003,2.55005l2.8197,4.24945L5.1311,50.52006H5.09998   c-1.01996,0-1.84998,0.82996-1.84998,1.83997v1.84003c0,1.01001,0.83002,1.83997,1.84998,1.83997h58.81   c1.01001,0,1.84003-0.82996,1.84003-1.83997v-1.84003C65.75,51.35001,64.91998,50.52006,63.90997,50.52006z M6.91241,50.52006   l3.92358-6.12469l4.06403,6.12469H6.91241z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M4.40192,30.51017c-1.4873-7.68408,1.56152-15.67822,7.71484-20.44824c-2.2261,4.96109-1.04059,12.40789,0.69531,17.53174   C9.67878,25.97682,5.84355,27.35319,4.40192,30.51017z M14.08118,26.63096c-1.79372-5.51403-3.17539-15.6588,1.6947-18.76019   l6.70996,15.30688C19.12468,21.61848,15.29877,23.30627,14.08118,26.63096z M23.85669,22.56779l-6.7066-15.29913   c5.66456-1.49689,12.03191,6.32911,14.94153,11.45844C28.8084,17.34275,24.94596,19.10389,23.85669,22.56779z M33.66412,18.45304   c-2.69336-4.93291-7.2589-10.69123-12.48389-12.40576c7.77588-1.40137,15.68359,1.75244,20.35254,8.2002   C38.2326,13.15241,34.60911,15.0176,33.66412,18.45304z"
        ></path>
      </g>
    </svg>
  );
}