export function skis (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M66.556,15.746c-2.246-1.047-6.942,2.762-8.078,5.199c-6.41,13.745-27.586,59.157-27.586,59.157l9.291,4.332  c0,0,21.008-45.053,27.584-59.158C68.902,22.839,68.807,16.795,66.556,15.746z M49.855,57.252l-4.359-2.035l0.635-1.359l4.359,2.035  L49.855,57.252z M52.909,50.703l-4.359-2.034l0.635-1.359l4.359,2.033L52.909,50.703z M82.146,74.92  c-0.789,0.326-1.606,0.531-2.432,0.625V31.868c0.771-0.373,1.308-1.156,1.308-2.07c0-0.422,0-4.699,0-5.121  c0-1.275-1.034-2.308-2.308-2.308s-2.308,1.032-2.308,2.308c0,0.422,0,4.699,0,5.121c0,0.914,0.536,1.697,1.308,2.07v43.677  c-0.825-0.094-1.643-0.299-2.432-0.625l-0.766,1.848c1.036,0.428,2.112,0.689,3.197,0.791v1.961h2v-1.961  c1.085-0.102,2.161-0.363,3.197-0.791L82.146,74.92z M22.285,75.545V31.868c0.771-0.373,1.308-1.156,1.308-2.07  c0-0.422,0-4.699,0-5.121c0-1.275-1.034-2.308-2.308-2.308s-2.308,1.032-2.308,2.308c0,0.422,0,4.699,0,5.121  c0,0.914,0.537,1.697,1.308,2.07v43.677c-0.825-0.094-1.644-0.299-2.432-0.625l-0.766,1.848c1.036,0.428,2.112,0.689,3.197,0.791  v1.961h2v-1.961c1.085-0.102,2.161-0.363,3.197-0.791l-0.766-1.848C23.929,75.246,23.11,75.451,22.285,75.545z M50.828,65.15  c5.024,10.773,8.991,19.283,8.991,19.283l9.289-4.332c0,0-6.029-12.93-12.626-27.076L50.828,65.15z M43.519,49.475l5.654-12.125  c-3.148-6.754-5.932-12.72-7.65-16.405c-1.136-2.438-5.83-6.247-8.078-5.199c-2.251,1.05-2.347,7.094-1.212,9.53  C34.642,30.438,38.981,39.748,43.519,49.475z"
        ></path>
      </g>
    </svg>
  );
}