import ResponsiveIconButton from 'features/itinerary/components/responsive-icon-button';
import { useState } from 'react';
import useCreateTripFolderModalButton from '../hooks/use-create-trip-folder-modal-button';

/**
 * CreateListModalButton component for opening a modal to create a new list.
 *
 * @param {Object} props - The props for the component.
 *
 * @returns {JSX.Element} The rendered component.
 */
const CreateTripFolderModalButton = ({ sx, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const { openModal } = useCreateTripFolderModalButton();

  const handleClick = async (e) => {
    setLoading(true); // prevent button spam
    e.preventDefault();
    e.stopPropagation();
    await openModal();
    setLoading(false);
  };

  return (
    <>
      <ResponsiveIconButton
        onClick={handleClick}
        text={'New Folder'}
        icon={<FolderIcon />}
        disabled={loading}
        sx={{ whiteSpace: 'nowrap', ...sx }}
        {...rest}
      />
    </>
  );
};

import SvgIcon from '@mui/material/SvgIcon';
export function FolderIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 20">
      <path d="M23.1035 5.34026C23.0003 5.21189 22.8456 5.13481 22.6909 5.13481H19.5967V2.05392C19.5967 1.77153 19.3646 1.54044 19.081 1.54044H8.94741L7.01352 0.102726C6.91049 0.0256449 6.80732 0 6.70416 0H0.515704C0.232096 0 0 0.231096 0 0.513482V19.5123V19.538V19.615V19.6407C0 19.6663 0.0257564 19.692 0.0257564 19.7434C0.0257564 19.7434 0.0257564 19.769 0.0515129 19.769C0.0772694 19.7947 0.0772693 19.8203 0.103024 19.8461L0.128781 19.8718C0.154537 19.8974 0.154537 19.8974 0.180292 19.9231L0.206049 19.9487C0.232093 19.9231 0.257848 19.9487 0.257848 19.9487C0.257848 19.9487 0.283604 19.9487 0.283604 19.9744C0.309361 19.9744 0.335116 20 0.361019 20H0.386775H0.489946H19.0553H19.1585H19.1842C19.21 20 19.2357 20 19.2357 19.9744C19.2615 19.9744 19.2615 19.9744 19.2615 19.9487C19.2873 19.9487 19.2873 19.9231 19.313 19.9231L19.3388 19.8974C19.3645 19.8974 19.3645 19.8718 19.3903 19.8461C19.3903 19.8461 19.416 19.8461 19.416 19.8205C19.4418 19.7948 19.4418 19.7692 19.4675 19.7692C19.4933 19.7435 19.4933 19.7179 19.5191 19.6921V19.6665C19.5191 19.6665 19.5191 19.6408 19.5448 19.6152L23.1547 5.75117C23.2324 5.62266 23.2066 5.46863 23.1035 5.34026ZM1.03131 1.02701H6.52356L8.45746 2.46473C8.56063 2.54181 8.6638 2.56745 8.76682 2.56745H18.5652V5.13486H4.12548C3.89338 5.13486 3.68721 5.28888 3.63552 5.51997L1.03125 15.4815L1.03131 1.02701ZM18.6942 18.9989H1.18602L4.5381 6.16183H22.0463L18.6942 18.9989Z" />
    </SvgIcon>
  );
}

export default CreateTripFolderModalButton;
