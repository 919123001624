export function bedAndBreakfast (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="100"
      height="100"
      viewBox="0 0 995 996"
      className={outerLayer}
    >
      <path className={className} d="M175.267 715.6C210.1 715.6 238.24 687.313 238.24 652.559C238.24 617.647 210.095 589.569 175.267 589.569C140.464 589.569 112.293 617.648 112.293 652.559C112.293 687.313 140.471 715.6 175.267 715.6ZM175.267 614.704C196.1 614.704 213.043 631.677 213.043 652.543C213.043 673.345 196.1 690.381 175.267 690.381C154.403 690.381 137.46 673.34 137.46 652.543C137.445 631.663 154.387 614.704 175.267 614.704Z" fill="#273906"/>
      <path className={className} d="M924.693 631.533V855.6H70.2134V575.453C31.5254 575.453 0.213379 606.844 0.213379 645.568V995.688H70.2134V925.718H924.707V995.688H994.676L994.681 701.568C994.661 662.833 963.223 631.537 924.697 631.537L924.693 631.533Z" fill="#273906"/>
      <path className={className} d="M490.374 575.453H406.374V785.573C406.374 808.786 425.223 827.688 448.404 827.688H854.657C877.793 827.688 896.688 808.792 896.688 785.573V631.546L588.475 575.468L490.374 575.453Z" fill="#273906"/>
      <path className={className} d="M140.28 827.667H308.2H308.346C316.987 827.667 325.065 824.948 331.783 820.323C343.033 812.818 350.377 800.099 350.377 785.558C350.377 785.558 350.345 785.412 350.345 785.256V757.558H378.315V575.465H308.345C285.195 575.465 266.345 594.438 266.345 617.434V743.549H140.283C117.132 743.549 98.252 762.429 98.252 785.58C98.252 808.648 117.132 827.667 140.283 827.667L140.28 827.667ZM140.28 768.74H291.507V617.433C291.507 608.168 299.074 600.668 308.34 600.668C317.46 600.668 325.173 608.382 325.173 617.433V786.433C324.887 791.647 322.163 796.433 317.684 799.444C315.845 800.694 312.866 802.308 309.043 802.386L308.194 802.392H140.274C130.946 802.392 123.441 794.824 123.441 785.558C123.441 776.454 131.17 768.741 140.274 768.741L140.28 768.74Z" fill="#273906"/>
      <path className={className} d="M801.987 282.573H802.789V473.027C802.789 486.688 813.956 497.86 827.554 497.86H827.632C841.294 497.86 852.482 486.694 852.482 473.027L852.487 282.573V100.467C852.487 98.0032 852.263 95.7012 852.185 93.3002C852.185 92.8991 852.409 92.5658 852.409 92.1648V25.9567C852.409 12.2954 841.242 1.10742 827.56 1.10742H811.033C788.246 1.10742 769.658 45.8261 769.658 100.467V250.307C785.814 253.615 798.658 266.338 801.986 282.578L801.987 282.573Z" fill="#273906"/>
      <path className={className} d="M174.68 196.373V472.106C174.68 485.768 185.847 496.955 199.513 496.955C213.175 496.955 224.363 485.789 224.363 472.106L224.357 196.413C243.54 191.309 257.493 178.074 257.493 162.236V8.4622C257.493 3.89967 253.749 0.191406 249.191 0.191406C244.629 0.191406 240.92 3.905 240.92 8.4622V124.348C240.92 128.988 237.207 132.65 232.65 132.65C228.056 132.65 224.347 128.988 224.347 124.348V8.4622C224.347 3.89967 220.634 0.191406 216.077 0.191406C211.514 0.191406 207.806 3.905 207.806 8.4622V124.348C207.806 128.988 204.061 132.65 199.504 132.65C194.91 132.65 191.233 128.988 191.233 124.348V8.4622C191.233 3.89967 187.519 0.191406 182.962 0.191406C178.4 0.191406 174.66 3.905 174.66 8.4622V124.348C174.66 128.988 170.978 132.65 166.389 132.65C161.795 132.65 158.118 128.988 158.118 124.348V8.4622C158.118 3.89967 154.405 0.191406 149.816 0.191406C145.254 0.191406 141.592 3.905 141.592 8.4622V162.236C141.608 178.074 155.524 191.309 174.68 196.381L174.68 196.373Z" fill="#273906"/>
      <path className={className} d="M497.067 94.9863C431.594 94.9863 378.572 148.013 378.572 213.481H615.532C615.532 147.997 562.496 94.9863 497.068 94.9863H497.067ZM497.067 120.074C539.916 120.074 576.078 149.033 587.036 188.377L407.09 188.382C418.084 149.038 454.246 120.076 497.074 120.076L497.067 120.074Z" fill="#273906"/>
      <path className={className} d="M481.053 0.186523H513.023V71.2905H481.053V0.186523Z" fill="#273906"/>
      <path className={className} d="M316.24 81.1328L377.787 116.669L361.787 144.382L300.24 108.846L316.24 81.1328Z" fill="#273906"/>
      <path className={className} d="M405.974 16.5332L441.51 78.0799L413.796 94.0799L378.26 32.5332L405.974 16.5332Z" fill="#273906"/>
      <path className={className} d="M678.253 81.0928L694.284 108.79L632.757 144.399L616.727 116.702L678.253 81.0928Z" fill="#273906"/>
      <path className={className} d="M588.52 16.5469L616.223 32.5629L580.66 94.0789L552.958 78.0629L588.52 16.5469Z" fill="#273906"/>
      <path className={className} d="M650.067 260.894H331.28V361.576C331.28 416.951 376.624 462.248 431.952 462.248H549.458C592.963 462.248 629.89 434.055 643.859 395.16H666.886C703.912 395.16 733.974 365.098 733.974 327.994C733.974 290.952 703.911 260.906 666.886 260.906L650.068 260.911L650.067 260.894ZM688.723 327.982C688.723 340.028 678.884 349.804 666.9 349.804H650.067V306.19H666.9C678.947 306.19 688.723 315.966 688.723 327.982Z" fill="#273906"/>
    </svg>
  );
}