/**
 * @typedef {Object} Block
 * @property {string|null} meta_tag
 * @property {string} ui_color
 * @property {number} block_rid
 * @property {boolean} list_flag
 * @property {string} tree_path
 * @property {boolean} vote_flag
 * @property {number|null} block_cost
 * @property {string} block_name
 * @property {number} sort_order
 * @property {number} account_rid
 * @property {number|null} block_count
 * @property {number} block_level
 * @property {number} child_count
 * @property {boolean} folder_flag
 * @property {boolean} comment_flag
 * @property {number} comment_count
 * @property {boolean} location_flag
 * @property {boolean} template_flag
 * @property {boolean} auto_sync_flag
 * @property {boolean} completed_flag
 * @property {Array<Object>} location_point
 * @property {number} checklist_total
 * @property {number} block_file_count
 * @property {number|null} block_total_cost
 * @property {number|null} explorer_vote_up
 * @property {number|null} explorer_vote_rid
 * @property {number|null} explorer_vote_down
 * @property {number} itinerary_item_rid
 * @property {number|null} activity_detail_rid
 * @property {number} adventure_block_rid
 * @property {string|null} block_activity_name
 * @property {number} block_category_rid
 * @property {string} block_category_name
 * @property {number} total_vote_count_up
 * @property {number} total_vote_count_down
 * @property {number} completed_checklist_total
 * @property {number} checklist_percent_complete
 * @property {number|null} parent_block_rid
 * @property {string|null} parent_block_name
 * @property {string} ui_image_location
 */

/**
 * @typedef {Block & {
 *   list_flag: true
 * }} ListBlock
 */

/**
 * @typedef {Block & {
 *   list_flag: false
 * }} ActivityBlock
 */

/**
 * @typedef {Object} BlockType
 * @property {"List"} LIST
 * @property {"Activity"} ACTIVITY
 */

/**
 * @type {{ TYPE: BlockType }}
 */
export const BLOCK = Object.freeze({
  TYPE: {
    LIST: 'List',
    ACTIVITY: 'Activity',
  },
});

export {};
