import merge from "lodash/merge";
import {
  RECEIVE_USER_FRIENDS,
  RECEIVE_USER_UNFRIENDS,
  RECEIVE_ACCOUNT_GROUP,
  RECEIVE_ACCOUNT_GROUP_FRIENDS,
  RECEIVE_ADVENTURE_FRIENDS,
  RECEIVE_ADVENTURE_GROUP,
  RECEIVE_ADVENTURE_GROUP_FRIENDS,
  RECEIVE_USER_INCOMING_NOTIFICATION,
  RECEIVE_USER_RECENT_BLOCK_UPDATE,
  RECEIVE_DISPLAY_NOTIFICATION_BADGE,
  RECEIVE_UNDISPLAY_NOTIFICATION_BADGE,
  RECEIVE_USER_FRIEND_REQUESTS,
  RECEIVE_USER_ADVENTURE_INVITES,
  RECEIVE_DELETE_USER_FRIEND,
  RECEIVE_UPDATE_USER_UNFRIENDS,
  RECEIVE_USER_FRIEND_UPDATES,
  RECEIVE_FLUSH_USER_UNFRIENDS,
  RECEIVE_FLUSH_USER_FRIENDS,
} from "../../constants/index";
const FriendsReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_USER_FRIENDS:
      if (newState.userFriends) {
        newState.userFriends = [
          ...newState.userFriends,
          ...action.data.userFriends,
        ];
      } else {
        newState["userFriends"] = action.data.userFriends;
      }
      return newState;
    case RECEIVE_USER_FRIEND_UPDATES:
      newState["userFriends"] = action.data.userFriends;
      return newState;
    case RECEIVE_DELETE_USER_FRIEND:
      newState.userFriends.splice(action.data, 1);
      return newState;

    case RECEIVE_USER_UNFRIENDS:
      if (action.searchType.isUpdating) {
        newState.userUnfriends = [
          ...newState.userUnfriends,
          ...action.data.unfriendedUsers,
        ];
      } else if (action.searchType.isNew) {
        newState["userUnfriends"] = action.data.unfriendedUsers;
      }

      return newState;
    case RECEIVE_UPDATE_USER_UNFRIENDS:
      newState.userUnfriends.splice(action.data, 1);
      return newState;
    case RECEIVE_FLUSH_USER_UNFRIENDS:
      newState.userUnfriends = null;
      return newState;
    case RECEIVE_FLUSH_USER_FRIENDS:
      newState.userFriends = null;
      return newState;
    case RECEIVE_ACCOUNT_GROUP:
      newState["userAccountGroup"] = action.data.userAccountGroup;
      return newState;
    case RECEIVE_ACCOUNT_GROUP_FRIENDS:
      newState["userAccountGroupFriends"] = action.data.userAccountGroupFriends;
      return newState;
    case RECEIVE_ADVENTURE_FRIENDS:
      newState["adventureFriends"] = action.data.userAdventureFriends;
      return newState;
    case RECEIVE_ADVENTURE_GROUP:
      newState["adventureGroups"] = action.data.userAdventureGroups;
      return newState;
    case RECEIVE_ADVENTURE_GROUP_FRIENDS:
      newState["adventureGroupFriends"] = action.data.userAdventureGroupFriends;
      return newState;
    case RECEIVE_USER_INCOMING_NOTIFICATION:
      newState["userIncomingNotifications"] = action.data.userNotification;
      return newState;
    case RECEIVE_USER_RECENT_BLOCK_UPDATE:
      newState["userRecentBlockUpdate"] = action.data.userRecentBlockUpdate;
      return newState;
    case RECEIVE_DISPLAY_NOTIFICATION_BADGE:
      if (!newState.notificationToDisplay) {
        newState["notificationToDisplay"] = {
          1: {
            badge: JSON.parse(action.data),
            display: true,
            key: 1,
          },
        };
      } else {
        let key = Object.keys(newState.notificationToDisplay).length + 1;
        newState.notificationToDisplay[key] = {
          badge: JSON.parse(action.data),
          display: true,
          key,
        };
      }
      return newState;
    case RECEIVE_UNDISPLAY_NOTIFICATION_BADGE:
      newState.notificationToDisplay[action.data].display = false;
      return newState;
    case RECEIVE_USER_FRIEND_REQUESTS:
      newState["userFriendRequests"] = action.data;
      return newState;
    case RECEIVE_USER_ADVENTURE_INVITES:
      newState["userAdventureInvites"] = action.data;
      return newState;
    default:
      return state;
  }
};

export default FriendsReducer;
