"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDateFromInput = exports.isValidDate = void 0;
/**
 * Build a date from input
 * @param {string} day
 * @param {string} month
 * @param {string} year
 */
var buildDateFromInput = function (day, month, year) {
    var date = new Date(Number(year), Number(month) - 1, Number(day));
    return date;
};
exports.buildDateFromInput = buildDateFromInput;
/**
 * Check if the date value is a valid Date object and that it matches the values it was created from
 * @param date Date
 * @param day string
 * @param month string
 * @param year string
 */
var isValidDateObject = function (date, day, month, year) {
    var isDate = Object.prototype.toString.call(date) === '[object Date]';
    var dayMatch = date.getDate() === Number(day);
    var monthMatch = date.getMonth() === Number(month) - 1;
    var yearMatch = date.getFullYear() === Number(year);
    return isDate && dayMatch && monthMatch && yearMatch;
};
/**
 * Determine if provided date is a valid date and falls between date range
 * @param {string} day
 * @param {string} month
 * @param {string} year
 * @param {object} props
 */
var isValidDate = function (day, month, year, props) {
    var userDate = buildDateFromInput(day, month, year);
    // Must be a valid date
    if (!isValidDateObject(userDate, day, month, year)) {
        return props.invalidMessage || 'Not a valid date';
    }
    // Must be same or before max date
    if (props.maxDate) {
        var maxDate = props.maxDate;
        if (userDate > maxDate) {
            var maxDatePlusOne = new Date(maxDate);
            maxDatePlusOne.setDate(maxDatePlusOne.getDate() + 1);
            return (props.maxDateMessage ||
                "Date must be less than " + maxDatePlusOne.toDateString().substring(3));
        }
    }
    // Must be same or after min date
    if (props.minDate) {
        var minDate = props.minDate;
        if (userDate < minDate) {
            var minDateMinusOne = new Date(minDate);
            minDateMinusOne.setDate(minDateMinusOne.getDate() - 1);
            return (props.minDateMessage ||
                "Date must be greater than " + minDateMinusOne
                    .toDateString()
                    .substring(3));
        }
    }
    return '';
};
exports.isValidDate = isValidDate;
