import { Paper } from "@mui/material";
import Section from ".";

function PaperSection({ children, color = "background.primary", sx = {} }) {
  return (
    <Section>
      <Paper
        elevation={0}
        sx={{ borderRadius: 1, padding: 3, backgroundColor: color, ...sx }}
      >
        {children}
      </Paper>
    </Section>
  );
}

export default PaperSection;
