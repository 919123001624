export const svgLock = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="76.5"
      height="94.9"
      viewBox="0, 0, 76.5, 94.9"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M66.7,35 L64,35 L64,21.8 C64,9.8 54.2,0 42.2,0 L34.2,0 C22.2,0 12.4,9.8 12.4,21.8 L12.4,34.9 L9.7,34.9 C4.3,34.9 -0,39.3 -0,44.6 L-0,85.2 C-0,90.6 4.4,94.9 9.7,94.9 L66.8,94.9 C72.2,94.9 76.5,90.5 76.5,85.2 L76.5,44.7 C76.5,39.3 72.1,35 66.7,35 z M25.5,21.8 C25.5,17 29.4,13.1 34.2,13.1 L42.2,13.1 C47,13.1 50.9,17 50.9,21.8 L50.9,34.9 L25.5,34.9 L25.5,21.8 z"
          fill={color}
        />
      </g>
    </svg>
  );
};