import React from 'react';
import { Link } from 'react-router-dom';
const MembersSummary = ({ accountDetails }) => {
  const {
    account_about,
    account_name,
    account_status,
    auto_renew_flag,
    explorer_count,
    explorer_limit,
    plan_name,
    plan_description,
    remaining_explorer_count,
    ui_image_location,
  } = accountDetails;
  return (
    <section className="member-summary-wrapper">
      <div className="member-summary-header">
        <h3>Summary</h3>
        <hr />
      </div>
      <div className="member-summary-contents-wrapper">
        <section className="member-summary-contents-columns member-summary-contents-columns--1">
          <div className="account-detail-profile-pic-wrapper">
            {ui_image_location ? (
              <img
                className="account-detail-profile-pic"
                src={ui_image_location}
                alt="/svg_logos/nav_bar_logo.svg"
              />
            ) : (
              <Link to="/hub/account/account-profile">
                <span className="no-account-image-text">
                  No account image,
                  <br />
                  click to edit
                </span>
              </Link>
            )}
          </div>
        </section>
        <section className="member-summary-contents-columns member-summary-contents-columns--2">
          <div className="contents-column-2 contents-column-2--accountname ">
            <div className="content-description-wrapper">
              <span>Account Name:</span>
            </div>
            <span className="content-description-content"> {account_name}</span>
          </div>
          <div className="contents-column-2 contents-column-2--accountstatus ">
            <div className="content-description-wrapper">
              <span>Account status:</span>
            </div>
            <span className="content-description-content">
              {account_status}
            </span>
          </div>
          <div className="contents-column-2 contents-column-2--accountdescription ">
            <div className="content-description-wrapper">
              <span>Account Description:</span>
            </div>
            <span className="content-description-content">{account_about}</span>
          </div>
        </section>
      </div>
    </section>
  );
};

export default MembersSummary;
