export const svgAttendees = (color, className, outerLayer) => {
  return (
    <svg viewBox="0 0 64 64" x="0px" y="0px" className={outerLayer}>
      <path
        className={className}
        d="M20.75 16.25c4.48 0 8.125-3.645 8.125-8.125s-3.645-8.125-8.125-8.125c-4.48 0-8.125 3.645-8.125 8.125s3.645 8.125 8.125 8.125z"
      ></path>
      <path
        className={className}
        d="M3.875 29.375v11.25c0 3.102 2.523 5.625 5.625 5.625 0.657 0 1.288-0.113 1.875-0.321v18.071h18.75v-44h-16.875c-5.169 0-9.375 4.206-9.375 9.375z"
      ></path>
      <path
        className={className}
        d="M43.25 16.25c4.48 0 8.125-3.645 8.125-8.125s-3.645-8.125-8.125-8.125c-4.48 0-8.125 3.645-8.125 8.125s3.645 8.125 8.125 8.125z"
      ></path>
      <path
        className={className}
        d="M50.75 20h-16.875v44h18.75v-18.071c0.587 0.208 1.218 0.321 1.875 0.321 3.102 0 5.625-2.523 5.625-5.625v-11.25c0-5.169-4.206-9.375-9.375-9.375z"
      ></path>
    </svg>
  );
};
