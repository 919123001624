import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-185609776-1');

export default (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = (props) => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname,
    ]);
    return <WrappedComponent {...props} {...options} />;
  };

  return HOC;
};
