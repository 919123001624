import axios from 'axios'

export const fetchRecGovCampgrounds = (data) => {
  return axios.patch(`/api/linked-apps/recgov-facilities`, data);
}

export const fetchRecGovCampsites = (data) => {
  return axios.patch(`/api/linked-apps/recgov-campsites`, data);
}

export const fetchRecGovCampsitesMapData = () => {
  return axios.get(`/api/linked-apps/recgov-campsites-map-data`);
}

export const fetchRecGovCampGroundsMapData = () => {
  return axios.get(`/api/linked-apps/recgov-campgrounds-map-data`);
}

export const upsertRecGovMetaData = (data) => {
  return axios.post(`/api/linked-apps/recgov-upsert-meta-data`, data);
}

export const fetchRecGovMetaData = (block_rid) => {
  return axios.get(`/api/linked-apps/recgov-meta-data/${block_rid}`);
}