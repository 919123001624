import { Box, Stack } from '@mui/material';
import CreateActivityModalButton from 'features/activity/components/create-activity-modal-button';

import TripPlannerButton from 'features/adventure/components/trip-builder-button';
import AppendItineraryDayButton from './append-itinerary-day-button';
import EditItneraryModalButton from './edit-itinerary-modal-button';

const props = {
  size: 'small',
  variant: 'contained',
};
const ItineraryActionButtonList = () => {
  return (
    <Box display="flex" mt={1}>
      <Stack
        direction="row"
        gap={1}
        width="65.067"
        height="46.299"
        // sx={{ transform: 'scale(.65)', transformOrigin: "left" }}
      >
        <CreateActivityModalButton {...props} />
        <AppendItineraryDayButton {...props} />
        <EditItneraryModalButton {...props} />
        <TripPlannerButton {...props} />
      </Stack>
    </Box>
  );
};

export default ItineraryActionButtonList;
