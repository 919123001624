import { useQuery, useQueryClient } from '@tanstack/react-query';
import useUserInfo from 'features/user/hooks/use-user-info';
import { useEffect, useState } from 'react';
import {
  fetchAdventureFriend,
  fetchFriends,
} from 'util/api_util/social_api_util';

export const INVITE_STATUS = Object.freeze({
  NOT_INVITED: 0,
  PENDING: 1,
  ACCEPTED: 2,
  DENIED: 3,
  TRIP_HOST: 4,
});

//TODO: Finish me!
export const ADVENTURE_FRIEND_ROLES = Object.freeze({
  _: 1,
  TRIP_GUEST: 2,
  TRIP_HOST: 3,
});

// prevent refetching so we don't override the local state
const queryConfig = {
  refetchOnWindowFocus: false, // Prevent refetching on window focus
  refetchOnReconnect: false, // Prevent refetching on network reconnect
  staleTime: 0,
  refetchOnMount: true,
};

function useAdventureFriends(accountRid, adventureRid) {
  const { explorer_rid } = useUserInfo();
  const queryClient = useQueryClient();
  const userFriends = useQuery({
    queryKey: ['invite-friends', 'explorer', 'friends', accountRid],
    queryFn: () =>
      fetchFriends(accountRid, 1, 100).then((res) => res.data.userFriends),
    ...queryConfig,
  });

  const adventureFriends = useQuery({
    queryKey: [explorer_rid, 'adventure', adventureRid, 'attendees'],
    queryFn: () =>
      fetchAdventureFriend(adventureRid, 1, 100).then(
        (res) => res.data.userAdventureFriends,
      ),
    ...queryConfig,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const queries = [userFriends, adventureFriends];
  const isFetching = queries.some((query) => query.isFetching);
  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  useEffect(() => {
    if (isLoading || isError) return;

    // normalize data so it is easy to use
    // Union user friends list with advneture user list
    // to get the list of friends a user can edit
    let data = userFriends.data
      .map((friend) => {
        if (friend.explorer_rid == explorer_rid) return; // don't show self

        friend.invite_status = INVITE_STATUS.NOT_INVITED;
        return friend;
      })
      .filter(Boolean); // remove stripped values

    // set the values of friends that are already in the trip,
    // so that they are not default
    adventureFriends.data.forEach((friend) => {
      const friendData = data.find(
        (e) => e.explorer_rid == friend.explorer_rid,
      );
      if (!friendData) {
        return;
      }
      if (friendData) {
        friendData.invite_status = friend.invite_response_rid;
        friendData.role = friend.friend_role_rid;
        friendData.adventure_friend_rid = friend.adventure_friend_rid;
      }
    });
    data = data.filter(
      (friend) => friend.role !== ADVENTURE_FRIEND_ROLES.TRIP_HOST,
    ); // filter out the host
    setData(data);
    setLoading(false);
    return () => {
      // RQ will show stale data first before it refetches, which is annoying.
      // That means without this, you will see stale data until new data is fetched...
      queryClient.removeQueries({ queryKey: ['invite-friends'] });
      setData([]);
    };
  }, [
    userFriends.data,
    adventureFriends.data,
    adventureRid,
    isLoading,
    isFetching,
  ]);

  return { data, isLoading: isLoading || loading, isError };
}

export default useAdventureFriends;
