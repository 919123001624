import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import { logout } from '../../../actions/session_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { svgCryingFace } from 'components/svg_module';
import { withRouter } from 'react-router-dom';
import { Headers } from './shared_modules';

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchStates: () => dispatch(fetchStates()),
  fetchCountries: () => dispatch(fetchCountries()),
  logout: () => dispatch(logout()),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;

  return { userInfo, currentExplorerDetails };
};

const ComingSoonPage = ({ logout, history }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const [remainingSeconds, setRemainingSeconds] = useState(5);
  scrollTop();

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
    let decreaser = 1;
    let decreaseTime = setInterval(() => {
      setRemainingSeconds(remainingSeconds - decreaser);
      decreaser += 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(decreaseTime);
      logout();
      history.push('/');
    }, 6000);
  }, []);

  // const [objectProfile, setObjectProfile] = useState({
  // })

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>

      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="CANCEL CONFIRMED" />
          <section className="cancel-subscription-confirmed-wrapper">
            <section className="cancel-subscription-confirmed-section cancel-subscription-confirmed-section--top">
              {svgCryingFace(
                '',
                'cancel-confirmed-svg',
                'cancel-confirmed-svg-outer',
              )}
            </section>
            <section className="cancel-subscription-confirmed-section cancel-subscription-confirmed-section--middle">
              <h2>We're sad to see you go!</h2>
            </section>
            <section className="cancel-subscription-confirmed-section cancel-subscription-confirmed-section--bottom">
              <h3>
                You'll be automatically redirected in {remainingSeconds} seconds
              </h3>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComingSoonPage));
