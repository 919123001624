import {
  RECEIVE_USER,
  RECEIVE_BOARDING_USER,
  RECEIVE_EXPLORER_DETAIL,
  RECEIVE_ALL_ACCOUNT_EXPLORERS,
  RECEIVE_ACCOUNT_DETAILS,
  RECEIVE_ACCOUNT_PARTNER_DONATIONS,
} from '../../constants';
// import { RECEIVE_DATA } from "../actions/fb_actions";

const nullState = Object.freeze({});

const UsersReducer = (state = nullState, action) => {
  Object.freeze(nullState);
  let newState = Object.assign({}, state);
  switch (action.type) {
    case RECEIVE_USER:
      let id = action.user._id;
      delete newState['password'];
      delete newState['date'];
      return { [id]: newState };
    // case RECEIVE_DATA:
    //   newState.data = action.data
    // debugger
    case RECEIVE_BOARDING_USER:
      newState['boarding_user'] = action.user;
      return newState;
    case RECEIVE_EXPLORER_DETAIL:
      newState['currentExplorerDetails'] = action.data;
      return newState;
    case RECEIVE_ALL_ACCOUNT_EXPLORERS:
      newState['allAccountExplorers'] = action.data;
      return newState;
    case RECEIVE_ACCOUNT_DETAILS:
      newState['accountDetails'] = action.data[0];
      return newState;
    case RECEIVE_ACCOUNT_PARTNER_DONATIONS:
      newState['accountPartnerDonations'] = action.data;
      return newState;
    default:
      return state;
  }
};

export default UsersReducer;
