import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

const Auth = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={(props) =>
      !loggedIn ? <Component {...props} /> : <Redirect to="/hub/my_adventures" />
    }
  />
);

const Protected = ({
  component: Component,
  loggedIn,
  pageName,
  title,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Component pageName={pageName} title={title} {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return { loggedIn: state.session.isAuthenticated };
};

export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));

export const AuthRoute = withRouter(connect(mapStateToProps)(Auth));
