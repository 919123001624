import React from 'react';
import Comments from './comments';
import { Toggle } from '../../modal_modules';
import { SectionSubTitle } from '../../../common/section-title';

const Social = ({
  comments,
  blockComments,
  currentBlock,
  handleOnChangeComments,
  handleBlockCommentSubmit,
  triggerNext,
  inputValues,
  switchHandler,
}) => {
  return (
    <>
      <SectionSubTitle>SOCIAL</SectionSubTitle>
      {/* <div className="comments-page-toggle-wrapper"> */}
      <Toggle
        setToggleState={switchHandler}
        name="vote_flag"
        value={inputValues.vote_flag}
        text="Allow voting."
        customClassName="summary-comments-toggle"
      />
      <Toggle
        setToggleState={switchHandler}
        name="comment_flag"
        value={inputValues.comment_flag}
        text="Allow comments."
        customClassName="summary-comments-toggle"
      />
      {/* {console.log(inputValues.comment_flag,currentBlock, triggerNext.newBlock )} */}
      {/* && (currentBlock || triggerNext.newBlock) */}
      {inputValues.comment_flag  && (
        <Comments
          comments={comments}
          handleOnChangeComments={handleOnChangeComments}
          blockComments={blockComments}
          currentBlock={inputValues}
          handleBlockCommentSubmit={handleBlockCommentSubmit}
        />
      )}
    </>
  );
};

export default Social;
