import { makeStyles } from '@material-ui/core/styles';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';
import { openDeleteModal } from '../../../actions/modal_actions';
import {
  deleteAccountGroupFriends,
  deleteAdventureFriend,
  upsertAdventureFriend,
} from '../../../actions/social_actions';
import { deleteAccountExplorer } from '../../../actions/user_actions';
import NotifcationPaylods from '../../../util/notification/return_payloads';
import UserBar from '../../user-bar';

const useStyles = makeStyles({
  icon: {
    color: '#273a09',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAccountGroupFriend: (accountGroupRid, accountGroupFriendRid) =>
      dispatch(
        deleteAccountGroupFriends(accountGroupRid, accountGroupFriendRid),
      ),
    upsertAdventureFriend: (data) => {
      dispatch(upsertAdventureFriend(data));
    },
    deleteAdventureFriend: (adventure_friend_rid, adventure_rid) =>
      dispatch(deleteAdventureFriend(adventure_friend_rid, adventure_rid)),
    deleteAccountExplorer: (explorerRid, accountRid) =>
      dispatch(deleteAccountExplorer(explorerRid, accountRid)),
    openDeleteModal: (data, func) => dispatch(openDeleteModal(data, func)),
  };
};
const mapStateToProps = ({ session }) => {
  let userInfo =
    session.user && session.user.userInfo ? session.user.userInfo[0] : null;
  return { userInfo };
};
const MemberBlock = ({
  userInfo,
  member,
  openDeleteModal,
  type,
  addClickHandler,
  deleteAccountGroupFriend,
  isAdventureFriends, // used for adventure friends
  isAccountGroup, // used for account groups
  isAccountGroupEdit,
  accountGroupRid,

  // this is for inviting friend to adventure
  // TODO: extract this logic to parent
  handleInviteFriend,
  handleDeleteFriend,

  isInviteToAdventure,
  upsertAdventureFried,
  currentAdventureData,
  upsertAdventureFriend,

  //adventurefriend
  deleteAdventureFriend,
  // member management page
  isAccountExplorers, // used for account exploere edits on settings page
  accountExplorerClickHandler,
  deleteAccountExplorer,
}) => {
  const classes = useStyles();
  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isInviteToAdventure && member.invite_response_rid === 0) {
      let adventureFriendData = {
        adventure_friend_rid: null,
        adventure_rid: currentAdventureData.adventure_rid,
        friend_rid: member.friend_rid,
        sender_rid: userInfo.explorer_rid,
        recipient_rid: member.explorer_rid,
        friend_role_rid: 2,
        invite_response_rid: 2,
        ui_notification_location: NotifcationPaylods['adventureInviteRequest'](
          userInfo,
          currentAdventureData,
        ),
      };
      // upsertAdventureFriend(adventureFriendData);
    }
    if (isAccountExplorers) {
      accountExplorerClickHandler();
    }
  };

  let inviteIcon;
  // let member = merge ({}, member)
  switch (member.invite_response) {
    // case "notInvited":
    //   inviteIcon = (
    //     <div className="mod-inv-svg-wrapper">
    //       {svgInviteCircle("color", "mod-inv-svg", "mod-inv-svg-outer")}
    //     </div>
    //   );
    //   break;
    case 'pending':
      // inviteIcon = (
      //   <button className="mod-inv-svg-wrapper" disabled>
      //     <PendingOutlinedIcon className={classes.icon} />
      //     {/* {svgInvitePending('#f7b844', 'mod-inv-svg', 'mod-inv-svg-outer')} */}
      //   </button>
      // );
      inviteIcon = <Button variant="contained">Pending</Button>;
      break;
    case 'accepted':
      inviteIcon = (
        <Button variant="contained" onClick={onClickHandler}>
          Invite
        </Button>
      );
      // inviteIcon = (
      //   <button className="mod-inv-svg-wrapper" disabled>
      //     <CheckCircleOutlinedIcon className={classes.icon} />
      //     {/* {svgInviteAccepted('#6ec6ed', 'mod-inv-svg', 'mod-inv-svg-outer')} */}
      //   </button>
      // );
      break;
    default:
      inviteIcon = (
        <Button variant="contained" onClick={onClickHandler}>
          Invite
        </Button>
      );
      // inviteIcon = (
      //   <button
      //     className="mod-inv-svg-wrapper"
      //     value={member.friend_rid}
      //     // onClick={onClickHandler}
      //     disabled
      //   >
      //     <Brightness1OutlinedIcon className={classes.icon} />
      //     {/* {svgInviteCircle('#6ec6ed', 'mod-inv-svg', 'mod-inv-svg-outer')} */}
      //   </button>
      // );
      break;
  }
  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={`member-block-wrapper ${(isInviteToAdventure || isAccountExplorers) &&
          'account-explorer-account-hover'
          }`}
      // onClick={onClickHandler}
      >
        {/* {member.ui_image_location && ( */}
        {/* <img
        src={
          member.ui_image_location
            ? member.ui_image_location
            : '/logos_v2/lodestar-mark.svg'
        }
        className="mbr-blk-prf-img"
        alt="no-profile-pic"
      /> */}
        {/* // )} */}
        <div className={`mbr-blk-mid-wrapper`}>
          <div className="mbr-blk-mid-lft">
            <UserBar
              user={{
                name:
                  member.full_name ||
                  `${member.first_name} ${member.last_name}`,
                ui_image_location: member.ui_image_location,
              }}
              avatarposition="left"
              leftaligned="left"
            />
          </div>
          <div className="">
            {/* ADD to Adventure */}
            {isInviteToAdventure &&
              member.invite_response_rid !== 2 &&
              inviteIcon}
            {/* DELETE from Adventure */}
            {isInviteToAdventure &&
              member.invite_response_rid === 2 &&
              currentAdventureData.account_rid === userInfo.account_rid && (
                <Button
                  variant="outlined"
                  sx={{ px: 1 }}
                  onClick={() =>
                    handleDeleteFriend(
                      member.adventure_friend_rid,
                      currentAdventureData.adventure_rid,
                    )
                  }
                >
                  Remove
                </Button>
                // <button
                //   className="mod-inv-del-svg-wrapper"
                //   onClick={(e) => {
                //     e.preventDefault();
                //     e.stopPropagation();
                //     handleDeleteFriend(
                //       member.adventure_friend_rid,
                //       currentAdventureData.adventure_rid,
                //     );
                //     // deleteAdventureFriend(
                //     //   member.adventure_friend_rid,
                //     //   currentAdventureData.adventure_rid,
                //     // );
                //   }}
                // >
                //   <HighlightOffOutlinedIcon className={classes.icon} />
                //   {/* {svgDelete('color', 'mod-inv-svg', 'mod-invg-svg-outer')} */}
                //   remove
                // </button>
              )}
            {/* DELETE from Account  */}
            {isAccountExplorers && (
              <button
                className="mod-inv-del-svg-wrapper"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const passedFunc = (callBack) => {
                    deleteAccountExplorer(
                      member.explorer_rid,
                      userInfo.account_rid,
                    );
                    callBack();
                  };

                  openDeleteModal(
                    `user: ${member.first_name} ${member.last_name}`,
                    passedFunc,
                  );
                }}
              >
                <HighlightOffOutlinedIcon className={classes.icon} />
                {/* {svgDelete('color', 'mod-inv-svg', 'mod-invg-svg-outer')} */}
              </button>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberBlock);
