import React,{useState} from "react";
import { Search, MemberBlock, GroupBlock } from "../../modal_modules";
const AdventureGroupInvite = ({ userAccountGroups, adventureFriends }) => {
  const [tabLabel, setTabLabel] = useState("Account Groups");

  const renderAccountGroups = () => {
    return userAccountGroups ? (
      <>
        {userAccountGroups.map((group, idx) => {
          return <GroupBlock group={group} />;
        })}
      </>
    ) : (
      <div />
    );
  };

  const renderAdventureFriends = () => {
    return adventureFriends ? (
      <>
        {adventureFriends.map((friend, idx) => {
          return <MemberBlock member={friend} />;
        })}
      </>
    ) : (
      <div />
    );
  };
  return (
    <div className="invite-from-account--wrapper">
      <div className="invite-from-account-tabs--wrapper">
        <div
          onClick={() => setTabLabel("Account Groups")}
          className={`invite-from-account-tab ${
            tabLabel === "Account Groups" && "invite-from-account-tab-selected"
          }`}
        >
          <span>Account Groups</span>
        </div>
        <div
          onClick={() => setTabLabel("All Friends")}
          className={`invite-from-account-tab ${
            tabLabel === "All Friends" && "invite-from-account-tab-selected"
          }`}
        >
          <span>All Trip Friends</span>
        </div>
      </div>
      <div className="invite-from-account-search--wrapper">
        <Search />
      </div>
      <div className="invite-from-account-contents--wrapper">
        {tabLabel === "Account Groups"
          ? renderAccountGroups()
          : renderAdventureFriends()}
      </div>
    </div>
  );
};

export default AdventureGroupInvite