/* this modal will be used to add friends to an adventure through selection from user friendlist 



*/
import React, { useState, useEffect, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import HubModal from '../../modal';

import { Button, Title, Tab } from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
import Invites from './components/invite';
import { fetchAccountGroupMembersAndFriends } from 'actions/social_actions';
import { fetchAccountRoles } from 'actions/ui_actions';
import { updateUserInfo } from 'actions/user_actions';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import axios from 'axios';

const mapStateToProps = ({ entities, session, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let accountRoles = ui.components.accountRoles
    ? ui.components.accountRoles
    : null;

  return {
    userInfo,
    accountRoles,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccountGroupMembersAndFriends: (userInfo, accountGroupRid) =>
      dispatch(fetchAccountGroupMembersAndFriends(userInfo, accountGroupRid)),
    updateUserInfo: (data, userInfo) =>
      dispatch(updateUserInfo(data, userInfo)),
    fetchAccountRoles: () => dispatch(fetchAccountRoles()),
  };
};

const InviteFriendsToAdventure = ({
  closeModal,
  userInfo,
  accountRoles,
  updateUserInfo,
  fetchAccountRoles,
}) => {
  const [inputValues, setInputValues] = useState({
    first_name: null,
    last_name: null,
    account_rid: userInfo.account_rid,
    email: '',
    account_role_rid: null,
    message: null,
    sender_rid: userInfo.explorer_rid,
  });
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: '',
    emailErrors: '',
    passwordErrors: [],
  });
  const [accountRoleRid, setAccountRoleRid] = useState([]);

  const handleAccountRoleSelect = useCallback((event) => {
    if (event.length > 0) {
      const { account_role_rid } = event[0];
      setInputValues({ ...inputValues, account_role_rid });
    } else {
      setInputValues({ ...inputValues, account_role_rid: null });
    }
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      let emailValidation = EmailValidator.validate(value);
      if (emailValidation === false) {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: 'Please enter a valid email',
        });
      } else {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: '',
        });
        axios
          .patch('/api/e/verify_auth_email', { email: value })
          .then((res) => {
            if (res.data.success) {
              setSignUpErrors({ ...signUpErrors, emailErrors: '' });
            } else {
              setSignUpErrors({
                ...signUpErrors,
                emailErrors:
                  res.data.message === 'Email valid' ? '' : res.data.message,
              });
            }
          });
      }
    }
    setInputValues({ ...inputValues, [name]: value });
  });
  const [renderAccountGroupMembers, setRenderAccountGroupMember] = useState(
    false,
  );
  const handleSubmit = useCallback(() => {
    const { account_role_rid, email } = inputValues;
    if(!account_role_rid || !email) return;
    updateUserInfo(inputValues, userInfo);
    closeModal();
  });
  const renderEmailErrors = () => {
    if (inputValues.email.length > 0 && signUpErrors.emailErrors.length > 0) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pic" src={'/symbols/red-alert.png'} />
            <span>{signUpErrors.emailErrors}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (!accountRoles) {
      fetchAccountRoles();
    }
  }, [accountRoles]);
  return (
    <HubModal
      show={true}
      title="INVITE NEW MEMBER"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <Stack spacing={2}>
          <Invites
            handleAccountRoleSelect={handleAccountRoleSelect}
            accountRoles={accountRoles}
            accountRoleRid={accountRoleRid}
            inputValues={inputValues}
            handleOnChange={handleOnChange}
            renderEmailErrors={renderEmailErrors}
          />
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFriendsToAdventure);
