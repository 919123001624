import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchUserIncomingNotification,
  updateNotifications,
  queryAllNotificationsAsRead,
} from 'actions/social_actions';
import NotificationCard from './notification_card';
import { withRouter } from 'react-router-dom';
import { svgBackButton } from 'components/svg_module';
const mapStateToProps = ({ entities, session }) => {
  let userNotifications =
    entities.social.userIncomingNotifications &&
    entities.social.userIncomingNotifications;
  let userInfo =
    session.user && session.user.userInfo ? session.user.userInfo[0] : null;
  return { userNotifications, userInfo };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserIncomingNotification: (explorer_rid) =>
      dispatch(fetchUserIncomingNotification(explorer_rid)),
    updateNotifications: (data) => dispatch(updateNotifications(data)),
    queryAllNotificationsAsRead: (explorer_rid) =>
      dispatch(queryAllNotificationsAsRead(explorer_rid)),
  };
};
const MenuNotifications = ({
  fetchUserIncomingNotification,
  queryAllNotificationsAsRead,
  userNotifications,
  setTab,
  ...props
}) => {
  const { userInfo } = props;
  useEffect(() => {
    // queryAllNotificationsAsRead(userInfo.explorer_rid);
  }, []);
  if (userNotifications) {
  }
  return (
    <section className="user-notifications-wrapper">
      {userNotifications && userNotifications.length === 0 && (
        <div className="no-notif-text-wrapper">
          <div className='friends-prompt-text'>Yeah! No unread messages...</div>
        </div>
      )}
      {userNotifications &&
        userNotifications.length > 0 &&
        userNotifications.map((ele) => (
          <NotificationCard notification={ele} {...props} />
        ))}
    </section>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MenuNotifications));
