export const svgLibrary2 = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      className={outerLayer}
      width={width ? width : '100'}
      height={height ? height : '100'}
      viewBox={viewBox ? viewBox : '0 0 100 100'}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M1.514,100.001 C0.678,100.001 0,99.286 0,98.403 L0,93.042 C0,92.16 0.678,91.444 1.514,91.444 L98.478,91.444 C99.314,91.444 99.992,92.16 99.992,93.042 L99.992,98.403 C99.992,99.286 99.314,100.001 98.478,100.001 z M31.69,87.223 C30.17,87.217 28.938,85.918 28.932,84.314 L28.932,4.8 C28.938,3.196 30.17,1.897 31.69,1.891 L48.833,1.891 C50.354,1.897 51.585,3.196 51.591,4.8 L51.591,84.314 C51.566,85.91 50.346,87.197 48.833,87.223 z M2.758,87.223 C1.237,87.217 0.006,85.918 0,84.314 L0,4.8 C0.007,3.196 1.238,1.898 2.758,1.891 L19.901,1.891 C21.422,1.898 22.653,3.196 22.659,4.8 L22.659,84.314 C22.653,85.918 21.422,87.217 19.901,87.223 z M78.198,85.113 L56.891,8.85 C56.679,8.116 56.754,7.323 57.1,6.648 C57.445,5.973 58.032,5.472 58.73,5.256 L75.17,0.123 C75.865,-0.101 76.617,-0.022 77.257,0.343 C77.897,0.707 78.372,1.326 78.577,2.062 L99.884,78.382 C100.096,79.116 100.021,79.909 99.675,80.584 C99.33,81.259 98.743,81.76 98.045,81.976 L81.605,87.052 C81.347,87.133 81.079,87.175 80.81,87.175 C79.601,87.177 78.537,86.337 78.198,85.113 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};