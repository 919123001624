import { Box, Container, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';

import { PUBLIC_SITE_URL } from 'constants/env';
import Banner404 from './404-banner.png';
import PublicSiteNavButton from './public_site_nav_button';

const mapStateToProps = ({ session }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;

  return { userInfo };
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.goToHubHandler = this.goToHubHandler.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };

    // Update state so the next render will show the fallback UI.
    // let currentVersionInLocalStorage = localStorage.getItem(
    //   'lodestarPWAVersion',
    // );
    // if (process.env.NODE_ENV !== 'development') {
    //   axios.get('/api/ui/hub-version').then(({ data }) => {
    //     if (data.hubVersion[0].current_version) {
    //       if (
    //         parseInt(data.hubVersion[0].current_version.split('.').join('')) >=
    //         parseInt(
    //           currentVersionInLocalStorage
    //             .split('v')
    //             .join('')
    //             .split('.')
    //             .join(''),
    //         )
    //       ) {
    //         window.location.reload(true);
    //         localStorage.setItem('lodestarPWAVersion', `v${data.hubVersion}`);

    //         return { hasError: false };
    //       } else {
    //         return { hasError: true };
    //       }
    //     }
    //   });
    // }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Caught Error:', error);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // let currentVersionInLocalStorage = localStorage.getItem(
    //   'lodestarPWAVersion',
    // );
    // if (process.env.NODE_ENV !== 'development') {
    //   axios.get('/api/ui/hub-version').then(({ data }) => {
    //     if (data.hubVersion[0].current_version) {
    //       if (
    //         parseInt(data.hubVersion[0].current_version.split('.').join('')) >
    //         parseInt(
    //           currentVersionInLocalStorage
    //             .split('v')
    //             .join('')
    //             .split('.')
    //             .join(''),
    //         )
    //       ) {
    //         if (this.props.userInfo) {
    //           axios.post('/api/ui/errors', {
    //             explorer_rid: this.props.userInfo.explorer_rid,
    //             error_text: `last_visited_page: ${JSON.stringify(
    //               this.props.history.location,
    //             )} [:::::::::] user_page_history: ${JSON.stringify(
    //               this.props.userPageHistory,
    //             )} [::::::::] refreshed images for missing chunk`,
    //             error_info: 'refreshed images for missing chunk',
    //             ui_version: `v${process.env.REACT_APP_VERSION}`,
    //           });
    //         } else {
    //           axios.post('/api/ui/errors', {
    //             explorer_rid: 0,
    //             error_text: `last_visited_page: ${JSON.stringify(
    //               this.props.history.location,
    //             )} [:::::::::] user_page_history: ${JSON.stringify(
    //               this.props.userPageHistory,
    //             )} [::::::::] refreshed images for missing chunk`,

    //             error_info: 'refreshed images for missing chunk',
    //             ui_version: `v${process.env.REACT_APP_VERSION}`,
    //           });
    //         }
    //         window.location.reload(true);
    //         localStorage.setItem('lodestarPWAVersion', `v${data.hubVersion}`);
    //         this.props.clearPageHistory();

    //         return { hasError: false };
    //       } else {
    //         if (this.props.userInfo) {
    //           axios.post('/api/ui/errors', {
    //             explorer_rid: this.props.userInfo.explorer_rid,
    //             error_text: `last_visited_page: ${JSON.stringify(
    //               this.props.history.location,
    //             )} [:::::::::] user_page_history: ${JSON.stringify(
    //               this.props.userPageHistory,
    //             )} [::::::::] ${error.message}`,
    //             error_info: ` ${JSON.stringify(errorInfo)}`,
    //             ui_version: `v${process.env.REACT_APP_VERSION}`,
    //           });
    //           this.props.clearPageHistory();
    //         } else {
    //           axios.post('/api/ui/errors', {
    //             explorer_rid: 0,
    //             error_text: error.message,
    //             error_info: errorInfo,
    //             ui_version: `v${process.env.REACT_APP_VERSION}`,
    //           });
    //           this.props.clearPageHistory();
    //         }
    //       }
    //     }
    //   });
    // }
  }
  goToHubHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hasError: false }, () => {
      this.props.history.push(PUBLIC_SITE_URL);
    });
  }
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hasError: false }, () => {
      this.props.history.push(PUBLIC_SITE_URL);
    });
  };

  render() {
    // Check if the error is thrown
    return this.state.hasError ? (
      <ThemeProvider theme={muiTheme}>
        <Container maxWidth={'xl'}>
          <Box
            paddingTop={'80px'}
            paddingBottom={'20px'}
            display="flex"
            justifyContent={'center'}
            flexDirection={'column'}
            gap={3}
            height="80vh"
          >
            <Box width={{ xs: 1, sm: 0.8 }}>
              <Typography variant="h6" fontSize={{ xs: 16, sm: 20, md: 24 }}>
                <Box>{`Oops, something has gone wrong.`}</Box>
                <Box>{`Let's get you back on track.`}</Box>
              </Typography>
              <PublicSiteNavButton />
            </Box>
            <Box sx={{ borderRadius: 1, overflow: 'hidden' }}>
              <Box
                height={{ xs: 400, sm: 500, md: 800 }}
                sx={{ position: 'relative' }}
              >
                <img src={Banner404} style={{ objectFit: 'cover' }} />
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    ) : (
      this.props.children
    );

    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (
    // <div className="error-boundary-wrapper">
    //   <nav className="error-boundary-navbar">
    //     <img
    //       alt="pwa-nav-bar"
    //       src="/svg_logos/nav_bar_logo.svg"
    //       className="error-boundary-logoimage"
    //       id="logoimage-hub"
    //     />
    //   </nav>
    //   <div className="error-boundary-contents">
    //     <div className="error-boundary-contents-top">
    //       <div className="error-boudary-headers-wrapper">
    //         <h1>Oops! We went off trail by accident.</h1>
    //       </div>
    //       <div className="error-boudary-headers-wrapper">
    //         <h2>We're sorry about that and have caught the error.</h2>
    //       </div>
    //       <div className="error-boundary-contents-buttons-wrapper">
    //         <button
    //           onClick={(e) => {
    //             e.preventDefault();
    //             e.stopPropagation();
    //             this.setState({ hasError: false });
    //             window.location = `${
    //               process.env.REACT_APP_RUN_MODE === 'PROD'
    //                 ? process.env.REACT_APP_PROD_PUB_URL
    //                 : process.env.REACT_APP_RUN_MODE === 'TEST'
    //                 ? process.env.REACT_APP_TEST_PUB_URL
    //                 : process.env.REACT_APP_DEV_PUB_URL
    //             }/`;
    //           }}
    //         >
    //           GO TO LODESTAR HOME
    //         </button>
    //         <button
    //           onClick={(e) => {
    //             this.goToHubHandler(e);
    //           }}
    //         >
    //           GO TO MY HUB
    //         </button>
    //       </div>
    //     </div>
    //     <div className="error-boundary-contents-bottom">
    //       <span>
    //         <a
    //           class="mailtag"
    //           href="mailto:support@lodestarhub.com"
    //           style={{ color: '#09132b' }}
    //         >
    //           support@lodestarhub.com
    //         </a>
    //       </span>
    //       <span>&#9400;2021 LODESTARHUB, INC. ALL RIGHTS RESERVED</span>
    //     </div>
    //   </div>
    // </div>
    // );
    // }

    // return this.props.children;
  }
}

export default connect(mapStateToProps)(withRouter(ErrorBoundary));
