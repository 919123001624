export function outdoorCooking (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M56.67078,57.13184c-1.28998,0-2.33997,1.03998-2.33997,2.33002c0,1.28998,1.04999,2.33002,2.33997,2.33002   c1.28003,0,2.33002-1.04004,2.33002-2.33002C59.00079,58.17181,57.95081,57.13184,56.67078,57.13184z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M65.75,65.375h-4.62524c1.79681-1.35284,2.966-3.49512,2.966-5.91315c0-4.09003-3.32996-7.41003-7.41998-7.41003   c-4.09998,0-7.42999,3.32001-7.42999,7.41003c0,2.41803,1.16919,4.5603,2.96808,5.91315H23.77234l1.52844-5.66315h22.45001   c-0.01001-0.08002-0.01001-0.16998-0.01001-0.25c0-1.21002,0.23999-2.35999,0.67999-3.41003h-22.13l5.65002-17.76996   c2.57001,0.85999,5.31,1.32001,8.15997,1.32001c2.85004,0,5.60004-0.46002,8.16998-1.32001l4.29004,13.26996   c1.07001-0.54999,2.26001-0.89996,3.52997-0.97998l-4.38-13.71997c7.56-3.79004,12.99005-11.19,14.02002-19.94   c0.19-1.63-1.09998-3.06-2.73999-3.06H17.20081c-1.65002,0-2.94,1.42999-2.75,3.06c0.01001,0.12,0.02997,0.22998,0.04999,0.34998   h6.15002c1.75,0,3.16998,1.42999,3.16998,3.17999s-1.41998,3.16998-3.16998,3.16998h-4.45001   c2.37,5.79004,6.79999,10.52002,12.37,13.29004L19.99573,65.375H3.25c-0.41406,0-0.75,0.33594-0.75,0.75s0.33594,0.75,0.75,0.75   h62.5c0.41406,0,0.75-0.33594,0.75-0.75S66.16406,65.375,65.75,65.375z M56.67078,63.29187   c-2.12,0-3.83997-1.72003-3.83997-3.83002s1.71997-3.83002,3.83997-3.83002c2.10999,0,3.83002,1.72003,3.83002,3.83002   S58.78076,63.29187,56.67078,63.29187z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M32.04736,12.24854c0.41406,0,0.75-0.33594,0.75-0.75c0-3.88021,2-4.27347,2-8.62354c0-0.41406-0.33594-0.75-0.75-0.75   s-0.75,0.33594-0.75,0.75c0,3.8794-2,4.27438-2,8.62354C31.29736,11.9126,31.6333,12.24854,32.04736,12.24854z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M39.09326,12.24854c0.41406,0,0.75-0.33594,0.75-0.75c0-3.88021,2-4.27347,2-8.62354c0-0.41406-0.33594-0.75-0.75-0.75   s-0.75,0.33594-0.75,0.75c0,3.8794-2,4.27438-2,8.62354C38.34326,11.9126,38.6792,12.24854,39.09326,12.24854z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M46.13916,12.24854c0.41406,0,0.75-0.33594,0.75-0.75c0-3.88021,2-4.27347,2-8.62354c0-0.41406-0.33594-0.75-0.75-0.75   s-0.75,0.33594-0.75,0.75c0,3.8794-2,4.27438-2,8.62354C45.38916,11.9126,45.7251,12.24854,46.13916,12.24854z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M7.56079,24.74182c1.78003,0,3.32001-1.08997,3.97003-2.63h9.12c0.91998,0,1.66998-0.75,1.66998-1.66998   c0-0.92999-0.75-1.67999-1.66998-1.67999h-9.13c-0.65002-1.53998-2.17999-2.62-3.96002-2.62c-2.38,0-4.31,1.91998-4.31,4.29999   C3.25079,22.81183,5.18079,24.74182,7.56079,24.74182z"
        ></path>
      </g>
    </svg>
  );
}