export function snowMobile (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 80.861"
      className={outerLayer}
    >
      <path
        className={className}
        d="M41.016,14.945c4.127,0,7.473-3.333,7.473-7.483C48.488,3.355,45.143,0,41.016,0c-4.129,0-7.473,3.355-7.473,7.462
C33.543,11.612,36.887,14.945,41.016,14.945z"
      ></path>
      <path
        className={className}
        d="M49.812,75.298h35.542c0,0,2.295,0.155,6.865-3.51L100,65.851v6.711l-7.936,5.938c0,0-2.363,1.921-6.336,2.362H46.832
L49.812,75.298z"
      ></path>
      <polygon points="0.298,80.861 35.85,80.861 38.818,75.298 38.521,75.298 2.969,75.298 0,80.861 "></polygon>
      <path
        className={className}
        d="M44.856,24.04c0-4.128-3.345-7.483-7.483-7.483c-4.129,0-7.483,3.355-7.483,7.483v18.301c0,2.317,1.137,4.48,2.804,5.85
H15.949L6.898,64.614c-0.199,0.375-0.287,0.795-0.287,1.236c0,1.501,1.225,2.736,2.727,2.736h61.733c0,0,2.648,0.42,6.645-2.715
l16.016-11.788l-6.049-10.573c0,0-1.357-2.562-6.49-3.334l-12.086-2.031l-6.49-10.838l-0.076-0.111
c-0.939-1.081-2.572-1.191-3.654-0.265l-0.012,0.022c-0.762,0.706-1.049,1.854-0.629,2.869l4.824,8.036v0.065L57.032,48.19
l-12.175-4.636v-4.548h9.615c0.463,0.022,0.949-0.044,1.412-0.242c1.82-0.685,2.727-2.738,2.031-4.548
c-0.531-1.413-1.855-2.252-3.256-2.274l-9.813-0.021L44.856,24.04"
      ></path>
    </svg>
  );
}