import React from "react";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "./notification_badges_container";

import { connect } from "react-redux";
import {
  NotificationBadgeFriendRequest,
  NotificationBadgeAcceptFriendRequest,
  NotificationBadgeAdventureRequest,
  NotificationBadgeAcceptAdventureRequest,
} from "./components";
const NotificationBadgesWrapper = ({ badgesToDisplay, ...props }) => {
  /* this is a container because in the future, this component will display many 
  different badges stacked on top of each other, the bottom 
  */

  // debugger;
  return (
    <div
      className={`notification-badges-wrapper notification-badges-wrapper--${
        badgesToDisplay &&
        Object.values(badgesToDisplay).every((ele) => !ele.display)
          ? "undisplay"
          : "display"
      }`}
    >
      {badgesToDisplay &&
        Object.values(badgesToDisplay).map((ele) => {
          if (ele.display) {
            if (ele.badge.type === "friendRequest")
              return (
                <NotificationBadgeFriendRequest
                  notification={ele.badge.badgePayload}
                  badgeKey={ele.key}
                  {...props}
                />
              );
            if (ele.badge.type === "acceptFriendRequest")
              return (
                <NotificationBadgeAcceptFriendRequest
                  notification={ele.badge.badgePayload}
                  badgeKey={ele.key}
                  {...props}
                />
              );
            if (ele.badge.type === "adventureInviteRequest")
              return (
                <NotificationBadgeAdventureRequest
                  notification={ele.badge.badgePayload}
                  badgeKey={ele.key}
                  {...props}
                />
              );
            if (ele.badge.type === "acceptAdventureInviteRequest")
              return (
                <NotificationBadgeAcceptAdventureRequest
                  notification={ele.badge.badgePayload}
                  badgeKey={ele.key}
                  {...props}
                />
              );
          }
        })}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBadgesWrapper);
