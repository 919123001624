import React, { useState, useCallback, useEffect } from 'react';
import { Title, Input, Button } from '../../modal_modules/index';
import { connect } from 'react-redux';
import {
  fetchPexelImages,
  flushFetchedImages,
} from 'actions/third_party_actions';
import { sendImages, openAdventureModal } from 'actions/modal_actions';

const mapStateToProps = ({ thirdParty, session, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let pexelImages = thirdParty.pexels.pexelPhotos
    ? thirdParty.pexels.pexelPhotos
    : null;
  let totalResults = thirdParty.pexels.pexelImageTotal
    ? thirdParty.pexels.pexelImageTotal
    : null;
  let nextPageNumber = thirdParty.pexels.pexelNextPageNumber
    ? thirdParty.pexels.pexelNextPageNumber
    : null;
  let currentAdventureData = ui.modal.current_adventure ? ui.modal.current_adventure : null;
  let defaultSearchTerm = ui.modal.searchTerm ? ui.modal.searchTerm : null;
  let origin = ui.modal.origin ? ui.modal.origin : null;
  return {
    userInfo,
    defaultSearchTerm,
    origin,
    pexelImages,
    totalResults,
    nextPageNumber,
    currentAdventureData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPexelImages: (query) => dispatch(fetchPexelImages(query)),
    sendImage: (hasMulti, imageObject) =>
      dispatch(sendImages(hasMulti, imageObject)),
    openAdventureModal: (isInspirationAdventure, data, edit) =>
      dispatch(openAdventureModal(isInspirationAdventure, data, edit)),
    // /openAdventureModal: () => dispatch(openAdventureModal()),
    flushFetchedImages: () => dispatch(flushFetchedImages()),
  };
};
const FetchImageFromUnsplash = ({
  closeModal,
  sendImage,
  fetchPexelImages,
  defaultSearchTerm,
  openAdventureModal,
  pexelImages,
  totalResults,
  nextPageNumber,
  origin,
  flushFetchedImages,
  currentAdventureData
}) => {
  const [inputValues, setInputValues] = useState({
    searchTerm: '',
    imageSelected: null,
    selectMultipleImage: false,
  });
  const [loading, setLoading] = useState(false);
  /*
  the effects underneath are for fetching and setting loading icons based on requests made
  to unsplash api
  */
  // useEffect(() => {
  //   if (inputValues.searchTerm && inputValues.searchTerm.length > 0) {
  //     setLoading(true);
  //     fetchPexelImages({ search_term: inputValues.searchTerm, query_page: 1 });
  //   }
  // }, []);
  useEffect(() => {
    if (pexelImages) {
      setLoading(false);
    }
    return () => {
      flushFetchedImages();
    };
  }, [pexelImages]);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    if (!inputValues.imageSelected) {
    }
    console.log("inputValues.imageSelected", inputValues.imageSelected)
    sendImage(false, { singleImage: inputValues.imageSelected });
    console.log("currentAdventureData", currentAdventureData)
    defaultSearchTerm.origin === 'TRIP'
      ? openAdventureModal(false, null, false)
      : openAdventureModal(true, null, true);
  };

  const handleImageSelect = (url) => {
    setInputValues({ ...inputValues, imageSelected: url });
  };
  const handleBack = () => {
    console.log("origin", origin)
    if (origin === 'ADVENTURE') {    
      // openAdventureModal();
      defaultSearchTerm.origin === 'TRIP'
      ? openAdventureModal(false, null, false)
      : openAdventureModal(true, null, true);
    } else {
      closeModal();
    }
  };
  const renderLoadMoreButton = () => {
    if (totalResults > pexelImages.length) {
      return (
        <div className="loadmore-button-wrapper">
          <button
            className="image-modal-loadmore-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLoading(true);
              fetchPexelImages({
                search_term: inputValues.searchTerm,
                query_page: nextPageNumber ? nextPageNumber : 1,
              });
            }}
          >
            Load more...
          </button>
        </div>
      );
    }
  };
  return (
    <div className="mod-type1-wrapper fetch-image-from-unsplash-modal--wrapper">
      <div className="mod-type1-padder ">
        <Title closeModal={handleBack} title="IMAGES" />

        <div className="mod-cnt-def-unsplash-image">
          <form onSubmit={handleSubmit}>
            <div className="pexel-modal-top">
              <Input
                label="Search"
                name="searchTerm"
                onChangeHandler={handleOnChange}
                value={inputValues.searchTerm}
                spacingTop="10px"
              />
              <button
                className="pexel-search-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setLoading(true);
                  fetchPexelImages({
                    search_term: inputValues.searchTerm,
                    query_page: nextPageNumber ? nextPageNumber : 1,
                  });
                }}
                disabled={inputValues.searchTerm === ''}
              >
                Search
              </button>
            </div>

            <div className="fetch-image-from-unsplash-modal--contents--title">
              <span>Search Results</span>
            </div>
            <div className="fetch-image-from-unsplash-modal--contents--container">
              {pexelImages &&
                pexelImages.map((ele, idx) => {
                  return (
                    <div
                      className={`unsplash-image--wrapper ${
                        inputValues.imageSelected === ele.src.medium &&
                        'seleted-image-wrapper'
                      } `}
                      onClick={() => {
                        handleImageSelect(ele.src.medium);
                      }}
                    >
                      <img
                        className="unsplash-image--image"
                        src={ele.src.small}
                        alt={ele.src.tiny}
                      />
                    </div>
                  );
                })}
              {totalResults && totalResults > 0 && renderLoadMoreButton()}
              {loading && (
                <img
                  src="/logos_v2/lodestar-mark.svg"
                  className="modal-loader-icon"
                  alt="loading..."
                />
              )}
            </div>
            <Button
              spacingBottom="20px"
              spacingTop="20px"
              text="SELECT IMAGE"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FetchImageFromUnsplash);
