import axios from 'axios';

export const updateUserInfo = (data) => {
  return axios.patch('/api/e/update', data);
};

export const fetchUsers = () => {
  return axios.get('api/users');
};

// export const pgFetchUsers = () => {
//   return axios.get
// }

export const fetchUser = (userId) => {
  return axios.get(`api/users/${userId}`);
  //   return $.ajax({
  //     method: "GET",
  //     url: `api/users/${userId}`
  //   });
};

// export const updateUser = (formData, userId) => {
//   return $.ajax({
//     method: "PATCH",
//     url: `api/users/${userId}`,
//     data: formData,
//     contentType: false,
//     processData: false
//   });
// };

export const deleteUser = (id) => {
  return axios.delete(`api/users/${id}`);
  //   return $.ajax({
  //     method: "DELETE",
  //     url: `api/users/${id}`
  //   });
};

export const fetchAccountExplorers = (account_rid, explorer_rid) => {
  return axios.patch(`/api/e/account-explorers/${account_rid}/${explorer_rid}`);
};

export const fetchAccountDetail = (account_rid) => {
  return axios.get(`/api/e/account-detail/${account_rid}`);
};

export const updateAccountDetails = (data) => {
  return axios.post(`/api/e/account-detail`, data);
};

export const insertUserSubscription = (data) => {
  return axios.post('/api/e/user_subscription', data);
};

export const insertUserPayment = (data) => {
  return axios.post('/api/e/user_payment', data);
};

export const terminateUserSubscriptionEarly = (data) => {
  return axios.post(`/api/e/terminate_subscription`, data);
};

export const updateUserPassword = (data) => {
  return axios.patch('/api/e/password', data);
};

export const deleteAccountExplorer = (explorerRid, accountRid) => {
  return axios.delete(`/api/e/explorer/${explorerRid}/${accountRid}`);
};

export const checkTokenExpiration = (token) => {
  return axios.get(`/api/e/expired/${token}`);
};

export const signUpInvitedUser = (data) => {
  return axios.patch('/api/e/invited-explorer', data);
};

export const updateUserProfilePic = (data) => {
  return axios.patch('/api/e/explorer-profile-pic', data);
};

export const updateAccountProfilePic = (data) => {
  return axios.patch('/api/e/account-profile-pic', data);
};

export const updateUserToken = (data) => {
  return axios.patch('/api/e/user-token', data);
};

export const inviteNewMember = (data) => {
  return axios.post('/api/e/invite-new', data);
};

export const updateSubScriptionPartnerCode = (data) => {
  return axios.patch('/api/e/subscription-partner-code', data);
};

export const fetchMyAccountDonations = (account_rid) => {
  return axios.get(`/api/e/account-partner-donations/${account_rid}`);
};
export const fetchTripDonations = (adventure_rid) => {
  return axios.get(`/api/e/adventure-partner-donations/${adventure_rid}`);
};
export const insertAccountPartnerDonation = (data) => {
  return axios.post('/api/e/account-partner-donations', data);
};
