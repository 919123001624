import CardSkeleton from '@components/card/card-skeleton';
import CardCarousel from './card-carousel';

function CarouselCardSkeleton() {
  return (
    <CardCarousel className={'skeleton'}>
      {Array.from({ length: 4 }).map((_, i) => (
        <CardSkeleton key={i} />
      ))}
    </CardCarousel>
  );
}

export default CarouselCardSkeleton;
