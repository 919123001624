import {
  RECEIVE_ADDED_ADVENTURE,
  RECEIVE_ADDED_BLOCK,
  RECEIVE_ADVENTURE_BLOCKS,
  RECEIVE_ADVENTURE_DATA,
  RECEIVE_ADVENTURE_INFORMATIONS,
  RECEIVE_ADVENTURE_NOOP,
  RECEIVE_COPIED_ADVENTURE,
  RECEIVE_FLUSH_ADVENTURES,
  RECEIVE_FLUSH_CURRENT_ADVENTURE,
  RECEIVE_ITINERARY_INFORMATION,
  RECEIVE_ITINERARY_ITEM_BLOCKS,
  RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED,
  RECEIVE_LAZY_ADVENTURES,
  RECEIVE_LIBRARY_BLOCKS,
  RECEIVE_SELECTED_ADVENTURE,
} from '../constants';
import * as ApiAdventure from '../util/api_util/adventure_api_util';
import * as ApiBlock from '../util/api_util/block_api_util';
import * as ItineraryAPI from '../util/api_util/itinerary_api_util';
import { receiveNewLibraryBlock } from './block_actions';
import { receiveItineraries } from './itinerary_actions';
// const receive_adventures = adventures => {
//     return {
//         type: RECEIVE_ADVENTURES,
//         adventures
//     };
// };

// const receive_adventure = adventure => {
//     return {
//         type: RECEIVE_ADVENTURE,
//         adventure
//     };
// };

// const remove_adventure = adventure_rid => {
//     return {
//         type: REMOVE_ADVENTURE,
//         adventure_rid
//     };
// };
const receiveAdventureNoop = () => {
  return {
    type: RECEIVE_ADVENTURE_NOOP,
  };
};
const receiveSelectedAdventure = (data) => {
  return {
    type: RECEIVE_SELECTED_ADVENTURE,
    data,
  };
};
const receiveFlushAdventures = () => ({
  type: RECEIVE_FLUSH_ADVENTURES,
});
const receiveAdventureInformations = (data) => {
  return {
    type: RECEIVE_ADVENTURE_INFORMATIONS,
    data,
  };
};

export const receiveAdventureBlocks = (data) => {
  return {
    type: RECEIVE_ADVENTURE_BLOCKS,
    data,
  };
};

export const receiveLibraryBlocks = (data) => {
  return {
    type: RECEIVE_LIBRARY_BLOCKS,
    data,
  };
};

const receiveAdventureData = (data) => {
  return {
    type: RECEIVE_ADVENTURE_DATA,
    data,
  };
};

const receiveAddedAdventure = (data) => {
  return {
    type: RECEIVE_ADDED_ADVENTURE,
    data,
  };
};

const receiveAddedBlock = (data) => {
  return {
    type: RECEIVE_ADDED_BLOCK,
    data,
  };
};

const receiveCopiedAdventure = (data) => {
  return {
    type: RECEIVE_COPIED_ADVENTURE,
    data,
  };
};

export const receiveItineraryInformation = (data) => {
  return {
    type: RECEIVE_ITINERARY_INFORMATION,
    data,
  };
};

const receiveItineraryItemBlocksUpdated = (data, organizerData) => {
  return {
    type: RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED,
    data,
    organizerData,
  };
};

const receiveItineraryItemBlocks = (itineraryItemRid, data) => {
  return {
    type: RECEIVE_ITINERARY_ITEM_BLOCKS,
    data,
    itineraryItemRid,
  };
};
const receiveLazyAdventures = (data) => {
  return {
    type: RECEIVE_LAZY_ADVENTURES,
    data,
  };
};

export const flushCurrentAdventure = () => {
  return {
    type: RECEIVE_FLUSH_CURRENT_ADVENTURE,
  };
};

export const getAdventureData = (explorer_rid) => (dispatch) => {
  return ApiAdventure.fetchAdventureData(explorer_rid).then((data) => {
    console.debug(data.data);
    return dispatch(receiveAdventureData(data.data));
  });
};

export const postAdventure = (account_rid, data) => (dispatch) => {
  return ApiAdventure.postAdventureData(account_rid, data).then((res) => {
    return ApiAdventure.fetchSingleAdventure(
      Object.values(res.data.userAdventures[0])[0],
    ).then((res2) => {
      dispatch(receiveAddedAdventure(res2.data.singleAdventure[0]));
      return dispatch(receiveSelectedAdventure(res2.data.singleAdventure[0]));
    });
  });
};

export const fetchUserAdventures = (data) => (dispatch) => {
  return ApiAdventure.fetchUserAdventures(data).then((res) => {
    return dispatch(receiveAdventureData(res.data));
  });
};

export const fetchUserAdventureInformation = (
  account_rid,
  adventure_rid,
  userInfo,
) => (dispatch) => {
  let promise1 = ApiBlock.fetchBlocksDynamic(account_rid, {
    adventure_rid: adventure_rid,
  });
  let promise2 = ApiAdventure.fetchAdventureItinerary(
    adventure_rid,
    userInfo.explorer_rid,
  );
  return Promise.all([promise1, promise2]).then((res) => {
    return dispatch(receiveAdventureInformations(res));
  });
};

export const fetchAdventureBlocks = (account_rid, adventure_rid) => (
  dispatch,
) => {
  return ApiAdventure.fetchAdventureBlocks(account_rid, adventure_rid).then(
    (res) => {
      return dispatch(receiveAdventureBlocks(res.data));
    },
  );
};

export const postAdventureBlock = (
  userInfo,
  adventure_rid,
  data,
  displayBlockType,
) => (dispatch) => {
  return ApiBlock.postAdventureBlock(
    userInfo.account_rid,
    adventure_rid,
    data,
  ).then((res) => {
    if (adventure_rid) {
      if (displayBlockType.type === 'allBlocks') {
        return ApiAdventure.fetchUserBlocks(userInfo.account_rid).then(
          (res) => {
            return dispatch(receiveAdventureBlocks(res.data));
          },
        );
        // Promise.all([ApiAdventure.fetchUserBlocks(userInfo.account_rid), ApiBlock.fetchBlocksDynamic(userInfo.account_rid, {advenutre_rid})])
      }
      if (displayBlockType.type === 'itineraryItem') {
        return ItineraryAPI.fetchItineraryDetail(
          data.itinerary_rid,
          userInfo.explorer_rid,
        ).then((res2) => {
          // -- use this for refetching itinerary deets
          let newBlocks = res.data.userBlocks;
          let recentBlock = newBlocks.reduce((acc, ele) => {
            acc = acc.block_rid > ele.block_rid ? acc : ele;
            return acc;
          });
          dispatch(receiveNewLibraryBlock(recentBlock));
          return dispatch(receiveItineraries(res2.data));
        });
      }
      dispatch(receiveAdventureBlocks(res.data));
    } else {
      if (displayBlockType.type === 'itineraryItem') {
        return dispatch(
          receiveItineraryItemBlocksUpdated(res.data, {
            itinerary_item_rid: data.itinerary_item_rid,
          }),
        );
      }
      return dispatch(receiveAdventureBlocks(res.data));
    }
  });
};

export const fetchItineraryInformation = (
  adventure_rid,
  itinerary_rid,
  userInfo,
) => (dispatch) => {
  return ApiAdventure.fetchAdventureItinerary(
    adventure_rid,
    userInfo.explorer_rid,
  ).then((res) => {
    let itineraryInformation = res.data.adventureItineraries.filter((ele) => {
      return ele.itinerary_rid === parseInt(itinerary_rid);
    });
    dispatch(receiveItineraryInformation(itineraryInformation));
  });
};

export const deleteAdventure = (adventure) => (dispatch) => {
  return ApiAdventure.deleteAdventure(adventure);
  //   .then((res) => {
  //   return dispatch(receiveAdventureData(res.data));
  // });
};

export const deleteAdventureBlock = (
  adventure_block_rid,
  adventure_rid,
  account_rid,
) => (dispatch) => {
  return ApiBlock.deleteAdventureBlock(
    adventure_block_rid,
    adventure_rid,
    account_rid,
  ).then((res) => {
    return dispatch(receiveAdventureBlocks(res.data));
  });
};

/* 
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com  
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : 	this function is supplemental for upsertItineraryItemBlock that hadles the refreshing portion of the func
 * Updates 		: 	DD-MON-YYYY description of update  
 * Pararmeters  :{
 * itineraryItemRid; itinerary Item Rid,
 * explorerRid: exploererRid fetched from account info
    }
 * }
*/

//
export const updateItineraryItemBlock = (itineraryItemRid, explorerRid) => (
  dispatch,
) => {
  return ItineraryAPI.fetchItineraryDetail(itineraryItemRid, explorerRid).then(
    (itineraryItemBlocks) => {
      return dispatch(
        receiveItineraryItemBlocksUpdated(itineraryItemBlocks.data, {
          itinerary_item_rid: itineraryItemRid,
        }),
      );
    },
  );
};
/* 
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com  
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : 	func 'upsertItineraryItemBlock' will be used for editing blocks inside itinerary item, drilling down on itinerary item, inserting new blocks to itnerary item through modals and moving blocks to itinerary item
    all "upserting of block " will initally happen through posting dynamic block, and resorting the necessary refresh will be decided through "fetchType"
    while some funcs may require the use of same fucnctions, they will be separated by the case of the properties of fetchType for future scalabitlity.
 * Updates 		: 	DD-MON-YYYY description of update  
 * Pararmeters  :{
 *  data: pass block data to upsert,
 *  itineraryItemRid: passItineraryItemRid to be used to update specific itineraryItem,
 *  userInfo: pass whole userInfo to interchangeably use account_rid and explorer_rid,
 *  fetchType: passObeject to note how what the use case is{ 
        isNewBlock: true,
        isUpdateExistingBlock: true,
        isDraggingInNewBlock: true,
        isDrillingDown: true,
        isDeleting: truewA wA 
    }
 * }
*/

export const upsertItineraryItemBlock = (
  data,
  itineraryItemRid,
  userInfo,
  fetchType,
) => async (dispatch) => {
  // if (fetchType.isDeleting) {
  //   ItineraryAPI.deleteItineraryBlockItem(
  //     data.itinerary_block_rid,
  //     itineraryItemRid,
  //     userInfo.explorer_rid
  //   ).then(() => {
  //     ItineraryAPI.fetchItineraryItemBlocks(
  //       userInfo.explorer_rid,
  //       itineraryItemRid
  //     ).then((res) => {
  //       dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
  //     });
  //   });
  // } else {
  //   ApiBlock.upsertBlocksDynamic(userInfo.account_rid, data).then((res) => {
  //     ItineraryAPI.fetchItineraryItemBlocks(
  //       userInfo.explorer_rid,
  //       itineraryItemRid
  //     ).then((res2) => {
  //       debugge
  //       dispatch(receiveItineraryItemBlocks(itineraryItemRid, res2.data));
  //     });
  //   });
  // }
  let returnedData;
  returnedData = fetchType.isDeleting
    ? await ItineraryAPI.deleteItineraryBlockItem(
        data.itinerary_block_rid,
        itineraryItemRid,
        userInfo.explorer_rid,
      ).then
    : await ApiBlock.upsertBlocksDynamic(userInfo.account_rid, data);
  await returnedData;
  if (fetchType.isNewBlock) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  } else if (fetchType.isUpdateExistingBlock) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  } else if (fetchType.isDraggingInNewBlock) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  } else if (fetchType.isDraggingInNewBlock) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  } else if (fetchType.isDrillingDown) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  } else if (fetchType.isDeleting) {
    ItineraryAPI.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      itineraryItemRid,
    ).then((res) => {
      dispatch(receiveItineraryItemBlocks(itineraryItemRid, res.data));
    });
  }
};

/* 
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com  
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : 	func 'upsertItineraryItemBlock' will be used for editing blocks inside itinerary item, drilling down on itinerary item, inserting new blocks to itnerary item through modals and moving blocks to itinerary item
    all "upserting of block " will initally happen through posting dynamic block, and resorting the necessary refresh will be decided through "fetchType"
    while some funcs may require the use of same fucnctions, they will be separated by the case of the properties of fetchType for future scalabitlity.
 * Updates 		: 	DD-MON-YYYY description of update  
 * Pararmeters  :{
 *  data: pass block data to upsert,
 *  itineraryItemRid: passItineraryItemRid to be used to update specific itineraryItem,
 *  userInfo: pass whole userInfo to interchangeably use account_rid and explorer_rid,
 *  fetchType: passObeject to note how what the use case is{ 
        isNewBlock: true,
        isUpdateExistingBlock: true,
        isDraggingInNewBlock: true,
        isDrillingDown: true,
        isDeleting: truewA wA 
    }
 * }
*/

export const lazyLoadAdventures = (page_number, page_size) => (dispatch) => {
  return ApiAdventure.lazyLoadAdventures(page_number, page_size).then((res) => {
    return dispatch(receiveLazyAdventures(res.data));
  });
};

/* 
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com  
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : func upsert and update itinerary libra
 * Updates 		: 	DD-MON-YYYY description of update  
 * Pararmeters  :{
 * block: **object** information of the block thats being upserted,
 * fetchData: **object** data required for refetching and updating the front end,
 *   {
 *    isNested: boolean 
 *     nestedParentBlockRid: number, 
 *    isAdventure: boolean,
 *      adventure_rid: number,
 *    isLibrary: boolean, 
 *  }
 * userInfo: **object** userInfo data,
 * actionType: **object** action type data that determins type of action to be called
 *    {
 *      isInsert,
 *      isEdit,
 *      isFale
 *    }
    }
 * }
*/
export const upsertAndUpdateLibraryBlocks = async (
  block,
  fetchData,
  userInfo,
  actionType,
) => async (dispatch) => {
  if (actionType.isInsert || actionType.isEdit) {
    await ApiBlock.postUserBlock(block, userInfo.account_rid);
  } else if (actionType.isDelete) {
    await ApiBlock.deleteBlock(block, userInfo.account_rid);
  }

  if (fetchData.isNested) {
    ApiBlock.fetchBlocksDynamic(userInfo.account_rid, {
      parent_block_rid: fetchData.nestedParentBlockRid,
    }).then((res) => {
      fetchData.isAdventure
        ? dispatch(receiveAdventureBlocks(res.data))
        : dispatch(receiveLibraryBlocks(res.data));
    });
  }
};

export const copyAdventure = (data) => (dispatch) => {
  return ApiAdventure.copyAdventure(data).then((res) => {
    return dispatch(receiveCopiedAdventure(res.data));
  });
};

export const flushAdventures = () => (dispatch) => {
  return dispatch(receiveFlushAdventures());
};

export const selectSingleAdventure = (data) => (dispatch) => {
  return dispatch(receiveSelectedAdventure(data));
};

export const fetchSingleAdventure = (adventure_rid) => (dispatch) => {
  ApiAdventure.fetchSingleAdventure(adventure_rid).then((res) => {
    dispatch(receiveSelectedAdventure(res.data.singleAdventure[0]));
  });
};

export const updateAdventureImpact = (data) => (dispatch) => {
  ApiAdventure.updateAdventureImpact(data).then(({ status, data }) => {
    if (status === 200) {
      return true;
    }
  });
};

export const fetchAdventureVoteInfo = (data) => (dispatch) => {
  ApiAdventure.upsertAdventureVote(data);
};
