import React, { useState, useCallback, useEffect } from 'react';
import { svgSend } from 'components/svg_module';
const ChatInput = () => {
  const [message, setMessage] = useState(null);
  const handleOnChange = useCallback((e) => {
    const { value, name } = e.target;
    setMessage(value);
  });
  return (
    <div className="chat-input-wrapper">
      <div className="chat-input-send-container">
        <input
          name="message"
          value={message}
          onChange={handleOnChange}
          className="chat-input-field"
        />
        <button className="chat-send-button">
          {svgSend('', 'send-svg', 'send-svg-outer')}
        </button>
      </div>
    </div>
  );
};
export default ChatInput;
