import React, { Component, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';

import {
  svgAccountMenuAccount,
  svgAccountMenuImpact,
  svgAccountMenuMembership,
  svgAccountMenuPassword,
  svgAccountMenuProfile,
  svgCog,
} from 'components/svg_module';
const mapStateToProps = (state) => {
  let account_rid = state.session?.user?.account_rid;

  return {
    account_rid,
  };
};

const AccountMenu = ({ match, history, account_rid }) => {
  useEffect(() => {
    if (!isMobile && history.location.pathname === '/hub/account')
      history.push('/hub/account/profile');
  }, []);
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="account-main-wrapper">
        <div className="account-menu-wrapper" id="account-menu-responsive">
          <div
            className={`account-menu account-menu--${
              isMobile ? 'mobile' : 'browser'
            }`}
          >
            <div className="account-menu-divider-wrapper">
              <hr />
              <span>MANAGE</span>
              <hr />
            </div>
            <section className="account-menu-section manage-section">
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/account-profile"
              >
                {svgAccountMenuAccount(
                  '',
                  'account-menu-svg',
                  'account-menu-svg-outer account',
                )}
                <p>ACCOUNT</p>
              </NavLink>
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/profile"
              >
                {svgAccountMenuProfile(
                  '',
                  'account-menu-svg',
                  'account-menu-svg-outer profile',
                )}
                <p>PROFILE</p>
              </NavLink>
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/manage-subscription"
              >
                {svgAccountMenuMembership(
                  '',
                  'account-menu-svg',
                  'account-menu-svg-outer profile',
                )}
                <p>MEMBERSHIP</p>
              </NavLink>
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/change-password"
              >
                {svgAccountMenuPassword(
                  '',
                  'account-menu-svg',
                  'account-menu-svg-outer profile',
                )}
                <p>PASSWORD</p>
              </NavLink>
            </section>
            {/* {(parseInt(account_rid) === 151 ||
              parseInt(account_rid) === 150) && (
              <>
                <div className="account-menu-divider-wrapper">
                  <hr />
                  <span>REVIEW</span>
                  <hr />
                </div>
                <section className="account-menu-section reveiew-section">
                  <NavLink
                    activeClassName="account-menu-link--active"
                    className="account-menu-link"
                    to="/hub/account/my-impact-summary"
                  >
                    {svgAccountMenuImpact(
                      '',
                      'account-menu-svg',
                      'account-menu-svg-outer profile',
                    )}
                    <p>IMPACT</p>
                  </NavLink>
                </section>
              </>
            )} */}
            {/* <section className="account-menu-section account-menu-section--membership">
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/account-profile"
              >
                Account Information{' '}
              </NavLink>
            </section> */}
            {/* <div className="account-menu-divider-wrapper">
              <hr />
              <span>PROFILE</span>
              <hr />
            </div>
            <section className="account-menu-section account-menu-section--membership">
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/profile"
              >
                My Profile{' '}
              </NavLink>
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/members"
              >
                Explorer Profiles{' '}
              </NavLink>
            </section>
            <div className="account-menu-divider-wrapper">
              <hr />
              <span>REVIEW</span>
              <hr />
            </div>
            <section className="account-menu-section account-menu-section--billing">
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/manage-subscription"
              >
                My Plan{' '}
              </NavLink>
            </section>
            <div className="account-menu-divider-wrapper">
              <hr />
              <span>SECURITY</span>
              <hr />
            </div>
            <section className="account-menu-section account-menu-section--security">
              <NavLink
                activeClassName="account-menu-link--active"
                className="account-menu-link"
                to="/hub/account/change-password"
              >
                Change Password
              </NavLink>
            </section>
            {account_rid === 151 && (
              <>
                <div className="account-menu-divider-wrapper">
                  <hr />
                  <span>IMPACT</span>
                  <hr />
                </div>
                <section className="account-menu-section account-menu-section--security">
                  <NavLink
                    activeClassName="account-menu-link--active"
                    className="account-menu-link"
                    to="/hub/account/my-impact"
                  >
                    My Impact
                  </NavLink>
                  <NavLink
                    activeClassName="account-menu-link--active"
                    className="account-menu-link"
                    to="/hub/account/my-impact-summary"
                  >
                    Impact Summary
                  </NavLink>
                </section>{' '}
              </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(connect(mapStateToProps, null)(AccountMenu));
