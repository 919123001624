export function walk (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g fill={color}>
        <path
          className={className}
          fill={color}
          d="m666 295.2c70.801-28.801 103.2-110.4 74.398-181.2-28.801-70.801-110.4-103.2-181.2-74.398-70.801 30-103.2 110.4-74.398 181.2 30 70.801 110.4 104.4 181.2 74.398z"
        />
        <path
          className={className}
          fill={color}
          d="m900 580.8c21.602-9.6016 37.199-30 37.199-56.398 0-32.398-26.398-58.801-58.801-62.398l-134.4-16.805s-67.199-45.602-128.4-85.199c-87.602-56.398-166.8-22.801-189.6 48-15.602 48-27.602 141.6-38.398 226.8-6 54 20.398 106.8 68.398 133.2l-32.398 111.6-124.8 176.4c-20.398 28.801-19.199 68.398 4.8008 92.398 14.398 14.398 32.398 21.602 50.398 21.602 21.602 0 44.398-10.801 57.602-30l133.2-188.4c4.8008-6 8.3984-13.199 10.801-21.602l24-82.801 56.398 40.801 49.199 225.6c7.1992 33.602 36 55.199 69.602 55.199 16.801 0 33.602-6 49.199-19.199 19.199-16.801 25.199-44.398 20.398-69.602l-56.398-248.4c-3.6016-16.801-13.199-32.398-27.602-42l-117.6-85.199 30-170.4 36 22.801c8.3984 4.8008 16.801 8.3984 26.398 9.6016l124.8 15.594 110.4 559.2c2.3984 14.398 15.602 24 28.801 24h6c15.602-3.6016 26.398-18 22.801-34.801z"
        />
        <path
          className={className}
          fill={color}
          d="m344.4 651.6 43.203-279.6c2.3984-18-13.199-33.602-31.199-31.199l-110.4 18c-16.801 2.3984-28.801 15.602-31.199 32.398l-24 192c-2.3984 14.398 4.8008 28.801 18 36l92.398 54c17.996 9.6016 39.598-1.1992 43.195-21.598z"
        />
      </g>
    </svg>
  )
}