export function fatBike (color, className, outerLayer) {
  return (
    <svg
      height="90px"
      width="100px"
      fill="#000000"
      className={outerLayer}
      viewBox="0 0 1411 1183"
      version="1.1"
      x="0px"
      y="0px"
    >
      <g fill="#273906">
        <path className={className} d="M755.637 636.795C736.101 636.795 716.935 627.274 705.351 609.737C687.033 581.977 694.679 544.633 722.439 526.316L862.132 434.076C889.877 415.759 927.205 423.389 945.591 451.149C963.908 478.909 956.247 516.269 928.503 534.571L788.783 626.811C778.553 633.55 767.017 636.795 755.627 636.795L755.637 636.795Z" fill="#273906"/>
        <path className={className} d="M747.33 761.795C746.513 776.962 738.32 791.456 724.226 799.702C701.909 812.728 673.237 805.254 660.196 782.915L594.513 670.546C581.471 648.228 588.977 619.556 611.278 606.515C633.617 593.442 662.289 600.979 675.341 623.264L741.038 735.65C745.825 743.874 747.856 752.931 747.33 761.795Z" fill="#273906"/>
        <path className={className} d="M827.971 80.5413C827.971 125.025 791.908 161.088 747.424 161.088C702.94 161.088 666.883 125.025 666.883 80.5413C666.883 36.0573 702.94 0 747.424 0C791.908 0 827.971 36.0573 827.971 80.5413Z" fill="#273906"/>
        <path className={className} d="M588.291 335.634C577.489 335.634 567.056 329.811 561.651 319.588C553.875 304.9 559.505 286.692 574.177 278.916L720.164 201.749C734.866 193.989 753.076 199.588 760.852 214.291C768.612 228.993 762.982 247.217 748.31 254.979L602.377 332.145C597.866 334.515 593.069 335.635 588.298 335.635L588.291 335.634Z" fill="#273906"/>
        <path className={className} d="M680.624 448.114C674.285 448.114 667.936 446.13 662.493 442.036C649.227 432.005 646.608 413.124 656.623 399.859L746.593 280.801C756.64 267.536 775.519 264.916 788.786 274.947C802.067 284.963 804.671 303.843 794.64 317.109L704.671 436.166C698.749 443.989 689.728 448.114 680.624 448.114Z" fill="#273906"/>
        <path className={className} d="M752.197 825.834C762.359 828.808 770.885 836.652 774.228 847.548C779.509 864.714 769.89 882.985 752.644 888.313L665.988 914.985C648.775 920.266 630.515 910.631 625.201 893.401C619.904 876.188 629.571 857.928 646.785 852.636L733.441 825.964C739.811 824.011 746.275 824.073 752.196 825.833L752.197 825.834Z" fill="#273906"/>
        <path className={className} d="M973.637 431.782C955.252 431.782 937.096 423.381 925.268 407.464L811.971 255.144C792.148 228.456 797.7 190.728 824.387 170.89C851.122 151.067 888.803 156.619 908.627 183.306L1021.96 335.626C1041.78 362.314 1036.23 400.028 1009.54 419.866C998.726 427.892 986.132 431.782 973.637 431.782Z" fill="#273906"/>
        <path className={className} d="M1401.48 811.941C1368.4 683.124 1246.46 589.075 1113.57 589.075C1087.72 589.075 1061.87 592.465 1036.88 599.153C1025.28 602.257 1013.12 605.491 1002.58 611.345C991.57 617.46 988.919 615.971 982.277 605.747C970.34 587.361 958.871 568.659 946.98 550.241C935.094 560.017 921.412 568.033 908.564 576.528C874.132 599.263 839.684 622.017 805.236 644.736C790.225 670.225 775.189 695.709 760.163 721.168C773.189 745.777 776.017 773.537 759.876 798.157C758.933 799.579 757.699 800.668 756.694 801.996C786.407 808.683 800.601 836.798 797.59 863.433C808.246 865.433 816.757 868.282 816.549 872.522C814.309 916.876 822.34 961.704 839.861 1002.52C904.23 1152.53 1081.95 1223.69 1231.85 1158.84C1364.29 1101.56 1437.39 951.802 1401.47 811.935L1401.48 811.941ZM1016.22 768.665C1033.04 794.52 1049.82 820.395 1066.61 846.28C1034.8 841.29 1002.98 836.28 971.172 831.269C980.573 807.015 996.208 785.253 1016.22 768.665ZM931.697 651.592C913.156 666.712 895.952 682.633 880.948 701.431C863.027 723.925 848.385 749.045 837.636 775.717C835.829 780.228 829.876 807.124 825.86 808.056C814.261 810.759 794.355 803.462 782.647 801.624C823.111 733.014 863.573 664.41 904.037 595.797C911.511 607.302 918.98 618.808 926.439 630.292C932.095 639.042 940.641 644.276 931.699 651.589L931.697 651.592ZM1113.56 1039.37C1032.67 1039.37 963.071 971.428 960.871 890.605C994.245 895.871 1027.61 901.1 1060.99 906.351C1077.66 908.96 1094.35 911.585 1111.02 914.205C1125.59 916.507 1139.42 918.637 1150.55 906.7C1173.11 882.46 1140.39 849.34 1127.19 829.017C1107.96 799.386 1088.78 769.736 1069.51 740.137C1155.32 714.252 1249.38 772.678 1264.36 861.049C1279.74 951.966 1205.74 1039.37 1113.56 1039.37L1113.56 1039.37Z" fill="#273906"/>
        <path className={className} d="M539.451 715.874C524.185 694.114 505.961 674.453 485.513 657.505C480.409 653.281 475.175 649.219 469.816 645.343C469.477 643.567 475.91 634.75 476.326 634.062C484.597 619.745 492.837 605.406 501.092 591.089C523.956 618.594 546.821 646.1 569.685 673.616C566.581 666.157 564.388 658.334 564.071 649.746C563.305 629.616 571.367 610.981 584.664 597.429C576.695 587.846 568.743 578.278 560.779 568.694H671.852C672.571 561.142 674.107 553.429 677.18 545.621C682.763 531.397 693.019 519.413 705.325 508.834C653.117 508.704 600.909 508.48 548.712 508.48C558.426 491.6 568.185 474.735 577.895 457.84C588.311 469.153 599.973 485.408 617.301 484.288C634.405 483.184 646.775 468.111 644.823 451.261C642.614 432.303 622.937 417.84 610.869 404.735C592.839 385.136 574.791 365.537 556.755 345.932C545.203 333.37 534.244 319.068 515.156 322.604C498.129 325.755 486.817 343.916 492.724 360.573C497.51 374.079 510.115 384.62 520.131 394.396C524.563 398.735 529.235 403.099 532.865 408.141C537.808 414.99 525.506 428.095 521.954 434.235C495.683 479.803 469.412 525.385 443.136 570.955C439.267 577.674 421.808 616.168 415.136 613.897C404.303 610.215 393.715 605.804 382.896 602.059C299.522 573.226 200.803 591.387 129.043 640.668C-5.95704 733.382 -40.9837 921.482 52.3603 1056.28C149.511 1196.59 353.88 1224.98 485.174 1115.63C558.163 1054.84 599.543 960.586 592.679 865.626C588.804 812.104 570.278 759.834 539.444 715.879L539.451 715.874ZM349.464 1029.69C284.923 1053.54 210.611 1030.55 171.131 974.229C135.376 923.208 134.303 854.666 168.542 802.562C206.239 745.202 279.615 719.729 344.782 741.682C325.375 775.344 305.949 809.026 286.542 842.709C274.829 863.048 250.397 897.682 282.605 912.98C312.397 927.125 326.25 894.34 338.094 873.844C357.693 839.844 377.329 805.86 396.881 771.844C429.49 800.546 448.943 842.994 448.943 886.484C448.959 949.233 408.365 1007.89 349.47 1029.68L349.464 1029.69Z" fill="#273906"/>
      </g>
    </svg>
  );
}