import * as ApiUtil from '../util/api_util/session_api_util';
// import jwt_decode from 'jwt-decode';
// import jwt from 'jsonwebtoken';
// import keys from '../../../config';
// const secretOrKey = keys.secretOrKey;
import * as UserApiUtil from '../util/api_util/user_api_util';
// export const RECEIVE_RESET_ERRORS = "RECEIVE_RESET_ERRORS";
import jwt_decode from 'jwt-decode';
import { merge } from 'lodash';
import Cookies from 'universal-cookie';

import {
  RECEIVE_CURRENT_USER,
  RECEIVE_SESSION_ERRORS,
  RECEIVE_USER_LOGOUT,
  RECEIVE_USER_SIGN_IN,
} from '../constants';

export const receiveCurrentUser = (currentUser) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser,
});

export const receiveUserSignIn = () => ({
  type: RECEIVE_USER_SIGN_IN,
});

export const receiveErrors = (errors) => ({
  type: RECEIVE_SESSION_ERRORS,
  errors,
});

export const logoutUser = () => ({
  type: RECEIVE_USER_LOGOUT,
});

export const signup = (user) => (dispatch) =>
  ApiUtil.signup(user).then(
    (res) => {
      // const { token } = res.data.data;
      // localStorage.setItem('jwtToken', token);
      // ApiUtil.setAuthToken(token);
      const decoded = res.data.data;
      return res;
      // return { currentUser: decoded }; //this code is for session error
      // return dispatch(receiveCurrentUser(decoded))
    },
    (err) => dispatch(receiveErrors(err.response.data)),
  );

export const updateToken = (token) => (dispatch) => {
  const cookies = new Cookies();
  cookies.set('userTokenLode', token, {
    domain: '.lodestarhub.com',
    path: '/',
  });
  cookies.set('userTokenLocal', token, {
    domain: 'localhost',
    path: '/',
  });
  // localStorage.setItem('lodehubUserJwtToken', token);
  ApiUtil.setAuthToken(token);
  //localStorage.setItem('lodehubUserJwtToken', token);
  let decoded = jwt_decode(token);

  dispatch(receiveCurrentUser(decoded));
};

export const updateSessionToken = (data) => (dispatch) => {
  const cookies = new Cookies();
  let token = cookies.get('userTokenLode', {
    domain: '.lodestarhub.com',
    path: '/',
  });
  //let token = localStorage.getItem('lodehubUserJwtToken');
  let decoded = jwt_decode(token);
  decoded.userInfo[0] = merge(decoded.userInfo[0], data);
  UserApiUtil.updateUserToken(decoded).then((res) => {
    let newTokenDecoded = jwt_decode(res.data.token);
    dispatch(receiveCurrentUser(newTokenDecoded));
    //localStorage.setItem('lodehubUserJwtToken', res.data.token);
    cookies.set('userTokenLode', token, {
      domain: '.lodestarhub.com',
      path: '/',
    });
    cookies.set('userTokenLocal', token, {
      domain: 'localhost',
      path: '/',
    });
    ApiUtil.setAuthToken(res.data.token);
  });
};
export const login = (user) => (dispatch) =>
  ApiUtil.login(user).then(async (res) => {
    const cookies = await new Cookies();

    if (res.data.error === true) {
      return { error: true, message: res.data.message };
    }
    const { token } = res.data.data;
    // debugger

    await cookies.set('userTokenLode', token, {
      domain: '.lodestarhub.com',
      path: '/',
    });
    await cookies.set('userTokenLocal', token, {
      domain: 'localhost',
      path: '/',
    });
    // localStorage.setItem('lodehubUserJwtToken', token);
    ApiUtil.setAuthToken(token);
    const decoded = res.data.data;
    //debugger
    dispatch(receiveCurrentUser(decoded));
    return { error: false, userInfo: decoded };
  });
//     .catch(err => {
//             dispatch(receiveErrors(err.response.data));
// })
export const logout = () => (dispatch) => {
  // localStorage.removeItem('lodehubUserJwtToken');
  const cookies = new Cookies();

  cookies.remove('userTokenLode', { domain: '.lodestarhub.com', path: '/' });
  cookies.remove('userTokenLode', {
    domain: '.explorelodestar.com',
    path: '/',
  });
  cookies.remove('userTokenLode', { domain: 'localhost', path: '/' });

  ApiUtil.setAuthToken(false);
  dispatch(logoutUser());
};

// export const receiveResetErrors = () => {
//   return {
//     type: RECEIVE_RESET_ERRORS,
//     errors: []
//   };
// };
