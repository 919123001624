export const CalendarIcon = ({ color = "#273806", className, outerLayer, width = 25, height = 25 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 5.15464C18.8843 5.15464 19.5 4.51987 19.5 3.73711V1.41753C19.5 0.634772 18.8843 0 18.125 0C17.3657 0 16.75 0.634772 16.75 1.41753V3.73711C16.75 4.51987 17.3657 5.15464 18.125 5.15464ZM6.875 5.15464C7.63427 5.15464 8.25 4.51987 8.25 3.73711V1.41753C8.25 0.634772 7.63427 0 6.875 0C6.11573 0 5.5 0.634772 5.5 1.41753V3.73711C5.5 4.51987 6.11573 5.15464 6.875 5.15464ZM23.75 2.31959H20.25V3.99485C20.25 5.20464 19.2985 6.18557 18.125 6.18557C16.9515 6.18557 16 5.20464 16 3.99485V2.31959H9V3.99485C9 5.20464 8.0485 6.18557 6.875 6.18557C5.7015 6.18557 4.75 5.20464 4.75 3.99485V2.31959H1.25C0.559479 2.31959 0 2.89637 0 3.60825V23.7113C0 24.4232 0.559479 25 1.25 25H23.75C24.4405 25 25 24.4232 25 23.7113V3.60825C25 2.89637 24.4405 2.31959 23.75 2.31959ZM8.5 20.8763H4.25V16.4948H8.5V20.8763ZM8.5 14.9485H4.25V10.567H8.5V14.9485ZM14.75 20.8763H10.5V16.4948H14.75V20.8763ZM14.75 14.9485H10.5V10.567H14.75V14.9485ZM21 20.8763H16.75V16.4948H21V20.8763ZM21 14.9485H16.75V10.567H21V14.9485Z"
        fill={color}
      />
    </svg>
  )
};
