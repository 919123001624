import React, { useState } from 'react';
import { svgTripDate } from 'components/svg_module';
import { openDatePickerModal } from '../../../actions/modal_actions';
import { connect } from 'react-redux';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    openDatePickerModal: (data, date) =>
      dispatch(openDatePickerModal(data, date)),
  };
};

const DatePicker = ({
  spacingTop,
  spacingBottom,
  openDatePickerModal,
  persistParentData,
  startDate,
  endDate,
  originalStartDate,
  originalEndDate,
  origin, // pass the id of the html tag of the most outer div tag of the
  // component that is rendering this date picker
  defaultDate,
  additionalCallback,
  id,
  ...rest
}) => {
  const [displayDate, toggleDisplayDate] = useState(false);
  const handleDisplay = () => {
    toggleDisplayDate(!displayDate);
  };
  if (originalStartDate || originalEndDate) {
    startDate = originalStartDate;
    endDate = originalEndDate;
  }

  return (
    <div
      className="mod-dte-pkr-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
      id={id}
    >
      {!startDate || !endDate ? (
        <div
          className="mod-dte-pkr-untrig"
          id="date-picker-modal-button"
          // onClick={() => toggleDisplayDate(!displayDate)}
          onClick={() => {
            persistParentData();
            if (additionalCallback) additionalCallback();
            defaultDate
              ? openDatePickerModal(origin, defaultDate)
              : openDatePickerModal(origin);
          }}
        >
          {svgTripDate('white', 'mod-svg-cal', 'mod-svg-cal-wrapper')}
          <span>
            {origin === 'ITINERARY-EDIT'
              ? 'To move your trip, choose a new start date'
              : 'Choose your dates'}
          </span>
        </div>
      ) : (
        <div
          className="mod-dte-pkr-set"
          onClick={() => {
            persistParentData();
            if (additionalCallback) additionalCallback();
            openDatePickerModal(origin);
          }}
          id="date-picker-modal-button"
        >
          <div className="mod-dte-dur-tp">
            {origin !== 'ITINERARY-EDIT' ? (
              <>{moment(endDate).diff(moment(startDate), 'days') + 1} days</>
            ) : (
              <>New start date </>
            )}
          </div>
          {/* duration change to amount of days */}
          {(startDate || endDate) && (
            <div className="mod-dte-dur-btm">
              {origin !== 'ITINERARY-EDIT' ? (
                <>
                  {moment(startDate).format('MMM Do YYYY')} -{' '}
                  {moment(endDate).format('MMM Do YYYY')}
                </>
              ) : (
                <>{moment(startDate).format('MMM Do YYYY')}</>
              )}
            </div>
          )}
          {/* {(originalStartDate || originalEndDate) && <div className="mod-dte-dur-btm">{moment(originalStartDate).format("MMM Do YY")} - {moment(originalEndDate).format("MMM Do YY")}</div> } */}
        </div>
      )}
    </div>
  );
  // : <div className='modal-layer-two'>
  //
  //     </div>
};

export default connect(null, mapDispatchToProps)(DatePicker);
