export const styleValues = (theme) => ({
  avatar: {
    height: '42px',
    padding: 0,
    position: 'absolute',
  },
  grid: {
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: 'auto',
    },
  },
  gridRight: {
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      '& > .setting-bar': {
        float: 'right',
      },
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      '& > .setting-bar': {
        margin: 'auto',
      },
    },
  },
  gridContainer: {
    [theme.breakpoints.up('sm')]: {
      // Styles for desktop
      '& > *': {
        margin: theme.spacing(0), // Adjust the spacing value for desktop
      },
    },
    [theme.breakpoints.down('xs')]: {
      // Styles for mobile
      '& > *': {
        margin: theme.spacing(1), // Adjust the spacing value for mobile
      },
    },
  },
  font: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
  },
  bold: {
    fontWeight: 700,
  },
  titleFontSize: {
    fontFamily: 'Soleil',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  subTitleFontSize: {
    fontFamily: 'Soleil',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  title: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  subTitle: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  subContentFont: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  subContentFont_Yellow: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#FCEDD6',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  staticSubContentFont: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    fontSize: '14px',
  },
  font_18_14: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  font_20_14: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      fontWeight: '600',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      fontWeight: '600',
    },
  },

  font_18_12: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      fontWeight: '400 !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      fontWeight: '400 !important',
    },
  },

  font_22_16: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  contentFontSize: {
    fontFamily: 'Albiona',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px ! important',
      '& button': {
        fontSize: '20px ! important',
      },
      '& span': {
        fontSize: '20px ! important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px ! important',
      '& button': {
        fontSize: '14px ! important',
      },
      '& span': {
        fontSize: '14px ! important',
      },
    },
  },
  span: {
    fontFamily: 'Albiona',
    color: '#273A07',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  spanSizeWithOutFont: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  h3Size: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  spanSize: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  staticSpanSize: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    color: '#273A07',
    fontWeight: 700,
    fontSize: '10px',
  },
  svgColor_237a07: {
    fill: '#273A07 !important',
    '& svg path': {
      fill: '#273A07 !important',
    },
  },
  svgSize: {
    [theme.breakpoints.up('sm')]: {
      width: '30px',
      height: '30px',
      '& svg, & path': {
        width: '30px',
        height: '30px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
      '& svg, & path': {
        width: '18px',
        height: '18px',
      },
    },
  },
  margin_12: {
    '& > *': {
      margin: '0px 12px',
    },
  },
  margin_horizontal_12: {
    '& > *': {
      margin: '12px 0px',
    },
  },
  staticSvgSize: {
    '& svg, & path': {
      width: '12px',
      height: '12px',
      fill: '#273A07',
    },
  },
  staticSvgSize_30_18: {
    [theme.breakpoints.up('md')]: {
      '& svg, & path': {
        width: '30px',
        height: '30px',
        fill: '#273A07',
        margin: '0px !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg, & path': {
        width: '18px',
        height: '18px',
        fill: '#273A07',
        margin: '0px !important',
      },
    },
  },
  staticSvgSize_30_18_withoutColor: {
    [theme.breakpoints.up('950px')]: {
      '& svg, & path': {
        width: '30px',
        height: '30px',
        margin: '0px !important',
      },
    },
    [theme.breakpoints.down('950px')]: {
      '& svg, & path': {
        width: '18px',
        height: '18px',
        margin: '0px !important',
      },
    },
  },
  cursorSvgDefault: {
    '& svg, & path': {
      cursor: 'default',
    },
  },
  cursorSvgPointer: {
    '& svg, & path': {
      cursor: 'pointer',
    },
  },
  svgThumbUpCenter: {
    [theme.breakpoints.up('md')]: {
      '& svg': {
        marginTop: '-3px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        marginTop: '-3px',
      },
    },
  },
  svgClass: {
    [theme.breakpoints.up('md')]: {
      '& svg': {
        width: '58px ! important',
        height: '50px ! important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
      '& svg': {
        width: '29px ! important',
        height: '25px ! important',
      },
    },
  },
  cardContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
      backgroundColor: 'white',
      borderRadius: '20px',
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '16px',
    },
  },
  square: {
    [theme.breakpoints.up('sm')]: {
      aspectRatio: '2 / 1',
    },
    [theme.breakpoints.down('sm')]: {
      aspectRatio: '1 / 1',
    },
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    '& > *:first-child': {
      margin: '7px',
    },
  },
  flexLeft: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  flexDynamic: {
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  borderRadius_20_10: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px',
    },
  },
  hyperlink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const titleStyle = {
  fontFamily: 'Soleil',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '38px',
  color: '#273A07',
};

export const itemStyle = {
  fontFamily: 'Albiona',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#273A07',
};

export const pairArray = (arr) => {
  if (arr.length === 0) return [null];
  const result = [[arr[0]]];
  let temp = [];
  for (let i = 1; i < arr.length; i++) {
    if (i % 2 === 0) {
      temp.push(arr[i]);
      result.push(temp);
      temp = [];
    } else {
      temp.push(arr[i]);
    }
  }

  if (temp.length > 0) {
    result.push(temp);
  }

  return result;
};
