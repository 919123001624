import React from "react";
const UserProfileCircle = ({ profile }) => {
  return (
    <div className="user-profile-circle--container">
      <img
        src={profile.ui_image_location}
        className="user-profile-circle--image"
      />
    </div>
  );
};

export default UserProfileCircle;
