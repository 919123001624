import React, { useState, useEffect, useCallback } from 'react';
import {
  Input,
  TextArea,
  Button,
  Title,
  Tab,
  Toggle,
  MemberBlock,
} from '../../modal_modules';
import CustomScroller from 'react-custom-scroller';

const fakeData = [
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'notInvited',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'pending',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    status: 'accepted',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'accepted',
  },
];
const BlockFileViewer = ({
  inspirations,
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
}) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    about: '',
    autoRenewal: true,
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });
  const [topLabel, setTopLabel] = useState('MEMBERSHIP');
  const [currentExplorerTab, setCurrentExplorerTab] = useState('allExplorers');
  const [lastExplorerTab, setLastExplorerTab] = useState([]);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const handleSubmit = () => {
    if (startDate || endDate) {
      inputValues.start_date = startDate;
      inputValues.end_date = endDate;
    }
    postItinerary(adventure_rid, inputValues);
  };

  const renderMembership = () => {
    return (
      <div className="account-modal-membership-wrapper">
        <div className="account-modal-memebership-top">
          <img
            className="lodestar-memberhsip-image smaller-logo"
            src="/svg_logos/nav_bar_logo.svg"
          />
          <span className="account-modal-membership-subtext color-white">
            For Individuals
          </span>
        </div>
        <span className="account-modal-membership-dividers"></span>
        <div className="account-modal-memebership-middle">
          <div>
            <span className="color-white font-14">Annual Subscription</span>
          </div>
          <div>
            <span className="color-white font-14">Family Plan</span>
          </div>
          <div>
            <span className="color-white font-14">
              Renewal Date: 02-FEB-2022
            </span>
          </div>
        </div>
        <span className="account-modal-membership-dividers"></span>
        <div className="account-modal-memebership-bottom">
          <Toggle
            setToggleState={switchHandler}
            name="autoRenewal"
            value={inputValues.autoRenewal}
            text="Auto Renewal"
          />
        </div>
      </div>
    );
  };
  const renderAllExplorers = () => {
    return (
      <div className="account-explorers-container">
        {fakeData.map((ele, idx) => {
          return (
            <MemberBlock
              firstName={ele.firstName}
              lastName={ele.lastName}
              profileImage={ele.profileImage}
              inviteStatus={ele.status}
              city={ele.city}
              state={ele.state}
              member={ele}
              isAccountExplorers
              onClickHandler={() => {
                let lastTabs = lastExplorerTab;
                lastTabs.push('allExplorers');
                setCurrentExplorerTab('explorerMenu');
                setLastExplorerTab(lastTabs);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderExplorerMenu = () => {
    return (
      <div className="explorer-menu-wrapper">
        <div className="explorer-menu-title-tab">EXPLORER NAME</div>
        <div className="explorer-menu-squares">
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerImage');
              setLastExplorerTab(lastTabs);
            }}
          >
            IMAGE
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerProfile');
              setLastExplorerTab(lastTabs);
            }}
          >
            PROFILE
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerAddress');
              setLastExplorerTab(lastTabs);
            }}
          >
            ADDRESS
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerPassword');
              setLastExplorerTab(lastTabs);
            }}
          >
            PASSWORD
          </div>
        </div>
      </div>
    );
  };

  const renderExplorerImage = () => {
    return (
      <div className="explorer-image-wrapper">
        <div className="explorer-title-tab">EXPLORER NAME</div>
        <div className="explorer-image-content-container"></div>
      </div>
    );
  };

  const renderExplorerProfile = () => {
    return (
      <div className="explorer-profile-wrapper">
        <div className="explorer-title-tab">EXPLORER NAME</div>
        <div className="explorer-input-content-container">
          <Input
            label="First Name"
            name="first_name"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Last Name"
            name="last_name"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Username"
            name="username"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Email"
            name="email"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Phone"
            name="phone"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
        </div>
      </div>
    );
  };

  const renderExplorerAddress = () => {
    return (
      <div className="explorer-address-wrapper">
        <div className="explorer-title-tab">EXPLORER NAME</div>
        <div className="explorer-input-content-container">
          <Input
            label="Address 1"
            name="address1"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Address 2"
            name="address2"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="City"
            name="city"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Zip/Postal Code"
            name="zip"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Country"
            name="country"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
        </div>
      </div>
    );
  };

  const renderExplorerPassword = () => {
    return (
      <div className="explorer-password-wrapper">
        <div className="explorer-title-tab">EXPLORER NAME</div>
        <div className="explorer-input-content-container">
          <Input
            label="Current password"
            name="current_password"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="New password"
            name="new_password"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
          <Input
            label="Confirm new password"
            name="confirm_new_password"
            onChangeHandler={handleOnChange}
            spacingTop="5px"
            spacingBottom="5px"
          />
        </div>
      </div>
    );
  };
  const renderExplorers = () => {
    return (
      <div className="account-modal-explorers-wrapper">
        {currentExplorerTab === 'allExplorers' && renderAllExplorers()}
        {currentExplorerTab === 'explorerMenu' && renderExplorerMenu()}
        {currentExplorerTab === 'explorerImage' && renderExplorerImage()}
        {currentExplorerTab === 'explorerProfile' && renderExplorerProfile()}
        {currentExplorerTab === 'explorerAddress' && renderExplorerAddress()}
        {currentExplorerTab === 'explorerPassword' && renderExplorerPassword()}

        <Button
          spacingBottom="5px"
          text="BACK"
          isAltButton
          handleIsAltButton={() => {
            let lastTabOpened = lastExplorerTab[lastExplorerTab.length - 1];
            let lastTabs = lastExplorerTab;
            lastTabs.pop();
            setLastExplorerTab(lastTabs);
            setCurrentExplorerTab(lastTabOpened);
          }}
        />
      </div>
    );
  };
  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div
        className="mod-type1-padder "
        style={{ height: '440px', maxHeight: '440px' }}
      >
        <Title closeModal={closeModal} title="ACCOUNT" />

        <CustomScroller className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Input
              label="Name"
              name="name"
              onChangeHandler={handleOnChange}
              value={inputValues.name}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <TextArea
              label="About"
              name="about"
              onChangeHandler={handleOnChange}
              value={inputValues.about}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <div className="mod-tabs-wrapper" style={{ marginBottom: '0px' }}>
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'MEMBERSHIP'}
                text="MEMBERSHIP"
              />
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'EXPLORERS'}
                text="EXPLORERS"
              />
            </div>
            {topLabel === 'MEMBERSHIP' && renderMembership()}
            {topLabel === 'EXPLORERS' && renderExplorers()}
          </form>
        </CustomScroller>
        <Button spacingBottom="20px" text="SAVE" />
      </div>
    </div>
  );
};

export default BlockFileViewer;
