export function atv (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      className={outerLayer}
    >
      <g>
        <g>
          <g>
            <path
              className={className}
              d="M41.9,73.3c1.9-5.8,3.7-12.3,4.4-16.1c-2.7-1-5.4-1.7-7.9-2.3c-2.8-0.7-6.9-2-9.5-3.1c-0.7-0.3-1.3-0.7-1.9-1.1     c-8.1-1.9-15.5-2.8-18.4-3.1c-0.6-0.1-1.1,0.5-1,1.1l0.7,4.7c0.1,0.3,0.2,0.6,0.5,0.7l6.4,3.3l-1.1,4.9c1.3-0.3,2.6-0.4,4-0.4     c10.7,0,19.5,8.7,19.5,19.5h7.2C42.2,79.8,40.8,76.4,41.9,73.3z"
            ></path>
            <path
              className={className}
              d="M81.7,61.9c3.8,0,7.4,1.1,10.4,3l0.3-7.3c0-0.8-0.4-1.6-1.1-2l-14.5-7.7l-6.4-11.2c-0.3-0.4-0.7-0.7-1.2-0.7h-4.4     c-0.7-0.5-1.6-0.7-2.5-0.6c-3.1,0.5-6.6,0.8-8.2,0.5c-0.8-2.6-1.9-8.4-2.4-11.7c-0.2-1-1.1-1.9-1.6-2.3S48.9,21,48,20.6     c-5.4-2.3-7.1-0.8-11.8,4.4c-4,4.5-7.6,11.5-8.7,17.3c-0.2,0.5-0.3,1.1-0.4,1.9c-0.3,2.2,1,4.1,3,5c2.2,1,6.1,2.2,9.1,3     c3.2,0.8,6.8,1.8,10.2,3.3C49,59,46.8,67.4,44.5,74.2c-0.7,2.1,0.4,4.4,2.6,5.2c0.6,0.2,1.2,0.2,1.7,0.2c1.5-0.2,2.9-1.2,3.4-2.8     c0.1-0.2,1.9-5.6,3.3-11.2c2.6-9.7,3.1-14.7-0.9-16.6c-3.9-1.9-8-3.1-11.5-4.1c1.2-3,2.7-6.5,4.1-9.4c0.4,1.6,0.8,3,1.2,3.9     c0.4,1,1.4,3.2,7.5,3.2c1.9,0,4.4-0.2,7.4-0.8c0.7-0.1,1.2-0.4,1.7-0.9h2l3.9,6.8c-1.5,0.6-3.3,1.6-5.3,3.5c-0.9,0.9-2.9,2-5.4,3     c0.1,3.7-1,8-2.1,12c-1.5,5.8-3.4,11.3-3.4,11.4c-0.5,1.6-1.6,2.9-3,3.7h10.4C62.3,70.6,71,61.9,81.7,61.9z"
            ></path>
            <path
              className={className}
              d="M18.3,65.6c-8.7,0-15.8,7.1-15.8,15.8c0,8.7,7.1,15.8,15.8,15.8C26.9,97.1,34,90,34,81.4C34,72.7,26.9,65.6,18.3,65.6z      M18.3,87.1c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8C24.1,84.5,21.5,87.1,18.3,87.1z"
            ></path>
            <path
              className={className}
              d="M81.7,65.6c-8.7,0-15.8,7.1-15.8,15.8c0,8.7,7.1,15.8,15.8,15.8c8.7,0,15.8-7.1,15.8-15.8C97.5,72.7,90.4,65.6,81.7,65.6     z M81.7,87.1c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8C87.5,84.5,84.9,87.1,81.7,87.1z"
            ></path>
            <ellipse
              className={className}
              transform="matrix(0.9993 -3.746652e-02 3.746652e-02 0.9993 -0.3905 2.0015)"
              cx="53.2"
              cy="11.4"
              rx="8.5"
              ry="8.5"
            ></ellipse>
          </g>
        </g>
      </g>
    </svg>
  );
}