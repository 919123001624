import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  insertUserSusbscriptionAndSubscriptionId,
  updateSubScriptionPartnerCode,
  sendBoardingUser,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { updateSessionToken } from '../../../actions/session_actions';
import { fetchPlanFinalPrice } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { PayPalButton } from './subscription_modules';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { insertUserSubscription } from '../../../util/api_util/user_api_util';
import { merge } from 'lodash';
import { Headers } from './shared_modules';
const mapStateToProps = ({ session, entities }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  return {
    userInfo,
    currentExplorerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPlanFinalPrice: (data) => dispatch(fetchPlanFinalPrice(data)),
    insertUserSusbscriptionAndSubscriptionId: (data) =>
      dispatch(insertUserSusbscriptionAndSubscriptionId(data)),
    updateSubScriptionPartnerCode: (data) =>
      dispatch(updateSubScriptionPartnerCode(data)),
    sendBoardingUser: (user) => dispatch(sendBoardingUser(user)),
    updateSessionToken: (data) => dispatch(updateSessionToken(data)),
    fetchAccountExplorer: (accountRid, explorerRid) =>
      dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  };
};

const CheckoutPage = ({
  location,
  fetchPlanFinalPrice,
  history,
  userInfo,
  insertUserSusbscriptionAndSubscriptionId,
  updateSubScriptionPartnerCode,
  sendBoardingUser,
  currentExplorerDetails,
  updateSessionToken,
  fetchAccountExplorer,
  ...props
}) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const isOnboarding = props.match.params.plan_rid ? true : false;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const [promoCode, setPromoCode] = useState('');
  const [discounted, setDiscounted] = useState(false);
  const [discountedFinalPrice, setDiscountedFinalPrice] = useState('');
  const [promoCodeError, setPromoCodeError] = useState({
    display: false,
    errorMessage: '',
  });
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discount, setDiscount] = useState(0.0);
  const [planDataAndExplorer, setPlanDataAndExplorer] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [promotionDescription, setPromotionDescription] = useState('');
  const [
    promocodeMembershipDuration,
    setPromocodeMembershipDuration,
  ] = useState(null);
  const handleChange = useCallback((e) => {
    const { name, value } = e.currentTarget;
    setPromoCode(value);
  });
  useEffect(() => {
    scrollTop();
  }, []);
  function generateSubscriptionPayload() {
    let payload;
    if (planDataAndExplorer) {
      payload = discounted
        ? {
            // plan_id: planDataAndExplorer.plan.plan_code, //plan id goes here÷÷
            plan_id: planDataAndExplorer.plan.plan_code,
            plan: {
              billing_cycles: [
                {
                  frequency: {
                    interval_unit:
                      planDataAndExplorer.plan.plan_duration_month === 1
                        ? 'MONTH'
                        : 'YEAR',
                    interval_count: 1,
                  },
                  tenure_type: 'TRIAL',
                  sequence: 1,
                  total_cycles: 1,
                  pricing_scheme: {
                    fixed_price: {
                      currency_code: 'USD',
                      value: discountedFinalPrice,
                    },
                  },
                },
                {
                  frequency: {
                    interval_unit:
                      planDataAndExplorer.plan.plan_duration_month === 1
                        ? 'MONTH'
                        : 'YEAR',
                    interval_count: 1,
                  },
                  tenure_type: 'REGULAR',
                  sequence: 2,
                  total_cycles: 0,
                  pricing_scheme: {
                    fixed_price: {
                      value: parseFloat(
                        planDataAndExplorer.plan.plan_price,
                      ).toFixed(2),
                      currency_code: 'USD',
                    },
                  },
                },
              ],
            },
            // plan_id: planDataAndExplorer ? planDataAndExplorer.plan.plan_code : null, //plan id goes here
          }
        : {
            // plan_id: planDataAndExplorer.plan.plan_code, //plan id goes here÷÷
            plan_id: planDataAndExplorer.plan.plan_code,
            plan: {
              billing_cycles: [
                {
                  frequency: {
                    interval_unit:
                      planDataAndExplorer.plan.plan_duration_month === 1
                        ? 'MONTH'
                        : 'YEAR',
                    interval_count: 1,
                  },
                  tenure_type: 'TRIAL',
                  sequence: 1,
                  total_cycles: 1,
                  pricing_scheme: {
                    fixed_price: {
                      currency_code: 'USD',
                      value: parseFloat(
                        planDataAndExplorer.plan.plan_price,
                      ).toFixed(2),
                    },
                  },
                },
                {
                  sequence: 2,
                  total_cycles: 0,
                  tenure_type: 'REGULAR',
                  frequency: {
                    interval_unit:
                      planDataAndExplorer.plan.plan_duration_month === 1
                        ? 'MONTH'
                        : 'YEAR',
                    interval_count: 1,
                  },
                  pricing_scheme: {
                    fixed_price: {
                      value: parseFloat(
                        planDataAndExplorer.plan.plan_price,
                      ).toFixed(2),
                      currency_code: 'USD',
                    },
                  },
                },
              ],
            },
            // plan_id: planDataAndExplorer ? planDataAndExplorer.plan.plan_code : null, //plan id goes here
          };
    } else {
      payload = {
        // plan_id: planDataAndExplorer.plan.plan_code, //plan id goes here÷÷
        // plan_id: 'P-063939876U130082KMF5CIKY',

        plan_id: planDataAndExplorer
          ? planDataAndExplorer.plan.plan_code
          : null, //plan id goes here
      };
    }
    return payload;
  }

  const paypalSubscribe = async (data, actions) => {
    let payload = generateSubscriptionPayload();
    return actions.subscription.create(payload);
  };
  const paypalOnError = (err) => {
    console.log('Error'); // call the backend api to  store error flag for our db
  };

  const paypalOnApprove = (data, detail) => {
    // if(!accessToken === false) {
    //   let data = {
    //     reason: 'I am canceling', // replace this with reaosn
    //   };
    //   let config = {
    //     method: 'post',
    //     url: `https://api-m.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/cancel`, // the I- has to be passed from the user's subscription id
    //     // url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/cancel`, // the I- has to be passed from the user's subscription id
    //     headers: {
    //       Authorization: `Bearer ${accessToken.access_token}`,
    //     },
    //     data,
    //   };
    //   // console.log("cancel subscription config=========>", config)
    //   axios(config).then((res) => {
    //     console.log("previous subscription is cancelled");
    //   });
    // }
    // call the backend api to store transaction details for our db with subscription_id
    let updateSubscriptionPayload = merge({}, planDataAndExplorer.account_info);
    const { subscription_rid, email } = planDataAndExplorer.account_info;
    const { explorer_rid } = planDataAndExplorer.explorer;
    let payload = {
      explorer_rid,
      subscription_rid,
      subscription_partner_code: data.subscriptionID,
    };
    payload.membershipUpgrade = true;
    if (isOnboarding) {
      updateSubScriptionPartnerCode(payload).then((res) => {
        if (res.status === 200) {
          let path = '/sign-up-success';
          sendBoardingUser({
            subscription_rid,
            email,
          });
          history.push(path);
        }
      });
    } else {
      updateSubscriptionPayload.plan_rid = planDataAndExplorer.plan.plan_rid;
      updateSubscriptionPayload.auto_renew_flag = true;
      updateSubscriptionPayload.subscription_partner_code =
        payload.subscription_partner_code;
      insertUserSusbscriptionAndSubscriptionId(updateSubscriptionPayload).then(
        (res) => {
          if (res.status === 200) {
            payload.subscription_rid =
              res.data.userSubscription[0].subscription_rid;
            updateSubScriptionPartnerCode(payload).then((res2) => {
              if (res2.status === 200) {
                updateSessionToken({
                  plan_rid: planDataAndExplorer.plan.plan_rid,
                  subscription_rid:
                    res.data.userSubscription[0].subscription_rid,
                });
                history.push('payment-succesful');
              }
            });
          }
        },
      );
    }
    console.log('Payapl approved');
    console.log(data.subscriptionID);
  };

  const updateSubscriptionHandler = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let now = new Date().toISOString();
    let data = {
      plan_id: planDataAndExplorer ? planDataAndExplorer.plan.plan_code : null,
      effective_time: now,
      application_context: {
        brand_name: 'LodestarHub',
        locale: 'en-US',
        shipping_preference: 'SET_PROVIDED_ADDRESS',
        payment_method: {
          payer_selected: 'PAYPAL',
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED',
        },
        return_url: `${
          process.env.REACT_APP_RUN_MODE === 'PROD'
            ? process.env.REACT_APP_PROD_HUB_URL
            : process.env.REACT_APP_RUN_MODE === 'TEST'
            ? process.env.REACT_APP_TEST_HUB_URL
            : process.env.REACT_APP_DEV_HUB_URL
        }/hub/account/payment-succesful?plan_rid=${
          planDataAndExplorer.plan.plan_rid
        }`, // we need to now think of the logic for updating effective time and return url to display the correct information
        cancel_url: `${
          process.env.REACT_APP_RUN_MODE === 'PROD'
            ? process.env.REACT_APP_PROD_HUB_URL
            : process.env.REACT_APP_RUN_MODE === 'TEST'
            ? process.env.REACT_APP_TEST_HUB_URL
            : process.env.REACT_APP_DEV_HUB_URL
        }/hub/account/subscription`,
      },
    };
    //revisit after signup for update
    let config = {
      method: 'post',
      url: `https://api-m.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/revise`, // the I- has to be passed from the user's subscription id
      // url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/revise`, // the I- has to be passed from the user's subscription id
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
      },
      data,
    };
    let returned = await axios(config)
      .then((res) => {
        config.method = res.data.links[0].method;
        config.url = res.data.links[0].href;
        delete config.data;
        window.location = res.data.links[0].href;
      })
      .catch((err) => {});
  });
  useEffect(() => {
    if (!currentExplorerDetails && !isOnboarding)
      fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      // var data = qs.stringify({
      //   grant_type: 'client_credentials',
      // });
      try {
        let configlive = {
          method: 'POST',
          url: 'https://api-m.paypal.com/v1/oauth2/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Credentials': true,
          },
          data: qs.stringify({
            grant_type: 'client_credentials',
          }),
          auth: {
            username:
              'AaqHK7225WylmflFkWUBJRX8MrHV5I5Poe6phsNdPwVm8CQsAIZkO3AtM1XlgRqNghN9qpp_qAlAbX1U',
            password:
              'EFAlBCzsaqIygCMoWw2hvaIVlMqpr9v3GJTHLnLLqt0sKnJIDJwUuJY4r7nkHAl7DJ0GH1HYDRDMp7nE',
          },
        };
        let configsandbox = {
          method: 'POST',
          url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Credentials': true,
          },
          data: qs.stringify({
            grant_type: 'client_credentials',
          }),
          auth: {
            username:
              'AdRq-0Ie3ZoKkMxYztBN-2XtI_X24J1eu92e_4o8cp355xRHMk0M126OMEFEqqcUAh0_OuClw_oM6JyB',
            password:
              'EGh4Q3e2Eiixf3C0qejMJTbEl5bMTcH4ohoyq1BwD0OYFdXbwQeGZ9EDq_7AHkGB5KqRrriU9p9ituhD',
          },
        };
        // let config = {
        //   method: 'post',
        //   url: 'https://api-m.paypal.com/v1/oauth2/token',
        //   // url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
        //   headers: {
        //     Authorization:
        //       'Basic QWRScS0wSWUzWm9La014WXp0Qk4tMlh0SV9YMjRKMWV1OTJlXzRvOGNwMzU1eFJITWswTTEyNk9NRUZFcXFjVUFoMF9PdUNsd19vTTZKeUI6RUdoNFEzZTJFaWl4ZjNDMHFlak1KVGJFbDViTVRjSDRvaG95cTFCd0QwT1lGZFhid1FlR1o5RURxXzdBSGtHQjVLcVJycmlVOXA5aXR1aEQ=',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        //   data,
        // };
        axios(configlive).then(({ data }) => {
          const { access_token, token_type } = data;
          setAccessToken({
            access_token,
            token_type,
          });
        });
        // debugger
      } catch (err) {}
    };
    fetchToken();
    // if (location.state && location.state.isUpdate) {
    //   fetchToken();
    // }
    if (location.state && location.state.plan) {
      setPlanDataAndExplorer({
        plan: location.state.plan,
        explorer: location.state.currentExplorerDetails,
        account_info: location.state.account_info,
      });
    }
    if (location.state && location.state.isAmbassadorInvite) {
      setPromoCode(location.state.ambassador_code);
    }
    if (location.state && location.state.isPromoted) {
      setPromoCode(location.state.promotionCode);
    }
  }, []);
  useEffect(() => {
    if (location.state && location.state.isAmbassadorInvite) {
      if (promoCode === location.state.ambassador_code) {
        document.getElementById('apply-promocode-button').click();
      }
    }
    if (location.state && location.state.isPromoted) {
      if (promoCode === location.state.promotionCode) {
        document.getElementById('apply-promocode-button').click();
      }
    }
  }, [promoCode]);

  const handleApplyPromoCode = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      planDataAndExplorer.plan.promotion_code_ambassador_code.includes(
        promoCode,
      )
    ) {
      axios
        .patch('/api/ui/plan/price', {
          plan_rid: planDataAndExplorer.plan.plan_rid,
          promotion_code_ambassador_code: promoCode,
        })
        .then((res) => {
          // let newPlan = merge({}, planDataAndExplorer.plan);
          // newPlan.plan_price = res.data.planFinalPrice[0].final_price;
          const {
            discount_amount,
            discount_percent,
            plan_duration,
            plan_duration_label,
            subscription_start_date,
            subscription_end_date,
            final_price,
            promotion_description,
          } = res.data.planFinalPrice[0];
          setDiscount(parseFloat(parseFloat(discount_amount).toFixed(2)));
          setDiscountPercent(parseFloat(discount_percent) * 100);
          setPromoCodeError({
            display: false,
            errorMessage: '',
          });
          setPromocodeMembershipDuration({
            duration: `${plan_duration} ${plan_duration_label}`,
            start_date: new Date(subscription_start_date),
            end_date: new Date(subscription_end_date),
          });
          setDiscounted(true);
          setDiscountedFinalPrice(parseFloat(final_price).toFixed(2));
          setPromotionDescription(promotion_description);
          // setPlanDataAndExplorer({ ...planDataAndExplorer, plan: newPlan });
        });
    } else {
      setPromoCodeError({
        display: true,
        errorMessage: 'Invalid Promo Code',
      });
      setDiscounted(false);
    }
  });
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  let today = new Date();
  let endingDate = new Date();
  if (planDataAndExplorer)
    endingDate.setMonth(
      today.getMonth() + planDataAndExplorer.plan.plan_duration_month,
    );
  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id={
            userInfo
              ? 'account-page-container-responsive'
              : 'account-page-onboard-responsive'
          }
        >
          <Headers text="CHECKOUT" />
          {planDataAndExplorer && (
            <>
              <section className="subscription-checkout-plan-detail-wrapper">
                <div className="subscription-checkout-plan-detail-top">
                  {/* <img
                    className="subscription-checkout-plan-detail-top-image"
                    src={planDataAndExplorer.plan.ui_image_location}
                  /> */}
                  <div className="subscription-checkout-plan-detail-top-name">
                    <span>{planDataAndExplorer.plan.plan_name}</span>
                  </div>
                </div>
              </section>
              <section className="subscription-checkout-plan-prices-section">
                <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Plan Subtotal</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>
                      $
                      {Number.parseFloat(
                        planDataAndExplorer.plan.plan_price,
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
                {/* <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Membership Duration</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>
                      {promocodeMembershipDuration
                        ? promocodeMembershipDuration.duration
                        : `${planDataAndExplorer.plan.plan_duration_month} ${
                            planDataAndExplorer.plan.plan_duration_month > 1
                              ? 'months'
                              : 'month'
                          } `}
                    </span>
                  </div>
                </div>
                <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Start Date</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>
                      {promocodeMembershipDuration
                        ? promocodeMembershipDuration.start_date.toDateString()
                        : `${today.toDateString()} `}
                    </span>
                  </div>
                </div>
                <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Renewal Date</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>
                      {promocodeMembershipDuration
                        ? promocodeMembershipDuration.end_date.toDateString()
                        : `${endingDate.toDateString()}
                          `}
                    </span>
                  </div>
                </div> */}

                <section className="subscription-checkout-promotion-wrapper">
                  <div className="subscription-promotion-inner-wrapper">
                    <div className="subscription-checkout-promotion-text-wrapper">
                      <span>
                        Enter a{' '}
                        {/* {planDataAndExplorer.plan.plan_rid === 5
                        ? "ambassador"
                        : "promo"}{" "} */}
                        promo code
                      </span>
                    </div>
                    <div className="input-and-button-wrapper">
                      {promoCodeError.display && (
                        <>
                          <div className="promocode-error-container">
                            <span>&#10006;</span>
                          </div>
                          {/* <div className="promocode-error-message-container">
                            <span>{promoCodeError.errorMessage}</span>{' '}
                          </div> */}
                        </>
                      )}
                      <input
                        className="subscription-checkout-promocode-input"
                        name="promoCode"
                        value={promoCode}
                        onChange={handleChange}
                      />
                      <button
                        className="subscription-checkout-apply-button"
                        onClick={handleApplyPromoCode}
                        id="apply-promocode-button"
                      >
                        APPLY
                      </button>
                    </div>
                  </div>
                </section>
                {/* <div className="subscription-prices-price-detail-wrapper --promotion-description">
                  <div className="subscription-prices-header-section">
                    <span>Promo Description</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>{promotionDescription && promotionDescription}</span>
                  </div>
                </div> */}
                {/* <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Discount percent</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>{discountPercent}%</span>
                  </div>
                </div> */}
                <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Discount amount</span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>${Number.parseFloat(discount).toFixed(2)}</span>
                  </div>
                </div>

                <div className="subscription-prices-price-detail-wrapper">
                  <div className="subscription-prices-header-section">
                    <span>Plan Total </span>
                  </div>
                  <div className="subscription-prices-prices-section">
                    <span>
                      $
                      {Number.parseFloat(
                        planDataAndExplorer.plan.plan_price,
                      ).toFixed(2) - Number.parseFloat(discount).toFixed(2)}
                    </span>
                  </div>
                </div>
              </section>
              {/* plan-detail */}
              {/* <section></section> */}
              {/* contact */}
              {/* <section></section> */}
              {/* billing */}

              {/* promotion*/}
              <section className="subscription-checkout-paypal-buttons-wrapper">
                {location.state &&
                location.state.currentExplorerDetails.plan_rid === 20 ? (
                  <button
                    className="subscription-checkout-update-button"
                    onClick={updateSubscriptionHandler}
                  >
                    <span>Upgrade</span>
                  </button>
                ) : (
                  <PayPalButton
                    currency="USD"
                    createSubscription={paypalSubscribe}
                    onApprove={paypalOnApprove}
                    catchError={paypalOnError}
                    onError={paypalOnError}
                    onCancel={paypalOnError}
                  />
                )}
                {}
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutPage),
);
