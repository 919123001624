export const svgPreferences = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="94.907"
      height="91.3"
      viewBox="0, 0, 94.907, 91.3"
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M2.1,18.9 L18.8,18.9 L18.8,26.3 C18.8,27.3 19.6,28.2 20.7,28.2 L33,28.2 C34,28.2 34.9,27.4 34.9,26.3 L34.9,1.9 C34.9,0.9 34.1,-0 33,-0 L20.8,-0 C19.8,-0 18.9,0.8 18.9,1.9 L18.9,9.3 L2.1,9.3 C0.9,9.3 -0,10.3 -0,11.4 L-0,16.7 C-0,18 1,18.9 2.1,18.9 z"
            fill={color}
          />
          <path
            className={className}
            d="M92.9,9.3 L41,9.3 L41,18.9 L92.8,18.9 C94,18.9 94.9,17.9 94.9,16.8 L94.9,11.5 C95,10.2 94,9.3 92.9,9.3 z"
            fill={color}
          />
          <path
            className={className}
            d="M92.9,40.9 L74.2,40.9 L74.2,50.5 L92.8,50.5 C94,50.5 94.9,49.5 94.9,48.4 L94.9,43.1 C95,41.8 94,40.9 92.9,40.9 z"
            fill={color}
          />
          <path
            className={className}
            d="M2.1,50.5 L52,50.5 L52,57.9 C52,58.9 52.8,59.8 53.9,59.8 L66.2,59.8 C67.2,59.8 68.1,59 68.1,57.9 L68.1,33.5 C68.1,32.5 67.3,31.6 66.2,31.6 L54,31.6 C53,31.6 52.1,32.4 52.1,33.5 L52.1,40.9 L2.1,40.9 C0.9,40.9 -0,41.9 -0,43 L-0,48.3 C-0,49.6 1,50.5 2.1,50.5 z"
            fill={color}
          />
          <path
            className={className}
            d="M92.9,72.5 L41,72.5 L41,82.1 L92.8,82.1 C94,82.1 94.9,81.1 94.9,80 L94.9,74.7 C95,73.5 94,72.5 92.9,72.5 z"
            fill={color}
          />
          <path
            className={className}
            d="M33,63.2 L20.8,63.2 C19.8,63.2 18.9,64 18.9,65.1 L18.9,72.5 L2.1,72.5 C0.9,72.5 -0,73.5 -0,74.6 L-0,79.9 C-0,81.1 1,82 2.1,82 L18.8,82 L18.8,89.4 C18.8,90.4 19.6,91.3 20.7,91.3 L33,91.3 C34,91.3 34.9,90.5 34.9,89.4 L34.9,65.1 C34.9,64 34.1,63.2 33,63.2 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};