import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Input, TextArea, Button, Title, Tab } from '../../modal_modules';
import {
  upsertAdventureGroup,
  upsertAccountGroupAndAddMembers,
} from 'actions/social_actions';
import LodestarMembers from './render_members';
import { merge } from 'lodash';
import { convertToObjectString } from 'util/helpers/database_friendly_converter';
import Invites from './render_invite';
import CustomScroller from 'react-custom-scroller';

const mapStateToProps = (state) => {
  let userAdventureFriends = state.entities.social.adventureFriends
    ? state.entities.social.adventureFriends
    : null;
  let adventureGroupModalType = state.ui.modal.adventureGroupModalType
    ? state.ui.modal.adventureGroupModalType
    : null;

  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let currentAdventureGroups = state.dataCache.modal.currentAdventureGroups
    ? state.dataCache.modal.currentAdventureGroups
    : null;
  let userAccountGroup = state.entities.social.userAccountGroup
    ? state.entities.social.userAccountGroup
    : null;
  return {
    userAdventureFriends,
    adventureGroupModalType,
    userInfo,
    currentAdventureGroups,
    userAccountGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upsertAccountGroupAndAddMembers: (data) =>
      dispatch(upsertAccountGroupAndAddMembers(data)),
    upsertAdventureGroup: (data) => dispatch(upsertAdventureGroup(data)),
  };
};
const AccountGroup = ({
  closeModal,
  userAdventureFriends,
  adventureGroupModalType,
  userInfo,
  currentAdventureGroups,
  upsertAdventureGroup,
  upsertAdventureGroupAndAddMembers,
  userAccountGroup,
}) => {
  const [inputValues, setInputValues] = useState({
    group_name: currentAdventureGroups ? currentAdventureGroups.group_name : '',
    group_description: currentAdventureGroups
      ? currentAdventureGroups.group_description
      : '',
    account_group_rid: currentAdventureGroups
      ? currentAdventureGroups.account_group_rid
      : null,
  });
  const [topLabel, setTopLabel] = useState(
    adventureGroupModalType.isEdit ? 'MEMBERS' : 'INVITE',
  );
  const [localListOfUserFriends, setLocalListOfUserFriends] = useState(null);
  const [listOfFriendsToAdd, setListOfFriendsToAdd] = useState([]);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  useEffect(() => {
    let localized = merge([], userAdventureFriends);
    setLocalListOfUserFriends(localized);
  }, [userAdventureFriends]);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let listOfMemberToAdd = convertToObjectString(listOfFriendsToAdd);
    let passOver = merge({}, inputValues);
    passOver['friend_rid'] = listOfMemberToAdd;
    passOver['account_rid'] = userInfo.account_rid;
    passOver['sender_rid'] = userInfo.explorer_rid;
    adventureGroupModalType.isEdit
      ? upsertAdventureGroup(passOver)
      : upsertAdventureGroupAndAddMembers(passOver);
    closeModal();
  };
  const handleAddMember = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    const { value } = e.currentTarget;
    let modifiEdFriendsList = merge([], listOfFriendsToAdd);
    modifiEdFriendsList.push(value);
    let modifiedLocalList = localListOfUserFriends.map((ele, idx) => {
      if (ele.friend_rid === parseInt(value)) {
        ele['placeholder_status'] = 'pending';
        return ele;
      } else return ele;
    });
    setListOfFriendsToAdd(modifiEdFriendsList);
    setLocalListOfUserFriends(modifiedLocalList);
  });

  return (
    <div className="mod-type1-wrapper" id="cre-ad" style={{ height: '440px' }}>
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="TRIP GROUP" />

        <CustomScroller className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Input
              label="Name"
              name="group_name"
              onChangeHandler={handleOnChange}
              value={inputValues.group_name}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <TextArea
              abel="Name"
              name="group_description"
              onChangeHandler={handleOnChange}
              value={inputValues.group_description}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <div className="mod-tabs-wrapper">
              {adventureGroupModalType.isEdit && (
                <Tab
                  handleClick={setTopLabel}
                  selected={topLabel === 'MEMBERS'}
                  text="MEMBERS"
                />
              )}
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'INVITE'}
                text="INVITE"
              />
            </div>
            <div className="mod-inv-friends-contents--wrapper">
              {topLabel === 'MEMBERS' ? (
                <LodestarMembers
                  friends={localListOfUserFriends}
                  isAccountGroupEdit={adventureGroupModalType.isEdit}
                  handleAddMember={handleAddMember}
                />
              ) : (
                <Invites
                  userAccountGroups={userAccountGroup}
                  adventureFriends={userAdventureFriends}
                />
              )}
            </div>
          </form>
        </CustomScroller>
        <Button spacingBottom="20px" spacingTop="10px" text="SAVE" />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountGroup);
