
export const svgAccountMenuProfile = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="95"
      height="66.593"
      className={outerLayer}
      viewBox="0, 0, 95, 66.593"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M0,0 L0,66.593 L95,66.593 L95,0 L0,0 z M4.159,59.445 L39.135,59.445 L39.135,61.796 L4.159,61.796 L4.159,59.445 z M46.307,23.449 L87.11,23.449 L87.11,25.812 L46.307,25.812 L46.307,23.449 z M66.674,11.643 L87.11,11.643 L87.11,14.007 L66.674,14.007 L66.674,11.643 z M24.839,27.075 C29.867,27.191 33.945,31.327 33.945,36.378 L33.945,51.022 L9.72,51.022 L9.72,36.378 C9.72,31.327 13.798,27.191 18.826,27.075 C16.092,25.905 14.18,23.194 14.18,20.031 C14.18,15.814 17.61,12.385 21.827,12.385 C26.055,12.385 29.485,15.814 29.485,20.031 C29.485,23.194 27.573,25.905 24.839,27.075 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};