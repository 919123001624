import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { Grid } from '@mui/material';
import { Box } from '@material-ui/core';
import { merge } from 'lodash';
import { postAdventure, fetchSingleAdventure } from 'actions/adventure_actions';
import { openUnsplashModal, flushModal } from 'actions/modal_actions';
import { persistTripData } from 'actions/cache_actions';
import { handleFileUpload } from '../../../global_modules/bucket';
import HubCircularProgress from '../../../common/circular-progress/circular-progress';
import {
  fetchAdventureImages,
  upsertAdventureImage,
  deleteAdventureImage,
} from '../../../../util/api_util/adventure_api_util';
import {
  RequiredInputFields,
  RequiredInputFieldsSeed,
  RequiredInputFieldsEdit,
} from './_required_fields';
import {
  Input,
  // TextArea,
  Button,
  DropZone,
  Title,
  Toggle,
  DatePicker,
  ActionButtonCluster,
} from '../../modal_modules';
import HubModal from '../../modal';
import { SectionSubTitle } from '../../../common/section-title';
import { DescriptionText } from '../../../common/description-text';
import { ImageUpload } from '../../../common/image-upload';
import TextBox from '../../../common/text-box';
import Switch from '../../../common/switch';
import ActivityButton from '../../../common/activity-button';
import {
  fetchUserFriends,
  upsertAdventureFriend,
} from 'actions/social_actions';
import { fetchAccountExplorer } from 'actions/user_actions';
import { fetchTransportationTypes } from 'actions/ui_actions';
import { withRouter } from 'react-router-dom';
import urlencode from 'urlencode';
import useMaskingHooks from 'util/custom_hooks/maskUrl';
import NotifcationPaylods from 'util/notification/return_payloads';
import functionLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import FriendBlock from '../../modal_modules/_friend_block';
import { promiseImpl } from 'ejs';

const mapStateToProps = (state) => {
  const account_rid = state.session?.user?.account_rid || null;
  const startDate = state.ui?.modal?.selectedDates || null;
  const endDate = state.ui?.modal?.selectedDates?.endDate || null;
  const inspiration_rid = state.ui?.modal?.inspiration_rid || null;
  const original_adventure = state.ui?.modal?.current_adventure || null;
  const isAdventureEdit = state.ui?.modal?.isAdventureEdit || null;
  const currentInspirationRid = state.ui?.modal?.currentInspirationRid || null;
  const persistedTripData = state.dataCache?.modal?.tripDataHolding || null;
  const userInfo = state.session?.user?.userInfo[0] || null;
  const userFriends = state.entities?.social?.userFriends;
  const allAccountExplorers =
    state.entities?.users?.allAccountExplorers || null;
  const transportationTypes = state.ui?.components?.transportationTypes || null;
  return {
    account_rid,
    startDate,
    endDate,
    inspiration_rid,
    original_adventure,
    isAdventureEdit,
    currentInspirationRid,
    persistedTripData,
    userInfo,
    userFriends,
    allAccountExplorers,
    transportationTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAdventure: (account_rid, data) =>
      dispatch(postAdventure(account_rid, data)),
    openUnsplashModal: (searchTermObject, origin) =>
      dispatch(openUnsplashModal(searchTermObject, origin)),
    persistTripData: (status, data) => dispatch(persistTripData(status, data)),
    flushModal: () => dispatch(flushModal()),
    fetchAccountExplorer: (account_rid, explorer_rid) =>
      dispatch(fetchAccountExplorer(account_rid, explorer_rid)),
    fetchUserFriends: (account_rid, page_number, page_size) =>
      dispatch(fetchUserFriends(account_rid, page_number, page_size)),
    fetchTransportationTypes: () => dispatch(fetchTransportationTypes()),
    upsertAdventureFriend: (data) => {
      dispatch(upsertAdventureFriend(data));
    },
    fetchSingleAdventure: (data) => {
      dispatch(fetchSingleAdventure(data));
    },
  };
};

const CreateAdventure = ({
  closeModal,
  postAdventure,
  account_rid,
  inspiration_rid,
  startDate,
  endDate,
  original_adventure,
  isAdventureEdit,
  currentInspirationRid,
  openUnsplashModal,
  fetchedOnlineImage,
  flushModal,
  userInfo,
  history,
  persistedTripData,
  persistTripData,
  fetchUserFriends,
  fetchAccountExplorer,
  allAccountExplorers,
  userFriends,
  fetchTransportationTypes,
  transportationTypes,
  upsertAdventureFriend,
  fetchSingleAdventure,
}) => {
  const { maskURL } = useMaskingHooks();
  const [uploading, setUploading] = useState('');
  const [goingFriends, setGoingFriends] = useState([]);
  const [goingFriendsObjects, setGoingFriendsObjects] = useState([]);
  const totalProgress = useRef(0);
  const [totalSize, setTotalSize] = useState(0);
  const [curProgress, setCurProgress] = useState(0);
  const [formErrors, setFormErrors] = useState({});

  const [inputValues, setInputValues] = useState({
    inspiration_rid: null,
    adventure_rid: original_adventure?.adventure_rid || null,
    account_rid,
    adventure_name:
      original_adventure?.adventure_name || "",
      // `${
      //   userInfo.full_name || `${userInfo.first_name} ${userInfo.last_name}`
      // }'s Trip`,
    explorer_rid: userInfo.explorer_rid,
    start_date: dayjs(original_adventure?.start_date) || null,
    end_date: dayjs(original_adventure?.end_date) || null,
    ui_image_location: [],
    adventure_description: original_adventure?.adventure_description || null,
    seed_flag: true,
    default_transportation_type_rid: original_adventure
      ? `${original_adventure.default_transportation_type_rid}`
      : 2, //default transportation set to 2 (SUV).
  });

  useEffect(() => {
    if (!userFriends) fetchUserFriends(userInfo.account_rid, 1, 100);
    if (!transportationTypes) fetchTransportationTypes();
    if (original_adventure) {
      setUploading('Loading');
      const adventureRid = original_adventure?.adventure_rid;
      fetchAdventureImages(adventureRid).then((res) => {
        const images = res.data.images;
        const transImages = [
          ...images?.map((v) => {
            const {
              adventure_file_rid,
              file_location,
              file_note,
              media_type,
            } = v;
            return {
              adventure_file_rid,
              url: file_location,
              caption: file_note,
              type: media_type,
              primary: false,
              isEdit: false,
            };
          }),
        ];
        original_adventure.ui_image_location = [...transImages];
        setInputValues({ ...inputValues, ui_image_location: transImages });
        setUploading('');
      });
    }
  }, []);

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const onImageChange = (files) => {
    console.log(files, `files`)
    setInputValues({ ...inputValues, ui_image_location: files });
  };

  const onDateChange = (dates) => {
    setInputValues({
      ...inputValues,
      start_date: dates[0],
      end_date: dates[1],
    });
  };

  const isNewImage = (image) => {
    if (!original_adventure) return true;
    if (!image.adventure_file_rid) return true;

    const originalImages = original_adventure?.ui_image_location;
    const matchedImage = originalImages?.filter(
      (v) => v.adventure_file_rid === v.adventure_file_rid,
    );

    if (matchedImage.length > 0 && matchedImage[0].file_note !== image.caption)
      return true;
    return false;
  };

  const isDeletedImage = (image) => {
    if (!image) return false;
    if (!image.adventure_file_rid) return false;
    const images = inputValues.ui_image_location;

    for (let i = 0; i < images.length; i++) {
      const value = images[i];
      if (value.adventure_file_rid === image.adventure_file_rid) return false;
    }

    return true;
  };

  const calculateTotalFileSize = async () => {
    let tSize = 0;
    const {
      ui_image_location,
    } = inputValues;
    return promiseImpl.all(
    ui_image_location.map(async (v) => {
      if (isNewImage(v)) {
        if (!v.adventure_file_rid) {
          const blob = await fetch(v.url).then((r) => r.blob());
          tSize += blob.size;
        }
      }
    })
    ).then(() => setTotalSize(tSize))
  }
  const handleSubmit = async () => {
    const {
      start_date,
      end_date,
      ui_image_location,
      adventure_name,
      default_transportation_type_rid,
    } = inputValues;
    const newFormErrors = {};
    if (!adventure_name) {
      newFormErrors.adventure_name = "Enter a trip name"
    }
    if (
      !start_date ||
      !end_date ||
      !adventure_name ||
      !default_transportation_type_rid
    ) {
      setFormErrors(newFormErrors);
      console.log('invalid data');
      return;
    }
    await calculateTotalFileSize();
    setUploading('Saving');
    const data = merge({}, inputValues);
    data.ui_image_location = null;
    postAdventure(account_rid, data).then((res) => {
      const { adventure_name, adventure_rid } = res.data;
      const originalImages = original_adventure?.ui_image_location;
      originalImages?.forEach((v) => {
        if (isDeletedImage(v)) {
          deleteAdventureImage(
            original_adventure.adventure_rid,
            v.adventure_file_rid,
          );
        }
      });
      return Promise.all(
        ui_image_location.map(async (v) => {
          if (isNewImage(v)) {
            if (v.adventure_file_rid) {
              return deleteAdventureImage(
                original_adventure.adventure_rid,
                v.adventure_file_rid,
              ).then(() =>
                upsertAdventureImage(adventure_rid, {
                  file_name: v.name,
                  file_location: v.url,
                  location_flag: false,
                  file_note: v.caption,
                  media_type: v.type,
                }),
              );
            }
            setUploading('Uploading');
           const blob = await fetch(v.url).then((r) => r.blob());
           blob['originalname'] = v.name;
            // const res = await handleFileUpload(blob);
            // console.log(res, 'upload result')
            // alert('Right');
            return handleFileUpload(blob, setCurProgress).then((res) => {
              totalProgress.current = totalProgress.current + blob.size;
              return upsertAdventureImage(adventure_rid, {
                file_name: v.name,
                file_location: res,
                location_flag: false,
                file_note: v.caption,
                media_type: v.type,
              });
            }).catch(err => alert('error'));
            // formData.set('original_name', v.name);
            // formData.set('file', blob);
            // return axios.post('/api/bucket/uploads', formData).then((res) => {
            //   const imageUrl = res.data.data;
            //   return upsertAdventureImage(adventure_rid, {
            //     file_name: v.name,
            //     file_location: imageUrl,
            //     location_flag: false,
            //     file_note: v.caption,
            //     media_type: v.type,
            //   });
            // }).catch(err => alert('error'));
          }
        }),
      ).then((r) => {
        fetchSingleAdventure(adventure_rid);
      }).catch(err => {
        console.log(err)
      }).finally(() => {
         setTimeout(() => {
          setUploading('');
          closeModal();
          setInputValues({
            inspiration_rid: null,
            adventure_name: null,
            start_date: null,
            end_date: null,
            ui_image_location: null,
            adventure_description: null,
            seed_flag: true,
          });
          history.push(`/hub/adventure/${urlencode(adventure_name, 'gbk')}/${maskURL(`${adventure_rid}`)}`);
        }, 1000)
      }
      );
    });
  };

  const handleAddAttendee = useCallback((e, friend) => {
    e.preventDefault();
    e.stopPropagation();
    const { friend_rid } = friend;
    if (goingFriends.includes(friend_rid)) {
      let idx = goingFriends.indexOf(friend_rid);
      goingFriends.splice(idx, 1);
      goingFriendsObjects.splice(idx, 1);
      setGoingFriends([...goingFriends]);
      setGoingFriendsObjects([...goingFriendsObjects]);
    } else {
      setGoingFriends([...goingFriends, friend_rid]);
      setGoingFriendsObjects([...goingFriendsObjects, friend]);
    }
  });

  const transportationTypeHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.currentTarget;
    if (inputValues.default_transportation_type_rid === value) {
      setInputValues({ ...inputValues, default_transportation_type_rid: null });
    } else {
      setInputValues({
        ...inputValues,
        default_transportation_type_rid: value,
      });
    }
  });

  const title = `${inputValues.adventure_rid ? 'EDIT' : 'BUILD'} A TRIP`;
  console.log(totalSize, `totalSize`)

  return (
    <HubModal
      show={true}
      title={title}
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
      loading = {uploading}
      loadingProcess = {totalSize !== 0 ? Math.ceil((totalProgress.current + curProgress) * 100 / totalSize) : 0}
    >
      <Box className="create-adventure-wrapper">
        <Stack spacing={2}>
          <SectionSubTitle>Destination</SectionSubTitle>
          <TextBox
            className="text-field required"
            name="adventure_name"
            label="Trip Name*"
            variant="outlined"
            error={formErrors?.adventure_name ? true : false}
            helperText={formErrors.adventure_name}
            fullWidth
            onChange={handleOnChange}
            value={inputValues.adventure_name}
            size="small"
          />
          {/* <>{formErrors.adventure_name}</div> */}
          { isAdventureEdit &&
                <TextBox
                name="adventure_description"
                label="Summary"
                variant="outlined"
                multiline
                maxRows={4}
                onChange={handleOnChange}
                value={inputValues.adventure_description}
                size="small"
              />
          }
          {/* {!isAdventureEdit && (
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Switch
                onChange={switchHandler}
                name="seed_flag"
                value={inputValues.seed_flag}
                defaultChecked
              />
              <DescriptionText>Automatically add an itinerary</DescriptionText>
            </Box>
          )} */}
          {!inputValues.adventure_rid && inputValues.seed_flag && (
            <>
              <SectionSubTitle>Dates</SectionSubTitle>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateRangePicker
                  value={[inputValues.start_date, inputValues.end_date]}
                  onChange={onDateChange}
                  localeText={{ start: 'Start', end: 'End' }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#273A07',
                          },
                          '&:hover fieldset': {
                            borderColor: '#273A07',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#273A07',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#827700',
                        },
                        '& input': {
                          color: '#827700',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </>
          )}
          <SectionSubTitle>Pictures</SectionSubTitle>
          <ImageUpload
            defaultFiles={inputValues.ui_image_location}
            onChange={onImageChange}
          />
          <SectionSubTitle>Transportation*</SectionSubTitle>
          <Box
            sx={{
              gap: 2,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              maxWidth: '350px',
              alignSelf: 'center',
            }}
          >
            {
            transportationTypes ? 
            transportationTypes
              // ?.sort((a, b) => (a.transportation_type_rid === 2 ? -1 : 1))
              .map(
                (
                  {
                    transportation_type_name,
                    transportation_type_rid,
                    ui_image_location,
                  },
                  idx,
                ) => {
                  console.log('DEFUALT TRANSPORTATION', inputValues.default_transportation_type_rid);
                  const selected =
                    inputValues.default_transportation_type_rid ==
                    `${transportation_type_rid}`;
                  return (
                    <ActivityButton
                      key={transportation_type_rid}
                      icon={
                        typeof functionLibrary[ui_image_location] ===
                          'function' &&
                        functionLibrary[ui_image_location](
                          '',
                          'travel-method-svg',
                          'travel-method-svg-outer',
                        )
                      }
                      title={transportation_type_name}
                      value={transportation_type_rid}
                      onClick={transportationTypeHandler}
                      selected={selected}
                      style={{ width: '60px', height: '60px' }}
                    />
                  );
                },
              ) : ""}
          </Box>
        </Stack>
      </Box>
    </HubModal>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAdventure),
);
