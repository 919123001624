import { combineReducers } from 'redux';

import errors from './errors_reducer/index';
import session from './session_reducer/index';
import entities from './entities_reducer/index';
import ui from './ui_reducer/index';
import thirdParty from './external_api';
import dataCache from './data_cache_reducer';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
const dataCachePersistConfig = {
  key: 'dataCache',
  storage: localforage,
  whitelist: ['recGov']
};
// import chatRoom from "./chat_room_reducer";
const RootReducer = combineReducers({
  entities,
  session,
  errors,
  // ui: persistReducer(uiPersistConfig, ui),
  ui,
  thirdParty,
  dataCache: persistReducer(dataCachePersistConfig, dataCache),
  // chatRoom,
});

export default RootReducer;
