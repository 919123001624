export const lastLevelFinder = (list) => {
  let highestLevel = 2;
  list.forEach((ele) =>
    ele.block_level > highestLevel ? (highestLevel = ele.block_level) : null,
  );
  return highestLevel;
};

export const returnWidthDivisor = (highestLevel, level) => {
  let appliedStyling =
    level === 2 ? '99%' : `calc(99% - ${(level - 2) * 10}px)`;

  return appliedStyling;
};
