import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
`;

const HorizontalLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #273A07;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #273A07;
`;

const SectionTitle = ({ children }) => {
  return (
    <Container>
      <HorizontalLine />
      <Title>{children}</Title>
      <HorizontalLine />
    </Container>
  )
};

export default SectionTitle;