import * as BlockAPIUtil from '../util/api_util/block_api_util';
import * as AdventureAPIUtil from '../util/api_util/adventure_api_util';
import * as ItineraryAPIUtil from '../util/api_util/itinerary_api_util';
import { receiveAdventureBlocks } from './adventure_actions';

import {
  RECEIVE_USER_BLOCK_CATEGORIES,
  RECEIVE_USER_BLOCK_ACTIVITIES,
  RECEIVE_USER_BLOCK_ACTIVITY_DETAILS,
  RECEIVE_MODAL_BLOCK_FILES,
  RECEIVE_ITINERARY_ITEM_COPIED_BLOCK,
  RECEIVE_BLOCK_VOTE,
  RECEIVE_BLOCK_VOTES_ALL,
  RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED,
  RECEIVE_NEW_LIBRARY_BLOCK,
  RECEIVE_BLOCK_LISTS,
  RECEIVE_LIBRARY_BLOCKS,
  RECEIVE_PEOPLE_ASSIGNED,
  RECEIVE_IS_ADVENTURE_BLOCK,
  RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST,
  RECEIVE_ALL_BLOCKS_AS_LISTS,
  RECEIVE_LIST_DROPDOWN,
  RECEIVE_LIBRARY_LISTS,
  RECEIVE_TEMPLATE_BLOCKS,
  RECEIVE_TEMPLATE_LISTS,
  RECEIVE_ADVENTURE_RID_EXIST_FLAG,
  RECEIVE_FOLDER_CONTENTS,
  RECEIVE_FLUSH_LOCATION_BLOCKS,
  RECEIVE_ITINERARY_BLOCK_SPECTATORS,
  RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES,
  RECEIVE_ADDRESS_BOOK_BLOCKS,
  UPDATE_VOTE_INFO,
} from '../constants';
import {
  fetchItineraryDetails,
  fetchItineraryItemsAndBlockNames,
  receiveItineraries,
  receiveLocationBlocks,
} from './itinerary_actions';
import {
  receiveBlockFileModalPersister,
  receiveBlockModalFilesPersister,
} from './cache_actions';

export const updateVoteInfo = (data) => {
  return {
    type: UPDATE_VOTE_INFO,
    data,
  };
};

export const receiveFolderContents = (data) => {
  return {
    type: RECEIVE_FOLDER_CONTENTS,
    data,
  };
};

export const receiveItineraryBlockSpectators = (data) => {
  return {
    type: RECEIVE_ITINERARY_BLOCK_SPECTATORS,
    data,
  };
};
export const receiveUserBlockCategories = (data) => {
  return {
    type: RECEIVE_USER_BLOCK_CATEGORIES,
    data,
  };
};

const receiveTemplateBlocks = (data) => {
  return {
    type: RECEIVE_TEMPLATE_BLOCKS,
    data,
  };
};

const receiveTemplateLists = (data) => {
  return {
    type: RECEIVE_TEMPLATE_LISTS,
    data,
  };
};

const receiveUserBlockActivities = (data) => {
  return {
    type: RECEIVE_USER_BLOCK_ACTIVITIES,
    data,
  };
};
export const receiveLibraryBlocks = (data) => {
  return {
    type: RECEIVE_LIBRARY_BLOCKS,
    data,
  };
};
export const receiveLibraryLists = (data) => {
  return {
    type: RECEIVE_LIBRARY_LISTS,
    data,
  };
};
const receiveUserBlockActivityDetails = (data) => {
  return {
    type: RECEIVE_USER_BLOCK_ACTIVITY_DETAILS,
    data,
  };
};

const receiveModalBlockFiles = (data) => {
  return {
    type: RECEIVE_MODAL_BLOCK_FILES,
    data,
  };
};

const receiveItineraryItemCopiedBlock = (data, itineraryItemRid) => {
  return {
    type: RECEIVE_ITINERARY_ITEM_COPIED_BLOCK,
    data,
    itineraryItemRid,
  };
};

const receiveBlockVote = (data, itineraryItemRid, itineraryBlockRid) => {
  return {
    type: RECEIVE_BLOCK_VOTE,
    data,
    itineraryItemRid,
    itineraryBlockRid,
  };
};

const receiveAddressBookBlocks = (data) => {
  return {
    type: RECEIVE_ADDRESS_BOOK_BLOCKS,
    data,
  };
};

const receiveBlockLists = (data) => {
  return {
    type: RECEIVE_BLOCK_LISTS,
    data,
  };
};
const receiveAdventureSelected = (data) => {
  return {
    type: RECEIVE_ADVENTURE_RID_EXIST_FLAG,
    data,
  };
};

const receiveFlushLocationBlocks = () => {
  return {
    type: RECEIVE_FLUSH_LOCATION_BLOCKS,
  };
};

const receiveTravelMethodBlockCategories = (data) => {
  return {
    type: RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES,
    data,
  };
};

export const receiveIsAdventureBlock = (data) => {
  return {
    type: RECEIVE_IS_ADVENTURE_BLOCK,
    data,
  };
};
export const receivePeopleAssigned = (data) => {
  return {
    type: RECEIVE_PEOPLE_ASSIGNED,
    data,
  };
};
export const receiveBlockVotesAll = (data) => {
  return {
    type: RECEIVE_BLOCK_VOTES_ALL,
    data,
  };
};

export const receiveNewLibraryBlock = (data) => {
  return {
    type: RECEIVE_NEW_LIBRARY_BLOCK,
    data,
  };
};

export const receiveListDropDown = (data) => {
  return {
    type: RECEIVE_LIST_DROPDOWN,
    data,
  };
};

export const receiveAllBlocksAsLists = (data) => {
  return {
    type: RECEIVE_ALL_BLOCKS_AS_LISTS,
    data,
  };
};
const receiveItineraryItemBlocksUpdated = (data, organizerData) => {
  return {
    type: RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED,
    data,
    organizerData,
  };
};
export const fetchUserBlockCategories = (account_rid) => (dispatch) => {
  return BlockAPIUtil.fetchUserBlockCategory(account_rid).then((res) => {
    return dispatch(receiveUserBlockCategories(res.data));
  });
};

export const postUserBlockCategory = (account_rid, data) => (dispatch) => {
  return BlockAPIUtil.postUserBlockCategory(account_rid, data).then((res) => {
    return dispatch(receiveUserBlockCategories(res.data));
  });
};

const receiveUpdateAdventureBlockAndItineraryBlockChecklist = (
  itineraryItemRids,
  adventureBlockRid,
  actionType,
) => ({
  type: RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST,
  data: {
    itineraryItemRids,
    adventureBlockRid,
    actionType,
  },
});

export const fetchUserBlockActivity = (account_rid, block_category_rid) => (
  dispatch,
) => {
  return BlockAPIUtil.fetchUserBlockActivity(
    account_rid,
    block_category_rid,
  ).then((res) => {
    return dispatch(receiveUserBlockActivities(res.data));
  });
};

export const postUserBlockActivity = (account_rid, data) => (dispatch) => {
  return BlockAPIUtil.postUserBlockActivity(account_rid, data).then((res) => {
    return dispatch(receiveUserBlockActivities(res.data));
  });
};

export const fetchUserBlockActivityDetail = (
  account_rid,
  block_activity_rid,
) => (dispatch) => {
  return BlockAPIUtil.fetchUserBlockActivityDetail(
    account_rid,
    block_activity_rid,
  ).then((res) => {
    return dispatch(receiveUserBlockActivityDetails(res.data));
  });
};

export const postUserBlockActivityDetail = (account_rid, data) => (
  dispatch,
) => {
  return BlockAPIUtil.postUserBlockActivityDetail(account_rid, data).then(
    (res) => {
      return dispatch(receiveUserBlockActivityDetails(res.data));
    },
  );
};

export const fetchAllBlocksItinerary = (account_rid) => (dispatch) => {
  return BlockAPIUtil.fetchUserBlocks(account_rid).then((res) => {
    return dispatch(receiveAdventureBlocks(res.data));
  });
};

export const postBlockFile = (data) => (dispatch) => {
  return BlockAPIUtil.postBlockFile(data).then((res) => {
    return dispatch(receiveBlockModalFilesPersister(res.data));
  });
};

export const deleteBlockFile = (blockFileRid, blockRid) => (dispatch) => {
  return BlockAPIUtil.deleteBlockFile(blockFileRid, blockRid).then((res) => {
    return dispatch(receiveBlockModalFilesPersister(res.data));
  });
};

/* Copy Blocks function */

export const copyBlock = (
  isAdventure,
  adventureRid,
  account_rid,
  data,
  userInfo,
) => (dispatch, getState) => {
  const explorer_rid = getState().session?.user?.explorer_rid;
  //[1]. Copy Itinerary Block.
  if (data.itinerary_item_rid) {
    data['account_rid'] = userInfo.account_rid;
    data['explorer_rid'] = userInfo.explorer_rid;
    return ItineraryAPIUtil.copyItineraryItemBlock(data).then((res) => {
      updateCurrentItinerary(dispatch, getState);
      updateCurrentAdventure(dispatch, getState);
    });
  } else {
    BlockAPIUtil.copyBlock(data).then((res) => {
      // [2]. Copy Trip Board Block.
      if (isAdventure) {
        BlockAPIUtil.fetchBlocksDynamic(account_rid, {
          adventure_rid: adventureRid,
          adventure_rid_exist_flag: true,
          parent_block_rid: data.parent_block_rid,
          explorer_rid: explorer_rid,
        }).then((res2) => {
          dispatch(receiveAdventureBlocks(res2.data));
        });
      } else {
        // [3]. Copy List Block.
        if (data.list_flag) {
          dispatch(receiveLibraryLists(res.data));
          // [4]. Copy Library Block.
        } else {
          dispatch(receiveLibraryBlocks(res.data));
        }
      }
    });
  }
};

/*  
  returnType {
    isLibraryBlock,
    isNestedLibraryBlock
  }
*/
export const moveToAdventureBlock = (
  data,
  userInfo,
  returnType,
  actionPayload,
) => (dispatch) => {
  console.log('move trip board block');
  return BlockAPIUtil.moveToAdventureBlock(data).then((res) => {
    if (
      returnType &&
      (returnType.isLibraryBlock || returnType.isNestedLibraryBlock)
    ) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: actionPayload.parentBlockRid,
        adventure_rid: actionPayload.adventureRid,
        adventure_rid_exist_flag: true,
      }).then((res) => dispatch(receiveLibraryBlocks(res.data)));
    }
  });
};

export const refreshItineraries = (
  itinerary_rid,
  explorer_rid,
  dispatch,
  getState,
) => {
  const updateBlocks = new Promise((resolve, reject) => {
    try {
      if (!!getState) {
        BlockAPIUtil.fetchBlocksDynamic(explorer_rid, {
          adventure_rid: getState().entities.itinerary.itinerary_info
            .adventure_rid,
          adventure_rid_exist_flag: true,
          explorer_rid: explorer_rid,
        }).then((res2) => {
          dispatch(receiveAdventureBlocks(res2.data));
          resolve();
        });
      } else {
        resolve();
      }
    } catch (err) {
      reject(err);
    }
  });

  const updatedItinerary = updateBlocks.then(async () => {
    return await ItineraryAPIUtil.fetchItineraryDetail(
      itinerary_rid,
      explorer_rid,
    ).then((res) => {
      return dispatch(receiveItineraries(res.data));
    });
  });

  return updatedItinerary;
};

export const updateCurrentItinerary = (dispatch, getState, items) => {
  const itineraries = getState().entities?.itinerary?.itineraryDetails;
  const userInfo = getState().session?.user?.userInfo[0];
  return Promise.all(
    itineraries.map(async ({ itinerary_item_rid }) => {
      let userBlocks = getState().entities?.itBlocks?.item_blocks[
        itinerary_item_rid
      ];
      if (userBlocks.length > 0 && userBlocks[0].itinerary_item_rid) {
        await ItineraryAPIUtil.fetchItineraryItemBlocks(
          getState().session?.user?.explorer_rid,
          itinerary_item_rid,
        ).then((res) => {
          return dispatch(
            receiveItineraryItemBlocksUpdated(
              {
                userBlocks: res.data.itineraryItemBlocks,
              },
              {
                itinerary_item_rid,
              },
            ),
          );
        });
      } else if (userBlocks.length > 0) {
        return BlockAPIUtil.fetchBlocksDynamic(userInfo.explorer_rid, {
          adventure_rid: getState().entities.itinerary.itinerary_info
            .adventure_rid,
          adventure_rid_exist_flag: true,
          explorer_rid: userInfo.explorer_rid,
          parent_block_rid: userBlocks[0].parent_block_rid,
        }).then((res2) => {
          return dispatch(
            receiveItineraryItemBlocksUpdated(res2.data, {
              itinerary_item_rid,
            }),
          );
        });
      } else {
        return;
      }
    }),
  );
};

/**
 *
 * @param {Dispatch} dispatch
 * @param {?} getState
 */
export const updateCurrentAdventure = (dispatch, getState) => {
  // sort blocks to get current level?

  const curBlocksSortedByLevel = [
    ...(getState().entities?.itinerary?.adventure_blocks ?? []),
  ].sort((a, b) => {
    if (a.block_level > b.block_level) return 1;
    if (a.block_level < b.block_level) return -1;
    return 0;
  });

  const parentBlockRid =
    curBlocksSortedByLevel && curBlocksSortedByLevel.length > 0
      ? curBlocksSortedByLevel[0].parent_block_rid
      : null;

  //  update adventure blocks slice
  return new Promise((resolve, reject) => {
    return BlockAPIUtil.fetchBlocksDynamic(
      getState().session?.user?.account_rid,
      {
        adventure_rid: getState().entities?.itinerary?.itinerary_info
          ?.adventure_rid,
        adventure_rid_exist_flag: true,
        explorer_rid: getState()?.session?.user?.explorer_rid,
        parent_block_rid: parentBlockRid,
      },
    )
      .then((blocks) => {
        dispatch(receiveAdventureBlocks(blocks.data));
        resolve(blocks);
      })
      .catch((err) => {
        console.error('Failed to update current adventure');
        reject(err);
      });
  });
};

export const voteOnBlock = (data) => (dispatch, getState) => {
  return BlockAPIUtil.upsertAdventureBlockVotes(data).then((res) => {
    return Promise.all([
      updateCurrentAdventure(dispatch, getState),
      updateCurrentItinerary(dispatch, getState),
    ]);
  });
};

/*
  origin{
    fromModal: boolean,
    fromBlock : booelan,
    fromNestedBlock:
  },
  returnActionPayload: {
    adventureRid,
    parentBlockRid
  }
*/

export const voteOnAdventureBlock = (
  data,
  userInfo,
  origin,
  returnActionPayload,
  itinerary_rid,
) => (dispatch, getState) => {
  return BlockAPIUtil.upsertAdventureBlockVotes(data).then((res) => {
    updateCurrentAdventure(dispatch, getState);
    updateCurrentItinerary(dispatch, getState);
  });
};

export const removeVote = (
  explorerVoteRid,
  explorerRid,
  itineraryBlockRid,
  itineraryRid,
  account_rid,
  adventure_info,
) => (dispatch, getState) => {
  const curBlocks = getState().entities?.itinerary?.adventure_blocks;
  return BlockAPIUtil.deleteAdventureBlockVotes(
    adventure_info.adventure_block_rid,
    adventure_info.adventure_block_vote_rid,
    explorerRid,
  ).then((res) => {
    updateCurrentAdventure(dispatch, getState);
    updateCurrentItinerary(dispatch, getState);
  });

  // return BlockAPIUtil.deleteBlockVote(
  //   explorerVoteRid,
  //   explorerRid,
  //   itineraryBlockRid,
  // ).then((res) => {
  //   return ItineraryAPIUtil.fetchItineraryDetail(
  //     itineraryRid,
  //     explorerRid,
  //   ).then((res) => {
  //     // -- use this for refetching itinerary deets
  //     return dispatch(receiveItineraries(res.data));
  //   });
  // });
};
/*
  origin{
    fromModal: boolean,
    fromBlock : booelan,
    fromNestedBlock:
  },
  returnActionPayload: {
    adventureRid,
    parentBlockRid
  }
*/
export const removeAdventureBlockVote = (
  adventure_block_rid,
  adventure_block_vote_rid,
  userInfo,
  origin,
  returnActionPayload,
  itinerary_rid,
) => (dispatch, getState) => {
  return BlockAPIUtil.deleteAdventureBlockVotes(
    adventure_block_rid,
    adventure_block_vote_rid,
    userInfo.explorer_rid,
  ).then((res) => {
    updateCurrentAdventure(dispatch, getState);
    updateCurrentItinerary(dispatch, getState);
  });
};

export const nestBlock = (accountRid, data, parent_block_rid) => (dispatch) => {
  return BlockAPIUtil.postUserBlock(data, accountRid);
};

export const unnestBlock = (accountRid, data) => (dispatch) => {
  return BlockAPIUtil.postUserBlock(accountRid, data).then((res) => {});
};

export const fetchBlockList = (block_rid, adventure_block_rid, userInfo) => (
  dispatch,
) => {
  if (adventure_block_rid) {
    return BlockAPIUtil.fetchBlockLists(
      block_rid,
      userInfo.explorer_rid,
      adventure_block_rid,
    ).then((res) => {
      return dispatch(receiveBlockLists(res.data));
    });
  } else {
    return BlockAPIUtil.fetchBlockLists(
      block_rid,
      userInfo.explorer_rid,
      adventure_block_rid,
    ).then((res) => {
      return dispatch(receiveBlockLists(res.data));
    });
  }
};

export const fetchBlocksDynamic = (account_rid, data) => (dispatch) => {
  return BlockAPIUtil.fetchBlocksDynamic(account_rid, data).then((res) => {
    return dispatch(receiveBlockFileModalPersister(res.data));
  });
};

export const copyTemplateBlock = (data, userInfo) => (dispatch) => {
  data.account_rid = userInfo.account_rid;
  return BlockAPIUtil.copyTemplateBlock(data).then((res) => {});
};

export const fetchTemplateBlocks = (parentBlockRid, type) => (dispatch) => {
  return BlockAPIUtil.fetchTemplateBlocks(
    parentBlockRid ? parentBlockRid : null,
  ).then((res) => {
    if (type.isTemplateListOnly) {
      return dispatch(receiveTemplateLists(res.data));
    }
    if (type.isTemplateActivityAndList)
      dispatch(receiveTemplateLists(res.data));
    return dispatch(receiveTemplateBlocks(res.data));
  });
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : fetchBoardBlocks will be used between fetching blocks and send them to the correct reducer
 * between adventrue blocks and library blocks
 *
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 *  userInfo: **object** userInfo object,
 *  data: data to be used for dynamic fetching of blocks{
*     :v_account_rid,
      :v_inpsiration_rid,
      :v_adventure_rid,
      :v_itinerary_item_rid, 	
      :v_parent_block_rid
 * }
 *  origin: **object**{
 *    isAdventure: boolean
 *    isLibrary:boolean
 *   }
 *  isNested: **boolean**
 * }
 * 
 */

export const fetchBoardBlocks = (userInfo, data, origin, isNested) => (
  dispatch,
) => {
  if (data) {
    data['explorer_rid'] = userInfo.explorer_rid;
  } else {
    data = { explorer_rid: userInfo.explorer_rid };
  }
  if (isNested) {
    return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, data).then(
      (res) => {
        if (origin.isLibraryListOnly) {
          return dispatch(receiveLibraryLists(res.data));
        }
        if (origin.isLibraryBlockOnly) {
          return dispatch(receiveLibraryBlocks(res.data));
        }
        if (origin.isLibraryAndList) dispatch(receiveLibraryLists(res.data));
        return origin.isAdventure
          ? dispatch(receiveAdventureBlocks(res.data))
          : dispatch(receiveLibraryBlocks(res.data));
      },
    );
  } else {
    return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, data).then(
      (res) => {
        if (origin.isLibraryListOnly) {
          return dispatch(receiveLibraryLists(res.data));
        }
        if (origin.isLibraryBlockOnly) {
          return dispatch(receiveLibraryBlocks(res.data));
        }
        if (origin.isLibraryAndList) dispatch(receiveLibraryLists(res.data));
        return origin.isAdventure
          ? dispatch(receiveAdventureBlocks(res.data))
          : dispatch(receiveLibraryBlocks(res.data));
      },
    );
  }
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : function upsertAndUpdateLists will be used for all things relating to lists entries of a block
 *
 * Updates 		: 	DD-MON-YYYY description of update
 *{
   data: **object** block data,
   userInfo: **object** userInfo,
   actionType: **object** {
    isUpdate: boolean,
    isDelete: boolean,
    isCopy: boolean,
    isNew: boolean,
   },
   origin:{
    isAdventure:boolean
    adventureRid: integer,
    isNested: boolean
    parentBlockRid: 
   }
 }
 *
 */

// export const upsertAndUpdateLists = (data, userInfo, actionType, origin) => (
//   dispatch
// ) => {
//   BlockAPIUtil.upsertBlocksDynamic
// };

export const getCorrectBlocksWithVote = (userBlocks, blocks) => {
  return blocks.map((block) => {
    let filters = userBlocks.filter((e) => block.block_rid === e.block_rid);
    return filters.length > 0
      ? {
          ...block,
          explorer_vote_rid: filters[0].explorer_vote_rid,
          explorer_vote_up: filters[0].explorer_vote_up,
          explorer_vote_down: filters[0].explorer_vote_down,
        }
      : block;
  });
};

export const drillDownIntoAndOutOfItineraryBlock = (
  data,
  userInfo,
  destination,
) => (dispatch, getState) => {
  if (data.itinerary_item_rid) {
    return ItineraryAPIUtil.fetchItineraryItemBlocks(
      userInfo.explorer_rid,
      data.itinerary_item_rid,
    ).then((res) => {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.explorer_rid, {
        adventure_rid: getState().entities?.itinerary?.itinerary_info
          ?.adventure_rid,
        adventure_rid_exist_flag: true,
        explorer_rid: userInfo.explorer_rid,
      }).then((res2) => {
        return dispatch(
          receiveItineraryItemBlocksUpdated(
            {
              userBlocks: getCorrectBlocksWithVote(
                res2.data.userBlocks,
                res.data.itineraryItemBlocks,
              ),
            },
            destination,
          ),
        );
      });
    });
  }
  return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, data).then(
    (res) => {
      BlockAPIUtil.fetchBlocksDynamic(userInfo.explorer_rid, {
        adventure_rid: getState().entities.itinerary.itinerary_info
          .adventure_rid,
        explorer_rid: userInfo.explorer_rid,
      }).then((res2) => {
        dispatch(
          receiveItineraryItemBlocksUpdated(
            {
              userBlocks: getCorrectBlocksWithVote(
                res2.data.userBlocks,
                res.data['userBlocks'],
              ),
            },
            destination,
          ),
        );
      });
    },
  );
};
/* Purpose
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 *  userInfo: **object** user data
 *  data: **object** block data for upserting,
 *  blockType: **object**  {
 *    isAdventureBlock: boolean
 *    isNestedAdventureBlock: boolean,
 *    isLibraryBlock: boolean,
 *    isNestedLibraryBlock,
 *    isItineraryItemBlock: boolean,
 *    isNestedItineraryItemBlock: boolean,
 *    isListBlock: boolean,
 *    isNestedListBlock,
 *    isAdventureListBlockCheck,
 *    isNestedListBlock,
 *    isUnnestingBlock,
 *    isLocationBlock
 *
 *  }
 *  returnActionPayload: **object** used to update the ui accordingly
 *  {
 *    adventureRid,
 *    itineraryItemRid,
 *    nestedLevel,
 *    parentBlockRid
 * }
 *
 */
export const upsertBlock = (
  userInfo,
  data,
  blockType,
  returnActionPayload,
) => async (dispatch, getState) => {
  data['explorer_rid'] = userInfo.explorer_rid;
  data['account_rid'] = userInfo.account_rid;
  let returnedBlocks;
  let itineraryItemRid;
  if (
    (blockType.isAdventureList && blockType.isAdventureBlock) ||
    (blockType.isLibraryList && blockType.isLibraryBlock)
  ) {
    returnedBlocks = await BlockAPIUtil.insertBlockAndReturn(data); // this is for inserting block and getting single data returned then setting it to a variable
  } else if (blockType.isLocationBlock) {
    if (data.block_rid) {
      if (blockType.createFromModal) {
        let block = await BlockAPIUtil.postUserBlock(data);

        if (blockType.isLibraryBlock)
          dispatch(receiveLibraryBlocks(block.data));
        if (blockType.isAdventureBlock)
          dispatch(receiveAdventureBlocks(block.data));
      } else {
        delete data.adventure_rid;

        let block = await BlockAPIUtil.postUserBlock(data);

        delete data.parent_block_rid;
        if (blockType.isLibraryBlock)
          dispatch(receiveLibraryBlocks(block.data));
        if (blockType.isAdventureBlock)
          dispatch(receiveAdventureBlocks(block.data));
      }
      data.adventure_rid = returnActionPayload.adventureRid;
    } else {
      let adventure_rid_placeholder = data.adventure_rid;
      data.adventure_rid = null;
      if (blockType.fromDrawer) {
        // data.adventure_rid = adventure_rid_placeholder;
        data.adventure_rid_exist_flag = null;
      }
      if (blockType.isAdventureBlock) {
        if (data.library_parent_block_rid) {
          data.parent_block_rid = data.library_parent_block_rid;
        } else {
          data.parent_block_rid = null;
        }
      }
      let block = blockType.isLibraryBlock
        ? blockType.fromDrawer
          ? await BlockAPIUtil.upsertBlockMap(data)
          : // await BlockAPIUtil.postUserBlock(data)
            await BlockAPIUtil.postUserBlock(data)
        : await BlockAPIUtil.upsertBlockMap(data);

      if (blockType.isLibraryBlock) {
        // this code under bottom has to return library Blocks  ? might or might not have to happen.
        if (blockType.fromDrawer) {
          // let router = useRouter();
          if (window.location.pathname.includes('library')) {
            BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
              account_rid: userInfo.account_rid,
              //parent_block_rid: returnActionPayload.parentBlockRid,
            }).then((libBlocks) => {
              dispatch(receiveLibraryBlocks(libBlocks.data));
              dispatch(receiveLibraryLists(libBlocks.data));
            });
          }
          // BlockAPIUtil.fetchBlocksDynamic({});
        } else {
          dispatch(receiveLibraryBlocks(block.data));
        }
        if (blockType.fromDrawer) return block;
      }
      let blockToClone;
      if (blockType.isAdventureBlock) blockToClone = block.data.singleBlock[0];
      if (blockToClone && adventure_rid_placeholder) {
        blockToClone.adventure_rid = returnActionPayload.adventureRid;
        delete blockToClone.parent_block_rid;
        blockToClone.adventure_rid = adventure_rid_placeholder;
        if (data.trip_parent_block_rid) {
          blockToClone.parent_block_rid = data.trip_parent_block_rid;
        }
        returnedBlocks = await BlockAPIUtil.copyAndReturnNew(blockToClone);
        if (block)
          block.data.singleBlock[0].block_rid =
            returnedBlocks.data.userBlocks[0].func_copy_return_new_block;
        return block;
      }
    }
  } else {
    if (blockType.isAdventureListBlockCheck || blockType.isNestedListBlock) {
      delete data.adventure_rid;
      // adventure_rid is deleted because the blocks are now a copy
      returnedBlocks = await BlockAPIUtil.postUserBlock(
        data,
        userInfo.account_rid,
      );
    } else if (blockType.isItineraryItemBlock) {
      data.adventure_rid = null;
      data.adventure_rid_exist_flag = null;
      itineraryItemRid = data.itinerary_item_rid;
      data.itinerary_item_rid = null;
      let block = await BlockAPIUtil.upsertBlockMap(data);
      if (!data.block_rid) {
        let blockToClone = block.data.singleBlock[0];
        blockToClone.adventure_rid =
          returnActionPayload.itineraryInfo.adventure_rid;

        returnedBlocks = await BlockAPIUtil.copyAndReturnNew(blockToClone);
        let newBlockRid =
          returnedBlocks.data.userBlocks[0].func_copy_return_new_block;
        returnedBlocks = await ItineraryAPIUtil.moveBlockToItnerary(
          itineraryItemRid,
          newBlockRid,
          userInfo.account_rid,
          userInfo.explorer_rid,
        );
      }
    } else if (
      blockType.isAdventureBlock &&
      !data.block_rid &&
      data.parent_block_rid
    ) {
      let block = await BlockAPIUtil.insertBlockAndReturn(data);
      // this inserting adventure block then removing adventure_rid and parent block rid to take it to library
      delete data.parent_block_rid;
      data.adventure_rid = null;
      data.adventure_rid_exist_flag = null;
      // this is to check whether or not user has selected a new folder to nest
      //under while creating new trip block and inserting library parent block rid
      if (returnActionPayload.addLibraryParent) {
        data.parent_block_rid = returnActionPayload.libraryParentRid;
      }
      await BlockAPIUtil.insertBlockAndReturn(data);
      return block;
    } else if (blockType.isAdventureBlock && !data.block_rid) {
      let adventure_rid_placeholder;
      if (!data.folder_flag) {
        adventure_rid_placeholder = data.adventure_rid;
        data.adventure_rid = null;
      }
      let block = await BlockAPIUtil.insertBlockAndReturn(data);
      BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        account_rid: userInfo.account_rid,
        adventure_rid: data.adventure_rid,
        explorer_rid: userInfo.explorer_rid,
        parent_block_rid: null,
      }).then((blocks) => {
        dispatch(receiveAdventureBlocks(blocks.data));
      });
      let blockToClone = block.data.singleBlock[0];
      if (blockToClone && !data.block_rid) {
        blockToClone.adventure_rid = returnActionPayload.adventureRid;
        delete blockToClone.parent_block_rid;
        blockToClone.adventure_rid = adventure_rid_placeholder;
        if (!data.folder_flag) {
          returnedBlocks = await BlockAPIUtil.copyAndReturnNew(blockToClone);
          // return block;
          if (block)
            block.data.singleBlock[0].block_rid =
              returnedBlocks.data.userBlocks[0].func_copy_return_new_block;
        }
        // block.data.singleBlock[0].
        return block;
      }
    } else {
      returnedBlocks = await BlockAPIUtil.postUserBlock(
        data,
        userInfo.account_rid,
      );
    }
  }

  if (blockType.isLocationBlock) {
    let mapBlockQuery = {
      account_rid: userInfo.account_rid,
      // itinerary_rid: null,
      itinerary_rid: returnActionPayload.itineraryRid,
      adventure_rid: returnActionPayload.adventureRid,
      direction_flag: false,
    };
    if (blockType.isAdventureBlock && blockType.isLocationBlock) {
      Promise.all([
        BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
          adventure_rid: returnActionPayload.adventureRid,
        }),
        ItineraryAPIUtil.fetchLocationBlocks(mapBlockQuery),
      ]).then((promiseRes) => {
        dispatch(receiveAdventureBlocks(promiseRes[0].data));
        dispatch(receiveLocationBlocks(promiseRes[1].data));
      });
      return returnedBlocks; // this returns single new blocks
    } else {
      Promise.all([
        BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
          parent_block_rid: null,
        }),
        ItineraryAPIUtil.fetchLocationBlocks(mapBlockQuery),
      ]).then((promiseRes) => {
        dispatch(receiveAdventureBlocks(promiseRes[0].data));
        dispatch(receiveLocationBlocks(promiseRes[1].data));
      });
      return returnedBlocks; // this returns single new blocks
    }
  }
  if (!blockType.isLibraryList && !blockType.isLibraryBlock) {
  }
  if (blockType.isAdventureListBlockCheck) {
    BlockAPIUtil.fetchAdventureBlockItineraryItemRids(
      returnActionPayload.adventureBlockRid,
    ).then((res) => {
      dispatch(
        receiveUpdateAdventureBlockAndItineraryBlockChecklist(
          res.data.adventureBlockItineraryItemRids,
          returnActionPayload.adventureBlockRid,
          data.completed_flag ? '+' : '-',
        ),
      );
    });
  }
  //updating library blocks
  if (blockType.isLibraryBlock) {
    //code here will update correctly
    // for updating ui when block is nested
    if (blockType.isNestedLibraryBlock) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: returnActionPayload.parentBlockRid,
      }).then((res) => {
        if (returnActionPayload.isLibraryListOnly) {
          return dispatch(receiveLibraryLists(res.data));
        }
        dispatch(receiveLibraryBlocks(res.data));
      });
    }
    if (blockType.isLibraryList || blockType.isLibraryBlock) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: returnActionPayload.parentBlockRid,
        adventure_rid: returnActionPayload.adventureRid,
        adventure_rid_exist_flag: true,
      }).then((res) => {
        if (returnActionPayload.isLibraryListOnly) {
          dispatch(receiveLibraryLists(res.data));
          return returnedBlocks;
        }
        dispatch(receiveLibraryBlocks(res.data));
        return returnedBlocks; // this returns single new blocks
      });
    }
    if (blockType.isLibraryBlock && !blockType.isNestedLibraryBlock)
      if (returnActionPayload.isLibraryListOnly) {
        // updating when not neseted
        return dispatch(receiveLibraryLists(returnedBlocks.data));
      }
    return dispatch(receiveLibraryBlocks(returnedBlocks.data));
  } else if (blockType.isAdventureBlock) {
    //for updating ui when adventure block is nested
    if (returnActionPayload.itineraryInfo) {
      ItineraryAPIUtil.fetchItineraryDetail(
        returnActionPayload.itineraryInfo.itinerary_rid,
        userInfo.explorer_rid,
      ).then((res) => {
        // -- use this for refetching itinerary deets
        dispatch(receiveItineraries(res.data));
      });
    }
    if (blockType.isNestedAdventureBlock) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: returnActionPayload.parentBlockRid,
        adventure_rid: returnActionPayload.adventureRid,
      }).then((res) => dispatch(receiveAdventureBlocks(res.data)));
    } else {
      // updating when not neseted
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        adventure_rid: returnActionPayload.adventureRid,
      }).then((res) => {
        dispatch(receiveAdventureBlocks(res.data));
        if (blockType.isAdventureList) return returnedBlocks;
      });
    }
    // return dispatch(receiveAdventureBlocks(returnedBlocks.data));
  } else if (blockType.isItineraryItemBlock) {
    if (blockType.isNestedItineraryItemBlock) {
      await BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        adventure_rid: returnActionPayload.itineraryInfo.adventure_rid,
        adventure_rid_exist_flag: true,
        parent_block_rid: returnActionPayload.parentBlockRid,
      }).then((res) => {
        dispatch(
          receiveItineraryItemBlocksUpdated(res.data, {
            itinerary_item_rid: returnActionPayload.itineraryItemRid,
          }),
        );
      });
    } else {
      BlockAPIUtil.fetchAssoicatedItemRids(data.block_rid).then(
        async (res3) => {
          res3.data.itineraryItemRids.forEach(
            async ({ itinerary_item_rid }) => {
              await ItineraryAPIUtil.fetchItineraryItemBlocks(
                userInfo.explorer_rid,
                itinerary_item_rid,
              ).then((res) => {
                dispatch(
                  receiveItineraryItemBlocksUpdated(
                    { userBlocks: res.data.itineraryItemBlocks },
                    {
                      itinerary_item_rid,
                    },
                  ),
                );
              });
            },
          );
        },
      );
    }
    return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
      adventure_rid: returnActionPayload.itineraryInfo.adventure_rid,
    }).then((res) => {
      dispatch(receiveAdventureBlocks(res.data));
      if (blockType.isAdventureList) return returnedBlocks;
    });
  } else if (blockType.isListBlock) {
    if (returnActionPayload.itineraryInfo) {
      ItineraryAPIUtil.fetchItineraryDetail(
        returnActionPayload.itineraryInfo.itinerary_rid,
        userInfo.explorer_rid,
      ).then((res) => {
        // -- use this for refetching itinerary deets
        dispatch(receiveItineraries(res.data));
      });
    }
    return returnActionPayload.adventureBlockRid
      ? BlockAPIUtil.fetchBlockLists(
          returnActionPayload.parentBlockRid,
          userInfo.explorer_rid,
          returnActionPayload.adventureBlockRid,
        ).then((res) => {
          return dispatch(receiveBlockLists(res.data));
        })
      : BlockAPIUtil.fetchBlockLists(
          returnActionPayload.parentBlockRid,
          userInfo.explorer_rid,
          returnActionPayload.adventureBlockRid,
        ).then((res) => {
          return dispatch(receiveBlockLists(res.data));
        });
  } else if (blockType.isUnnestingBlock) {
    return;
  }
};

export const upsertAndUpdateLibraryBlocks = async (
  block,
  fetchData,
  userInfo,
  actionType,
) => async (dispatch) => {
  block['explorer_rid'] = userInfo.explorer_rid;
  if (actionType.isInsert || actionType.isEdit) {
    await BlockAPIUtil.postUserBlock(block, userInfo.account_rid);
  } else if (actionType.isDelete) {
    await BlockAPIUtil.deleteBlock(block, userInfo.account_rid);
  }

  if (fetchData.isNested) {
    BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
      parent_block_rid: fetchData.nestedParentBlockRid,
    }).then((res) => {
      fetchData.isAdventure
        ? dispatch(receiveAdventureBlocks(res.data))
        : dispatch(receiveLibraryBlocks(res.data));
    });
  }
};

/* 
  Purpose : deletes all types of blocks; library blocks, adventure blocks, and itinerary item blocks
  Updates 		: 	05-06-2021
  Pararmeters  :
    userInfo: **object** user data
    data: **object** block data for deleting,
    blockType: **object**  {
      isLibraryBlock,
      isNestedLibraryBlock,
      isAdventureBlock,
      isNestedAdentureBlock,
      isItineraryBlock,
      isNestedItineraryBlock
    }
    returnActionPayload: **object** used to update the ui accordingly
    {
      adventureRid,
      parentBlockRid,
      itineraryItemRid
  }
 */

/* Delete Blocks */

export const deleteListBlock = (data, userInfo) => async (
  dispatch,
  getstate,
) => {
  await BlockAPIUtil.deleteBlock(data, userInfo.account_rid);
};

export const deleteBlock = (
  data,
  userInfo,
  blockType,
  returnActionPayload,
) => async (dispatch, getState) => {
  let returnedBlocks;
  const state = getState();
  const itinerary_rid =
    state.entities?.itinerary?.itinerary_info?.itinerary_rid;
  // code here will delete correctly
  if (
    blockType.isLibraryBlock ||
    blockType.isListBlock ||
    blockType.isNestedListBlock ||
    blockType.isLibraryAndLists ||
    blockType.isNestedLibraryAndLists
  ) {
    returnedBlocks = await BlockAPIUtil.deleteBlock(data, userInfo.account_rid);
  } else if (blockType.isAdventureBlock) {
    // deleting a nested adventure block current doesnt have an adventure rid, there for uses normal delete
    returnedBlocks = await BlockAPIUtil.deleteAdventureBlock(
      data.adventure_block_rid,
      returnActionPayload.adventureRid,
      userInfo.account_rid,
      userInfo.explorer_rid,
    );
  } else if (blockType.isItineraryBlock) {
    // deleting a nested adventure block current doesnt have an itinerary_item_block_rid, therefor uses normal delete
    if (blockType.isNestedItineraryBlock) {
      returnedBlocks = await BlockAPIUtil.deleteBlock(
        data,
        userInfo.account_rid,
      );
    } else {
      returnedBlocks = await ItineraryAPIUtil.deleteItineraryItemBlock(
        data.itineriary_block_rid,
        data.itinerary_item_rid,
        userInfo.explorer_rid,
      );
    }
  }

  //code here will update correctly
  if (blockType.isLibraryBlock) {
    // for updating ui when block is nested
    if (returnActionPayload.itineraryRid) {
      ItineraryAPIUtil.fetchItineraryDetail(
        returnActionPayload.itineraryRid,
        userInfo.explorer_rid,
      ).then((res) => {
        // -- use this for refetching itinerary deets
        dispatch(receiveItineraries(res.data));
      });
    }
    if (blockType.isNestedLibraryBlock) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: returnActionPayload.parentBlockRid,
      }).then((res) => {
        dispatch(receiveLibraryBlocks(res.data));
      });
    }

    if (blockType.isNestedLibraryAndLists) {
      return BlockAPIUtil.fetchBlockLists(
        returnActionPayload.parentBlockRid,
        userInfo.explorer_rid,
        returnActionPayload.adventureBlockRid,
      ).then((res) => {
        return dispatch(
          receiveLibraryLists({ userBlocks: res.data.blockLists || [] }),
        );
      });
    }

    // updating when not neseted
    if (blockType.isLibraryAndLists) {
      return dispatch(receiveLibraryLists(returnedBlocks.data));
    }

    return dispatch(receiveLibraryBlocks(returnedBlocks.data));
  } else if (blockType.isAdventureBlock) {
    updateCurrentAdventure(dispatch, getState);
    updateCurrentItinerary(dispatch, getState);
    //for updating ui when adventure block is nested
    // ItineraryAPIUtil.fetchItineraryDetail(
    //   returnActionPayload.itineraryRid,
    //   userInfo.explorer_rid,
    // ).then((res) => {
    //   // -- use this for refetching itinerary deets
    //   dispatch(receiveItineraries(res.data));
    // });
    // if (blockType.isNestedAdventureBlock) {
    //   return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
    //     parent_block_rid: returnActionPayload.parentBlockRid,
    //     adventure_rid: returnActionPayload.adventureRid,
    //     adventure_rid_exist_flag: true,
    //   }).then((res) => {
    //     dispatch(receiveAdventureBlocks(res.data));
    //   });
    // }
    // // updating when not neseted
    // return dispatch(receiveAdventureBlocks(returnedBlocks.data));
  } else if (blockType.isItineraryBlock) {
    //for updating ui when adventure block is nested
    updateCurrentItinerary(dispatch, getState);
    // updateCurrentAdventure(dispatch, getState);
    // if (blockType.isNestedItineraryBlock) {
    //   return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
    //     parent_block_rid: returnActionPayload.parentBlockRid,
    //   }).then((res) => {
    //     dispatch(
    //       receiveItineraryItemBlocksUpdated(res.data, {
    //         itinerary_item_rid: returnActionPayload.itineraryItemRid,
    //       }),
    //     );
    //   });
    // }
    // // updating when not neseted
    // return dispatch(
    //   receiveItineraryItemBlocksUpdated(
    //     {
    //       userBlocks: returnedBlocks.data.itinerary_item_blocks,
    //     },
    //     { itinerary_item_rid: returnActionPayload.itineraryItemRid },
    //   ),
    // );
  } else if (blockType.isListBlock || blockType.isNestedListBlock) {
    return BlockAPIUtil.fetchBlockLists(
      returnActionPayload.parentBlockRid,
      userInfo.explorer_rid,
      returnActionPayload.adventureBlockRid,
    ).then((res) => {
      return dispatch(receiveBlockLists(res.data));
    });
  }
};

/* 
  Purpose : deletes all types of blocks; library blocks, adventure blocks, and itinerary item blocks
  Updates 		: 	05-12-2021
  Pararmeters  :
    userInfo: **object** user data
    data: **object** block data for deleting,
    blockType: **object**  {
      isListBlock,
      isNestedListBlock
    }
    returnActionPayload: **object** used to update the ui accordingly
    {
      adventureRid,
      parentBlockRid,
      itineraryItemRid
  }
 */
export const upsertBlockAssignment = (data, origin, returnActionPayload) => (
  dispatch,
) => {
  return BlockAPIUtil.upsertBlockAssignment(data).then((res) => {});
};

export const checkIsAdventureBlock = (block_rid, adventure_rid) => (
  dispatch,
) => {
  return BlockAPIUtil.checkIsAdventureBlock(block_rid, adventure_rid).then(
    (res) => {
      return dispatch(receiveIsAdventureBlock(res.data));
    },
  );
};

export const copyToTripBoard = (
  userInfo,
  data,
  blockType,
  returnActionPayload,
  placeToUpdate,
) => (dispatch) => {
  return BlockAPIUtil.copyAndReturnNew(data).then((res) => {
    // if (placeToUpdate.itinerary_item_rid) {
    //   dispatch(receiveSingleItineraryItemBlockUpdate(returnActionPayload))
    // }
    if (blockType && blockType.isLibraryBlock) {
      // if (blockType.isNestedLibraryBlock) {
      return BlockAPIUtil.fetchBlocksDynamic(userInfo.account_rid, {
        parent_block_rid: returnActionPayload.parentBlockRid
          ? returnActionPayload.parentBlockRid
          : null,
        adventure_rid_exist_flag: returnActionPayload.adventureRid,
      }).then((res) => {
        if (blockType.isLibraryListOnly) {
          dispatch(receiveLibraryLists(res.data));
        }
        return dispatch(receiveLibraryBlocks(res.data));
      });
      // }
      // // updating when not neseted
      // return dispatch(receiveLibraryBlocks(returnedBlocks.data));
    } else {
      return res;
    }
  });
};

export const copyBlockAndAddToItineraryItems = (data, itineraryItems, func) => (
  dispatch,
) => {
  let payload = {
    blockData: data,
    itineraryItems,
  };
  BlockAPIUtil.copyBlockAndAddToItineraryItems(payload).then(() => {
    BlockAPIUtil.fetchBlocksDynamic(data.account_rid, {
      adventure_rid: data.adventure_rid,
      parent_block_rid:
        data.parent_block_rid &&
        data.createdFromNested &&
        data.parent_block_rid,
    }).then((res2) => {
      dispatch(receiveAdventureBlocks(res2.data));
      if (func) {
        func(); // this is the location block that is passed in from activity modal
      }
      // this is when the this aciton was called from the acitivty modal not from the map
      else {
        ItineraryAPIUtil.fetchItineraryDetail(
          data.itineraryRid,
          data.explorer_rid,
        ).then((res) => {
          // -- use this for refetching itinerary deets
          dispatch(receiveItineraries(res.data));
        });
      }
    });
  });
};

export const upsertLocationBlock = (
  data,
  userInfo,
  returnType,
  returnActionPayload,
) => (dispatch) => {
  return BlockAPIUtil.postUserBlock(data).then((res) => {
    if (returnType.isLocationMap) {
      return res.data;
    }
  });
};

export const fetchAllBlocksAsLists = (account_rid, block_rid, list_flag) => (
  dispatch,
) => {
  return BlockAPIUtil.fetchAllBlocksAsLists(
    account_rid,
    block_rid,
    list_flag,
  ).then((res) => {
    return dispatch(receiveAllBlocksAsLists(res.data));
  });
};

export const fetchAllAdventureBlocksAsLists = (
  account_rid,
  adventure_rid,
  block_rid,
  list_flag,
) => (dispatch) => {
  return BlockAPIUtil.fetchAllAdventureBlocksAsLists(
    account_rid,
    adventure_rid,
    block_rid,
    list_flag,
  ).then((res) => {
    return dispatch(receiveAllBlocksAsLists(res.data));
  });
};

export const fetchListDropDown = (userInfo, adventureRid, blockRid) => (
  dispatch,
) => {
  return BlockAPIUtil.fetchBlockListDropdown(
    userInfo.account_rid,
    adventureRid,
    blockRid,
  ).then((res) => {
    return dispatch(receiveListDropDown(res.data));
  });
};

export const updateBlockLocationPoint = (data) => (dispatch) => {
  return BlockAPIUtil.updateBlockLocationPoint(data).then((res) => {});
};

export const sendAdventureSelected = (data) => (dispatch) => {
  return dispatch(receiveAdventureSelected(data));
};

export const fetchFolderContents = (block_rid) => (dispatch) => {
  BlockAPIUtil.fetchFolderContents(block_rid).then((res) => {
    dispatch(receiveFolderContents(res.data));
  });
};

export const flushLocationBlocks = () => (dispatch) => {
  dispatch(receiveFlushLocationBlocks());
};

// ---------------------------- V2 FUNCTIONS FOR UPDATING UI ------------------------

/**
 *
 * @typedef {Object} libraryUiContext
 * @prop { number | null} adventure_rid
 * @prop { number } explorer_rid
 * @prop { number | null } inspiration_rid TODO: REMOVE
 * @prop { number | null } itinerary_item_rid TODO: REMOVE
 * @prop { number | null} parent_block_rid
 * @prop { boolean | null } adventure_rid_exist_flag TODO: REMOVE
 * @prop { ? | null } itineraryInfo TODO: REMOVE
 */

/**
 * Fetches block updates dynamically based on current UI Context
 * then updates library block store
 *
 * @param {libraryUiContext} returnActionPayload
 * @returns {?}
 */
export const updateLibraryBlock = (returnActionPayload) => (dispatch) => {
  return BlockAPIUtil.fetchBlocksDynamic(null, returnActionPayload).then(
    (res) => {
      return dispatch(receiveLibraryBlocks(res.data));
    },
  );
};

/**
 * @typedef {Object} uiUpdatePayload
 * @prop {number} account_rid
 * @prop {Object} data
 */

/**
 *
 * @param {uiUpdatePayload} uiUpdatePayload
 * @returns {unknown}
 */
export const updateAdventureBlock = (uiUpdatePayload) => (dispatch) => {
  return BlockAPIUtil.fetchBlocksDynamic(null, uiUpdatePayload).then((res) => {
    return dispatch(receiveAdventureBlocks(res.data));
  });
};

/**
 * Updates the current Adventure trip board and itinerary days with fresh block data
 *
 * @returns {Promise<*>}
 */
export const updateAdventureBlockAndItinerary = () => (dispatch, getState) => {
  // check if a current adventure is set
  if (!getState().entities?.itinerary?.itinerary_info?.adventure_rid) {
    throw new Error('No current adventure to update is set!');
  }

  const {
    itinerary_rid,
    ...rest
  } = getState().entities?.itinerary?.itinerary_info;
  const { account_rid, explorer_rid } = getState().session?.user;

  // update adventure and itinerary slices
  return Promise.all([
    updateCurrentAdventure(dispatch, getState),
    updateCurrentItinerary(dispatch, getState),
    dispatch(fetchItineraryDetails(itinerary_rid, account_rid, explorer_rid)),
  ]);
};

export const updateAdventureItineraries = (uiUpdatePayload) => (dispatch) => {
  return ItineraryAPIUtil.fetchItineraryDetail(
    uiUpdatePayload.itineraryInfo.itinerary_rid,
    uiUpdatePayload.explorer_rid,
  ).then((res) => {
    // -- use this for refetching itinerary deets
    return dispatch(receiveItineraries(res.data));
  });
};
export const updateLocationBlock = () => (dispatch) => {
  dispatch();
};

//v2 functions from here for upsertBlock

export const upsertBlockNoReturn = (data) => (dispatch) => {
  return BlockAPIUtil.postUserBlock(data);
};

export const upsertBlockReturnBlockRid = (data) => (dispatch) => {
  return BlockAPIUtil.insertBlockAndReturn(data).then((res) => {
    return res.data.singleBlock[0];
  });
};

export const upsertLibraryBlockAndCopyToAdventure = (data) => (dispatch) => {
  let adventureRidPlaceHolder = data.adventure_rid;
  let parentBlockRidPlaceHolders = data.parent_block_rid;
  data.adventure_rid = null;
  data.parent_block_rid = data.library_parent_block_rid;
  return BlockAPIUtil.insertBlockAndReturn(data).then((res) => {
    let newBlockToCopy = res.data.singleBlock[0];
    newBlockToCopy.adventure_rid = adventureRidPlaceHolder;
    newBlockToCopy.parent_block_rid = parentBlockRidPlaceHolders;
    return BlockAPIUtil.copyAndReturnNew(newBlockToCopy).then((res) => {
      return { block_rid: res.data.userBlocks[0].func_copy_return_new_block };
    });
  });
};
export const upsertToSeparateFolders = () => (dispatch) => {};

export const copyItemsToItineraries = (payload) => (dispatch) => {
  return BlockAPIUtil.copyBlockAndAddToItineraryItems(payload);
  // .then(() => {
  //   BlockAPIUtil.fetchBlocksDynamic(data.account_rid, {
  //     adventure_rid: data.adventure_rid,
  //     parent_block_rid:
  //       data.parent_block_rid &&
  //       data.createdFromNested &&
  //       data.parent_block_rid,
  //   }).then((res2) => {
  //     dispatch(receiveAdventureBlocks(res2.data));
  //     if (func) {
  //       func(); // this is the location block that is passed in from activity modal
  //     }
  //     // this is when the this aciton was called from the acitivty modal not from the map
  //     else {
  //       ItineraryAPIUtil.fetchItineraryDetail(
  //         data.itineraryRid,
  //         data.explorer_rid,
  //       ).then((res) => {
  //         // -- use this for refetching itinerary deets
  //         dispatch(receiveItineraries(res.data));
  //       });
  //     }
  //   });
  // });
};

// used for fetching itineray block spectators
export const fetchItineraryBlockSpectators = (itinerary_block_rid) => (
  dispatch,
) => {
  return BlockAPIUtil.fetchItineraryBlockSpectators(itinerary_block_rid).then(
    (res) => {
      return dispatch(
        receiveItineraryBlockSpectators(res.data.itineraryBlockSpectators),
      );
    },
  );
};

//used for upserting itinerary block spectators
export const upsertItineraryBlockSpectator = (data) => (dispatch) => {
  return BlockAPIUtil.upsertItineraryBlockSpectator(data).then((res) => {
    return dispatch(
      receiveItineraryBlockSpectators(res.data.itineraryBlockSpectators),
    );
  });
};

//used for deleting itinerary block spectators
export const deleteItineraryBlockSpectator = (
  itinerary_block_spectator_rid,
  itinerary_block_rid,
) => (dispatch) => {
  return BlockAPIUtil.deleteItineraryBlockSpectator(
    itinerary_block_spectator_rid,
    itinerary_block_rid,
  ).then((res) => {
    return dispatch(
      receiveItineraryBlockSpectators(res.data.itineraryBlockSpectators),
    );
  });
};

export const fetchTravelMethodBlockCategories = () => (dispatch) => {
  return BlockAPIUtil.fetchTravelMethodBlockCategories().then((res) => {
    return dispatch(
      receiveTravelMethodBlockCategories(res.data.travelMethodCategories),
    );
  });
};

export const fetchUserAddressBookBlocks = (account_rid) => (dispatch) => {
  return BlockAPIUtil.fetchUserAddressBookBlocks(account_rid).then((res) => {
    return dispatch(receiveAddressBookBlocks(res.data.userAddressBookBlocks));
  });
};

export const insertBlockToTripBoardWithTransportation = (data) => (
  dispatch,
) => {
  return BlockAPIUtil.insertBlockToTripBoardWithTransportation(data);
};
