export const svgBottomNavRefresh = (color, className, outerLayer, width = 75, height = 75) => {
  return (
    <svg className={outerLayer} width={width} height={height} viewBox="0 0 80 80">
      <g id="noun_Refresh_2391171" transform="translate(0 -0.002)">
        <path
          className={className}
          fill={color}
          id="Path_395"
          data-name="Path 395"
          d="M43.822,25.98a2.322,2.322,0,0,0,1.847,1.1l26.109.146a1.911,1.911,0,0,0,1.645-.923,1.948,1.948,0,0,0,.058-1.9L61.574,1.11A2.1,2.1,0,0,0,59.846,0a2.14,2.14,0,0,0-1.9.81L54.3,6.736a36.05,36.05,0,0,0-17.8-4.728,36.671,36.671,0,1,0,35.9,30.045c-.2.018-.394.073-.6.073h-.053l-12.257-.035a24.046,24.046,0,1,1-11.8-14.634L43.7,23.924A2.139,2.139,0,0,0,43.822,25.98Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};
