export function camping (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 128 128"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M87.72,47.29l-.06-.06c.02.02.02.06.04.08Z"
        ></path>
        <path
          className={className}
          d="M39.46,73.97l-3.6,2.7a1.99984,1.99984,0,0,1-2.4,0l-3.34-2.5L15.86,99.95H27.02l13.8-24.96Z"
        ></path>
        <path
          className={className}
          d="M38.26,69.87a2.04026,2.04026,0,0,1,2.42,0l2.1,1.6,7.04-12.74L44.24,48.65,32.08,70.63l2.58,1.94Z"
        ></path>
        <path
          className={className}
          d="M56.34,61.83l3.6-2.7a1.98018,1.98018,0,0,1,2.4,0l3.6,2.7,3.6-2.7a2.01034,2.01034,0,0,1,2.4-.02l3.62,2.72,2.54-1.92L65.92,37.89l-12.16,22Z"
        ></path>
        <path
          className={className}
          d="M76.76,65.93a2.04106,2.04106,0,0,1-2.4,0l-3.62-2.72-3.6,2.72a2.04106,2.04106,0,0,1-2.4,0l-3.6-2.7-3.6,2.7a2.00008,2.00008,0,0,1-2.4,0L51.8,63.41,31.6,99.95H82.9a2.17366,2.17366,0,0,1,.88,0h16.48l-20.2-36.5Z"
        ></path>
        <path
          className={className}
          d="M94.66,76.67a1.99982,1.99982,0,0,1-2.4,0l-.54-.4L104.82,99.95h7.44L98,74.15Z"
        ></path>
      </g>
    </svg>
  );
}