import { useQueryClient } from '@tanstack/react-query';
import { closeModal, openDeleteModal } from 'actions/modal_actions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlock } from 'util/api_util/block_api_util';

/**
 * @typedef {object} ListBlockData
 * @prop {number} block_rid
 * @prop {string} title
 */

/**
 * @param {ListBlockData} listData
 */
const useDeleteListModalButton = (listData) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const adventure_rid = useSelector(
    (state) => state?.entities?.adventure?.selectedAdventure?.adventure_rid,
  );
  const itinerary_rid = useSelector(
    (state) => state?.entities?.itinerary?.itinerary_info?.itinerary_rid,
  );
  const userInfo = useSelector((state) => state?.session?.user);

  let data = {
    block_rid: listData.block_rid,
    inspiration_rid: null,
    adventure_rid: adventure_rid,
    adventure_rid_exist_flag: null,
    itinerary_item_rid: null,
    parent_block_rid: null,
    explorer_rid: userInfo.explorer_rid,
  };

  const returnActionPayload = {};

  const openModal = async () => {
    const onConfirm = async () => {
      await deleteBlock(data, userInfo.account_rid);
      queryClient.invalidateQueries({
        queryKey: ['adventure', adventure_rid, 'blocks'],
      });
      queryClient.invalidateQueries({
        queryKey: ['itinerary'],
      });
      dispatch(closeModal());
    };

    dispatch(
      openDeleteModal(
        {
          content: `Are you sure you want to delete '${listData.title}' list?`,
        },
        onConfirm,
      ),
    );
  };

  return { openModal };
};

export default useDeleteListModalButton;
