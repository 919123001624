/**
 *
 * Gets the number of seconds from Unix Epoch given a js Date object.
 *
 * Useful for checking jwt exp time, which is in the format of Unix Epoch Seconds.
 *
 * https://en.wikipedia.org/wiki/Unix_time
 * @param {Date} date
 * @returns
 */
export const getUnixEpochSeconds = (date) => {
  if (!(date instanceof Date)) throw new TypeError('Expected a Date Object');
  return Math.floor(date.getTime() / 1000); // Return current time in second
};
