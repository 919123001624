import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upsertBlock } from 'actions/block_actions';
import { getQueryKey } from 'features/adventure/hooks/use-adventure-lists';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import useUserInfo from 'features/user/hooks/use-user-info';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBlockDetails } from 'util/api_util/block_api_util';

const useMutateListItemInAdventureLists = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userInfo = useUserInfo();
  const { adventure_rid } = useCurrentAdventure();
  const adventureListsQueryKeys = getQueryKey(adventure_rid);

  const { callDebounceFn } = useDebounce(async () => {
    await queryClient.cancelQueries(adventureListsQueryKeys);
    queryClient.invalidateQueries(adventureListsQueryKeys);
  }, 5000);

  const updateCacheOptimistically = async (updatedItem) => {
    await queryClient.cancelQueries(adventureListsQueryKeys);

    // Snapshot the previous value
    const previousLists = queryClient.getQueryData(adventureListsQueryKeys);

    // Optimistically update the cache
    queryClient.setQueryData(adventureListsQueryKeys, (oldLists) =>
      oldLists.map((list) => {
        if (list.block_rid === updatedItem.listId) {
          return {
            ...list,
            items: list.items.map((item) =>
              item.block_rid === updatedItem.block_rid ? updatedItem : item,
            ),
          };
        }
        return list;
      }),
    );

    return { previousLists };
  };

  return useMutation({
    mutationFn: async (updatedListItem) => {
      // get full block data to update
      const block = await fetchBlockDetails(updatedListItem.block_rid).then(
        (res) => res.data?.blockDetails?.[0],
      );

      block.completed_flag = updatedListItem.completed;

      let blockType = {
        isAdventureListBlockCheck: !!block.adventure_block_rid,
        isLibraryListBlockCheck: !!block.adventure_block_rid,
        isListBlock: true,
      };

      let passObject = { ...block };
      let returnActionPayload = {
        parentBlockRid: block.block_rid,
        adventureBlockRid: block.adventure_block_rid,
      };

      dispatch(
        await upsertBlock(userInfo, passObject, blockType, returnActionPayload),
      );
    },
    onMutate: async (updatedItem) => {
      // Call debouncedOnMutate with updated item
      return updateCacheOptimistically(updatedItem);
    },
    onError: (err, variables, context) => {
      console.error('Error mutating adventure lists: ' + err);
      // Roll back cache to the previous value in case of an error
      queryClient.setQueryData(adventureListsQueryKeys, context?.previousLists);
    },
    onSettled: async () => {
      // Refetch after mutation settles
      callDebounceFn();
    },
  });
};

const useDebounce = (fn, duration) => {
  const debounceRef = useRef();

  // Set up the debounced function
  const debounceFn = useRef(
    debounce(async (...args) => {
      await fn(...args);
    }, duration),
  ).current;

  // Cancel the previous debounce on new call
  useEffect(() => {
    debounceRef.current = debounceFn;
    return () => {
      debounceRef.current.cancel();
    };
  }, [debounceFn]);

  // Create a function that calls the debounced function and cancels previous calls
  const callDebounceFn = (...args) => {
    if (debounceRef.current) {
      debounceRef.current.cancel(); // Cancel the previous call
    }
    debounceRef.current(...args); // Call the debounced function
  };

  return { callDebounceFn };
};

export default useMutateListItemInAdventureLists;
