import {
  RECEIVE_ACCOUNT_ROLES,
  RECEIVE_AMBASSADOR_CODES,
  RECEIVE_COUNTRIES,
  RECEIVE_FLUSH_PARTNERS,
  RECEIVE_FLUSH_SELECTED_PARTNERS,
  RECEIVE_OPEN_DRAWER_WITH_TAB,
  RECEIVE_PAGE_DATA,
  RECEIVE_PAGE_TOOL,
  RECEIVE_PARTNER_DONATION_DISTRIBUTION,
  RECEIVE_PARTNER_PROMOTION_CODES,
  RECEIVE_PARTNERS,
  RECEIVE_PLAN_DETAILS,
  RECEIVE_PLAN_FINAL_PIRCE,
  RECEIVE_PROMOTION_BANNER,
  RECEIVE_SELECTED_PARTNER,
  RECEIVE_STATES,
  RECEIVE_TRANSPORTATION_TYPES,
  RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES,
  RECEIVE_UNSUBSCRIPTION_REASONS,
} from '../../constants';

import { merge } from 'lodash';
const defaultState = {};

export default function modalReducer(state = defaultState, action) {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_PAGE_DATA:
      return { pageComponentData: action.data.pageContent };
    case RECEIVE_PAGE_TOOL:
      return { pageTools: action.data.uiTools };
    case RECEIVE_ACCOUNT_ROLES:
      newState['accountRoles'] = action.data;
      return newState;
    case RECEIVE_COUNTRIES:
      newState['countries'] = action.data;
      return newState;
    case RECEIVE_STATES:
      newState['statesProvences'] = action.data;
      return newState;
    case RECEIVE_PLAN_DETAILS:
      newState['planDetails'] = action.data;
      return newState;
    case RECEIVE_PLAN_FINAL_PIRCE:
      newState['planFinalPrice'] = action.data;
      return newState;
    case RECEIVE_UNSUBSCRIPTION_REASONS:
      newState['unsubscriptionReasons'] = action.data;
      return newState;
    case RECEIVE_AMBASSADOR_CODES:
      newState['ambassadorCodes'] = action.data;
      return newState;
    case RECEIVE_OPEN_DRAWER_WITH_TAB:
      newState['openDrawerWithTab'] = action.data;
      return newState;
    case RECEIVE_PROMOTION_BANNER:
      newState['bannerMessages'] = action.data;
      return newState;
    case RECEIVE_PARTNER_PROMOTION_CODES:
      newState['partnerPromotionCodes'] = action.data;
      return newState;
    case RECEIVE_PARTNERS:
      newState['partners'] = action.data;
      return newState;
    case RECEIVE_SELECTED_PARTNER:
      if (Array.isArray(action.data)) {
        newState['selectedPartner'] = action.data.map(
          (partner) => partner.partner_rid,
        );
        newState['selectedPartnerObject'] = action.data;
        return newState;
      }
      if (newState.selectedPartner) {
        if (newState.selectedPartner.includes(action.data.partner_rid)) {
          let index = newState.selectedPartner.indexOf(action.data.partner_rid);
          newState.selectedPartner.splice(index, 1);
          newState.selectedPartnerObject.splice(index, 1);
          return { ...newState };
        } else {
          newState.selectedPartner.push(action.data.partner_rid);
          newState.selectedPartnerObject.push(action.data);
          return { ...newState };
        }
      } else {
        newState['selectedPartner'] = [action.data.partner_rid];
        newState['selectedPartnerObject'] = [action.data];
      }

      return newState;
    case RECEIVE_FLUSH_PARTNERS:
      // newState['selectedPartner'] = null;
      newState['partners'] = null;
      return newState;
    case RECEIVE_PARTNER_DONATION_DISTRIBUTION:
      newState['partnerDistribution'] = action.data;
      return newState;
    case RECEIVE_TRANSPORTATION_TYPES:
      newState['transportationTypes'] = action.data;
      return newState;
    case RECEIVE_FLUSH_SELECTED_PARTNERS:
      delete newState.selectedPartner;
      delete newState.selectedPartnerObject;
      return newState;
    case RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES:
      newState['travelMethodBlockCategories'] = action.data;
      return newState;
    default:
      return state;
  }
}
