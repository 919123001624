import React from 'react'

const NoneFound = () => {
  return (
    <h1>
      404 no address found
    </h1>
  )
}

export default NoneFound