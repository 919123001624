export const svgLinkedApps = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '36'}
      height={height ? height : '36'}
      viewBox={viewBox ? viewBox : '0 0 36 36'}
    >
      <path
        className={className}
        id="Icon_awesome-heart"
        data-name="Icon awesome-heart"
        d="M32.505,4.4a9.615,9.615,0,0,0-13.12.956L18,6.785,16.615,5.358A9.615,9.615,0,0,0,3.495,4.4,10.1,10.1,0,0,0,2.8,19.02L16.4,33.068a2.2,2.2,0,0,0,3.185,0L33.195,19.02A10.09,10.09,0,0,0,32.505,4.4Z"
        transform="translate(0.001 -2.248)"
        fill={color}
      />
    </svg>
  );
};