export const mapStateToProps = (state) => {
  let sendingUser = state.chatRoom.activeChatRoom.sendingUser
    ? state.chatRoom.activeChatRoom.sendingUser
    : null;
  let receivingUser = state.chatRoom.activeChatRoom.receivingUser
    ? state.chatRoom.activeChatRoom.receivingUser
    : null;
  return {
    sendingUser,
    receivingUser,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {};
};
