import React from 'react';
import moment from 'moment';
import { svgDelete } from 'components/svg_module';
const Comment = ({ comment, deleteHandler }) => {
  return (
    <div className="modal-comment-module-wrapper">
      <div className="modal-comment modal-comment--left">
        <div className="modal-comment modal-comment--top">
          {comment.explorer_name} —{' '}
          {moment(comment.explorer_comment_date).format('D MMM YYYY, hh:mm a')}
        </div>
        <div className="modal-comment modal-comment--bottom">
          {comment.explorer_comment}
        </div>
      </div>
      <div className="modal-comment modal-comment--right">
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteHandler();
          }}
          className="modal-comment--button"
        >
          {svgDelete('', 'comment-delete-svg', 'comment-delete-svg-outer')}
        </button>
      </div>
    </div>
  );
};

export default Comment;
