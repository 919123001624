import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserInfo } from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Headers } from './shared_modules';
import { merge } from 'lodash';
import {
  insertUserSusbscriptionAndSubscriptionId,
  updateSubScriptionPartnerCode,
  sendBoardingUser,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { updateSessionToken } from '../../../actions/session_actions';
const planDetails = [
  'Create and store trips in your own Adventure Hub',
  'Build a library to save and organize your ideas',
  'Tag & Save trail maps, lodging, and other trips ideas from the web',
  'Invite friends to collaborate on trips',
  'Build detailed trip itineraries',
  'Map your route',
  'Create custom packing lists',
  "Keep track of where you've been",
  'Record and share your trips',
];
const mapStateToProps = ({ session, entities }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  return {
    userInfo,
    currentExplorerDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    insertUserSusbscriptionAndSubscriptionId: (data) =>
      dispatch(insertUserSusbscriptionAndSubscriptionId(data)),
    updateSubScriptionPartnerCode: (data) =>
      dispatch(updateSubScriptionPartnerCode(data)),
    updateSessionToken: (data) => dispatch(updateSessionToken(data)),
    fetchAccountExplorer: (accountRid, explorerRid) =>
      dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  };
};
const PaymentSuccessful = ({
  location,
  history,
  userInfo,
  insertUserSusbscriptionAndSubscriptionId,
  updateSubScriptionPartnerCode,
  currentExplorerDetails,
  updateSessionToken,
  fetchAccountExplorer,
  ...props
}) => {
  const [planDataAndExplorer, setPlanDataAndExplorer] = useState(null);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    scrollTop();
    fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  useEffect(() => {
    if (location.search !== '' && currentExplorerDetails !== null) {
      let url_plan_rid = location.search.split('&')[0].split('=')[1];
      let url_subscription_id = location.search.split('&')[1].split('=')[1];
      let updateSubscriptionPayload = merge({}, currentExplorerDetails);
      const { subscription_rid, email, explorer_rid } = currentExplorerDetails;
      let payload = {
        explorer_rid,
        subscription_rid,
        subscription_partner_code: url_subscription_id,
      };
      console.log(payload);
      payload.membershipUpgrade = true;
      updateSubscriptionPayload.plan_rid = url_plan_rid;
      updateSubscriptionPayload.auto_renew_flag = false;
      updateSubscriptionPayload.subscription_partner_code =
        payload.subscription_partner_code;
      insertUserSusbscriptionAndSubscriptionId(updateSubscriptionPayload).then(
        (res) => {
          if (res.status === 200) {
            payload.subscription_rid =
              res.data.userSubscription[0].subscription_rid;
            updateSubScriptionPartnerCode(payload).then((res2) => {
              if (res2.status === 200) {
                updateSessionToken({
                  plan_rid: url_plan_rid,
                  subscription_rid:
                    res.data.userSubscription[0].subscription_rid,
                });
                history.push('/hub/account/payment-succesful');
              }
            });
          }
        },
      );
    }
  }, [currentExplorerDetails]);
  useEffect(() => {
    setTimeout(() => {
      history.push('manage-subscription');
    }, 10000);
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="PAYMENT CONFIRMED" />
          <section className="account-payment-succesful-wrapper">
            {/* <section className="account-payment-successful-section account-payment-successful-section--1">
              <img
                className="account-payment-successful-banner"
                src="https://storage.googleapis.com/lodestar-vpc-host-nonprod.appspot.com/app_media/app_product_image/lodestar_explorer_logo.svg"
              />
            </section> */}
            <section className="account-payment-successful-section account-payment-successful-section--2">
              <div className="account-payment-successful-message-header-wrapper">
                <h2>Thank you for your payment!</h2>
              </div>
              <hr className="account-payment-successful-message-divider" />
              <div classname="account-payment-successful-message-small-text">
                <span>
                  Here are some features you'll be able to enjoy make sure to
                  check them out!
                </span>
              </div>
              <div className="account-payment-successful-message-features-list">
                {planDetails.map((ele) => (
                  <div className="payment-successful-text-contents-wrapper">
                    <div>
                      <span className="check-mark-payment-successful">
                        &#10003;
                      </span>
                    </div>

                    <span className="payment-successful-text">{ele}</span>
                  </div>
                ))}
              </div>
            </section>
            <section className="account-payment-successful-section account-payment-successful-section--3">
              <h2>
                You'll be automatically directed to your membership page..
              </h2>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessful),
);
