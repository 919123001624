export const svgClip = (color, className, outerLayer, width, height) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 49.348, 53.706"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M4.042,49.664 C-1.347,44.275 -1.347,35.537 4.042,30.148 L31.364,2.825 C35.132,-0.942 41.258,-0.942 45.026,2.826 C48.793,6.593 48.793,12.719 45.026,16.487 L17.703,43.809 C15.558,45.955 12.042,45.955 9.897,43.809 C7.751,41.664 7.751,38.148 9.897,36.003 L35.268,10.632 C35.615,10.275 36.127,10.133 36.608,10.26 C37.089,10.386 37.465,10.762 37.591,11.243 C37.718,11.724 37.576,12.236 37.219,12.583 L11.848,37.955 C10.75,39.053 10.75,40.76 11.848,41.858 C12.946,42.956 14.653,42.956 15.751,41.858 L43.074,14.535 C45.794,11.815 45.794,7.497 43.074,4.777 C40.354,2.057 36.036,2.057 33.316,4.777 L5.993,32.1 C1.652,36.441 1.652,43.371 5.993,47.712 C10.335,52.054 17.265,52.054 21.606,47.713 L46.977,22.342 C47.324,21.984 47.837,21.842 48.318,21.968 C48.8,22.095 49.176,22.471 49.302,22.952 C49.429,23.434 49.286,23.946 48.929,24.293 L23.558,49.664 C18.169,55.053 9.431,55.053 4.042,49.664 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};