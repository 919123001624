export const MapRoad = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
 ) => { return (
  <svg className={outerLayer} 
    width={width ? width : '27' }
    height={height ? height: '27'}
    viewBox={ viewBox || "0 0 27 27"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width|| "27"} height={ height || "27" } rx="5" fill={'#FCCE13'}/>
    <path d="M6.00812 18.3439C7.14536 19.9885 8.80517 21.3625 10.7963 22.2798C11.3237 22.5228 12.4476 23.0003 12.9987 22.9998C13.5497 23.0003 14.6738 22.5228 15.2012 22.2798C17.1923 21.3625 18.8519 19.9885 19.9895 18.3439C21.5031 16.1547 22.5251 12.6864 21.7085 9.25977H4.28896C3.47229 12.6862 4.49433 16.1547 6.00812 18.3439Z" fill={'#273906'}/>
    <path d="M19.7022 5C18.1287 5.46026 16.4389 5.58518 14.6268 5.33603C14.0658 5.25886 13.504 5 12.9988 5C12.4937 5 11.932 5.25887 11.3709 5.33603C9.55858 5.58517 7.86898 5.46025 6.29547 5C5.57741 5.92285 4.97658 6.9632 4.61157 8.24H21.3861C21.0212 6.96325 20.4206 5.9228 19.7023 5H19.7022Z" fill={'#273906'}/>
  </svg>
  )
}
