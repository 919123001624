import { upsertAdventureFriend } from 'actions/social_actions';
import { insertBlockToTripBoardWithTransportation } from 'actions/block_actions';
import { moveItineraryBlock } from 'actions/itinerary_actions';
export const mapDispatchToProps = (dispatch) => {
  return {
    upsertAdventureFriend: (data) => dispatch(upsertAdventureFriend(data)),
    insertBlockToTripBoardWithTransportation: (data) =>
      dispatch(insertBlockToTripBoardWithTransportation(data)),
    moveItineraryBlock: (
      itinerary_item_rid,
      block_rid,
      account_rid,
      explorer_rid,
      index,
      itinerary_rid,
    ) =>
      dispatch(
        moveItineraryBlock(
          itinerary_item_rid,
          block_rid,
          account_rid,
          explorer_rid,
          index,
          itinerary_rid,
        ),
      ),
  };
};

export const mapStateToProps = ({ entities, ui, session }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let userAddressBookBlocks = entities?.blocks?.addressBookBlocks;
  let invitedAdventureItineraries =
    entities?.itinerary?.itineraryAndItineraryItems;
  let adventureFriendPayload = ui?.modal?.acceptAdventureInvitePayload;
  let invitedAdventureData = ui?.modal?.invitedAdventureData;
  let travelMethodBlockCategories = ui?.components?.travelMethodBlockCategories;
  return {
    userInfo,
    userAddressBookBlocks,
    invitedAdventureItineraries,
    adventureFriendPayload,
    invitedAdventureData,
    travelMethodBlockCategories,
  };
};
