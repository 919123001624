"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexColumn = exports.flex = void 0;
exports.flex = {
    display: 'flex',
};
exports.flexColumn = {
    display: 'flex',
    flexDirection: 'column',
};
