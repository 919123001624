export function kayak (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path enableBackground="new 0 0 100 100" />
      <path
        fill={color}
        className={className}
        d="M32.793,33.841l5.846,5.846l1.062-1.062l-5.843-5.843c0.423-1.621,0.221-5.094-0.465-5.775l-4.756-4.744  c-0.826-0.819-2.154-0.819-2.977,0.004l-3.393,3.404c-0.824,0.82-0.822,2.156,0,2.977l4.758,4.744  C27.712,34.069,31.168,34.264,32.793,33.841z M67.202,66.146l-5.842-5.842c0,0-1.034,1.031-1.064,1.061l5.84,5.84  c-0.421,1.622-0.219,5.096,0.467,5.777l4.758,4.743c0.824,0.819,2.152,0.818,2.974-0.004l3.396-3.404  c0.822-0.819,0.822-2.156-0.002-2.976l-4.758-4.744C72.286,65.916,68.827,65.724,67.202,66.146z M32.626,62.601  c-1.314,1.314-1.315,3.453,0,4.768c1.272,1.273,3.493,1.273,4.768-0.001c1.314-1.314,1.314-3.452,0-4.767  c-0.637-0.637-1.484-0.988-2.385-0.988C34.11,61.612,33.263,61.966,32.626,62.601z M45.895,45.903  c-2.257,2.266-2.257,5.934,0.002,8.193c2.258,2.258,5.925,2.256,8.189-0.002c2.26-2.26,2.259-5.93-0.001-8.189  C51.823,43.645,48.155,43.645,45.895,45.903z M56.897,35.044l5.766,0.492l-0.805-3.576L56.897,35.044z M64.949,43.096l3.084-4.962  l-3.577-0.805L64.949,43.096z M40.235,40.204c-0.004,0.002-0.025,0.012-0.025,0.012C25.618,54.805,19.854,72.685,23.581,76.409  c3.479,3.479,21.598-2.04,36.181-16.62c0.001-0.001,0.015-0.007,0.015-0.007c14.588-14.584,20.109-32.711,16.629-36.19  C72.928,20.113,54.815,25.629,40.235,40.204z M38.454,68.43c-0.92,0.92-2.145,1.428-3.445,1.429c-1.301,0-2.526-0.508-3.446-1.428  c-1.898-1.9-1.898-4.991,0-6.893c0.922-0.92,2.146-1.428,3.445-1.428c1.302,0,2.525,0.508,3.445,1.428  C40.354,63.438,40.354,66.529,38.454,68.43z M56.78,56.786c-3.748,3.748-9.827,3.748-13.576,0c-3.748-3.752-3.748-9.827,0-13.574  c3.75-3.754,9.829-3.754,13.577-0.002C60.531,46.956,60.53,53.034,56.78,56.786z M70.426,37.132l-6.587,10.597l-0.912-10.664  l-10.662-0.91l10.598-6.59l1.389,6.178L70.426,37.132z"
      ></path>
    </svg>
  );
}