export const PhoneIcon = ({ color = "#273806", className, outerLayer, width = 25, height = 25 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5004 0C5.63933 0 0.0559985 5.58333 0.000442952 12.4167C-0.0273163 15.75 1.25044 18.9167 3.61155 21.2778C5.94489 23.6667 9.08378 24.9722 12.4171 25H12.5005C19.3616 25 24.9449 19.4167 25.0005 12.5833C25.056 5.69444 19.4727 0.0555556 12.5005 0H12.5004ZM18.556 16.6667L18.0004 17.2222C17.0837 18.1389 16.306 18.3611 15.0838 18C13.806 17.6111 12.6671 16.9167 11.6393 16.0834C10.1671 14.8889 8.58378 13.3334 7.556 11.7222C6.52827 10.0556 6.19486 8.30558 7.63937 6.86113L8.19492 6.30558C8.58384 5.91667 9.22266 5.91667 9.63932 6.30558L11.2782 7.94449C11.6671 8.3334 11.6671 8.97222 11.2782 9.38889L10.306 10.3611L10.3338 10.3889C11.4171 12.0278 12.8338 13.4444 14.4727 14.5278L14.5004 14.5555L15.4726 13.5833C15.8616 13.1944 16.5004 13.1944 16.917 13.5833L18.556 15.2222C18.9726 15.6389 18.9726 16.2777 18.556 16.6666L18.556 16.6667Z"
        fill={color}
      />
    </svg>
  )
};
