import { useQueryClient } from '@tanstack/react-query';
import useCurrentAdventure from './use-current-adventure';

const useInvalidateAdventureData = () => {
  const { adventure_rid = null } = useCurrentAdventure() || {}; // sometimes this may null

  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    if (!adventure_rid)
      console.warn('Invalidating adventure data when no adventure is set.');

    queryClient.invalidateQueries({
      queryKey: ['adventure', adventure_rid],
    });
    queryClient.invalidateQueries({
      queryKey: ['itinerary'],
    });
  };

  return { invalidateQueries };
};

export default useInvalidateAdventureData;
