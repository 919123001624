export const svgAccept = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      className={outerLayer}
      width="100px"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      x="0px"
      y="0px"
    >
      <g data-name="Layer 101">
        <path
          className={className}
          d="M32,5.83A26.17,26.17,0,1,0,58.17,32,26.2,26.2,0,0,0,32,5.83ZM47.77,22.88,29.94,43.72a2,2,0,0,1-1.39.7h-.13a2,2,0,0,1-1.34-.52L16.41,34.23a2,2,0,0,1,2.69-3l9.14,8.29,16.5-19.27a2,2,0,1,1,3,2.6Z"
        ></path>
      </g>
    </svg>
  );
};