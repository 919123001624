import React from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import HubModal from '../../modal';

const mapStateToProps = ({ ui }) => {
  let message = ui.modal.message && ui.modal.message;
  return {
    message,
  };
};
const MessageModal = ({ message, closeModal }) => {
  const handleSubmit = () => {
    closeModal();
  };
  return (
    <HubModal
      show={true}
      title="LODESTAR HUB"
      buttonTitle="CLOSE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <Stack spacing={2}>
          <div className="alert-message-modal-information--wrapper">
            <span>{message}</span>
          </div>
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps)(MessageModal);
