export function airplane (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M88.5,41.2l-24.1,0.5l-6.7-12h2.9c2.6,0,4.7-2.1,4.7-4.7l0,0c0-2.6-2.1-4.7-4.7-4.7h-8.2L45.3,7.4    c-0.5-0.9-1.5-1.5-2.6-1.5h-7c-0.9,0-1.6,0.9-1.3,1.8L45.9,42l-29.8,0.6L13,35.4c-0.5-1.1-1.5-1.8-2.7-1.8H3.8    c-0.9,0-1.5,0.8-1.3,1.6L5.7,50L2.5,64.8c-0.2,0.8,0.5,1.6,1.3,1.6h6.5c1.2,0,2.2-0.7,2.7-1.8l3.1-7.2L45.9,58L34.5,92.4    c-0.3,0.9,0.4,1.8,1.3,1.8h7c1.1,0,2-0.6,2.6-1.5l7.2-12.8h8.2c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7h-2.9l6.7-12l24.1,0.5    c4.9,0.1,9-3.9,9-8.8C97.5,45.1,93.4,41.1,88.5,41.2z"
        >
          {' '}
        </path>
      </g>
    </svg>
  );
}