export function bus (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 287 157"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path
          shapeRendering="geometricPrecision;"
          textRendering="geometricPrecision;"
          imageRendering="optimizeQuality;"
        ></path>
        <path
          fill={color}
          className={className}
          d="M257 0l-242 0c-8,0 -15,7 -15,15l0 109c0,5 4,9 9,9l27 0c-1,-2 -1,-4 -1,-6 0,-20 16,-36 36,-36 20,0 36,16 36,36 0,2 0,4 0,6l84 0c-1,-2 -1,-4 -1,-6 0,-20 16,-36 36,-36 20,0 36,16 36,36 0,2 0,4 0,6l16 0c21,0 3,-133 -21,-133zm-31 98c16,0 30,13 30,29 0,16 -14,30 -30,30 -16,0 -29,-14 -29,-30 0,-16 13,-29 29,-29zm0 13c-9,0 -16,7 -16,16 0,9 7,16 16,16 9,0 16,-7 16,-16 0,-9 -7,-16 -16,-16zm-155 0c9,0 16,7 16,16 0,9 -7,16 -16,16 -9,0 -16,-7 -16,-16 0,-9 7,-16 16,-16zm0 -13c16,0 29,13 29,29 0,16 -13,30 -29,30 -16,0 -29,-14 -29,-30 0,-16 13,-29 29,-29zm185 -88c9,7 18,47 20,81 0,2 0,4 -3,4l-17 0c-4,0 -7,-3 -8,-6 -3,-18 -29,-31 -39,-31l0 -48 47 0zm-57 48l-59 0 0 -48 59 0 0 48zm-69 0l-58 0 0 -48 58 0 0 48zm-68 0l-52 0 0 -43c0,-3 2,-5 5,-5l47 0 0 48z"
        ></path>
      </g>
    </svg>
  );
}