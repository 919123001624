import { combineReducers } from 'redux';
import sites from './sites_reducer';
import users from './users_reducer';
import resource from './resources_reducer';
import adventure from './adventures_reducer';
import inspiration from './inspirations_reducer';
import blocks from "./blocks_reducer"
import itinerary from "./itinerary_reducer"
import itBlocks from './it_blocks_reducer'
import social from "./social_reducer"
import notification from './notification_reducer'
import comments from './comments_reducer'
import votes from './votes_reducer'
const EntitiesReducers = combineReducers({
  resource,
  sites,
  users,
  adventure,
  inspiration,
  blocks,
  itinerary,
  itBlocks,
  social,
  notification,
  comments,
  votes
})

export default EntitiesReducers