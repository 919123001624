export function butterfly (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 100 100" />
        <path
          fill={color}
          className={className}
          d="M65.037,45.68c6.367-6.367,10.126-12.932,8.395-14.663c-1.731-1.732-8.297,2.025-14.664,8.393  c-5.473,5.473-9.007,11.083-8.796,13.653L31.045,71.991c-0.863-0.324-1.874-0.144-2.568,0.551l-4.745,4.744  c-0.947,0.947-0.945,2.482,0,3.429c0.946,0.946,2.482,0.947,3.43,0.001l4.745-4.745c0.694-0.693,0.874-1.701,0.552-2.564  l18.93-18.932C53.958,54.684,59.566,51.15,65.037,45.68z M39.757,35.486c0.921,2.674,2.117,5.392,3.432,7.936  c1.211-3.217,4.492-7.537,8.272-11.318c3.78-3.78,8.1-7.061,11.315-8.272c-2.543-1.313-5.261-2.51-7.934-3.431  c-1.675,0.489-4.907,2.404-8.794,6.292C42.162,30.578,40.245,33.813,39.757,35.486z M52.521,33.164  c-5.263,5.263-8.013,9.957-8.338,12.111c1.438,2.583,2.956,4.895,4.362,6.69c0.195-1.187,0.748-2.593,1.686-4.231  c1.649-2.882,4.305-6.215,7.476-9.386c3.956-3.956,9.705-8.521,13.605-9.169c-1.786-1.396-4.078-2.901-6.64-4.33  c-0.732,0.094-1.852,0.461-3.491,1.4C58.546,27.76,55.471,30.215,52.521,33.164z M44.988,25.632c2.448-2.45,5.277-4.721,7.652-5.929  c-5.341-1.53-10.226-1.724-12.765,0.815c-2.539,2.54-2.347,7.425-0.816,12.765C40.267,30.909,42.538,28.081,44.988,25.632z   M72.896,64.521l4.084-1.645l-0.748-1.855l-3.76,1.515l1.514-3.761l-1.855-0.746l-1.644,4.083c-0.209-0.596-0.543-1.156-1.019-1.632  c-1.702-1.701-4.458-1.701-6.161,0c-1.223,1.224-1.553,2.988-1.016,4.521c-0.817,0.048-1.62,0.372-2.245,0.998  c-1.358,1.356-1.357,3.562,0,4.921c0.837,0.836,1.999,1.156,3.084,0.96c-0.197,1.083,0.121,2.244,0.96,3.082  c1.357,1.358,3.562,1.358,4.92,0.001c0.625-0.625,0.947-1.428,0.998-2.246c1.529,0.536,3.297,0.207,4.521-1.016  c1.701-1.701,1.7-4.459-0.001-6.16C74.052,65.064,73.491,64.729,72.896,64.521z"
        ></path>
      </g>
    </svg>
  );
}