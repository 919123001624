export function convertToGeolocationString(latitude, longitude) {
  const latitudeDegrees = Math.floor(latitude);
  const latitudeMinutes = Math.floor((latitude - latitudeDegrees) * 60);
  const latitudeSeconds = ((latitude - latitudeDegrees) * 60 - latitudeMinutes) * 60;
  
  const longitudeDegrees = Math.floor(longitude);
  const longitudeMinutes = Math.floor((longitude - longitudeDegrees) * 60);
  const longitudeSeconds = ((longitude - longitudeDegrees) * 60 - longitudeMinutes) * 60;
  
  const latitudeString = `${latitudeDegrees}°${latitudeMinutes}'${latitudeSeconds.toFixed(3)}"N`;
  const longitudeString = `${longitudeDegrees}°${longitudeMinutes}'${longitudeSeconds.toFixed(3)}"W`;

  return latitudeString + " " + longitudeString;
}