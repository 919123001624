import { Box, Link, Paper } from '@mui/material';
import FolderTag from 'features/activity/components/folder-tag';
import { CenteredSectionHeader } from 'features/adventure/components/adventure-lists';
import { useCallback } from 'react';
import useEditListModalButton from '../hooks/use-edit-list-modal-button';
import CheckList from './checklist';
import ListActionMenu from './list-action-menu';

/**
 * ListBlockCard component displays a checklist inside a styled Paper component with additional functionalities
 * such as displaying a folder tag and a delete list button.
 *
 * @component
 *
 * @param {Object} props - The props for the ListBlockCard component.
 * @param {Object} props.list - The data for the list block.
 * @param {number} props.list.block_rid - The unique identifier for the list block.
 * @param {string} props.list.title - The title of the list block.
 * @param {string} [props.list.folder] - The name of the folder associated with the list (optional).
 * @param {string} [props.list.color] - The background color associated with the folder tag (optional).
 * @param {Array<import('./checklist').CheckListItem>} props.list.items - The array of items to display in the checklist.
 *
 * @returns {JSX.Element} A card displaying a list with a folder tag and a delete button.
 *
 * @example
 * // Usage example:
 * <ListBlockCard
 *   list={{
 *     block_rid: '123',
 *     title: 'My Checklist',
 *     folder: 'Work Folder',
 *     color: '#D5E3E7',
 *     items: [...]
 *   }}
 * />
 */
const ListBlockCard = ({ list, onCheck }) => {
  const { openModal } = useEditListModalButton(list.block_rid);

  // Memoized handler to avoid recreating on every render
  const handleTitleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openModal();
    },
    [openModal],
  );

  return (
    <Box maxWidth={500} width={1} key={`adventure-checklist-${list.block_rid}`}>
      <Paper
        color="#D5E3E7"
        elevation={0}
        sx={{ mb: 4, background: '#D5E3E7' }}
      >
        <Box pt={2} />
        <Box
          px={2}
          display="flex"
          flexDirection={'row'}
          justifyContent="space-between"
          pb={1}
        >
          {list.folder ? (
            <FolderTag text={list.folder} backgroundColor={list.color} />
          ) : (
            <Box />
          )}
        </Box>
        <Box position="relative" width={1}>
          <Box sx={{ px: 6, pt: 0.5 }}>
            <Link onClick={handleTitleClick} variant="primary">
              <CenteredSectionHeader title={list.title} />
            </Link>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 8,
            }}
          >
            <ListActionMenu listData={list} />
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: 'min(500px, 50dvh)',
            pb: 2,
          }}
        >
          <CheckList
            items={list.items}
            onCheck={onCheck}
            listId={list.block_rid}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ListBlockCard;
