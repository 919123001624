export const svgGroup = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width ? width : 80}
      height={height ? height : 56}
      viewBox={viewBox ? viewBox : '0 0 80 56'}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M43.077,15.385 C43.077,23.882 36.189,30.77 27.692,30.77 C19.195,30.77 12.307,23.882 12.307,15.385 C12.307,6.888 19.195,0 27.692,0 C36.189,0 43.077,6.888 43.077,15.385 z"
          fill={color}
        />
        <path
          className={className}
          d="M69.288,33.411 L54.654,33.411 C58.847,37.411 62,42.409 62,48.206 L62,51.411 L80,51.411 L80,44.103 C80,38.212 75.18,33.411 69.288,33.411 z"
          fill={color}
        />
        <path
          className={className}
          d="M55,56.411 L55,48.206 C55,41.437 49.846,35.411 43.077,35.411 L12.307,35.411 C5.539,35.411 0,41.437 0,48.206 L0,56.411 L55,56.411 z"
          fill={color}
        />
        <path
          className={className}
          d="M55.897,2.147 C52.419,2.147 49.262,3.483 46.882,5.656 C48.371,8.583 49.23,11.883 49.23,15.385 C49.23,18.971 48.336,22.348 46.781,25.325 C49.173,27.554 52.371,28.927 55.897,28.927 C63.293,28.927 69.288,22.934 69.288,15.537 C69.288,8.142 63.293,2.147 55.897,2.147 z"
          fill={color}
        />
      </g>
    </svg>
  );
};