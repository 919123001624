export function snowshoe (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M30.76447,100c-3.42838,0-6.52935-1.3686-9.21837-4.06843C9.8737,84.20805,8.27405,49.22427,8.35959,29.41402   l0.00197-0.7895C8.36156,20.0177,10.54521,0,30.76447,0c20.27136,0,22.45895,20.0177,22.45895,28.62452l0.00393,0.78852   c0.08456,19.79746-1.51804,54.76355-13.20814,66.50281C37.32134,98.6255,34.20759,100,30.76447,100z M30.76447,3.3684   c-16.56179,0-19.03451,15.82342-19.03451,25.25612l-0.00295,0.80425c-0.14158,32.82077,4.30734,56.19506,12.20529,64.12743   c2.06273,2.07059,4.29653,3.07738,6.83217,3.07738c2.5494,0,4.79599-1.01269,6.86855-3.09311   c7.91072-7.94612,12.36752-31.31452,12.22593-64.11366L49.856,28.62452C49.856,19.19182,47.3764,3.3684,30.76447,3.3684z"
        ></path>
        <path
          className={className}
          d="M19.23462,20.66267c-0.9301,0-1.6842-0.7541-1.6842-1.6842V5.05162c0-0.9301,0.7541-1.6842,1.6842-1.6842   s1.6842,0.7541,1.6842,1.6842v13.92685C20.91883,19.90856,20.16472,20.66267,19.23462,20.66267z"
        ></path>
        <path
          className={className}
          d="M26.93987,20.09144c-0.93009,0-1.6842-0.7541-1.6842-1.6842V1.96932c0-0.9301,0.75411-1.6842,1.6842-1.6842   c0.9301,0,1.6842,0.7541,1.6842,1.6842v16.43791C28.62407,19.33733,27.86996,20.09144,26.93987,20.09144z"
        ></path>
        <path
          className={className}
          d="M34.64609,20.09144c-0.93009,0-1.6842-0.7541-1.6842-1.6842V1.96932c0-0.9301,0.75411-1.6842,1.6842-1.6842   c0.9301,0,1.6842,0.7541,1.6842,1.6842v16.43791C36.33029,19.33733,35.57618,20.09144,34.64609,20.09144z"
        ></path>
        <path
          className={className}
          d="M42.35133,20.66267c-0.9301,0-1.6842-0.7541-1.6842-1.6842V5.05162c0-0.9301,0.7541-1.6842,1.6842-1.6842   s1.6842,0.7541,1.6842,1.6842v13.92685C44.03553,19.90856,43.28143,20.66267,42.35133,20.66267z"
        ></path>
        <path
          className={className}
          d="M15.41003,82.1925c-0.21532,0-0.43457-0.04326-0.64694-0.13174c-0.85832-0.35788-1.26438-1.34303-0.9065-2.20037   l4.2808-10.27235c0.3569-0.85931,1.33812-1.26634,2.20234-0.90649c0.85832,0.35788,1.26438,1.34303,0.9065,2.20037   l-4.2808,10.27234C16.69604,81.8012,16.06974,82.1925,15.41003,82.1925z M33.61768,38.58519   c-0.21532,0-0.43457-0.04129-0.64694-0.12978c-0.85832-0.35788-1.26438-1.34304-0.9065-2.20234l1.42661-3.42444   c0.3569-0.85734,1.34008-1.26634,2.20234-0.9065c0.85832,0.35788,1.26438,1.34303,0.9065,2.20234l-1.42661,3.42444   C34.90368,38.19487,34.27739,38.58519,33.61768,38.58519z"
        ></path>
        <path
          className={className}
          d="M11.01419,57.13696c-0.2163,0-0.43752-0.04326-0.64989-0.13175c-0.85734-0.35985-1.26143-1.34697-0.90158-2.20431   l9.01878-21.51902c0.35788-0.85734,1.34598-1.2634,2.20332-0.90158c0.85734,0.35886,1.26143,1.34598,0.90158,2.20332   l-9.01878,21.51902C12.29824,56.74762,11.67293,57.13696,11.01419,57.13696z"
        ></path>
        <path
          className={className}
          d="M27.28201,89.32848c-0.21433,0-0.4326-0.0413-0.64301-0.12978c-0.8593-0.35591-1.26733-1.3391-0.9124-2.1984   l2.05585-4.96706c0.35395-0.85931,1.34008-1.27225,2.19939-0.9124c0.85931,0.35591,1.26733,1.3391,0.91142,2.19841   l-2.05388,4.96706C28.57097,88.93521,27.9437,89.32848,27.28201,89.32848z M43.49183,50.45718   c-0.21532,0-0.43457-0.04129-0.64694-0.12978c-0.85832-0.35788-1.26438-1.34402-0.9065-2.20234l5.7074-13.69875   c0.35788-0.85734,1.33714-1.26537,2.20234-0.9065c0.85832,0.35788,1.26438,1.34402,0.9065,2.20234L45.04723,49.4209   C44.77784,50.06686,44.15155,50.45718,43.49183,50.45718z"
        ></path>
        <path
          className={className}
          d="M42.35035,88.69924c-0.21433,0-0.4326-0.04326-0.64399-0.12978c-0.85931-0.35592-1.26635-1.34107-0.91043-2.20038   l5.53633-13.35562c0.35493-0.8593,1.33615-1.26437,2.20037-0.91043c0.85931,0.35592,1.26635,1.34107,0.91043,2.20038   l-5.53633,13.35561C43.63832,88.30794,43.01105,88.69924,42.35035,88.69924z"
        ></path>
        <path
          className={className}
          d="M46.17593,82.1925c-0.65972,0-1.28601-0.3913-1.5554-1.03825l-4.2808-10.27234   c-0.35788-0.85734,0.04818-1.84249,0.9065-2.20037c0.86029-0.35985,1.84446,0.04719,2.20234,0.90649l4.2808,10.27235   c0.35788,0.85734-0.04818,1.84249-0.9065,2.20037C46.6105,82.14925,46.39125,82.1925,46.17593,82.1925z M27.96828,38.58519   c-0.65972,0-1.28601-0.39033-1.5554-1.03628l-1.42661-3.42444c-0.35788-0.8593,0.04818-1.84446,0.9065-2.20234   c0.86226-0.35985,1.84544,0.04916,2.20234,0.9065l1.42661,3.42444c0.35788,0.85931-0.04818,1.84446-0.9065,2.20234   C28.40285,38.5439,28.1836,38.58519,27.96828,38.58519z"
        ></path>
        <path
          className={className}
          d="M50.51474,57.13696c-0.65972,0-1.28601-0.39131-1.5554-1.03825l-8.96175-21.51902   c-0.35788-0.85833,0.04818-1.84348,0.90748-2.20136c0.85734-0.35985,1.84446,0.04916,2.20136,0.90748l8.96077,21.51902   c0.35788,0.85734-0.04719,1.84446-0.9065,2.20037C50.9493,57.0937,50.73005,57.13696,50.51474,57.13696z"
        ></path>
        <path
          className={className}
          d="M34.30394,89.32848c-0.66169,0-1.28896-0.39327-1.55737-1.04218l-2.05388-4.96706   c-0.35591-0.85931,0.05211-1.8425,0.91142-2.19841c0.85931-0.35985,1.84643,0.05309,2.19939,0.9124l2.05585,4.96706   c0.35493,0.85931-0.05309,1.84249-0.9124,2.1984C34.73654,89.28719,34.51828,89.32848,34.30394,89.32848z M18.09413,50.45718   c-0.65972,0-1.28601-0.38934-1.5554-1.03628l-5.7074-13.69875c-0.35788-0.85833,0.04818-1.84446,0.9065-2.20234   c0.86127-0.35886,1.84446,0.04916,2.20234,0.9065l5.7074,13.69875c0.35788,0.85832-0.04818,1.84446-0.9065,2.20234   C18.5287,50.41589,18.30945,50.45718,18.09413,50.45718z"
        ></path>
        <path
          className={className}
          d="M19.23561,88.69924c-0.65874,0-1.28404-0.38934-1.55442-1.03431l-5.59335-13.35562   c-0.35886-0.85734,0.04523-1.84446,0.90257-2.20431c0.86226-0.35591,1.84544,0.0472,2.20431,0.90257l5.59335,13.35562   c0.35886,0.85734-0.04523,1.84446-0.90257,2.20431C19.67214,88.65598,19.45191,88.69924,19.23561,88.69924z"
        ></path>
        <path
          className={className}
          d="M30.79298,89.38551c-4.44991,0-8.89981-0.29102-13.31924-0.87307c-0.92125-0.12191-1.57015-0.96745-1.44922-1.88969   c0.12093-0.92223,0.95861-1.57899,1.88969-1.44922c8.54586,1.12477,17.21168,1.12477,25.75754,0   c0.92813-0.13174,1.76679,0.52699,1.88969,1.44922c0.12093,0.92223-0.52797,1.76778-1.44922,1.88969   C39.69279,89.09448,35.24289,89.38551,30.79298,89.38551z"
        ></path>
        <path
          className={className}
          d="M51.54217,37.21463c-0.11602,0-0.234-0.0118-0.35198-0.03736c-13.53456-2.87484-27.25986-2.87484-40.79441,0   c-0.9124,0.19369-1.80415-0.38737-1.99685-1.29683c-0.19369-0.90945,0.38738-1.80415,1.29682-1.99685   c13.99862-2.97513,28.19585-2.97513,42.19447,0c0.91043,0.1927,1.49051,1.08741,1.29781,1.99685   C53.01891,36.67191,52.32085,37.21463,51.54217,37.21463z"
        ></path>
        <path
          className={className}
          d="M50.85787,22.03225c-0.11307,0-0.2281-0.0118-0.34313-0.0354c-13.08623-2.7077-26.35729-2.7077-39.44352,0   c-0.91043,0.18484-1.8012-0.39721-1.98997-1.30764c-0.18877-0.91043,0.39721-1.8012,1.30764-1.98997   c13.53849-2.80012,27.26969-2.80012,40.80818,0c0.91142,0.18877,1.49543,1.07954,1.30862,1.98997   C52.34052,21.48461,51.6395,22.03225,50.85787,22.03225z"
        ></path>
        <path
          className={className}
          d="M30.76447,80.07865c-4.64163,0-8.41903-4.90217-8.41903-10.92911c0-1.29585,0.17402-2.57989,0.51814-3.8187   c0.28512-1.10117,0.15928-2.07452-0.40311-3.04001c-1.43644-2.4796-2.22692-5.61597-2.22692-8.83689   c0-7.98643,4.6259-14.24245,10.53092-14.24245c5.93649,0,10.58794,6.25602,10.58794,14.24245   c0,3.22092-0.79048,6.35729-2.22495,8.83296c-0.56435,0.96942-0.6902,1.94278-0.39721,3.07148   c0.33625,1.21128,0.51027,2.49532,0.51027,3.79117C39.24052,75.17648,35.43755,80.07865,30.76447,80.07865z M30.76447,42.57988   c-3.94946,0-7.16252,4.87759-7.16252,10.87405c0,2.63691,0.62826,5.17353,1.77072,7.14581   c1.00777,1.73041,1.25848,3.61616,0.74329,5.60417c-0.26939,0.97336-0.40212,1.95458-0.40212,2.94563   c0,4.09989,2.31246,7.56268,5.05063,7.56268c2.76866,0,5.10766-3.46278,5.10766-7.56268c0-0.99105-0.13273-1.97227-0.39426-2.9181   c-0.52306-2.01554-0.27234-3.90129,0.73739-5.63564c1.1405-1.96834,1.76876-4.50496,1.76876-7.14187   C37.98401,47.45748,34.74539,42.57988,30.76447,42.57988z"
        ></path>
        <path
          className={className}
          d="M69.17858,100c-8.35513,0-14.49415-7.94219-18.24697-23.61027c-0.21729-0.90257,0.34018-1.813,1.2457-2.0293   c0.90846-0.21236,1.813,0.34215,2.0293,1.24668c3.34284,13.95143,8.37872,21.02645,14.97198,21.02645   c2.55039,0,4.79599-1.01269,6.86855-3.09311c7.91072-7.94415,12.36653-31.31256,12.22495-64.11366l-0.00197-0.80228   c0-7.59414-1.86019-25.25612-19.09154-25.25612c-6.71714,0-11.72942,2.59267-14.89332,7.70622   c-0.49159,0.79048-1.52787,1.03333-2.31737,0.54567c-0.79049-0.48864-1.0353-1.52689-0.54567-2.31737   C55.18782,3.21699,61.32882,0,69.17858,0C83.2421,0,91.63655,10.70101,91.63655,28.62452l0.00394,0.78852   c0.08455,19.79746-1.51804,54.76355-13.20815,66.50281C75.73447,98.62747,72.6217,100,69.17858,100z"
        ></path>
        <path
          className={className}
          d="M57.59074,20.66267c-0.9301,0-1.68322-0.7541-1.68322-1.6842V5.05162c0-0.9301,0.75312-1.6842,1.68322-1.6842   s1.68322,0.7541,1.68322,1.6842v13.92685C59.27395,19.90856,58.52083,20.66267,57.59074,20.66267z"
        ></path>
        <path
          className={className}
          d="M65.29696,20.09144c-0.93009,0-1.68322-0.7541-1.68322-1.6842V1.96932c0-0.9301,0.75312-1.6842,1.68322-1.6842   c0.9301,0,1.68322,0.7541,1.68322,1.6842v16.43791C66.98018,19.33733,66.22706,20.09144,65.29696,20.09144z"
        ></path>
        <path
          className={className}
          d="M73.05824,20.09144c-0.9301,0-1.68322-0.7541-1.68322-1.6842V1.96932c0-0.9301,0.75312-1.6842,1.68322-1.6842   c0.93009,0,1.68321,0.7541,1.68321,1.6842v16.43791C74.74146,19.33733,73.98833,20.09144,73.05824,20.09144z"
        ></path>
        <path
          className={className}
          d="M80.76447,20.66267c-0.93009,0-1.68322-0.7541-1.68322-1.6842V5.05162c0-0.9301,0.75313-1.6842,1.68322-1.6842   s1.68322,0.7541,1.68322,1.6842v13.92685C82.44769,19.90856,81.69456,20.66267,80.76447,20.66267z"
        ></path>
        <path
          className={className}
          d="M53.76614,82.1925c-0.21827,0-0.44047-0.04326-0.6548-0.13371c-0.85734-0.36181-1.25848-1.34893-0.89666-2.20627   l4.33782-10.27235c0.36181-0.85734,1.34893-1.26044,2.20627-0.89667c0.85734,0.36182,1.25848,1.34894,0.89667,2.20628   l-4.33782,10.27234C55.04625,81.80513,54.4229,82.1925,53.76614,82.1925z M72.03179,38.58519   c-0.2163,0-0.43653-0.04129-0.64693-0.12978c-0.85931-0.35788-1.26438-1.34402-0.9065-2.20234l1.42759-3.42444   c0.35788-0.8593,1.3509-1.26339,2.20038-0.9065c0.8593,0.35788,1.26437,1.34402,0.90649,2.20234l-1.42759,3.42444   C73.31583,38.19585,72.69053,38.58519,72.03179,38.58519z"
        ></path>
        <path
          className={className}
          d="M56.50726,40.2399c-0.21827,0-0.4385-0.04228-0.65087-0.13175c-0.85734-0.35886-1.26241-1.34599-0.90257-2.20332   l1.93885-4.62295c0.36181-0.85931,1.35287-1.26044,2.20431-0.90158c0.85734,0.35886,1.26241,1.34598,0.90257,2.20332   l-1.93885,4.62294C57.78934,39.85154,57.16403,40.2399,56.50726,40.2399z"
        ></path>
        <path
          className={className}
          d="M65.63911,89.32848c-0.22023,0-0.4444-0.04326-0.65874-0.13568c-0.85537-0.36378-1.25454-1.35287-0.89076-2.20824   l2.11189-4.96706c0.36378-0.85734,1.3568-1.26044,2.20824-0.89077c0.85538,0.36378,1.25455,1.35287,0.89077,2.20824   l-2.11189,4.96706C66.91528,88.94308,66.29391,89.32848,65.63911,89.32848z M81.90496,50.45718   c-0.2163,0-0.43653-0.04129-0.64693-0.12978c-0.85931-0.35788-1.26438-1.34402-0.9065-2.20234l5.70839-13.69875   c0.35788-0.85734,1.34697-1.26241,2.20037-0.9065c0.85931,0.35788,1.26438,1.34402,0.9065,2.20234L83.4584,49.4209   C83.189,50.06686,82.5637,50.45718,81.90496,50.45718z"
        ></path>
        <path
          className={className}
          d="M80.76447,88.69924c-0.2163,0-0.43457-0.04326-0.64497-0.12978c-0.85931-0.35592-1.26634-1.34107-0.91043-2.20038   l5.53732-13.35562c0.35788-0.86127,1.34696-1.25848,2.20037-0.91043c0.85931,0.35592,1.26635,1.34107,0.91043,2.20038   l-5.53731,13.35561C82.05048,88.30794,81.42517,88.69924,80.76447,88.69924z"
        ></path>
        <path
          className={className}
          d="M84.58907,82.1925c-0.65677,0-1.28011-0.38737-1.55147-1.03038l-4.33783-10.27234   c-0.36181-0.85734,0.03933-1.84446,0.89667-2.20628c0.85931-0.36378,1.84446,0.03933,2.20628,0.89667l4.33782,10.27235   c0.36182,0.85734-0.03933,1.84446-0.89667,2.20627C85.02953,82.14925,84.80733,82.1925,84.58907,82.1925z M66.32341,38.58519   c-0.65874,0-1.28601-0.39033-1.5554-1.03726l-1.42562-3.42444c-0.35592-0.85832,0.04916-1.84446,0.90846-2.20136   c0.85144-0.3569,1.84446,0.04818,2.20234,0.90748l1.42562,3.42444c0.35592,0.85832-0.04916,1.84446-0.90846,2.20136   C66.75994,38.5439,66.53971,38.58519,66.32341,38.58519z"
        ></path>
        <path
          className={className}
          d="M88.92689,57.13696c-0.65874,0-1.28404-0.39131-1.55344-1.03825l-8.96077-21.51902   c-0.35788-0.85833,0.0472-1.84348,0.90649-2.20136c0.84948-0.35592,1.8425,0.04818,2.20038,0.90748l8.96077,21.51902   c0.35788,0.85734-0.0472,1.84446-0.9065,2.20037C89.36343,57.0937,89.14319,57.13696,88.92689,57.13696z"
        ></path>
        <path
          className={className}
          d="M72.71609,89.32848c-0.65481,0-1.27618-0.38541-1.54951-1.02645l-2.11189-4.96706   c-0.36378-0.85537,0.03539-1.84446,0.89077-2.20824c0.8534-0.35985,1.84249,0.03343,2.20824,0.89077l2.11189,4.96706   c0.36378,0.85537-0.03539,1.84446-0.89077,2.20824C73.16049,89.28326,72.93633,89.32848,72.71609,89.32848z M56.50726,50.45718   c-0.65087,0-1.27224-0.38148-1.54754-1.01859l-0.34411-0.79933c-0.36771-0.85439,0.02753-1.84446,0.88094-2.21217   c0.85734-0.36771,1.84446,0.02556,2.21414,0.88094l0.34412,0.79933c0.36575,0.85439-0.02753,1.84446-0.88094,2.21217   C56.9556,50.41294,56.72947,50.45718,56.50726,50.45718z"
        ></path>
        <path
          className={className}
          d="M57.64776,88.69924c-0.65677,0-1.28208-0.38934-1.55344-1.03431L51.18724,75.965   c-0.35985-0.85734,0.04326-1.84446,0.90158-2.20431c0.85144-0.35197,1.84446,0.04327,2.20431,0.90257l4.90807,11.69993   c0.35984,0.85734-0.04523,1.84446-0.90257,2.20431C58.08626,88.65598,57.86603,88.69924,57.64776,88.69924z"
        ></path>
        <path
          className={className}
          d="M69.17662,89.38158c-4.45778,0-8.91751-0.28905-13.34579-0.86914c-0.92223-0.11995-1.57113-0.96549-1.45118-1.88772   c0.11995-0.92027,0.96746-1.57114,1.88772-1.45119c8.56553,1.12083,17.25494,1.12083,25.82047,0   c0.92027-0.13174,1.76777,0.52895,1.88772,1.45119c0.11995,0.92223-0.52895,1.76777-1.45119,1.88772   C78.09609,89.09252,73.63635,89.38158,69.17662,89.38158z"
        ></path>
        <path
          className={className}
          d="M89.89828,37.21463c-0.11602,0-0.23596-0.0118-0.35395-0.03736c-10.79147-2.30262-21.813-2.75489-32.76571-1.34599   c-0.9183,0.117-1.7658-0.53289-1.88379-1.45512c-0.11995-0.92223,0.53289-1.76679,1.45512-1.88575   c11.32632-1.46003,22.73326-0.99204,33.89834,1.39318c0.90846,0.19467,1.48855,1.08937,1.29388,1.99882   C91.37502,36.67289,90.675,37.21463,89.89828,37.21463z"
        ></path>
        <path
          className={className}
          d="M89.21398,22.03225c-0.11405,0-0.2281-0.0118-0.34412-0.0354c-10.72461-2.22299-21.78351-2.60151-32.88369-1.12083   c-0.9065,0.12683-1.76581-0.52404-1.89165-1.44725c-0.12191-0.92125,0.52502-1.76875,1.44725-1.89165   c11.46593-1.53082,22.9122-1.13853,34.01239,1.16213c0.91043,0.18877,1.49445,1.07954,1.30567,1.99095   C90.69662,21.48461,89.99463,22.03225,89.21398,22.03225z"
        ></path>
        <path
          className={className}
          d="M69.17858,80.07865c-4.67407,0-8.47704-4.90217-8.47704-10.92911c0-1.29977,0.17501-2.58382,0.51912-3.82067   c0.33035-1.11297,0.21827-2.07059-0.34608-3.03608c-1.47478-2.55039-2.28493-5.68676-2.28493-8.83886   c0-7.98643,4.65048-14.24245,10.58893-14.24245c5.83817,0,10.58697,6.38974,10.58697,14.24245   c0,3.26418-0.81605,6.41038-2.29673,8.86049c-0.55255,0.94386-0.66463,1.90148-0.34215,2.98889   c0.35198,1.26241,0.52699,2.54646,0.52699,3.84623C77.65366,75.17648,73.85265,80.07865,69.17858,80.07865z M69.17858,42.57988   c-3.9819,0-7.22249,4.87759-7.22249,10.87405c0,2.57005,0.65087,5.10864,1.82873,7.14581   c1.03234,1.76974,1.26045,3.67516,0.6725,5.65923c-0.25563,0.91633-0.38934,1.89755-0.38934,2.89057   c0,4.09989,2.33998,7.56268,5.1106,7.56268c2.76866,0,5.10864-3.46278,5.10864-7.56268c0-0.99302-0.13371-1.97424-0.39721-2.91614   c-0.58008-1.9585-0.35197-3.86393,0.6843-5.63956c1.17982-1.95458,1.82479-4.48137,1.82479-7.13991   C76.39911,47.45748,73.16049,42.57988,69.17858,42.57988z"
        ></path>
      </g>
    </svg>
  );
}