/*
functions for search:
 (optional) - search all > al
 - search blocks > onClick > ?
 - search friends > onClick > go to friend profile
 - search inspiration > onClick > go to inspiration_selected
 - search adventure > onClick > go to selected adventure_selected
 - search share > onClick > ?

*/

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import HubModal from '../../modal';
import HubButton from '../../../hub-button';
import {
  sendDates,
  openAdventureModal,
  openItineraryModal,
  closeModal,
} from 'actions/modal_actions';
import { Button, Title } from '../../modal_modules';
const mapStateToProps = (state) => {
  let content = state.ui.modal.message ? state.ui.modal.message : null;
  let func = state.ui.modal.deleteFunc ? state.ui.modal.deleteFunc : null;
  return {
    content,
    func,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendDates: (data) => dispatch(sendDates(data)),
    openAdventureModal: () => dispatch(openAdventureModal()),
    openItineraryModal: () => dispatch(openItineraryModal()),
    closeModal: () => dispatch(closeModal()),
  };
};
const DeleteModal = ({ closeModal, message, content, func }) => {
  const handleSubmit = (type) => {
    if (type === 2) {
      func(closeModal);
    } else if (type === 1) {
      closeModal();
    }
  };

  return (
    <HubModal
      show={true}
      title="DELETE"
      buttonTitle="Delete"
      onClose={closeModal}
      onApproved={() => handleSubmit(2)}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <div className="delete-modal-message-container">
          {typeof content === 'string' ? (
            content === 'block' ? (
              'Are you sure you want to delete?'
            ) : (
              `Are you sure you want to delete this ${content}?`
            )
          ) : (
            <span>
              {content.content}<br />
              {content.following}
            </span>
          )}
        </div>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, null)(DeleteModal);
