/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  RECEIVE_ACCOUNT_GROUP,
  RECEIVE_ACCOUNT_GROUP_FRIENDS,
  RECEIVE_ADVENTURE_FRIENDS,
  RECEIVE_ADVENTURE_GROUP,
  RECEIVE_ADVENTURE_GROUP_FRIENDS,
  RECEIVE_DELETE_USER_FRIEND,
  RECEIVE_DISPLAY_NOTIFICATION_BADGE,
  RECEIVE_FLUSH_USER_FRIENDS,
  RECEIVE_FLUSH_USER_UNFRIENDS,
  RECEIVE_UNDISPLAY_NOTIFICATION_BADGE,
  RECEIVE_UPDATE_USER_UNFRIENDS,
  RECEIVE_USER_ADVENTURE_INVITES,
  RECEIVE_USER_FRIEND_REQUESTS,
  RECEIVE_USER_FRIEND_UPDATES,
  RECEIVE_USER_FRIENDS,
  RECEIVE_USER_INCOMING_NOTIFICATION,
  RECEIVE_USER_RECENT_BLOCK_UPDATE,
  RECEIVE_USER_UNFRIENDS,
} from '../constants';
import * as SocialApiUtil from '../util/api_util/social_api_util';
const receiveUserFriendRequests = (data) => {
  return {
    type: RECEIVE_USER_FRIEND_REQUESTS,
    data,
  };
};
const receiveFlushUserFriends = () => {
  return {
    type: RECEIVE_FLUSH_USER_FRIENDS,
  };
};
const receiveDeleteUserFriend = (data) => {
  return {
    type: RECEIVE_DELETE_USER_FRIEND,
    data,
  };
};
export const receiveUserFriends = (data) => {
  return {
    type: RECEIVE_USER_FRIENDS,
    data,
  };
};
const receiveUserFriendUpdates = (data) => {
  return {
    type: RECEIVE_USER_FRIEND_UPDATES,
    data,
  };
};
const receiveUserAdventureInvites = (data) => {
  return {
    type: RECEIVE_USER_ADVENTURE_INVITES,
    data,
  };
};

const receiveUserUnfriends = (data, searchType = { isNew: true }) => {
  return {
    type: RECEIVE_USER_UNFRIENDS,
    data,
    searchType,
  };
};

const receiveUpdateUserUnfriends = (data) => {
  return {
    type: RECEIVE_UPDATE_USER_UNFRIENDS,
    data,
  };
};

const receiveDisplayNotificationBadge = (data) => {
  return {
    type: RECEIVE_DISPLAY_NOTIFICATION_BADGE,
    data,
  };
};

const receiveUndisplayNotificationBadge = (data) => {
  return {
    type: RECEIVE_UNDISPLAY_NOTIFICATION_BADGE,
    data,
  };
};

export const receiveUserAccountGroup = (data) => {
  return {
    type: RECEIVE_ACCOUNT_GROUP,
    data,
  };
};

const receiveUserAccountGroupFriends = (data) => {
  return {
    type: RECEIVE_ACCOUNT_GROUP_FRIENDS,
    data,
  };
};

export const receiveAdventureFriends = (data) => {
  return {
    type: RECEIVE_ADVENTURE_FRIENDS,
    data,
  };
};

const receiveAdventureGroup = (data) => {
  return {
    type: RECEIVE_ADVENTURE_GROUP,
    data,
  };
};

const receiveAdventureGroupFriends = (data) => {
  return {
    type: RECEIVE_ADVENTURE_GROUP_FRIENDS,
    data,
  };
};

const receiveUserIncomingNotifications = (data) => {
  return {
    type: RECEIVE_USER_INCOMING_NOTIFICATION,
    data,
  };
};

const receiveUserRecentBlockUpdate = (data) => {
  return {
    type: RECEIVE_USER_RECENT_BLOCK_UPDATE,
    data,
  };
};

const receiveFlushUserUnfriends = () => {
  return {
    type: RECEIVE_FLUSH_USER_UNFRIENDS,
  };
};

export const fetchUserUnfriends = (
  account_rid,
  page_number,
  page_size,
  searchString,
  searchType, //optional object {isNew, isUpdating}
) => (dispatch) => {
  return SocialApiUtil.fetchUnfriends(
    account_rid,
    page_number,
    page_size,
    searchString,
  ).then((res) => {
    dispatch(receiveUserUnfriends(res.data, searchType));
    return res;
  });
};

export const fetchUserFriends = (account_rid, page_number, page_size) => (
  dispatch,
) => {
  return SocialApiUtil.fetchFriends(account_rid, page_number, page_size).then(
    (res) => {
      dispatch(receiveUserFriends(res.data));
      return res;
    },
  );
};

export const fetchUserFriendsForAdventure = (
  account_rid,
  adventure_rid,
  page_number,
  page_size,
) => (dispatch) => {
  return SocialApiUtil.fetchFriendsForAdventure(
    account_rid,
    page_number,
    page_size,
  ).then((res) => {
    dispatch(receiveUserFriends(res.data));
    return res;
  });
};
// origin here will be an object where it can either be from community page or menu drawer
export const upsertUserFriends = (data, userInfo, origin = {}) => (
  dispatch,
) => {
  const { fromNewFriends, fromFriends, fromInvite } = origin;
  return SocialApiUtil.upsertFriends(data).then((res) => {
    if (fromNewFriends) {
      dispatch(receiveUpdateUserUnfriends(origin.friendIndex));
      if (origin.userFriendCount !== 0)
        SocialApiUtil.fetchFriends(
          userInfo.account_rid,
          1,
          origin.userFriendCount + 1,
          origin.searchString,
        ).then((res2) => {
          dispatch(receiveUserFriendUpdates(res2.data, { isNew: true }));
        });
    }
    if (fromInvite) {
      if (data.invite_response_rid === 1) {
        Promise.all([
          SocialApiUtil.fetchUnfriends(
            userInfo.account_rid,
            1,
            origin.userUnfriendCount,
            origin.searchString,
          ),
          SocialApiUtil.fetchUserFriendRequests(userInfo.explorer_rid),
        ]).then((res2) => {
          dispatch(receiveUserUnfriends(res2[0].data, { isNew: true }));
          dispatch(receiveUserFriendRequests(res2[1].data.userFriendRequests));
        });
      } else {
        return SocialApiUtil.fetchUserFriendRequests(
          userInfo.explorer_rid,
        ).then((res2) => {
          dispatch(receiveUserFriendRequests(res2.data.userFriendRequests));
        });
      }
    }
    // return dispatch(receiveUserFriends(res.data))
  });
};

export const acceptUserFriendRequest = (data, userInfo) => (dispatch) => {
  return SocialApiUtil.upsertFriends(data).then(() => {
    return SocialApiUtil.fetchUserIncomingNotification(
      userInfo.explorer_rid,
    ).then((res) => {
      dispatch(receiveUserIncomingNotifications(res.data));
    });
  });
};

// export const ignoreUserFriendRequest = (data, userInfo) => dispatch => {
//   return SocialApiUtil.
// }
export const deleteUserFriend = (
  account_rid,
  friend_rid,
  explorer_rid,
  index,
) => (dispatch) => {
  return SocialApiUtil.deleteFriend(account_rid, friend_rid, explorer_rid).then(
    (res) => {
      return dispatch(receiveDeleteUserFriend(index));
    },
  );
};

export const fetchAccountGroup = (account_rid) => (dispatch) => {
  return SocialApiUtil.fetchAccountGroup(account_rid).then((res) => {
    dispatch(receiveUserAccountGroup(res.data));
    return res;
  });
};

export const upsertAccountGroup = (data) => (dispatch) => {
  return SocialApiUtil.upsertAccountGroup(data).then((res) => {
    return dispatch(receiveUserAccountGroup(res.data));
  });
};

export const deleteAccountGroup = (userInfo, account_group_rid) => (
  dispatch,
) => {
  return SocialApiUtil.deleteAccountGroup(
    userInfo.account_rid,
    account_group_rid,
  ).then((res) => {
    return dispatch(receiveUserAccountGroup(res.data));
  });
};

export const fetchAccountGroupFriends = (account_group_rid) => (dispatch) => {
  return SocialApiUtil.fetchAccountGroupFriends(account_group_rid).then(
    (res) => {
      return dispatch(receiveUserAccountGroupFriends(res.data));
    },
  );
};

export const upsertAccountGroupFriend = (data) => (dispatch) => {
  return SocialApiUtil.upsertAccountGroupFriend(data).then((res) => {
    return dispatch(receiveUserAccountGroupFriends(res.data));
  });
};

export const deleteAccountGroupFriends = (
  account_group_rid,
  account_group_friend_rid,
) => (dispatch) => {
  return SocialApiUtil.deleteAccountGroupFriend(
    account_group_rid,
    account_group_friend_rid,
  ).then((res) => {
    return dispatch(receiveUserAccountGroupFriends(res.data));
  });
};

export const fetchAdventureFriend = (adventure_rid) => (dispatch) => {
  return SocialApiUtil.fetchAdventureFriend(adventure_rid).then((res) => {
    dispatch(receiveAdventureFriends(res.data));
    return res;
  });
};
/*
 * Author       : 	Paul Choi
 * Date Created	: 	14-May-2021
 * Purpose      : upserting friends to  trip
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 * accountGroupData: **object** account group upsert dta
 * accountFriendsList: **array** list of friend_rids from modal,
 * userInfo: **object** userInfo
 */
export const upsertAdventureFriend = (data) => (dispatch) => {
  return SocialApiUtil.upsertAdventureFriend(data).then((res) => {
    return dispatch(receiveAdventureFriends(res.data));
  });
};

export const deleteAdventureFriend = (adventure_friend_rid, adventure_rid) => (
  dispatch,
) => {
  return SocialApiUtil.deleteAdventureFriend(
    adventure_friend_rid,
    adventure_rid,
  )
    .then((res) => {
      return dispatch(receiveAdventureFriends(res.data));
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchAdventureGroup = (adventure_rid) => (dispatch) => {
  return SocialApiUtil.fetchAdventureGroup(adventure_rid).then((res) => {
    return dispatch(receiveAdventureGroup(res.data));
  });
};

export const upsertAdventureGroup = (data) => (dispatch) => {
  return SocialApiUtil.upsertAdventureGroup(data).then((res) => {
    return dispatch(receiveAdventureGroup(res.data));
  });
};

export const deleteAdventureGroup = (adventure_rid, adventure_group_rid) => (
  dispatch,
) => {
  return SocialApiUtil.deleteAdventureGroup(
    adventure_rid,
    adventure_group_rid,
  ).then((res) => {
    return dispatch(receiveAdventureGroup(res.data));
  });
};

export const fetchAdventureGroupFriend = (adventure_group_rid) => (
  dispatch,
) => {
  return SocialApiUtil.fetchAdventureGroupFriend(adventure_group_rid).then(
    (res) => {
      return dispatch(receiveAdventureGroupFriends(res.data));
    },
  );
};

export const upsertAdventureGroupFriend = (data) => (dispatch) => {
  return SocialApiUtil.upsertAdventureGroupFriend(data).then((res) => {
    return dispatch(receiveAdventureGroupFriends(res.data));
  });
};

export const deleteAdventureGroupFriend = (
  adventure_group_rid,
  adventure_group_friend_rid,
) => (dispatch) => {
  return SocialApiUtil.deleteAdventureGroupFriend(
    adventure_group_rid,
    adventure_group_friend_rid,
  ).then((res) => {
    return dispatch(receiveAdventureGroupFriends(res.data));
  });
};

export const fetchUserIncomingNotification = (explorer_rid) => (dispatch) => {
  return SocialApiUtil.fetchUserIncomingNotification(explorer_rid).then(
    (res) => {
      dispatch(receiveUserIncomingNotifications(res.data));
    },
  );
};

export const fetchUserRecentBlockUpdate = (account_rid) => (dispatch) => {
  return SocialApiUtil.fetchUserRecentBlockUpdate(account_rid).then((res) => {
    return dispatch(receiveUserRecentBlockUpdate(res.data));
  });
};
/*
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : post group and add members to it
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 * accountGroupData: **object** account group upsert dta
 * accountFriendsList: **array** list of friend_rids from modal,
 * userInfo: **object** userInfo
 */
export const upsertAccountGroupAndAddMembers = (data) => (dispatch) => {
  return Promise.all([
    SocialApiUtil.postAccountGroupAndAddMembers(data),
    SocialApiUtil.fetchAccountGroup(data.account_rid),
  ]).then((res) => {
    dispatch(receiveUserAccountGroup(res[1].data));
  });
};

/*
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : function fetchAccountGroupMembersAndFriends is used to fetch both member and available friends of a user
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 * userInfo: **object** of userInfo
 *  accountGroupRid: **integer** of account group being fetched
 */
export const fetchAccountGroupMembersAndFriends = (
  userInfo,
  accountGroupRid,
) => (dispatch) => {
  return SocialApiUtil.fetchAccountGroupMembersAndFriends(
    userInfo.account_rid,
    accountGroupRid,
  ).then((res) => {
    return dispatch(receiveUserFriends(res.data));
  });
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	09-Apr-2021
 * Purpose      : fetchAdventureGroupMembersAndFriends is used to fetch both available
 * members and friends when trying to edit a adventure group
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 * userInfo: **object** of userInfo
 *  accountGroupRid: **integer** of account group being fetched
 */

export const fetchAdventureGroupMembersAndFriends = (
  userInfo,
  accountGroupRid,
) => (dispatch) => {
  return SocialApiUtil.fetchAdventureGroupMembersAndFriends(
    userInfo.account_rid,
    accountGroupRid,
  ).then((res) => {
    return dispatch(receiveAdventureFriends(res.data));
  });
};

export const updateNotifications = (data) => (dispatch) => {
  return SocialApiUtil.updateNotificationAsRead(data).then((res) => {
    // debugger;
  });
};

export const deleteNotification = (data, userInfo) => (dispatch) => {
  return SocialApiUtil.deleteNotification(data).then(() => {
    return SocialApiUtil.fetchUserIncomingNotification(
      userInfo.explorer_rid,
    ).then((res) => {
      dispatch(receiveUserIncomingNotifications(res.data));
    });
  });
};

export const triggerNewNotificationBadge = (data) => (dispatch) => {
  return dispatch(receiveDisplayNotificationBadge(data));
};

export const undisplayNotifcationBadge = (data) => (dispatch) => {
  return dispatch(receiveUndisplayNotificationBadge(data));
};

export const queryAllNotificationsAsRead = (explorer_rid) => (dispatch) => {
  return SocialApiUtil.queryAllNotificationsAsRead(explorer_rid).then(() => {
    return SocialApiUtil.fetchUserIncomingNotification(explorer_rid).then(
      (res) => {
        dispatch(receiveUserIncomingNotifications(res.data));
      },
    );
  });
};

export const fetchUserFriendRequest = (explorer_rid) => (dispatch) => {
  return SocialApiUtil.fetchUserFriendRequests(explorer_rid).then((res) => {
    dispatch(receiveUserFriendRequests(res.data.userFriendRequests));
  });
};

export const fetchUserAdventureInvites = (explorer_rid) => (dispatch) => {
  return SocialApiUtil.fetchUserAdventureInvites(explorer_rid).then((res) => {
    dispatch(receiveUserAdventureInvites(res.data.userAdventureInvites));
  });
};

export const flushUserUnfriends = () => (dispatch) => {
  dispatch(receiveFlushUserUnfriends());
};
export const flushUserFriends = () => (dispatch) => {
  return dispatch(receiveFlushUserFriends());
};

// this action is called when user lands on a previously created trip to add user as an attendee to the trip
export const updateAdventureAttendee = (data) => (dispatch) => {
  return SocialApiUtil.updateAdventureAttendee(data).then((res) => {
    dispatch(receiveAdventureFriends(res.data));
    return res;
  });
};
