import React, { useState, useEffect, useCallback, createRef } from 'react';
import {
  Input,
  TextArea,
  Button,
  Title,
  Tab,
  Toggle,
  MemberBlock,
} from '../../modal_modules';
import { mapDispatchToProps, mapStateToProps } from './container';
import { connect } from 'react-redux';
import TripInfo from './_trip_info';
import TravelMethod from './_travel_methods';
import SelectDate from './_select_date';
import StartingPoints from './_starting_points';
const AcceptTripInvite = ({
  closeModal,
  adventureFriendPayload,
  upsertAdventureFriend,
  moveItineraryBlock,
  insertBlockToTripBoardWithTransportation,
  userInfo,
  ...props
}) => {
  const scrollerRef = createRef(null);

  const [inputValues, setInputValues] = useState({});
  const [startDates, setStartDates] = useState({});
  const [bottomText, setBottomText] = useState('');
  const [startingPoint, setStartingPoint] = useState(null);
  const [travelCategoryRid, setTravelCategoryRid] = useState(null);
  const [page, setPage] = useState(1);
  const bottomTexts = {
    1: 'CONTINUE',
    2: 'CONTINUE',
    3: 'CONTINUE',
    4: 'SAVE',
  };

  useEffect(() => {
    let container = document.getElementById('snap-scroll-container');
    function changePageNumber(event) {
      let pageNumber = event.currentTarget.scrollTop / 344 + 1;
      if (Number.isInteger(pageNumber)) {
        setPage(pageNumber);
      }
    }
    container.addEventListener('scroll', changePageNumber);
    return () => container.removeEventListener('scroll', changePageNumber);
  }, []);
  const handleSelectStartingPoint = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.currentTarget;
    if (startingPoint === value) {
      setStartingPoint(null);
    } else {
      setStartingPoint(value);
    }
  });

  const handleSelectTravelMethod = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.currentTarget;
    if (travelCategoryRid === value) {
      setTravelCategoryRid(null);
    } else {
      setTravelCategoryRid(value);
    }
  });

  const handleSelectStartDate = (itineraryRid) => (itineraryItemRid) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      startDates[itineraryRid] &&
      startDates[itineraryRid] === itineraryItemRid
    ) {
      delete startDates[itineraryRid];
      return setStartDates({ ...startDates });
    }
    setStartDates({ ...startDates, [itineraryRid]: itineraryItemRid });
  };
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (page === 1) {
      setPage(2);
      document.getElementById('page-2').scrollIntoView();
    } else if (page === 2) {
      setPage(3);
      document.getElementById('page-3').scrollIntoView();
    } else if (page === 3) {
      setPage(4);
      document.getElementById('page-4').scrollIntoView();
    } else {
      // first upsert adventure friend
      upsertAdventureFriend(adventureFriendPayload).then((res) => {
        let tripboardPayload = {
          block_rid: startingPoint,
          account_rid: userInfo.account_rid,
          block_category_rid: travelCategoryRid,
          explorer_rid: userInfo.explorer_rid,
          copy_to_account_rid: null,
          inspiration_rid: null,
          adventure_rid_exist_flag: null,
          itinerary_item_rid: null,
          parent_block_rid: null,
          adventure_rid: adventureFriendPayload.adventure_rid,
        };
        insertBlockToTripBoardWithTransportation(tripboardPayload).then(
          (res) => {
            let newBlockRid = res.data.returnedBlock[0].block_rid;
            let allItineraryItems = Object.values(startDates);
            let promises = [];
            // allItineraryItems.forEach((itinerary_item_rid) => {
            //   moveItineraryBlock(itinerary_item_rid, res.data[0]);
            // });
            for (let i = 0; i < allItineraryItems.length; i++) {
              let itineraryItemRid = allItineraryItems[i];
              promises.push(
                moveItineraryBlock(
                  itineraryItemRid,
                  newBlockRid,
                  userInfo.account_rid,
                  userInfo.explorer_rid,
                  1,
                  itinerary_rid,
                ),
              );
            }
            Promise.all(promises).then((_) => {
              closeModal();
            });
          },
        );
      });
    }
  });
  return (
    <div className={`mod-type1-wrapper accept-trip-invite-modal`} id="cre-ad">
      <div
        className="mod-type1-padder "
        style={{
          maxHeight: '510px',
        }}
        id="accept-trip-invite-modal"
      >
        <Title closeModal={closeModal} title={`ACCEPT INVITE`} />
        <div className="modal-subtle-line modal-subtle-line--top" />
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="accept-trip-invite--form"
        >
          <section
            ref={scrollerRef}
            id="snap-scroll-container"
            className={`accept-trip-invite-sections-container snap-scroll-container `}
          >
            <section
              id="page-1"
              className="accept-trip-invite-sections accept-trip-invite-sections--page1"
            >
              {/* this section will display information about trip */}
              <TripInfo {...props} />
            </section>

            <section
              id="page-2"
              className="accept-trip-invite-sections accept-trip-invite-sections--page2"
            >
              {/* this section will be used to select starting date */}
              <SelectDate
                startDates={startDates}
                handleSelectItineraryItem={handleSelectStartDate}
                {...props}
              />
            </section>
            <section
              id="page-3"
              className="accept-trip-invite-sections accept-trip-invite-sections--page3"
            >
              {/* this section will be used to select starting points */}
              <StartingPoints
                handleSelectStartingPoint={handleSelectStartingPoint}
                selectedBlock={startingPoint}
                {...props}
              />
            </section>
            <section
              id="page-4"
              className="accept-trip-invite-sections accept-trip-invite-sections--page4"
            >
              {/* this section will be used to select travel methods */}
              <TravelMethod
                selectedCategoryRid={travelCategoryRid}
                clickHandler={handleSelectTravelMethod}
                {...props}
              />
            </section>
          </section>
          <div className="paginator">
            <div
              className={`pagination-bullet ${
                page === 1 ? 'pagination-bullet-active' : ''
              }`}
              onClick={() => {
                setPage(1);
                document.getElementById('page-1').scrollIntoView();
              }}
              data-text="Summary"
            ></div>
            <div
              className={`pagination-bullet ${
                page === 2 ? 'pagination-bullet-active' : ''
              }`}
              onClick={() => {
                setPage(2);
                document.getElementById('page-2').scrollIntoView();
              }}
              data-text={`${!inputValues.adventure_rid ? 'Dates' : 'Picture'}`}
            ></div>
            <div
              className={`pagination-bullet ${
                page === 3 ? 'pagination-bullet-active' : ''
              }`}
              onClick={() => {
                setPage(3);
                document.getElementById('page-3').scrollIntoView();
              }}
              data-text="Picture"
            ></div>
            <div
              className={`pagination-bullet ${
                page === 4 ? 'pagination-bullet-active' : ''
              }`}
              onClick={() => {
                setPage(4);
                document.getElementById('page-4').scrollIntoView();
              }}
              data-text="Picture"
            ></div>
          </div>
          <div className={`accept-trip-invite-button-wrapper `} id="">
            <Button
              spacingTop="5px"
              text={bottomTexts[page]}
              // disabledCondition={inputValues.block_name.length === 0}
            />
          </div>

          <div className="next-message-wrapper">
            <span> &#x2192;</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTripInvite);
