export function train (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 846660 846660"
      fillRule="evenodd"
      clipRule="evenodd"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M216580 176740c11210,-30830 35450,-44990 68520,-47360l152600 0c48750,-1620 76080,31240 82530,70060 34400,193570 95570,242970 219220,250330l80 80220c-7370,4130 -10660,11350 -6730,21720l56190 70 -70 10510 -222870 280c-88140,12820 -158690,54430 -192950,154770l-70270 -140c21020,-62220 60040,-117150 140260,-154910 -155120,2310 -255870,57660 -313110,155050l-72310 -220c26070,-58080 89760,-110200 195480,-155460l-15770 -36640c-112450,-16680 -49450,-272190 -20800,-348280zm68590 -7700l153460 4140c15800,420 15160,24320 -650,23900l-153460 -4140c-15800,-420 -15160,-24320 650,-23900zm-44640 232810l61590 0c5060,0 9190,4130 9190,9190l0 25740c0,5060 -4130,9190 -9190,9190l-61590 0c-5060,0 -9190,-4130 -9190,-9190l0 -25740c0,-5060 4130,-9190 9190,-9190zm151670 920l61590 0c5060,0 9190,4130 9190,9190l0 25740c0,5060 -4130,9180 -9190,9180l-61590 0c-5060,0 -9190,-4120 -9190,-9180l0 -25740c0,-5060 4130,-9190 9190,-9190zm-144980 -160110l222220 9720c13130,570 23410,11780 22830,24920l-2970 67950c-570,13130 -11790,23410 -24920,22830l-222220 -9710c-13130,-570 -23410,-11790 -22840,-24930l2980 -67940c570,-13130 11790,-23410 24920,-22840z"
        ></path>
      </g>
    </svg>
  );
}