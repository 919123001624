import axios from 'axios';
import { ADVENTURES_API } from './constants.js';

async function fetchRecommendedPartners(adventureRid) {
  return await axios.get(
    ADVENTURES_API + `/${adventureRid}/partners/recommended`,
  );
}

export default fetchRecommendedPartners;
