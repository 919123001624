export function roadBike (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 566.9 566.9"
      className={outerLayer}
    >
      <path
        className={className}
        d="M506.4,335.4c0,0-20-74.5-98.5-96.5c-22.7-4.2-37.1-1.6-46.2,2.5c-19.6-63.6-46.8-100-46.8-100s-9-59-77-61s-109,31-109,31  s10-14,0-44c-9,10-72,7.8-70,53c1.8,39.8,37.6,55.2,46.2,58.4c-1.4,21.7,6.2,43.6,13.7,59.4c-5.2,3.1-8.6,8.4-9.9,16.2  c3.5-1.9,7-3.1,10.3-3.9l-44.3,50.4c0,0-26.5,37.7,27.4,40c-8,13.2-11.8,31.4-6.4,56.5c18,84,103,100,103,100s70.5,16,86.5-40  s-30-99-30-99s-27-32.5-69.5-44l-1.5-5.5l119.5,54c0,0,9.5,59,57,42.5l28.5-10.5c0,0,41.5,31,85.5,15.5S506.4,335.4,506.4,335.4z   M267,185.5l18.8,55.4l-59.7,7.4c-0.4-0.3-0.8-0.6-1.2-0.9C205.7,232.5,254.4,194.8,267,185.5z M289.3,251.1l10.6,31.3  c-16.6-11.3-38.8-18.2-55.8-25.1L289.3,251.1z M155.9,194.4c18.7-3.6,30.4-9.4,36.9-13.4c3.7,1.6,6,3.7,6.1,6.4  c-18.4,18-27.1,32.4-30.7,43.6c-4.2,2.7-8.9,4.8-13.7,5.4C147.2,216.5,138.2,197.8,155.9,194.4z M168.6,255.3l-8.4,1  c-0.1-0.4-0.1-0.7-0.2-1.1l7.3-3.1C167.7,253.4,168.1,254.4,168.6,255.3z M73.2,151.2c-7.7-6.7-10-20.7-10-20.7c14.7,6,57-21,57-21  S80.9,157.9,73.2,151.2z M82.9,305.4c9.7-19.7,32.3-31.3,47.5-37.2c-3.3,9.3-14,28.3-48.1,57.1C78.9,321.4,78.1,315.2,82.9,305.4z   M109.3,331.6c-0.2-0.1-0.4-0.2-0.4-0.2s-11.3,1.4-19.9-1.7c25.6-6.3,46.8-28.5,63.4-48.2l9.9,25.4  C162.4,306.9,129.7,309.4,109.3,331.6z M271.9,430.4c-4,101.5-163,35-159.5-43C112.4,265.9,277.4,348.4,271.9,430.4z M171.4,286.9  l-8-18.5l21.3-2.9l124.1,43l15.5,45.6L171.4,286.9z M341.7,361.8l-54.4-156.1c10.9,14.9,25.8,36.6,41.2,63.9  c-5.4,16.7-14.6,64.1,38.4,103.3L341.7,361.8z M340.9,299.4c0,0,19,34.5,27,63.5C337.9,320.4,340.9,299.4,340.9,299.4z M438.9,398.9  c-41-6.8-54-23-54-23s-0.2-81-19.4-121.5c0,0,24.7-16,72.9,12.5s53.5,75.5,53.5,75.5S502.4,401.9,438.9,398.9z"
      ></path>
    </svg>
  );
}