import { createTheme, responsiveFontSizes } from '@mui/material';
import muiButtonStyles from '../components/button';
import muiCardStyles from '../components/card';
import muiLinkStyles from '../components/link';
import muiTypographyStyles from '../components/typography';
import lightPalette from '../palettes/light';

const theme = createTheme({
  palette: lightPalette,
  typography: muiTypographyStyles,
  shape: {
    borderRadius: 15, // Set the global border radius here
  },
  components: {
    ...muiButtonStyles,
    ...muiLinkStyles,
    ...muiCardStyles,
    MuiTooltip: {
      defaultProps: {
        arrow: true, // Set arrow as default
      },
    },
  },
});

const muiTheme = responsiveFontSizes(theme);

export default muiTheme;
