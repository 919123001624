import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const Spinner = styled(CircularProgress)`
  display: block !important;
  color: #827700 !important;
  width: 20px !important;
  height: 20px !important;
  margin: auto;
`;

export default Spinner;