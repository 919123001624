import { Input } from '../../../modal_modules';
import TextBox from '../../../../common/text-box';

const AirportSearchResult = ({ airport, onClickHandler, key }) => {
  const { airport_location_id, airport_name } = airport;
  return (
    <div
      className="airport-search-result-wrapper"
      onClick={(e) => onClickHandler(e, airport)}
      key={key}
    >
      <span>
        <span className="airport-location-id">{airport_location_id}</span> -{' '}
        <span className="airport-name">{airport_name} </span>
      </span>
    </div>
  );
};
const FlightInfo = ({ onChangeHandler, flightInfo, airportClickHandler }) => {
  const toAirportHandler = airportClickHandler('to');
  const fromAirportHandler = airportClickHandler('from');
  return (
    <>
      <button id="replay" style={{ display: 'none' }}>
        Replay
      </button>
      <div className="flights-inputs-wrapper">
        <div className="flight-input-from-wrapper">
          <TextBox
            className="text-field"
            label="Leaving from"
            name="from"
            onChange={onChangeHandler}
            value={flightInfo.from}
            variant="outlined"
            fullWidth
            size="small"
          />
          {flightInfo.displayfromResults && (
            <div className="flight-input-search-results-wrapper">
              {flightInfo.searchResults.length > 0 &&
                flightInfo.searchResults.map((ele, idx) => (
                  <AirportSearchResult
                    key={`from-airport-${idx}`}
                    airport={ele}
                    onClickHandler={fromAirportHandler}
                  />
                ))}
            </div>
          )}
        </div>
        <span className="to-arrow">&#8594; </span>
        <div className="flight-input-to-wrapper">
          <TextBox
            className="text-field"
            label="Going to"
            name="to"
            onChange={onChangeHandler}
            value={flightInfo.to}
            variant="outlined"
            fullWidth
            size="small"
          />
          {flightInfo.displaytoResults && (
            <div className="flight-input-search-results-wrapper">
              {flightInfo.searchResults.length > 0 &&
                flightInfo.searchResults.map((ele, idx) => (
                  <AirportSearchResult
                    key={`to-airport-${idx}`}
                    airport={ele}
                    onClickHandler={toAirportHandler}
                  />
                ))}
            </div>
          )}
        </div>
        {/* <input type="checkbox" /> */}
      </div>
    </>
  );
};
export default FlightInfo;
