import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
  MemberBlock,
  Search,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';

const Contacts = ({ closeModal }) => {
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  });
  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="CONTACTS" />

        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            {/* <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === "LODESTAR"}
                text="LODESTAR"
              />
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === "INVITE"}
                text="INVITE"
              />
            </div> */}
            {/* {topLabel === "LODESTAR" ? renderLodestar() : renderInvite()} */}

            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
