export function scubaDiving (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 512 512"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M352,185.738A17.738,17.738,0,1,0,334.262,168,17.758,17.758,0,0,0,352,185.738Z"
        ></path>
        <path
          className={className}
          d="M400,130.131A18.131,18.131,0,1,0,381.869,112,18.151,18.151,0,0,0,400,130.131Z"
        ></path>
        <path
          className={className}
          d="M304,98.635A18.635,18.635,0,1,0,285.365,80,18.657,18.657,0,0,0,304,98.635Z"
        ></path>
        <rect
          x="261.635"
          y="254.155"
          width="32"
          height="48"
          transform="translate(-93.142 147.863) rotate(-25.692)"
        ></rect>
        <path
          className={className}
          d="M324.085,282.441a24,24,0,1,0-20.81-43.255l-7.209,3.468,20.81,43.255Z"
        ></path>
        <path
          className={className}
          d="M432,232a24,24,0,0,0-48,0v8h48Z"
        ></path>
        <path
          className={className}
          d="M258.609,312.42l-19.621-40.784a24,24,0,0,0,19.621,40.784Z"
        ></path>
        <path
          className={className}
          d="M408,288a23.943,23.943,0,0,1,17.878,8.01,24.059,24.059,0,0,0,4.744-8.01H424a7.994,7.994,0,0,1-3.578-.845L408,280.944l-12.422,6.211A7.994,7.994,0,0,1,392,288h-6.622a24.059,24.059,0,0,0,4.744,8.01A23.943,23.943,0,0,1,408,288Z"
        ></path>
        <path
          className={className}
          d="M89.224,230.06l8.716,2.179a8,8,0,0,1,4.716,3.323l58.832,88.248a21.9,21.9,0,0,0,10.135,8.223l15.022,6.009,14.125-14.125a67.924,67.924,0,0,1,12.357-9.764L191.2,311.96a8,8,0,0,1-5.183-2.645l-64-72A8,8,0,0,1,120,232V216H73.446A22.707,22.707,0,0,0,89.224,230.06Z"
        ></path>
        <path
          className={className}
          d="M376,256v16h14.111l14.311-7.155a8,8,0,0,1,7.156,0L425.889,272H440V256Z"
        ></path>
        <path
          className={className}
          d="M431.056,408l-2.634-5.267-8.844,4.422a8,8,0,0,1-8.576-.908l-40-32A8,8,0,0,1,368,368V332a12.013,12.013,0,0,0-9.861-11.8l.034-1.718a22.088,22.088,0,0,0,8.05,1.516h30.061a14.2,14.2,0,1,0,0-16H366.223A6.231,6.231,0,0,1,360,297.777V264a8,8,0,0,0-16,0v22.919a39.883,39.883,0,0,1-12.979,9.94l-57.672,27.746a39.766,39.766,0,0,1-17.278,3.952,40.261,40.261,0,0,1-21.8-6.453,52.1,52.1,0,0,0-22.191,13.126l-30.426,30.427A8,8,0,0,1,174.2,367.8L73.939,344.658,43.5,368.652a20.865,20.865,0,0,0,24.063.692,7.991,7.991,0,0,1,6.237-1.139l95.775,22.1a21.994,21.994,0,0,0,11.9-.566l87.992-29.33A8,8,0,0,1,272,360a176.047,176.047,0,0,0,56-9.12l16-6.328v35.331l52.65,37.607a8,8,0,0,1,2.006,2.072l12.873,19.31,16.856-4.214-3.54-7.08a8,8,0,0,1,0-7.156Z"
        ></path>
      </g>
    </svg>
  );
}