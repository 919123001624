// import {
//   SEARCH_CHATROOM,
//   CHAT_ROOM_SELECTED,
//   SEND_MESSAGE,
//   CHAT_ROOM_LIST,
//   USER_LIST,
//   CHAT_SERVER_LOGIN,
//   LOGGED_IN_USER,
//   NEW_USER,
//   NEW_CHAT_ROOM,
//   NEW_MESSAGE,
//   CHAT_PERSON_TYPE_USER,
//   CHAT_PERSON_TYPE_CHAT_ROOM,
//   CURRENT_CHAT_ROOM_LIST,
// } from "../constants";
import {
  RECEIVE_CHATROOM_DETAILS,
} from "../constants";

export const receiveChatroomDetails = (receivingUser, sendingUser) => {
  return {
    type: RECEIVE_CHATROOM_DETAILS,
    receivingUser,
    sendingUser,
  };
};
// import socket from "../components/socket";

// export const searchChatRoom = (search) => {
//   return {
//     type: SEARCH_CHATROOM,
//     payload: search,
//   };
// };

// export const selectChatRoom = (chatRoom) => {
//   return {
//     type: CHAT_ROOM_SELECTED,
//     payload: chatRoom,
//   };
// };

// export const selectChatRoomDispatcher = (chatRoom) => {
//   return (dispatch) => {
//     dispatch(selectChatRoom(chatRoom));
//   };
// };

// export const sendChatMessage = (message) => {
//   return {
//     type: SEND_MESSAGE,
//     payload: message,
//   };
// };
// //chatType USER/CHAT_ROOM
// export const sendChatMessageAction = (
//   message,
//   fromId,
//   toId,
//   recepientType,
//   fromName,
//   toName
// ) => {
//   //console.log('Sending Message : ', message);
//   return (dispatch) => {
//     socket.emit("message", {
//       message,
//       fromId,
//       toId,
//       recepientType,
//       fromName,
//       toName,
//     });
//   };
// };

// /* Used only by actions for sockets */
// export const usersListAction = (res) => ({
//   type: USER_LIST,
//   payload: res,
// });

// export const loginUserAction = (res) => ({
//   type: LOGGED_IN_USER,
//   payload: res,
// });
// export const newUserAction = (res) => ({
//   type: NEW_USER,
//   payload: res,
// });

// export const newChatRoomAction = (res) => ({
//   type: NEW_CHAT_ROOM,
//   payload: res,
// });

// export const newMessageAction = (res) => {
//   return (dispatch, getState) => {
//     const { loggedInUser, activeChatRoom } = getState();
//     if (
//       CHAT_PERSON_TYPE_CHAT_ROOM == res.recepientType ||
//       (CHAT_PERSON_TYPE_USER == res.recepientType &&
//         (loggedInUser.get("id") == res.toId ||
//           loggedInUser.get("id") == res.fromId))
//     )
//       dispatch({
//         type: NEW_MESSAGE,
//         payload: res,
//         activeChatRoom,
//       });
//   };
// };
// export const createChatRoom = (socket, chatRoomName) => {
//   return (dispatch) => {
//     socket.emit("newChatRoom", chatRoomName);
//   };
// };

// export const loginToChatServerAction = (res) => ({
//   type: CHAT_SERVER_LOGIN,
//   payload: res,
// });

// export const loadCurrentChatRoomList = (socket) => {
//   return (dispatch) => {
//     socket.on("chatRoomList", (res) => {
//       let { currentChatRoomListAction } = this.props;
//       dispatch(currentChatRoomListAction(res));
//     });
//   };
// };

// export const loginToChatServer = (socket, name) => {
//   return (dispatch) => {
//     socket.emit("newUser", { name });
//   };
// };

// export const logOffFromChatServer = (socket) => {
//   return (dispatch) => {
//     socket.emit("disconnect");
//   };
// };
