export function rafting2 (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="92.809"
      height="92.794"
      viewBox="0, 0, 92.809, 92.794"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M8.792,89.786 L8.284,90.293 C6.69,91.888 4.111,91.888 2.516,90.293 C0.922,88.699 0.922,86.12 2.516,84.511 L3.009,84.018 C3.806,85.12 4.705,86.163 5.676,87.134 C6.69,88.163 7.734,89.062 8.792,89.786 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M90.313,8.279 L89.821,8.772 C88.966,7.612 88.082,6.583 87.154,5.67 C86.154,4.67 85.111,3.772 84.038,3.004 L84.531,2.511 C86.14,0.902 88.719,0.902 90.313,2.511 C91.908,4.105 91.908,6.685 90.313,8.279 z"
            fill="#000000"
          />
          <g>
            <path
              className={className}
              d="M48.584,21.937 L48.572,21.949 L48.567,21.954 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M48.598,21.924 C48.596,21.925 48.594,21.927 48.592,21.929 C48.594,21.927 48.596,21.925 48.598,21.924 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M48.682,21.839 C48.678,21.843 48.674,21.847 48.67,21.851 C48.677,21.844 48.684,21.837 48.692,21.829 C48.689,21.832 48.686,21.835 48.682,21.839 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M14.942,77.867 C19.332,82.257 26.235,83.051 31.478,79.937 L12.873,61.336 C9.756,66.593 10.556,73.482 14.942,77.867 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M48.696,21.826 C48.695,21.827 48.693,21.828 48.692,21.829 C48.693,21.828 48.695,21.827 48.696,21.826 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M48.592,21.929 L48.59,21.931 L48.589,21.932 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M61.336,12.864 L79.958,31.483 C83.072,26.224 82.275,19.316 77.888,14.93 C73.537,10.579 66.651,9.72 61.336,12.864 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M14.942,58.648 C14.663,58.926 14.412,59.204 14.177,59.484 C14.165,59.499 14.154,59.514 14.142,59.529 L33.282,78.666 C33.295,78.655 33.309,78.646 33.323,78.635 C33.604,78.399 33.883,78.146 34.165,77.866 L40.844,71.187 L21.623,51.969 L14.942,58.648 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M85.618,7.207 C76.009,-2.402 60.531,-2.402 50.937,7.207 L31.371,26.757 L37.56,32.946 C42.922,27.583 48.893,21.627 57.125,13.395 C63.328,7.192 73.415,7.38 79.429,13.395 C85.415,19.38 85.647,29.453 79.429,35.685 C71.574,43.54 65.139,49.975 59.879,55.25 L66.067,61.438 L85.618,41.888 C95.4,32.076 95.009,16.467 85.618,7.207 z M55.255,59.859 C33.284,81.815 36.328,78.772 35.705,79.409 C29.444,85.656 19.386,85.395 13.4,79.409 C7.299,73.308 7.284,63.221 13.4,57.105 C21.4,49.105 27.111,43.395 32.951,37.554 L26.763,31.366 L7.212,50.917 C-2.353,60.467 -2.455,75.931 7.212,85.598 C16.719,95.105 32.212,95.279 41.893,85.598 L61.458,66.047 L55.255,59.859 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M69.661,42.38 L58.342,53.714 L54.284,49.656 C55.531,48.337 55.444,46.293 54.212,45.12 C53.009,43.917 50.98,43.786 49.603,45.12 C49.299,45.424 45.313,49.424 45.168,49.569 C43.821,50.859 43.893,53.018 45.082,54.149 C46.255,55.424 48.342,55.54 49.676,54.279 L53.719,58.323 L42.386,69.656 L23.154,50.438 L34.502,39.105 L38.531,43.134 C37.342,44.409 37.371,46.438 38.603,47.67 C39.966,49.033 42.111,48.859 43.212,47.67 L47.719,43.178 C48.951,41.902 48.937,39.844 47.719,38.627 C46.458,37.322 44.386,37.322 43.14,38.525 L39.111,34.496 L50.444,23.163 L69.661,42.38 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M58.667,14.93 L51.975,21.62 L71.197,40.838 C78.229,33.781 77.951,34.145 78.681,33.279 L59.561,14.164 C59.262,14.415 58.948,14.649 58.667,14.93 z"
              fill="#000000"
            />
          </g>
          <g>
            <path
              className={className}
              d="M91.082,82.714 C91.168,84.004 90.69,85.279 89.763,86.192 L86.212,89.743 C85.357,90.598 84.212,91.076 83.024,91.076 C82.922,91.076 82.835,91.062 82.734,91.062 C81.444,90.975 80.241,90.337 79.458,89.322 L72.574,80.424 C71.792,79.424 71.415,78.206 71.415,77.004 C71.415,75.902 71.734,74.815 72.357,73.873 L49.676,51.192 L48.212,52.67 C47.763,53.12 47.049,53.07 46.676,52.67 C46.241,52.25 46.241,51.554 46.676,51.134 L48.864,48.931 L48.937,48.873 L51.14,46.656 C51.574,46.235 52.255,46.235 52.676,46.656 C53.111,47.076 53.111,47.772 52.676,48.192 L51.212,49.656 L73.893,72.337 C75.864,71.033 78.502,71.076 80.444,72.554 L89.342,79.438 C90.357,80.221 90.995,81.424 91.082,82.714 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M46.154,41.67 L43.908,43.902 L41.676,46.134 C41.305,46.532 40.591,46.586 40.139,46.134 C39.719,45.714 39.719,45.033 40.139,44.598 L41.603,43.134 L18.937,20.467 C17.995,21.091 16.908,21.409 15.806,21.409 C14.603,21.409 13.386,21.018 12.371,20.235 L3.487,13.366 C2.458,12.569 1.821,11.38 1.748,10.091 C1.661,8.786 2.14,7.525 3.053,6.612 L6.618,3.047 C7.531,2.134 8.806,1.641 10.096,1.743 C11.386,1.815 12.574,2.453 13.371,3.482 L20.241,12.366 C21.734,14.308 21.777,16.946 20.473,18.931 L43.139,41.598 L44.618,40.134 C45.038,39.699 45.734,39.699 46.154,40.134 C46.574,40.554 46.574,41.235 46.154,41.67 z"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}