import React from 'react'

const SearchModule = ({
  handleSearch,
  triggerOnUpdate,
  triggerOnSubmit
}) => {
  
  return (
    <section className="search-bar-wrapper">
    <div className="search-bar-container">
      <input type="text" className="search-bar-input" placeholder="SEARCH"/>
      <button type="submit" className="search-bar-button" >GO </button>
    </div>
  </section>
  )
}

export default SearchModule