
export const svgTree = ({ color, className, outerLayer, size }) => {
  return (
    <svg
      version="1.1"
      viewBox="0, 0, 64.703, 90"
      width={size}
      height={size}
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M32.443,0 C32.411,-0.004 32.375,0.03 32.343,0.039 C32.316,0.033 32.29,0.002 32.263,0.006 L32.263,0.072 C31.021,0.873 29.487,11.201 27.051,15.975 C22.161,25.558 6.68,29.858 7.007,30.112 C7.362,31.077 18.373,33.126 25.672,30.826 C22.442,37.641 2.538,45.714 2.686,46.416 C3.033,48.06 17.026,47.064 25.246,42.671 C20.129,51.313 2.248,61.843 2.503,62.261 C3.428,63.778 16.87,62.335 25.797,55.736 C17.368,66.627 -0.379,74.595 0.006,75.127 C1.082,76.613 17.261,77.12 27.491,68.841 C27.474,75.784 27.648,82.727 24.85,89.671 C27.321,89.612 29.792,89.723 32.263,89.981 L32.263,90 C32.305,89.995 32.345,89.996 32.388,89.992 C32.405,89.993 32.425,89.992 32.443,89.994 L32.443,89.986 C34.914,89.729 37.382,89.617 39.853,89.676 C37.055,82.733 37.229,75.789 37.212,68.846 C47.442,77.125 63.622,76.619 64.697,75.132 C65.082,74.601 47.338,66.633 38.909,55.742 C47.835,62.341 61.275,63.783 62.2,62.266 C62.455,61.848 44.574,51.318 39.457,42.676 C47.677,47.07 61.67,48.065 62.017,46.421 C62.166,45.72 42.261,37.647 39.031,30.831 C46.33,33.131 57.341,31.083 57.696,30.117 C58.023,29.864 42.545,25.563 37.655,15.981 C35.219,11.206 33.684,0.878 32.443,0.078 z"
          fill={color}
        />
      </g>
    </svg>
  );
};
