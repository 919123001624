export const EmailIcon = ({ color = "#273806", className, outerLayer, width = 25, height = 25 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 14.75L22.75 5.36111C20.4722 2.13889 16.75 0.0277778 12.5 0C8.25004 0 4.47226 2.13884 2.22226 5.41667L12.5 14.75Z" fill={color} />
      <path d="M23.6112 18.2222C24.5 16.5278 25 14.6111 25 12.5833C25 10.5 24.5 8.5 23.5834 6.75L17.3612 12.5L23.6112 18.2222Z" fill={color} />
      <path d="M7.63891 12.5002L1.36113 6.8335C0.500046 8.52792 0 10.4168 0 12.4168C0 14.4724 0.472176 16.4446 1.36113 18.1946L7.63891 12.5002Z" fill={color} />
      <path
        d="M16.1667 13.5835L13.0278 16.4168C12.8612 16.5557 12.6667 16.6391 12.4723 16.6391C12.2779 16.6391 12.0833 16.5557 11.9167 16.4168L8.83337 13.5835L2.22226 19.6113C2.63893 20.1946 3.1111 20.7501 3.61115 21.2779C5.94448 23.6668 9.08337 24.9724 12.4167 25.0002H12.5001C16.7501 25.0002 20.5001 22.8613 22.7501 19.6113L16.1667 13.5835Z"
        fill={color}
      />
    </svg>
  )
};
