import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import { svgCopy } from 'components/svg_module';
import useMaskingHooks from '../../../util/custom_hooks/maskUrl';
const mapStateToProps = ({ session /*, entities, ui */}) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;

  return { userInfo };
};

const ShareViaQr = ({ userInfo }) => {
  const { maskURL } = useMaskingHooks();
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const [ambassadorCode, setAmbassadorCode] = useState(null);
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
    axios
      .get(`/api/e/user-ambassador-code/${userInfo.explorer_rid}`)
      .then(({ status, data }) => {
        if (status === 200) {
          setAmbassadorCode(data.ambassadorCode);
        }
      });
  }, []);
  const copyLinkHandler = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_RUN_MODE === 'PROD'
          ? process.env.REACT_APP_PROD_HUB_URL
          : process.env.REACT_APP_RUN_MODE === 'TEST'
          ? process.env.REACT_APP_TEST_HUB_URL
          : process.env.REACT_APP_DEV_HUB_URL
      }/shared/sign-up/ambassador-code/ambCode:${maskURL(ambassadorCode)}`,
    );
  });

  const copyLinkHandlerFree = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(
      `https://app.lodestarhub.com/sign-up/freetrial`,
    );
  });
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile "
        id="hub-page-container-account-responsive"
      >
        {ambassadorCode && (
          <div
            className={`account-page-container  qr-code-page-container`}
            id="account-page-container-responsive"
            style={{ touchAction: 'none' }}
          >
            <QRCode
              value={`${
                process.env.REACT_APP_RUN_MODE === 'PROD'
                  ? process.env.REACT_APP_PROD_PUB_URL
                  : process.env.REACT_APP_RUN_MODE === 'TEST'
                  ? process.env.REACT_APP_TEST_PUB_URL
                  : process.env.REACT_APP_DEV_PUB_URL
              }/`}
              size={isMobile ? 200 : 512}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'L'}
              includeMargin={false}
              renderAs={'png'}
              // imageSettings={{
              //   src: '/hub_svg/lodestar_logo.png',
              //   x: null,
              //   y: null,
              //   height: 24,
              //   width: 24,
              //   excavate: true,
              // }}
            />
            <h4>
              FREE TRIAL QR CODE
              <br />
              <br />
              Share the link below with anyone you want to sign up:
              <br />
              <br />
              <p
                className="sharvia-qr-copy-link-text"
                onClick={copyLinkHandler}
              >
                {process.env.REACT_APP_RUN_MODE === 'PROD'
                  ? process.env.REACT_APP_PROD_PUB_URL
                  : process.env.REACT_APP_RUN_MODE === 'TEST'
                  ? process.env.REACT_APP_TEST_PUB_URL
                  : process.env.REACT_APP_DEV_PUB_URL}
                /
              </p>{' '}
              <button
                className="share-via-qr-copy-button"
                onClick={copyLinkHandlerFree}
              >
                {svgCopy('', 'copy-link-svg', 'copy-link-svg-outer')}
              </button>
              <br />
              <br />
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ShareViaQr);
