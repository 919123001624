export function electricVehicle (color, className, outerLayer) {
  return (
    <svg
      width="1200pt"
      height="1200pt"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path
        className={className}
        fill={color}
        d="m103.85 601.67v42.789c-63.496-82.191-27.398-220.55 43.98-286.23 87.195-80.277 200.62-94.336 314.36-94.621 6.918 27.949 32.258 48.812 62.262 48.812h79.469v-14.203h60.465c19.715 0 19.715-30.883 0-30.883h-60.465v-43.414h60.465c19.715 0 19.715-30.883 0-30.883h-60.465v-14.203h-79.469c-28.73 0-53.207 19.133-61.285 45.297-125 0.19922-246.42 16.91-342.12 105-95.598 88.016-131.81 295.34-17.207 374.01v88.398c0 51.988 29.652 81.059 73.375 89.617 6.6602-79.145 73.035-141.31 153.89-141.31 81.793 0 148.73 63.582 154.11 144.03h326.21c5.3711-80.449 72.312-144.03 154.11-144.03 79.965 0 145.73 60.773 153.65 138.66 25.727-9.625 48.445-31.039 48.445-54.965v-144.07c0-121.38-118.4-127.84-212.47-138.29l-148.54-164.31c-34.043-37.672-38.352-32.203-79.387-32.441l-427.94-2.5938c-37.402-0.22656-39.77 9.1836-58.523 36.977l-117.78 174.52c-15.363 22.777-19.164 21.004-19.164 48.344zm861.7 165.79c70.043 0 126.85 56.793 126.85 126.85 0 70.043-56.809 126.85-126.85 126.85-70.059 0-126.87-56.809-126.87-126.85 0-70.059 56.809-126.85 126.87-126.85zm-634.43 0c70.059 0 126.85 56.793 126.85 126.85 0 70.043-56.793 126.85-126.85 126.85-70.043 0-126.85-56.809-126.85-126.85 0-70.059 56.809-126.85 126.85-126.85zm526.5-216.13-113.37-128.05c-25.965-29.367-29.27-25.086-60.578-25.301l-326.61-2.0117c-28.516-0.18359-30.344 7.1562-44.645 28.812l-83.637 126.55h628.84z"
        fillRule="evenodd"
      />
    </svg>
  );
}