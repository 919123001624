import { PARTNERS, SUMMARIES } from '@features/query-keys';
import { useQuery } from '@tanstack/react-query';
import fetchPartners from './api/fetch-partners';

function usePartners() {
  const { data, isLoading, isError } = useQuery({
    queryKey: [PARTNERS, SUMMARIES],
    queryFn: () => fetchPartners().then((res) => res.data),
  });

  return { data, isLoading, isError };
}

export default usePartners;
