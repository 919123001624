import * as InspirationApiUtil from '../util/api_util/inspiration_api_util'
import {
  RECEIVE_ADDED_INSPIRATION,
  RECEIVE_USER_INSPIRATIONS,
  RECEIVE_CHANGED_TREE
} from '../constants'

const receiveAddedInspiration = data => {
  return {
    type: RECEIVE_ADDED_INSPIRATION,
    data
  }
}

const receiveUserInspirations = data => {
  return { 
    type: RECEIVE_USER_INSPIRATIONS,
    data
  }
}

const receiveChangedtree = data => {
  return {
    type: RECEIVE_CHANGED_TREE,
    data
  }
}

export const postInspiration = (accountRid, data) => dispatch => {
  return InspirationApiUtil.postInspiration(accountRid, data).then(data => {
    return dispatch(receiveUserInspirations(data.data))
    }
  )
}

export const fetchUserInspirations =  (account_rid) => dispatch => {
  return InspirationApiUtil.fetchUserInspirations(account_rid).then(data => {
    return dispatch(receiveUserInspirations(data.data))
  })
}

export const pushChangedTree = (data) => dispatch => {
  return dispatch(receiveChangedtree(data))
}

export const deleteInspiration = (account_rid, inspiration_rid) => dispatch => {
  return InspirationApiUtil.deleteInspiration(account_rid, inspiration_rid).then(res => {
    return dispatch(receiveUserInspirations(res.data))
  })

}