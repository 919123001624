export function lodging (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
      version="1.1"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          className={className}
          d="M10.2554,33.1869 C10.2554,30.9219 12.1024,29.0759 14.3664,29.0759 C16.6304,29.0759 18.4774,30.9219 18.4774,33.1869 C18.4774,35.4509 16.6304,37.2969 14.3664,37.2969 C12.1024,37.2969 10.2554,35.4509 10.2554,33.1869 Z M8.3047,51.9301 L3.1487,51.9301 L3.1487,22.6651 L8.3047,22.6651 L8.3047,39.1101 L62.7927,39.1101 L62.7927,29.6331 L67.9487,29.6331 L67.9487,51.9301 L62.7927,51.9301 L62.7927,46.2161 L8.3047,46.2161 L8.3047,51.9301 Z M20.707,37.2973 C21.029,33.1613 22.654,30.0013 28.929,30.3303 C36.686,30.2833 44.444,30.2363 52.201,30.1913 C58.486,30.2283 59.874,33.5293 60.563,37.2973 L20.707,37.2973 Z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
}