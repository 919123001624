/**
 * ensure that the protocol is set to https when externally navigating
 * @param {*} url
 * @returns
 */
export const ensureProtocol = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};
