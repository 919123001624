export const svgPrint = (color, className, outerLayer) => {
  return (
    <svg
      width="700pt"
      height="700pt"
      version="1.1"
      viewBox="0 0 700 700"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="m87.5 472.5h105v70c0 9.6602 7.8398 17.5 17.5 17.5h280c9.6602 0 17.5-7.8398 17.5-17.5v-70h105c9.6602 0 17.5-7.8398 17.5-17.5v-280c0-9.6602-7.8398-17.5-17.5-17.5h-105v-140c0-9.6602-7.8398-17.5-17.5-17.5h-280c-9.6602 0-17.5 7.8398-17.5 17.5v140h-105c-9.6602 0-17.5 7.8398-17.5 17.5v280c0 9.6602 7.8398 17.5 17.5 17.5zm385 52.5h-245v-175h245zm-245-490h245v122.5h-245zm-122.5 157.5h490v245h-87.5v-105c0-9.6602-7.8398-17.5-17.5-17.5h-280c-9.6602 0-17.5 7.8398-17.5 17.5v105h-87.5z"
        />
        <path
          className={className}
          d="m169.84 223.91c-6.4766-6.5625-18.199-6.5625-24.676 0-3.3242 3.2344-5.1602 7.6992-5.1602 12.336 0 4.5508 1.8359 9.1016 5.1641 12.426 3.2344 3.2383 7.6992 5.0742 12.336 5.0742s9.1016-1.8359 12.426-5.1641c3.2383-3.2344 5.0742-7.7852 5.0742-12.336 0-4.6367-1.8359-9.1016-5.1641-12.336z"
        />
        <path
          className={className}
          d="m210 253.75c4.6367 0 9.1016-1.8359 12.336-5.1641 3.3281-3.2344 5.1641-7.7852 5.1641-12.336s-1.8359-9.1016-5.1641-12.426c-6.4766-6.4766-18.199-6.4766-24.676 0.085937-3.3242 3.2383-5.1602 7.7031-5.1602 12.34 0 4.5508 1.8359 9.1016 5.1641 12.426 3.2344 3.2383 7.6992 5.0742 12.336 5.0742z"
        />
        <path
          className={className}
          d="m437.5 385h-175c-9.6602 0-17.5 7.8398-17.5 17.5s7.8398 17.5 17.5 17.5h175c9.6602 0 17.5-7.8398 17.5-17.5s-7.8398-17.5-17.5-17.5z"
        />
        <path
          className={className}
          d="m437.5 455h-175c-9.6602 0-17.5 7.8398-17.5 17.5s7.8398 17.5 17.5 17.5h175c9.6602 0 17.5-7.8398 17.5-17.5s-7.8398-17.5-17.5-17.5z"
        />
      </g>
    </svg>
  );
};