import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/root_reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
// import socketMiddleware from "./socketMiddleWare";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import * as reduxLoop from "redux-loop-symbol-ponyfill";
// import promiseMiddleware from "redux-promise";
// let socket = io(WS_BASE);
// let socketIoMiddleware = createSocketIoMiddleware(socket, "Socket/");

// import { routerMiddleware } from "react-router-redux";
// import createHistory from "history/createBrowserHistory";

// export const history = createHistory();

// const loggerMiddleware = createLogger({});

// // Build the middleware for intercepting and dispatching navigation actions
// const myRouterMiddleware = routerMiddleware(history);

// const enhancers = [
//   reduxLoop.install(),
//   applyMiddleware(
//     socketIoMiddleware,
//     myRouterMiddleware,
//     promiseMiddleware,
//     thunkMiddleware,
//     loggerMiddleware
//   ),
// ];
// const persistConfig = {
//   key: 'root',
//   storage: localforage,
//   whitelist: ['session'],
//   blacklist: ['ui'],
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const configureStore =
//   process.env.NODE_ENV !== 'development'
//     ? (preloadedState) => {
//         let store = createStore(
//           persistedReducer,
//           preloadedState,
//           applyMiddleware(
//             thunk,
//             // socketIoMiddleware

//             // socketMiddleware()
//           ),
//         );
//         let persistor = persistStore(store);
//         return { store, persistor };
//       }
//     : (preloadedState) => {
//         let store = createStore(
//           persistedReducer,
//           preloadedState,
//           applyMiddleware(
//             thunk,
//             logger,
//             // socketIoMiddleware

//             // socketMiddleware()
//           ),
//         );
//         let persistor = persistStore(store);
//         return { store, persistor };
//       };

// export default configureStore;

const configureStore =
  process.env.NODE_ENV !== 'development'
    ? (preloadedState = {}) =>
        createStore(
          rootReducer,
          preloadedState,
          applyMiddleware(
            thunk,
            // socketIoMiddleware

            // socketMiddleware()
          ),
        )
    : (preloadedState = {}) =>
        createStore(
          rootReducer,
          preloadedState,
          composeWithDevTools(
            applyMiddleware(
              thunk,
              logger,
              // socketIoMiddleware

              // socketMiddleware()
            ),
          ),
        );

export default configureStore;
