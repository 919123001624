import React, { useState, useCallback, useRef } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  sendDates,
  openAdventureModal,
  openItineraryModal,
  openItineraryItemModal,
  closeModal,
} from 'actions/modal_actions';
import { Title, Button } from '../../modal_modules';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
const mapDispatchToProps = (dispatch) => {
  return {
    sendDates: (data) => dispatch(sendDates(data)),
    openAdventureModal: () => dispatch(openAdventureModal()),
    openItineraryModal: (data, openType, actionPayload) =>
      dispatch(openItineraryModal(data, openType, actionPayload)),
    openItineraryItemModal: (openType, actionPayload) =>
      dispatch(openItineraryItemModal(openType, actionPayload)),
    closeModal: () => dispatch(closeModal()),
  };
};

const mapStateToProps = (state) => {
  let origin = state.ui.modal.past_reducer ? state.ui.modal.past_reducer : null;
  let defaultDates = state.ui.modal.defaultDates
    ? state.ui.modal.defaultDates
    : null;
  return {
    origin,
    defaultDates,
  };
};
const DatePicker = ({
  sendDates,
  openAdventureModal,
  openItineraryModal,
  openItineraryItemModal,
  origin,
  closeModal,
  defaultDates,
}) => {
  const [date, setDate] = useState([
    {
      startDate: defaultDates ? new Date(defaultDates) : new Date(),
      endDate: defaultDates ? new Date(defaultDates) : new Date(),
      key: 'selection',
    },
  ]);
  let dateRef = useRef(null);
  dateRef.current = date;
  const handleSelect = useCallback((item) => {
    if (origin === 'ITINERARY-EDIT') {
      let prevDate = dateRef.current;
      if (item.selection.startDate === item.selection.endDate) {
        dateRef.current = [item.selection];
        setDate([item.selection]);
      } else {
        let newDate = singularizeDate(dateRef.current[0], item.selection);
        item.selection.startDate = newDate;
        item.selection.endDate = newDate;
        dateRef.current = [item.selection];
        setDate([item.selection]);
      }
    } else {
      setDate([item.selection]);
    }
    function singularizeDate(prevDate, currentDate) {
      if (prevDate.startDate !== currentDate.startDate) {
        return currentDate.startDate;
      }
      if (prevDate.endDate !== currentDate.endDate) {
        return currentDate.endDate;
      }
    }
    // item) => {
    //             setDate([item.selection]);
    //           }
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  });
  const handleBack = () => {
    if (origin === 'ADVENTURE') openAdventureModal();
    if (origin === 'ITINERARY')
      openItineraryModal(null, { isTransitioning: true }, null);
    if (origin === 'ITINERARY-EDIT')
      openItineraryModal(null, { isTransitioning: true }, null);
    if (origin === 'ITINERARY_ITEM')
      openItineraryItemModal({ isTransitioning: true }, null);
  };
  const handleSubmit = () => {
    let dateObject = {
      startDate: date[0].startDate,
      endDate: date[0].endDate,
    };
    sendDates(dateObject);
    if (origin === 'ADVENTURE') openAdventureModal();
    if (origin === 'ITINERARY')
      openItineraryModal(null, { isTransitioning: true }, null);
    if (origin === 'ITINERARY-EDIT')
      openItineraryModal(null, { isTransitioning: true }, null);
    if (origin === 'ITINERARY_ITEM')
      openItineraryItemModal({ isTransitioning: true }, null);
  };
  return (
    <div className="mod-type1-wrapper disable-max-width" id="cre-ad">
      <div className="modal-float-padder">
        <Title closeModal={handleBack} title="PICK DATES" />
        <div className="mod-cnt-def-pickdate" style={{ height: '500px' }}>
          <form onSubmit={handleSubmit}>
            {/* {origin === "ITINERARY-EDIT" ? (
              <Calendar
                onChange={(item) => setDate(item)}
                locale={locales[locale]}
                date={date}
              />
            ) : ( */}
            <DateRange
              editableDateInputs={true}
              // onChange={(item) => {
              //   setDate([item.selection]);
              // }}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              ranges={date}
              months={isMobile ? 1 : 2}
              // showSelectionPreview={false}
              className="modal-date-range-picker-wrapper"
              showMonthAndYearPickers={false}
              direction="horizontal"
              // showDateDisplay={false}
              showPreview={false}
              retainEndDateOnFirstSelection={true}
              showDateDisplay={false}
              // focusedRange={origin === "ITINERARY-EDIT" && [0, 0]}
            />
            {/* )} */}
            <Button spacingBottom="20px" spacingTop="10px" text="Save dates" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
