export const svgImpact = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="83.333"
      height="83.333"
      viewBox="0, 0, 83.333, 83.333"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          d="M83.333,35.833 C83.333,28.333 77.917,22.5 70.833,21.667 C70,14.583 63.75,9.167 56.25,9.167 C55.417,9.167 54.583,9.167 53.75,9.583 C52.083,3.75 47.5,0 41.667,0 C35.833,0 31.25,3.75 29.583,8.75 C28.75,8.75 27.917,8.333 27.083,8.333 C19.583,8.333 13.75,13.75 12.5,20.833 C5.417,21.667 0,27.917 0,35 C0,40.833 3.333,45.833 8.333,47.917 C8.75,56.25 15,62.5 22.917,62.5 C27.083,62.5 30.833,60.833 33.333,57.917 C34.583,59.167 35.833,60 37.5,60.833 L37.5,75 L25,75 C20.417,75 16.667,78.75 16.667,83.333 L66.667,83.333 C66.667,78.75 62.917,75 58.333,75 L45.833,75 L45.833,62.5 C48.333,62.083 50.417,61.25 52.083,60 C54.583,61.667 57.5,62.5 60.417,62.5 C68.333,62.5 74.583,56.25 75,48.75 C80,46.25 83.333,41.25 83.333,35.833 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};