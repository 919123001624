import { STOP_MODAL, IMPACT_MODAL } from '../../constants';

const defaultState = { action: STOP_MODAL, isLoading: false };

export default function modalv2(state = defaultState, action) {
  Object.freeze(state);
  const { type, data } = action;
  switch (type) {
    case STOP_MODAL:
      return { type };

    case IMPACT_MODAL:
      return { ...state, type, data };
    default:
      return state;
  }
}
