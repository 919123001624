export const svgDashboardImpact = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      x="0"
      y="0"
      width={width ? width : '60'}
      height={height ? height : '54'}
      viewBox={viewBox ? viewBox : '0 0 100 90'}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            fill={color || "#000000"}
            d="M78.787,71.474 C78.491,71.809 78.157,72.051 77.768,72.423 L83.475,76.704 L86.543,79.782 C87.563,78.833 88.416,77.736 89.083,76.714 L84.14,74.605 L78.787,71.474 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M76.951,73.074 C76.71,73.279 76.376,73.521 76.098,73.633 C76.005,73.67 75.82,73.744 75.764,73.874 L79.245,79.05 L81.517,83.633 C81.795,83.522 81.944,83.354 82.222,83.242 C83.261,82.609 84.17,81.921 85.079,81.233 L81.732,78.266 L76.951,73.074 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M80.048,69.783 C79.937,70.043 79.733,70.34 79.529,70.638 L85.381,72.597 L90.12,75.004 C90.657,73.926 91.064,72.793 91.378,71.697 L86.345,71.24 L80.048,69.783 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M78.294,64.344 C78.591,64.548 79.019,64.808 79.353,65.104 L84.286,60.752 L88.107,57.923 C87.084,56.718 85.82,55.717 84.5,54.846 L81.944,59.214 L78.294,64.344 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M86.936,68.955 L91.765,69.709 C91.856,68.595 91.947,67.481 91.778,66.255 C91.759,65.94 91.685,65.754 91.666,65.438 L86.877,66.931 L80.472,67.888 C80.454,68.111 80.473,68.427 80.362,68.687 L86.936,68.955 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M74.781,74.377 C74.076,74.768 73.371,75.159 72.628,75.458 L75.293,81.284 L76.693,86.11 C77.713,85.7 78.697,85.197 79.773,84.657 L77.259,80.279 L74.781,74.377 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M75.507,63.309 C76.12,63.494 76.733,63.678 77.253,63.9 L79.993,58.381 L82.828,53.902 C81.88,53.42 80.877,53.069 79.743,52.662 L78.061,57.864 L75.507,63.309 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M80.005,65.92 C80.247,66.254 80.358,66.533 80.452,67.034 L86.112,64.76 L91.198,63.471 C90.732,62.042 90.173,60.65 89.28,59.5 L85.626,62.476 L80.005,65.92 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M50.759,78.963 C50.629,78.908 50.406,78.889 50.313,78.927 C49.161,78.743 48.047,78.652 46.803,78.505 L46.494,83.371 L45.074,89.761 C46.188,89.852 47.433,89.998 48.677,90.145 L49.319,85.038 L50.759,78.963 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M54.993,79.31 C54.547,79.273 54.102,79.237 53.693,79.293 C53.117,79.201 52.486,79.239 51.91,79.147 L51.622,84.867 L50.59,90.347 C51.389,90.457 52.15,90.474 52.949,90.584 C53.487,90.584 53.933,90.62 54.472,90.619 L54.316,85.94 L54.993,79.31 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M67.803,77.396 C66.467,77.825 65.186,78.124 64.314,78.367 L66.049,84.028 L66.911,89.393 C66.911,89.393 66.911,89.393 67.004,89.356 C67.877,89.113 68.749,88.871 69.807,88.553 L68.667,83.838 L67.803,77.396 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M73.252,82.643 L71.738,75.923 C70.717,76.333 69.789,76.706 68.824,76.986 L70.764,82.888 L71.645,88.031 C72.703,87.713 73.723,87.303 74.837,86.856 L73.252,82.643 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M63.218,78.592 C62.309,78.741 61.399,78.891 60.489,79.041 L61.334,85.168 L61.36,90.33 C62.586,90.161 63.718,90.03 64.906,89.768 L63.767,85.053 L63.218,78.592 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M59.45,79.136 C58.317,79.267 57.147,79.306 56.07,79.308 L56.655,85.324 L56.403,90.598 C57.387,90.633 58.334,90.576 59.281,90.519 L58.995,85.784 L59.45,79.136 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M72.312,62.868 C72.981,62.923 73.649,62.977 74.355,63.125 L75.703,57.626 L77.848,52.238 C76.79,52.017 75.638,51.833 74.394,51.686 L73.789,56.886 L72.312,62.868 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M68.134,62.93 C68.673,62.929 69.304,62.891 69.842,62.89 C70.288,62.927 70.697,62.87 71.142,62.907 L71.338,57.224 L72.425,51.615 C71.441,51.579 70.457,51.543 69.51,51.6 C69.194,51.62 68.786,51.676 68.433,51.602 L68.645,56.69 L68.134,62.93 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M52.652,66.24 C53.673,65.83 54.694,65.42 55.752,65.103 L53.294,60.594 L51.131,54.674 C50.388,54.972 49.609,55.177 48.866,55.476 L50.972,60.449 L52.652,66.24 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M56.995,64.711 C57.682,64.543 58.239,64.319 58.926,64.151 L57.172,58.713 L56.235,53.162 C55.047,53.424 53.989,53.741 52.931,54.058 L55.26,59.05 L56.995,64.711 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M64.476,63.214 C65.293,63.101 66.147,63.082 66.964,62.969 L66.269,57.213 L66.409,51.661 C65.053,51.774 63.791,51.851 62.565,52.019 L63.78,56.92 L64.476,63.214 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M46.352,62.629 L48.439,67.825 C49.46,67.415 50.388,67.042 51.409,66.632 L48.932,61.808 L46.918,56.258 C45.711,56.743 44.598,57.19 43.429,57.768 L46.352,62.629 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M60.021,63.926 C60.114,63.889 60.299,63.815 60.429,63.87 C61.339,63.72 62.249,63.57 63.251,63.383 L61.572,58.13 L60.653,52.357 C59.836,52.469 59.057,52.675 58.333,52.75 L59.492,57.781 L60.021,63.926 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M11.681,73.971 C11.829,73.804 12.163,73.562 12.59,73.283 L7.683,70.728 L2.59,67.17 C1.941,67.969 1.423,68.824 1.034,69.735 C0.479,71.036 0.109,72.262 -0,73.599 L6.165,73.385 L11.681,73.971 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M23.878,71.873 C24.992,71.964 25.976,72 27.091,72.091 L26.86,66.688 L27.389,60.763 C26.182,60.709 24.938,60.563 23.731,60.509 L24.127,64.983 L23.878,71.873 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M11.404,75.16 L6.558,75.706 L0.114,75.493 C0.096,75.716 0.171,75.901 0.152,76.124 C0.247,77.164 0.434,78.166 0.751,79.224 C0.9,79.596 1.05,79.967 1.161,80.245 L6.376,77.935 L11.759,76.311 C11.554,76.07 11.368,75.606 11.404,75.16 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M17.055,79.033 C16.201,79.052 15.402,78.942 14.752,78.665 L11.082,83.48 L7.245,87.608 C8.955,88.645 10.813,89.515 12.893,89.865 L14.593,84.44 L17.055,79.033 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M20.843,78.804 C19.803,78.898 18.949,78.918 18.225,78.994 L16.897,84.808 L14.788,90.289 C15.81,90.418 16.701,90.491 17.741,90.396 C18.056,90.377 18.688,90.339 19.412,90.264 L19.646,85.212 L20.843,78.804 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M19.718,71.712 C20.795,71.711 21.779,71.746 22.856,71.745 L21.826,65.692 L21.725,60.345 C20.518,60.291 19.218,60.274 17.955,60.35 L19.133,65.697 L19.718,71.712 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M25.485,78.556 C24.445,78.65 23.183,78.726 22.013,78.765 L22.059,84.782 L21.306,90.149 C22.791,90.091 24.239,89.941 25.409,89.902 L25.086,85.074 L25.485,78.556 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M13.619,78.258 C13.358,78.147 13.061,77.943 12.856,77.702 C12.726,77.646 12.559,77.498 12.484,77.313 L7.196,79.976 L1.907,82.101 C2.894,83.752 4.159,85.291 5.628,86.533 L9.335,82.35 L13.619,78.258 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M13.851,72.668 C14.315,72.482 14.909,72.351 15.466,72.127 L11.8,67.026 L9.361,62.294 C9.082,62.406 8.897,62.481 8.618,62.593 C6.8,63.431 5.279,64.473 4.018,65.627 L9.278,68.794 L13.851,72.668 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M16.599,71.995 C16.914,71.976 17.23,71.957 17.639,71.901 C17.954,71.882 18.27,71.863 18.585,71.844 L16.925,66.368 L15.913,60.632 C14.279,60.857 12.59,61.213 11.031,61.623 L13.804,66.113 L16.599,71.995 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M75.643,67.672 L74.195,67.823 L74.044,66.375 L72.689,66.488 L72.84,67.937 L71.392,68.087 L71.505,69.443 L72.953,69.292 L73.104,70.74 L74.459,70.627 L74.309,69.179 L75.757,69.028 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M69.211,74.999 L69.06,73.55 L70.508,73.4 L70.395,72.044 L68.947,72.195 L68.889,70.709 L67.533,70.823 L67.591,72.308 L66.236,72.422 L66.349,73.777 L67.705,73.664 L67.855,75.112 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M67.768,66.31 L66.32,66.461 L66.169,65.013 L64.814,65.126 L64.964,66.574 L63.516,66.725 L63.63,68.081 L65.078,67.93 L65.228,69.378 L66.584,69.265 L66.433,67.816 L67.881,67.666 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M60.071,72.635 L61.519,72.485 L61.577,73.97 L62.933,73.857 L62.875,72.371 L64.323,72.221 L64.209,70.865 L62.761,71.016 L62.61,69.568 L61.255,69.681 L61.406,71.129 L59.958,71.28 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M54.792,69.152 L56.147,69.039 L56.298,70.487 L57.654,70.374 L57.503,68.925 L58.951,68.775 L58.838,67.419 L57.389,67.57 L57.331,66.085 L55.976,66.198 L56.034,67.683 L54.679,67.797 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M54.952,76.524 L56.4,76.373 L56.551,77.822 L57.906,77.708 L57.755,76.26 L59.204,76.109 L59.09,74.754 L57.642,74.905 L57.491,73.456 L56.136,73.57 L56.286,75.018 L54.838,75.169 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M48.54,73.173 L49.933,73.152 L50.046,74.508 L51.402,74.394 L51.288,73.039 L52.736,72.888 L52.623,71.533 L51.175,71.683 L51.117,70.198 L49.761,70.311 L49.819,71.797 L48.427,71.817 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M21.786,76.054 L23.234,75.904 L23.385,77.352 L24.74,77.238 L24.589,75.79 L26.075,75.732 L25.961,74.377 L24.476,74.435 L24.362,73.079 L23.007,73.193 L23.121,74.548 L21.672,74.699 z"
            
          />
          <path
            className={className}
            fill={color || "#000000"}
            d="M17.145,77.38 L18.501,77.266 L18.35,75.818 L19.836,75.76 L19.722,74.405 L18.237,74.463 L18.086,73.015 L16.73,73.128 L16.881,74.576 L15.433,74.727 L15.546,76.082 L16.995,75.932 z"
            
          />
        </g>
        <g>
          <g>
            <path
              className={className}
              fill={color || "#000000"}
              d="M86.044,12.085 C85.622,11.932 85.213,11.878 84.692,11.737 L87.674,5.238 L88.906,1.059 C90.258,1.408 91.534,1.967 92.61,2.548 L89.263,6.769 L86.044,12.085 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M83.662,11.553 C83.351,11.488 82.942,11.434 82.643,11.469 C82.543,11.481 82.344,11.504 82.233,11.415 L82.826,5.192 L82.637,0.069 C82.936,0.034 83.147,0.111 83.445,0.076 C84.663,0.137 85.793,0.309 86.923,0.481 L85.391,4.694 L83.662,11.553 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M87.962,12.973 C87.74,12.797 87.419,12.632 87.097,12.468 L91.316,7.944 L94.341,3.559 C95.329,4.252 96.229,5.056 97.029,5.872 L92.814,8.679 L87.962,12.973 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M89.016,18.602 C89.181,18.281 89.434,17.848 89.587,17.427 L96.013,18.904 L100.731,19.569 C100.406,21.12 99.77,22.606 99.024,24.004 L94.685,21.378 L89.016,18.602 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M94.426,10.41 L98.32,7.438 C98.933,8.376 99.546,9.314 99.983,10.474 C100.117,10.761 100.14,10.961 100.274,11.248 L95.342,12.221 L89.242,14.44 C89.119,14.252 88.985,13.965 88.763,13.789 L94.426,10.41 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M81.127,11.442 C80.318,11.435 79.51,11.427 78.714,11.519 L78.276,5.113 L77.203,0.192 C78.298,0.065 79.405,0.038 80.611,-0 L80.49,5.059 L81.127,11.442 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M87.056,20.847 C87.508,20.391 87.96,19.935 88.312,19.491 L93.364,23.044 L98.002,25.636 C97.397,26.513 96.681,27.302 95.877,28.202 L91.909,24.423 L87.056,20.847 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M89.771,16.396 C89.824,15.987 89.79,15.688 89.632,15.202 L95.706,14.5 L100.803,13.205 C101.075,14.687 101.247,16.18 101.01,17.62 L96.369,16.744 L89.771,16.396 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M57.772,18.883 C57.684,18.994 57.496,19.117 57.396,19.128 C56.47,19.841 55.532,20.454 54.505,21.178 L51.908,17.038 L47.603,12.088 C48.541,11.475 49.567,10.751 50.594,10.027 L53.601,14.22 L57.772,18.883 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M61.336,16.554 C60.961,16.799 60.586,17.045 60.199,17.19 C59.736,17.546 59.162,17.815 58.698,18.171 L55.71,13.27 L52.183,8.935 C52.834,8.456 53.496,8.077 54.147,7.598 C54.622,7.342 54.997,7.096 55.472,6.84 L57.571,11.037 L61.336,16.554 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M73.537,12.118 C72.154,12.378 70.883,12.727 69.998,12.93 L68.822,7.113 L67.017,1.974 C67.017,1.974 67.017,1.974 67.117,1.963 C68.001,1.76 68.886,1.557 69.969,1.331 L71.219,6.029 L73.537,12.118 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M75.829,4.891 L77.707,11.535 C76.612,11.661 75.616,11.777 74.632,11.991 L73.52,5.864 L71.838,0.913 C72.922,0.687 74.017,0.56 75.212,0.422 L75.829,4.891 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M68.926,13.256 C68.053,13.559 67.18,13.861 66.307,14.164 L64.123,8.363 L61.679,3.802 C62.839,3.365 63.9,2.94 65.072,2.603 L66.321,7.301 L68.926,13.256 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M65.346,14.578 C64.285,15.003 63.236,15.528 62.286,16.041 L59.926,10.462 L57.184,5.936 C58.034,5.434 58.895,5.032 59.757,4.629 L61.768,8.937 L65.346,14.578 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M84.452,22.762 C85.015,22.394 85.578,22.026 86.13,21.559 L89.944,25.759 L94.41,29.481 C93.583,30.182 92.657,30.894 91.63,31.618 L88.612,27.326 L84.452,22.762 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M80.742,24.704 C81.217,24.447 81.791,24.179 82.266,23.923 C82.641,23.677 83.028,23.532 83.403,23.287 L86.291,28.199 L89.93,32.622 C89.08,33.124 88.23,33.626 87.368,34.028 C87.081,34.162 86.695,34.307 86.419,34.541 L84.174,29.958 L80.742,24.704 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M65.52,29.187 C66.616,29.061 67.711,28.934 68.795,28.708 L68.784,33.855 L69.708,40.104 C68.911,40.196 68.126,40.388 67.33,40.48 L66.808,35.092 L65.52,29.187 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M70.077,28.459 C70.763,28.279 71.36,28.21 72.046,28.03 L73.1,33.659 L74.927,38.997 C73.756,39.334 72.672,39.56 71.588,39.786 L71.254,34.276 L70.077,28.459 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M77.384,26.202 C78.157,25.911 78.919,25.52 79.693,25.229 L81.83,30.632 L84.608,35.457 C83.359,36.004 82.211,36.541 81.05,36.978 L79.778,32.08 L77.384,26.202 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M61.696,35.38 L61.051,29.804 C62.147,29.678 63.142,29.563 64.238,29.436 L64.361,34.87 L65.239,40.722 C63.945,40.871 62.75,41.009 61.444,41.059 L61.696,35.38 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M73.118,27.704 C73.217,27.692 73.417,27.669 73.505,27.558 C74.378,27.256 75.251,26.953 76.223,26.639 L77.254,32.069 L79.205,37.594 C78.431,37.886 77.646,38.077 76.972,38.357 L75.589,33.371 L73.118,27.704 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M25.73,41.957 C25.941,42.034 26.351,42.088 26.86,42.13 L23.759,46.725 L20.972,52.294 C20.018,51.9 19.153,51.394 18.375,50.778 C17.264,49.897 16.353,48.994 15.617,47.868 L21.151,45.11 L25.73,41.957 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M37.478,37.977 C38.417,37.364 39.267,36.862 40.205,36.249 L42.584,41.12 L45.882,46.086 C44.844,46.71 43.818,47.434 42.78,48.059 L40.991,43.927 L37.478,37.977 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M24.918,41.042 L20.388,42.877 L14.813,46.145 C14.69,45.957 14.667,45.758 14.545,45.57 C14.131,44.609 13.817,43.637 13.591,42.553 C13.545,42.155 13.499,41.756 13.464,41.458 L19.162,41.001 L24.681,39.859 C24.616,40.169 24.673,40.667 24.918,41.042 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M28.046,34.93 C27.284,35.321 26.633,35.799 26.193,36.355 L20.659,33.867 L15.305,32.064 C16.315,30.333 17.537,28.678 19.202,27.376 L23.293,31.342 L28.046,34.93 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M31.492,33.321 C30.531,33.735 29.769,34.125 29.095,34.405 L25.146,29.918 L20.669,26.096 C21.507,25.495 22.258,25.005 23.219,24.591 C23.506,24.457 24.081,24.189 24.755,23.909 L27.375,28.248 L31.492,33.321 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M33.891,40.106 C34.84,39.593 35.69,39.091 36.64,38.578 L38.625,44.402 L41.092,49.162 C40.054,49.786 38.917,50.422 37.768,50.959 L36.25,45.686 L33.891,40.106 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M35.7,31.321 C34.739,31.735 33.591,32.271 32.541,32.796 L29.707,27.474 L26.478,23.105 C27.814,22.446 29.162,21.886 30.211,21.362 L32.234,25.769 L35.7,31.321 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M25.389,37.255 C25.212,37.477 25.048,37.799 24.983,38.109 C24.895,38.22 24.818,38.431 24.841,38.63 L18.909,38.811 L13.234,39.466 C13.314,37.54 13.693,35.579 14.394,33.783 L19.659,35.697 L25.389,37.255 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M28.265,42.068 C28.763,42.011 29.349,41.842 29.946,41.773 L29.155,48.019 L29.267,53.353 C28.968,53.388 28.769,53.411 28.47,53.445 C26.467,53.576 24.629,53.385 22.967,52.972 L26.087,47.667 L28.265,42.068 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M31.007,41.348 C31.294,41.214 31.582,41.079 31.968,40.934 C32.255,40.8 32.543,40.666 32.83,40.532 L33.983,46.149 L35.834,51.687 C34.287,52.269 32.629,52.763 31.059,53.146 L31.357,47.865 L31.007,41.348 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M85.091,16.937 L83.743,17.496 L84.302,18.844 L83.054,19.392 L82.495,18.044 L81.147,18.604 L80.599,17.355 L81.947,16.796 L81.388,15.448 L82.636,14.9 L83.195,16.248 L84.543,15.689 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M75.923,13.557 L76.482,14.905 L77.83,14.346 L78.378,15.594 L77.03,16.153 L77.689,17.49 L76.44,18.038 L75.781,16.701 L74.533,17.249 L73.985,16.001 L75.234,15.453 L74.674,14.105 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M78.804,21.901 L77.456,22.461 L78.015,23.808 L76.767,24.356 L76.208,23.008 L74.86,23.568 L74.312,22.319 L75.66,21.76 L75.1,20.412 L76.349,19.864 L76.908,21.212 L78.256,20.653 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M69,20.007 L70.348,19.448 L69.689,18.112 L70.937,17.564 L71.596,18.9 L72.944,18.341 L73.492,19.589 L72.144,20.148 L72.703,21.496 L71.455,22.044 L70.896,20.696 L69.548,21.256 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M66.014,25.599 L67.262,25.051 L66.703,23.703 L67.951,23.155 L68.51,24.503 L69.858,23.944 L70.406,25.192 L69.058,25.751 L69.717,27.088 L68.469,27.636 L67.81,26.299 L66.562,26.847 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M62.631,19.028 L63.979,18.469 L63.42,17.121 L64.668,16.573 L65.228,17.921 L66.575,17.361 L67.123,18.61 L65.775,19.169 L66.335,20.517 L65.086,21.065 L64.527,19.717 L63.179,20.276 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M58.584,25.045 L59.821,24.397 L59.273,23.149 L60.522,22.601 L61.07,23.85 L62.418,23.29 L62.965,24.539 L61.617,25.098 L62.276,26.434 L61.028,26.982 L60.369,25.646 L59.132,26.293 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M33.637,35.293 L34.985,34.733 L34.426,33.386 L35.674,32.838 L36.233,34.186 L37.57,33.527 L38.117,34.775 L36.781,35.434 L37.329,36.682 L36.081,37.23 L35.533,35.982 L34.185,36.541 z"
              
            />
            <path
              className={className}
              fill={color || "#000000"}
              d="M28.915,36.343 L30.164,35.795 L30.723,37.143 L32.059,36.484 L32.607,37.732 L31.271,38.391 L31.83,39.739 L30.582,40.287 L30.023,38.939 L28.675,39.498 L28.127,38.25 L29.475,37.691 z"
              
            />
          </g>
        </g>
      </g>
    </svg>
  );
};