import merge from 'lodash/merge';
import {
  PERSIST_UPSERT_ADVENTURE_MODAL,
  RECEIVE_IMAGES_SELECTED,
  CLOSE_MODAL,
  PERSIST_UPSERT_ACCOUNT_GROUP_MODAL,
  PERSIST_UPSERT_ADVENTURE_GROUP_MODAL,
  RECEIVE_FLUSH_MODAL,
  PERSIST_UPSERT_BLOCK_MODAL,
  PERSIST_UPSERT_BLOCK_MODAL_FILE,
  PERSIST_UPSERT_ITINERARY_MODAL,
  PERSIST_UPSERT_ITINERARY_ITEM_MODAL,
  UPDATE_FEATURE_MAP_RID,
} from '../../constants';

const AdventuresReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case PERSIST_UPSERT_ADVENTURE_MODAL:
      if (action.status) {
        newState['tripDataHolding'] = action.data;
      } else {
        newState['tripDataHolding'] = null;
      }
      return newState;
    case PERSIST_UPSERT_ITINERARY_MODAL:
      newState['currentItinerary'] = action.data;

      if (action.openType.isNew || action.openType.isEdit) {
        newState['itineraryData'] = action.actionPayload;
      } else if (action.openType.isSaving) {
        newState['savedItineraryData'] =
          action.actionPayload.persistedItineraryModalData;
      }
      return newState;
    case RECEIVE_IMAGES_SELECTED:
      if (action.hasMulti) {
        newState['images'] = action.data.multiImages;
      } else {
        newState['image'] = action.data.singleImage;
      }

      return newState;
    case PERSIST_UPSERT_ITINERARY_ITEM_MODAL:
      const { isNew, isEdit, isSaving } = action.openType;
      const { actionPayload } = action;
      if (isNew || isEdit) {
        newState['itineraryItemData'] = actionPayload;
      } else if (isSaving) {
        newState['savedItineraryItemData'] =
          actionPayload.persistedItineraryItemModalData;
        newState['originalItineraryItem'] =
          actionPayload.itineraryItemBeingEdited;
      }
      return newState;
    case PERSIST_UPSERT_ACCOUNT_GROUP_MODAL:
      newState['currentAccountGroup'] = action.data;
      return newState;
    case PERSIST_UPSERT_ADVENTURE_GROUP_MODAL:
      newState['currentAdventureGroup'] = action.data;
      return newState;

    case PERSIST_UPSERT_BLOCK_MODAL:
      if (
        action.openType.isNew ||
        action.openType.isEditAndView ||
        action.openType.isEditWithTab
      ) {
        newState['currentBlock'] = action.actionPayload.currentBlock;
        newState['actionPayload'] = action.actionPayload;
      } else if (action.openType.isSaving) {
        newState['savedBlockModalData'] = action.actionPayload.savingModalData;
      } else if (action.openType.isTransitioning) {
        return newState;
      }
      return newState;
    case PERSIST_UPSERT_BLOCK_MODAL_FILE:
      newState['currentBlockFiles'] = action.data.blockFiles;
      return newState;
    case UPDATE_FEATURE_MAP_RID:
      newState.actionPayload.activityModalTypeRid = action.featureRid;
      return newState;
    case CLOSE_MODAL:
      newState = {};
      return newState;
    case RECEIVE_FLUSH_MODAL:
      newState = {};
      return newState;
    default:
      return state;
  }
};
export default AdventuresReducer;
