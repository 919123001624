export const svgTripDate = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="84.452"
      height="76.418"
      viewBox="0, 0, 84.452, 76.418"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M7.99,10.075 C6.692,10.075 5.64,11.127 5.64,12.425 C5.64,13.723 6.692,14.775 7.99,14.775 C9.288,14.775 10.34,13.723 10.34,12.425 C10.34,11.127 9.288,10.075 7.99,10.075 z M7.99,13.575 C7.355,13.575 6.84,13.06 6.84,12.425 C6.84,11.79 7.355,11.275 7.99,11.275 C8.625,11.275 9.14,11.79 9.14,12.425 C9.14,13.06 8.625,13.575 7.99,13.575 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M60.62,10.075 C59.322,10.075 58.27,11.127 58.27,12.425 C58.27,13.723 59.322,14.775 60.62,14.775 C61.918,14.775 62.97,13.723 62.97,12.425 C62.97,11.127 61.918,10.075 60.62,10.075 z M60.62,13.575 C59.985,13.575 59.47,13.06 59.47,12.425 C59.47,11.79 59.985,11.275 60.62,11.275 C61.255,11.275 61.77,11.79 61.77,12.425 C61.77,13.06 61.255,13.575 60.62,13.575 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M63.26,43.285 L63.26,23.355 C63.26,23.023 62.991,22.755 62.66,22.755 L22.98,22.755 C22.649,22.755 22.38,23.023 22.38,23.355 L22.38,29.355 L7.11,29.355 C6.779,29.355 6.51,29.623 6.51,29.955 L6.51,49.925 C6.51,50.256 6.779,50.525 7.11,50.525 L46.79,50.525 C47.121,50.525 47.39,50.256 47.39,49.925 L47.39,43.925 L62.7,43.925 C62.859,43.914 63.008,43.841 63.113,43.721 C63.218,43.601 63.271,43.444 63.26,43.285 z M14.45,49.285 L7.7,49.285 L7.7,43.885 L14.44,43.885 L14.44,49.325 z M14.45,42.645 L7.7,42.645 L7.7,37.245 L14.44,37.245 L14.44,42.685 z M14.45,36.005 L7.7,36.005 L7.7,30.595 L14.44,30.595 L14.44,36.035 z M22.39,49.295 L15.65,49.295 L15.65,43.885 L22.39,43.885 L22.39,49.325 z M22.39,42.655 L15.65,42.655 L15.65,37.245 L22.39,37.245 L22.39,42.685 z M30.33,49.295 L23.58,49.295 L23.58,43.885 L30.32,43.885 L30.32,49.325 z M30.33,42.655 L23.58,42.655 L23.58,37.245 L30.32,37.245 L30.32,42.685 z M30.33,29.365 L23.58,29.365 L23.58,23.955 L30.32,23.955 L30.32,29.395 z M38.27,49.295 L31.52,49.295 L31.52,43.885 L38.26,43.885 L38.26,49.325 z M38.27,42.655 L31.52,42.655 L31.52,37.245 L38.26,37.245 L38.26,42.685 z M38.27,29.365 L31.52,29.365 L31.52,23.955 L38.26,23.955 L38.26,29.395 z M46.21,49.295 L39.46,49.295 L39.46,43.885 L46.2,43.885 L46.2,49.325 z M46.21,42.655 L39.46,42.655 L39.46,37.245 L46.2,37.245 L46.2,42.685 z M46.21,29.365 L39.46,29.365 L39.46,23.955 L46.2,23.955 L46.2,29.395 z M54.13,42.685 L47.39,42.685 L47.39,37.245 L54.13,37.245 L54.13,42.685 z M54.13,29.395 L47.39,29.395 L47.39,23.955 L54.13,23.955 L54.13,29.395 z M62.06,42.685 L55.33,42.685 L55.33,37.245 L62.07,37.245 L62.07,42.685 z M62.06,36.045 L55.33,36.045 L55.33,30.595 L62.07,30.595 L62.07,36.035 z M62.06,29.405 L55.33,29.405 L55.33,23.955 L62.07,23.955 L62.07,29.395 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M50.47,11.325 L17.34,11.325 C16.732,11.325 16.24,11.817 16.24,12.425 C16.24,13.032 16.732,13.525 17.34,13.525 L50.47,13.525 C51.078,13.525 51.57,13.032 51.57,12.425 C51.57,11.817 51.078,11.325 50.47,11.325 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M69.2,44.045 L69.2,7.855 C69.2,6.64 68.215,5.655 67,5.655 L63.76,5.655 L63.76,3.755 C63.762,3.385 63.577,3.04 63.27,2.835 L59.2,0.185 C58.862,-0.041 58.427,-0.061 58.069,0.131 C57.711,0.324 57.488,0.698 57.49,1.105 L57.49,5.685 L10.85,5.685 L10.85,3.785 C10.852,3.415 10.667,3.07 10.36,2.865 L6.29,0.185 C5.952,-0.041 5.517,-0.061 5.159,0.131 C4.801,0.324 4.578,0.698 4.58,1.105 L4.58,5.685 L2.2,5.685 C0.985,5.685 -0,6.67 -0,7.885 L-0,55.335 C0.001,56.47 0.869,57.416 2,57.515 L2,59.605 C2,60.157 2.448,60.605 3,60.605 L3.26,60.605 L3.26,62.605 C3.26,63.157 3.708,63.605 4.26,63.605 L52.41,63.605 C54.163,71.804 61.883,77.31 70.206,76.299 C78.529,75.287 84.705,68.092 84.444,59.712 C84.183,51.331 77.57,44.535 69.2,44.045 z M59.7,3.115 L61.56,4.345 L61.56,5.655 L59.7,5.655 L59.7,3.115 z M6.8,3.115 L8.7,4.345 L8.7,5.655 L6.79,5.655 L6.79,3.115 z M2.2,7.855 L67,7.855 L67,16.735 L2.2,16.735 L2.2,7.855 z M5.2,61.565 L5.2,60.565 L52.07,60.565 C52.07,60.885 52.07,61.215 52.07,61.565 L5.22,61.565 z M52.11,58.565 L3.95,58.565 L3.95,57.535 L52.29,57.535 C52.23,57.885 52.17,58.235 52.13,58.595 z M2.2,55.335 L2.2,17.935 L67,17.935 L67,44.065 C60.414,44.581 54.799,49.038 52.8,55.335 L2.2,55.335 z M68.25,74.185 C61.833,74.168 56.249,69.792 54.7,63.565 C54.63,63.265 54.56,62.965 54.51,62.665 C54.46,62.365 54.45,62.295 54.42,62.105 C54.39,61.915 54.36,61.745 54.34,61.565 C54.32,61.385 54.34,60.925 54.34,60.565 C54.34,60.435 54.34,60.305 54.34,60.165 C54.34,59.775 54.34,59.385 54.4,59.005 C54.4,58.855 54.4,58.715 54.4,58.565 Q54.46,58.025 54.56,57.505 C54.62,57.175 54.71,56.845 54.8,56.505 L54.8,56.335 Q54.95,55.805 55.15,55.335 C57.017,50.298 61.602,46.774 66.95,46.265 C67.38,46.265 67.82,46.195 68.26,46.195 C68.7,46.195 68.87,46.195 69.18,46.195 C76.909,46.451 82.967,52.925 82.71,60.655 C82.453,68.384 75.979,74.441 68.25,74.185 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M75.39,54.655 L66.39,64.975 L61.01,59.625 C60.579,59.183 59.872,59.174 59.43,59.605 C58.988,60.035 58.979,60.743 59.41,61.185 L65.63,67.365 C65.837,67.571 66.118,67.686 66.41,67.685 L66.41,67.685 C66.713,67.675 66.999,67.542 67.2,67.315 L77.02,56.115 C77.351,55.658 77.283,55.026 76.863,54.65 C76.443,54.274 75.807,54.276 75.39,54.655 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};