import React, { useEffect } from "react";
import { mapStateToProps, mapDispatchToProps } from "./container";
import { connect } from "react-redux";
const FolderContents = ({
  currentBlock,
  fetchFolderContents,
  folderContents,
}) => {
  useEffect(() => {
    if (currentBlock) {
      fetchFolderContents(currentBlock.block_rid);
    }
  }, []);
  if (folderContents) {
  }
  return (
    <div className="folder-contents-wrapper">{folderContents && <></>}</div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderContents);
