import { Button } from '@mui/material';
import { PUBLIC_SITE_URL } from 'constants/env';

function PublicSiteNavButton() {
  return (
    // @ts-ignore
    /** @type {import('react').ReactElement<import('@mui/material').ButtonProps & {
     *   LinkComponent?: typeof NavLink,
     *   external?: boolean,
     *   target?: string,
     *   rel?: string
     * }>} */
    <Button variant="contained" href={PUBLIC_SITE_URL}>
      Return Home
    </Button>
  );
}

export default PublicSiteNavButton;
