import React from "react";

const ModalSaveButton = ({
  spacingTop,
  spacingBottom,
  text,
  isAltButton,
  handleIsAltButton,
  type,
  large,
  applyBackground,
  disabledCondition,
  customClassName,
  buttonType,
  handleClick
}) => {
  return (
    <div
      className={`mod-save-btn-wrapper ${large && "mod-save-btn-wrapper--large"}
      
      ${applyBackground && "mod-save-btn-wrapper--background"}  
      ${customClassName && customClassName}
      `}
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
    >
      {!isAltButton && (
        <button
          type={buttonType ? buttonType : "submit"}
          className={`mod-save-btn-${type}`}
          disabled={disabledCondition ? disabledCondition : false}
          onClick={handleClick}
        >
          {text}
        </button>
      )}
      {isAltButton && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleIsAltButton(e);
          }}
          className={`mod-save-btn-type-${type}`}
          disabled={disabledCondition ? disabledCondition : false}
        >
          {text}
        </button>
      )}
    </div>
  );
};

export default ModalSaveButton;
