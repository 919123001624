import { useEffect, useState } from 'react';
import { Menu, Notifications } from './components';

const MenuDrawer = ({
  style,
  name,
  imageSrc,
  email,
  sendDataToParent,
  history,
  menuOpen,
  userInfo,
  userNotifications,
  ...props
}) => {
  let openType = {
    isEditWithTab: false,
  };
  let blockType = {
    isItineraryItemBlock: false,
    isNestedItineraryItemBlock: false,
  };
  const { openDrawerWithTab, triggerMenuDrawerWithTab } = props;
  const [tab, setTab] = useState('Menu');
  const [socket, setSocket] = useState(null);
  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    return pathname === '/';
  };
  const customScroll = () => {
    var winScroll = document.getElementById('menu-scroller').scrollTop;
    var height =
      document.getElementById('menu-scroller').scrollHeight -
      document.getElementById('menu-scroller').clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById('menu-status-bar').style.height =
      scrolled + 7 + '%';
  };

  let scrollerStyle;
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [scrollable, setScrollable] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    if (menuOpen) window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);
  useEffect(() => {
    if (openDrawerWithTab === 'notification') {
      setTab('Notification');
      triggerMenuDrawerWithTab(null);
    } else if (openDrawerWithTab === 'menu') {
      setTab('Menu');
      triggerMenuDrawerWithTab(null);
    }
  }, [openDrawerWithTab]);
  // useEffect(() => {
  //   const notificationHandler = (payload) => {
  //     //when something reaches here it will mean that there ia a new
  //     // in app notification, there for trigger a new fetch  of the notification
  //     // and display the notification on screen
  //     props.fetchUserIncomingNotification(userInfo.explorer_rid);
  //     props.triggerNewNotificationBadge(payload);
  //   };
  //   let socketIO;
  //   if (!socket) {
  //     socketIO = io.connect(WS_BASE);
  //     setSocket(socketIO);
  //     socketIO.emit('connect_notifications', {
  //       explorer_rid: userInfo.explorer_rid,
  //     });

  //     socketIO.on(
  //       `user_notification_${userInfo.explorer_rid}`,
  //       notificationHandler,
  //     );
  //   }
  //   return () => {
  //     if (socketIO) {
  //       socketIO.off(
  //         `user_notification_${userInfo.explorer_rid}`,
  //         notificationHandler,
  //       );
  //       socketIO.emit('disconnect');
  //     }
  //   };
  // }, []);
  // useEffect(() => {
  //   if (socket) {
  //   }
  // }, [socket]);
  useEffect(() => {
    const scrollBarVisible = () => {
      if (
        document.getElementById('menu-scroller').scrollHeight >
        document.getElementById('menu-scroller').clientHeight
      ) {
        document.getElementById('progress-bar-container').style.display = '';
      } else {
        document.getElementById('progress-bar-container').style.display =
          'none';
      }
    };
    scrollBarVisible();
    customScroll();
  }, [windowDimensions, menuOpen]);
  return (
    <>
      <div
        className={`menu-wrapper ${
          menuOpen ? 'menu-drawer-open' : 'menu-drawer-closed'
        }`}
        id="menu-scroller"
        onScroll={() => customScroll()}
      >
        <div className="menu-drawer-wrapper" id="menu-drawer-wrapper-mobile">
          <div className="header-wrapper"></div>
          {tab === 'Menu' && (
            <Menu
              sendDataToParent={sendDataToParent}
              checkActive={checkActive}
              setTab={setTab}
              userInfo={userInfo}
              userNotifications={userNotifications}
              {...props}
            />
          )}
          {tab === 'Notification' && <Notifications setTab={setTab} />}
        </div>
      </div>
      <div className={`progress-container `} id="progress-bar-container">
        <div
          className={`no-progress-bar   ${
            menuOpen ? 'progressbar-drawer-open' : 'progressbar-drawer-closed'
          }`}
        ></div>
        <div
          className={`progress-bar  ${
            menuOpen ? 'progressbar-drawer-open' : 'progressbar-drawer-closed'
          }`}
          id="menu-status-bar"
        ></div>
      </div>
    </>
  );
};

export default MenuDrawer;
