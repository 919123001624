import { Typography } from '@mui/material';

function SectionSubheader({ children, ...rest }) {
  return (
    <Typography component="h6" {...rest}>
      {children}
    </Typography>
  );
}

export default SectionSubheader;
