import * as ApiUtilUser from '../util/api_util/user_api_util';

import {
  RECEIVE_USER,
  REMOVE_USER,
  RECEIVE_ALL_USERS,
  RECEIVE_EXPLORER_DETAIL,
  RECEIVE_BOARDING_USER,
  RECEIVE_ALL_ACCOUNT_EXPLORERS,
  RECEIVE_ACCOUNT_DETAILS,
  RECEIVE_USER_SUBSCRIPTION,
  RECEIVE_USER_PAYMENT,
  RECEIVE_ACCOUNT_PARTNER_DONATIONS,
  RECEIVE_FLUSH_ACCOUNT_DONATIONS,
} from '../constants';
const receiveAllUsers = (users) => {
  return {
    type: RECEIVE_ALL_USERS,
    users,
  };
};
const receiveAccountPartnerDonations = (data) => {
  return {
    type: RECEIVE_ACCOUNT_PARTNER_DONATIONS,
    data,
  };
};
const receiveUserSubscription = (data) => {
  return {
    type: RECEIVE_USER_SUBSCRIPTION,
    data,
  };
};

const receiveFlushAccountDonations = () => ({
  type: RECEIVE_FLUSH_ACCOUNT_DONATIONS,
});

const receiveUserPayment = (data) => ({
  type: RECEIVE_USER_PAYMENT,
  data,
});

const receiveAccountDetails = (data) => {
  return {
    type: RECEIVE_ACCOUNT_DETAILS,
    data,
  };
};
const receiveExplorerDetail = (data) => {
  return {
    type: RECEIVE_EXPLORER_DETAIL,
    data,
  };
};
const receiveAllAccountExplorers = (data) => {
  return {
    type: RECEIVE_ALL_ACCOUNT_EXPLORERS,
    data,
  };
};
const receiveBoardingUser = (user) => {
  return {
    type: RECEIVE_BOARDING_USER,
    user,
  };
};

export const removeUser = (user) => {
  return {
    type: REMOVE_USER,
    user,
  };
};
export const receiveUser = (user) => {
  return {
    type: RECEIVE_USER,
    user: user,
  };
};
export const deleteUser = (id) => (dispatch) => {
  return ApiUtilUser.deleteUser(id).then((user) => {
    return dispatch(removeUser(user));
  });
};

export const fetchUsers = () => (dispatch) => {
  return ApiUtilUser.fetchUsers().then((users) => {
    return dispatch(receiveAllUsers(users));
  });
};

export const fetchUser = (userId) => (dispatch) => {
  return ApiUtilUser.fetchUser(userId).then((user) =>
    dispatch(receiveUser(user)),
  );
};

export const sendBoardingUser = (userData) => (dispatch) => {
  return dispatch(receiveBoardingUser(userData));
};
// export const updateUser = user => dispatch => {
//   return ApiUtilUser.updateUser(user).then(res =>
//     dispatch(receiveUser(res)));
// };

export const updateUserInfo = (userData, userInfo) => (dispatch) => {
  return ApiUtilUser.updateUserInfo(userData).then(() => {
    Promise.all([
      ApiUtilUser.fetchAccountExplorers(userData.account_rid, null),
      ApiUtilUser.fetchAccountDetail(userData.account_rid),
    ]).then((res) => {
      dispatch(receiveAllAccountExplorers(res[0].data.accountExplorers));
      dispatch(receiveAccountDetails(res[1].data.accountDetails));
    });
  });
};

export const fetchAccountExplorer = (account_rid, explorer_rid) => (
  dispatch,
) => {
  return ApiUtilUser.fetchAccountExplorers(account_rid, explorer_rid).then(
    (res) => {
      if (explorer_rid) {
        dispatch(receiveExplorerDetail(res.data.accountExplorers[0]));
      } else {
        dispatch(receiveAllAccountExplorers(res.data.accountExplorers));
      }
    },
  );
};

export const fetchAccountDetails = (account_rid) => (dispatch) => {
  return ApiUtilUser.fetchAccountDetail(account_rid).then((res) => {
    dispatch(receiveAccountDetails(res.data.accountDetails));
  });
};

export const updateAccountDetails = (data) => (dispatch) => {
  return ApiUtilUser.updateAccountDetails(data).then((res) => {
    dispatch(receiveAccountDetails(res.data.accountDetails));
  });
};

export const insertUserSubscription = (data) => (dispatch) => {
  return ApiUtilUser.insertUserSubscription(data).then((res) => {
    dispatch(receiveUserSubscription(res.data.userSubscription));
  });
};

export const insertUserPayment = (data) => (dispatch) => {
  return ApiUtilUser.insertUserPayment(data).then((res) => {
    dispatch(receiveUserPayment(res.data.paymentData));
  });
};

export const insertUserSusbscriptionAndSubscriptionId = (dataIncoming) => (
  dispatch,
) => {
  return ApiUtilUser.insertUserSubscription(dataIncoming).then((res) => {
    return res;
  });
};

export const updateSubScriptionPartnerCode = (data) => (dispatch) => {
  return ApiUtilUser.updateSubScriptionPartnerCode(data).then((res) => {
    return res;
  });
};
export const terminateUserSubscriptionEarly = (data) => (dispatch) => {
  return ApiUtilUser.terminateUserSubscriptionEarly(data);
};

export const updateUserPassword = (data) => (dispatch) => {
  return ApiUtilUser.updateUserPassword(data).then((res) => {
    return res;
  });
};

export const deleteAccountExplorer = (explorerRid, accountRid) => (
  dispatch,
) => {
  return ApiUtilUser.deleteAccountExplorer(explorerRid, accountRid).then(
    (res) => {
      Promise.all([
        ApiUtilUser.fetchAccountExplorers(accountRid, null),
        ApiUtilUser.fetchAccountDetail(accountRid),
      ]).then((res2) => {
        dispatch(receiveAllAccountExplorers(res2[0].data.accountExplorers));
        dispatch(receiveAccountDetails(res2[1].data.accountDetails));
      });
    },
  );
};

export const fetchAccountPartnerDonations = (account_rid) => (dispatch) => {
  return ApiUtilUser.fetchMyAccountDonations(account_rid).then(
    ({ status, data }) => {
      if (status === 200) {
        return dispatch(
          receiveAccountPartnerDonations(data.accountPartnerDonations),
        );
      }
    },
  );
};

export const flushAccountPartnerDonations = () => (dispatch) => {
  return dispatch(receiveFlushAccountDonations());
};

export const insertAccountPartnerDonation = (data) => (dispatch) => {
  return ApiUtilUser.insertAccountPartnerDonation(data).then(() => {
    return dispatch(receiveFlushAccountDonations());
  });
};
