export function boatCruising (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      viewBox="0 0 304 200"
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
      className={outerLayer}
    >
      <g>
        <path
          className={`${className} fil0`}
          d="M24 104l247 0 -8 36c-2,2 -6,3 -9,3 -16,0 -22,-17 -42,-16 -17,1 -25,15 -39,16 -15,0 -22,-17 -41,-16 -17,1 -25,15 -39,16 -17,0 -25,-20 -47,-15l-22 -24z"
        ></path>
        <path
          className={`${className} fil0`}
          d="M304 200l-304 0 0 -54c25,9 34,-13 51,-14 16,0 23,17 42,16 17,-1 25,-15 39,-16 16,0 22,17 42,16 17,-1 25,-15 39,-16 15,0 22,17 41,16 20,-1 30,-23 50,-13l0 65z"
        ></path>
        <path
          className={`${className} fil0`}
          d="M202 49l0 15c-5,-1 -10,-1 -16,-3l0 -12 16 0z"
        ></path>
        <polygon
          className={`${className} fil0`}
          points="186,43 202,43 202,47 186,47 "
        ></polygon>
        <path
          className={`${className} fil0`}
          d="M243 12c6,1 11,5 11,11 0,6 -5,11 -11,11 -3,0 -6,-1 -8,-3 -2,5 -6,8 -11,8 -4,0 -8,-2 -10,-5 -3,2 -7,3 -10,3 -2,2 -4,4 -7,4 -5,0 -8,-4 -8,-8 0,-3 1,-6 4,-7 0,-8 5,-14 12,-14 4,0 8,3 10,6 1,-1 3,-2 4,-3 0,-1 0,-2 0,-3 0,-7 5,-12 12,-12 7,0 12,5 12,12l0 0z"
        ></path>
        <path
          className={`${className} fil0`}
          d="M113 41c50,17 99,31 150,38l0 21 -191 0 15 -28 10 -3 2 -12 3 -1 11 -15zm-18 33l-5 0 -2 5 6 0 1 -5zm0 11l-8 0 -3 5 10 0 1 -5zm61 -22l6 0 2 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-13 0l8 0 0 5 -8 0 0 -5zm-13 0l7 0 0 5 -8 0 1 -5zm109 11l6 0 2 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-13 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-13 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -9 0 1 -5zm138 11l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-13 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-14 0l8 0 0 5 -8 0 0 -5zm-13 0l8 0 0 5 -8 0 0 -5zm-16 0l10 0 0 5 -11 0c0,0 0,0 0,0l1 -5z"
        ></path>
      </g>
    </svg>
  );
}