import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from 'components/modules/footer';
import axios from 'axios';
const Homepage = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  let locationParse = props.location.pathname.split('/');
  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });

  let email = `${locationParse[locationParse.length - 3]}@${
    locationParse[locationParse.length - 2]
  }.${locationParse[locationParse.length - 1]}`;

  const [message, setMessage] = useState('');
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data', { pageName: 'about-us' })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
    let user = { email: email };
    axios.patch('/api/e/unsubscribe', user).then((res) => {
      if (res.status === 'success') {
        setMessage(res.message);
      } else {
        setMessage(res.message);
      }
    });
    scrollTop();
  }, []);
  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div className="page-content-wrapper">
        <div className="unsubscribed-block">
          <div className="unsubscribed-header" id="unsubscribed-header-mobile">
            <h1>UNSUBSCRIBED</h1>
          </div>
          <div className="unsubscribed-text">
            <span>Thank you for visiting Lodestar!</span>
          </div>
          <div className="unsubscribed-text">
            <span>{message ? message : 'No user found'}</span>
          </div>
          <div className="unsubscribed-text last-sect-unsubscribed">
            <span>
              Send a message at support@lodestarhub.com if you have any further
              questions.
            </span>
          </div>
        </div>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
