import { useQuery } from '@tanstack/react-query';
import fetchAdventureLists from '../api/fetchAdventureLists';

/**
 * Get all list blocks from adventure
 * @param {number} adventureRid
 */
const useAdventureLists = (adventureRid) => {
  const query = useQuery({
    queryFn: () =>
      fetchAdventureLists(adventureRid).then((res) => {
        return res.data;
      }),
    queryKey: getQueryKey(adventureRid),
  });
  return query;
};
export const getQueryKey = (/** @type {number} */ adventureRid) => {
  return ['adventure', adventureRid, 'blocks', 'lists'];
};
export default useAdventureLists;
