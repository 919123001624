export function surfBoard (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M58.621,61.557c1.3-1.992,2.471-4.014,3.506-6.039l-7.211-19.814L35.083,42.92c-0.884,2.093-1.671,4.295-2.362,6.566  l18.992-6.914L58.621,61.557z M63.052,53.67c0.01-0.018,0.021-0.037,0.029-0.057c6.168-13.226,9.122-33.348,0.836-37.211  S42.116,27.735,35.948,40.96c-0.009,0.02-0.017,0.04-0.026,0.06l19.891-7.24L63.052,53.67z M68.442,68.578  c-1.3-1.299-2.771-2.066-3.789-2.066c-0.374,0-0.688,0.105-0.907,0.324l-3.107,3.107c-0.815,0.816-0.035,2.918,1.743,4.695  c1.777,1.777,3.879,2.559,4.694,1.742l3.107-3.107C71,72.457,70.22,70.355,68.442,68.578z M66.55,70.471  c-1.004-1.004-1.437-1.906-1.575-2.406c0.54,0.146,1.451,0.619,2.407,1.574c1.004,1.004,1.437,1.906,1.574,2.406  C68.416,71.898,67.505,71.426,66.55,70.471z M59.515,79.146c0,1.689-1.374,3.063-3.063,3.063s-3.063-1.373-3.063-3.063  c0-2.033-1.654-3.688-3.688-3.688h-2.759c3.968-3.691,7.587-7.93,10.642-12.361l-6.769-18.601l-18.61,6.773  c-2.598,9.41-3.477,19.857-1.996,28.906l4.674-1.514l1.846,4.553c2.732-1.623,5.412-3.57,7.978-5.756h4.995  c0.931,0,1.688,0.758,1.688,1.688c0,2.791,2.271,5.063,5.063,5.063s5.063-2.271,5.063-5.063c0-1.436,0.559-2.785,1.574-3.801  l-0.707-0.707l-0.707-0.707C60.282,75.324,59.515,77.176,59.515,79.146z M34.727,64.973l-0.954-1.156l3.873-3.191l0.954,1.158  L34.727,64.973z M37.877,74.02l-1.359-0.633l2.535-5.439l1.359,0.635L37.877,74.02z M45.426,64.965l1.5-0.014l0.047,5.018  l-1.5,0.014L45.426,64.965z"
        ></path>
      </g>
    </svg>
  );
}