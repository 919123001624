
export const svgGiveBack = (color, className, outerLayer) => {
  return (
    <svg version="1.1" className={outerLayer}>
      <g id="Layer_1">
        <path
          className={className}
          d="M78.37,25.087 C85.297,25.087 90.913,19.471 90.913,12.543 C90.913,5.616 85.297,0 78.37,0 C71.442,0 65.826,5.616 65.826,12.543 C65.826,19.471 71.442,25.087 78.37,25.087 z M78.37,23.157 C84.231,23.157 88.983,18.405 88.983,12.543 C88.983,6.682 84.231,1.93 78.37,1.93 C72.508,1.93 67.756,6.682 67.756,12.543 C67.756,18.405 72.508,23.157 78.37,23.157 z"
          fill={color}
        />
        <path
          className={className}
          d="M25.087,12.543 C25.087,5.613 19.474,0 12.544,0 C5.613,0 0,5.613 0,12.543 C0,19.474 5.613,25.087 12.544,25.087 C19.474,25.087 25.087,19.474 25.087,12.543 z M13.617,19.474 L13.617,20.743 C13.617,21.036 13.373,21.28 13.08,21.28 L11.714,21.28 C11.421,21.28 11.177,21.036 11.177,20.743 L11.177,19.376 C10.006,19.035 9.029,18.351 8.444,17.912 C8.151,17.668 8.102,17.229 8.395,16.985 L9.322,16.009 C9.566,15.765 9.908,15.765 10.152,15.96 C10.738,16.399 11.714,16.985 12.544,16.985 C14.252,16.985 14.252,16.009 14.252,15.618 C14.252,15.032 14.105,14.544 12.251,13.861 C9.615,12.934 8.249,11.421 8.249,9.468 C8.249,7.663 9.371,6.296 11.177,5.759 L11.177,4.393 C11.177,4.1 11.421,3.856 11.714,3.856 L13.08,3.856 C13.373,3.856 13.617,4.1 13.617,4.393 L13.617,5.613 C14.593,5.808 15.423,6.296 15.911,6.638 C16.204,6.882 16.253,7.321 16.009,7.614 L15.081,8.59 C14.886,8.834 14.545,8.883 14.252,8.688 C13.813,8.395 13.227,8.102 12.592,8.151 C12.202,8.151 10.884,8.297 10.884,9.42 C10.884,9.761 10.884,10.542 13.129,11.372 C14.252,11.762 16.887,12.69 16.887,15.618 C16.887,17.668 15.618,19.083 13.617,19.474 z"
          fill={color}
        />
        <path
          className={className}
          d="M35.209,66.3 C33.709,63.7 31.109,60.5 28.409,57.5 C26.109,54.9 23.609,52.1 21.909,49.6 C20.709,47.8 18.509,47.8 16.909,48.7 C14.909,49.8 13.609,52.5 15.609,56.4 C16.709,58.5 19.309,61.3 22.009,64.3 C22.209,64.5 22.309,64.7 22.509,64.8 C23.109,65.5 23.009,66.6 22.209,67.1 C21.609,67.5 20.709,67.3 20.209,66.8 C20.109,66.6 19.909,66.5 19.809,66.3 C17.709,64 15.809,61.9 14.409,60 C13.809,59.2 13.309,58.5 13.009,57.7 C11.409,54.6 11.409,51.9 12.209,49.8 C10.809,46.7 9.509,43.8 8.909,41.9 C7.209,36.4 -0.291,37.8 0.009,43.7 C0.309,49.6 2.409,69.2 8.109,75.9 C10.709,78.9 15.409,83.1 20.409,86.5 C21.209,87 21.609,87.9 21.609,88.9 L21.609,92.9 C21.609,94.1 22.609,95.1 23.809,95.1 L37.809,95.1 C39.009,95.1 40.009,94.1 40.009,92.9 C39.909,90.5 39.809,86.5 39.609,83.1 C39.109,75.8 36.909,69.1 35.209,66.3 z"
          fill={color}
        />
        <path
          className={className}
          d="M82.009,41.9 C81.409,43.8 80.209,46.7 78.709,49.8 C79.509,51.9 79.509,54.7 77.909,57.7 C77.509,58.4 77.009,59.2 76.509,60 C75.109,61.9 73.209,64 71.109,66.3 C71.009,66.5 70.809,66.6 70.709,66.8 C70.209,67.4 69.309,67.5 68.709,67.1 C67.909,66.6 67.809,65.5 68.409,64.8 C68.609,64.6 68.709,64.4 68.909,64.3 C71.609,61.4 74.209,58.6 75.309,56.4 C77.309,52.4 76.009,49.8 74.009,48.7 C72.409,47.8 70.209,47.8 69.009,49.6 C67.309,52.2 64.909,54.9 62.509,57.5 C59.809,60.5 57.209,63.7 55.609,66.3 C53.909,69.1 51.709,75.9 51.309,83 C51.109,86.5 51.009,90.4 50.909,92.8 C50.909,94 51.909,95 53.109,95 L67.109,95 C68.309,95 69.309,94 69.309,92.8 L69.309,88.8 C69.309,87.9 69.809,87 70.509,86.4 C75.509,83 80.209,78.8 82.809,75.8 C88.509,69.1 90.609,49.5 90.909,43.6 C91.109,37.9 83.709,36.5 82.009,41.9 z"
          fill={color}
        />
        <path
          className={className}
          d="M75.565,4.082 C75.565,3.407 76.373,2.859 77.371,2.859 C78.37,2.859 79.178,3.407 79.178,4.082 L79.178,13.357 L84.055,16.658 C84.761,17.136 84.761,17.91 84.055,18.387 C83.35,18.865 82.207,18.865 81.501,18.387 L76.093,14.727 C75.719,14.475 75.544,14.139 75.565,13.809 L75.565,4.082 z"
          fill="#FFFFFF"
        />
        <path
          className={className}
          d="M43.275,39.006 C43.276,39.007 43.297,39.009 43.307,39.012 C43.307,39.022 43.305,39.035 43.305,39.043 C43.637,39.453 43.939,39.879 44.219,40.328 L44.219,40.328 C47.37,45.368 47.619,52.55 47.614,54.843 C47.611,52.478 47.207,44.918 42.463,40.173 C36.785,34.496 27.045,35.033 27.045,35.033 C27.045,35.033 26.508,44.775 32.183,50.453 C35.476,53.745 40.133,54.945 43.466,55.37 C43.401,55.36 43.34,55.354 43.273,55.343 L43.273,65.349 L47.635,65.349 L47.635,40.074 C50.953,39.625 55.499,38.411 58.729,35.177 C64.405,29.5 63.868,19.759 63.868,19.759 C63.868,19.759 54.127,19.222 48.45,24.9 C45.969,27.381 44.678,30.633 44.007,33.509"
          fill={color}
        />
      </g>
    </svg>
  );
};
