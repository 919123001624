export function brewery (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0, 0, 79.359, 90"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M72.436,41.539 L62.66,41.539 L62.241,34.413 C62.135,32.582 60.62,31.154 58.784,31.154 L23.777,31.154 C21.941,31.154 20.427,32.582 20.32,34.413 L17.266,86.336 C17.15,88.323 18.73,90 20.723,90 L61.842,90 C63.831,90 65.412,88.323 65.297,86.335 L64.492,72.692 L72.436,72.692 C76.259,72.692 79.359,69.592 79.359,65.769 L79.359,48.461 C79.36,44.638 76.26,41.539 72.436,41.539 M58.798,76.164 C58.798,79.987 55.49,83.087 51.667,83.087 L30.898,83.087 C27.073,83.087 24.183,79.987 24.183,76.164 L24.183,38.087 L30.898,38.087 L30.898,53.654 L32.628,67.5 L34.359,53.654 L34.359,38.087 L58.845,38.077 L58.798,76.164 z M72.644,65.77 L64.086,65.77 L63.068,48.462 L72.643,48.462 L72.643,65.77 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M56.624,3.826 C52.045,2.719 47.764,4.219 44.926,7.132 C42.554,2.889 38.02,0 32.836,0 C27.671,0 23.149,2.87 20.77,7.092 C18.513,5.77 15.858,5.056 13.02,5.216 C4.736,5.676 -0.996,13.603 0.144,21.82 C1.422,31.018 8.605,37.292 8.605,45 C8.605,53.461 5.144,53.078 5.144,60.577 C5.144,68.078 12.067,65.769 12.067,65.769 C12.067,65.769 13.678,32.884 13.797,32.884 L13.797,31.153 C13.797,27.33 16.897,24.23 20.72,24.23 L26.627,24.23 L55.335,24.23 C61.937,24.23 65.578,22.32 65.718,15.838 C65.838,10.26 62.049,5.138 56.624,3.826"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
}