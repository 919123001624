import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.avatarImg
 * @param {React.ReactNode} [props.actionButton]
 */
function UserListItem({ name, avatarImg, actionButton }) {
  return (
    <ListItem secondaryAction={actionButton}>
      <ListItemAvatar>
        <Avatar alt={name} src={avatarImg} />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          mr: 4,
          textOverflow: 'ellipsis',
          '& MuiTypography-root': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
        primaryTypographyProps={{
          sx: {
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Use 'nowrap' (not 'noWrap')
            textOverflow: 'ellipsis',
          },
        }}
      />
    </ListItem>
  );
}

export default UserListItem;
