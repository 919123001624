import React from 'react';
import { Link } from 'react-router-dom';
const planDetails = [
  'Create and store trips in your own Adventure Hub',
  'Build a library to save and organize your ideas',
  'Tag & Save trail maps, lodging, and other trips ideas from the web',
  'Invite friends to collaborate on trips',
  'Build detailed trip itineraries',
  'Map your route',
  'Create custom packing lists',
  "Keep track of where you've been",
  'Record and share your trips',
];
const PlanBox = ({
  plan,
  isUserPlan,
  currentExplorerDetails,
  fetchPlanFinalPrice,
  isAvailablePlansPage,
  isUpdate,
  isUpgradable,
}) => {
  const {
    plan_code,
    plan_month_max,
    plan_name,
    plan_price,
    software_name,
    software_rid,
    ui_image_location,
    upgradable_flag,
    plan_duration_month,
    plan_description,
  } = plan;
  return (
    <section className="subscription-plan-box-wrapper">
      {isUserPlan && (
        <div className={`subscription-current-plan-indicator-box`}>
          <span>CURRENT PLAN</span>
        </div>
      )}
      <div
        className={`subscription-plan-box ${isUserPlan && 'remove-top-border'}`}
      >
        <div className="subscription-plan-name-and-image-container">
          {/* <div className="subscription-plan-image-wrapper">
            <img src={ui_image_location} className="subscription-plan-image" />
          </div> */}
          <div className="subscription-plan-name-wrapper">
            <span>{plan_name}</span>
          </div>
        </div>
        {/* <hr className="subscription-box-borders" /> */}
        <div className="subscription-plan-detail-wrapper">
          <h2>{plan_description}</h2>
        </div>
        <div className="subscription-box-borders-wrapper">
          <hr className="subscription-box-borders" />
        </div>
        <div className="subscription-plan-prices-wrapper">
          <div className="subscription-plan-prices">
            <span>
              <span className="bold-plan-price">
                ${Number.parseFloat(plan_price).toFixed(2)}
              </span>{' '}
              / {plan_duration_month === 12 ? 'Year' : 'Month'}
            </span>
          </div>
          <div className="subscription-plan-buttons-wrapper">
            {!isUserPlan ? (
              <Link
                to={{
                  pathname: '/hub/account/checkout-subscription',
                  state: {
                    plan: plan,
                    currentExplorerDetails,
                    account_info: currentExplorerDetails,
                    isUpdate,
                  },
                }}
                className="subscription-box-select-button"
              >
                <span>Upgrade</span>
              </Link>
            ) : (
              <>
                {!isAvailablePlansPage && upgradable_flag && !isUpgradable && (
                  <Link
                    to={{
                      pathname: '/hub/account/subscription',
                      state: {
                        plan: plan,
                        currentExplorerDetails,
                        isUpgrade: true,
                      },
                    }}
                    className="subscription-box-select-button"
                  >
                    <span>Upgrade</span>
                  </Link>
                )}
                {!isAvailablePlansPage && (
                  <Link
                    to={{
                      pathname: '/hub/account/cancel-subscription',
                      state: {
                        plan: plan,
                        currentExplorerDetails,
                        isCancel: true,
                      },
                    }}
                    className="subscription-box-select-button cancel"
                  >
                    <span>Cancel Membership</span>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanBox;
