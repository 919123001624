export function dayHike (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      enableBackground="new 0 0 34 34"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M2,33.00006h30c0.55273,0,1-0.44775,1-1c0-0.55225-0.44727-1-1-1h-6.16571l2.36786-13.07013h0.35748   c0.83002,0,1.5-0.66998,1.5-1.5c0-0.7674-0.57489-1.39081-1.31726-1.48163l1.24164-6.85364   c0.09766-0.54346-0.2627-1.06396-0.80566-1.16211c-0.55078-0.10156-1.06445,0.2627-1.16309,0.80566l-1.30292,7.19171h-2.06927   c-0.53931,0-1.05573-0.21777-1.43219-0.60394l-4.6225-4.74207c-0.37646-0.38617-0.89288-0.604-1.43219-0.604h-1.52527   c-1.30872,0-2.47681,0.63879-3.20984,1.63043l0.00854-0.08044c0.10999-0.89996-0.39001-1.76996-1.22998-2.10999L9.91962,7.8999   c-1.20001-0.48999-2.53998,0.27002-2.72998,1.56L7.11963,9.92993l2.39001,1.16998c0.48999,0.25,0.69,0.85004,0.45001,1.34003   c-0.17999,0.35999-0.53003,0.56-0.90002,0.56c-0.15002,0-0.29999-0.03003-0.44-0.10004l-1.81-0.88995l-0.58002,5.91998   c-0.29999,2.01001,1.45001,3.72998,3.45001,3.39996l0.84442-0.13983l-0.47498,3.63452   c-0.01935,0.14838-0.07178,0.29047-0.15338,0.41583l-3.87384,5.75964H2c-0.55273,0-1,0.44775-1,1   C1,32.55231,1.44727,33.00006,2,33.00006z M17.99963,13.80993l3.47247,3.5238c0.37592,0.38147,0.88904,0.59619,1.42456,0.59619   h3.27216l-2.36786,13.07013h-1.68579l-0.03082-7.78851c-0.00287-0.7337-0.40735-1.40698-1.05377-1.75409l-3.03094-1.62756V13.80993   z M13.69415,26.5285c0.56505-0.7925,0.30722-1.47612,0.42548-3.27856c2.67615,1.2375,4,1.11865,4,2.26019v5.48993H10.5014   L13.69415,26.5285z"
        ></path>
        <circle className={className} cx="17.04132" cy="3.99994" r="3"></circle>
      </g>
    </svg>
  );
}