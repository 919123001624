export const SvgUpArrow = (
  color = '#273906',
  className,
  outerLayer,
  width = 25,
  height = 25,
) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 1200 1200"
      className={outerLayer}
    >
      <path
        className={className}
        fill={color}
        d="m600 60c-298.23 0-540 241.77-540 540s241.77 540 540 540 540-241.77 540-540-241.77-540-540-540zm169.71 688.5-169.71-169.71-169.71 169.71-84.84-84.852 254.55-254.57 254.55 254.55z"
      />
    </svg>
  );
};