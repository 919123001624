export const svgAdventureCount = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
      x="0"
      y="0"
      width="167.161"
      height="167.161"
      viewBox="0, 0, 167.161, 167.161"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M83.58,167.161 C37.42,167.161 0,129.741 0,83.58 C0,37.42 37.42,0 83.58,0 C129.741,0 167.161,37.42 167.161,83.58 C167.161,129.741 129.741,167.161 83.58,167.161 z"
          fill={color}
        />
        <g>
          <path
            className={className}
            d="M135.591,49.949 L83.6,130.035 L56.816,88.894 L50.401,98.778 L83.612,149.792 L148.43,49.949 z"
            fill={color}
          />
          <path
            className={className}
            d="M83.549,17.369 L18.731,117.21 L31.57,117.21 L83.561,37.124 L110.344,78.263 L116.76,68.381 z"
            fill={color}
          />
          <path
            className={className}
            d="M83.548,59.826 L89.179,77.337 L104.197,83.9 L89.179,90.465 L83.548,107.976 L77.917,90.465 L62.899,83.9 L77.917,77.337 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};