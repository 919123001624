export const svgCheckList = (color, className, outerLayer, width, height) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 79.038, 78.23"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M-0,78.23 L68.9,78.23 L68.9,28.33 C67,30.33 63.7,32.93 62.1,34.93 L62.1,71.43 L6.8,71.43 L6.8,16.03 L45.2,16.03 C46.6,14.33 48.8,11.03 50.5,9.23 L-0,9.23 L-0,78.23 z"
          fill={color}
        />
        <path
          className={className}
          d="M26.3,31.43 C21.2,24.73 9.7,31.33 14.9,38.13 C20.2,45.03 24.8,52.53 27.1,60.93 C29,67.73 37.5,66.63 39.9,60.93 C48,41.03 61,25.43 76.9,11.13 C83.3,5.43 73.9,-3.87 67.5,1.73 C53.8,14.13 42.5,27.43 33.9,43.13 C31.7,39.03 29.1,35.13 26.3,31.43 z"
          fill={color}
        />
      </g>
    </svg>
  );
};