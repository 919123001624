import styled from 'styled-components';

/* 

  <Button
    background={'#fff'}
    foreground={'#fff'}
    hoverForeground={'#fff'}
    hoverBackground={'#fff'}
    width={}
    height={}
    font={'Soleil, Arial'}
    size={15}                     // font size
    round={5}                     // border-radius
    textAlign={'center'}          // text-align
    border={'1px solid green'}  
    color={'#fff'}
    bold={true | false}
    medium={true | false}
    align={'center | right'}      // default: left aligned
  ></Button>

*/

const Button = styled.button`
  background: ${({ background }) => background || '#DDD5C3'};
  color: ${({ foreground }) => foreground || '#273A07'};
  font-family: ${({ font }) => font || 'Soleil'};
  font-size: ${({ size }) => size || '20px'};
  font-weight: ${({ bold, medium }) => (bold ? 700 : medium ? 500 : 300)};
  padding: ${({ padding }) => padding || '8px 16px'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  border: ${({ border }) => border || 'none'};
  border-radius: ${({ round }) => round || '4px'};
  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || 'fit-content'};
  margin: ${({ margin }) => margin || '0px'};
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground || '#F2A93B'};
    color: ${({ hoverForeground }) => hoverForeground || '#273A07'};
  }
  ${({ align }) =>
    align === 'center'
      ? 'margin: auto;'
      : align === 'right'
      ? 'float: right;'
      : ''}
`;

export default Button;
