export function rockClimbing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      className={outerLayer}
    >
      <path
        className={className}
        d="M33.739,68.596c0.028,0.995-0.283,3-0.368,3.6c-0.657,4.605-1.325,9.21-2.005,13.812c-0.358,2.427-1.936,2.882-3.632,2.612  c-0.454-0.072-0.911-0.147-1.349-0.28c-1.265-0.384-2.015-1.295-1.788-2.572c0.506-2.823,1.124-5.628,1.792-8.417  c0.167-0.697,2.238-9.217,2.384-10.002c0.147-0.785-1.632-5.163-1.389-5.12c0.132,0.023,4.635-0.588,5.924,0  C33.966,63.309,33.711,67.602,33.739,68.596"
      ></path>
      <path
        className={className}
        d="M19.949,26.544c-2.072-2.225-1.971-5.64,0.229-7.737c2.228-2.123,5.84-2.02,7.894,0.224c2.032,2.221,1.915,5.696-0.26,7.775  C25.576,28.945,22.077,28.829,19.949,26.544"
      ></path>
      <polygon points="45.53,100.022 38.026,60.825 35.918,61.228 44.004,100.022 "></polygon>
      <path
        className={className}
        d="M29.394,37.923c0,0,1.076-0.336,1.305-0.402c1.405-0.402,2.972,0.032,3.815,0.201c1.004,0.201,6.727-1.807,8.534-2.912  c1.807-1.104,5.823-5.02,5.823-5.723c0-0.703-1.104-3.715-1.907-2.811c-0.803,0.904-8.333,6.325-9.337,6.425  c-1.004,0.101-7.53-0.1-9.136-0.903c-1.15-0.575-5.321-1.807-6.526-1.406c-1.205,0.402-3.815,1.807-3.413,4.016  c0.402,2.209,2.108,10.14,2.61,12.148s2.209,7.931,3.112,9.337c0.904,1.406,1.506,4.016,3.715,4.217  c2.209,0.201,5.221-0.2,8.031-0.903c2.811-0.703,5.522-3.514,6.426-1.707c0.904,1.807,0.804,4.116,2.108,7.429  c1.305,3.313,2.309,7.831,2.711,9.638c0.402,1.807,0.2,1.206,1.305,2.008c1.105,0.803,4.017,0.401,4.217-0.603  c0.201-1.004-4.819-18.975-5.421-20.381c-0.603-1.406-1.306-3.915-4.217-4.116c-2.911-0.201-6.626,0.702-7.831,0.602  c-1.205-0.1-2.811-1.204-3.313-2.911c-0.502-1.707-2.261-6.542-2.811-8.132C28.29,38.426,29.394,37.923,29.394,37.923z"
      ></path>
      <path
        className={className}
        d="M26.681,16.248c1.105,0.402,4.017,2.209,4.017,2.209s1.506-10.441,1.506-11.746c0-1.305-2.108-4.719-2.811-4.719  c-0.703,0-1.405,0.703-1.405,2.209s-1.205,11.646-1.205,11.646"
      ></path>
      <path
        className={className}
        d="M21.762,53.697c0.402,0.703,3.213,6.124,3.112,6.827c-0.101,0.703-0.001,7.028-1.004,8.032  c-1.004,1.004-1.807-2.309-1.807-2.309s0.803,3.313-0.703,3.614c-1.506,0.301-1.606-7.128-1.606-7.128s-1.305-5.321-0.301-6.526  C20.456,55.002,21.762,53.697,21.762,53.697z"
      ></path>
      <g>
        <path
          className={className}
          d="M92.798,0H34.323c-0.227,5.138,2.082,1.308,3.354,3.895c1.428,2.907-0.253,11.402,2.149,13.91   c5.76,6.01,11.827,2.256,16.356,2.507c-14.314,1.138-1.232,6.76-6.911,9.727c-3.683,1.923,1.994,24.385,4.007,32.66   c2.013,8.275,10.618,9.619,10.618,9.619s-9.083,0.529-9.415,5.711c-0.333,5.182,0.052,2.036,2.512,12.612   C59.162,99.968,59.107,97,58.786,100H92.8c4.101,0,7.2-3.854,7.2-8.021V8C100,3.833,96.9,0,92.798,0z"
        ></path>
      </g>
    </svg>
  );
}