import CardCarousel from '@components/carousel/card-carousel';
import CardCarouselLayout from '@components/carousel/card-carousel-layout';
import CarouselCardSkeleton from '@components/carousel/card-carousel-skeleton';
import SectionHeader from '@components/section/header';
import SectionSubheader from '@components/section/subheader';
import { Box } from '@mui/material';
import PaperSection from 'components/section/paper-section';
import React from 'react';
import PartnerCard from './partner-card';
import usePartners from './use-partners';

function PartnersCarousel() {
  const { data, isLoading, isError } = usePartners();
  if (isLoading) return;
  if (isError) return;

  return (
    <Box marginY={3}>
      <PaperSection>
        <CardCarouselLayout>
          <Box mb={3}>
            <SectionHeader>IMPACT PARTNERS</SectionHeader>
            <SectionSubheader>Learn more about our partners</SectionSubheader>
          </Box>
          {isLoading ? (
            <CarouselCardSkeleton />
          ) : (
            <CardCarousel className="related-partner">
              {data.map((partner) => {
                return (
                  <PartnerCard
                    backgroundColor="transparent"
                    key={partner.Name}
                    name={partner._Name}
                    image={partner.Logo}
                    description={partner.Description}
                    link={partner.CMS_URL ?? partner.Domain_URL}
                    externalLink={!partner.CMS_URL}
                  />
                );
              })}
            </CardCarousel>
          )}
        </CardCarouselLayout>
      </PaperSection>
    </Box>
  );
}

export default React.memo(PartnersCarousel);
