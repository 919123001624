export function leaf (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 138 157"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M4 117c-2,-90 55,-97 134,-117 -7,70 -22,131 -118,134 -2,5 -4,10 -5,16 -3,12 -18,6 -14,-3 17,-44 46,-77 83,-105 0,0 -53,25 -80,75z"
        ></path>
      </g>
    </svg>
  );
}