import React from 'react';
import { Tooltip } from '@mui/material';
import './tooltip-button.scss';
const TooltipButton = ({ icon, label, onClick, disabled, isListAdd, size="sm"}) => {
  return (
    <Tooltip title={label} arrow>
      <div
        onClick={onClick}
        className={
          (isListAdd
            ? 'tooltip-button-container list-button-add'
            : 'tooltip-button-container'
          ) + ` ${size}`
        }
      >
          {icon}
      </div>
    </Tooltip>
  );
};

export default TooltipButton;
