import styled from "styled-components";
import { Typography } from "@material-ui/core";

const DescriptionText = styled(Typography)`
  font-weight: 200;
  font-size: 16px;
  line-height: 12px;
  color: #827700;
`;

export default DescriptionText;