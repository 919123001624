export function hillStation (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;" />
        <g fill={color} className={className}>
          <path d="M31.02997,51.70001c1.91003,0.77997,3.67999,1.72998,5.26001,2.83997C34.70996,53.42999,32.94,52.47003,31.02997,51.70001z   "></path>
          <path d="M35.89996,26.53998l4.36005,4.16003l4.38-4.16003c0.26996-0.25995,0.69-0.28998,1-0.08997l4.59998,3.02002l-9.31-16.74005   c-0.14001-0.23999-0.39001-0.38995-0.65997-0.38995c-0.27002,0-0.52002,0.14996-0.66003,0.38995l-9.31,16.74005l4.59998-3.02002   C35.20996,26.23999,35.64001,26.28003,35.89996,26.53998z"></path>
          <path d="M39.08002,55.84003L39.45996,56.19c3.98004-1.98999,8.82001-3.15002,13.94-3.15002   c3.82001,0,7.60004,0.68005,11.04004,1.99005L51.83997,32.34998l-0.25-0.15997l-6.29999-4.15002l-4.46997,4.25   c-0.15002,0.14001-0.35004,0.22003-0.56,0.22003c-0.21002,0-0.41003-0.08002-0.55005-0.22003l-4.45996-4.25l-6.54999,4.30005   l-0.91003,1.63995C27.77997,34,27.77002,34.02002,27.76001,34.04999L19.69,48.56   C27.34998,48.87,34.34998,51.47998,39.08002,55.84003z"></path>
          <path d="M24.16998,37.41998l-0.96997,0.97003C23.06,38.53003,22.87,38.60999,22.66998,38.60999s-0.39001-0.07996-0.52997-0.21997   l-2.73004-2.72998v-0.01001c-0.01001,0.01001-0.01996,0.01001-0.02997,0.02997C19.37,35.69,19.35999,35.69,19.34998,35.70001   c-0.01996,0.01001-0.01996,0.01001-0.01996,0.01001c-0.30005,0.17999-1.57001,0.96997-6.23004,3.94l-5.87,10.54999   C10.58997,49.10999,14.23999,48.54999,18,48.53003L24.16998,37.41998z"></path>
          <path d="M22.67999,23.21002c-0.27002,0-0.52002,0.14996-0.65997,0.38995l-7.41003,13.32001   c1.80414-1.14941,0.53583-0.34479,4.53003-2.87c0.12-0.07001,0.25995-0.10999,0.39996-0.10999c0.25,0,0.5,0.12,0.64001,0.34003   c0.01001,0.01996,0.02002,0.02997,0.02997,0.04999l2.46002,2.46997c2.36877-2.18079,2.62573-3.08142,3.41003-2.81   c-0.05573,0.10101,1.43427-2.599,1.48999-2.70001l0.02997-0.01996l-4.26001-7.67004   C23.20001,23.35999,22.95001,23.21002,22.67999,23.21002z"></path>
          <path d="M65.30023,56.98651c-13.35974-5.89917-30.03192-0.46509-33.82025,9.1535H65c0.41418,0,0.75-0.33582,0.75-0.75v-7.70746   C65.75,57.38354,65.57379,57.1073,65.30023,56.98651z"></path>
          <path d="M16.09998,4.67999V3.60999c0-0.40997,0.33002-0.75,0.75-0.75c0.41003,0,0.75,0.34003,0.75,0.75v1.07001   c0,0.41003-0.33997,0.75-0.75,0.75C16.42999,5.42999,16.09998,5.09003,16.09998,4.67999z"></path>
          <path d="M11.70001,17.28003c0.28998,0.29999,0.26996,0.77997-0.03003,1.06l-0.77997,0.72998   c-0.3075,0.28698-0.77471,0.25537-1.06-0.03003c-0.29004-0.31-0.27002-0.77997,0.02997-1.06L10.64001,17.25   C10.95001,16.96002,11.41998,16.97998,11.70001,17.28003z"></path>
          <path d="M22.15997,12.47003c0,2.92999-2.38995,5.31-5.32001,5.31c-2.91998,0-5.31-2.38-5.31-5.31c0-2.93005,2.39001-5.31,5.31-5.31   C19.77002,7.16003,22.15997,9.53998,22.15997,12.47003z"></path>
          <path d="M9.82001,12c-0.03003,0.40002-0.36005,0.70001-0.75,0.70001H9.02002L7.95001,12.63   c-0.41003-0.03003-0.72003-0.38-0.69-0.79999c0.01996-0.41003,0.38-0.73004,0.78998-0.70001L9.12,11.20001   C9.52997,11.22998,9.84998,11.59003,9.82001,12z"></path>
          <path d="M23.03998,18.84998c0.26001,0.32001,0.21002,0.80005-0.10999,1.06006c-0.35693,0.28038-0.82708,0.19244-1.04999-0.11005   l-0.67999-0.82001c-0.26001-0.31995-0.22003-0.79999,0.09998-1.06c0.32001-0.25995,0.79999-0.20996,1.06,0.11005L23.03998,18.84998   z"></path>
          <path d="M23.83002,6.96002l-0.78003,0.73999c-0.33832,0.29317-0.78802,0.25004-1.06-0.03998   C21.70001,7.35999,21.71997,6.88,22.02002,6.59998L22.79999,5.87c0.31-0.27997,0.78003-0.27002,1.06,0.03003   C24.14996,6.21002,24.13,6.67999,23.83002,6.96002z"></path>
          <path d="M10.64996,6.09003c-0.25995-0.32001-0.20996-0.80005,0.11005-1.06c0.32001-0.26001,0.78998-0.21002,1.06,0.10999   l0.66998,0.82001c0.27002,0.32996,0.21997,0.79999-0.09998,1.06c-0.34389,0.27013-0.81023,0.20637-1.06-0.10999L10.64996,6.09003z"></path>
          <path d="M26.44,13.10999C26.40997,13.51001,26.08002,13.81,25.69,13.81h-0.04999l-1.07001-0.07001   C24.15997,13.71002,23.83997,13.34998,23.87,12.94c0.02997-0.41998,0.39001-0.72998,0.79999-0.70001L25.73999,12.31   C26.14996,12.34003,26.45996,12.70001,26.44,13.10999z"></path>
          <path d="M17.59998,20.26001v1.07001c0,0.41998-0.33997,0.75-0.75,0.75c-0.41998,0-0.75-0.33002-0.75-0.75v-1.07001   c0-0.41003,0.33002-0.75,0.75-0.75C17.26001,19.51001,17.59998,19.84998,17.59998,20.26001z"></path>
          <path d="M38.06,56.94c-4.78009-4.4115-11.86365-6.72693-19.07794-6.88965c-0.00006-0.06097-0.00476-0.12317,0.00793-0.18036   c-0.01587,0.06036-0.01202,0.12024-0.01349,0.1803c-5.32257-0.1192-10.71576,0.92761-15.30835,3.19849   C3.41077,53.37598,3.25,53.64227,3.25,53.92932v11.46069c0,0.41418,0.33582,0.75,0.75,0.75h25.87l0.21002-0.54999   C31.45001,62.12,34.29999,59.13,38.06,56.94z"></path>
        </g>
      </g>
    </svg>
  );
}