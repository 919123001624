import { PUBLIC_SITE_URL } from 'constants/env';
import { geolocated } from 'react-geolocated';
import { connect } from 'react-redux';
import { setLoading } from '../../../actions/modal_actions';
import { login } from '../../../actions/session_actions';
import PageLoading from '../../../components/global_modal/hub_modal_pages/loading';

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
    setLoading: (boolean) => dispatch(setLoading(boolean)),
  };
};
const Homepage = (props) => {
  // const [inputValues, setInputValues] = useState({
  //   username: '',
  //   password: '',
  // });
  // const [pageInfos, setPageInfos] = useState({
  //   metaData: '',
  //   keywords: '',
  //   title: '',
  // });

  // const [loginErrors, setLoginErrors] = useState('');
  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };
  // useEffect(() => {
  //   if (props.isGeolocationEnabled && !props.coords) {
  //     props.setLoading(true);
  //   } else {
  //     props.setLoading(false);
  //   }
  // }, [props.coords]);
  // useEffect(() => {
  //   axios
  //     .patch('/api/ui/fetch_meta_data', { pageName: 'log-in' })
  //     .then((res) => {
  //       let title = res.data.metaData[0];
  //       let keywords = res.data.metaData[1];
  //       let metaData = res.data.metaData[2];
  //       setPageInfos({ metaData, keywords, title });
  //     });
  //   scrollTop();
  // }, []);
  // const handleOnChange = useCallback((event) => {
  //   const { name, value } = event.target;
  //   setInputValues({ ...inputValues, [name]: value });
  // });

  // const submitHanlder = async (e) => {
  //   e.preventDefault();
  //   navigator.sayswho = (function () {
  //     var ua = navigator.userAgent,
  //       tem,
  //       M =
  //         ua.match(
  //           /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
  //         ) || [];
  //     if (/trident/i.test(M[1])) {
  //       tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
  //       return 'IE ' + (tem[1] || '');
  //     }
  //     if (M[1] === 'Chrome') {
  //       tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
  //       if (tem !== null)
  //         return tem
  //           .slice(1)
  //           .join(' ')
  //           .replace('OPR', 'Opera')
  //           .replace('Edg ', 'Edge ');
  //     }
  //     M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  //     if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
  //     return M.join(' ');
  //   })();

  //   let OSName = 'Unknown OS';
  //   if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
  //   if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
  //   if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
  //   if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';

  //   // console.log(await publicIp.v4());

  //   const deviceDetector = new DeviceDetector();
  //   const userAgent =
  //     'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36';
  //   const device = deviceDetector.parse(userAgent);
  //   let user;
  //   user = {
  //     username: inputValues.username.trim(),
  //     password: inputValues.password,
  //     web_browser: navigator.sayswho,
  //     operating_system: OSName,
  //     device_type: device.device.type,
  //   };

  //   if (props.isGeolocationAvailable) {
  //     if (props.isGeolocationEnabled) {
  //       // props.setLoading(true);
  //       // if (!props.coords) {
  //       //   while (!props.coords) {
  //       //     props.setLoading(true);
  //       //   }
  //       // } else {
  //       // props.setLoading(false);
  //       if (props.coords) {
  //         user.latitude = props.coords.latitude;
  //         user.longitude = props.coords.longitude;
  //       }
  //       // }
  //     } else {
  //       window.alert(
  //         'For better services with Lodestar Hub. Please enable location services.',
  //       );
  //       user.latitude = null;
  //       user.longitude = null;
  //     }
  //   } else {
  //     window.alert('Geolocation unavailable in this area');
  //   }

  //   let path = '/hub/dashboard';
  //   // axios.post("/api/e/signin_placeholder", user)
  //   props.login(user).then((res) => {
  //     if (res.error) {
  //       return setLoginErrors(res.message);
  //     }
  //     if (
  //       res.userInfo.account_status_rid === '5' ||
  //       res.userInfo.account_status_rid === '2'
  //     ) {
  //       props.history.push('/hub/dashboard');
  //     } else {
  //       props.history.push('/hub/dashboard');
  //     }
  //     //
  //   });
  // };
  function RedirectToLodestarHome() {
    window.location.replace(PUBLIC_SITE_URL);
  }
  return (
    // <div className="page-container">
    //   <Helmet>
    //     <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
    //     <meta
    //       name="description"
    //       content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
    //     />
    //     <meta
    //       name="keywords"
    //       content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
    //     />
    //   </Helmet>
    //   <NavBar />
    //   <div
    //     className="page-content-wrapper"
    //     style={{ justifyContent: 'center' }}
    //     id="log-in-page-wrapper"
    //   >
    //     <img
    //       alt="log-in-page-banner"
    //       src="https://storage.googleapis.com/lodestar-vpc-host-nonprod.appspot.com/app_media/app_background_image/app_background.jpg"
    //       className="login-page-background-image"
    //     />
    //     <form
    //       id="log-in-form"
    //       onSubmit={(e) => {
    //         submitHanlder(e);
    //       }}
    //     >
    //       <div className="log-in-block" id="log-in-block-mobile">
    //         <div
    //           className="log-in-error-wrapper"
    //           style={{
    //             display: 'flex',
    //             width: '100%',
    //             justifyContent: 'center',
    //           }}
    //         >
    //           <span
    //             style={{
    //               color: '#FFFFFF',
    //               fontWeight: 'bolder',
    //               textAlign: 'center',
    //             }}
    //           >
    //             {loginErrors.length > 0 ? (
    //               <span className="animate__animated animate__shakeX delay-1s">
    //                 {loginErrors}
    //                 <br />
    //                 <br />
    //               </span>
    //             ) : null}
    //           </span>
    //         </div>
    //         <div className="newsletter-inputs login-input-dist">
    //           <FloatingLabelInput
    //             className="floating-lode-input"
    //             id="lode-float-input"
    //             type="text"
    //             onChange={handleOnChange}
    //             name="username"
    //             value={inputValues.username}
    //             label="Username or Email"
    //           />
    //         </div>
    //         <div className="newsletter-inputs login-input-dist">
    //           <FloatingLabelInput
    //             className="floating-lode-input"
    //             id="lode-float-input"
    //             type="password"
    //             onChange={handleOnChange}
    //             name="password"
    //             value={inputValues.password}
    //             label="Password"
    //           />
    //         </div>
    //         <button
    //           className={`login-page-login ${
    //             inputValues.username.length === 0 ||
    //             inputValues.password.length === 0
    //               ? ''
    //               : 'animate-button-slideright'
    //           } `}
    //           type="submit"
    //           disabled={
    //             inputValues.username.length === 0 ||
    //             inputValues.password.length === 0
    //           }
    //         >
    //           <span>LOG IN</span>
    //         </button>
    //         <br />
    //         <br />
    //         <div className="link-to-signup">
    //           <span>Not a member yet?</span>
    //         </div>
    //         {/* <SignUpBlock text="SIGN UP" linksTo="/sign-up" styles={{ marginTop: "2px" }} />*/}
    //         <SignupBlock text="SIGN UP" />
    //         <br />
    //         <br />
    //         <div className="forgot-username-container">
    //           <Link
    //             // to="/email-reset-password"
    //             onClick={() => {
    //               window.location = 'http://localhost:4000/reset-password';
    //             }}
    //           >
    //             <span>Forgot your username or password?</span>
    //           </Link>
    //         </div>
    //       </div>
    //     </form>
    //   </div>

    //   <Footer />
    // </div>
    <>
      <PageLoading />
      <RedirectToLodestarHome />
    </>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
  geolocationProvider: navigator.geolocation,
})(connect(null, mapDispatchToProps)(Homepage));
