export const svgVolunteer = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      id="time"
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
    >
      <path
        className={className}
        id="Path_331"
        data-name="Path 331"
        d="M12,6.423a2.423,2.423,0,1,1,4.846,0V24.8l6.543,6.544a2.423,2.423,0,0,1-3.426,3.427l-7.255-7.254A2.415,2.415,0,0,1,12,25.7Z"
        transform="translate(17.076 5.692)"
        fill={color}
      />
      <path
        className={className}
        id="Path_332"
        data-name="Path 332"
        d="M31.5,63A31.5,31.5,0,1,0,0,31.5,31.5,31.5,0,0,0,31.5,63Zm0-4.846A26.654,26.654,0,1,0,4.846,31.5,26.654,26.654,0,0,0,31.5,58.154Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};