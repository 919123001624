import CardDescription from '@components/card/card-description';
import NavLink from '@components/nav/NavLink';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
} from '@mui/material';

function PartnerCard({
  name,
  image,
  description,
  link,
  elevation = 0,
  externalLink = true,
  backgroundColor = 'background.primary',
}) {
  return (
    <Card
      elevation={elevation}
      sx={{
        height: 1,
        backgroundColor,
        maxWidth: { sm: '400px' },
        borderRadius: 1,
      }}
    >
      <CardMedia
        component="img"
        image={image}
        sx={{ height: '200px' }}
        style={{ height: '200px', objectFit: 'contain' }}
      />
      <CardContent>
        <Divider
          sx={{
            borderBottomWidth: 3,
            borderColor: 'text.primary',
            marginY: 2,
          }}
        />
        <Description
          external={externalLink}
          description={description}
        ></Description>
        <CardActions>
          <PartnerLink link={link}></PartnerLink>
        </CardActions>
      </CardContent>
    </Card>
  );
}

function Description(props) {
  return (
    <Box height={150}>
      <CardDescription lineCount={5}>{props.description}</CardDescription>
    </Box>
  );
}

function PartnerLink(props) {
  return (
    <Box display="flex" justifyContent={'center'} width={1}>
      <NavLink external={true} href={props.link}>
        Learn more
      </NavLink>
    </Box>
  );
}
export default PartnerCard;
