import axios from 'axios';

export const postAdventureBlock = (account_rid, adventure_rid, data) => {
  return axios.post(
    `/api/b/post_adventure_block/${account_rid}/${adventure_rid}`,
    data,
  );
};

export const fetchUserBlockCategory = (account_rid) => {
  return axios.patch(`/api/b/return_user_block_category/${account_rid}`);
};

export const postUserBlockCategory = (account_rid, data) => {
  return axios.post(`/api/b/post_user_block_category/${account_rid}`, data);
};

export const postUserBlock = (data, account_rid) => {
  return axios.post(`/api/b/user_block/${account_rid}`, data);
};

export const fetchUserBlockActivity = (account_rid, block_category_rid) => {
  return axios.patch(
    `/api/b/return_user_block_activity/${account_rid}/${block_category_rid}`,
  );
};

export const postUserBlockActivity = (account_rid, data) => {
  return axios.post(`/api/b/post_user_block_activity/${account_rid}`, data);
};

export const fetchUserBlockActivityDetail = (
  account_rid,
  block_activity_rid,
) => {
  return axios.patch(
    `/api/b/return_user_block_activity_detail/${account_rid}/${block_activity_rid}`,
  );
};

export const postUserBlockActivityDetail = (account_rid, data) => {
  return axios.post(`/api/b/post_user_activity_detail/${account_rid}`, data);
};

export const fetchBlockDetails = (block_rid) => {
  return axios.get(`/api/b/block-details/${block_rid}/null`);
};

export const fetchUserBlocks = (account_rid) => {
  return axios.patch(`/api/b/return_user_blocks/${account_rid}`);
};

export const fetchBlocksDynamic = (account_rid, data) => {
  return axios.patch(`/api/b/return_blocks/${account_rid}`, data);
};

export const fetchBlockFiles = (block_rid) => {
  // return axios.patch(`/api/b/fetch_block_files/255`)
  return axios.patch(`/api/b/fetch_block_files/${block_rid}`);
};

export const postBlockFile = (data) => {
  return axios.post(`/api/b/post_block_file`, data);
};

export const createScreenShot = (data) => {
  return axios.post('/api/b/screenshot', data);
};

export const deleteBlockFile = (blockFileRid, blockRid) => {
  return axios.patch(`/api/b/delete_block_file/${blockFileRid}/${blockRid}`);
};

export const deleteBlock = (data, account_rid) => {
  return axios.patch(`/api/b/delete_block/${account_rid}`, data);
};

export const copyBlock = (data) => {
  return axios.patch('/api/b/copy_block', data);
};

export const copyAndReturnNew = (data) => {
  return axios.patch(`/api/b/copy-return-one`, data);
};

export const moveToAdventureBlock = (data) => {
  return axios.patch('/api/b/move_to_adventure_block', data);
};

export const upsertBlockVote = (data) => {
  return axios.patch('/api/b/upsert_block_vote', data);
};

export const deleteBlockVote = (
  explorerVoteRid,
  explorerRid,
  itineraryBlockRid,
) => {
  return axios.delete(
    `/api/b/block_vote/${explorerVoteRid}/${explorerRid}/${itineraryBlockRid}`,
  );
};

export const fetchBlockVote = (itineraryBlockRid, explorerRid) => {
  return axios.patch(`/api/b/block_vote/${itineraryBlockRid}/${explorerRid}`);
};

export const fetchBlockComment = (itinerary_block_rid) => {
  return axios.patch(`/api/b/block_comments/${itinerary_block_rid}`);
};

export const postBlockComment = (data) => {
  return axios.post(`/api/b/block_comments`, data);
};

export const deleteBlockComment = (
  itinerary_block_rid,
  explorer_comment_rid,
) => {
  return axios.delete(
    `/api/b/block_comments/${itinerary_block_rid}/${explorer_comment_rid}`,
  );
};

export const fetchBlockCommentCount = (itinerary_block_rid) => {
  return axios.patch(`/api/b/block_comment_count/${itinerary_block_rid}`);
};

export const fetchBlockVotesAll = (itinerary_block_rid) => {
  return axios.patch(`/api/b/block_votes/all/${itinerary_block_rid}`);
};

export const deleteAdventureBlock = (
  adventure_block_rid,
  adventure_rid,
  account_rid,
  explorer_rid,
  adventure_rid_exist_flag,
) => {
  return axios.delete(
    `/api/b/adventure_blocks/${adventure_block_rid}/${adventure_rid}/${account_rid}/${explorer_rid}/${adventure_rid_exist_flag}`,
  );
};

export const fetchBlockLists = (
  block_rid,
  explorer_rid,
  adventure_block_rid,
) => {
  return axios.patch(
    `/api/b/block_list/${block_rid}/${explorer_rid}/${adventure_block_rid}`,
  );
};

export const upsertBlocksDynamic = (account_rid, block) => {
  return axios.post(`/api/b/${account_rid}`, block);
};

export const fetchAdventureBlockComments = (adventure_block_rid) => {
  return axios.patch(`/api/b/adventure_block_comments/${adventure_block_rid}`);
};

export const upsertAdventureBlockComment = (data) => {
  return axios.post(`/api/b/adventure_block_comments`, data);
};

export const deleteAdventureBlockComment = (
  adventure_block_rid,
  adventure_block_comment_rid,
) => {
  return axios.delete(
    `/api/b/adventure_block_comments/${adventure_block_rid}/${adventure_block_comment_rid}`,
  );
};

export const fetchAdventureBlockVotes = (adventure_block_rid, explorer_rid) => {
  return axios.patch(
    `/api/b/adventure_block_votes/${adventure_block_rid}/${explorer_rid}`,
  );
};

export const upsertAdventureBlockVotes = (data) => {
  return axios.post(`/api/b/adventure_block_votes`, data);
};

export const deleteAdventureBlockVotes = (
  adventure_block_rid,
  adventure_block_vote_rid,
  explorer_rid,
) => {
  return axios.delete(
    `/api/b/adventure_block_votes/${adventure_block_rid}/${adventure_block_vote_rid}/${explorer_rid}`,
  );
};

export const upsertAdventureBlock = (data) => {
  return axios.post('/api/b/adventure_block', data);
};

export const fetchBlockPeopleAssignment = (adventure_block_rid) => {
  return axios.patch(`/api/b/block_assignment/${adventure_block_rid}`);
};

export const upsertBlockAssignment = (data) => {
  return axios.post(`/api/b/block_assignment`, data);
};

export const deleteBlockAssignment = (
  block_assignment_rid,
  adventure_block_rid,
  adventure_friend_rid,
) => {
  return axios.delete(
    `/api/b/block_assignment/${block_assignment_rid}/${adventure_block_rid}/${adventure_friend_rid}`,
  );
};

export const checkIsAdventureBlock = (block_rid, adventure_rid) => {
  return axios.patch(`/api/b/is_adventure_block/${block_rid}/${adventure_rid}`);
};

export const insertBlockAndReturn = (data) => {
  return axios.post(`/api/b/single-block`, data);
};

export const fetchAdventureBlockList = (data) => {
  return axios.patch('/api/b/adventure_block_list', data);
};

export const fetchAdventureBlockItineraryItemRids = (adventureBlockRid) => {
  return axios.patch(`/api/b/itinerary_item_rid/${adventureBlockRid}`);
};

export const copyBlockAndAddToItineraryItems = (data) => {
  return axios.post('/api/b/location_block', data);
};

export const upsertBlockMap = (data) => {
  return axios.post(`/api/b/map-block`, data);
};

export const fetchAllBlocksAsLists = (account_rid, block_rid, list_flag) => {
  return axios.patch(
    `/api/b/library-block-list/${account_rid}/${block_rid}/${list_flag}`,
  );
};

export const fetchAllAdventureBlocksAsLists = (
  account_rid,
  adventure_rid,
  block_rid,
  list_flag,
) => {
  return axios.patch(
    `/api/b/library-block-list-adventure/${account_rid}/${adventure_rid}/${block_rid}/${list_flag}`,
  );
};

export const fetchBlockListDropdown = (accountRid, adventureRid, blockRid) => {
  return axios.patch(
    `/api/b/lists/dropdown/${accountRid}/${adventureRid}/${blockRid}`,
  );
};

export const fetchTemplateBlocks = (parentBlockRid) => {
  return axios.get(`/api/b/template_blocks/${parentBlockRid}`);
};

export const copyTemplateBlock = (data) => {
  return axios.post(`/api/b/template_blocks/copy`, data);
};

export const fetchFolderContents = (block_rid) => {
  return axios.get(`/api/b/folder_content/${block_rid}`);
};

export const checkFamily = (block_rid) => {
  return axios.get(`/api/b/check_family/${block_rid}`);
};

export const fetchLibraryLocationBlocks = (account_rid) => {
  return axios.get(`/api/b/library_location_block/${account_rid}`);
};

export const updateBlockLocationPoint = (data) => {
  return axios.patch(`/api/b/block_location_point`, data);
};

export const fetchAssoicatedItemRids = (block_rid) => {
  return axios.get(`/api/b/associated-item-rids/${block_rid}`);
};

export const fetchItineraryBlockSpectators = (itinerary_block_rid) => {
  return axios.get(`/api/b/itinerary-block-spectator/${itinerary_block_rid}`);
};

export const upsertItineraryBlockSpectator = (data) => {
  return axios.post(`/api/b/itinerary-block-spectator`, data);
};

export const deleteItineraryBlockSpectator = (
  itinerary_block_spectator_rid,
  itinerary_block_rid,
) => {
  return axios.delete(
    `/api/b/itinerary-block-spectator/${itinerary_block_spectator_rid}/${itinerary_block_rid}`,
  );
};

export const fetchTravelMethodBlockCategories = () => {
  return axios.get(`/api/b/block-category/travel-methods`);
};

export const fetchUserAddressBookBlocks = (account_rid) => {
  return axios.get(`/api/b/address-book/${account_rid}`);
};

export const insertBlockToTripBoardWithTransportation = (data) => {
  return axios.post('/api/b/insert-to-trip-board-impact', data);
};
