import { useQuery } from '@tanstack/react-query';
import { receiveItineraries } from 'actions/itinerary_actions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItineraryDetail } from 'util/api_util/itinerary_api_util';

// fetches all itiems in an itnerary, along with the blocks for each item
const useItineraryItems = (itineraryRid) => {
  // dispatch data for backwards compatability
  const explorerRid = useSelector((state) => state.session.user.explorer_rid);
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['itinerary', itineraryRid, 'items'],

    queryFn: () =>
      fetchItineraryDetail(itineraryRid, explorerRid).then((res) => {
        dispatch(receiveItineraries(res.data));
        return res.data.itineraryDetails;
      }),
    enabled: Boolean(itineraryRid),
  });
};

export default useItineraryItems;
