import React, { Component } from 'react';
import styled from '@emotion/styled';
import { hexToRGBA, getByPath } from '../util';
import * as PropTypes from 'prop-types';
import { LIB_NAME } from '../constants';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import moment from 'moment';
class Item extends Component {
  item = React.createRef();

  componentDidUpdate() {
    if (this.props.state.cursor === this.props.itemIndex) {
      this.item.current &&
        this.item.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }
  }

  render() {
    const {
      props,
      state,
      methods,
      item,
      itemIndex,
      displayCustomTripDates,
    } = this.props;
    if (props.itemRenderer) {
      return props.itemRenderer({ item, itemIndex, props, state, methods });
    }

    if (!props.keepSelectedInList && methods.isSelected(item)) {
      return null;
    }

    return (
      <ItemComponent
        role="option"
        ref={this.item}
        aria-selected={methods.isSelected(item)}
        aria-disabled={item.disabled}
        aria-label={getByPath(item, props.labelField)}
        disabled={item.disabled}
        key={`${getByPath(item, props.valueField)}${getByPath(
          item,
          props.labelField,
        )}`}
        tabIndex="-1"
        className={`${LIB_NAME}-item ${
          methods.isSelected(item) ? `${LIB_NAME}-item-selected` : ''
        } ${state.cursor === itemIndex ? `${LIB_NAME}-item-active` : ''} ${
          item.disabled ? `${LIB_NAME}-item-disabled` : ''
        } activity-list-wrapper
        ${item.level === 1 && 'bold-item-category'} 
        `}
        onClick={item.disabled ? undefined : () => methods.addItem(item)}
        onKeyPress={item.disabled ? undefined : () => methods.addItem(item)}
        color={props.color}
        displayCustomTripDates={displayCustomTripDates}
        item={item}
        data-text={`${moment(item.start_date).format(
          'MMM DD, YYYY',
        )} - ${moment(item.end_date).format('MMM DD, YYYY')}`}
        content="attr(data-text)"
      >
        {displayCustomTripDates ? (
          <span>
            <span className="dropdown-adventure-item dropdown-adventure-item--adventure-name">
              {item.adventure_name}
            </span>{' '}
          </span>
        ) : (
          getByPath(item, props.labelField)
        )}{' '}
        {typeof svgLibrary[item.icon_name] === 'function' &&
          svgLibrary[item.icon_name](
            '',
            'activities-svg',
            'activities-svg-outer',
          )}
        {item.disabled && <ins>{props.disabledLabel}</ins>}
      </ItemComponent>
    );
  }
}

Item.propTypes = {
  props: PropTypes.any,
  state: PropTypes.any,
  methods: PropTypes.any,
  item: PropTypes.any,
  itemIndex: PropTypes.any,
};

const ItemComponent = styled.span`
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  content: attr(data-text);
  &.${LIB_NAME}-item-active {
    border-bottom: 1px solid #fff;
    ${({ disabled, color }) =>
      !disabled && color && `background: ${hexToRGBA(color, 0.1)};`}
  }
  :hover,
  :focus {
    background: ${({ color }) => color && hexToRGBA(color, 0.1)};
    outline: none;
  }
  &.${LIB_NAME}-item-selected {
    ${({ disabled, color }) =>
      disabled
        ? `
    background: #f2f2f2;
    color: #ccc;
    `
        : `
    background: ${color};
    color: #fff;
    border-bottom: 1px solid #fff;
    `}
  }
  ${({ disabled }) =>
    disabled
      ? `
    background: #f2f2f2;
    color: #ccc;
    ins {
      text-decoration: none;
      border:1px solid #ccc;
      border-radius: 2px;
      padding: 0px 3px;
      font-size: x-small;
      text-transform: uppercase;
    }
    `
      : ''}
`;

export default Item;
