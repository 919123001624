import {
  openBlockModal,
  openCheckListModal,
  openFolderModal,
} from 'actions/modal_actions';
import category from 'components/global_modal/hub_modal_pages/create_edit_block/category';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlockDetails } from 'util/api_util/block_api_util';

const useEditListModalButton = (blockRid) => {
  const userInfo = useSelector((state) => state?.session?.user);
  const { itinerary_info, itineraryDetails } = useSelector(
    (state) => state?.entities?.itinerary,
  );

  const adventure_rid = useSelector(
    (state) => state?.entities?.adventure?.selectedAdventure?.adventure_rid,
  );

  const dispatch = useDispatch();

  const openModal = async () => {
    const block = await fetchBlockDetails(blockRid).then(
      (res) => res.data?.blockDetails?.[0],
    );
    if (!block)
      throw new Error(
        'Error fetching list block details for modal open:',
        blockRid,
      );

    // I'm not sure if list blocks can be folders.
    // At this time im assuming they are not
    let openType = {
      isEditAndView: true,
      isFolder: false,
      isListFolder: false,
    };

    let blockType = {
      isItineraryItemBlock: false,
      isAdventureBlock: true,
      isNestedItineraryItemBlock: Boolean(block.parent_block_rid),
    };

    let actionPayload = {
      itineraryItemRid: block.itinerary_item_rid,
      adventure_block_rid: block.adventure_block_rid,
      parentBlockRid:
        blockType.isNestedItineraryItemBlock && block.parent_block_rid,
      adventureRid: adventure_rid,
      itineraryInfo: itinerary_info,
    };

    dispatch(
      openCheckListModal(openType, blockType, actionPayload, block, userInfo),
    );
  };

  return { openModal };
};

export default useEditListModalButton;
