export const svgThumbsUp = (color, className, outerLayer, width, height) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 67.758, 68.752"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M9.375,68.75 C4.197,68.75 -0,64.553 -0,59.375 L-0,37.5 C-0,32.322 4.197,28.125 9.375,28.125 L16.719,28.125 L28.394,1.856 C28.896,0.727 30.015,0 31.25,0 C38.154,0 43.75,5.596 43.75,12.5 L43.75,21.875 L58.277,21.875 C61.028,21.844 63.653,23.022 65.458,25.098 C67.263,27.174 68.065,29.937 67.651,32.661 L63.34,60.781 C62.639,65.403 58.639,68.803 54,68.751 L18.75,68.751 C18.729,68.751 18.709,68.75 18.688,68.75 L9.375,68.75 z"
          fill={color}
        />
      </g>
    </svg>
  );
};