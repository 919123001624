import React from 'react';
import styled from 'styled-components';
import './styles.scss';
import CurrencyFormat from '../currency_format';
const FloatingLabelInput = styled.div`
  width: 100%;
`;

const FloatingLabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 2em;
  border-bottom: 1px solid #ddd;
  font-size: inherit;
`;

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: #9b9b9b;
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 1em;
  cursor: text;
  pointer-events: none;
  width: 66.6%;
  transform: ${(props) =>
    props.active ? 'translate3d(0, -70%, 0) scale(0.70)' : 'none'};
`;

const FloatingInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1em;
  &::placeholder {
    color: #9b9b9b;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!props.id && !props.name) {
      throw new Error('expected id but none present');
    }

    this.state = {
      active: props.value && props.value.length > 0,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.active &&
      this.props.value &&
      prevProps.value !== this.props.value
    ) {
      this.setState({ active: true });
    }
  }

  onFocus(event) {
    this.setState({ active: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  onBlur(event) {
    this.setState({ active: event.target.value.length !== 0 });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  render() {
    const {
      id,
      label,
      onBlur,
      onFocus,
      type,
      refs,
      className,
      defaultActive,
      step,
      min,
      ...otherProps
    } = this.props;
    const { active } = this.state;

    return (
      <FloatingLabelInput>
        <FloatingLabelInputContainer className={className}>
          <FloatingLabel
            className={`${className} ${
              this.props.important && 'important-text'
            }`}
            htmlFor={id}
            active={defaultActive ? defaultActive : active}
          >
            {label}
          </FloatingLabel>
          {type === 'money' ? (
            <CurrencyFormat
              thousandSeparator={true}
              prefix={'$'}
              value={!otherProps.value ? '' : otherProps.value}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={otherProps.onChange}
              displayType="input"
              className="hello"
            />
          ) : type === 'formattedNumber' ? (
            <CurrencyFormat
              thousandSeparator={true}
              value={!otherProps.value ? '' : otherProps.value}
              isNumericString={true}
              decimalScale={0}
              fixedDecimalScale={true}
              onValueChange={otherProps.onChange}
              displayType="input"
            />
          ) : (
            <FloatingInput
              active={active}
              className={className}
              id={id}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              ref={refs}
              step={step}
              min={min}
              type={type === 'number' ? 'number' : type}
              pattern={type === 'number' ? '^d*(.d{0,2})?$' : undefined}
              {...otherProps}
            />
          )}
        </FloatingLabelInputContainer>
      </FloatingLabelInput>
    );
  }
}
