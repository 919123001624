export const LoopIcon = ({ color = "#273A07", className, outerLayer, width = 20, height = 20 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.4772 0 0 4.4772 0 10C0 15.523 4.4772 20 10 20C15.523 20 20 15.523 20 10C20 4.4772 15.523 0 10 0ZM9.47 8.51V4.2998C9.47 4.00776 9.70774 3.7698 9.99976 3.7698C10.2918 3.7698 10.5298 4.00754 10.5298 4.2998V6.8008H10.53V15.1522H9.46999L9.47 8.51ZM4.2276 7.64852L5.48432 7.095H9.16232V8.20226H5.48432L4.2276 7.64852ZM12.4872 16.8455H7.513V15.5895H12.4872V16.8455ZM14.516 6.49312H10.8378V5.38562H14.516L15.7727 5.93938L14.516 6.49312Z"
        fill={color}
      />
    </svg>
  )
};
