import { Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import HubModal from '../../modal';
import { mapDispatchToProps, mapStateToProps } from './container';
import Summary from './summary';

const CreatCheckList = ({
  user_block_categories,
  persistedModalData,
  closeModal,
  openType,
  currentBlock,
  userInfo,
  blockLists,
  actionPayload,
  blockComments,
  openBlockFileModal,
  currentBlockFiles,
  deleteBlockFile,
  upsertBlock,
  persistBlockModalData,
  blockType,
  isAdventureBlock,
  fetchBoardBlocks,
  fetchBlockList,
  fetchUserBlockCategories,
  deleteBlock,
  moveToAdventureBlock,
  copyToTripBoard,
  postBlockComment,
  postAdventureBlockComment,
  fetchListDropDown,
  currentTab,
  upsertBlockNoReturn,

  //entries

  copyBlockAndAddToItineraryItems,
  // used for itinerary items,
  itineraryItemsAndBlockNames,
  fetchItineraryItemsAndBlockNames,

  updateLibraryBlock,
  updateAdventureBlockAndItinerary,
}) => {
  useEffect(() => {
    if (openType.isNew && blockType?.isAdventureBlock) {
      fetchItineraryItemsAndBlockNames(
        actionPayload.itineraryInfo?.itinerary_rid,
      );
    }
  }, []);
  const [triggerNext, setTriggerNext] = useState({
    display: false,
    newBlock: null,
  });

  const adventure_rid = useSelector(
    (state) => state.entities.adventure.selectedAdventure.adventure_rid,
  );
  const [nextTab, setNextTab] = useState(
    currentTab || (!openType.isNew ? 'ENTRIES' : 'SUMMARY'),
  );
  //ui related toggles
  const [uiTransitionToggles, setUiTransitionToggles] = useState({
    viewFullScreen: false,
    triggerCommentsButton: 'load',
    viewingList: currentBlock ? true : false,
  });
  const queryClient = useQueryClient();
  const [bottomLabel, setBottomLabel] = useState('COLOR');
  const [uiToggle, setUiToggle] = useState({
    addToAdventure:
      currentBlock && currentBlock.adventure_block_rid ? true : false,
  });
  const [parentBlockRid, setParentBlockRid] = useState(null);
  const [parentTripBlockRid, setParentTripBlockRid] = useState(null);
  const [inputValues, setInputValues] = useState(
    persistedModalData
      ? persistedModalData
      : {
          account_rid: userInfo.account_rid,

          block_rid: currentBlock ? currentBlock.block_rid : null,
          inspiration_rid: currentBlock ? currentBlock.inspiration_rid : null,
          adventure_rid: currentBlock ? currentBlock.adventure_rid : null,
          adventure_rid_exist_flag: currentBlock
            ? currentBlock.adventure_rid
            : null,
          adventure_block: currentBlock ? currentBlock.adventure_block : null,
          inspiration_block_rid: currentBlock
            ? currentBlock.inspiration_block_rid
            : null,
          itinerary_item_rid: currentBlock
            ? currentBlock.itinerary_item_rid
            : null,
          itinerary_rid: currentBlock ? currentBlock.itinerary_rid : null,
          parent_block_rid: currentBlock ? currentBlock.parent_block_rid : null,
          block_name: currentBlock ? currentBlock.block_name : '',
          block_description: currentBlock
            ? currentBlock.block_description
            : null,
          block_category_rid: currentBlock
            ? currentBlock.block_category_rid
            : null,
          block_activity_rid: currentBlock
            ? currentBlock.block_activity_rid
            : null,
          activity_detail_rid: currentBlock
            ? currentBlock.activity_detail_rid
            : null,
          block_count: currentBlock ? currentBlock.block_count : null,
          ui_color: null,
          ui_image_location: currentBlock
            ? currentBlock.ui_image_location
            : null,
          vote_flag:
            currentBlock && currentBlock.vote_flag
              ? currentBlock.vote_flag
              : true,
          list_flag: true,
          completed_flag:
            currentBlock && currentBlock.completed_flag
              ? currentBlock.completed_flag
              : false,
          comment_flag:
            currentBlock && currentBlock.comment_flag
              ? currentBlock.comment_flag
              : false,
          region_rid:
            currentBlock && currentBlock.region_rid
              ? currentBlock.region_rid
              : null,
          country_rid: currentBlock ? currentBlock.country_rid : null,
          state_providence_rid: currentBlock
            ? currentBlock.state_providence_rid
            : null,
          meta_data: currentBlock ? currentBlock.meta_data : null,
          location_pointer: '',
          createdFromNested: actionPayload.parentBlockRid ? true : false,
        },
  );
  const [comments, setComments] = useState({
    explorer_comment_rid: null,
    itinerary_block_rid: currentBlock
      ? currentBlock.itineriary_block_rid
      : null,
    explorer_rid: userInfo ? userInfo.explorer_rid : null,
    explorer_comment: null,
  });
  const [topLabel, setTopLabel] = useState('SUMMARY');
  const [selectedItineraryItems, setSelectedItineraryItems] = useState({});
  const handleSelectItineraryItem = (itineraryItem) => {
    if (selectedItineraryItems[itineraryItem.itinerary_item_rid]) {
      let cloned = merge({}, selectedItineraryItems);
      delete cloned[itineraryItem.itinerary_item_rid];
      setSelectedItineraryItems(cloned);
    } else {
      setSelectedItineraryItems({
        ...selectedItineraryItems,
        [itineraryItem.itinerary_item_rid]: itineraryItem,
      });
    }
  };
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleOnChangeComments = useCallback((event) => {
    const { name, value } = event.target;
    setComments({ ...comments, [name]: value });
  });
  const uiToggleHandler = (name, value) => {
    setUiToggle({ ...uiToggle, [name]: !value });
  };

  const invalidateDependentQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ['adventure', adventure_rid, 'blocks'],
    });
    queryClient.invalidateQueries({
      queryKey: ['itinerary'],
    });
  };
  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };
  const transitionHandler = (newBlock) => {
    let openType = {
      isSaving: true,
    };

    let actionPayload = {
      savingModalData: inputValues,
    };
    persistBlockModalData(openType, actionPayload);

    openType = {
      isEditAndView: true,
    };

    actionPayload = {
      currentBlock: newBlock,
    };
    persistBlockModalData(openType, actionPayload);
  };
  const handleCategorySelect = useCallback((event) => {
    const { block_category_rid } = event[0];
    setInputValues({ ...inputValues, block_category_rid });
  });
  const handleParentBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      setParentBlockRid(event[0].block_rid);
    } else {
      setParentBlockRid(null);
    }
  });
  const handleParentTripBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      setParentTripBlockRid(event[0].block_rid);
    } else {
      setParentTripBlockRid(null);
    }
  });
  const handleNext = async (e) => {
    if (openType.isNew) {
      if (topLabel === 'ENTRIES') {
        if (openType.isFromMenu) {
          invalidateDependentQueries();
          closeModal();
        }
        //setTopLabel('TRIP PLAN');
        return;
      }
    }
    const { block_name, block_category_rid } = inputValues;
    if (!block_name || !block_category_rid) return;
    let passOver = merge({}, inputValues);
    // if (openType.isTransitionWithTab) {
    //   if (inputValues.block_rid || inputValues.adventure_block_rid) {
    //     openType.isEditAndView = true;
    //   }
    // }
    let block;
    //if statements will separate the b
    let returnActionPayload = {
      adventureRid: actionPayload.adventureRid,
    };
    // HANDLE LIBRARY BLOCK
    if (blockType.isLibraryBlock) {
      if (parentBlockRid) passOver['parent_block_rid'] = parentBlockRid;
      returnActionPayload = {
        parentBlockRid: actionPayload.parentBlockRid ? parentBlockRid : null,
        isLibraryListOnly: true,
      };
      if (openType.isNew) {
        // HANDLE NEW LIBRARY BLOCK
        if (blockType.isNestedLibraryBlock)
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        // HANDLE EXISTING LIBRARY BLOCK
        if (blockType.isNestedLibraryBlock)
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
      }
    } else if (blockType.isAdventureBlock) {
      // HANDLE ADVENTURE BLOCK
      passOver['parent_block_rid'] = parentTripBlockRid;
      if (openType.isNew) {
        // HANDLE NEW ADVENTURE BLOCK
        passOver['adventure_rid'] = actionPayload.adventureRid;

        if (blockType.isNestedLibraryBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        }

        returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          adventureRid:
            actionPayload.adventureRid && actionPayload.adventureRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        // HANDLE EXISTING ADVENTURE BLOCK
        if (blockType.isNestedLibraryBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        }

        returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          adventureRid:
            actionPayload.adventureRid && actionPayload.adventureRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };
      }
    } else if (blockType.isItineraryItemBlock) {
      // HANDLE ITINERARY BLOCK
      if (openType.isNew) {
        // HANDLE NEW ITINERARY BLOCK
        passOver['itinerary_item_rid'] = actionPayload.itineraryItemRid;
        if (blockType.isNestedItineraryItemBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
          delete passOver.itinerary_item_rid;
        }
        returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          itineraryItemRid:
            actionPayload.itineraryItemRid && actionPayload.itineraryItemRid,
        };
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        // HANDLE EXISTING ITINERARY BLOCK
        if (blockType.isNestedItineraryItemBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        }
        if (parentTripBlockRid) {
          passOver['parent_block_rid'] = parentTripBlockRid;
        }
        returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          itineraryItemRid:
            actionPayload.itineraryItemRid && actionPayload.itineraryItemRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };
      }
    }
    //************ used when initally creating a new block for working onthe block */
    if (openType.isNew && !triggerNext.display) {
      let _blockType;
      if (blockType.isLibraryBlock) {
        _blockType = { isLibraryList: true, isLibraryBlock: true };
      } else {
        _blockType = { isAdventureList: true, isAdventureBlock: true };
      }
      block = await upsertBlock(
        userInfo,
        passOver,
        _blockType,
        returnActionPayload,
      );
      setTriggerNext({
        display: true,
        newBlock: block.data.singleBlock[0],
      });
      transitionHandler(block.data.singleBlock[0]);
      setInputValues({
        ...inputValues,
        block_rid: block.data.singleBlock[0].block_rid,
      });
    } else {
      if (passOver.adventure_block_rid) {
        blockType['updateAdventureAndItineraries'] = true;
      }
      await upsertBlockNoReturn(passOver).then(async (res) => {
        if (blockType.isLibraryBlock) {
          const blockUpdatePayload = {
            account_rid: userInfo.account_rid,
            adventureRid: actionPayload.adventureRid,
            adventure_rid_exist_flag: actionPayload.adventureRid ? true : false,
            explorer_rid: userInfo.explorer_rid,
            inspiration_rid: null,
            itinieraryInfo: null,
            itinerary_item_rid: null,
            parent_block_rid: parentBlockRid ?? null,
          };
          await updateLibraryBlock(blockUpdatePayload);
        } else {
          await updateAdventureBlockAndItinerary();
        }
      });
    }
    if (openType.isNew) {
      if (topLabel === 'SUMMARY') {
        setTopLabel('ENTRIES');
      }
    } else {
      invalidateDependentQueries();
      closeModal();
    }
  };

  const handleNextPage = (tabTitle) => {
    setNextTab(tabTitle);
  };

  const handleBlockCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentBlock.adventure_block_rid && !currentBlock.itinerary_item_rid) {
      let passOver = merge({}, comments);
      passOver['adventure_block_rid'] = currentBlock.adventure_block_rid;
      passOver['idx'] = currentBlock.idx;

      postAdventureBlockComment(passOver);
    } else {
      let passOver = merge({}, comments);
      passOver['idx'] = currentBlock.idx;
      passOver['itinerary_item_rid'] = currentBlock.itinerary_item_rid;
      postBlockComment(passOver);
    }
    setComments({
      explorer_comment_rid: null,
      itinerary_block_rid: currentBlock
        ? currentBlock.itineriary_block_rid
        : null,
      explorer_rid: userInfo ? userInfo.explorer_rid : null,
      explorer_comment: '',
    });
  };

  const handleTripPlanSubmit = async () => {
    let blockData = merge({}, triggerNext.newBlock);
    blockData['account_rid'] = userInfo.account_rid;
    blockData['explorer_rid'] = userInfo.explorer_rid;
    blockData['itineraryRid'] = actionPayload.itineraryInfo?.itinerary_rid;
    blockData['adventure_rid'] = actionPayload.itineraryInfo?.adventure_rid;

    await upsertBlock(userInfo, blockData, blockType, actionPayload).then(
      async () => {
        if (blockType.isLibraryBlock) {
          const displayDetails = {
            level: parentBlockRid ? parentBlockRid : 1,
            currentBlockRid: parentBlockRid ? parentBlockRid : null,
          };
          const libraryBlockUpdatePayload = {
            account_rid: userInfo.account_rid,
            adventureRid: actionPayload.adventureRid,
            adventure_rid_exist_flag: actionPayload.adventureRid ? true : false,
            explorer_rid: userInfo.explorer_rid,
            inspiration_rid: null,
            itinieraryInfo: null,
            itinerary_item_rid: null,
            parent_block_rid: parentBlockRid ?? null,
          };
          updateLibraryBlock(libraryBlockUpdatePayload);
        } else {
          await updateAdventureBlockAndItinerary();
        }
      },
    );
    // await copyBlockAndAddToItineraryItems(
    //   blockData,
    //   Object.keys(selectedItineraryItems),
    //   // this func is the callback to update the map blocks and replace dropped pin without data with new made block item#:f000001
    //   actionPayload.func,
    // );
    invalidateDependentQueries();
    return closeModal();
  };
  const {
    viewFullScreen,
    viewingList,
    triggerCommentsButton,
  } = uiTransitionToggles;
  return (
    <HubModal
      show={true}
      title="LIST"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={topLabel === 'ENTRIES' ? handleTripPlanSubmit : handleNext}
    >
      <Box className="create-adventure-wrapper" sx={{ minHeight: 450 }}>
        <Stack spacing={2}>
          {(topLabel === 'SUMMARY' || topLabel === 'ENTRIES') && (
            <Summary
              handleOnChange={handleOnChange}
              inputValues={inputValues}
              currentBlock={
                triggerNext.displayNext ? triggerNext.newBlock : currentBlock
              }
              uiToggle={uiToggle}
              uiToggleHandler={uiToggleHandler}
              switchHandler={switchHandler}
              bottomLabel={bottomLabel}
              isAdventureBlock={isAdventureBlock}
              openType={openType}
              blockType={blockType}
              moveToAdventureBlock={moveToAdventureBlock}
              actionPayload={actionPayload}
              userInfo={userInfo}
              copyToTripBoard={copyToTripBoard}
              user_block_categories={user_block_categories}
              handleCategorySelect={handleCategorySelect}
              setParentBlockRid={handleParentBlockSelect}
              setParentTripBlockRid={handleParentTripBlockSelect}
              openBlockFileModal={openBlockFileModal}
              currentBlockFiles={currentBlockFiles}
              deleteBlockFile={deleteBlockFile}
              transitionHandler={transitionHandler}
              newBlock={triggerNext.newBlock}
              comments={comments}
              handleOnChangeComments={handleOnChangeComments}
              blockComments={blockComments}
              upsertBlock={upsertBlock}
              list={blockLists}
              deleteBlock={deleteBlock}
              triggerNext={triggerNext}
              fetchListDropDown={fetchListDropDown}
              currentTab={currentTab}
              nextTab={nextTab}
              setNextTab={setNextTab}
              uiTransitionToggles={uiTransitionToggles}
              setUiTransitionToggles={setUiTransitionToggles}
            />
          )}
          {/* {topLabel === 'TRIP PLAN' && (
            <ItineraryItems
              itineraryItems={reformatItinerayItems(
                itineraryItemsAndBlockNames,
              )}
              selectedItineraryItems={selectedItineraryItems}
              handleSelectItineraryItem={handleSelectItineraryItem}
            />
          )} */}
        </Stack>
      </Box>
    </HubModal>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatCheckList);
