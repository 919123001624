import React, { useState, useEffect } from 'react';
import Footer from 'components/modules/footer';
import Banner from 'components/modules/banner_regularstack';
import { Helmet } from 'react-helmet';
import axios from 'axios';
const Homepage = (props) => {
  let splitted = props.location.pathname.split('/');
  let email = splitted[splitted.length - 1].split('_').join('@');
  let subscription_rid = splitted[splitted.length - 2];
  let body = {
    email: `${email}.com`,
  };
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data', { pageName: 'email-verified' })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
    axios.patch(`/api/e/verify_email/${subscription_rid}`, body);
  }, []);
  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });

  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div className="page-content-wrapper">
        <Banner
          photo="/banner_images/lodestar_email_confirmation_banner.jpeg"
          bigText={
            <span>
              YOU'RE GOOD <br />
              TO GO!
            </span>
          }
          style={{ color: '#09132B' }}
        />
        <div className="sign-up-success-content-body">
          <h1
            className="sign-up-success-content-header"
            style={{ color: 'white' }}
          >
            Your email address {email}.com has been confirmed
          </h1>
          <div className="sign-up-success-big-text">
            <span>
              Thank you for signing up!
              <br />
              Follow us on{' '}
              <a
                rel="noopener noreferrer"
                className="instagram-link"
                href="https://instagram.com/lodestarhub"
                target="_blank"
              >
                Instagram
              </a>
              , tell all your friends, only good things can happen.
            </span>
          </div>

          <div className="sign-up-small-texts">
            <span>
              Drop us a note at{' '}
              <a
                rel="noopener noreferrer"
                className="contact-link"
                href="mailto:support@lodestarhub.com"
                target="_blank"
              >
                support@lodestarhub.com
              </a>{' '}
              with any questions.
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
