export const svgCopyToTripBoard = (
  color,
  className,
  outerLayer,
  width = 15,
  height = 15,
) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 90, 90"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          d="M43.104,69.457 C43.217,69.504 43.336,69.527 43.455,69.564 C43.643,69.626 43.832,69.697 44.031,69.736 C44.313,69.792 44.599,69.814 44.885,69.822 C44.925,69.823 44.961,69.834 45,69.834 C45.001,69.834 45.002,69.834 45.003,69.834 C45.333,69.834 45.663,69.799 45.987,69.734 C46.092,69.713 46.19,69.672 46.293,69.642 C46.506,69.585 46.72,69.533 46.925,69.447 C47.031,69.402 47.125,69.34 47.228,69.289 C47.417,69.193 47.609,69.103 47.789,68.984 C47.884,68.921 47.963,68.838 48.053,68.767 C48.219,68.64 48.389,68.521 48.542,68.369 C48.582,68.329 48.607,68.281 48.646,68.241 L59.136,57.75 C61.089,55.799 61.089,52.633 59.136,50.68 C57.184,48.727 54.018,48.727 52.067,50.68 L50,52.746 L50,5 C50,2.238 47.762,0 45,0 C42.238,0 40,2.238 40,5 L40,52.757 L37.936,50.694 C35.983,48.74 32.817,48.74 30.865,50.692 C28.912,52.645 28.914,55.811 30.865,57.762 L41.43,68.329 C41.678,68.582 41.951,68.808 42.246,69.004 C42.292,69.033 42.342,69.053 42.389,69.081 C42.617,69.223 42.852,69.354 43.104,69.457 z"
          fill={color}
          className={className}
        />
        <path
          d="M85,52.112 C82.238,52.112 80,54.35 80,57.112 L80,80 L10,80 L10,57.112 C10,54.35 7.761,52.112 5,52.112 C2.239,52.112 0,54.35 0,57.112 L0,85 C0,85.346 0.035,85.683 0.102,86.008 C0.568,88.286 2.583,90 5,90 L85,90 C87.762,90 90,87.762 90,85 L90,57.112 C90,54.351 87.762,52.112 85,52.112 z"
          fill={color}
          className={className}
        />
      </g>
    </svg>
  );
};