import React, { useState } from 'react';
import {
  ModalWrapper,
  ModalStatusBar,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from './modal_layout';
import { closeModal } from '../../actions/modal_v2.actions';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGrourp } from 'react-transition-group';
import { STOP_MODAL, IMPACT_MODAL } from '../../constants';
import PopUpOverlay from '../global_modules/pop_up';
const Modal = ({ modal, payload, ...props }) => {
  let component;
  const [page, setPage] = useState();
  const buttonHandler = (curredFunc) => (e) => {};
  return (
    <div
      className={
        modal.action === STOP_MODAL
          ? 'modal-background-closed'
          : 'modal-background'
      }
      // onClick={this.props.closeModal}
    >
      <ModalWrapper>
        {/* <ModalHeader /> */}
        {/* <ModalBody>{component}</ModalBody> */}
        {/* <ModalFooter /> */}
      </ModalWrapper>
    </div>
  );
};

const mapStateToProps = ({ ui, session }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;

  return {
    modal: ui.modalv2,
    currentUser: session.user,
    isAuthenticated: session.isAuthenticated,
    popup: ui.popup,
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    // goBackPrevModal: (data) => dispatch(goBackPrevModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
