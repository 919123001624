export const SiteIcon = ({ color = "#273806", className, outerLayer, width = 25, height = 25 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4065 16.0796L23.5379 17.2424L12.9515 3.06607L14.9149 0.436358L14.3229 0L12.4863 2.46L10.6497 0L10.0583 0.436358L12.0287 3.07371L1.4595 17.2427L0.59146 16.0792L0 16.5155L1.00117 17.8571H8.77779L12.4997 12.2611L16.2228 17.8568H23.9988L25 16.5152L24.4065 16.0796ZM16.585 17.4909L12.4983 11.3477L8.41098 17.4915L6.67213 14.1311L12.4979 11.3475L18.3243 14.1311L16.585 17.4909Z"
        fill={color}
      />
    </svg>
  )
};