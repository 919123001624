import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Box } from '@mui/material';
import TripFsLightBox from './trip-fslightbox';
import SwiperCarousel from '../../swiper-carousel/swiper-carousel';
import VideoCard from './video-card';
import ImageCard from './image-card';
import { makeStyles } from '@material-ui/core';
import { styleValues } from '../../../pages/pwa_hub/adventure/components/constants';

const useStyles = makeStyles((theme) => styleValues(theme));

const MediaSliderWithPreview = ({images, width, muted, className, uniqueKey}) => {
  const [fullScreen, setFullScreen] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [caption, setCaption] = useState('');
  const classes = useStyles();
  const handleShowFullScreen = (index) => {
    setFullScreen(index);
  };

  return (
    <Box sx={{width: width}}>
      {ReactDOM.createPortal(
        fullScreen >= 0 ? (
          <TripFsLightBox
            onClose={() => setFullScreen(-1)}
            classes={classes}
            images={images}
            activeIndex={fullScreen}
          />
        ) : (
          <></>
        ),
        document.getElementById('root'),
      )}
      <SwiperCarousel
        className={className}
        prev={`swiper-image-prev-${uniqueKey}`}
        next={`swiper-image-next-${uniqueKey}`}
        navigation={{
          prevEl: `.swiper-image-prev-${uniqueKey}`,
          nextEl: `.swiper-image-next-${uniqueKey}`,
        }}
        autoplay={{ delay: 15000 }}
        onActiveIndexChange={(swiper) => {
          let image = images[swiper.activeIndex];
          setActiveIndex(swiper.activeIndex);
          setCaption(image['file_note']);
        }}
      >
        {images.map((image, index) => {
          return (
            <React.Fragment key={`trip-overview ${index}`}>
              {image['media_type'] === 'video' ? (
                <VideoCard
                  video={image['file_location']}
                  caption={image['file_note']}
                  onClick={() => handleShowFullScreen(index)}
                  autoPlay={activeIndex === index && fullScreen === -1 && !modalFlag}
                  muted={muted}
                />
              ) : (
                <ImageCard
                  image={
                    image['file_location'] ? image['file_location'] : image
                  }
                  caption={image['file_note']}
                  onClick={() => handleShowFullScreen(index)}
                />
              )}
            </React.Fragment>
          )
        })}
      </SwiperCarousel>
      <Box
        className={[classes.font, classes.contentFontSize].join(' ')}
        sx={{
          marginTop: '8px',
          minHeight: '30px',
          padding: '0px 16px',
          width: 'calc(100% - 32px)',
          height: 'fit-content',
          fontWeight: '400',
        }}
      >
        {caption || ''}
      </Box>
    </Box>
  )
}

export default MediaSliderWithPreview;

