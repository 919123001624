import merge from 'lodash/merge';
import { RECEIVE_ADDED_INSPIRATION, RECEIVE_INSPIRATION, RECEIVE_INSPIRATIONS, REMOVE_INSPIRATION, RECEIVE_USER_INSPIRATIONS,RECEIVE_CHANGED_TREE } from '../../constants';
import { RECEIVE_ADDED_ADVENTURE } from '../../constants';
const InspirationsReducer = (state = { inspirations: [] }, action) => {
    Object.freeze(state);
    let newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_USER_INSPIRATIONS:
            // debugger
            // if (newState['inspirations']) {
            //     let modifiedState = merge({}, state)
            //     modifiedState['inspirations'] = action.data.userInspirations
            //     return modifiedState
            // }
            newState['inspirations'] = action.data.userInspirations
            return newState;
        case RECEIVE_ADDED_INSPIRATION:
            newState['inspirations'].push(action.data)
            return newState
        case RECEIVE_CHANGED_TREE:
            newState['treeData'] = action.data
            return newState
        case RECEIVE_INSPIRATION:
            // debugger
            break;
        case RECEIVE_INSPIRATIONS:
            // debugger
            break;
        case REMOVE_INSPIRATION:
            // debugger
            break;
        // case RECEIVE_ADDED_ADVENTURE:
        //     let modified
        //     if (newState.inspirations[action.data.inspiration_name]) {
        //         newState.inspirations[action.data.inspiration_name].push(action.data.addedAdventure)
        //     }
        //     return newState
        // case RECEIVE_ADVENTURE_DATA:
        //     break;
        case RECEIVE_ADDED_ADVENTURE:
            return newState
        default:
            return state;
    }
}
export default InspirationsReducer;