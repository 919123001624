import React, { useEffect, useState } from 'react';
const NotificationBadge = ({
  notification,
  undisplayNotifcationBadge,
  badgeKey,
  triggerMenuDrawerWithTab,
}) => {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDisplay(false);
    }, 7500);
    setTimeout(() => {
      undisplayNotifcationBadge(badgeKey);
    }, 8500);
  }, []);
  const { img, title, adventureImg } = notification;
  return (
    <div
      className={`badge-friend-request-wrapper 
      adventure-invites-wrapper
      `}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        triggerMenuDrawerWithTab('notification');
      }}
      style={{ backgroundImage: adventureImg ? adventureImg : null }}
    >
      <div className="adventure-invite-opacity-layer" />
      <div className="badge-image-wrapper">
        <img src={img ? img : '/logos_v2/lodestar-mark.svg'} />
      </div>
      <div className="badge-title-wrapper">
        <span>{title}</span>
      </div>
    </div>
  );
};
export default NotificationBadge;
