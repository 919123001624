export const svgTrip = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      width={width ? width : '100'}
      height={height ? height : '100'}
      viewBox={viewBox ? viewBox : '0 0 100 60'}
      x="0"
      y="0"
    >
      <g id="Layer_1">
        <path
          className={className}
          fill={color}
          d="M20.942,51.473 C20.942,53.328 22.406,54.835 24.217,54.835 C26.029,54.835 27.493,53.328 27.493,51.473 C27.493,51.212 27.464,50.951 27.406,50.705 L21.029,50.705 C20.971,50.951 20.942,51.212 20.942,51.473 z"
          
        />
        <path
          className={className}
          fill={color}
          d="M76.623,51.473 C76.623,53.338 75.157,54.849 73.348,54.849 C71.539,54.849 70.072,53.338 70.072,51.473 C70.072,49.608 71.539,48.096 73.348,48.096 C75.157,48.096 76.623,49.608 76.623,51.473 z"
          
        />
        <path
          className={className}
          fill={color}
          d="M77.449,59.03 C80.058,57.556 81.826,54.725 81.826,51.473 C81.826,46.69 78.029,42.82 73.348,42.82 C68.667,42.82 64.87,46.69 64.87,51.473 C64.87,54.725 66.638,57.556 69.246,59.03 L28.279,59.03 C30.581,57.742 32.238,55.419 32.609,52.661 L31.464,51.081 C31.275,50.82 31.043,50.705 30.783,50.705 L29.609,50.705 C29.652,50.951 29.667,51.212 29.667,51.473 C29.667,54.516 27.217,57.009 24.217,57.009 C21.217,57.009 18.768,54.516 18.768,51.473 C18.768,51.212 18.783,50.951 18.826,50.705 L17.87,50.705 C17.609,50.705 17.377,50.82 17.217,51.052 L15.87,52.907 C16.292,55.557 17.919,57.781 20.148,59.03 z M73.348,57.023 C70.348,57.023 67.899,54.531 67.899,51.473 C67.899,48.415 70.348,45.922 73.348,45.922 C76.362,45.922 78.797,48.415 78.797,51.473 C78.797,54.531 76.362,57.023 73.348,57.023 z"
          
        />
        <path
          className={className}
          fill={color}
          d="M1.768,46.849 C1.913,47.444 2.072,48.023 2.232,48.589 C2.913,50.922 5.101,52.545 7.551,52.545 L12.884,52.545 C13.246,52.545 13.565,52.386 13.768,52.096 L15.435,49.806 C16,48.994 16.899,48.531 17.87,48.531 L30.783,48.531 C31.754,48.531 32.667,48.994 33.217,49.806 L34.884,52.096 C35.087,52.386 35.406,52.545 35.754,52.545 L62.754,52.545 C62.71,52.197 62.696,51.835 62.696,51.473 C62.696,45.502 67.478,40.647 73.348,40.647 C79.232,40.647 84,45.502 84,51.473 C84,51.835 83.986,52.197 83.942,52.545 L87.159,52.545 C90.246,52.545 92.739,50.023 92.739,46.907 L92.739,35.444 C92.739,34.241 92.609,33.052 92.319,31.893 L-0,31.893 C0.029,37.096 0.609,42.168 1.696,46.531 C1.696,46.603 1.725,46.676 1.768,46.849 z M84.928,41.922 C84.928,40.371 86.159,39.125 87.667,39.125 L90.565,39.125 L90.565,45.864 L87.667,45.864 C86.159,45.864 84.928,44.603 84.928,43.052 L84.928,41.922 z"
          
        />
        <path
          className={className}
          fill={color}
          d="M91.348,29.023 L84.71,14.371 C83.435,11.574 80.638,9.763 77.594,9.763 L52.44,9.763 L52.44,7.055 L54.867,7.055 C55.467,7.055 55.954,6.568 55.954,5.968 C55.954,5.368 55.467,4.881 54.867,4.881 L52.44,4.881 L52.44,2.174 L54.867,2.174 C55.467,2.174 55.954,1.687 55.954,1.087 C55.954,0.487 55.467,-0 54.867,-0 L12.404,-0 C11.803,-0 11.317,0.487 11.317,1.087 C11.317,1.687 11.803,2.174 12.404,2.174 L14.83,2.174 L14.83,4.881 L12.404,4.881 C11.803,4.881 11.317,5.368 11.317,5.968 C11.317,6.568 11.803,7.055 12.404,7.055 L14.83,7.055 L14.83,9.763 L7.638,9.763 C5.159,9.763 2.942,11.458 2.261,13.878 C0.913,18.661 0.159,24.125 0.029,29.719 L91.638,29.719 C91.551,29.473 91.464,29.241 91.348,29.023 z M17.004,2.174 L50.266,2.174 L50.266,4.881 L17.004,4.881 L17.004,2.174 z M17.004,7.055 L50.266,7.055 L50.266,9.763 L17.004,9.763 L17.004,7.055 z M24.536,26.458 L7.188,26.458 C7.478,23.531 7.957,17.806 8.638,15.197 L24.536,15.197 L24.536,26.458 z M59.725,26.458 L29.435,26.458 L29.435,15.197 L59.725,15.197 L59.725,26.458 z M64.623,26.458 L64.623,15.197 L77.58,15.197 C77.956,15.197 78.29,15.415 78.449,15.791 L81.971,26.458 L64.623,26.458 z"
          
        />
      </g>
    </svg>
  );
};