import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const HubCircularProgress = (props) => {
  const restprops = { ...props };
  delete restprops.isLoading;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        sx={{ color: '#273a07' }}
        size={80}
        variant={props.isLoading ? 'indeterminate' : 'determinate'}
        {...restprops}
      />
      {!props.isLoading && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              fontColor: '#273A07',
            }}
          >{`${props.value > 100 ? 100 : props.value}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default HubCircularProgress;
