import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 50px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #273A07;
`;

const IconTitle = ({ children, icon }) => {
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <Title>{children}</Title>
    </Container>
  )
};

export default IconTitle;