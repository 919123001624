import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { flushModal } from '../../../actions/modal_actions';
import {
  svgDelete,
  svgCamera,
  svgPdf,
  svgDocument,
} from 'components/svg_module';
const ModalDropzone = ({
  spacingTop,
  spacingBottom,
  deletePreview,
  image,
  setImage,
  original_image,
  inputHandler,
  fetchedOnlineImage,
  hasFetchedOnlineImage,
  flushModal,
  id,
  ...rest
}) => {
  const [flag, setFlag] = useState(0);
  const verifyFile = (file) => {
    //filters file input
    const maxImgSize = 10000000000;
    const acceptedFileTypes =
      'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
    const acceptedFileTypesArr = acceptedFileTypes.split(',').map((item) => {
      return item.trim();
    });
    if (file && file.length > 0) {
      const currentFile = file[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > maxImgSize) {
        alert('File too big');
        return false;
      }
      // if (!acceptedFileTypesArr.includes(currentFileType)) {
      //   alert("File type not allowed");
      //   return false;
      // }
    }
    return true;
  };
  const handleOnDrop = (files, rejectedFiles) => {
    //handles image drop
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.onloadend = () => {
          let fileObject = {
            file: myFileItemReader.result,
            file_name: currentFile.path,
          };
          setImage(fileObject);
        };
        let file_name = myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  const renderDropZone = () => {
    const maxImgSize = 10000000000;
    return (
      <div className="mod-drpzn-padder">
        <Dropzone onDrop={handleOnDrop} maxSize={maxImgSize} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mod-drpzn">
                  <div className="mod-drpzn-inner">
                    <span>Click to browse or drop files here.</span>
                  </div>
                  <div className="mod-drpzn-cam-wrapper">
                    {svgCamera(
                      'color',
                      'mod-drpzn-svg-cam',
                      'mod-drpzn-svg-cam-outerlayer',
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  const renderPreview = () => {
    // let isImage;
    // if (image && image.file) isImage = checkIfImageExists(image.file);

    return (
      <div className="prev-image-cont">
        <>
          {image && image.file && checkIfImageExists(image.file)}
          {fetchedOnlineImage && (
            <img alt="" src={fetchedOnlineImage} className="imgprvw" />
          )}
          <div className="remove-btn-cont">
            <div
              onClick={() => {
                if (original_image) {
                  inputHandler();
                }
                setImage(null);
                flushModal();
              }}
            >
              {svgDelete(
                'color',
                'mod-drpzn-svg-del',
                'mod-dpzn-svg-del-outer',
              )}
            </div>
          </div>
        </>
      </div>
    );
  };
  function checkIfImageExists(url, callback) {
    let sliced = url.slice(0, 30);
    if (sliced.includes('http')) {
      if (
        !url.includes('jpeg') &&
        !url.includes('jpg') &&
        !url.includes('png') &&
        !url.includes('svg')
      ) {
        return (
          <span className="pdf-svg-wrapper">
            {svgDocument('', 'drop-zone-pdf', 'drop-zone-pdf-outer')}
          </span>
        );
      }

      return (
        <img
          alt=""
          src={hasFetchedOnlineImage ? fetchedOnlineImage : url}
          className="imgprvw"
        />
      );
    } else {
      if (
        !sliced.includes('jpeg') &&
        !sliced.includes('jpg') &&
        !sliced.includes('png') &&
        !sliced.includes('svg')
      ) {
        return (
          <span className="pdf-svg-wrapper">
            {svgDocument('', 'drop-zone-pdf', 'drop-zone-pdf-outer')}
          </span>
        );
      }

      return (
        <img
          alt=""
          src={hasFetchedOnlineImage ? fetchedOnlineImage : url}
          className="imgprvw"
        />
      );
    }
  }
  return (
    <div
      className="mod-dropzone-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
      id={id}
    >
      {hasFetchedOnlineImage ||
      (image && (image.file || image.file_name) !== null)
        ? renderPreview()
        : renderDropZone()}
    </div>
  );
};

ModalDropzone.defaultProps = {
  flushModal: () => {},
}

export default ModalDropzone;
