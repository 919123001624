import { Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { merge } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HubModal from '../../modal';
// import { DropDownSelect } from "@lodestarhubdevelopment/uicomponentlibrary";
import { mapDispatchToProps, mapStateToProps } from './container';
import Summary from './summary';
const CreateBlock = ({
  fetchUserBlockCategories,
  user_block_categories,
  deleteAdventureBlock,
  closeModal,
  openBlockFileModal,
  deleteBlockFile,
  history,
  match,
  userInfo,
  postBlockComment,
  blockVotes,
  blockComments,
  fetchBlockList,
  blockLists,
  upsertItineraryItemBlock,
  postAdventureBlockComment,
  persistBlockModalData,
  deleteBlock,

  upsertBlock,
  //—————————————— fetched from modal reducer
  openType,
  blockType,
  actionPayload,
  currentTab,

  //—————————————— fetched from modal cache reducer
  currentBlock,
  currentBlockFiles,
  setPrevModal,
  persistedModalData,
  isAdventureBlock,
  moveToAdventureBlock,
  copyToTripBoard,
  copyBlockAndAddToItineraryItems,

  uiUpdatePayload,

  // used for itinerary items,
  itineraryItemsAndBlockNames,
  fetchItineraryItemsAndBlockNames,
}) => {
  useEffect(() => {
    fetchUserBlockCategories(userInfo.account_rid);
    if (actionPayload.itineraryInfo) {
      fetchItineraryItemsAndBlockNames(
        actionPayload.itineraryInfo.itinerary_rid,
      );
    }
    if (currentBlock) {
      Promise.all([
        fetchBlockList(
          currentBlock.block_rid,
          currentBlock.adventure_block_rid,
          userInfo,
        ),
      ]);
    }
  }, []);

  const [triggerCommentsButton, setTriggerCommentsButton] = useState('load');
  const [parentBlockRid, setParentBlockRid] = useState(null);
  const [parentTripBlockRid, setParentTripBlockRid] = useState(null);

  const [uiToggle, setUiToggle] = useState({
    addToAdventure:
      currentBlock && currentBlock.adventure_block_rid ? true : false,
  });
  const [triggerNext, setTriggerNext] = useState({
    display: false,
    newBlock: null,
  });
  const [inputValues, setInputValues] = useState(
    persistedModalData
      ? persistedModalData
      : {
          account_rid: userInfo.account_rid,

          block_rid: currentBlock ? currentBlock.block_rid : null,
          inspiration_rid: currentBlock ? currentBlock.inspiration_rid : null,
          adventure_rid: currentBlock ? currentBlock.adventure_rid : null,
          adventure_block: currentBlock ? currentBlock.adventure_block : null,
          inspiration_block_rid: currentBlock
            ? currentBlock.inspiration_block_rid
            : null,
          itinerary_item_rid: currentBlock
            ? currentBlock.itinerary_item_rid
            : null,
          itinerary_rid: currentBlock ? currentBlock.itinerary_rid : null,
          parent_block_rid: currentBlock ? currentBlock.parent_block_rid : null,
          block_name: currentBlock ? currentBlock.block_name : '',
          block_description: currentBlock
            ? currentBlock.block_description
            : null,
          block_category_rid: currentBlock
            ? currentBlock.block_category_rid
            : null,
          block_activity_rid: currentBlock
            ? currentBlock.block_activity_rid
            : null,
          activity_detail_rid: currentBlock
            ? currentBlock.activity_detail_rid
            : null,
          block_count: currentBlock ? currentBlock.block_count : null,
          ui_color:
            currentBlock && currentBlock.ui_color
              ? currentBlock.ui_color
              : 'ffff',
          ui_image_location: currentBlock
            ? currentBlock.ui_image_location
            : null,
          vote_flag:
            currentBlock && currentBlock.vote_flag
              ? currentBlock.vote_flag
              : false,
          list_flag:
            currentBlock && currentBlock.list_flag
              ? currentBlock.list_flag
              : openType.isListFolder
              ? true
              : false,
          completed_flag:
            currentBlock && currentBlock.completed_flag
              ? currentBlock.completed_flag
              : false,
          comment_flag:
            currentBlock && currentBlock.comment_flag
              ? currentBlock.comment_flag
              : false,
          region_rid:
            currentBlock && currentBlock.region_rid
              ? currentBlock.region_rid
              : null,
          country_rid: currentBlock ? currentBlock.country_rid : null,
          state_providence_rid: currentBlock
            ? currentBlock.state_providence_rid
            : null,
          meta_data: currentBlock ? currentBlock.meta_data : null,
          location_flag: blockType.isLocationBlock ? true : false,

          location_point: blockType.isLocationBlock
            ? actionPayload.location
            : currentBlock
            ? currentBlock.location_point
            : { lat: null, lng: null },
          auto_sync_flag: currentBlock ? currentBlock.auto_sync_flag : true,
          folder_flag: openType.isFolder ? true : false,
          template_flag: false,
        },
  );
  // useEffect(() => {
  //   if (openType.isTransitionWithTab) {
  //     if (persistedModalData) {
  //     }
  //   }
  // }, []);
  const [uiColor, setUiColor] = useState(
    currentBlock ? currentBlock.ui_color : 'ffff',
  );
  const [toggleColor, setToggleColor] = useState(false);
  // const clickHandler = () => {
  //   props.history.push(`/hub/inspiration/${props.inspiration_name}/${props.inspiration_rid}`)
  // }
  const [blockCategoryRid, setBlockCategoryRid] = useState([]);
  const [blockActivityRid, setBlockActivityRid] = useState([]);
  const [blockActivityDetailRid, setBlockActivityDetailRid] = useState([]);
  const [topLabel, setTopLabel] = useState('SUMMARY');
  const [bottomLabel, setBottomLabel] = useState('COLOR');
  const [comments, setComments] = useState({
    explorer_comment_rid: null,
    itinerary_block_rid: currentBlock
      ? currentBlock.itineriary_block_rid
      : null,
    explorer_rid: userInfo ? userInfo.explorer_rid : null,
    explorer_comment: null,
  });
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleOnChangeComments = useCallback((event) => {
    const { name, value } = event.target;
    setComments({ ...comments, [name]: value });
  });
  const uiToggleHandler = (name, value) => {
    setUiToggle({ ...uiToggle, [name]: !value });
  };
  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };
  const [selectedItineraryItems, setSelectedItineraryItems] = useState({});
  const handleLocationPointChange = useCallback((event) => {
    if (event.target) {
      const { name, value } = event.target;
      let latLng = inputValues.location_point;
      latLng[name] = value;
      setInputValues({ ...inputValues, location_point: latLng });
    } else {
      let lat = event.geometry.coordinates[1];
      let lng = event.geometry.coordinates[0];
      setInputValues({ ...inputValues, location_point: { lat, lng } });
    }
  });
  const handleSelectItineraryItem = (itineraryItem) => {
    if (selectedItineraryItems[itineraryItem.itinerary_item_rid]) {
      let cloned = merge({}, selectedItineraryItems);
      delete cloned[itineraryItem.itinerary_item_rid];
      setSelectedItineraryItems(cloned);
    } else {
      setSelectedItineraryItems({
        ...selectedItineraryItems,
        [itineraryItem.itinerary_item_rid]: itineraryItem,
      });
    }
  };
  // let adventure_rid = current_adventure_data ? current_adventure_data.adventure_rid : null;
  const handleSubmit = async () => {
    const { block_name, block_category_rid } = inputValues;
    if (!block_name || !block_category_rid) return;
    if (
      inputValues.location_point &&
      inputValues.location_point.lng &&
      inputValues.location_point.lat
    ) {
      inputValues.location_point.lng = parseFloat(
        inputValues.location_point.lng,
      );
      inputValues.location_point.lat = parseFloat(
        inputValues.location_point.lat,
      );
    }
    let passOver = merge({}, inputValues);
    passOver['ui_color'] = uiColor;
    if (
      inputValues.location_point &&
      inputValues.location_point.lng &&
      inputValues.location_point.lat
    ) {
      passOver.location_flag = true;
    }
    if (openType.isTransitionWithTab) {
      if (inputValues.block_rid || inputValues.adventure_block_rid) {
        openType.isEditAndView = true;
      }
    }

    //Here is for creating block from map
    if (openType.isNew && blockType.isLocationBlock) {
      if (triggerNext.newBlock) {
        let blockData = merge({}, triggerNext.newBlock);
        blockData['account_rid'] = userInfo.account_rid;
        blockData['explorer_rid'] = userInfo.explorer_rid;
        blockData['adventure_rid'] = actionPayload.itineraryInfo.adventure_rid;
        await copyBlockAndAddToItineraryItems(
          blockData,
          Object.keys(selectedItineraryItems),
          // this func is the callback to update the map blocks and replace dropped pin without data with new made block item#:f000001
          actionPayload.func,
        );
        return closeModal();
      }
      // block = await insertBlockAndReturn(userInfo, passOver);
      passOver['adventure_rid_exist_flag'] =
        actionPayload.itineraryInfo.adventure_rid;
      passOver['adventure_rid'] = null;
      blockType.createdFromModal = true;
      passOver['adventure_rid'] = actionPayload.itineraryInfo.adventure_rid;
      let block = await upsertBlock(
        userInfo,
        passOver,
        blockType,
        actionPayload,
      );
      setTriggerNext({
        display: true,
        newBlock: block.data.singleBlock[0],
      });
      // setTopLabel('TRIP PLAN');
      setInputValues({
        ...inputValues,
        block_rid: block.data.singleBlock[0].block_rid,
      });
      return closeModal();
    }
    if (passOver.adventure_block_rid) {
      //if statements will separate the b
      blockType['updateAdventureAndItineraries'] = true;
    }
    //library block updates here

    if (blockType.isLibraryBlock) {
      let returnActionPayload = {
        adventureRid: actionPayload.adventureRid,
      };
      if (openType.isNew) {
        // new library block

        if (blockType.isNestedLibraryBlock)
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        if (openType.isListFolder) {
          returnActionPayload.isLibraryListOnly = true;
        }
        if (parentBlockRid) passOver['parent_block_rid'] = parentBlockRid;
        returnActionPayload['parentBlockRid'] =
          actionPayload.parentBlockRid && actionPayload.parentBlockRid;
        await upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        // updating library block
        if (blockType.isNestedLibraryBlock)
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        if (parentBlockRid) passOver['parent_block_rid'] = parentBlockRid;
        returnActionPayload['parentBlockRid'] =
          actionPayload.parentBlockRid && actionPayload.parentBlockRid;
        await upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      }
    } else if (blockType.isAdventureBlock) {
      if (openType.isNew) {
        //this is for creating new block and adding to itinerary
        if (triggerNext.newBlock) {
          let blockData = merge({}, triggerNext.newBlock);
          blockData['account_rid'] = userInfo.account_rid;
          blockData['explorer_rid'] = userInfo.explorer_rid;
          blockData['itineraryRid'] = actionPayload.itineraryInfo.itinerary_rid;
          blockData['adventure_rid'] =
            actionPayload.itineraryInfo.adventure_rid;
          await copyBlockAndAddToItineraryItems(
            blockData,
            Object.keys(selectedItineraryItems),
            // this func is the callback to update the map blocks and replace dropped pin without data with new made block item#:f000001
            actionPayload.func,
          );
          return closeModal();
        }
        let returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          adventureRid:
            actionPayload.adventureRid && actionPayload.adventureRid,
        };
        // new adventure block
        passOver['adventure_rid'] = actionPayload.adventureRid;
        // this is when creating an adventure block from the sub nav
        if (blockType.isNestedAdventureBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        } else if (blockType.isAdventureBlock) {
          passOver['parent_block_rid'] = parentTripBlockRid;
          returnActionPayload['addLibraryParent'] = true;
          returnActionPayload['libraryParentRid'] = parentBlockRid;
        }

        let block = await upsertBlock(
          userInfo,
          passOver,
          blockType,
          returnActionPayload,
        );
        setTriggerNext({
          display: true,
          newBlock: block.data.singleBlock[0],
        });
        // setTopLabel('TRIP PLAN');
        setInputValues({
          ...inputValues,
          block_rid: block.data.singleBlock[0].block_rid,
        });
        return closeModal();
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        // this is for updating adventure block
        if (parentTripBlockRid)
          passOver['parent_block_rid'] = parentTripBlockRid;
        if (blockType.isNestedLibraryBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        }

        let returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          adventureRid:
            actionPayload.adventureRid && actionPayload.adventureRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };
        await upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      }
    } else if (blockType.isItineraryItemBlock) {
      if (openType.isNew) {
        passOver['itinerary_item_rid'] = actionPayload.itineraryItemRid;
        // passOver["adventureRid"] = actionPayload.itineraryInfo.adventure_rid;
        if (blockType.isNestedItineraryItemBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
          delete passOver.itinerary_item_rid;
        }
        let returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          itineraryItemRid:
            actionPayload.itineraryItemRid && actionPayload.itineraryItemRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };

        await upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      } else if (openType.isEditAndView || openType.isEditWithTab) {
        if (blockType.isNestedItineraryItemBlock) {
          passOver['parent_block_rid'] = actionPayload.parentBlockRid;
        }
        if (parentTripBlockRid) {
          passOver['parent_block_rid'] = parentTripBlockRid;
        }
        let returnActionPayload = {
          parentBlockRid:
            actionPayload.parentBlockRid && actionPayload.parentBlockRid,
          itineraryItemRid:
            actionPayload.itineraryItemRid && actionPayload.itineraryItemRid,
          itineraryInfo: actionPayload.itineraryInfo,
        };
        await upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      }
    }
    closeModal();

    setInputValues({
      account_rid: userInfo.account_rid,
      block_rid: null,
      inspiration_rid: null,
      adventure_rid: null,
      adventure_block: null,
      inspiration_block_rid: null,
      itinerary_rid: null,
      parent_block_rid: null,
      block_name: null,
      block_description: null,
      block_category_rid: null,
      block_activity_rid: null,
      activity_detail_rid: null,
      block_count: null,
      ui_color: null,
      ui_image_location: null,
      vote_flag: false,
      list_flag: false,
      location_point: { lat: null, lng: null },
    });
  };
  const handleCategorySelect = useCallback((event) => {
    if (event.length > 0) {
      const { block_category_rid } = event[0];
      setInputValues({ ...inputValues, block_category_rid });
    } else {
      setInputValues({ ...inputValues, block_category_rid: null });
    }
  });

  const handleParentBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      setParentBlockRid(event[0].block_rid);
    } else {
      setParentBlockRid(null);
    }
  });
  const handleParentTripBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      setParentTripBlockRid(event[0].block_rid);
    } else {
      setParentTripBlockRid(null);
    }
  });
  const handleBlockCommentSubmit = (e) => {
    if (currentBlock.adventure_block_rid && !currentBlock.itinerary_item_rid) {
      let passOver = merge({}, comments);
      passOver['adventure_block_rid'] = currentBlock.adventure_block_rid;
      passOver['idx'] = currentBlock.idx;

      postAdventureBlockComment(passOver);
    } else {
      let passOver = merge({}, comments);
      passOver['idx'] = currentBlock.idx;
      passOver['itinerary_item_rid'] = currentBlock.itinerary_item_rid;
      postBlockComment(passOver);
    }
    setComments({
      explorer_comment_rid: null,
      itinerary_block_rid: currentBlock
        ? currentBlock.itineriary_block_rid
        : null,
      explorer_rid: userInfo ? userInfo.explorer_rid : null,
      explorer_comment: '',
    });
  };

  const transitionHandler = () => {
    let openType = {
      isSaving: true,
    };

    let actionPayload = {
      savingModalData: inputValues,
    };
    persistBlockModalData(openType, actionPayload);
  };

  return (
    <HubModal
      show={true}
      title="FOLDER"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box className="create-adventure-wrapper" sx={{ minHeight: 450 }}>
        <Stack spacing={2}>
          {topLabel === 'SUMMARY' && (
            <Summary
              handleOnChange={handleOnChange}
              inputValues={inputValues}
              currentBlock={currentBlock}
              uiToggle={uiToggle}
              uiToggleHandler={uiToggleHandler}
              uiColor={uiColor}
              setUiColor={setUiColor}
              switchHandler={switchHandler}
              bottomLabel={bottomLabel}
              isAdventureBlock={isAdventureBlock}
              openType={openType}
              blockType={blockType}
              moveToAdventureBlock={moveToAdventureBlock}
              actionPayload={actionPayload}
              userInfo={userInfo}
              copyToTripBoard={copyToTripBoard}
              setParentBlockRid={handleParentBlockSelect}
              setParentTripBlockRid={handleParentTripBlockSelect}
              handleLocationPointChange={handleLocationPointChange}
              user_block_categories={user_block_categories}
              handleCategorySelect={handleCategorySelect}
              openBlockFileModal={openBlockFileModal}
              currentBlockFiles={currentBlockFiles}
              deleteBlockFile={deleteBlockFile}
              transitionHandler={transitionHandler}
              comments={comments}
              handleOnChangeComments={handleOnChangeComments}
              blockComments={blockComments}
              handleBlockCommentSubmit={handleBlockCommentSubmit}
              triggerNext={triggerNext}
              setTriggerCommentsButton={setTriggerCommentsButton}
              currentTab={currentTab}
              setInputValues={setInputValues}
            />
          )}
          {/* {topLabel === 'TRIP PLAN' && (
            <ItineraryItems
              itineraryItems={reformatItinerayItems(
                itineraryItemsAndBlockNames,
              )}
              selectedItineraryItems={selectedItineraryItems}
              handleSelectItineraryItem={handleSelectItineraryItem}
            />
          )} */}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateBlock),
);
