import './stylesheets/app.scss';
// import App from './App';
import jwt_decode from 'jwt-decode';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Cookies from 'universal-cookie';
import { logout } from './actions/session_actions';
import Root from './root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store/store';
import {
  getEncodedTokenFromCookie,
  goToPublicSite,
  InvalidAuthSession,
  isValidAuthSession,
  setAuthToken,
} from './util/api_util/session_api_util';

import axios from 'axios';
import { createRoot } from 'react-dom/client';

if (
  process.env.REACT_APP_RUN_MODE === 'PROD' ||
  process.env.REACT_APP_RUN_MODE === 'TEST'
) {
  console.log = () => {};
  console.debug = () => {};
}

const cookie = new Cookies();
// fire the orientation change event at the start, to make sure
document.addEventListener('DOMContentLoaded', () => {
  document.cookie = 'SameSite=None';
  var PERMITTED_DOMAIN = `${
    process.env.REACT_APP_RUN_MODE === 'PROD'
      ? process.env.REACT_APP_PROD_PUB_URL
      : process.env.REACT_APP_RUN_MODE === 'TEST'
      ? process.env.REACT_APP_TEST_PUB_URL
      : process.env.REACT_APP_DEV_PUB_URL
  }/`;

  let store;
  let cookieToken;

  // handle initial setup of Redux Store and session if there is one
  try {
    const token = getEncodedTokenFromCookie();
    // and Axios middleware
    setAuthToken(token);
    const decodedUser = jwt_decode(token);
    const preloadedState = {
      session: { isAuthenticated: true, user: decodedUser },
    };
    store = configureStore(preloadedState);
    const validSession = isValidAuthSession();
    if (!validSession) throw new InvalidAuthSession();
  } catch (err) {
    if (err instanceof InvalidAuthSession) {
      console.error('Token is invalid');
    } else {
      console.error('Failed to get valid auth session, unhandled', err.message);
    }
    // there might be an error before configuring store
    if (store) {
      store.dispatch(logout());
    }
    goToPublicSite();
    return '';
  }

  if (process.env.PROD_ENV !== 'production') window.store = store;

  const container = document.getElementById('root');
  if (!container) throw new Error('failed to get root dom element');
  const root = createRoot(container);
  // if (window.location.host.split(".")[0] === "hub") {
  root.render(
    <GoogleReCaptchaProvider
      reCaptchaKey="6Le2Z_IZAAAAADRCLs9ycHmbWHEBmNlbRW9Tjnx2"
      // language="[optional_language]"
      // useRecaptchaNet="[optional_boolean_value]"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <Root store={store} />
    </GoogleReCaptchaProvider>,
  );
  // }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
if (process.env.NODE_ENV !== 'development') {
  let currentVersionInLocalStorage = localStorage.getItem('lodestarPWAVersion');
  if (!currentVersionInLocalStorage) {
    localStorage.setItem(
      'lodestarPWAVersion',
      `v${process.env.REACT_APP_VERSION}`,
    );
    currentVersionInLocalStorage = `v${process.env.REACT_APP_VERSION}`;
  }
  // if (
  //   currentVersionInLocalStorage &&
  //   currentVersionInLocalStorage !== `v${process.env.REACT_APP_VERSION}`
  // ) {
  //   serviceWorkerRegistration.forceSWupdate().then(() => {
  //     window.location.reload(true);
  //   });
  // }
  axios.get('/api/ui/hub-version').then(({ data }) => {
    if (`v${data.hubVersion}` !== currentVersionInLocalStorage) {
      serviceWorkerRegistration.forceSWupdate().then(() => {
        window.location.reload(true);
      });
      localStorage.setItem('lodestarPWAVersion', `v${data.hubVersion}`);
    }
  });
}
