export const svgDocument = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="79.7"
      height="95.1"
      viewBox="0, 0, 79.7, 95.1"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M76.3,20.4 L59.3,3.4 C57.1,1.2 54.1,-0 51,-0 L11.8,-0 C5.3,-0 -0,5.3 -0,11.8 L-0,83.3 C-0,89.8 5.3,95.1 11.8,95.1 L67.9,95.1 C74.4,95.1 79.7,89.8 79.7,83.3 L79.7,28.8 C79.7,25.6 78.5,22.6 76.3,20.4 z M72,83.2 C72,85.5 70.2,87.3 67.9,87.3 L11.8,87.3 C9.5,87.3 7.7,85.5 7.7,83.2 L7.7,11.7 C7.7,9.4 9.5,7.6 11.8,7.6 L51,7.6 C51.1,7.7 51.2,7.8 51.4,8 L51.4,22.9 C51.4,25.9 53.8,28.3 56.8,28.3 L72,28.3 C72,28.3 72,28.3 72,28.3 L72,83.2 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M59.4,70.6 L20.4,70.6 C18.3,70.6 16.6,72.3 16.6,74.4 C16.6,76.5 18.3,78.2 20.4,78.2 L59.4,78.2 C61.5,78.2 63.2,76.5 63.2,74.4 C63.2,72.3 61.5,70.6 59.4,70.6 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M59.4,54 L20.4,54 C18.3,54 16.6,55.7 16.6,57.8 C16.6,59.9 18.3,61.6 20.4,61.6 L59.4,61.6 C61.5,61.6 63.2,59.9 63.2,57.8 C63.2,55.7 61.5,54 59.4,54 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M59.4,37.3 L20.4,37.3 C18.3,37.3 16.6,39 16.6,41.1 C16.6,43.2 18.3,44.9 20.4,44.9 L59.4,44.9 C61.5,44.9 63.2,43.2 63.2,41.1 C63.2,39 61.5,37.3 59.4,37.3 z"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
};
