export const svgMenuGreen = (color, className, outerLayer) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    className={outerLayer}
  >
    <rect className={className} width="15" height="15" rx="4" fill="#273A07" />
    <rect
      className={className}
      y="18"
      width="15"
      height="15"
      rx="4"
      fill="#273A07"
    />
    <rect
      className={className}
      x="18"
      width="15"
      height="15"
      rx="4"
      fill="#273A07"
    />
    <rect
      className={className}
      x="18"
      y="18"
      width="15"
      height="15"
      rx="4"
      fill="#273A07"
    />
  </svg>
);
