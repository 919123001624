export function coffeeShop(color = 'black', className, outerLayer) {
  return (
    <svg
      width="1290"
      height="1350"
      viewBox="0 0 1290 1350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path
        className={className}
        d="M76.507 757H1055.76C1057.26 741 1058.01 725 1058.01 708.749V599.499C1058.64 577.687 1050.45 556.624 1035.26 540.936C1020.14 525.248 999.324 516.373 977.512 516.248H80.765C36.1396 516.248 0.015625 552.373 0.015625 596.997V708.747C0.015625 724.997 0.015625 740.997 2.26563 756.997L76.507 757Z"
        fill={color}
      />
      <path
        className={className}
        d="M999.001 806.014H9.25391C40.6912 974.2 151.507 1116.83 306.761 1188.76C611.507 1336.25 991.507 1138.76 1048.76 806.014H999.001Z"
        fill={color}
      />
      <path
        className={className}
        d="M840.746 1188.75H804.496C671.621 1265.37 512.922 1283.44 366.242 1238.75C326.93 1226.5 289.18 1209.75 253.742 1188.75H217.242C189.617 1188.75 167.242 1211.12 167.242 1238.75V1300C167.242 1313.25 172.492 1326 181.868 1335.37C191.243 1344.75 203.993 1350 217.242 1350H840.749C853.999 1350 866.749 1344.75 876.124 1335.37C885.498 1326 890.749 1313.25 890.749 1300V1237.75C890.186 1210.5 868 1188.75 840.749 1188.75H840.746Z"
        fill={color}
      />
      <path
        className={className}
        d="M1082.51 604.253V654.253C1328.25 721.753 1270.01 918.746 1028.26 947.506C1019.94 965.569 1010.51 983.132 1000.01 1000.01C1324.99 985.506 1410.01 684.993 1082.51 604.26L1082.51 604.253Z"
        fill={color}
      />
      <path
        className={className}
        d="M480.999 303.747C521.499 317.184 546.311 357.872 539.749 399.997C536.499 415.997 530.561 431.435 522.249 445.497C548.374 431.123 566.749 405.809 572.311 376.497C577.874 347.185 570.061 316.935 550.999 293.997C538.249 278.56 521.874 266.623 503.25 259.248C466.562 239.811 449.625 196.373 463.501 157.248C467.751 143.248 478.001 127.748 484.001 111.248C468.813 120.686 454.938 132.123 442.75 145.248C423.813 170.499 417.125 202.873 424.562 233.499C431.937 264.187 452.625 289.936 480.999 303.749V303.747Z"
        fill={color}
      />
      <path
        className={className}
        d="M627.747 191.747C668.372 205.184 693.184 246.247 686.247 288.496C682.997 304.559 677.122 319.933 668.747 333.996C694.435 319.621 712.496 294.621 718.059 265.745C723.684 236.808 716.247 206.933 697.747 183.996C684.872 168.621 668.372 156.684 649.747 149.247C612.435 129.747 595.372 85.4974 609.998 45.9961C614.248 31.9961 624.498 16.2468 630.498 -0.00390625C615.247 9.37103 601.372 20.8081 589.247 33.9961C570.622 59.2468 564.184 91.4334 571.559 121.871C578.997 152.371 599.622 177.935 627.747 191.747Z"
        fill={color}
      />
    </svg>
  );
}
