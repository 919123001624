import moment from 'moment';
export const convertTime = (timeFetched) => {
  let time = timeFetched.split(':'); // convert to array

  // fetch
  let hours = Number(time[0]);
  let minutes = Number(time[1]);

  // calculate
  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = '' + hours;
  } else if (hours > 12) {
    timeValue = '' + (hours - 12);
  } else if (hours == 0) {
    timeValue = '12';
  }

  timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
  // timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
  timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM

  return timeValue;
};

export const checkIfToday = (time) => {
  let momentIncoming = moment(time).format('MM-DD-YYYY');
  let momentToday = moment().utcOffset(2).format('MM-DD-YYYY');
  let test = moment(momentIncoming).isSame(momentToday) ? true : false;
  return test ? 'current-day-itinerary-item' : false;
};

export const checkIfThisHour = (timeStart, timeEnd, timeDate) => {
  let valA = 0;
  let valToday = 0;
  let valB = 0;
  let timeA = new Date(timeDate);
  let today = new Date();
  let timeADate = timeA.getDate();
  let todayDate = today.getDate();
  let timeADay = timeA.getDay();
  let todayDay = today.getDay();
  let timeAParsed = timeStart.split(':');
  let timeBParsed = timeEnd.split(':');
  let Ahours = Number(timeAParsed[0]);
  let Aminutes = Number(timeAParsed[1]);
  let Bhours = Number(timeBParsed[0]);
  let Bminutes = Number(timeBParsed[1]);
  //  valA = timeADate * timeADay
  // valB = timeADate * timeADay
  valA = Ahours * 60;

  valA += Aminutes;

  valB = Bhours * 60;
  valB += Bminutes;
  // valToday = todayDate * todayDay
  valToday = today.getHours() * 60;
  valToday += today.getMinutes();

  if (
    timeADate * timeADay === todayDate * todayDay &&
    valToday >= valA &&
    valToday <= valB
  ) {
    return 'current-time-itinerary-item';
  }
  return null;
};
