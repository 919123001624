export function booking (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="95"
      height="92.6"
      viewBox="0, 0, 95, 92.6"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M71.6,39.4 C72.7,39.4 73.7,39.5 74.8,39.6 L74.8,18.4 C74.8,11.7 69.3,6.2 62.6,6.2 L54.8,6.2 L54.8,3.2 C54.8,1.4 53.4,0 51.6,0 C49.8,0 48.4,1.4 48.4,3.2 L48.4,6.2 L26.4,6.2 L26.4,3.2 C26.4,1.4 25,0 23.2,0 C21.4,0 20,1.4 20,3.2 L20,6.2 L12.3,6.2 C5.5,6.2 0,11.7 0,18.4 L0,60.2 C0,66.9 5.5,72.4 12.2,72.4 L41.9,72.4 C41.8,71.3 41.7,70.3 41.7,69.2 C41.8,52.8 55.2,39.4 71.6,39.4 z M6.4,24.7 L6.4,18.5 C6.4,15.3 9,12.6 12.3,12.6 L20,12.6 L20,15.6 C20,17.4 21.4,18.8 23.2,18.8 C25,18.8 26.4,17.4 26.4,15.6 L26.4,12.6 L48.4,12.6 L48.4,15.6 C48.4,17.4 49.8,18.8 51.6,18.8 C53.4,18.8 54.8,17.4 54.8,15.6 L54.8,12.6 L62.6,12.6 C65.8,12.6 68.4,15.2 68.4,18.5 L68.4,24.7 L6.4,24.7 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M71.6,45.8 C58.7,45.8 48.2,56.3 48.2,69.2 C48.2,82.1 58.7,92.6 71.6,92.6 C84.5,92.6 95,82.1 95,69.2 C95,56.3 84.5,45.8 71.6,45.8 z M80.4,72.4 L71.6,72.4 C69.8,72.4 68.4,71 68.4,69.2 L68.4,58.3 C68.4,56.5 69.8,55.1 71.6,55.1 C73.4,55.1 74.8,56.5 74.8,58.3 L74.8,66 L80.4,66 C82.2,66 83.6,67.4 83.6,69.2 C83.6,71 82.2,72.4 80.4,72.4 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}