import merge from 'lodash/merge';
import {
  RECEIVE_USER_BLOCK_ACTIVITY_DETAILS,
  RECEIVE_USER_BLOCK_ACTIVITIES,
  RECEIVE_USER_BLOCK_CATEGORIES,
  RECEIVE_BLOCK_LISTS,
  RECEIVE_LIBRARY_BLOCKS,
  RECEIVE_PEOPLE_ASSIGNED,
  RECEIVE_IS_ADVENTURE_BLOCK,
  RECEIVE_ALL_BLOCKS_AS_LISTS,
  CLOSE_MODAL,
  RECEIVE_LIST_DROPDOWN,
  RECEIVE_LIBRARY_LISTS,
  RECEIVE_TEMPLATE_BLOCKS,
  RECEIVE_TEMPLATE_LISTS,
  RECEIVE_ADVENTURE_RID_EXIST_FLAG,
  RECEIVE_FOLDER_CONTENTS,
  RECEIVE_ITINERARY_BLOCK_SPECTATORS,
  RECEIVE_ADDRESS_BOOK_BLOCKS,
} from '../../constants';

const BlocksReducer = (state = { adventureData: [] }, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_USER_BLOCK_CATEGORIES:
      newState['user_block_categories'] = action.data.userBlockCategories;
      return newState;
    case RECEIVE_USER_BLOCK_ACTIVITIES:
      newState['user_block_activities'] = action.data.userBlockActivities;
      return newState;
    case RECEIVE_USER_BLOCK_ACTIVITY_DETAILS:
      // debugger
      newState['user_block_activity_details'] =
        action.data.userBlockActivityDetails;
      return newState;
    case RECEIVE_BLOCK_LISTS:
      newState['block_lists'] = action.data.blockLists;
      newState['block_lists_tree'] = action.data.blockListsAsTree;
      return newState;
    case RECEIVE_LIBRARY_BLOCKS:
      newState['library_blocks'] = action.data.userBlocks;
      if (action.data.userBlocks.length === 0) {
        let stateModified = { ...newState, library_blocks: [] };
        return stateModified;
      }
      return newState;
    case RECEIVE_LIBRARY_LISTS:
      newState['library_lists'] = action.data.userBlocks;
      return newState;
    case RECEIVE_PEOPLE_ASSIGNED:
      newState['usersAssignedToListItem'] = action.data;
      return newState;
    case RECEIVE_IS_ADVENTURE_BLOCK:
      newState['isAdventureBlock'] = action.data;
      return newState;
    case RECEIVE_ALL_BLOCKS_AS_LISTS:
      newState['blocksAsLists'] = action.data.libraryLists;
      return newState;
    case RECEIVE_TEMPLATE_BLOCKS:
      newState['template_blocks'] = action.data.userBlocks;
      return newState;
    case RECEIVE_TEMPLATE_LISTS:
      newState['template_lists'] = action.data.userBlocks;
      return newState;
    case CLOSE_MODAL:
      delete newState.block_lists_tree;
      return newState;
    case RECEIVE_LIST_DROPDOWN:
      newState['blockListDropdown'] = action.data.blockListDropDown;
      return newState;
    case RECEIVE_ADVENTURE_RID_EXIST_FLAG:
      newState['libraryAdventureSelected'] = action.data;
      return newState;
    case RECEIVE_FOLDER_CONTENTS:
      newState['folderContents'] = action.data.blockContents;
      return newState;
    case RECEIVE_ITINERARY_BLOCK_SPECTATORS:
      let spectatorObj = {};
      action.data.forEach(
        ({ adventure_friend_rid }) =>
          (spectatorObj[adventure_friend_rid] = true),
      );
      newState['itineraryBlockSpectators'] = spectatorObj;
      return newState;
    case RECEIVE_ADDRESS_BOOK_BLOCKS:
      newState['addressBookBlocks'] = action.data;
      return newState;
    default:
      return state;
  }
};
export default BlocksReducer;
