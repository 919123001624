import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
  terminateUserSubscriptionEarly,
} from '../../../actions/user_actions.js';
import { fetchUnsubscriptionReasons } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import { Headers } from './shared_modules';
import { logout } from '../../../actions/session_actions';
import Cookies from 'universal-cookie';
import * as ApiUtil from '../../../util/api_util/session_api_util';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  terminateUserSubscriptionEarly: (data) =>
    dispatch(terminateUserSubscriptionEarly(data)),
  fetchUnsubscriptionReasons: () => dispatch(fetchUnsubscriptionReasons()),
});
const mapStateToProps = (state) => {
  console.log(state);
  const { session, entities, ui } = state;
  console.log(
    session.user,
    'entities.users.currentExplorerDetails',
    entities.users.currentExplorerDetails,
    'ui.components.unsubscribeReasons',
    ui.components.unsubscribeReasons,
  );
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let unsubscribeReasons = ui?.components?.unsubscriptionReasons;
  return { userInfo, currentExplorerDetails, unsubscribeReasons };
};

const ComingSoonPage = ({
  location,
  userInfo,
  terminateUserSubscriptionEarly,
  fetchUnsubscriptionReasons,
  unsubscribeReasons,
  history,
  currentExplorerDetails,
}) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const [planDataAndExplorer, setPlanDataAndExplorer] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    scrollTop();

    fetchUnsubscriptionReasons();
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      var data = qs.stringify({
        grant_type: 'client_credentials',
      });
      try {
        let configlive = {
          method: 'POST',
          url: 'https://api-m.paypal.com/v1/oauth2/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Credentials': true,
          },
          data: qs.stringify({
            grant_type: 'client_credentials',
          }),
          auth: {
            username:
              'AaqHK7225WylmflFkWUBJRX8MrHV5I5Poe6phsNdPwVm8CQsAIZkO3AtM1XlgRqNghN9qpp_qAlAbX1U',
            password:
              'EFAlBCzsaqIygCMoWw2hvaIVlMqpr9v3GJTHLnLLqt0sKnJIDJwUuJY4r7nkHAl7DJ0GH1HYDRDMp7nE',
          },
        };
        let configsandbox = {
          method: 'POST',
          url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Credentials': true,
          },
          data: qs.stringify({
            grant_type: 'client_credentials',
          }),
          auth: {
            username:
              'AdRq-0Ie3ZoKkMxYztBN-2XtI_X24J1eu92e_4o8cp355xRHMk0M126OMEFEqqcUAh0_OuClw_oM6JyB',
            password:
              'EGh4Q3e2Eiixf3C0qejMJTbEl5bMTcH4ohoyq1BwD0OYFdXbwQeGZ9EDq_7AHkGB5KqRrriU9p9ituhD',
          },
        };
        // let config = {
        //   method: 'post',
        //   url: 'https://api-m.paypal.com/v1/oauth2/token',
        //   // url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
        //   headers: {
        //     Authorization:
        //       'Basic QWRScS0wSWUzWm9La014WXp0Qk4tMlh0SV9YMjRKMWV1OTJlXzRvOGNwMzU1eFJITWswTTEyNk9NRUZFcXFjVUFoMF9PdUNsd19vTTZKeUI6RUdoNFEzZTJFaWl4ZjNDMHFlak1KVGJFbDViTVRjSDRvaG95cTFCd0QwT1lGZFhid1FlR1o5RURxXzdBSGtHQjVLcVJycmlVOXA5aXR1aEQ=',
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        //   data,
        // };
        axios(configlive).then(({ data }) => {
          const { access_token, token_type } = data;
          console.log(
            'access_token',
            access_token,
            'access_token_type',
            token_type,
          );
          setAccessToken({
            access_token,
            token_type,
          });
        });
        // debugger
      } catch (err) {}
    };
    fetchToken();
  }, []);
  const [cancelReason, setCancelReason] = useState({
    early_unsubscribe_reason_rid: null,
    early_unsubscribe_reason: null,
  });
  const [feedbackText, setFeedbackText] = useState('');
  const handleToggleSelect = useCallback((event) => {
    const { value } = event.target;
    let parsed = JSON.parse(value);
    if (cancelReason !== parsed) {
      setCancelReason(parsed);
    }
  });
  // const [objectProfile, setObjectProfile] = useState({
  // })
  const deleteAccount = (terminationPayload) => {
    terminateUserSubscriptionEarly({
      terminationPayload,
    }).then(({ status, data }) => {
      console.log(status, data.success);
      if (status === 200) {
        const cookies = new Cookies();
        cookies.remove('userTokenLode', {
          domain: '.lodestarhub.com',
          path: '/',
        });
        cookies.remove('userTokenLode', { domain: 'localhost', path: '/' });
        ApiUtil.setAuthToken(false);
        history.push('/');
        window.location.replace(
          `${
            process.env.REACT_APP_RUN_MODE === 'PROD'
              ? process.env.REACT_APP_PROD_PUB_URL
              : process.env.REACT_APP_RUN_MODE === 'TEST'
              ? process.env.REACT_APP_TEST_PUB_URL
              : process.env.REACT_APP_DEV_PUB_URL
          }/`,
        );
        // window.location.replace("https://localhost:4000/");
      }
    });
  };
  const unsubscribeHandler = useCallback(async (event) => {
    // event.preventDefault();
    event.stopPropagation();
    let data = {
      reason: 'im not canceling', // replace this with reaosn
    };

    let config = {
      method: 'post',
      url: `https://api-m.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/cancel`, // the I- has to be passed from the user's subscription id
      // url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${currentExplorerDetails.subscription_partner_code}/cancel`, // the I- has to be passed from the user's subscription id
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
      },
      data,
    };
    console.log(config);

    let terminationPayload = {
      account_rid: userInfo.account_rid,
      explorer_rid: userInfo.explorer_rid,
      early_unsubscribe_reason_rid: cancelReason.early_unsubscribe_reason_rid,
      early_unsubscribe_reason: feedbackText,
    };
    console.log(terminationPayload);
    if (!currentExplorerDetails.subscription_partner_code) {
      terminationPayload.isTiral = true;
      deleteAccount(terminationPayload);
    }
    axios(config).then((res) => {
      terminationPayload.isTiral = false;
      deleteAccount(terminationPayload);
    });
  });
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="WHY ARE YOU CANCELLING?" />
          <section className="account-cancel-reasons-wrapper">
            {/* <section className="account-cancel-reasons-context-wrapper account-cancel-reasons-context-wrapper--banner">
              <img
                className="account-cancel-reasons-banner"
                src="https://storage.googleapis.com/lodestar-vpc-host-nonprod.appspot.com/app_media/app_product_image/lodestar_explorer_logo.svg"
              />
            </section> */}
            <section className="account-cancel-reasons-context-wrapper account-cancel-reasons-context-wrapper--text">
              {/* <div className="account-cancel-message-header-wrapper">
                <h2>Before you go, what is your reason for canceling?</h2>
              </div>
              <hr className="account-cancel-message-divider" /> */}
              <div classname="account-cancel-message-reasons-choices">
                {unsubscribeReasons &&
                  unsubscribeReasons.map((ele) => (
                    <Item>
                      <RadioButton
                        type="radio"
                        name="radio"
                        value={JSON.stringify(ele)}
                        checked={
                          cancelReason.early_unsubscribe_reason_rid ===
                          ele.early_unsubscribe_reason_rid
                        }
                        onChange={handleToggleSelect}
                      />
                      <RadioButtonLabel />
                      <span className="toggle-span">
                        {ele.early_unsubscribe_reason}
                      </span>
                    </Item>
                  ))}
              </div>
              <textarea
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
                className="account-cancel-feedback"
              />
            </section>
            <section className="account-cancel-reasons-context-wrapper account-cancel-reasons-context-wrapper--confirm">
              <button
                className="account-cancel-reason-button --confirm"
                onClick={unsubscribeHandler}
              >
                Cancel my membership
              </button>
              <button
                className="account-cancel-reason-button --cancel"
                onClick={() => history.push('/hub/account/manage-subscription')}
              >
                Go Back
              </button>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComingSoonPage));
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  position: relative;
  flex-direction: row;
`;
const RadioButtonLabel = styled.label`
  position: absolute;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    `
  &:checked + ${RadioButtonLabel} {
    background: #09132b;
    border: 1px solid #09132b;
    &::after{
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      box-shadow: 1px 3px 3px 1px #09132b;
      background: white;
    }
  }
  `}
`;
