import merge from 'lodash/merge';

import {
  RECEIVE_USER_ITINERARY_DETAILS,
  RECEIVE_MOVED_BLOCK,
  RECEIVE_MOVED_BLOCK_BETWEEN_ITEM,
  RECEIVE_ITINERARY_ITEM_COPIED_BLOCK,
  RECEIVE_BLOCK_VOTE,
  RECEIVE_ITINERARY_ITEM_BLOCKS,
  RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED,
  RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE,
  RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE,
  RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST,
  UPDATE_VOTE_INFO,
} from '../../constants';
const ItineraryReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  var noop = function () {};
  switch (action.type) {
    case RECEIVE_USER_ITINERARY_DETAILS:
      newState['item_blocks'] = action.data.itinerary_item_blocks;
      return newState;
    case UPDATE_VOTE_INFO:
      let item_blocks = state['item_blocks'];
      newState['item_blocks'] = {
        ...Object.keys(item_blocks).map((key) => ({
          key: item_blocks[key].map((e) =>
            e.block_rid === action.data.block_rid
              ? {
                  ...e,
                  ...action.data,
                }
              : e,
          ),
        })),
      };
      return newState;
    case RECEIVE_MOVED_BLOCK:
      newState.item_blocks[action.data.itinerary_item_rid] = action.data.blocks;
      return newState;
    case RECEIVE_MOVED_BLOCK_BETWEEN_ITEM:
      const {
        org_itinerary_item_rid,
        itinerary_item_rid,
        block,
        blocks,
      } = action.data;

      if (org_itinerary_item_rid != itinerary_item_rid) {
        const modifiedItems = newState.item_blocks[
          org_itinerary_item_rid
        ].filter((ele) => {
          return (
            ele.itineriary_block_rid !== parseInt(block.itineriary_block_rid)
          );
        });
        newState.item_blocks[org_itinerary_item_rid] = modifiedItems;
        newState.item_blocks[itinerary_item_rid] = blocks;
      } else {
        newState.item_blocks[itinerary_item_rid] = blocks;
      }

      return newState;
    case RECEIVE_ITINERARY_ITEM_COPIED_BLOCK:
      newState.item_blocks[action.itineraryItemRid] = action.data.userBlocks;

      return newState;
    case RECEIVE_BLOCK_VOTE:
      let iterate = null;
      let index = null;
      let itBlockCollection = newState.item_blocks[action.itineraryItemRid];
      if (action.data.userBlockVote.length > 0) {
        for (let i = 0; iterate === null; i++) {
          let currItineraryBlock = itBlockCollection[i];
          if (
            currItineraryBlock.itineriary_block_rid ===
            action.data.userBlockVote[0].itinerary_block_rid
          ) {
            iterate = true;
            index = i;
          }
        }
        newState.item_blocks[action.itineraryItemRid][
          index
        ].total_vote_count_down =
          action.data.userBlockVote[0].total_vote_count_down;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].total_vote_count_up =
          action.data.userBlockVote[0].total_vote_count_up;
        newState.item_blocks[action.itineraryItemRid][index].explorer_vote_up =
          action.data.userBlockVote[0].explorer_vote_up;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].explorer_vote_down = action.data.userBlockVote[0].explorer_vote_down;
        newState.item_blocks[action.itineraryItemRid][index].explorer_vote_rid =
          action.data.userBlockVote[0].explorer_vote_rid;
      } else {
        for (let i = 0; iterate === null; i++) {
          let currItineraryBlock = itBlockCollection[i];
          if (
            currItineraryBlock.itineriary_block_rid === action.itineraryBlockRid
          ) {
            iterate = true;
            index = i;
          }
        }
        let explorer_vote_down =
          newState.item_blocks[action.itineraryItemRid][index]
            .explorer_vote_down;
        let explorer_vote_up =
          newState.item_blocks[action.itineraryItemRid][index].explorer_vote_up;
        let prev_vote_down_count =
          newState.item_blocks[action.itineraryItemRid][index]
            .total_vote_count_down;
        let prev_vote_up_count =
          newState.item_blocks[action.itineraryItemRid][index]
            .total_vote_count_up;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].total_vote_count_down = explorer_vote_down
          ? prev_vote_down_count - 1
          : prev_vote_down_count;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].total_vote_count_up = explorer_vote_up
          ? prev_vote_up_count - 1
          : prev_vote_up_count;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].explorer_vote_up = false;
        newState.item_blocks[action.itineraryItemRid][
          index
        ].explorer_vote_down = false;
      }
      return newState;
    case RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED:
      newState.item_blocks[action.organizerData.itinerary_item_rid] =
        action.data.userBlocks;
      return newState;
    case RECEIVE_ITINERARY_ITEM_BLOCKS:
      newState.item_blocks[action.itineraryItemRid] =
        action.data.itineraryItemBlocks;
      return newState;
    case RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE:
      newState.item_blocks[action.data.itinerary_item_rid][
        action.data.idx
      ].comment_count += 1;
      return newState;
    case RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE:
      // debugger;
      // debugger;
      // newState.item_blocks[action.data.itinerary_item_rid].splice(
      //   action.data.index,
      //   0,
      //   action.data.block
      // );
      return newState;
    case RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST:
      let promises = [];
      for (let i = 0; i < action.data.itineraryItemRids.length; i++) {
        let itineraryItemRid =
          action.data.itineraryItemRids[i].itinerary_item_rid;
        promises.push(
          newState.item_blocks[itineraryItemRid]
            ? newState.item_blocks[itineraryItemRid].forEach((ele, idx) => {
                if (ele.adventure_block_rid === action.data.adventureBlockRid)
                  action.data.actionType === '+'
                    ? (newState.item_blocks[itineraryItemRid][
                        idx
                      ].completed_checklist_total += 1)
                    : (newState.item_blocks[itineraryItemRid][
                        idx
                      ].completed_checklist_total -= 1);
              })
            : noop(),
        );
      }
      Promise.allSettled([promises]).then(() => {
        return newState;
      });
      return newState;
    default:
      return state;
  }
};

export default ItineraryReducer;
