import axios from 'axios';
import { PUBLIC_SITE_URL } from 'constants/env';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { getUnixEpochSeconds } from 'util/unix_time';

const COOKIE_TOKEN_NAME = 'userTokenLode';

// We've been using this method in previos steps
export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const signup = (userData) => {
  return axios.post('/api/e/explorers', userData);
};

export const login = (userData) => {
  return axios.post('/api/e/signin_placeholder', userData);
};

export class InvalidAuthSession extends Error {
  constructor(message = 'Invalid Auth Session') {
    super(message);
    this.name = 'InvalidAuthSession';
  }
}

export const isValidAuthSession = () => {
  const decodedToken = getTokenDecodedFromCookie();
  const isExpired = tokenIsExpired(decodedToken);
  if (isExpired) {
    throw new Error('expired token');
  }
  return true;
};

export const tokenIsExpired = (token) => {
  // check if current time has passed jwt exp time
  return getUnixEpochSeconds(new Date()) >= token.exp;
};

export class JwtParseError extends Error {
  constructor(message = 'Failed to parse JWT') {
    super(message);
    this.name = 'JwtParseError';
  }
}

export const getTokenDecodedFromCookie = () => {
  const token = getEncodedTokenFromCookie();
  try {
    return jwtDecode(token);
  } catch (err) {
    throw new JwtParseError(err.message);
  }
};
export class CookieNotFoundError extends Error {
  constructor(message = 'Cookie not found') {
    super(message);
    this.name = 'CookieNotFoundError';
  }
}
export const getEncodedTokenFromCookie = () => {
  // get token
  const cookie = new Cookies();
  try {
    return cookie.get(COOKIE_TOKEN_NAME);
  } catch (err) {
    throw new CookieNotFoundError(err.message);
  }
};

export const goToPublicSite = () => {
  window.location.href = PUBLIC_SITE_URL;
};

export const goToLogin = () => {
  goToPublicSite();
};
