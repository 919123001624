export const svgSyncCalendar = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      height="100px"
      width="100px"
      fill="#09132b"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
    >
      {/* <metadata> */}
      {/* <sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/">
          <slices>
          </slices>
          <sliceSourceBounds y="-8160" x="-8165" width="16389" height="16384" bottomLeftOrigin="true">
            </sliceSourceBounds>
          </sfw> */}
      {/* </metadata> */}
      <g>
        <g>
          <path
            className={className}
            d="M53.5,10H50V8c0-1.104-0.896-2-2-2s-2,0.896-2,2v2H18V8c0-1.104-0.896-2-2-2s-2,0.896-2,2v2h-3.5    C8.019,10,6,12.019,6,14.5v39c0,2.481,2.019,4.5,4.5,4.5h43c2.481,0,4.5-2.019,4.5-4.5v-39C58,12.019,55.981,10,53.5,10z M10.5,14    H14v2c0,1.104,0.896,2,2,2s2-0.896,2-2v-2h28v2c0,1.104,0.896,2,2,2s2-0.896,2-2v-2h3.5c0.275,0,0.5,0.224,0.5,0.5V20H10v-5.5    C10,14.224,10.224,14,10.5,14z M53.5,54h-43c-0.276,0-0.5-0.225-0.5-0.5V24h44v29.5C54,53.775,53.775,54,53.5,54z"
          ></path>
          <path
            className={className}
            d="M41.909,33.436c-0.02-0.067-0.035-0.135-0.062-0.2c-0.029-0.068-0.068-0.131-0.104-0.195    c-0.027-0.05-0.049-0.101-0.081-0.149c-0.073-0.11-0.157-0.213-0.25-0.307l-3.999-3.999c-0.781-0.781-2.047-0.781-2.828,0    c-0.781,0.781-0.781,2.047,0,2.828L35.172,32H24c-1.104,0-2,0.896-2,2s0.896,2,2,2h11.172l-0.586,0.586    c-0.781,0.781-0.781,2.047,0,2.828C34.977,39.805,35.488,40,36,40s1.023-0.195,1.414-0.586l3.999-3.999    c0.093-0.093,0.177-0.196,0.25-0.307c0.032-0.047,0.054-0.099,0.081-0.148c0.035-0.064,0.074-0.127,0.104-0.195    c0.026-0.065,0.042-0.133,0.062-0.2c0.017-0.058,0.039-0.113,0.052-0.173c0.051-0.259,0.051-0.524,0-0.783    C41.948,33.549,41.926,33.493,41.909,33.436z"
          ></path>
          <path
            className={className}
            d="M40,42H28.828l0.586-0.586c0.781-0.781,0.781-2.047,0-2.828c-0.78-0.781-2.048-0.781-2.828,0l-3.999,3.999    c-0.094,0.093-0.177,0.196-0.251,0.307c-0.032,0.047-0.053,0.099-0.081,0.148c-0.036,0.064-0.075,0.127-0.103,0.195    c-0.027,0.065-0.042,0.133-0.062,0.2c-0.017,0.058-0.04,0.113-0.051,0.173c-0.051,0.259-0.051,0.524,0,0.783    c0.012,0.06,0.034,0.115,0.051,0.173c0.02,0.067,0.035,0.135,0.062,0.2c0.028,0.068,0.067,0.131,0.103,0.195    c0.027,0.05,0.049,0.102,0.081,0.148c0.074,0.11,0.157,0.214,0.251,0.307l3.999,3.999C26.976,49.805,27.488,50,28,50    s1.024-0.195,1.414-0.586c0.781-0.781,0.781-2.047,0-2.828L28.828,46H40c1.104,0,2-0.896,2-2S41.104,42,40,42z"
          ></path>
        </g>
      </g>
    </svg>
  );
};