export function snowBoard (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M59.88,42.569l-2.45-2.45c-0.693-0.693-1.617-1.076-2.599-1.076c-0.982,0-1.905,0.383-2.6,1.078  c-0.694,0.693-1.076,1.615-1.076,2.598c0,0.983,0.382,1.906,1.075,2.601l2.448,2.45c1.435,1.434,3.768,1.434,5.201,0  C61.313,46.336,61.313,44.002,59.88,42.569z M47.769,54.682l-2.449-2.451c-0.693-0.695-1.617-1.076-2.6-1.076  s-1.906,0.383-2.6,1.076c-0.694,0.693-1.076,1.617-1.076,2.6s0.382,1.906,1.075,2.6l2.448,2.451c1.436,1.432,3.769,1.434,5.201,0  C49.202,58.447,49.202,56.115,47.769,54.682z M58.128,58.129c6.192-6.191,14.427-13.277,17.792-16.133  c0.533-0.389,1.053-0.811,1.535-1.293c5.016-5.014,5.014-13.145,0-18.158s-13.143-5.014-18.158,0.001  c-0.482,0.481-0.904,0.999-1.295,1.534c-2.854,3.367-9.94,11.6-16.13,17.791c-6.191,6.191-14.425,13.275-17.791,16.131  c-0.536,0.391-1.054,0.813-1.537,1.293c-5.014,5.016-5.014,13.145,0,18.16c5.016,5.014,13.145,5.014,18.158,0  c0.484-0.482,0.902-1.002,1.294-1.535C44.851,72.553,51.937,64.32,58.128,58.129z M41.508,60.941L39.06,58.49  c-0.978-0.977-1.516-2.277-1.516-3.66s0.537-2.684,1.516-3.66c2.018-2.02,5.302-2.02,7.319-0.002l2.449,2.451  c0.978,0.979,1.517,2.279,1.517,3.662s-0.537,2.682-1.516,3.66C46.813,62.959,43.528,62.959,41.508,60.941z M53.618,48.83  l-2.447-2.449c-0.978-0.979-1.516-2.278-1.516-3.662c0-1.383,0.538-2.683,1.516-3.659c0.977-0.978,2.277-1.517,3.66-1.517  c1.382,0,2.682,0.539,3.66,1.516l2.449,2.449c2.018,2.019,2.018,5.304,0,7.322C58.923,50.848,55.639,50.848,53.618,48.83z"
        ></path>
      </g>
    </svg>
  );
}