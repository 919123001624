import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { updateUserPassword } from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import passwordValidator from 'password-validator';
import { Input } from 'components/global_modal/modal_modules';
import { Headers } from './shared_modules';

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (data) => dispatch(updateUserPassword(data)),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;

  return {
    userInfo,
  };
};

const ComingSoonPage = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const { title, userInfo, updateUserPassword } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    scrollTop();

    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  const [inputValues, setInputValues] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
    fromExplorer: true,
    explorer_rid: userInfo.explorer_rid,
  });
  // const [objectProfile, setObjectProfile] = useState({
  // })

  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: '',
    emailErrors: '',
    passwordErrors: [],
    serverMessage: '',
    success: false,
  });
  //TAKES IN SECTION DATA AND NAME, COPIES TO FULL OBJ AND SETS SAVED TRUE FOR SECTION
  let schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(25) // Maximum length 25
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 number
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();
  const handleChange = useCallback((event) => {
    const { value, name } = event.currentTarget;
    if (name === 'new_password') {
      let errors = schema.validate(value, { list: true });
      setSignUpErrors({ ...signUpErrors, passwordErrors: errors });
    }
    setInputValues({ ...inputValues, [name]: value });
  });
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    updateUserPassword(inputValues).then((res) => {
      if (res.status === 200) {
        if (res.data.error) {
          setSignUpErrors({
            ...signUpErrors,
            serverMessage: 'Current password incorrect',
            success: false,
          });
        }
        if (res.data.success) {
          setSignUpErrors({
            ...signUpErrors,
            serverMessage: 'Password successfully updated',
            success: true,
          });
        }
      }
    });
  });
  const renderPasswordErrors = () => {
    if (
      inputValues.new_password.length > 0 ||
      inputValues.confirm_new_password.length > 0
    ) {
      return (
        <div className="email-error-container modal-password-error-wrapper">
          {signUpErrors.passwordErrors.includes('min') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('min')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must be minimum 8 characters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('symbols') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('symbols')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>
                Password must contain at least one symbol (i.e ! @ # $ % ^ & * )
              </span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('max') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('max')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must be maximum 25 characters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('uppercase') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('uppercase')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must contain uppercase letters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('lowercase') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('lowercase')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must contain lowercase letters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('digits') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('digits')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must have at least 1 number</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('spaces') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('spaces')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password cannot have spaces</span>
            </div>
          )}
          <div className="email-error-wrapper">
            <img
              alt="aler-pic"
              src={
                inputValues.new_password === inputValues.confirm_new_password
                  ? '/symbols/bluecheck.png'
                  : '/symbols/red-alert.png'
              }
            />
            <span>
              {inputValues.new_password === inputValues.confirm_new_password
                ? 'Passwords match'
                : 'Passwords not matched'}
            </span>
          </div>
        </div>
      );
    } else {
      return <div className="email-error-container" />;
    }
  };
  //TAKES IN SETTER STATE FUNCTION CALL TO RESET AND OBJECT TO RESET
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>

      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="PASSWORD" />

          <form
            class="general-section-container account-change-password-container"
            id="general-section-container-mobile"
            onSubmit={handleSubmit}
          >
            <header className="general-header">CHANGE PASSWORD</header>
            <hr />
            <section className="account-info-contents-wrapper">
              <Input
                label="Current password"
                name="current_password"
                value={inputValues.current_password}
                onChangeHandler={handleChange}
                type="password"
                customParentClassName="account-password-change-input"
              />
              <Input
                label="New password"
                name="new_password"
                value={inputValues.new_password}
                onChangeHandler={handleChange}
                type="password"
                customParentClassName="account-password-change-input"
              />
              <Input
                label="Confirm new password"
                name="confirm_new_password"
                value={inputValues.confirm_new_password}
                onChangeHandler={handleChange}
                type="password"
                customParentClassName="account-password-change-input"
              />
              {renderPasswordErrors()}
              <div className="general-section-footer-container">
                <div className="general-section-footer">
                  <button
                    className="general-button general-button--save"
                    disabled={
                      inputValues.current_password === '' ||
                      inputValues.new_password === '' ||
                      inputValues.confirm_new_password === '' ||
                      inputValues.new_password !==
                        inputValues.confirm_new_password ||
                      signUpErrors.passwordErrors.length > 0
                    }
                  >
                    Save Password
                  </button>
                  <div
                    className={`account-change-password-status-message-wrapper ${
                      signUpErrors.success && '--success'
                    }`}
                  >
                    <span className="account-change-password-status-message">
                      {signUpErrors.serverMessage.length > 0 &&
                        signUpErrors.serverMessage}
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonPage);
