import React from 'react';
import { FloatingLabelInput } from 'components/ui_library';
const FloatLabelInput = ({
  onChangeHandler,
  name,
  value,
  label,
  spacingTop,
  spacingBottom,
  triggerBlur,
  onBlur,
  displaySearchButton,
  searchButtonClickHandler,
  customClassName,
  defaultActive,
  type,
  important,
  customParentClassName,
  id,
  disabled,
  step,
  min,
  autocomplete,
  ...rest
}) => {
  return (
    <div
      className={`mod-fl-lab-inpt-wrapper ${
        customParentClassName && customParentClassName
      }`}
      style={{
        marginTop: spacingTop ? spacingTop : '0px',
        marginBottom: spacingBottom ? spacingBottom : '0px',
      }}
    >
      <FloatingLabelInput
        className={`floating-lode-input ${customClassName && customClassName}`}
        defaultActive={defaultActive ? true : false}
        type={type ? type : 'text'}
        onChange={onChangeHandler}
        step={step}
        min={min}
        name={name}
        value={value || ''}
        onBlur={
          triggerBlur
            ? () => {
                onBlur();
              }
            : null
        }
        label={label}
        important={important}
        disabled={disabled}
        autocomplete="false"
        id={id}
      />
      {displaySearchButton && (
        <button
          className="modal--input--search--button"
          onClick={(e) => {
            e.preventDefault();
            searchButtonClickHandler();
          }}
        >
          Search
        </button>
      )}
    </div>
  );
};

export default FloatLabelInput;
