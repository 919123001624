export const svgCompass = (
  color,
  className,
  outerLayer,
  width = 15,
  height = 15,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox={viewBox ? viewBox : '0 0 100 100'}
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M50,0 C22.435,0 0,22.431 0,50 C0,77.569 22.431,100 50,100 C77.565,100 100,77.569 100,50 C100,22.431 77.569,0 50,0 z M77.496,23.988 L50.7,82.938 C49.273,86.065 44.673,85.542 43.996,82.165 L39.565,60 C39.285,58.6 38.188,57.504 36.785,57.219 L14.623,52.788 C11.25,52.112 10.719,47.512 13.85,46.088 L72.8,19.288 C75.788,17.942 78.854,21.008 77.496,23.988 z"
          fill={color}
        />
      </g>
    </svg>
  );
};