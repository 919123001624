import React from 'react';

const Banner = (props) => {
  return (
    <div className="banner-regular-container" id="banner-regular-mobile">
      <img
        alt={`banner-pic for ${props.bigText}`}
        className="banner-landing-img"
        src={props.photo}
      />
      <div className="banner-text-block" style={props.style}>
        <div className="banner-lading-header-large">
          <span style={props.additionalStyles}>
            {props.bigText} <br />
          </span>
        </div>
        <div className="banner-lading-header-small">
          <span>{props.smallText}</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
