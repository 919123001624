export function nonFlyFishing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 95 51.4"
      className={outerLayer}
    >
      <path
        className={className}
        d="M56.7,13.6c-1.4-1.4-3.7-1.5-5.2-0.1C49.5,12.2,29.3-1,5.3,0.1c-1.9,0-3.6,1-4.6,2.6C0,3.9-0.2,5.4,0.2,6.8  c1.1,4.6,4.4,16.9,5.9,17.7c0.6,0.3,7.8-0.2,16.4-1.1c-0.6,1.5-0.2,3.2,1,4.3c0.6,0.5,1.3,0.8,2,0.8l0.2,2.8l0,0l0.8,8.6l0.4,5v0.1  c0.1,1.1-0.3,2.2-1,3.1c-0.7,0.6-1.6,0.9-2.6,0.8c-1.1,0-2-0.5-2.7-1.3c-0.7-1.1-1.2-2.3-1.2-3.6l1.5-2.6l-2.8-7.8  c0,0-1.1,6.2-1,8.9c0,0.1,0,0.1,0,0.2c0,1.9,0.3,4.5,1.7,6.4c1.1,1.3,2.6,2.1,4.4,2.2h0.5c1.4,0.1,2.8-0.4,3.8-1.4  c0.3-0.3,0.6-0.7,0.9-1.2c0.3,0.4,0.7,0.8,1.1,1.1c0.8,0.6,1.8,0.9,2.9,0.9c0.6,0,1.1-0.1,1.7-0.2c1.7-0.3,3.1-1.4,3.9-2.9  c1.5-2.8,0.7-6.6,0.2-8.2c-0.7-3-2.2-7.2-2.2-7.2l-1.5,8.2l1.9,2.3c0.2,1.3,0,2.6-0.6,3.8c-0.5,0.9-1.4,1.6-2.4,1.8  c-0.9,0.3-1.9,0.2-2.7-0.3c-0.9-0.8-1.4-1.8-1.6-3c0-0.1,0-0.2,0-0.3l-0.4-4.9l-1-11.8c1.8-1,2.4-3.3,1.4-5.1c0-0.1-0.1-0.1-0.1-0.2  c10-1.2,19.9-2.7,22.4-3.9l0,0c1.5,1.5,3.8,1.5,5.3,0S58.1,15,56.7,13.6L56.7,13.6z M24.8,23.9c0.5-0.6,1.4-0.7,2.1-0.2  c0.6,0.5,0.7,1.4,0.2,2.1c-0.5,0.6-1.4,0.7-2.1,0.2C24.4,25.4,24.3,24.5,24.8,23.9C24.8,23.9,24.8,23.9,24.8,23.9z M13.7,4.5  c0,0.4-1,0.8-2.3,0.8S9,5.2,9,4.8s1-0.8,2.3-0.8S13.7,4.1,13.7,4.5z M3.7,2.5c0-0.5,1.4-0.9,3-0.9s3,0.4,3,0.9s-1.4,0.9-3,0.9  S3.7,3,3.7,2.5L3.7,2.5z M11.1,20.2c-2.4,0-4.3-2-4.3-4.4c0-2.4,2-4.3,4.4-4.3c2.4,0,4.3,2,4.3,4.4c0,0,0,0,0,0  C15.5,18.3,13.6,20.2,11.1,20.2C11.1,20.2,11.1,20.2,11.1,20.2L11.1,20.2z M15.1,3c-1.3-0.1-2.3-0.4-2.3-0.8s1.1-0.6,2.4-0.6  s2.3,0.4,2.3,0.8S16.4,3.1,15.1,3L15.1,3z M18.6,5.7c-0.9-0.1-1.6-0.5-1.5-0.8s0.8-0.4,1.7-0.3s1.6,0.5,1.5,0.8S19.5,5.9,18.6,5.7  L18.6,5.7z M20.4,3.1c0.1-0.4,1-0.5,2.2-0.3s2,0.7,2,1.1s-1,0.5-2.2,0.3S20.3,3.5,20.4,3.1L20.4,3.1z M55.1,17.2  c-0.6,0.6-1.5,0.6-2.1,0c0,0,0,0,0,0l-0.1-0.1c0.2-0.6,0.2-1.3,0-1.9l0,0c0.6-0.6,1.5-0.6,2.1,0S55.6,16.7,55.1,17.2L55.1,17.2z"
      ></path>
      <path
        className={className}
        d="M89.5,17.7c1.9,0.3,3.7,0.7,5.5,1.3c-1.7-2.2-4.2-3.7-7-3.8l2.4-1.6c2.6-1.8,4.2-4.8,4.3-8c-2.8,2.3-10.6,5-10.6,5  c4.6-3.1,8-5.3,8-5.3c-1.8-0.5-3.7-0.1-5.1,1c-4.4,3.4-16.8,6-16.8,6l9.2-4.7c-2.5,0-5,0.2-7.4,0.7c-4.7,0.9-13.1,5.3-13.1,5.3  l0.6,1.4c0,0,0.4,0.6-0.3,3.3c0,0,8.4,4.4,13.1,5.3c2.4,0.5,4.9,0.7,7.4,0.7l-9.1-4.5c0,0,12.4,2.6,16.8,6c1.5,1.1,3.4,1.5,5.1,1  c0,0-3.4-2.1-8-5.3c0,0,7.7,2.7,10.6,5C95,26.6,93.9,20.4,89.5,17.7z"
      ></path>
      <circle cx="10.1" cy="15.8" r="2"></circle>
    </svg>
  );
}