import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddIcon, LikeIcon, SavedIcon, SaveIcon, ShareIcon } from '../svgs';
//import UserAvatar from '../../../../../components/user-bar/components/user-avatar';
import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import TooltipButton from '../../../../../components/hub-button/tooltip-button';
import {
  deleteAdventureTag,
  //upsertAdventureVote,
  fetchAdventureVoteInfo,
  getAdventureHolderImage,
  insertAdventureTag,
  returnAdventureTag,
} from '../../../../../util/api_util/adventure_api_util';
import { styleValues } from '../constants';
import DropDownButton from './drop-down-button';
import './friend-images.scss';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
}));

const TripHeader = ({
  openAdventureModal,
  deleteAdventure,
  openDeleteModal,
  openAdventureCopyModal,
}) => {
  const curAdv = useSelector(
    (state) => state.entities?.adventure?.selectedAdventure || null,
  );
  const curUser = useSelector((state) =>
    state.session?.user?.userInfo ? state.session?.user?.userInfo[0] : null,
  );
  const friends = useSelector((state) =>
    state.entities?.social?.adventureFriends
      ? state.entities?.social?.adventureFriends
      : null,
  );
  const myAccount =
    friends?.filter((friend) => friend.explorer_rid === curUser?.explorer_rid)
      .length > 0
      ? friends?.filter(
          (friend) => friend.explorer_rid === curUser?.explorer_rid,
        )
      : null;

  const classes = useStyles();
  const [activeId, setActiveId] = useState(-1);
  const [friendAvatars, setFriendAvatars] = useState([]);
  const [tripHolder, setTripHolder] = useState(undefined);
  const [adventureMark, setAdventureMark] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/ui/friends-images/${curAdv.adventure_rid}/0/5`)
      .then((res) => {
        let temp = res?.data?.images || [];
        setFriendAvatars(
          temp.filter(
            (e, i) =>
              temp.map(JSON.stringify).lastIndexOf(JSON.stringify(e)) === i,
          ),
        );
      });
    getAdventureHolderImage(curAdv?.adventure_rid).then((res) => {
      setTripHolder(res);
    });
    fetchAdventureVoteInfo(curAdv?.adventure_rid, curUser?.explorer_rid).then(
      (res) => {
        // For Trip Vote
      },
    );
    returnAdventureTag(curUser?.account_rid, curAdv?.adventure_rid).then(
      (res) => {
        let arr = res?.data?.adventure_tag;
        setAdventureMark(
          arr
            ? Array.isArray(arr)
              ? arr[0].func_return_adventure_tag
              : false
            : false,
        );
      },
    );
  }, []);

  const handleMarkTrip = () => {
    if (adventureMark) {
      deleteAdventureTag(curUser?.account_rid, curAdv?.adventure_rid).then(
        (res) => {
          let arr = res?.data?.adventure_tag;
          setAdventureMark(
            arr
              ? Array.isArray(arr)
                ? arr[0].func_return_adventure_tag
                : false
              : false,
          );
        },
      );
    } else {
      insertAdventureTag(curUser?.account_rid, curAdv?.adventure_rid).then(
        (res) => {
          let arr = res?.data?.adventure_tag;
          setAdventureMark(
            arr
              ? Array.isArray(arr)
                ? arr[0].func_insert_adventure_tag
                : false
              : false,
          );
        },
      );
    }
  };
  const onClick = (id) => (id === activeId ? setActiveId(-1) : setActiveId(id));
  return (
    <Box>
      <Grid container space={2}>
        <Grid item md={8} sm={7} xs={12} lg={9} className={classes.grid}>
          <Typography
            component={'div'}
            className={[
              classes.font,
              classes.bold,
              classes.subTitleFontSize,
            ].join(' ')}
          >
            {curAdv?.adventure_name}
          </Typography>
        </Grid>
        <Grid item md={4} sm={5} xs={12} lg={3} className={classes.gridRight}>
          <Box
            style={{
              display: 'flex',
              width: '200px',
            }}
            className={[
              classes.svgSize,
              'setting-bar',
              classes.margin_12,
              classes.flexDynamic,
            ].join(' ')}
          >
            {myAccount?.some((ac) => ac.friend_role_rid === 3) ||
            curAdv?.account_rid === curUser?.account_rid ? (
              <>
                <DropDownButton
                  openAdventureModal={openAdventureModal}
                  deleteAdventure={deleteAdventure}
                  openDeleteModal={openDeleteModal}
                  openAdventureCopyModal={openAdventureCopyModal}
                />
                {/* <TooltipButton icon={ShareIcon} label="Share Trip" />    */}
              </>
            ) : myAccount?.some((ac) => ac.friend_role_rid === 1) ? (
              <>
                <TooltipButton icon={ShareIcon} label="Share Trip" />
                <TooltipButton icon={LikeIcon} label="Like trip" />
                <TooltipButton
                  icon={adventureMark ? SavedIcon : SaveIcon}
                  label={adventureMark ? 'Remove Tag' : 'Tag trip'}
                  onClick={() => handleMarkTrip()}
                />
                <TooltipButton icon={AddIcon} label="Add trip" />
              </>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
      <div className="container">
        {friendAvatars.length > 0 &&
          friendAvatars.map((card, index) => (
            <div
              key={index}
              className={`panel ${activeId === index ? 'active' : ''}`}
              onClick={() => onClick(index)}
            >
              <div className="avatar">
                <img
                  src={
                    card.ui_image_location
                      ? card.ui_image_location
                      : '/svg_assets/person_light_orange.svg'
                  }
                />
              </div>
              <h3>{card.friend_name}</h3>
            </div>
          ))}
      </div>
      {/* <Box
        className={classes.grid}
        style={{
          position: 'relative',
          height: '42px',
          width: `${20 * (friendAvatars.length + 1)}px`,
        }}
      >
        {friendAvatars.length > 0 &&
          friendAvatars.map((image, index) => (
            <Box
              key={index}
              className={classes.avatar}
              style={{ left: `${20 * index}px` }}
            >
              <UserAvatar width="40px" height="40px" user={image} />
            </Box>
          ))}
        {/* <Box
          className={classes.avatar}
          style={{
            left: `${
              friendAvatars.length === 0 ? 0 : 20 * friendAvatars.length + 40
            }px`,
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <UserAvatar width="40px" height="40px" user={curUser} />
          <Typography
            style={{
              fontFamily: 'Soleil',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '25px',
              textAlign: 'center',
              color: '#273A07',
              marginLeft: '16px',
            }}
          >
            {curUser?.friend_name}
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default TripHeader;
