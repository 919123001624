import React from 'react';
import { UserAvatarContainer } from './user-bar.styled';
import "./user-avatar.scss";

const UserAvatar = (props) => {
  return (
    <UserAvatarContainer className="user-avatar">
      <img
        alt="pwa-nav-bar"
        src={
          props?.user?.ui_image_location
            ? props?.user?.ui_image_location
            : '/svg_assets/person_light_orange.svg'
        }
      />
    </UserAvatarContainer>
  );
};

export default UserAvatar;
