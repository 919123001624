import {
  fetchAdventureFriend,
  fetchAdventureGroup,
  updateAdventureAttendee,
  deleteAdventureFriend,
} from '../../../actions/social_actions';
import { updateToken } from '../../../actions/session_actions';
import {
  fetchUserAdventureInformation,
  postAdventureBlock,
  fetchUserAdventures,
  fetchAdventureBlocks,
  deleteAdventure,
  fetchSingleAdventure,
  fetchItineraryInformation,
  flushCurrentAdventure,
} from '../../../actions/adventure_actions';
import {
  fetchAllBlocksItinerary,
  fetchBlocksDynamic,
  moveToAdventureBlock,
  upsertBlock,
} from '../../../actions/block_actions';
import {
  openAdventureModal,
  openItineraryModal,
  openBlockModal,
  openDeleteModal,
  openFriendsModal,
  openAdventureGroupModal,
  openAdventureCopyModal,
  setLoading,
} from '../../../actions/modal_actions';
import {
  flushSelectedPartners,
  fetchTransportationTypes,
  fetchSocialCostOfCarbon,
} from '../../../actions/ui_actions';
import { calculateImpactForTrip, fetchLocationBlocks, fetchItineraryDetails } from '../../../actions/itinerary_actions';

export const mapStateToProps = (state) => {
  const account_rid = state.session.user.account_rid || null;
  const adventureBlocks = state.entities?.itinerary?.adventure_blocks || null;
  const current_adventure =
    state.entities?.adventure?.current_adventure || null;
  const current_adventure_data = state?.entities?.adventure?.selectedAdventure;
  const transportationTypes = state.ui?.components?.transportationTypes || [];
  const user_adventure_friend = state.entities.social.adventureFriends || [];
  const user_adventure_group = state.entities.social.adventureGroups || [];
  const user_block_activites =
    state.entities.blocks.user_block_activities || null;
  const user_block_activity_details =
    state.entities.blocks.user_block_activity_details || null;
  const user_block_categories =
    state.entities.blocks.user_block_categories || null;
  const userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  const userTokenParsed = state.session.user ? state.session.user : null;

  return {
    account_rid,
    adventureBlocks,
    current_adventure,
    current_adventure_data,
    transportationTypes,
    user_adventure_friend,
    user_adventure_group,
    user_block_activites,
    user_block_activity_details,
    user_block_categories,
    userInfo,
    userTokenParsed,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchItineraryDetail: (itinerary_rid, account_rid, explorer_rid) =>
      dispatch(fetchItineraryDetails(itinerary_rid, account_rid, explorer_rid)),
    fetchUserAdventureInformation: (account_rid, adventure_rid, userInfo) =>
      dispatch(
        fetchUserAdventureInformation(account_rid, adventure_rid, userInfo),
      ),
    postAdventureBlock: (account_rid, adventure_rid, data) =>
      dispatch(postAdventureBlock(account_rid, adventure_rid, data)),
    fetchUserAdventures: (
      account_rid,
      inspiration_rid,
      userInfo,
      image_position_type_rid,
    ) =>
      dispatch(
        fetchUserAdventures(
          account_rid,
          inspiration_rid,
          userInfo,
          image_position_type_rid,
        ),
      ),
    fetchAllBlocksItinerary: (account_rid) =>
      dispatch(fetchAllBlocksItinerary(account_rid)),
    fetchAdventureBlocks: (account_rid, adventure_rid) =>
      dispatch(fetchAdventureBlocks(account_rid, adventure_rid)),
    fetchLocationBlocks: (data) => dispatch(fetchLocationBlocks(data)),
    openAdventureModal: (isInspirationAdventure, data, edit) =>
      dispatch(openAdventureModal(isInspirationAdventure, data, edit)),
    openBlockModal: (
      editView,
      block_rid,
      addAdventureBlock,
      currentAdventure,
    ) =>
      dispatch(
        openBlockModal(
          editView,
          block_rid,
          addAdventureBlock,
          currentAdventure,
        ),
      ),
    openItineraryModal: (data, openType, actionPayload) =>
      dispatch(openItineraryModal(data, openType, actionPayload)),
    deleteAdventure: (adventure) => dispatch(deleteAdventure(adventure)),
    fetchBlocksDynamic: (account_rid, data) =>
      dispatch(fetchBlocksDynamic(account_rid, data)),
    openDeleteModal: (data, func) => dispatch(openDeleteModal(data, func)),

    fetchAdventureFriend: (adventure_rid) =>
      dispatch(fetchAdventureFriend(adventure_rid)),
    fetchAdventureGroup: (adventure_rid) =>
      dispatch(fetchAdventureGroup(adventure_rid)),
    fetchItineraryInformation: (adventure_rid, itinerary_rid, userInfo) =>
      dispatch(
        fetchItineraryInformation(adventure_rid, itinerary_rid, userInfo),
      ),
    openFriendsModal: (userInfo, currentAdventureData) => {
      dispatch(openFriendsModal(userInfo, currentAdventureData));
    },
    openAdventureGroupModal: (userInfo, adventureGroup, openType) =>
      dispatch(openAdventureGroupModal(userInfo, adventureGroup, openType)),
    openAdventureCopyModal: (data, openType) =>
      dispatch(openAdventureCopyModal(data, openType)),
    updateToken: (token) => dispatch(updateToken(token)),
    fetchSingleAdventure: (adventure_rid) =>
      dispatch(fetchSingleAdventure(adventure_rid)),
    flushSelectedPartners: () => dispatch(flushSelectedPartners()),
    fetchTransportationTypes: () => dispatch(fetchTransportationTypes()),

    fetchSocialCostOfCarbon: () => dispatch(fetchSocialCostOfCarbon()),
    setLoading: (bool) => dispatch(setLoading(bool)),
    calculateImpactForTrip: (data) => dispatch(calculateImpactForTrip(data)),
    updateAdventureAttendee: (data) => dispatch(updateAdventureAttendee(data)),
    deleteAdventureFriend: (adventure_friend_rid, adventure_rid) =>
      dispatch(deleteAdventureFriend(adventure_friend_rid, adventure_rid)),
    flushCurrentAdventure: () => dispatch(flushCurrentAdventure()),
  };
};
