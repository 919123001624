import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import Footer from 'components/modules/footer';
import Banner from 'components/modules/banner_regularstack';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const mapStateToProps = (state) => {
  let email = state.entities.users.boarding_user
    ? state.entities.users.boarding_user.email
    : null;
  let subscription_rid = state.entities.users.boarding_user
    ? state.entities.users.boarding_user.subscription_rid
    : null;
  return {
    email,
    subscription_rid,
  };
};

const Homepage = (props) => {
  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    axios.patch(
      `/api/e/move_up_first_queue/${props.subscription_rid}/${props.email}`,
    );

    axios
      .patch('/api/ui/fetch_meta_data', { pageName: 'sign-up-success' })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
    scrollTop();
  }, [props.subscription_rid, props.email]);
  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div className="page-content-wrapper">
        <Banner
          photo="/banner_images/lodestar_sign_up_success_banner.jpeg"
          bigText={
            <span>
              THANKS FOR <br /> SIGNING UP!
            </span>
          }
          style={{ color: '#09132B' }}
        />
        <div className="sign-up-success-content-body">
          <h1 className="sign-up-success-content-header">THANK YOU!</h1>
          <div className="sign-up-success-big-text">
            <span>
              Grab a coffee while we set up your account.
              <br />
              We'll send you an email at{' '}
              {props.email ? props.email : 'placeholder@email.com'} when it's
              all done
            </span>
          </div>

          <div className="sign-up-small-texts">
            <span>
              Didn't receive an email?{' '}
              <Link to="/resend-email">Resend it here.</Link>
              <br />
              Drop us a note at contact@lodestarhub.com with any questions.
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps, null)(Homepage);
