import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import HubModal from '../../modal';
import { Input, DateSelectPicker, Title, Button } from '../../modal_modules';
import { mapStateToProps, mapDispatchToProps } from './container';
const CopyAdventureModal = ({
  actionPayload,
  userInfo,
  copyAdventure,
  closeModal,
  openType,
  setLoading,
  openMessageModal,
}) => {
  const handleSubmit = useCallback((event) => {
    setLoading(true);
    copyAdventure(inputValues).then((res) => {
      setLoading(false);
      openMessageModal(res.data.copiedAdventure[0].func_copy_adventure_v2);
    });
  });

  const [inputValues, setInputValues] = useState({
    account_rid: openType.copySelf
      ? userInfo.account_rid
      : actionPayload.account_rid,
    explorer_rid: userInfo.explorer_rid,
    new_account_rid: openType.copySelf
      ? userInfo.account_rid
      : userInfo.account_rid,
    adventure_rid: actionPayload && actionPayload.adventure_rid,
    template_flag: false,
    template_name: null,
    new_itinerary_start_date: actionPayload && dayjs(actionPayload.start_date),
    display_start_date: dayjs(actionPayload.start_date),
  });

  const handleOnChangeDate = useCallback((date) => {
    setInputValues({
      ...inputValues,
      new_itinerary_start_date: date,
      display_start_date: date,
    });
  });
  return (
    <HubModal
      show={true}
      title="COPY ADVENTURE"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box className="create-adventure-wrapper">
        <Stack spacing={2}>
          <div className="copy-adventure-modal-information--wrapper">
            <span>
              Copy trip: {actionPayload && actionPayload.adventure_name}?
            </span>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              value={inputValues.display_start_date}
              onChange={handleOnChangeDate}
              label="Start"
              slotProps={{
                textField: {
                  size: 'small',
                  sx: {
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#273A07',
                      },
                      '&:hover fieldset': {
                        borderColor: '#273A07',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#273A07',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#827700',
                    },
                    '& input': {
                      color: '#827700',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          {/* <DateSelectPicker
            value={inputValues.display_start_date}
            onDateChange={handleOnChangeDate}
            name="start_date"
            showLabels={false}
          /> */}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyAdventureModal);
