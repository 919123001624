import React, { useState } from 'react';
import { svgTime } from 'components/svg_module';
import { openTimePickerModal } from '../../../actions/modal_actions';
import { connect } from 'react-redux';
import moment from 'moment';

const mapDispatchToProps = (dispatch) => {
  return {
    openTimePickerModal: (data) => dispatch(openTimePickerModal(data)),
  };
};

const DatePicker = ({
  spacingTop,
  spacingBottom,
  openTimePickerModal,
  persistParentData,
  startDate,
  endDate,
  originalStartDate,
  originalEndDate,
  origin, // pass the id of the html tag of the most outer div tag of the
  // component that is rendering this date picker
}) => {
  const [displayDate, toggleDisplayDate] = useState(false);
  const handleDisplay = () => {
    toggleDisplayDate(!displayDate);
  };
  if (originalStartDate || originalEndDate) {
    startDate = originalStartDate;
    endDate = originalEndDate;
  }
  return (
    <div
      className="mod-dte-pkr-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
    >
      {!startDate || !endDate ? (
        <div
          className="mod-dte-pkr-untrig"
          // onClick={() => toggleDisplayDate(!displayDate)}
          onClick={() => {
            persistParentData();
            openTimePickerModal(origin);
          }}
        >
          {svgTime('white', 'mod-svg-cal', 'mod-svg-cal-wrapper')}
          <span>choose your times</span>
        </div>
      ) : (
        <div
          className="mod-dte-pkr-set"
          onClick={() => {
            persistParentData();
            openTimePickerModal(origin);
          }}
        >
          <div className="mod-dte-dur-tp">
            {moment(endDate).diff(moment(startDate), 'days')} days
          </div>
          {/* duration change to amount of days */}
          {(startDate || endDate) && (
            <div className="mod-dte-dur-btm">
              {moment(startDate).format('MMM Do YY')} -{' '}
              {moment(endDate).format('MMM Do YY')}
            </div>
          )}
          {/* {(originalStartDate || originalEndDate) && <div className="mod-dte-dur-btm">{moment(originalStartDate).format("MMM Do YY")} - {moment(originalEndDate).format("MMM Do YY")}</div> } */}
        </div>
      )}
    </div>
  );
  // : <div className='modal-layer-two'>
  //
  //     </div>
};

export default connect(null, mapDispatchToProps)(DatePicker);
