import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { postItinerary } from 'actions/itinerary_actions';
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
  LongRectBlock,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
const SharePermissionPickerModal = ({
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
}) => {
  const [inputValues, setInputValues] = useState({
    share_permission_rid: null,
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');
  const [blockFilePic, setBlockFilePic] = useState(null);
  const [domainLink, setDomainLInk] = useState({
    url: '',
    domain: '',
    screenShot: '',
  });

  const handleLink = useCallback((event) => {
    const { name, value } = event;
    setDomainLInk({ ...domainLink, [name]: value });
  });
  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const triggerPersistData = () => {
    persistData(inputValues);
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    if (startDate || endDate) {
      inputValues.start_date = startDate;
      inputValues.end_date = endDate;
    }
    postItinerary(adventure_rid, inputValues);
  };

  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="SHARE PERMISSION" />

        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
            <LongRectBlock
              title="Observer"
              text="Can only view what is shared."
              clickHandler={handleOnChange}
              currentValue={inputValues.share_permission_rid}
              value={1}
              name="share_permission_rid"
            />
            <LongRectBlock
              title="Cpoier"
              text="Can view and make a copy of what is shared."
              clickHandler={handleOnChange}
              currentValue={inputValues.share_permission_rid}
              value={2}
              name="share_permission_rid"
            />
            <LongRectBlock
              title="Editor"
              text="Can view and edit what is shared."
              clickHandler={handleOnChange}
              currentValue={inputValues.share_permission_rid}
              name="share_permission_rid"
              value={3}
            />
            <LongRectBlock
              title="Owner"
              text="Can view, edit, delete what is shared and set sharing permissions."
              clickHandler={handleOnChange}
              currentValue={inputValues.share_permission_rid}
              name="share_permission_rid"
              value={4}
            />
            <Button spacingBottom="20px" spacingTop="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SharePermissionPickerModal;
