
export const svgFacebook = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="35.997"
      height="35.779"
      viewBox="0 0 35.997 35.779"
    >
      <path
        className={className}
        id="Icon_simple-facebook"
        data-name="Icon simple-facebook"
        d="M36,18a18,18,0,1,0-20.811,17.78V23.2h-4.57V18h4.57V14.033c0-4.511,2.687-7,6.8-7a27.68,27.68,0,0,1,4.029.352v4.429H23.744a2.6,2.6,0,0,0-2.933,2.811V18H25.8L25,23.2H20.811V35.779A18,18,0,0,0,36,18Z"
        fill={color}
      />
    </svg>
  );
};