/* global MapboxGeocoder, turf  */
import mapboxgl from '!mapbox-gl';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { Box, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { svgGoogleMaps, svgWaze } from 'components/svg_module';
import { svgAppleMaps } from 'components/svg_module/svgs/svg-apple-maps';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SectionSubTitle } from '../../../common/section-title';
import TextBox from '../../../common/text-box';
import FlightInfo from './module/_flights';

mapboxgl.accessToken =
  'pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA';
const Location = ({
  inputValues,
  setInputValues,
  searchAirports,
  setDistance,
}) => {
  const mapModalContainer = useRef(null);
  const mapModal = useRef(null);
  const markerSingletonRef = useRef(null);
  const locationPointRef = useRef(null);
  if (!markerSingletonRef.current) {
    const marker1 = new mapboxgl.Marker({
      color: '#da4431',
      draggable: true,
      // offset: [0, -50 / 2],
    });
    marker1.on('dragend', ({ type, target }) => {
      const { lng, lat } = target.getLngLat();
      let location_point = { lat, lng };
      setInputValues({ ...inputValues, location_point });
    });
    markerSingletonRef.current = marker1;
  }
  // const marker = new mapboxgl.Marker({
  //   color: '#FFFFFF',
  //   draggable: true,
  // });
  const handleLocationPointChange = useCallback((event) => {
    if (event.target) {
      const { name, value } = event.target;
      let latLng = inputValues.location_point;
      latLng[name] = value;
      const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
      let coordinatesString = `${latLng.lat},${latLng.lng}`;
      if (regexExp.test(coordinatesString)) {
        // checking if valid coordinates
        markerSingletonRef.current.setLngLat([latLng.lng, latLng.lat]);
        mapModal.current.flyTo({
          center: [latLng.lng, latLng.lat],
          essential: true,
          zoom: 12, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
      setInputValues({ ...inputValues, location_point: latLng });
    } else {
      if (event.fromButton) {
        const { lat, lng } = event.currentPosition;
        return setInputValues((inputValues) => ({
          ...inputValues,
          location_point: { lat, lng },
        }));
      }
      let lat = event.geometry.coordinates[1];
      let lng = event.geometry.coordinates[0];
      setInputValues({ ...inputValues, location_point: { lat, lng } });
    }
  });
  const [lng, setLng] = useState(
    inputValues.location_point && inputValues.location_point.lng
      ? inputValues.location_point.lng
      : -105.0510357,
  );
  const [lat, setLat] = useState(
    inputValues.location_point && inputValues.location_point.lat
      ? inputValues.location_point.lat
      : 38.8408698,
  );
  const [zoom, setZoom] = useState(6);
  const [flightInfo, setFlightInfo] = useState(
    inputValues.location_flag && inputValues.location_point.flight_data
      ? {
          from: inputValues.location_point.origin_airport_location_id,
          to: inputValues.location_point.destination_airport_location_id,
          roundTrip: null,
          searchResults: [],
          displaytoResults: false,
          displayfromResults: false,
        }
      : {
          from: null,
          to: null,
          roundTrip: null,
          searchResults: [],
          displaytoResults: false,
          displayfromResults: false,
        },
  );
  const setLatAndLng = (dateObject) => {
    let lat = dateObject.geometry.coordinates[1];
    let lng = dateObject.geometry.coordinates[0];
    setLat(lat);
    setLng(lng);
    mapModal.current.flyTo({
      center: [lng, lat],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });

    markerSingletonRef.current.setLngLat([lng, lat]).addTo(mapModal.current);
  };

  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    mapModal.current.flyTo({
      center: [currentPosition.lng, currentPosition.lat],
      essential: true,
      zoom: 12, // this animation is considered essential with respect to prefers-reduced-motion
    });
    // const marker1 = new mapboxgl.Marker({
    //   color: '#da4431',
    //   draggable: true,
    //   // offset: [0, -50 / 2],
    // })
    markerSingletonRef.current
      .setLngLat([currentPosition.lng, currentPosition.lat])
      .addTo(mapModal.current);
    handleLocationPointChange({ fromButton: true, currentPosition });
  };

  useEffect(() => {
    if (mapModal.current) return; // initialize map only once
    mapModal.current = new mapboxgl.Map({
      container: mapModalContainer.current,
      style: 'mapbox://styles/lodestarhubdev/cksaxb7zxcgzj17lyzl0spl1a',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
    });
    mapModal.current.addControl(new mapboxgl.NavigationControl());
    mapModal.current.addControl(new mapboxgl.FullscreenControl());
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true,
    });
    mapModal.current.addControl(geolocate);
    geolocate.on('geolocate', success);

    mapModal.current.on('click', ({ lngLat, target, point, ...rest }) => {
      let { lng, lat } = lngLat;
      let location_point = { lat, lng };
      // setInputValues({ ...inputValues, location_point });
      setInputValues((inputValues) => {
        return {
          ...inputValues,
          location_point,
          location_flag: true,
        };
      });
      markerSingletonRef.current.setLngLat([lng, lat]).addTo(mapModal.current);
    });

    //fucntion for geocoder to take in coordinates
    const coordinatesGeocoder = function (query) {
      // Match anything which looks like
      // decimal degrees coordinate pair.
      const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
      );
      if (!matches) {
        return null;
      }

      function coordinateFeature(lng, lat) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature',
        };
      }

      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2));
      }

      if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2));
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      return geocodes;
    };

    const geoCoderControl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      localGeocoder: coordinatesGeocoder,
      reverseGeocode: true,
      marker: false,
    });

    geoCoderControl.on('result', ({ result }) => {
      markerSingletonRef.current
        .setLngLat([
          result.geometry.coordinates[0],
          result.geometry.coordinates[1],
        ])
        .addTo(mapModal.current);
      setInputValues((inputValues) => {
        return {
          ...inputValues,
          location_point: {
            lng: result.geometry.coordinates[0],
            lat: result.geometry.coordinates[1],
            place_name: result.place_name,
            place_name_en_us: result['place_name_en-US'],
            place_type: result.place_type,
          },
          location_flag: true,
        };
      });
    });

    // await mapModal.current.addControl(new mapboxgl.NavigationControl());
    // conditionally appending geocoder control if inputValues category rid is not plane and it renders the map and it's
    // necessary components
    let geoCoderParent = document.getElementById('modal-geocoder');
    if (geoCoderParent) {
      geoCoderParent.appendChild(geoCoderControl.onAdd(mapModal.current));
    }
    //checking first if it's a flight or not a flight,
    if (inputValues.location_flag && !inputValues.location_point.flight_data) {
      //if it is not a flight show the location_point
      if (inputValues.location_point?.lat) {
        mapModal.current.flyTo({
          center: [
            inputValues.location_point.lng,
            inputValues.location_point.lat,
          ],
          essential: true,
          zoom: 12, // this animation is considered essential with respect to prefers-reduced-motion
        });

        markerSingletonRef.current
          .setLngLat([
            inputValues.location_point.lng,
            inputValues.location_point.lat,
          ])
          .addTo(mapModal.current);
      }
    }
  }, []);

  useEffect(() => {
    if (
      // first check if the inputValues is a flight data and it has all the necesssary inputs
      inputValues.location_flag &&
      inputValues.location_point &&
      inputValues.location_point.flight_data &&
      inputValues.location_point.destination_airport_location_id &&
      inputValues.location_point.origin_airport_location_id
    ) {
      // if the flight hasnt yet been updated, save it to the ref and render
      if (!locationPointRef.current) {
        locationPointRef.current = inputValues.location_point;
        const {
          lng,
          lat,
        } = locationPointRef.current.origin_airport_location_point;
        let origin = [lng, lat];
        let destination = [
          locationPointRef.current.destination_airport_location_point.lng,
          locationPointRef.current.destination_airport_location_point.lat,
        ];

        const route = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [origin, destination],
              },
            },
          ],
        };

        const point = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: origin,
              },
            },
          ],
        };

        // Calculate the distance in kilometers between route start/end point.
        const lineDistance = turf.length(route.features[0]);
        setDistance(lineDistance);

        const arc = [];

        // Number of steps to use in the arc and animation, more steps means
        // a smoother arc and animation, but too many steps will result in a
        // low frame rate
        const steps = 500;

        // Draw an arc between the `origin` & `destination` of the two points
        for (let i = 0; i < lineDistance; i += lineDistance / steps) {
          const segment = turf.along(route.features[0], i);
          arc.push(segment.geometry.coordinates);
        }

        // Update the route with calculated arc coordinates
        route.features[0].geometry.coordinates = arc;

        // Used to increment the value of the point measurement against the route.
        let counter = 0;
        const drawFlight = () => {
          // Add a source and layer displaying a point which will be animated in a circle.
          mapModal.current.addSource('route', {
            type: 'geojson',
            data: route,
          });

          mapModal.current.addSource('point', {
            type: 'geojson',
            data: point,
          });

          mapModal.current.addLayer({
            id: 'route',
            source: 'route',
            type: 'line',
            paint: {
              'line-width': 2,
              'line-color': '#007cbf',
            },
          });

          mapModal.current.addLayer({
            id: 'point',
            source: 'point',
            type: 'symbol',
            layout: {
              // This icon is a part of the Mapbox Streets style.
              // To view all images available in a Mapbox style, open
              // the style in Mapbox Studio and click the "Images" tab.
              // To add a new image to the style at runtime see
              // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
              'icon-image': 'airport-15',
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
            },
          });

          function animate() {
            const start =
              route.features[0].geometry.coordinates[
                counter >= steps ? counter - 1 : counter
              ];
            const end =
              route.features[0].geometry.coordinates[
                counter >= steps ? counter : counter + 1
              ];
            if (!start || !end) return;

            // Update point geometry to a new position based on counter denoting
            // the index to access the arc
            point.features[0].geometry.coordinates =
              route.features[0].geometry.coordinates[counter];

            // Calculate the bearing to ensure the icon is rotated to match the route arc
            // The bearing is calculated between the current point and the next point, except
            // at the end of the arc, which uses the previous point and the current point
            point.features[0].properties.bearing = turf.bearing(
              turf.point(start),
              turf.point(end),
            );

            // Update the source with this new data
            mapModal.current.getSource('point').setData(point);

            // Request the next frame of animation as long as the end has not been reached
            if (counter < steps) {
              requestAnimationFrame(animate);
            }

            counter = counter + 1;
          }

          document.getElementById('replay').addEventListener('click', () => {
            // Set the coordinates of the original point back to origin
            point.features[0].geometry.coordinates = origin;

            // Update the source layer
            mapModal.current.getSource('point').setData(point);

            // Reset the counter
            counter = 0;

            // Restart the animation
            animate(counter);
          });

          // Start the animation
          animate(counter);
        };
        let isMapLoaded = mapModal.current.loaded();
        if (isMapLoaded) {
          drawFlight();
        } else {
          mapModal.current.on('load', () => {
            drawFlight();
          });
        }
      } else {
        if (
          locationPointRef.current.destination_airport_location_id !==
            inputValues.location_point.destination_airport_location_id &&
          locationPointRef.current.origin_airport_location_id !==
            inputValues.location_point.origin_airport_location_id
        ) {
        }
      }
    }
    // if(locationPointRef !== inputValues.location_point) {

    // }
  }, [inputValues]);

  const getCurrentLocationHandler = () => {
    navigator.geolocation.getCurrentPosition(success, error);
  };

  const flightInfoOnChangeHandler = useCallback((e) => {
    const { value, name } = e.currentTarget;
    if (value === '') {
      setFlightInfo((flightInfo) => ({
        ...flightInfo,
        [name]: value,
        searchResults: [],
        [`display${name}Results`]: false,
      }));
    } else {
      searchAirports(value).then((res) => {
        setFlightInfo((flightInfo) => ({
          ...flightInfo,
          [name]: value,
          searchResults: value === '' ? [] : res.data.airports,
          [`display${name}Results`]: value === '' ? false : true,
        }));
      });
    }
  });
  const error = (error) => {
    window.alert('Please enable location services');
  };

  const airportClickHandler = (type) => (e, airport) => {
    e.preventDefault();
    e.stopPropagation();
    const { airport_location_id, airport_name, location_point } = airport;

    if (type === 'to') {
      setFlightInfo({
        ...flightInfo,
        to: airport_location_id,
        searchResult: [],
        displaytoResults: false,
      });
      setInputValues((inputValues) => {
        let airportLocationData = {
          ...inputValues.location_point,
          flight_data: true,
          location_flag: true,
          destination_airport_location_id: airport_location_id,
          destination_airport_location_name: airport_name,
          destination_airport_location_point: location_point,
        };
        return { ...inputValues, location_point: airportLocationData };
      });
    } else {
      setFlightInfo({
        ...flightInfo,
        from: airport_location_id,
        searchResult: [],
        displayfromResults: false,
      });
      setInputValues((inputValues) => {
        let airportLocationData = {
          ...inputValues.location_point,
          flight_data: true,
          location_flag: true,
          lat: location_point.lat,
          lng: location_point.lng,
          origin_airport_location_id: airport_location_id,
          origin_airport_location_name: airport_name,
          origin_airport_location_point: location_point,
        };
        return { ...inputValues, location_point: airportLocationData };
      });
    }
  };
  // for changing the location points manually

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    markerSingletonRef?.current.remove();
    setInputValues({
      ...inputValues,
      location_point: { lat: null, lng: null },
    });
    // clear map markers
  };

  return (
    <>
      <SectionSubTitle>LOCATION</SectionSubTitle>
      {/* this is the geocoder searchbox parent */}
      {inputValues.block_category_rid === 56 ? (
        <FlightInfo
          flightInfo={flightInfo}
          onChangeHandler={flightInfoOnChangeHandler}
          airportClickHandler={airportClickHandler}
        />
      ) : (
        <div id="modal-geocoder" className="modal-location-geocoder" />
      )}
      <Typography sx={{ fontSize: '0.75rem', color: '#827700' }}>
        Search, click map, add coordinates, or use current location.
      </Typography>
      {/* this is the map */}
      <div
        ref={mapModalContainer}
        onScroll={(e) => {
          e.stopPropagation();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
        className="map-container"
      />
      {inputValues.block_category_rid === 56 ? (
        <></>
      ) : (
        <>
          <div className="modal-location-long-lat-container">
            <Box display="flex" width={1} gap={1}>
              <TextBox
                className="text-field"
                label="Latitude"
                name="lat"
                onChange={handleLocationPointChange}
                value={inputValues.location_point?.lat ?? ''}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
              <TextBox
                className="text-field"
                label="Longitude"
                name="lng"
                onChange={handleLocationPointChange}
                value={inputValues.location_point?.lng ?? ''}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
              <Tooltip title={'Clear Location'}>
                <IconButton
                  sx={{ border: 1, borderRadius: 1, pb: 1 }}
                  onClick={handleClear}
                >
                  <WrongLocationIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {/* <Input
              label="Latitude"
              name="lat"
              step="0.00000000000000000000000000000000000001"
              onChangeHandler={handleLocationPointChange}
              value={
                inputValues.location_point && inputValues.location_point.lat
              }
              defaultActive
              type="text"
              // customParentClassName="location-section-input-top"
            />
            <Input
              label="Longitude"
              name="lng"
              type="text"
              step="0.000000000000000000000000000000000000001"
              onChangeHandler={handleLocationPointChange}
              value={
                inputValues.location_point && inputValues.location_point.lng
              }
              defaultActive
            /> */}
          </div>
          {inputValues.location_point &&
            inputValues.location_point.lat &&
            inputValues.block_rid && (
              <Typography sx={{ fontSize: '0.75rem', color: '#827700' }}>
                Click for driving directions to this location.
              </Typography>
            )}
          <div
            className="get-my-location-button-wrapper"
            onDoubleClick={(e) => {
              e.preventDefault();
              window.open(
                `https://google.com/maps/place/${inputValues.location_point.lat},${inputValues.location_point.lng}`,
                '_blank',
              );
              // navigator.clipboard.writeText(
              //   `${inputValues.location_point.lat},${inputValues.location_point.lng}`,
              // );
            }}
          >
            {inputValues.location_point &&
              inputValues.location_point.lat &&
              inputValues.block_rid && (
                <button
                  className="open-waze-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      `https://www.waze.com/ul?ll=${inputValues.location_point.lat},${inputValues.location_point.lng}&navigate=yes&zoom=17`,
                      '_blank',
                    );
                  }}
                >
                  {svgWaze('', 'waze-svg', 'waze-svg-outer')}
                </button>
              )}
            {/* <button
              className="get-my-location-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                getCurrentLocationHandler();
              }}
              id="get-current-location-button"
            >
              {svgCurrentLocation(
                '',
                'current-location',
                'current-location-svg',
              )}
            </button> */}

            {inputValues.location_point &&
              inputValues.location_point.lat &&
              inputValues.block_rid && (
                <button
                  className="open-google-maps-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      `https://maps.apple.com/?saddr=Current+Location&daddr=${inputValues.location_point.lat},${inputValues.location_point.lng}&dirflg=d`,
                      '_blank',
                    );
                  }}
                >
                  {svgAppleMaps('', 'google-map-svg', 'google-map-svg-outer')}
                </button>
              )}
            {inputValues.location_point &&
              inputValues.location_point.lat &&
              inputValues.block_rid && (
                <button
                  className="open-google-maps-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    window.open(
                      `https://www.google.com/maps/dir/?api=1&destination=${inputValues.location_point.lat},${inputValues.location_point.lng}&travelmode=driving`,
                      '_blank',
                    );
                  }}
                >
                  {svgGoogleMaps('', 'google-map-svg', 'google-map-svg-outer')}
                </button>
              )}
          </div>
        </>
      )}
    </>
  );
};

export default Location;
