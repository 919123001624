import React from 'react';
const MembersHeader = ({ text }) => {
  return (
    <section className="members-page-header-wrapper">
      <header>
        {' '}
        <div className="header-wrapper">
          <span>{text}</span>
        </div>
      </header>
    </section>
  );
};

export default MembersHeader;
