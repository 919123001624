/* this modal will be used to add friends to an adventure through selection from user friendlist 



*/
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { postItinerary } from 'actions/itinerary_actions';
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
  MemberBlock,
  Search,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
const fakeData = [
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'notInvited',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'pending',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    status: 'accepted',
  },
  {
    firstName: 'Member',
    lastName: 'Name',
    city: 'city',
    state: 'state',
    profileImage:
      'https://cdn-images-1.medium.com/max/1200/1*NpUUls7kjn9JhO4ChjGV7w.png',
    status: 'accepted',
  },
];
const BlockFileViewer = ({
  inspirations,
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
}) => {
  const [inputValues, setInputValues] = useState({
    searchName: null,
    firstName: null,
    lastName: null,
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');
  const [blockFilePic, setBlockFilePic] = useState(null);
  const [domainLink, setDomainLInk] = useState({
    url: '',
    domain: '',
    screenShot: '',
  });

  const handleLink = useCallback((event) => {
    const { name, value } = event.target;
    setDomainLInk({ ...domainLink, [name]: value });
  });
  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const triggerPersistData = () => {
    persistData(inputValues);
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    if (startDate || endDate) {
      inputValues.start_date = startDate;
      inputValues.end_date = endDate;
    }
    postItinerary(adventure_rid, inputValues);
  };

  const renderLodestar = () => {
    return (
      <div className="mod-inv-friends-lodestar-wrapper">
        <Search />
        <div className="mod-inv-frnds-rslts-wrapper">
          {fakeData.map((ele, idx) => {
            return (
              <MemberBlock
                firstName={ele.firstName}
                lastName={ele.lastName}
                profileImage={ele.profileImage}
                inviteStatus={ele.status}
                city={ele.city}
                state={ele.state}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderInvite = () => {
    return (
      <div className="mod-share-inv-wrapper">
        <Input label="First name" />
        <Input label="Last name" />
        <Input label="Email" />
      </div>
    );
  };
  return (
    <div className="mod-type1-wrapper" id="cre-ad">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="FRIEND" />

        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'LODESTAR'}
                text="LODESTAR"
              />
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'INVITE'}
                text="INVITE"
              />
            </div>
            {topLabel === 'LODESTAR' ? renderLodestar() : renderInvite()}

            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlockFileViewer;
