import { openFolderModal } from 'actions/modal_actions';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import useUserInfo from 'features/user/hooks/use-user-info';
import { useDispatch } from 'react-redux';

const useCreateTripFolderModalButton = () => {
  const dispatch = useDispatch();
  const userInfo = useUserInfo();
  const { itinerary_info, itineraryDetails } = useCurrentItinerary();
  const adventure = useCurrentAdventure();

  const openModal = async () => {
    const openType = {
      isNew: true,
      isFolder: true,
      isListFolder: false,
    };

    const blockType = {
      isAdventureBlock: true,
      isNestedAdventureBlock: false,
      isLibraryBlock: false,
      isNestedLibraryBlock: false,
    };

    const actionPayload = {
      adventureRid: adventure.adventure_rid,
      nestedLevel: 0,
      parentBlockRid: null,
      itineraryInfo: itinerary_info,
      itineraryDetails,
    };

    // I don't know what needs to be here
    const data = {};

    dispatch(
      openFolderModal(openType, blockType, actionPayload, data, userInfo),
    );
  };

  return { openModal };
};

export default useCreateTripFolderModalButton;
