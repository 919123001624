import React from 'react';
import { Link } from 'react-router-dom';
import {
  svgPBC,
  svgOnePercenter,
} from '../../../../components/svg_module/svg_library/home_page_svg';
const Footer = () => {
  return (
    <div className="footer-container" id="mobil-footer-container">
      <div className="hub-footer-top-border">
        <div />
      </div>

      <div className="footer-contents">
        <div className="footer-top">
          <div className="footer-left">
            <div className="footer-link-blocks" id="mobile-txt-footer">
              <Link to="/privacy-policy">PRIVACY POLICY</Link>
              <Link to="/cookie-policy">COOKIE POLICY</Link>
              <Link to="/terms-of-use">TERMS OF USE</Link>
            </div>
          </div>
          <div className="footer-middle" id="mobile-txt-footer">
            <span className="mission-text" id="mobile-mission-text">
              Lodestar Hub, Inc. is a Public Benefit Corporation. &nbsp; We
              believe in using business as a force to do good.
              <br />
              Our mission is to create an innovative platform to bring people to
              the outdoors, devoted to cultivating environmental stewardship and
              building an inclusive and diverse outdoor community.
            </span>
            {/* <br /> */}
          </div>

          <div className="footer-middle-right">
            <div className="footer-img-block"></div>
          </div>
          <div className="footer-right">
            <div className="footer-logo-block">
              <img alt="logo-lodestar-footer" src="/svg_assets/whitelogo.svg" />
            </div>
            <div className="footer-icons-wrapper">
              <a
                rel="noopener noreferrer"
                href="https://bcorporation.net/"
                target="_blank"
              >
                {svgPBC('', 'footer-sticker-svg', 'footer-sticker-svg-outer')}
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.onepercentfortheplanet.org/"
                target="_blank"
              >
                {svgOnePercenter(
                  '',
                  'footer-sticker-svg',
                  'footer-sticker-svg-outer',
                )}
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="soc-media-block">
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/lodestarhub"
              target="_blank"
            >
              <img alt="footer-soc-logos" src="/svg_assets/instgram.svg" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://linkedin.com/company/lodestarhub"
              target="_blank"
            >
              <img alt="footer-soc-logos" src="/svg_assets/linkedin.svg" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/LodestarHub1"
              target="_blank"
            >
              <img alt="footer-soc-logos" src="/svg_assets/facebook.svg" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/lodestarhub"
              target="_blank"
            >
              <img alt="footer-soc-logos" src="/svg_assets/twitter.svg" />
            </a>
          </div>
        </div>
        <div className="c-text" id="c-text">
          <span>© 2021 LODESTAR HUB, INC. ALL RIGHTS RESERVED.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
