export function gondola (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;" />
        <g>
          <path
            fill={color}
            className={className}
            d="M67.23161,2.70602c-0.10004-0.41003-0.51001-0.66003-0.90002-0.56l-27.95002,6.63   c-0.65997-1.53003-2.16998-2.54004-3.88-2.54004c-2.33997,0-4.23999,1.90002-4.23999,4.23999c0,0.08002,0,0.16003,0.01001,0.23999   l-27.94,6.63c-0.40997,0.10004-0.65997,0.5-0.56,0.90002c0.08002,0.34998,0.39001,0.58002,0.72998,0.58002   c0.06,0,0.11005-0.01001,0.17004-0.02002l27.94995-6.63c0.56006,1.29999,1.73004,2.22998,3.13,2.47998V24.186H12.97159   c-1.51001,0-2.96002,0.71997-3.87,1.91998c-8.74799,11.31122-8.82336,27.41944,0,38.85999   c0.90997,1.19,2.35999,1.91003,3.87,1.91003h43.06c1.51001,0,2.96002-0.72003,3.88-1.91003   c8.53821-11.05994,9.03382-27.16919,0-38.84998c-0.91003-1.21002-2.35999-1.92999-3.88-1.92999H35.25156v-9.53998   c1.98004-0.35004,3.49005-2.09003,3.49005-4.17004c0-0.07996,0-0.15997-0.01001-0.23999l27.94001-6.63   C67.08158,3.50601,67.32157,3.10599,67.23161,2.70602z M15.82157,48.97598c0,0.73004-0.58997,1.32001-1.32001,1.32001H4.39158   c-0.87427-5.8349-0.12091-11.58154,2-16.52997h8.10999c0.73004,0,1.32001,0.58997,1.32001,1.32001V48.97598z M39.81076,59.84451   H29.19358c-0.41406,0-0.75-0.33594-0.75-0.75s0.33594-0.75,0.75-0.75h10.61719c0.41406,0,0.75,0.33594,0.75,0.75   S40.22483,59.84451,39.81076,59.84451z M44.61155,56.26785H24.3928c-0.41406,0-0.75-0.33594-0.75-0.75s0.33594-0.75,0.75-0.75   h20.21875c0.41406,0,0.75,0.33594,0.75,0.75S45.02561,56.26785,44.61155,56.26785z M53.19156,35.08603   c0-0.73004,0.59003-1.32001,1.31-1.32001h8.12c2.27234,5.10962,2.82331,11.04645,2,16.52997h-10.12   c-0.71997,0-1.31-0.58997-1.31-1.32001V35.08603z M44.15158,33.76602c0.71997,0,1.31,0.58997,1.31,1.32001v13.88995   c0,0.73004-0.59003,1.32001-1.31,1.32001H24.8516c-0.72003,0-1.31-0.58997-1.31-1.32001V35.08603   c0-0.73004,0.58997-1.32001,1.31-1.32001H44.15158z"
          ></path>
        </g>
      </g>
    </svg>
  );
}