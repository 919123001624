import React, { useState, useEffect, createRef, useCallback } from 'react';
import { Input, TextArea, DropDownSelect } from '../../modal_modules';
import ColorPicker from './color_picker';
import { SectionSubTitle } from '../../../common/section-title';
import TextBox from '../../../common/text-box';
import { merge } from 'lodash';
import { svgAccept } from 'components/svg_module';
import { connect } from 'react-redux';
import {
  fetchAllBlocksAsLists,
  fetchAllAdventureBlocksAsLists,
} from 'actions/block_actions';
import Contents from './contents';
const mapStateToProps = ({ entities }) => {
  let allBlockList =
    entities.blocks.blocksAsLists && entities.blocks.blocksAsLists;
  return {
    allBlockList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllBlocksAsLists: (account_rid, block_rid, list_flag) =>
      dispatch(fetchAllBlocksAsLists(account_rid, block_rid, list_flag)),
    fetchAllAdventureBlocksAsLists: (
      account_rid,
      adventure_rid,
      block_rid,
      list_flag,
    ) =>
      dispatch(
        fetchAllAdventureBlocksAsLists(
          account_rid,
          adventure_rid,
          block_rid,
          list_flag,
        ),
      ),
  };
};
const Summary = ({
  handleOnChange,
  inputValues,
  currentBlock,
  uiToggle,
  uiToggleHandler,
  isAdventureBlock,
  openType,
  blockType,
  actionPayload,
  userInfo,
  copyToTripBoard,
  fetchAllBlocksAsLists,
  setParentBlockRid,
  setParentTripBlockRid,
  user_block_categories,
  handleCategorySelect,
  fetchAllAdventureBlocksAsLists,
}) => {
  const scrollerRef = createRef(null);
  const [allBlockList, setAllBlockList] = useState([]);
  const [allTripBlockList, setAllTripBlockList] = useState([]);
  useEffect(() => {
    if (
      (currentBlock && currentBlock.adventure_block_rid) ||
      blockType.isItineraryItemBlock ||
      blockType.isAdventureBlock
    ) {
      Promise.all([
        fetchAllAdventureBlocksAsLists(
          userInfo.account_rid,
          actionPayload.adventureRid,
          currentBlock ? currentBlock.block_rid : null,
          openType.isListFolder
            ? true
            : currentBlock && currentBlock.list_flag
            ? true
            : false,
        ),
        fetchAllBlocksAsLists(
          userInfo.account_rid,
          currentBlock ? currentBlock.block_rid : null,
          openType.isListFolder
            ? true
            : currentBlock && currentBlock.list_flag
            ? true
            : false,
        ),
      ]).then((res) => {
        setAllTripBlockList(res[0].data.libraryLists);
        setAllBlockList(res[1].data.libraryLists);
      });
    } else {
      Promise.all([
        fetchAllBlocksAsLists(
          userInfo.account_rid,
          currentBlock ? currentBlock.block_rid : null,
          openType.isListFolder
            ? true
            : currentBlock && currentBlock.list_flag
            ? true
            : false,
        ),
      ]).then((res) => {
        setAllBlockList(res[0].data.libraryLists);
      });
    }
  }, []);

  useEffect(() => {
    if (allBlockList && inputValues.parent_block_rid && currentBlock) {
      setParentBlock(
        allBlockList.filter(
          (ele) => ele.block_rid === inputValues.parent_block_rid,
        ),
      );
    } else if (actionPayload.parentBlockRid && allBlockList) {
      setParentBlock(
        allBlockList.filter(
          (ele) => ele.block_rid === actionPayload.parentBlockRid,
        ),
      );
    }
  }, [allBlockList]);
  useEffect(() => {
    if (allTripBlockList && currentBlock && currentBlock.adventure_block_rid) {
      setParentTripBlock(
        allTripBlockList.filter(
          (ele) => ele.block_rid === inputValues.parent_block_rid,
        ),
      );
    }
  }, [allTripBlockList]);
  useEffect(() => {
    if (
      user_block_categories &&
      inputValues.block_category_rid &&
      currentBlock
    ) {
      setBlockCategories(
        user_block_categories.filter(
          (ele) => ele.block_category_rid === currentBlock.block_category_rid,
        ),
      );
    }
  }, [user_block_categories]);
  
  const [parentBlock, setParentBlock] = useState([]);
  const [parentTripBlock, setParentTripBlock] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [blockCategories, setBlockCategories] = useState([]);
  const addToDestinationHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let passOver = merge({}, currentBlock);

    if (currentBlock.adventure_block_rid) {
      let returnType = {
        isLibraryBlock: true,
        isNestedLibraryBlock: currentBlock.block_level > 1 ? true : false,
      };
      let _actionPayload = {
        adventureRid: null,
        parentBlockRid:
          currentBlock.block_level > 1 ? currentBlock.parent_block_rid : null,
      };
      copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
    } else {
      passOver.adventure_rid = actionPayload.adventureRid;
      let returnType = {
        isLibraryBlock: true,
        isNestedLibraryBlock: currentBlock.block_level > 1 ? true : false,
      };
      let _actionPayload = {
        adventureRid: actionPayload.adventureRid,
        parentBlockRid:
          currentBlock.block_level > 1 ? currentBlock.parent_block_rid : null,
      };
      copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
    }
    setIsDisabled(true);
  });
  return (
    <>
      <SectionSubTitle>SUMMARY</SectionSubTitle>
      <TextBox
        className="text-field required"
        name="block_name"
        label="Name*"
        variant="outlined"
        fullWidth
        onChange={handleOnChange}
        value={inputValues.block_name}
        size="small"
      />
      <DropDownSelect
        values={blockCategories}
        placeholder="Select a category..."
        className="create_edit_block_modal_drop_down_select --activity-categories red-placeholder-dropdown"
        onChange={handleCategorySelect}
        options={user_block_categories}
        parentClassName="create_edit_block_modal_drop_down_select_parent "
        labelField={'block_category_name'}
        valueField={'block_category_rid'}
        clearable
        required
      />
      <TextBox
        name="block_description"
        label="Description"
        variant="outlined"
        multiline
        maxRows={4}
        onChange={handleOnChange}
        value={inputValues.block_description}
        size="small"
      />
      <SectionSubTitle>ORGANIZE</SectionSubTitle>
      {openType.isNew
        ? blockType.isAdventureBlock && (
            <DropDownSelect
              values={parentTripBlock}
              placeholder="Select trip board folder..."
              className="create_edit_block_modal_drop_down_select"
              onChange={setParentTripBlockRid}
              options={allTripBlockList}
              parentClassName="create_edit_block_modal_drop_down_select_parent folder-dropdown"
              labelField={'block_name'}
              valueField={'block_rid'}
              style={{ marginBottom: `${openType.isNew && '20px'}` }}
              clearable
            />
          )
        : (blockType.isAdventureBlock ||
            currentBlock.adventure_block_rid) && (
            <DropDownSelect
              values={parentTripBlock}
              placeholder="Select trip board folder..."
              className="create_edit_block_modal_drop_down_select"
              onChange={setParentTripBlockRid}
              options={allTripBlockList}
              parentClassName="create_edit_block_modal_drop_down_select_parent folder-dropdown"
              labelField={'block_name'}
              valueField={'block_rid'}
              style={{ marginBottom: `${openType.isNew && '20px'}` }}
              clearable
            />
          )}
      {!blockType.isAdventureBlock && (
        <DropDownSelect
          values={parentBlock}
          placeholder="Select library folder..."
          className="create_edit_block_modal_drop_down_select"
          onChange={setParentBlockRid}
          options={allBlockList}
          parentClassName="create_edit_block_modal_drop_down_select_parent folder-dropdown"
          labelField={'block_name'}
          valueField={'block_rid'}
          style={{ marginBottom: `${openType.isNew && '20px'}` }}
          clearable
        />
      )}
      {currentBlock && (
        <>
          {currentBlock.adventure_block_rid && (
            <div className="add-to-adventure-button-container">
              {isAdventureBlock || isDisabled ? (
                <div className="svg-container">
                  {svgAccept(
                    'color',
                    'added-to-adventure-svg',
                    'added-to-adventure-svg-outer',
                  )}
                </div>
              ) : (
                <button
                  className={`add-to-adventure-button ${
                    (isAdventureBlock || isDisabled) &&
                    'add-to-adventure-button--disabled'
                  }`}
                  setToggleState={uiToggleHandler}
                  name="addToAdventure"
                  disabled={isAdventureBlock || isDisabled}
                  value={isAdventureBlock ? true : uiToggle.addToAdventure}
                  onClick={addToDestinationHandler}
                  text={
                    currentBlock.adventure_block_rid
                      ? 'Add to Library'
                      : 'Add to Adventure'
                  }
                >
                  {
                    currentBlock.adventure_block_rid
                      ? 'Add to Library'
                      : 'Add to Adventure'
                  }
                </button>
              )}
              <span>
                {currentBlock.adventure_block_rid
                  ? isDisabled
                    ? 'Added To Library'
                    : ''
                  : isAdventureBlock || isDisabled
                  ? 'Added To Trip'
                  : ''}
              </span>
            </div>
          )}
          {!currentBlock.adventure_block_rid && actionPayload.adventureRid && (
            <div className="add-to-adventure-button-container">
              {isAdventureBlock || isDisabled ? (
                <div className="svg-container">
                  {svgAccept(
                    'color',
                    'added-to-adventure-svg',
                    'added-to-adventure-svg-outer',
                  )}
                </div>
              ) : (
                <button
                  className={`add-to-adventure-button ${
                    (isAdventureBlock || isDisabled) &&
                    'add-to-adventure-button--disabled'
                  }`}
                  setToggleState={uiToggleHandler}
                  name="addToAdventure"
                  disabled={isAdventureBlock || isDisabled}
                  value={isAdventureBlock ? true : uiToggle.addToAdventure}
                  onClick={addToDestinationHandler}
                  text={
                    currentBlock.adventure_block_rid
                      ? 'Add to Library.'
                      : 'Add to Trip.'
                  }
                >
                  {
                    currentBlock.adventure_block_rid
                      ? 'Add to Library'
                      : 'Add to Trip'
                  }
                </button>
              )}
              <span>
                {currentBlock.adventure_block_rid
                  ? isDisabled
                    ? 'Added To Library'
                    : ''
                  : isAdventureBlock || isDisabled
                  ? 'Added To Trip'
                  : ''}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
