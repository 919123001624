const sxLineClamp = (lines = 2) => {
  return {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lines,
  };
};

const sxFadeText = (lines = 2) => {
  return {
    ...sxLineClamp(lines),
  };
};
export { sxFadeText, sxLineClamp };
