export function downhillSkiing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      className={outerLayer}
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          className={className}
          d="M55.386,11.729c0-3.752-3.27-6.895-7.138-6.895c-1.328,0-2.542,0.361-3.631,0.968   l-17.654,9.795V14.63c0-4.231-2.053-8.345-5.562-10.884c-1.812-1.45-3.864-2.054-6.285-2.054c-5.682,0-10.28,4.35-10.28,9.913   c0,1.332,0.361,2.663,0.847,3.87c0.846,1.936,1.089,2.297,2.663,3.871c0.965,1.089,2.414,1.692,3.989,1.692   c0.964,0,1.935-0.242,2.781-0.729l1.574,1.332c-1.214,1.207-1.936,2.903-1.936,4.596c0,3.507,3.019,6.406,6.646,6.406h10.883   c0.486,0,0.85,0.485,0.85,0.971c0,0.243-0.121,0.604-0.481,0.726l-11.494,6.405c-2.172,1.09-3.503,3.389-3.503,5.807   c0,3.628,3.027,6.652,6.895,6.652h23.218c0.481,0,0.964,0.36,0.964,0.846c0,0.361-0.242,0.604-0.482,0.726L15.361,72.912   c-1.817,1.089-3.999,1.574-6.17,1.574c-3.625,0-6.771-1.331-9.191-3.87v9.552c3.143,1.21,4.72,1.574,8.224,1.574   c4.11,0,7.01-0.728,10.638-2.781l80.174-44.257l-3.51-6.048L67.592,44.135H41.96c-0.242,0-0.485-0.242-0.485-0.482   c0-0.124,0.118-0.242,0.243-0.367l22.488-12.451c2.782-1.45,4.478-4.356,4.478-7.377c0-4.839-3.991-8.706-8.951-8.706   c-1.45,0-3.024,0.364-4.347,1.21V11.729z M100,43.046L0,98.308h100V43.046z"
        ></path>
      </g>
    </svg>
  );
}