import React from "react";
import { Search, MemberBlock } from "../../modal_modules";
const Members = ({ friends, handleAddMember, isAccountGroupEdit, accountGroupRid }) => {
  return (
    <div className="mod-inv-friends-lodestar-wrapper">
      <Search />
      <div className="account-group-modal-friends--wrapper">
        {friends &&
          friends.map((ele, idx) => {
            return (
              <MemberBlock
                member={ele}
                type="account-group-friends"
                addClickHandler={handleAddMember}
                isAccountGroup
                isAccountGroupEdit={isAccountGroupEdit}
                accountGroupRid={accountGroupRid}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Members;
