import {
  deleteAdventureBlock,
  upsertItineraryItemBlock,
} from 'actions/adventure_actions';
import {
  fetchUserBlockCategories,
  deleteBlockFile,
  upsertBlock,
  fetchBlockList,
  moveToAdventureBlock,
  deleteBlock,
  copyToTripBoard,
  copyBlockAndAddToItineraryItems,
  fetchFolderContents,
} from 'actions/block_actions';
import {
  postBlockComment,
  postAdventureBlockComment,
} from 'actions/comment_actions';
import { persistBlockModalData } from 'actions/cache_actions';
import { openBlockFileModal } from 'actions/modal_actions';
import { fetchItineraryItemsAndBlockNames } from 'actions/itinerary_actions';

export const mapStateToProps = (state) => {
  //data of curretn adventure where it's passed from
  let current_adventure = state.entities.adventure.current_adventure
    ? state.entities.adventure.current_adventure
    : null;
  // fetched items needed for block lables

  let user_block_categories = state.entities.blocks.user_block_categories
    ? state.entities.blocks.user_block_categories
    : null;
  let user_block_activites = state.entities.blocks.user_block_activities
    ? state.entities.blocks.user_block_activities
    : null;

  let user_block_activity_details = state.entities.blocks
    .user_block_activity_details
    ? state.entities.blocks.user_block_activity_details
    : null;
  // necessary infomration persisted for session
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;

  // fetched when viewing block votes, files, lists
  let blockVotes = state.entities.votes.all_block_votes
    ? state.entities.votes.all_block_votes
    : null;
  let blockComments = state.entities.comments.block_comments
    ? state.entities.comments.block_comments
    : null;

  let blockLists = state.entities.blocks.block_lists
    ? state.entities.blocks.block_lists
    : null;

  // passed from modal reducers
  let openType = state.ui.modal.openType ? state.ui.modal.openType : null;
  let blockType = state.ui.modal.blockType ? state.ui.modal.blockType : null;
  let actionPayload = state.ui.modal.actionPayload
    ? state.ui.modal.actionPayload
    : null;
  let currentTab = state.ui.modal.currentTab ? state.ui.modal.currentTab : null;

  //passed from modal data persisters
  let currentBlock = state.dataCache.modal.currentBlock
    ? state.dataCache.modal.currentBlock
    : null;
  let currentBlockFiles = state.dataCache.modal.currentBlockFiles
    ? state.dataCache.modal.currentBlockFiles
    : null;

  let persistedModalData = state.dataCache.modal.savedBlockModalData
    ? state.dataCache.modal.savedBlockModalData
    : null;

  let isAdventureBlock = state.entities.blocks.isAdventureBlock
    ? state.entities.blocks.isAdventureBlock
    : null;

  let folderContents = state.entities.blocks.folderContents
    ? state.entities.blocks.folderContents
    : null;

  let itineraryItemsAndBlockNames =
    state.ui?.modal?.itineraryItemsAndBlockNames;
  return {
    current_adventure,
    user_block_categories,
    user_block_activites,
    user_block_activity_details,

    userInfo,
    blockVotes,
    blockComments,
    blockLists,

    openType,
    blockType,
    actionPayload,
    currentTab,

    currentBlock,
    currentBlockFiles,
    persistedModalData,
    isAdventureBlock,

    folderContents,
    // used for adding to the itinerary
    itineraryItemsAndBlockNames,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchUserInspirations: (account_rid) => dispatch(fetchUserInspirations(account_rid)),

    fetchUserBlockCategories: (account_rid) =>
      dispatch(fetchUserBlockCategories(account_rid)),
    openBlockFileModal: (editView, data) =>
      dispatch(openBlockFileModal(editView, data)),
    deleteBlockFile: (blockFileRid, blockRid) =>
      dispatch(deleteBlockFile(blockFileRid, blockRid)),
    postBlockComment: (data) => dispatch(postBlockComment(data)),
    deleteAdventureBlock: (adventure_block_rid, adventure_rid) =>
      dispatch(deleteAdventureBlock(adventure_block_rid, adventure_rid)),
    fetchBlockList: (block_rid, adventure_block_rid, userInfo) =>
      dispatch(fetchBlockList(block_rid, adventure_block_rid, userInfo)),
    upsertItineraryItemBlock: (data, itineraryItemRid, userInfo, fetchType) =>
      dispatch(
        upsertItineraryItemBlock(data, itineraryItemRid, userInfo, fetchType),
      ),
    postAdventureBlockComment: (data) =>
      dispatch(postAdventureBlockComment(data)),
    upsertBlock: (userInfo, data, returnType, updateActionPayload) =>
      dispatch(upsertBlock(userInfo, data, returnType, updateActionPayload)),
    persistBlockModalData: (openType, actionPayload) =>
      dispatch(persistBlockModalData(openType, actionPayload)),
    moveToAdventureBlock: (data, userInfo, returnType, actionPayload) =>
      dispatch(moveToAdventureBlock(data, userInfo, returnType, actionPayload)),
    deleteBlock: (data, userInfo, blockType, returnActionPayload) =>
      dispatch(deleteBlock(data, userInfo, blockType, returnActionPayload)),
    copyToTripBoard: (data, userInfo, origin, returnActionPayload) =>
      dispatch(copyToTripBoard(data, userInfo, origin, returnActionPayload)),
    copyBlockAndAddToItineraryItems: (data, itineraryItems, func) =>
      dispatch(copyBlockAndAddToItineraryItems(data, itineraryItems, func)),
    fetchFolderContents: (block_rid) =>
      dispatch(fetchFolderContents(block_rid)),
    fetchItineraryItemsAndBlockNames: (itinerary_rid) =>
      dispatch(fetchItineraryItemsAndBlockNames(itinerary_rid)),
  };
};
