import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { merge } from 'lodash';
import styled from 'styled-components';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal/dist/main.js';
import { svgDelete, svgEdit } from 'components/svg_module';
import { mapStateToProps, mapDispatchToProps } from './dnd_tree_container';
import ListAddForm from './list_form';

const maxDepth = 4;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? '#F7B844' : 'rgba(0,0,0,0.5)')};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className} onClick={(e) => props.onChange(e)}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

const TreeLists = ({
  blockListsAsTree,
  currentBlock,
  upsertBlock,
  actionPayload,
  userInfo,
  newBlock,
  setViewFullScreen,
  fetchListDropDown,
  listScrollerRef,
  setDisplayScrollIndicator,
  deleteBlock,
  closePopup,
  openEditListEntryPopup,
  ...props
}) => {
  currentBlock = currentBlock ? currentBlock : newBlock;
  const [treeState, setTreeState] = useState({
    searchString: '',
    searchFocusIndex: -1,
    searchFoundCount: 0,
    treeData: blockListsAsTree,
  });
  const [displayInput, setDisplayInput] = useState({
    display: false,
    displayIndex: 0,
    modifiedParentBlockRid: null,
    parentItemRid: null,
    selectedItem: null,
  });

  useEffect(() => {
    if (treeState.treeData !== blockListsAsTree)
      setTreeState({
        ...treeState,
        treeData: blockListsAsTree,
      });
  }, [blockListsAsTree]);
  useEffect(() => {
    let documentTargeted = document.getElementsByClassName(
      'check-list-tree',
    )[0];

    if (
      documentTargeted &&
      documentTargeted.scrollWidth > documentTargeted.clientWidth
    ) {
      setDisplayScrollIndicator(true);
    } else {
      setDisplayScrollIndicator(false);
    }
  }, [treeState]);
  const handleTreeOnChange = useCallback((treeData) => {
    //this function updates to ui to be seemless
    setTreeState({
      ...treeState,
      treeData,
    });
  });
  const listNodeCancelHandler = () => {
    setDisplayInput({
      display: false,
      displayIndex: 0,
      modifiedParentBlockRid: null,
      parentItemRid: null,
    });
    closePopup();
  };
  const addUnderItemHandler = useCallback((e, block) => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayInput({
      display: true,
      parentItemRid: block.block_rid,
      selectedItem: block,
    });
    openEditListEntryPopup(
      <ListAddForm
        currentBlock={currentBlock}
        cancelButtonHandler={listNodeCancelHandler}
        modifiedParentBlockRid={displayInput.modifiedParentBlockRid}
        actionPayload={actionPayload}
        upsertBlock={upsertBlock}
        userInfo={userInfo}
        parentItemRid={block.block_rid}
        newBlock={newBlock}
        fetchListDropDown={fetchListDropDown}
        selectedItem={block}
      />,
    );
  });
  const handleMoveNode = useCallback(
    ({
      treeData,
      node,
      nextParentNode,
      prevPath,
      prevTreeIndex,
      nextPath,
      nextTreeIndex,
    }) => {
      if (nextParentNode) {
        let blockType = {
          isListBlock: true,
        };
        let returnActionPayload = {
          parentBlockRid: currentBlock
            ? currentBlock.block_rid
            : newBlock.block_rid,
          adventureBlockRid: currentBlock
            ? currentBlock.adventure_block_rid
            : newBlock.adventure_block_rid,
        };
        let passOver = merge({}, node);
        passOver.parent_block_rid = nextParentNode.block_rid;
        if (passOver.block_count === '') passOver.block_count = null;
        if (passOver.block_cost === '') passOver.block_cost = null;
        upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      } else {
        let blockType = {
          isListBlock: true,
        };
        let returnActionPayload = {
          parentBlockRid: currentBlock
            ? currentBlock.block_rid
            : newBlock.block_rid,
          adventureBlockRid: currentBlock
            ? currentBlock.adventure_block_rid
            : newBlock.adventure_block_rid,
        };
        let passOver = merge({}, node);
        passOver.parent_block_rid = currentBlock.block_rid;
        if (passOver.block_count === '') passOver.block_count = null;
        if (passOver.block_cost === '') passOver.block_cost = null;
        upsertBlock(userInfo, passOver, blockType, returnActionPayload);
      }
    },
  );

  useEffect(() => {
    let treeWrapper = document.getElementById('treewrapperScrollContainer');
    if (treeWrapper && treeWrapper.scrollHeight > treeWrapper.clientHeight) {
      setDisplayScrollIndicator(true);
    } else {
      setDisplayScrollIndicator(false);
    }
  }, [treeState]);
  const handleSearchOnChange = useCallback((e) => {
    const { value } = e.target;
    setTreeState({
      ...treeState,
      searchString: value,
    });
  });

  const selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = treeState;
    setTreeState({
      ...treeState,
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1,
    });
  };
  const selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = treeState;
    setTreeState({
      ...treeState,
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0,
    });
  };

  const checkBoxOnChangeHanlder = (e, rowInfo) => {
    e.stopPropagation();
    e.preventDefault();
    const { node } = rowInfo;
    let block = node;
    let blockType = {
      isAdventureListBlockCheck: currentBlock.adventure_block_rid
        ? true
        : false,
      isLibraryListBlockCheck: currentBlock.adventure_block_rid ? true : false,
      isListBlock: true,
    };
    let passObject = merge({}, block);
    let returnActionPayload = {
      parentBlockRid: currentBlock.block_rid,
      adventureBlockRid: currentBlock.adventure_block_rid,
    };
    passObject['completed_flag'] = !block.completed_flag;
    passObject['adventure_rid'] = actionPayload.adventureRid
      ? actionPayload.adventureRid
      : null;
    if (passObject.child_count > 0) {
      passObject.block_count = null;
      passObject.block_cost = null;
    }
    upsertBlock(userInfo, passObject, blockType, returnActionPayload);
  };

  const deleteHandler = (block) => {
    let blockType = {
      isListBlock: true,
      isNestedListBlock: block.block_level > 2 ? true : null,
    };
    let returnActionPayload = {
      parentBlockRid: currentBlock ? currentBlock.block_rid : null,
      adventureBlockRid:
        currentBlock && currentBlock.adventure_block_rid
          ? currentBlock.adventure_block_rid
          : null,
    };
    deleteBlock(block, userInfo, blockType, returnActionPayload);
  };

  const {
    treeData,
    searchString,
    searchFocusIndex,
    searchFoundCount,
  } = treeState;
  return (
    <>
      <div className="tree-wrapper" ref={listScrollerRef} id="tree-wrapper">
        {treeData && treeData.length > 0 ? (
          <SortableTree
            treeData={treeData}
            onChange={handleTreeOnChange}
            maxDepth={maxDepth}
            searchQuery={searchString}
            searchFocusOffset={searchFocusIndex}
            canDrag={({ node }) => !node.noDragging}
            canDrop={({ nextParent }) => true}
            searchFinishCallback={(matches) =>
              setTreeState({
                ...treeState,
                searchFoundCount: matches.length,
                searchFocusIndex:
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
              })
            }
            isVirtualized={false}
            generateNodeProps={(rowInfo) => {
              return {
                buttons: [
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      addUnderItemHandler(e, rowInfo.node);
                    }}
                    className={`hidden-button-wrapper hidden-button-wrapper--edit hidden-button-wrapper--edit--level${rowInfo.node.block_level - 1
                      }`}
                  >
                    {svgEdit('', 'tree-node-svg', 'tree-node-svg-outer')}
                  </button>,
                  currentBlock.adventure_block_rid ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className={`hidden-button-wrapper hidden-button-wrapper--check-box hidden-button-wrapper--check-box--level${rowInfo.node.block_level - 1
                        }`}
                    >
                      <Checkbox
                        checked={rowInfo.node.completed_flag}
                        onChange={(e) => checkBoxOnChangeHanlder(e, rowInfo)}
                      />
                    </button>
                  ) : (
                    <button
                      className={`hidden-button-wrapper hidden-button-wrapper--check-box hidden-button-wrapper--check-box--level${rowInfo.node.block_level - 1
                        }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    ></button>
                  ),
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteHandler(rowInfo.node);
                    }}
                    className={`hidden-button-wrapper hidden-button-wrapper--delete hidden-button-wrapper--delete--level${rowInfo.node.block_level - 1
                      } `}
                  >
                    {svgDelete('', 'tree-node-svg', 'tree-node-svg-outer')}
                  </button>,
                ],
              };
            }}
            style={{
              height: '100%',
              widht: '100%',
            }}
            className="check-list-tree"
            rowHeight={38}
            theme={FileExplorerTheme}
            onMoveNode={handleMoveNode}
          />
        ) : (
          <div className="hello" />
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeLists);
