export function tent1 (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M25.45496,32.89001c-2.32353,0.64551-4.61218,0.65035-7.23999-0.46002v11.25L25.47498,32.88   C25.46497,32.88,25.46497,32.88,25.45496,32.89001z"
        ></path>
        <g>
          <path
            className={className}
            fill={color}
            d="M23.09509,20.92999c-0.29004-0.39996-0.96008-0.39996-1.23999,0C20.25703,23.1272,27.64457,12.97,6.22498,42.41998    H3.24499c-0.20996,0-0.41992,0.09003-0.55994,0.25c-0.14001,0.16003-0.21008,0.38-0.18005,0.59003l0.59009,4.46997    c0.04993,0.38,0.36987,0.66003,0.75,0.66003h2.12988c0.38013,0,0.70007-0.28003,0.75-0.66003l0.56006-4.25l2.69995-3.87162    v8.03166c0,0.40997,0.33008,0.75,0.75,0.75h23.47998c0.41016,0,0.75-0.34003,0.75-0.75v-9.31    c0-0.15002-0.04993-0.29999-0.12988-0.42999L23.09509,20.92999z M28.23499,46.89001H16.71497V31.23999    c0-0.26996,0.13013-0.51001,0.36011-0.64996c0.22998-0.13,0.51001-0.14001,0.73999-0.01001    c3.12988,1.66998,6.17993,1.66998,9.31006,0c0.52091-0.27599,1.10986,0.1075,1.10986,0.65997V46.89001z"
          ></path>
          <path
            className={className}
            fill={color}
            d="M34.745,26.79004c6.83643,2.96002,12.79272,2.65991,13.31006,2.70996l-5.5-2.70996H34.745z"
          ></path>
          <path
            className={className}
            fill={color}
            d="M66.31506,42.66998c-0.14001-0.15997-0.3501-0.25-0.57007-0.25h-3l-4.44922-4.54791    c-1.19844-1.73232,4.3414,6.27542-11.7207-16.94208c-0.12012-0.16998-0.30005-0.26001-0.5-0.28998    c-0.04004-0.01001-0.08008-0.03003-0.12012-0.03003H24.69495l11.37012,16.44c0.10999,0.17999,0.19995,0.35004,0.26001,0.53003    h19.94995l0.67993,0.98999v0.51001H36.46497v9.31h21.23999c0.41016,0,0.75-0.34003,0.75-0.75v-7.46173l3.26001,3.33173    l0.56006,4.21997c0.05005,0.38,0.37,0.66003,0.75,0.66003h2.13c0.38,0,0.70007-0.28003,0.75-0.66003l0.58997-4.46997    C66.52502,43.04999,66.45496,42.83002,66.31506,42.66998z M49.72498,30.28003c-1.38342-0.0127-2.35156,0.0415-4.03992-0.09003    l2.12,3.14001c0.17346,0.26031-0.01196,0.60999-0.32007,0.60999h-9.67993c-0.13,0-0.25-0.06-0.32007-0.16998    c0.2511,0.37048-3.6853-5.43719-4.85986-7.16998c-0.16772-0.25122,0.03589-0.59003,0.32983-0.59003h9.69006    c0.05994,0,0.12,0.01001,0.17004,0.03998l7.07996,3.49005c0.16003,0.07996,0.25,0.25995,0.21008,0.43994    C50.06506,30.16003,49.90503,30.28003,49.72498,30.28003z"
          ></path>
        </g>
      </g>
    </svg>
  );
}