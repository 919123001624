import * as ItineraryAPI from '../util/api_util/itinerary_api_util';
import * as AdventureAPI from '../util/api_util/adventure_api_util';
import * as BlockApi from '../util/api_util/block_api_util';
import {
  RECEIVE_ITINERARY_ITEM,
  RECEIVE_ITINERARY_ITEMS,
  RECEIVE_USER_ITINERARY_DETAILS,
  RECEIVE_MOVED_BLOCK,
  RECEIVE_EDITED_ITINERARY_ITEM,
  RECEIVE_MOVED_BLOCK_BETWEEN_ITEM,
  RECEIVE_USER_ITINERARY,
  RECEIVE_ITINERARY_COMMENTS,
  RECEIVE_ITINERARY_VOTES,
  RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE,
  RECEIVE_MOVE_ITINERARY_ITEM_ONLY,
  RECEIVE_ITINERARY_LOCATION_BLOCKS,
  RECEIVE_TRIP_BOARD_LOCATION_BLOCKS,
  RECEIVE_ITINERARY_MAP_FILTERS,
  RECEIVE_FLUSH_ITINERARY_DATA,
  RECEIVE_ITINERARY_AND_ITINERARY_ITEMS,
  RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES,
  RECEIVE_ITINERARY_INFORMATION,
} from '../constants';
import { updateCurrentItinerary } from './block_actions';
import { receiveItineraryInformation } from './adventure_actions';
const receiveItineraryItem = (data) => ({
  type: RECEIVE_ITINERARY_ITEM,
  data,
});
import { useSelector } from 'react-redux';

export const receiveItineraryItemsAndBlocksNames = (data) => ({
  type: RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES,
  data,
});

const receiveItineraryAndItineraryItems = (data) => ({
  type: RECEIVE_ITINERARY_AND_ITINERARY_ITEMS,
  data,
});

const receiveItineraryMapFilters = (data) => ({
  type: RECEIVE_ITINERARY_MAP_FILTERS,
  data,
});
const receiveFlushItineraryData = () => ({
  type: RECEIVE_FLUSH_ITINERARY_DATA,
});
export const receiveSingleItineraryItemBlockUpdate = (data) => {
  return {
    type: RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE,
    data,
  };
};
export const receiveItineraries = (data) => ({
  type: RECEIVE_USER_ITINERARY_DETAILS,
  data,
});

const receiveMovedBlock = (data) => ({
  type: RECEIVE_MOVED_BLOCK,
  data,
});
const receiveMoveItineraryItemOnly = (data) => ({
  type: RECEIVE_MOVE_ITINERARY_ITEM_ONLY,
  data,
});
const receiveEditedItineraryItem = (data) => ({
  type: RECEIVE_EDITED_ITINERARY_ITEM,
  data,
});
const receiveItineraryItems = (data) => ({
  type: RECEIVE_ITINERARY_ITEMS,
  data,
});
const receiveMovedBlockBetweenItem = (data) => ({
  type: RECEIVE_MOVED_BLOCK_BETWEEN_ITEM,
  data,
});

const receiveUserItinerary = (data) => ({
  type: RECEIVE_ITINERARY_INFORMATION,
  data,
});

const receiveItineraryComments = (data) => ({
  type: RECEIVE_ITINERARY_COMMENTS,
  data,
});

const receiveItineraryVotes = (data) => ({
  type: RECEIVE_ITINERARY_VOTES,
  data,
});

export const receiveLocationBlocks = (data) => ({
  type: RECEIVE_ITINERARY_LOCATION_BLOCKS,
  data,
});

export const receiveTripBoardLocationBlocks = (data) => ({
  type: RECEIVE_TRIP_BOARD_LOCATION_BLOCKS,
  data,
});

export const fetchItineraryDetails = (
  itinerary_rid,
  account_rid,
  explorer_rid,
) => (dispatch) => {
  return ItineraryAPI.fetchItineraryDetail(itinerary_rid, explorer_rid).then(
    (res) => {
      // -- use this for refetching itinerary deets
      return dispatch(receiveItineraries(res.data));
    },
  );
};
export const refreshItineraries = (itinerary_rid, explorer_rid, dispatch) => {
  return ItineraryAPI.fetchItineraryDetail(itinerary_rid, explorer_rid).then(
    (res) => {
      return dispatch(receiveItineraries(res.data));
    },
  );
};
export const moveItineraryBlock = (
  itinerary_item_rid,
  block_rid,
  account_rid,
  explorer_rid,
  sort_order,
  itinerary_rid,
) => (dispatch) => {
  return ItineraryAPI.moveBlockToItnerary(
    itinerary_item_rid,
    block_rid,
    account_rid,
    explorer_rid,
    sort_order,
  ).then((res) => {
    return dispatch(receiveMovedBlock(res.data));
  });
};

export const moveItineraryBlockBetweenItems = (
  itinerary_item_rid,
  block,
  account_rid,
  explorer_rid,
  sort_order,
) => (dispatch, getState) => {
  const { block_rid, itineriary_block_rid } = block;
  if (!!itineriary_block_rid === false) return;
  return ItineraryAPI.moveBlockToItneraryBetweenItem(
    itinerary_item_rid,
    block_rid,
    account_rid,
    itineriary_block_rid,
    explorer_rid,
    sort_order,
  ).then((res) => {
    let items =
      res?.data?.itinerary_item_rid !== block?.itinerary_item_rid
        ? [
            { itinerary_item_rid: parseInt(res.data.itinerary_item_rid) },
            { itinerary_item_rid: block.itinerary_item_rid },
          ]
        : [{ itinerary_item_rid: res.data.itinerary_item_rid }];
    //updateCurrentItinerary(dispatch, getState, items);
    return dispatch(
      receiveMovedBlockBetweenItem({
        ...res.data,
        org_itinerary_item_rid: block.itinerary_item_rid,
        block,
      }),
    );
  });
};

export const deleteItineraryBlockItem = (
  itinerary_block_rid,
  itinerary_item_rid,
  explorer_rid,
) => (dispatch) => {
  return ItineraryAPI.deleteItineraryBlockItem(
    itinerary_block_rid,
    itinerary_item_rid,
    explorer_rid,
  ).then((res) => {
    return dispatch(receiveMovedBlock(res.data));
  });
};

export const editItineraryItem = (itinerary_item_rid, data) => (dispatch) => {
  return ItineraryAPI.editItineraryItem(
    itinerary_item_rid,
    data,
  ).then((res) => {});
};

export const editItinerary = (data) => (dispatch) => {
  return ItineraryAPI.editItinerary(data).then((res) => {
    return dispatch(receiveEditedItineraryItem(res.data));
  });
};

export const postItinerary = (adventure_rid, data) => (dispatch) => {
  return ItineraryAPI.postItinerary(adventure_rid, data).then((res) => {
    return dispatch(receiveUserItinerary(res.data.adventureItineraries));
    // if (data.itinerary_rid) {
    //   window.location.reload();
    // }
  });
};

//**PINNED**
// if action type is move {moveItineraryItemOnly: true } do not update itBlock
export const upsertItineraryItem = (data, userInfo, actionType) => (
  dispatch,
) => {
  return ItineraryAPI.postItineraryItem(data).then((res) => {
    if (actionType && actionType.moveItineraryItemOnly) {
      return dispatch(receiveMoveItineraryItemOnly(res.data));
    }

    Promise.all([
      AdventureAPI.fetchAdventureItinerary(
        data.adventure_rid,
        userInfo.explorer_rid,
      ),
      ItineraryAPI.fetchItineraryDetail(
        data.itinerary_rid,
        userInfo.explorer_rid,
      ),
    ]).then((res) => {
      let itineraryInformation = res[0].data.adventureItineraries.filter(
        (ele) => {
          return ele.itinerary_rid === parseInt(data.itinerary_rid);
        },
      );
      dispatch(receiveItineraryInformation(itineraryInformation));
      // -- use this for refetching itinerary deets
      return dispatch(receiveItineraries(res[1].data));
    });
  });
};
export const moveItineraryItemOnly = (data, userInfo) => (dispatch) => {
  return ItineraryAPI.postItineraryItem(data).then((res) => {});
};
export const deleteItinerary = (adventure_rid, itinerary_rid, userInfo) => (
  dispatch,
) => {
  return ItineraryAPI.deleteItinerary(adventure_rid, itinerary_rid, userInfo);
};

export const deleteItineraryItem = (
  adventure_rid,
  explorer_rid,
  itinerary_item_rid,
  itinerary_rid,
) => (dispatch) => {
  return ItineraryAPI.deleteItineraryItem(
    itinerary_item_rid,
    itinerary_rid,
  ).then((res) => {
    Promise.all([
      AdventureAPI.fetchAdventureItinerary(adventure_rid, explorer_rid),
      ItineraryAPI.fetchItineraryDetail(itinerary_rid, explorer_rid),
    ]).then((res) => {
      const itineraryInformation = res[0].data.adventureItineraries.filter(
        (ele) => {
          return ele.itinerary_rid === parseInt(itinerary_rid);
        },
      );
      dispatch(receiveItineraryInformation(itineraryInformation));
      // -- use this for refetching itinerary deets
      return dispatch(receiveItineraries(res[1].data));
    });
    return dispatch(receiveItineraryItems(res.data));
  });
};

export const moveItineraries = (adventure_rid, data) => (dispatch) => {
  return ItineraryAPI.postItinerary(adventure_rid, data).then((res) => {});
};

export const fetchItineraryComments = (itineraryRid) => (dispatch) => {
  return ItineraryAPI.fetchItineraryComments(itineraryRid).then((res) => {
    dispatch(receiveItineraryComments(res.data));
  });
};

export const upsertItineraryComment = (data) => (dispatch) => {
  return ItineraryAPI.upsertItineraryComment(data).then((res) => {
    dispatch(receiveItineraryComments(res.data));
    return AdventureAPI.fetchAdventureItinerary(
      data.adventure_rid,
      data.explorer_rid,
    ).then((res) => {
      let itineraryInformation = res.data.adventureItineraries.filter((ele) => {
        return ele.itinerary_rid === parseInt(data.itinerary_rid);
      });
      dispatch(receiveItineraryInformation(itineraryInformation));
    });
  });
};

export const deleteItineraryComment = (itineraryRid, itineraryCommentRid) => (
  dispatch,
) => {
  return ItineraryAPI.deleteItineraryComments(
    itineraryRid,
    itineraryCommentRid,
  ).then((res) => {
    dispatch(receiveItineraryComments(res.data));
  });
};

export const fetchItineraryVotes = (itineraryRid, userInfo) => (dispatch) => {
  return ItineraryAPI.fetchItineraryVotes(
    itineraryRid,
    userInfo.explorer_rid,
  ).then((res) => {
    return dispatch(receiveItineraryVotes(res.data));
  });
};

export const upsertItineraryVotes = (data) => (dispatch) => {
  return ItineraryAPI.upsertItineraryVotes(data).then(() => {
    return ItineraryAPI.fetchItineraryVotes(
      data.itinerary_rid,
      data.explorer_rid,
    ).then((res) => {
      dispatch(receiveItineraryVotes(res.data));
      return AdventureAPI.fetchAdventureItinerary(
        data.adventure_rid,
        data.explorer_rid,
      ).then((res) => {
        let itineraryInformation = res.data.adventureItineraries.filter(
          (ele) => {
            return ele.itinerary_rid === parseInt(data.itinerary_rid);
          },
        );
        dispatch(receiveItineraryInformation(itineraryInformation));
      });
    });
  });
};

export const deleteItineraryVotes = (
  itineraryVoteRid,
  userInfo,
  itineraryInfo,
) => (dispatch) => {
  return ItineraryAPI.deleteItineraryVote(
    itineraryVoteRid,
    userInfo.explorer_rid,
    itineraryInfo.itinerary_rid,
  ).then(() => {
    return AdventureAPI.fetchAdventureItinerary(
      itineraryInfo.adventure_rid,
      userInfo.explorer_rid,
    ).then((res) => {
      let itineraryInformation = res.data.adventureItineraries.filter((ele) => {
        return ele.itinerary_rid === parseInt(itineraryInfo.itinerary_rid);
      });
      dispatch(receiveItineraryInformation(itineraryInformation));
    });
  });
};
// export const deleteItineraryItemBlock

export const updateItineraryStartDate = (data) => (dispatch) => {
  return ItineraryAPI.updateItineraryStartDate(data);
  //   .then((res) => {
  //   dispatch(receiveItineraryComments(res.data));
  //   return AdventureAPI.fetchAdventureItinerary(
  //     data.adventure_rid,
  //     data.explorer_rid
  //   ).then((res) => {
  //     let itineraryInformation = res.data.adventureItineraries.filter((ele) => {
  //       return ele.itinerary_rid === parseInt(data.itinerary_rid);
  //     });
  //     dispatch(receiveItineraryInformation(itineraryInformation));
  //   });
  // });
};

export const copyItinerary = (data) => (dispatch) => {
  return ItineraryAPI.copyItinerary(data).then((res) => {});
};

/* used for fetching itinerary location blocks
  data ={
   data:  {
    account_rid: number,
    itinerary_rid: number,
    adventure_rid: nullable, number,
    direction_flag: boolean
    }
  }

*/
export const fetchLocationBlocks = (data) => (dispatch) => {
  // if (!data.adventure_rid && !data.itinerary_rid) {
  //   return BlockApi.fetchLibraryLocationBlocks(data.account_rid);
  // }
  return ItineraryAPI.fetchLocationBlocks(data).then((res) =>
    dispatch(receiveLocationBlocks(res.data)),
  );
};

export const fetchTripBoardLocationBlocks = (data) => (dispatch) => {
  return ItineraryAPI.fetchLocationBlocks(data).then((res) =>
    dispatch(receiveTripBoardLocationBlocks(res.data)),
  );
};

// this functinon will be called on map filter changes in order for the mapbox elements to have access to map filters
export const sendItineraryMapFilters = (data) => (dispatch) => {
  return dispatch(receiveItineraryMapFilters(data));
};

// this is for flushing all data for itineraries so traces aren't left behind when user visits another itinerary the traces aren't left behind
export const flushItineraryData = () => (dispatch) => {
  return dispatch(receiveFlushItineraryData());
};

export const updateItineraryTransportationType = (data) => (dispatch) => {
  return ItineraryAPI.updateItineraryTransportationType(data).then(
    ({ status, data }) => {
      if (status === 200) {
        return data.itineraryRid;
      }
    },
  );
};

export const fetchItineraryAndItineraryItems = (
  adventure_rid,
  explorer_rid,
) => (dispatch) => {
  return ItineraryAPI.fetchItineraryAndItineraryItems(
    adventure_rid,
    explorer_rid,
  ).then((res) => {
    return dispatch(
      receiveItineraryAndItineraryItems(res.data.itineraryAndItineraryItems),
    );
  });
};

export const fetchItineraryItemsAndBlockNames = (itinerary_rid) => (
  dispatch,
) => {
  return ItineraryAPI.fetchItineraryItemsAndBlockNames(itinerary_rid).then(
    (res) => {
      return dispatch(
        receiveItineraryItemsAndBlocksNames(res.data.itineraryDetails),
      );
    },
  );
};

export const calculateImpactForTrip = (data) => (dispatch) => {
  return ItineraryAPI.calculateImpactForTrip(data);
};
