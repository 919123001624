import {
  RECEIVE_PAGE_KEY_WORDS,
  RECEIVE_PAGE_META_DATA,
  RECEIVE_PAGE_DATA,
  RECEIVE_PAGE_TOOL,
  RECEIVE_ACCOUNT_ROLES,
  RECEIVE_COUNTRIES,
  RECEIVE_STATES,
  RECEIVE_PLAN_DETAILS,
  RECEIVE_PLAN_FINAL_PIRCE,
  RECEIVE_UNSUBSCRIPTION_REASONS,
  RECEIVE_AMBASSADOR_CODES,
  RECEIVE_OPEN_DRAWER_WITH_TAB,
  RECEIVE_PROMOTION_BANNER,
  RECEIVE_PARTNER_PROMOTION_CODES,
  RECEIVE_PARTNERS,
  RECEIVE_SELECTED_PARTNER,
  RECEIVE_FLUSH_PARTNERS,
  RECEIVE_FLUSH_SELECTED_PARTNERS,
  RECEIVE_PARTNER_DONATION_DISTRIBUTION,
  RECEIVE_TRANSPORTATION_TYPES,
  RECEIVE_CALCULATED_IMPACT_CARBON,
  RECEIVE_SOCIAL_COST_OF_CARBON,
  SET_MODAL_POPUP_TOGGLE,
  SET_ACTIVE_MAP_LAYERS,
  SET_DETAIL_POPUP_TOGGLE,
} from '../constants';
import * as UiApiUtil from '../util/api_util/ui_api_util';

const receivePartners = (data) => {
  return {
    type: RECEIVE_PARTNERS,
    data,
  };
};
// this sets calculated carbon amount from adventure selected page to the check out page
const receiveImpactCarbon = (data) => ({
  type: RECEIVE_CALCULATED_IMPACT_CARBON,
  data,
});
// this is to receive social cost of carbon fetched from the database
const receiveSocialCostOfCarbon = (data) => ({
  type: RECEIVE_SOCIAL_COST_OF_CARBON,
  data,
});
const receiveSelectedPartner = (data) => {
  return {
    type: RECEIVE_SELECTED_PARTNER,
    data,
  };
};
const receiveTransportationTypes = (data) => {
  return {
    type: RECEIVE_TRANSPORTATION_TYPES,
    data,
  };
};
const receivePartnerDonationDistribution = (data) => {
  return {
    type: RECEIVE_PARTNER_DONATION_DISTRIBUTION,
    data,
  };
};
const receiveFlushSelectedPartners = () => {
  return {
    type: RECEIVE_FLUSH_SELECTED_PARTNERS,
  };
};
const receiveFlushPartners = () => {
  return {
    type: RECEIVE_FLUSH_PARTNERS,
  };
};
const receivePartnerPromotionCodes = (data) => {
  return {
    type: RECEIVE_PARTNER_PROMOTION_CODES,
    data,
  };
};
const receivePromotionBanner = (data) => {
  return {
    type: RECEIVE_PROMOTION_BANNER,
    data,
  };
};
const receiveKeyWords = (data) => {
  return {
    type: RECEIVE_PAGE_KEY_WORDS,
    data,
  };
};

const receiveOpenDrawerWithTab = (data) => {
  return {
    type: RECEIVE_OPEN_DRAWER_WITH_TAB,
    data,
  };
};

const receiveAmbassadorCodes = (data) => {
  return {
    type: RECEIVE_AMBASSADOR_CODES,
    data,
  };
};

const receiveUnsubscriptionReasons = (data) => {
  return {
    type: RECEIVE_UNSUBSCRIPTION_REASONS,
    data,
  };
};
const receivePlanFinalPrice = (data) => {
  return {
    type: RECEIVE_PLAN_FINAL_PIRCE,
    data,
  };
};
const receivePlanDetails = (data) => {
  return {
    type: RECEIVE_PLAN_DETAILS,
    data,
  };
};

const receiveActiveMapLayers = (data) => ({
  type: SET_ACTIVE_MAP_LAYERS,
  data,
});
export const receiveAccountRoles = (data) => {
  return {
    type: RECEIVE_ACCOUNT_ROLES,
    data,
  };
};

export const receiveCountries = (data) => {
  return {
    type: RECEIVE_COUNTRIES,
    data,
  };
};
export const receiveStates = (data) => {
  return {
    type: RECEIVE_STATES,
    data,
  };
};
const receiveMetaData = (data) => {
  return {
    type: RECEIVE_PAGE_META_DATA,
    data,
  };
};

const receivePageComponentData = (data) => {
  return {
    type: RECEIVE_PAGE_DATA,
    data,
  };
};

const receivePageTools = (data) => {
  return {
    type: RECEIVE_PAGE_TOOL,
    data,
  };
};

const receiveMapUiPopUpAction = (data) => ({
  type: SET_MODAL_POPUP_TOGGLE,
  data,
});

const receiveMapUiDetailPopUpAction = (data) => ({
  type: SET_DETAIL_POPUP_TOGGLE,
  data,
});

export const setMapUiDetailShow = (data) => (dispatch) => 
  dispatch(receiveMapUiDetailPopUpAction(data))

export const setMapUiPopup = (data) => (dispatch) =>
  dispatch(receiveMapUiPopUpAction(data));

export const fetchPageComponentData = (page_rid, component_rid) => (
  dispatch,
) => {
  return UiApiUtil.fetchPageComponentData(page_rid, component_rid).then(
    (res) => {
      return dispatch(receivePageComponentData(res.data));
    },
  );
};

export const fetchPageTools = (ui_tool_type_rid) => (dispatch) => {
  return UiApiUtil.fetchUiTools(ui_tool_type_rid).then((res) =>
    dispatch(receivePageTools(res.data)),
  );
};

export const fetchAccountRoles = () => (dispatch) => {
  return UiApiUtil.fetchAccountRoles().then((res) => {
    dispatch(receiveAccountRoles(res.data.accountRoles));
  });
};

export const fetchCountries = () => (dispatch) => {
  return UiApiUtil.fetchCountries().then((res) => {
    dispatch(receiveCountries(res.data.countries));
  });
};

export const fetchStates = () => (dispatch) => {
  return UiApiUtil.fetchStates().then((res) => {
    dispatch(receiveStates(res.data.statesProvences));
  });
};

export const fetchPlanDetails = (planTypeRid, planRid) => (dispatch) => {
  return UiApiUtil.fetchPlanDetails(planTypeRid, planRid).then((res) => {
    dispatch(receivePlanDetails(res.data.planDetails));
  });
};

export const fetchPlanFinalPrice = (data) => (dispatch) => {
  return UiApiUtil.fetchPlanFinalPirce(data).then((res) => {
    dispatch(receivePlanFinalPrice(res.data.planFinalPrice));
  });
};

export const fetchUnsubscriptionReasons = () => (dispatch) => {
  return UiApiUtil.fetchUnsubscriptionReasons().then(({ data }) =>
    dispatch(receiveUnsubscriptionReasons(data.unsubscriptionReasons)),
  );
};

export const fetchAmbassadorCodes = () => (dispatch) => {
  return UiApiUtil.fetchAmbassadorCodes().then((res) => {
    dispatch(receiveAmbassadorCodes(res.data.ambassadorDetails));
  });
};

export const fetchPartnerPromotionCodes = () => (dispatch) => {
  return UiApiUtil.fetchPartnerPromotionCode().then((res) => {
    dispatch(receivePartnerPromotionCodes(res.data.partnerCodes));
  });
};
export const triggerMenuDrawerWithTab = (tab) => (dispatch) => {
  return dispatch(receiveOpenDrawerWithTab(tab));
};

export const fetchUserPromotionBanner = (explorer_rid) => (dispatch) => {
  return UiApiUtil.fetchUiPromotionBanner(explorer_rid).then((res) => {
    dispatch(receivePromotionBanner(res.data.bannerMessages));
  });
};

export const fetchPartners = (
  partner_rid,
  partner_status_rid,
  adventure_rid,
) => (dispatch) => {
  return UiApiUtil.fetchPartners(
    partner_rid,
    partner_status_rid,
    adventure_rid,
  ).then((res) => {
    if (res.status === 200) {
      return dispatch(receivePartners(res.data.partners));
    }
  });
};

export const selectPartner = (partner) => (dispatch) => {
  dispatch(receiveSelectedPartner(partner));
};

export const flushPartners = () => (dispatch) => {
  dispatch(receiveFlushPartners());
};

export const flushSelectedPartners = () => (dispatch) => {
  dispatch(receiveFlushSelectedPartners());
};

export const sendPartnerDonationDistribution = (data) => (dispatch) => {
  dispatch(receivePartnerDonationDistribution(data));
};

export const fetchTransportationTypes = () => (dispatch) => {
  return UiApiUtil.fetchTransportationTypes().then(({ data, status }) => {
    return dispatch(receiveTransportationTypes(data.transportationTypes));
  });
};

export const fetchSocialCostOfCarbon = () => (dispatch) => {
  return UiApiUtil.fetchSocialCostOfCarbon().then((res) => {
    return dispatch(receiveSocialCostOfCarbon(res.data.impactCost));
  });
};

export const setCalculatedCarbon = (data) => (dispatch) => {
  return dispatch(receiveImpactCarbon(data));
};

export const searchAirports = (query) => (dispatch) => {
  return UiApiUtil.searchAirports(query);
};

export const setActiveMapLayers = (data) => (dispatch) => {
  return dispatch(receiveActiveMapLayers(data));
};
