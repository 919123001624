/* this modal will be used to add friends to an adventure through selection from user friendlist 



*/
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Skeleton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { setLoading } from 'actions/modal_actions';
import { fetchAdventureFriend } from 'actions/social_actions';
import useAdventureFriends, {
  ADVENTURE_FRIEND_ROLES,
  INVITE_STATUS,
} from 'features/friends/hooks/use-adventure-friends';
import UserListItem from 'features/user/components/user-list-item';
import UserListItemSkeleton from 'features/user/components/user-list-item.skeleton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';
import {
  deleteAdventureFriend,
  upsertAdventureFriend,
} from 'util/api_util/social_api_util';
import NotificationPayloads from '../../../../util/notification/return_payloads';
import HubModal from '../../modal';

const mapStateToProps = ({ ui, session }) => {
  let account_rid = session?.user?.account_rid;
  let explorer_rid = session?.user?.explorer_rid;

  let currentAdventureData = ui.modal.adventureData && ui.modal.adventureData;
  return {
    account_rid,
    explorer_rid,
    currentAdventureData,
  };
};

const generateClassName = createGenerateClassName({
  productionPrefix: 'invite-friends', // Unique prefix
});

const mapDispatchToProps = (dispatch) => {
  // return {
  //   upsertAdventureFriend: async (data) =>
  //     dispatch(await upsertAdventureFriend(data)),
  //   deleteAdventureFriend: (adventure_friend_rid, adventure_rid) =>
  //     dispatch(deleteAdventureFriend(adventure_friend_rid, adventure_rid)),
  // };
  return {
    fetchAdventureFriend: async (adventure_rid) =>
      dispatch(await fetchAdventureFriend(adventure_rid)),
  };
};

/**
 *  Modal to invite users to trip.
 * Uses offline first data manipulation and then
 * batch uploads when pressing save.
 * @param {*} param0
 * @returns
 */
const InviteFriendsToAdventure = ({
  account_rid, //current user
  explorer_rid, //current user
  closeModal,
  fetchAdventureFriend,
  currentAdventureData,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useAdventureFriends(
    account_rid,
    currentAdventureData.adventure_rid,
  );

  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    await handleChanges(data, users).then(() => {
      setSubmitting(false);
    });
  }, [users]);

  const handleChanges = async (original, updated) => {
    // Iterate through the original list while comparing against
    // the modified list, to see what users were changed and
    // what needs updating
    const updates = original.map((initialFriend) => {
      const updatedFriend = updated.find(
        (friend) => friend.explorer_rid === initialFriend.explorer_rid,
      );

      // user was not changed
      if (updatedFriend.invite_status === initialFriend.invite_status) {
        return;
      }

      // user was removed
      if (updatedFriend.invite_status === INVITE_STATUS.NOT_INVITED) {
        return deleteAdventureFriend(
          updatedFriend.adventure_friend_rid,
          currentAdventureData.adventure_rid,
        );
        // return deleteAdventureFriend(
        //   updatedFriend.adventure_friend_rid,
        //   currentAdventureData.adventure_rid,
        // );
      }

      // user was added
      if (updatedFriend.invite_status === INVITE_STATUS.ACCEPTED) {
        // format post data
        let newAdventureFriendData = {
          adventure_friend_rid: null,
          adventure_rid: currentAdventureData.adventure_rid,
          friend_rid: updatedFriend.friend_rid,
          sender_rid: explorer_rid,
          recipient_rid: updatedFriend.explorer_rid,
          friend_role_rid: ADVENTURE_FRIEND_ROLES.TRIP_GUEST,
          invite_response_rid: INVITE_STATUS.ACCEPTED,
          ui_notification_location: NotificationPayloads[
            'adventureInviteRequest'
          ](updatedFriend, currentAdventureData),
        };
        return upsertAdventureFriend(newAdventureFriendData);
        // return upsertAdventureFriend(newAdventureFriendData);
      }
    });
    try {
      // Wait for all promises to resolve
      await Promise.all(updates).then(
        async () =>
          await fetchAdventureFriend(currentAdventureData.adventure_rid),
      );

      const queryKey = [
        explorer_rid,
        'adventure',
        currentAdventureData.adventure_rid,
        'attendees',
      ];

      queryClient.invalidateQueries({
        queryKey,
      });
      closeModal();
    } catch (error) {
      // something when wrong in one of the promises
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    // update local copy with new data when it arrives
    if (isLoading) return;
    setUsers(data);
  }, [isLoading, data]);

  // return the button that corresponds with the desired action
  const ACTION_BUTTON = {
    [INVITE_STATUS.ACCEPTED]: (props) => <RemoveButton {...props} />,
    [INVITE_STATUS.NOT_INVITED]: (props) => <InviteButton {...props} />,
  };

  const filteredUsers = useMemo(() => {
    if (!users) return;
    if (!filter) return users;
    return users.filter((user) =>
      user.full_name.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [users, filter]);

  const hasData = !isLoading && data && data.length !== 0;
  const noSearchResults = Boolean(filter.length && filteredUsers?.length === 0);
  return (
    <StylesProvider generateClassName={generateClassName}>
      <HubModal
        show={true}
        title="INVITE FRIENDS"
        onClose={closeModal}
        onApproved={handleSubmit}
        submitButton={
          // need to wrap in theme provider so it doesn't
          // inherit the theme provider in the modal container
          <ThemeProvider theme={muiTheme}>
            {submitting ? (
              <Button disabled variant="contained">
                <Stack
                  direction="row"
                  gap={2}
                  sx={{ alignItems: 'center', px: 4 }}
                >
                  Saving
                </Stack>
              </Button>
            ) : (
              <Button
                sx={{ px: 4 }}
                variant="contained"
                disabled={error}
                onClick={() => handleSubmit()}
                color="primary"
              >
                SAVE
              </Button>
            )}
          </ThemeProvider>
        }
      >
        <ThemeProvider theme={muiTheme}>
          <Box
            minWidth={'min(400px, 90dvw)'}
            width={400}
            maxWidth={'90dvw'}
            minHeight={330}
            maxHeight={'60dvh'}
          >
            <Conditional c={error || isError}>
              <Alert severity="error">
                An error has occurred, please try again later.
              </Alert>
            </Conditional>
            {isLoading ? (
              <LoadingListSkeleton />
            ) : (
              <Box>
                <ListItem sx={{ mb: 1 }}>
                  <Conditional c={hasData}>
                    <Search
                      filter={filter}
                      setFilter={setFilter}
                      noSearchResults={noSearchResults}
                    />
                  </Conditional>
                </ListItem>
                <Box
                  sx={{
                    maxHeight: '50dvh',
                    overflowY: 'auto', // Enable vertical scrolling
                  }}
                >
                  <List sx={{ overflowY: 'auto' }}>
                    <Conditional c={!isLoading && filteredUsers}>
                      {filteredUsers.map((user) => {
                        return (
                          <>
                            <UserListItem
                              key={'invite-user-list:' + user.full_name}
                              name={user.full_name}
                              avatarImg={user.ui_image_location}
                              actionButton={ACTION_BUTTON[user.invite_status]({
                                clickedUser: user,
                                setUsers,
                              })}
                            />
                          </>
                        );
                      })}
                    </Conditional>
                  </List>
                </Box>
              </Box>
            )}
          </Box>
        </ThemeProvider>
      </HubModal>
    </StylesProvider>
  );
};

/**
 * Skeleton to show when the data is loading
 * @returns
 */
const LoadingListSkeleton = () => {
  const skeletonCount = 4;
  return (
    <>
      <ListItem>
        {/* Search skeleton */}
        <Skeleton width="100%" height={60}></Skeleton>
      </ListItem>
      {Array.from({ length: skeletonCount }).map(() => {
        return <UserListItemSkeleton />;
      })}
    </>
  );
};

/**
 * Adds a user to staged invite list
 * @param {*} param0
 * @returns
 */
const InviteButton = ({ clickedUser, setUsers }) => {
  return (
    // <Tooltip title="Invite User" placement="left-start">
    <Button
      variant="contained"
      sx={{ height: 'auto !important' }}
      onClick={() => {
        setUsers((prevUsers) => [
          ...prevUsers.map((user) => {
            return user.friend_rid === clickedUser.friend_rid
              ? _.assign({}, user, { invite_status: INVITE_STATUS.ACCEPTED })
              : user;
          }),
        ]);
      }}
    >
      INVITE
    </Button>
    // </Tooltip>
  );
};

const Conditional = ({ c, children }) => {
  if (!c) return;
  return children;
};

/**
 * Adds user to staged delete list
 * @param {*} param0
 * @returns
 */
const RemoveButton = ({ clickedUser, setUsers }) => {
  return (
    // <Tooltip title="Remove User" placement="left-start">
    <Button
      variant="outlined"
      sx={{ height: 'auto' }}
      onClick={() => {
        setUsers((prevUsers) => [
          ...prevUsers.map((user) => {
            return user.friend_rid === clickedUser.friend_rid
              ? _.assign({}, user, {
                  invite_status: INVITE_STATUS.NOT_INVITED,
                })
              : user;
          }),
        ]);
      }}
    >
      REMOVE
    </Button>
    // </Tooltip>
  );
};

/**
 * Search field for users
 * @param {*} props
 * @returns
 */
function Search(props) {
  return (
    <Stack direction="column" width={1}>
      <TextField
        onChange={(e) => props.setFilter(e.target.value)}
        fullWidth
        value={props.filter}
        variant="outlined"
        size="small"
        label="Search friends"
        InputProps={{
          endAdornment: props.filter.length ? (
            <InputAdornment position="end">
              <IconButton onClick={() => props.setFilter('')}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            ''
          ),
        }}
      />
      {props.noSearchResults && (
        <Typography
          sx={{
            mt: 2,
          }}
        >
          No results...
        </Typography>
      )}
    </Stack>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InviteFriendsToAdventure));
