import { openBlockModal } from "actions/modal_actions"
import { useDispatch, useSelector } from "react-redux"

const useCreateActivityModalButton = () => {

  const userInfo = useSelector((state) => state?.session?.user);
  const { itinerary_info, itineraryDetails } = useSelector((state) => state?.entities?.itinerary);

  const dispatch = useDispatch()
  const openModal = async () => {

    let openType = { isNew: true };
    let activityModalTypeRid = '2a';
    let uiUpdatePayload = {
      account_rid: userInfo.account_rid,
      adventure_rid: itinerary_info.adventure_rid,
      adventure_rid_exist_flag: null,
      inspiration_rid: null,
      itinerary_item_rid: null,
      parent_block_rid:
        null,
      explorer_rid: userInfo.explorer_rid,
      itineraryInfo: itinerary_info,
    };
    let blockPayload = {
      adventure_rid: itinerary_info.adventure_rid,
      parent_block_rid: null,
      itineraryDetails: itineraryDetails,
    };
    dispatch(openBlockModal(openType, activityModalTypeRid, uiUpdatePayload, blockPayload, userInfo));
    return;
  }

  return { openModal }

}



export default useCreateActivityModalButton
