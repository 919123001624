import React from 'react';
import { makeStyles } from '@material-ui/core';
import { styleValues } from '../constants';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
  textShadowFont: {
    textShadow: '-1px -1px 0px #2196f3',
    color: '#D9D9D9',
  },
}));

const ImageCard = ({ image, onClick }) => {
  const classes = useStyles();
  return (
    <>
      <img
        src={image}
        width="100%"
        style={{ objectFit: 'cover', cursor: 'zoom-in' }}
        onClick={onClick}
        loading="lazy"
      />
    </>
  );
};

export default ImageCard;
