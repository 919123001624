import merge from 'lodash/merge';
import {
  RECEIVE_ADVENTURE,
  RECEIVE_ADVENTURES,
  REMOVE_ADVENTURE,
  RECEIVE_ADVENTURE_DATA,
  RECEIVE_ADDED_ADVENTURE,
  RECEIVE_ADVENTURE_INFORMATIONS,
  RECEIVE_ADDED_BLOCK,
  RECEIVE_LAZY_ADVENTURES,
  RECEIVE_COPIED_ADVENTURE,
  RECEIVE_FLUSH_ADVENTURES,
  RECEIVE_FLUSH_CURRENT_ADVENTURE,
  RECEIVE_SELECTED_ADVENTURE,
  RECEIVE_USER_ITINERARY,
} from '../../constants';

const AdventuresReducer = (state = { adventureData: [] }, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_ADVENTURE_DATA:
      if (newState['user_adventures']) {
        let previousAdventures = newState['user_adventures'];
        previousAdventures = [
          ...previousAdventures,
          ...action.data.userAdventures,
        ];
        newState['user_adventures'] = previousAdventures;
      } else {
        newState['user_adventures'] = action.data.userAdventures;
      }
      return newState;
    case RECEIVE_LAZY_ADVENTURES:
      if (newState['allAdventures']) {
        let previousAdventures = newState['allAdventures'];
        previousAdventures = [
          ...previousAdventures,
          ...action.data.allAdventures,
        ];
        newState['allAdventures'] = previousAdventures;
      } else {
        newState['allAdventures'] = action.data.allAdventures;
      }
      return newState;
    // handles updating and inserting new adventure
    case RECEIVE_ADDED_ADVENTURE:
      const newAdventureData = action.data
      // in some cases like reloading the page user_adventures won't exist (TODO: Set default redux store values)
      if (!newState['user_adventures']) return newState 
      const existing_adventure_idx = newState['user_adventures'].findIndex((adventure) => adventure.adventure_rid === newAdventureData.adventure_rid);
      if (existing_adventure_idx !== -1) {
        //adventure is already cached, update it with new values
        newState['user_adventures'][existing_adventure_idx] = newAdventureData;
      } else {
        //adventure is not cached, append it to cache
        newState['user_adventures'].push(action.data);
      }
      return newState;
    case RECEIVE_USER_ITINERARY:
      if (newState.current_adventure) {
        if (newState.current_adventure.adventure_itineraries) {
          newState.current_adventure.adventure_itineraries = action.data;
        }
      }
      return newState;
    case RECEIVE_ADVENTURE:
      break;
    case RECEIVE_ADDED_BLOCK:
      if (newState.current_adventure) {
        if (newState.current_adventure.adventure_blocks) {
          newState.current_adventure.adventure_blocks = merge(
            [],
            action.data.userBlocks,
          );
        } else {
          newState.current_adventure['adventure_blocks'] = merge(
            [],
            action.data.userBlocks,
          );
        }
      }

      return newState;
    case RECEIVE_ADVENTURES:
      let resources = action.data;
      newState.entities.adventureData = resources;
      return newState;
    case REMOVE_ADVENTURE:
      break;
    case RECEIVE_ADVENTURE_INFORMATIONS:
      newState['current_adventure'] = {};
      newState.current_adventure['adventure_blocks'] =
        action.data[0].data.userAdventureBlocks;
      newState.current_adventure['adventure_itineraries'] =
        action.data[1].data.adventureItineraries;
      return newState;

    case RECEIVE_COPIED_ADVENTURE:
      newState['copied_adventure'] = action.data.copiedAdventure;
      return newState;
    case RECEIVE_SELECTED_ADVENTURE:
      newState['selectedAdventure'] = action.data;
      return newState;
    case RECEIVE_FLUSH_CURRENT_ADVENTURE:
      newState['current_adventure'] = {}
      return newState;
    case RECEIVE_FLUSH_ADVENTURES:
      newState['user_adventures'] = null;
      return newState;
    default:
      return state;
  }
};
export default AdventuresReducer;
