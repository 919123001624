export const svgOffset = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="62.594"
      viewBox="0 0 45 62.594"
    >
      <g id="Layer_1" transform="translate(0)">
        <path
          className={className}
          id="Path_330"
          data-name="Path 330"
          d="M22.563,0c-.022,0-.047.021-.07.027S22.457,0,22.438,0V.05c-.864.557-1.931,7.74-3.625,11.06-3.4,6.665-14.168,9.655-13.94,9.832.247.671,7.9,2.1,12.981.5C15.608,26.179,1.765,31.793,1.868,32.281c.241,1.143,9.973.451,15.69-2.6C14,35.687,1.563,43.011,1.741,43.3c.643,1.055,9.992.051,16.2-4.538C12.079,46.338-.264,51.879,0,52.249c.748,1.033,12,1.386,19.115-4.372-.012,4.829.109,9.657-1.837,14.487a40.335,40.335,0,0,1,5.156.216v.013c.029,0,.057,0,.087-.006h.038v-.006a40.428,40.428,0,0,1,5.154-.216c-1.946-4.829-1.825-9.658-1.837-14.487C32.995,53.639,44.248,53.287,45,52.253c.268-.369-12.073-5.911-17.935-13.485,6.208,4.589,15.555,5.592,16.2,4.537C43.436,43.014,31,35.691,27.442,29.68c5.717,3.056,15.449,3.748,15.69,2.6.1-.488-13.74-6.1-15.986-10.843,5.076,1.6,12.734.175,12.981-.5.227-.176-10.537-3.167-13.938-9.831C24.494,7.793,23.427.611,22.563.054Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};