import { SET_MODAL_POPUP_TOGGLE, SET_ACTIVE_MAP_LAYERS, SET_DETAIL_POPUP_TOGGLE } from '../../constants';

import { merge } from 'lodash';
const defaultState = {
  displayMapPopups: false,
  activeMapLayers: [],
};

export default function map(state = defaultState, action) {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case SET_DETAIL_POPUP_TOGGLE:
      return {
        ...newState,
        displayMapDetailPopups: action.data,
      }
    case SET_MODAL_POPUP_TOGGLE:
      return {
        ...newState,
        displayMapPopups: action.data,
      };
    case SET_ACTIVE_MAP_LAYERS:
      return {
        ...newState,
        activeMapLayers: action.data,
      };
    default:
      return state;
  }
}
