export function transportation (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M408.55,263.864c0-84.066-68.43-152.459-152.542-152.459c-84.122,0-152.56,68.393-152.56,152.459   c0,84.047,68.438,152.424,152.56,152.424C340.12,416.288,408.55,347.911,408.55,263.864z M121.757,272.864h36.698   c4.971,0,9-4.029,9-9s-4.029-9-9-9h-36.698c4.453-66.986,58.214-120.705,125.251-125.151v36.667c0,4.971,4.029,9,9,9s9-4.029,9-9   v-36.667c67.027,4.446,120.781,58.165,125.233,125.151h-36.698c-4.971,0-9,4.029-9,9s4.029,9,9,9h36.698   c-4.453,66.967-58.206,120.671-125.233,125.116v-36.666c0-4.971-4.029-9-9-9s-9,4.029-9,9v36.666   C179.972,393.535,126.211,339.832,121.757,272.864z"
        ></path>
        <path
          className={className}
          d="M272.193,288.354l26.018-21.434c0.031-0.025,0.057-0.056,0.087-0.081c0.148-0.126,0.285-0.262,0.424-0.396   c0.126-0.121,0.254-0.238,0.371-0.365c0.128-0.138,0.242-0.284,0.36-0.43c0.112-0.138,0.228-0.271,0.331-0.415   c0.107-0.149,0.202-0.307,0.3-0.463c0.096-0.15,0.194-0.298,0.28-0.453c0.089-0.162,0.164-0.33,0.243-0.497   c0.076-0.161,0.156-0.318,0.223-0.483c0.068-0.17,0.123-0.347,0.181-0.521c0.058-0.17,0.118-0.339,0.165-0.513   c0.047-0.175,0.078-0.354,0.115-0.532c0.037-0.182,0.078-0.361,0.104-0.545c0.006-0.04,0.018-0.078,0.022-0.118l8.496-66.886   c0.466-3.662-1.352-7.236-4.585-9.017c-3.232-1.782-7.227-1.407-10.071,0.941l-52.028,42.904c-0.03,0.025-0.056,0.054-0.085,0.08   c-0.146,0.124-0.282,0.259-0.419,0.392c-0.127,0.122-0.257,0.241-0.376,0.37c-0.125,0.135-0.237,0.28-0.354,0.422   c-0.114,0.14-0.232,0.277-0.338,0.423s-0.197,0.3-0.293,0.453c-0.098,0.154-0.199,0.305-0.287,0.464   c-0.086,0.157-0.159,0.321-0.236,0.483c-0.079,0.166-0.162,0.328-0.23,0.498c-0.066,0.165-0.118,0.335-0.175,0.504   c-0.059,0.176-0.123,0.351-0.17,0.531c-0.045,0.168-0.076,0.341-0.111,0.512c-0.039,0.188-0.081,0.375-0.107,0.566   c-0.005,0.039-0.017,0.076-0.022,0.115l-8.498,66.884c-0.465,3.662,1.353,7.236,4.585,9.018c1.36,0.749,2.854,1.117,4.342,1.117   c2.047,0,4.082-0.698,5.73-2.059L272.193,288.354z M252.246,281.479l3.964-31.201l20.313,11.188L252.246,281.479z M285.229,245.701   l-20.319-11.191l24.285-20.021L285.229,245.701z"
        ></path>
        <path
          className={className}
          d="M439.997,430.458H335.613c62.137-29.771,105.148-93.256,105.148-166.594c0-84.181-56.668-155.38-133.884-177.511V26.324   c0-4.971-4.029-9-9-9h-83.759c-4.971,0-9,4.029-9,9v59.975c-29.849,8.495-57.207,24.475-79.759,47.008   c-34.903,34.874-54.125,81.24-54.125,130.557c0,73.338,43.016,136.822,105.159,166.594H71.999c-4.971,0-9,4.029-9,9s4.029,9,9,9   h181.084c0.974,0.016,1.947,0.037,2.924,0.037s1.95-0.021,2.924-0.037h181.065c4.971,0,9-4.029,9-9   S444.968,430.458,439.997,430.458z M223.119,35.324h65.759v46.834c-0.049-0.009-0.099-0.016-0.147-0.025   c-0.349-0.063-0.7-0.115-1.05-0.176c-1.027-0.177-2.056-0.353-3.085-0.512c-0.935-0.146-1.873-0.275-2.813-0.407   c-0.448-0.063-0.896-0.129-1.345-0.189c-1.051-0.139-2.105-0.265-3.162-0.386c-0.339-0.039-0.677-0.079-1.017-0.116   c-1.105-0.121-2.215-0.23-3.327-0.332c-0.282-0.026-0.564-0.051-0.848-0.076c-1.15-0.1-2.304-0.189-3.46-0.268   c-0.225-0.015-0.449-0.028-0.674-0.042c-1.212-0.078-2.427-0.147-3.646-0.201c-0.084-0.004-0.169-0.006-0.253-0.01   c-2.669-0.115-5.349-0.186-8.045-0.186c-11.147,0-22.141,0.989-32.889,2.908V35.324z M89.236,263.864   c0-44.506,17.347-86.351,48.847-117.824c21.853-21.834,48.703-36.855,77.97-44.014c0.062-0.014,0.125-0.019,0.188-0.034   c22.725-5.51,46.495-6.214,69.507-2.103c0.56,0.101,1.115,0.214,1.673,0.321c0.821,0.155,1.642,0.313,2.461,0.48   c1.203,0.249,2.401,0.509,3.596,0.784c0.071,0.016,0.143,0.032,0.214,0.049c73.859,17.113,129.071,83.404,129.071,162.34   c0,90.903-73.226,165.026-163.828,166.594h-5.852C162.469,428.891,89.236,354.768,89.236,263.864z"
        ></path>
        <path
          className={className}
          d="M360.881,476.994H151.117c-4.971,0-9,4.029-9,9s4.029,9,9,9h209.764c4.971,0,9-4.029,9-9S365.852,476.994,360.881,476.994z   "
        ></path>
      </g>
    </svg>
  );
}