export const svgEye = (color, className, outerLayer, darkblue, lightblue) => {
  return (
    <svg
      className={outerLayer}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="18.257"
      viewBox="0 0 32 18.257"
    >
      <g id="Group_532" data-name="Group 532">
        <path
          className={className}
          id="Path_357"
          data-name="Path 357"
          d="M30.114,5.356h0C26.846,2.9,21.794,0,16,0S5.154,2.9,1.886,5.356A4.583,4.583,0,0,0,0,9.128,4.583,4.583,0,0,0,1.886,12.9C5.12,15.326,10.206,18.257,16,18.257s10.846-2.9,14.114-5.356A4.675,4.675,0,0,0,32,9.128,4.583,4.583,0,0,0,30.114,5.356ZM16,15.6a6.467,6.467,0,1,1,6.467-6.467A6.469,6.469,0,0,1,16,15.6Z"
          fill={color}
        />
        <path
          className={className}
          id="Path_358"
          data-name="Path 358"
          d="M43.584,19.142A4.042,4.042,0,1,1,39.542,15.1,4.042,4.042,0,0,1,43.584,19.142Z"
          transform="translate(-23.542 -10.014)"
          fill={color}
        />
      </g>
    </svg>
  );
};