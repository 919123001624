import React, { useRef, useEffect } from 'react';
const VideoCard = ({ video, onClick, autoPlay, muted }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold value as needed
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && autoPlay) {
          // Video is in view, autoplay
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [autoPlay]);

  return (
    <>
      <video
        ref={videoRef}
        className="video-card"
        width="100%"
        style={{ objectFit: 'cover', cursor: 'zoom-in' }}
        onClick={onClick}
        preload="none"
        controls
        muted = {muted}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default VideoCard;
