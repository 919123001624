import styled from 'styled-components';

export const UserBarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({leftaligned}) => leftaligned || 'center'};
  align-items: center;
  & > div {
    margin: ${({ spacing }) => (spacing ? spacing * 5 : 5)}px;
  }
`;

export const UserNameBar = styled.div`
  letter-spacing: 2px;
  flex: 1;
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ size }) =>
    size === 'small' ? 12 : size === 'large' ? 16 : 14}px;
  line-height: 13px;
  /* identical to box height */

  text-align: right;

  color: #273a07;
`;

export const UserAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #273a07;
  background-color: #eee;
  margin-left: 4px;
`;
