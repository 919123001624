const muiLinkStyles = {
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body1,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
          color: theme.palette.primary.main,
        },
        cursor: "pointer",
      }),
    },
    variants: [
      {
        props: { variant: "contrast" },
        style: ({ theme }) => ({
          color: theme.palette.text.contrast,
          "&:hover": {
            color: theme.palette.text.contrast,
            visibility: "visible",
          },
          "&:focus": {
            color: theme.palette.text.contrast, // Replace with desired focus color
          },
        }),
      },
      {
        props: { variant: "primary" },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          "&:hover": {
            color: theme.palette.text.primary,
            textDecorationColor: theme.palette.text.primary,
            visibility: "visible",
          },
          "&:focus": {
            color: theme.palette.text.primary, // Replace with desired focus color
          },
        }),
      },
    ],
  },
};

export default muiLinkStyles;
