import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Button } from '../../modal_modules';
import { closeModal } from 'actions/modal_actions';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import HubModal from '../../modal';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  updateUserInfo,
  updateAccountDetails,
  fetchAccountExplorer,
  fetchAccountDetails,
} from 'actions/user_actions.js';
import { updateSessionToken } from 'actions/session_actions';
import Dropzone from 'react-dropzone';
import { svgDelete, svgEdit, svgCamera, svgSwap } from 'components/svg_module';
import axios from 'axios';
import { merge } from 'lodash';

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountDetails: (data) => dispatch(updateAccountDetails(data)),
    updateUserInfo: (data) => dispatch(updateUserInfo(data)),
    updateSessionToken: (data) => dispatch(updateSessionToken(data)),
    fetchAccountExplorer: (accountRid, explorerRid) =>
      dispatch(fetchAccountExplorer(accountRid, explorerRid)),
    fetchAccountDetails: (accountRid) =>
      dispatch(fetchAccountDetails(accountRid)),
  };
};
const mapStateToProps = ({ entities, session, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let accountDetails = entities?.users?.accountDetails;
  let originType = ui?.modal?.imageCropperOrigin;
  return { userInfo, accountDetails, originType };
};
const EditProfileImage = ({
  closeModal,
  userInfo,
  accountDetails,
  originType,
  updateAccountDetails,
  updateUserInfo,
  updateSessionToken,
  fetchAccountExplorer,
  fetchAccountDetails,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValues, setInputValues] = useState(
    originType.isProfile
      ? userInfo
      : originType.isAccount
        ? accountDetails
        : {},
  );
  const [editingImage, setEditingImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(<img></img>);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };
  const verifyFile = (file) => {
    //filters file input
    const maxImgSize = 10000000000;
    const acceptedFileTypes =
      'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
    const acceptedFileTypesArr = acceptedFileTypes.split(',').map((item) => {
      return item.trim();
    });
    if (file && file.length > 0) {
      const currentFile = file[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > maxImgSize) {
        alert('File too big');
        return false;
      }
      // if (!acceptedFileTypesArr.includes(currentFileType)) {
      //   alert("File type not allowed");
      //   return false;
      // }
    }
    return true;
  };
  const handleOnDrop = (files, rejectedFiles) => {
    //handles image drop
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      const isVerified = verifyFile(files);

      if (isVerified) {
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.onloadend = () => {
          let fileObject = {
            file: myFileItemReader.result,
            file_name: currentFile.path,
          };
          // setInputValues({ ...inputValues, ui_image_location: fileObject });
          setEditingImage(fileObject);
          setIsEditing(true);
        };
        let file_name = myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };
  const renderDropZone = () => {
    const maxImgSize = 10000000000;
    return (
      <div className="mod-drpzn-padder">
        <Dropzone onDrop={handleOnDrop} maxSize={maxImgSize} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mod-drpzn">
                  <div className="mod-drpzn-inner">
                    <div className="mod-profile-edit-text-wrapper">
                      <span>Click to browse or drop images here.</span>
                    </div>
                  </div>
                  <div className="mod-drpzn-cam-wrapper">
                    {svgCamera(
                      'color',
                      'mod-drpzn-svg-cam',
                      'mod-drpzn-svg-cam-outerlayer',
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  const editHandler = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { ui_image_location } = inputValues;

    setInputValues({ ...inputValues, ui_image_location: '' });
  });
  const handleSubmit = useCallback((e) => {
    if (editingImage) {
      const formData = new FormData();
      const image = editingImage;
      image.file = croppedImage;
      if (!image.file.includes('blob')) {
        let typeName = image.file.slice(5, 15);
        if (typeName.includes('png') || typeName.includes('jpg')) {
          typeName = typeName.slice(0, typeName.length - 1);
        }
        const slicedData =
          typeName.includes('png') || typeName.includes('jpg')
            ? image.file.slice(22)
            : image.file.slice(23);
        const byteCharacters = atob(slicedData);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: `${typeName}` });

        blob['originalname'] = image.file_name;
        formData.set('original_name', image.file_name);
        formData.set('file', blob);
        window.formData = formData;
      } else {
        let blob3 = editingImage;
        blob3.originalname = blob3.name;
        // formData.set("file", blob);
        formData.set('file', blob3);
        window.formData = formData;
      }

      axios.post('/api/bucket/profile_image_uploads', formData).then((res) => {
        let picUrl = res.data.data;
        let data = merge({}, inputValues);
        data.ui_image_location = picUrl;
        data.account_rid = userInfo.account_rid;
        if (originType.isProfile) {
          axios.patch('/api/e/explorer-profile-pic', data).then(() => {
            updateSessionToken({ ui_image_location: picUrl });
            fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
            closeModal();
          });
        }
        if (originType.isAccount) {
          axios.patch('/api/e/account-profile-pic', data).then(() => {
            // updateSessionToken({ ui_image_location: picUrl });
            fetchAccountDetails(userInfo.account_rid);
            closeModal();
          });
        }
      });
    }
  });
  return (
    // remove 4 squares here
    <HubModal
      show={true}
      title="RESIZE IMAGE"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <Stack spacing={2}>
          <div className="image-edit-body-container">
            {isEditing && editingImage && (
              <Cropper
                src={editingImage.file}
                style={{ height: '100%', width: '100%' }}
                // Cropper.js options
                initialAspectRatio={1 / 1}
                guides={false}
                crop={onCrop}
                center="true"
                ref={cropperRef}
                dragMode="move"
                viewMode={1}
                cropBoxMovable={false}
                cropBoxResizable={false}
                minCropBoxHeight={'100px'}
                minCropBoxWidth={'100px'}
                className="cropper-container"
                responsive="true"
              />
            )}
            {!isEditing && (
              <>
                {inputValues.ui_image_location && (
                  <div className="edit-profile-image-profile-image-wrapper">
                    <div className="edit-profile-image-profile-image-container">
                      <img
                        src={inputValues.ui_image_location}
                        className="edit-profile-image-profile-image"
                        id="edit-profile-image"
                        alt="no-profile-pic"
                      />
                    </div>
                    <div className="edit-profile-image-buttons-wrapper">
                      <div
                        className="edit-profile-image-edit-button"
                        onClick={editHandler}
                      >
                        {svgSwap(
                          'color',
                          'mod-edit-profile-image-svg',
                          'mod-edit-profile-image-svg-outer',
                        )}
                      </div>
                      <div
                        onClick={() => {
                          setInputValues({
                            ...inputValues,
                            ui_image_location: '',
                          });
                        }}
                        className="edit-profile-image-delete-button"
                      >
                        {svgDelete(
                          'color',
                          'mod-edit-profile-image-svg',
                          'mod-edit-profile-image-svg-outer',
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!inputValues.ui_image_location && renderDropZone()}
              </>
            )}
          </div>
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileImage);
