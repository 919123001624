export function guitar (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 100 100" />
        <path
          fill={color}
          className={className}
          d="M64.658,30.962L51.463,44.159l4.379,4.379l13.196-13.195c-0.24-0.167-0.471-0.357-0.687-0.573l-3.121-3.121  C65.015,31.433,64.824,31.202,64.658,30.962z M73.015,20.126l-1.513-1.513l-1.414,1.414l1.378,1.379l-1.531,1.53l-1.378-1.378  l-1.414,1.414l1.378,1.378l-1.53,1.531l-1.379-1.378l-1.414,1.414l1.513,1.513c-0.47,1.044-0.285,2.311,0.571,3.167l3.122,3.121  c0.856,0.856,2.123,1.042,3.167,0.572l1.513,1.513l1.414-1.414l-1.378-1.378l1.53-1.531l1.377,1.377l1.414-1.415l-1.377-1.377  l1.531-1.53l1.377,1.377l1.414-1.414l-1.512-1.512c0.471-1.044,0.284-2.311-0.572-3.168l-3.12-3.12  C75.325,19.842,74.058,19.656,73.015,20.126z M55.842,50.66l-6.5-6.501l4.682-4.682c-4.603-3.031-9.651-3.465-13.927,0.811  c-2.301,2.302-2.199,3.287-3.938,5.025c-3.358,3.359-8.003,1.631-12.953,6.581c-6.875,6.876-5.666,16.815,1.209,23.692  c6.876,6.874,16.816,8.083,23.691,1.208c4.95-4.95,3.223-9.594,6.582-12.953c1.737-1.737,2.723-1.637,5.023-3.938  c4.275-4.276,3.844-9.324,0.813-13.925L55.842,50.66z M36.65,69.535l-1.061,1.061l-6.185-6.185l1.061-1.061L36.65,69.535z   M48.783,57.358c0,3.391-2.748,6.14-6.141,6.14c-3.391-0.001-6.141-2.75-6.141-6.141c0-3.392,2.75-6.141,6.141-6.141  C46.033,51.216,48.783,53.966,48.783,57.358z"
        ></path>
      </g>
    </svg>
  );
}