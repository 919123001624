import { openCheckListModal } from 'actions/modal_actions';
import { useDispatch, useSelector } from 'react-redux';

const useCreateListModalButton = () => {
  const userInfo = useSelector((state) => state?.session?.user);
  const { itinerary_info, itineraryDetails } = useSelector(
    (state) => state?.entities?.itinerary,
  );

  const adventure_rid = useSelector(
    (state) => state?.entities?.adventure?.selectedAdventure?.adventure_rid,
  );

  const dispatch = useDispatch();

  const openModal = async () => {
    let openType = {
      isNew: true,
    };

    let blockType = {
      isAdventureBlock: true,
      isNestedItineraryItemBlock: false,
      isLibraryBlock: false,
      isNestedLibraryBlock: false,
    };

    let actionPayload = {
      itineraryItemRid: null,
      nestedLevel: 0,
      parentBlockRid: null,
      adventureRid: adventure_rid,
      itineraryInfo: itinerary_info,
      itineraryDetails: itineraryDetails,
    };
    dispatch(
      openCheckListModal(openType, blockType, actionPayload, null, userInfo),
    );
  };

  return { openModal };
};
export default useCreateListModalButton;
