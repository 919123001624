import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { inviteNewMember } from '../../../../util/api_util/user_api_util';
import { Input, TextArea, Button, Title } from '../../modal_modules';
import * as EmailValidator from 'email-validator';
import axios from 'axios';

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = ({ entities, session }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;

  return {
    userInfo,
  };
};
const BlockFileViewer = ({
  adventureFriends,
  userFriends,
  userAccountGroups,
  closeModal,
  userInfo,
}) => {
  const [inputValues, setInputValues] = useState({
    email: '',
    explorer_rid: userInfo.explorer_rid,
  });
  const [topLabel, setTopLabel] = useState('LODESTAR');
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: '',
    emailErrors: '',
    passwordErrors: [],
  });
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      let emailValidation = EmailValidator.validate(value);
      if (emailValidation === false) {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: 'Please enter a valid email',
        });
      } else {
        setSignUpErrors({ ...signUpErrors, emailErrors: '' });
        axios
          .patch('/api/e/verify_auth_email', { email: value })
          .then((res) => {
            setSignUpErrors({
              ...signUpErrors,
              emailErrors:
                res.data.message === 'Email valid'
                  ? ''
                  : !res.data.message
                  ? ''
                  : res.data.message,
            });
          });
      }
    }
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    inviteNewMember(inputValues).then((res) => {
      if (res.status === 200) {
        closeModal();
      } else {
        window.alert(
          'There was an error sending the invite, please try again later',
        );
      }
    });
  });
  const renderEmailErrors = () => {
    if (inputValues.email.length > 0) {
      return (
        <div className="email-error-container">
          {signUpErrors.emailErrors.length === 0 && (
            <div className="email-error-wrapper">
              {/* <img alt="alert-pic" src="/symbols/bluecheck.png" />
              <span>Valid Email</span> */}
            </div>
          )}
          {signUpErrors.emailErrors?.length > 0 && (
            <div className="email-error-wrapper">
              <img alt="alert-pic" src={'/symbols/red-alert.png'} />
              <span>{signUpErrors.emailErrors}</span>
            </div>
          )}
        </div>
      );
    } else {
      // return (<div className="error-placeholder" />)
      return <div className="email-error-container" />;
    }
  };
  return (
    <div
      className="mod-type1-wrapper invite-new-modal-wrapper"
      id="cre-ad"
      style={{ height: '186px' }}
    >
      <div className="mod-type1-padder  invite-new-modal-padder">
        <Title closeModal={closeModal} title="INVITE A FRIEND" />

        <div className="mod-cnt-def invite-new-modal-content">
          <form
            onSubmit={handleSubmit}
            className="invite-new-modal-content-form"
          >
            <Input
              label="Email"
              name="email"
              onChangeHandler={handleOnChange}
              value={inputValues.email}
            />
            {renderEmailErrors()}
            <Button
              spacingBottom="20px"
              spacingTop="10px"
              text="SEND"
              disabledCondition={
                inputValues.email.length === 0 ||
                signUpErrors.emailErrors.length !== 0
              }
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(BlockFileViewer);
