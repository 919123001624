export const svgThumbsDown = (color, className, outerLayer, width, height) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 67.758, 68.752"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M58.383,0.002 C63.561,0.002 67.758,4.199 67.758,9.377 L67.758,31.252 C67.758,36.429 63.561,40.627 58.383,40.627 L51.039,40.627 L39.364,66.896 C38.862,68.024 37.743,68.752 36.508,68.752 C29.604,68.752 24.008,63.155 24.008,56.252 L24.008,46.877 L9.481,46.877 C6.73,46.908 4.105,45.729 2.3,43.654 C0.495,41.578 -0.307,38.815 0.107,36.091 L4.418,7.971 C5.119,3.348 9.118,-0.051 13.758,0.001 L49.008,0.001 C49.029,0.001 49.049,0.001 49.07,0.002 L58.383,0.002 z"
          fill={color}
        />
      </g>
    </svg>
  );
};