export const HamburgerIcon = ({ color = "#273806", className, outerLayer, width = 25, height = 19 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 25 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.7274 0.805278C5.79484 1.87046 5.79484 3.60767 4.7274 4.67276C3.65141 5.74647 1.87633 5.74647 0.800581 4.67276C-0.26686 3.60758 -0.26686 1.87037 0.800581 0.805278C1.87657 -0.268426 3.65165 -0.268426 4.7274 0.805278ZM9.36921 0.00142345H23.5916C24.3669 0.00142345 25 0.633141 25 1.40685V4.07108C25 4.84342 24.3658 5.47651 23.5916 5.47651H9.36921C8.59567 5.47651 7.96079 4.84298 7.96079 4.07108V1.40685C7.96079 0.633681 8.5945 0.00142345 9.36921 0.00142345ZM4.7274 13.6199C5.79484 14.6851 5.79484 16.4223 4.7274 17.4874C3.65141 18.5611 1.87633 18.5611 0.800581 17.4874C-0.26686 16.4222 -0.26686 14.685 0.800581 13.6199C1.87657 12.5462 3.65165 12.5462 4.7274 13.6199ZM9.25347 12.8161H23.7071C24.4188 12.8161 24.9999 13.3959 24.9999 14.1062V17.0011C24.9999 17.7114 24.4188 18.2913 23.7071 18.2913H9.25347C8.54161 18.2913 7.9606 17.7116 7.9606 17.0011V14.1062C7.9606 13.3959 8.54169 12.8161 9.25347 12.8161ZM4.7274 7.21264C5.79484 8.27782 5.79484 10.015 4.7274 11.0801C3.65141 12.1538 1.87633 12.1538 0.800581 11.0801C-0.26686 10.0149 -0.26686 8.27774 0.800581 7.21264C1.87657 6.13894 3.65165 6.13894 4.7274 7.21264ZM9.25347 6.40879H23.7071C24.4188 6.40879 24.9999 6.98864 24.9999 7.69891V10.5939C24.9999 11.3041 24.4188 11.884 23.7071 11.884H9.25347C8.54161 11.884 7.9606 11.3043 7.9606 10.5939V7.69891C7.9606 6.98867 8.54169 6.40879 9.25347 6.40879Z"
        fill={color}
      />
    </svg>
  )
};