import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import { FileAccordion } from '../../modal_modules';
import { svgCreate } from 'components/svg_module';

const Files = ({
  openBlockFileModal,
  currentBlock,
  currentBlockFiles,
  deleteBlockFile,
  transitionHandler,
  newBlock,
}) => {
  return (
    <div className="files-wrapper">
      <div
        className="mod-blk-fls-left"
        onClick={() => {
          // transitionHandler(newBlock);
          openBlockFileModal(
            false,
            newBlock ? newBlock.block_rid : currentBlock.block_rid,
          );
        }}
      >
        {/* {svgCreate('color', 'add-fls-svg', 'add-flgs-svg-outer')}
        <span className="add-block-file--button">NEW</span> */}
        <AddIcon />
      </div>
      <div className="mod-blk-fls-right">
        {/* {currentBlockFiles ? currentBlockFiles.slice(0,8).map((ele, idx) => {
            return renderFile(ele)
          }) : <div />} */}
        {currentBlockFiles ? (
          currentBlockFiles.map((ele, idx) => {
            return (
              <FileAccordion
                file={ele}
                deleteFunc={(e) => {
                  e.preventDefault();
                  deleteBlockFile(ele.block_file_rid, currentBlock.block_rid);
                }}
                editFunc={() => {
                  openBlockFileModal(true, ele);
                }}
              />
            );
          })
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default Files;
