import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  svgFriend,
  svgInvitation,
  svgLibrary2,
  svgLinkedApps,
  svgCreate,
} from 'components/svg_module';
import moment from 'moment';
const ShortBlock = ({
  type,
  displayAdd,
  addButtonHandler,
  userRecentBlockUpdates,
  userInfo,
  history,
}) => {
  let bottomContent;
  // const renderContentTitle = () => {
  if (userRecentBlockUpdates) {
  }
  switch (type) {
    case 'friends':
      // bottomContent = <span>{svgFriend('', 'dashboard-svg', 'dashboard-svg-outerlayer')} FRIENDS</span>
      bottomContent = (
        <div className="dashboard-block-bottom-comming-soon">
          <div className="custom-spacing-container">
            <div></div>
            <span className="custom-bottom-spacing">
              {' '}
              {svgFriend('', 'dashboard-svg', 'dashboard-svg-outerlayer')}{' '}
              FRIENDS
            </span>
            {displayAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/hub/friends');
                }}
                className="svg-bottom-adder-button"
              >
                {svgCreate(
                  'color',
                  'dashboard-bottom-svg',
                  'dashboard-bottom-svg-outer',
                )}
              </button>
            )}
          </div>
          {/* <span className="coming-soon-text">— COMING SOON —</span> */}
        </div>
      );
      break;
    case 'library':
      bottomContent = (
        <span>
          {svgLibrary2('', 'dashboard-svg', 'dashboard-svg-outerlayer')} LIBRARY
        </span>
      );
      break;
    default:
      bottomContent = null;
      break;
  }

  const renderTopContent = () => {
    switch (type) {
      case 'library':
        return (
          userInfo &&
          userRecentBlockUpdates && (
            <div className="dashboard-library-wrapper">
              {/* <div className="dashboard-library-block-contents-top">
                <span>New Contents</span>
              </div> */}
              <div className="dashboard-library-block-contents-middle">
                <span>{userRecentBlockUpdates[0].block_name}</span>
              </div>
              <div className="dashboard-library-block-contents-bottom">
                <span>
                  {/* {userRecentBlockUpdates[0].recent_action} by{" "} */}
                  {userInfo.first_name} on{' '}
                  {moment(userRecentBlockUpdates[0].recent_date).format(
                    'DD-MMM-YYYY',
                  )}
                </span>
              </div>
            </div>
          )
        );
      case 'friends':
        return (
          <div className="dashboard-friends-wrapper ">
            <div>
              <span>
                {svgInvitation('', 'dashboard-svg', 'dashboard-svg-outerlayer')}
                2 new invitations
              </span>
            </div>
            <div>
              <span>
                {svgFriend('', 'dashboard-svg', 'dashboard-svg-outerlayer')}3
                unread messages
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div
      className="short-block-wrapper"
      onClick={
        type === 'library'
          ? () => {
              history.push('/hub/library');
            }
          : null
      }
      style={
        type === 'library'
          ? {
              cusor: 'pointer',
            }
          : {
              cursor: 'default',
            }
      }
    >
      <div className="dashboard-short-block-top">{renderTopContent()}</div>
      <div className="dashboard-short-block-bottom">{bottomContent}</div>
    </div>
  );
};

export default withRouter(ShortBlock);
