import axios from 'axios';

export const gettingUrl = async (file_name, type) => {
  return axios.post(`/api/bucket/generate-upload-url/${file_name}`, {
    type: type
  });
}

export const handleFileUpload = async (file, setCurProgress) => {
    const { originalname } = file;
    const bucketName = 'lodestar-vpc-host-nonprod.appspot.com';
    const folderPath = 'app_media/app_trip_image/'; // Specify the desired folder path
    const fileName = `${folderPath}${originalname.replace(/ /g, "_")}`; // Include the folder path in the file name

    const formData = new FormData();
    formData.append('file', file);

    try {
      return gettingUrl(originalname, file.type).then(res=> {
        return axios.put(res.data.data.url, file, {
          headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*', // Replace with your website's URL
            'Origin': '*', // Replace with your website's URL
          },
          crossDomain: true,
          onUploadProgress: (progressEvent) => {
            setCurProgress(progressEvent.loaded);
          },
        }).then(res => {return `https://storage.googleapis.com/${bucketName}/${fileName}`;});
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
}
