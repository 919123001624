
export const svgAccountMenuPassword = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="28"
      height="15.57"
      viewBox="0, 0, 28, 15.57"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M27.28,-0 L0.72,-0 C0.32,-0 0,0.32 0,0.72 L0,10.77 C0,11.17 0.32,11.49 0.72,11.49 L27.28,11.49 C27.68,11.49 28,11.17 28,10.77 L28,0.72 C28,0.32 27.68,-0 27.28,-0 z M8.81,6.85 L8.03,8.2 L6.41,6.97 L6.62,8.62 L5.18,8.62 L5.39,6.97 L3.77,8.2 L2.99,6.85 L4.85,6.07 L2.99,5.28 L3.77,3.93 L5.38,5.15 L5.18,3.58 L6.62,3.58 L6.42,5.15 L8.03,3.93 L8.81,5.28 L6.95,6.06 L8.81,6.85 z M16.91,6.85 L16.13,8.2 L14.51,6.97 L14.72,8.62 L13.28,8.62 L13.49,6.97 L11.87,8.2 L11.09,6.85 L12.95,6.07 L11.09,5.29 L11.87,3.94 L13.48,5.16 L13.28,3.59 L14.72,3.59 L14.52,5.16 L16.13,3.94 L16.91,5.29 L15.05,6.07 L16.91,6.85 z M25.01,6.85 L24.23,8.2 L22.61,6.97 L22.82,8.62 L21.38,8.62 L21.59,6.97 L19.97,8.2 L19.19,6.85 L21.05,6.07 L19.19,5.29 L19.97,3.94 L21.58,5.16 L21.38,3.59 L22.82,3.59 L22.62,5.16 L24.23,3.94 L25.01,5.29 L23.15,6.07 L25.01,6.85 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M26.38,13.41 L1.62,13.41 C1.03,13.41 0.54,13.89 0.54,14.49 C0.54,15.08 1.02,15.57 1.62,15.57 L26.39,15.57 C26.98,15.57 27.47,15.09 27.47,14.49 C27.46,13.89 26.98,13.41 26.38,13.41 z"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
};