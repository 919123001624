import React, { useRef, useEffect } from 'react';
const VideoCard = ({ video, onClick, autoPlay, firstRenderFlag }) => {
  const videoRef = useRef(null);

  // Long-Time Touch event.
  let touchTimer = null;

  const handleTouchStart = () => {
    touchTimer = setTimeout(() => {
      onClick();
    }, 1000); // Adjust the duration as needed
  };

  const handleTouchMove = () => {
    clearTimeout(touchTimer);
    touchTimer = null;
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimer);
    touchTimer = null;
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold value as needed
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && autoPlay) {
          // Video is in view, autoplay

          if (!firstRenderFlag) {
            //videoRef.current.play();
          }
        } else {
          //videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [autoPlay]);

  return (
    <video
      ref={videoRef}
      className="video-card"
      width="100%"
      style={{ objectFit: 'cover', cursor: 'zoom-in' }}
      controls
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      disablePictureInPicture
      controlsList="nodownload nofullscreen noremoteplayback"
      playsInline
      //autoPlay
    >
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoCard;
