export function equipment (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      data-name="Layer 1"
      viewBox="0 0 96 96"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <path
        className={className}
        d="M22.30078,94.02051A19.8958,19.8958,0,0,1,8.08594,87.91406,19.93691,19.93691,0,0,1,2.04883,72.56934c.31738-5.59375,3.03076-11.21387,7.64111-15.82325a67.12779,67.12779,0,0,1,15.05762-10.916l.0083-.00391a39.05493,39.05493,0,0,0,4.333-2.38086l1.35351-.85937L53.40381,65.54688l-.84033,1.34863a45.43333,45.43333,0,0,0-2.396,4.36328A67.15168,67.15168,0,0,1,39.25439,86.30957C34.13281,91.43164,28.11865,94.02051,22.30078,94.02051Zm4.16016-44.57617A63.41808,63.41808,0,0,0,12.51807,59.57422C8.60352,63.48828,6.3042,68.18457,6.04248,72.7959a15.78039,15.78039,0,0,0,4.87158,12.29c7.03907,7.03906,17.52979,6.3789,25.51221-1.60449A63.40917,63.40917,0,0,0,46.55762,69.53613c.5498-1.14843,1.14941-2.28222,1.79-3.38867L29.85889,47.65918C28.75488,48.30371,27.61719,48.90137,26.46094,49.44434Z"
      ></path>
      <path
        className={className}
        d="M65.62695,53.48242,42.51611,30.37207l.80909-1.33984c.68359-1.13282,1.32421-2.30957,1.9038-3.5,2.44727-5.11621,7.88526-12.21094,11.51661-15.8418C66.39307.041,79.21045-.61914,87.91406,8.08594a19.93691,19.93691,0,0,1,6.03711,15.34472c-.31738,5.59375-3.03076,11.21387-7.64111,15.82325-3.6294,3.62988-10.72315,9.06738-15.85449,11.52343-1.17725.57325-2.355,1.21387-3.48828,1.89746ZM47.54,29.73926,66.26074,48.46c.80713-.45215,1.62891-.88183,2.45606-1.28418a65.45728,65.45728,0,0,0,14.76513-10.75c3.91407-3.91406,6.21387-8.61035,6.47559-13.22168a15.78039,15.78039,0,0,0-4.87158-12.29C78.04785,3.877,67.55713,4.53613,59.57373,12.51855A65.39577,65.39577,0,0,0,48.83105,27.27148C48.42432,28.10645,47.99268,28.93164,47.54,29.73926Z"
      ></path>
      <path
        className={className}
        d="M22.35352,81.89355A7.95265,7.95265,0,0,1,16.6416,79.3584c-3.89844-3.89746-3.29883-9.15235,1.60352-14.05762a54.773,54.773,0,0,1,11.70068-8.541,55.14521,55.14521,0,0,0,7.80762-4.52832l1.374-.96,5.59522,5.59473-.94873,1.37207a56.74812,56.74812,0,0,0-4.53467,7.81934,54.75549,54.75549,0,0,1-8.54053,11.69629,15.13319,15.13319,0,0,1-5.46582,3.62793A8.75623,8.75623,0,0,1,22.35352,81.89355Zm16.29834-25.4414a59.90475,59.90475,0,0,1-6.98731,3.91894l-.00732.00391a51.54056,51.54056,0,0,0-10.5835,7.75391c-2.28125,2.2832-4.41211,5.59375-1.604,8.40136a3.9378,3.9378,0,0,0,4.45068,1.07325,11.41772,11.41772,0,0,0,3.94971-2.67774,51.5348,51.5348,0,0,0,7.75537-10.583,60.742,60.742,0,0,1,3.92578-6.99121Z"
      ></path>
      <path
        className={className}
        d="M56.94287,44.79883l-5.74219-5.74219.93164-1.36816a54.17182,54.17182,0,0,0,3.99122-6.89942,57.9639,57.9639,0,0,1,9.17773-12.543,15.13319,15.13319,0,0,1,5.46582-3.62793A7.853,7.853,0,0,1,79.3584,16.6416h0c3.89844,3.89746,3.29883,9.15235-1.60352,14.05762A57.974,57.974,0,0,1,65.207,39.87793a54.2529,54.2529,0,0,0-6.89551,3.98926Zm-.58887-6.2461,1.09278,1.09278a57.92721,57.92721,0,0,1,6.0249-3.3711,54.95133,54.95133,0,0,0,11.45459-8.40332c2.28125-2.2832,4.41211-5.59375,1.604-8.40136h0a3.94177,3.94177,0,0,0-4.45068-1.07325,11.41772,11.41772,0,0,0-3.94971,2.67774,54.91927,54.91927,0,0,0-8.40283,11.45117A57.8644,57.8644,0,0,1,56.354,38.55273Z"
      ></path>
      <path
        className={className}
        d="M72.59717,59.625a1.99265,1.99265,0,0,1-1.41406-.58594L36.96094,24.81641a1.99979,1.99979,0,0,1,2.82812-2.82813L74.01123,56.21094A2,2,0,0,1,72.59717,59.625Z"
      ></path>
      <path
        className={className}
        d="M57.625,74.59766a1.99381,1.99381,0,0,1-1.41455-.58594L21.98877,39.78906a1.99978,1.99978,0,0,1,2.82812-2.82812L59.03955,71.18359A2.00025,2.00025,0,0,1,57.625,74.59766Z"
      ></path>
      <path
        className={className}
        d="M62.625,69.59766a1.99381,1.99381,0,0,1-1.41455-.58594L26.98877,34.78906a1.99978,1.99978,0,0,1,2.82812-2.82812L64.03955,66.18359A2.00025,2.00025,0,0,1,62.625,69.59766Z"
      ></path>
      <path
        className={className}
        d="M67.125,65.09766a1.99381,1.99381,0,0,1-1.41455-.58594L31.48877,30.28906a1.99978,1.99978,0,0,1,2.82812-2.82812L68.53955,61.68359A2.00025,2.00025,0,0,1,67.125,65.09766Z"
      ></path>
    </svg>
  );
}