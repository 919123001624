import { orange } from '@mui/material/colors';

const lightPalette = {
  primary: { main: '#DD603F' },
  secondary: orange,
  background: {
    default: '#ffffff', // White background
    primary: '#ffffff', // White background
    secondary: '#fcedd6',
    dark: '#273a07', // green background
    paper: '#faf7f1', // beige background for paper-like elements
  },
  text: {
    primary: '#273a07', // Dark Green text color
    secondary: '#526138', // Medium text color
    light: '#827700',
    disabled: '#999999', // Light text color for disabled elements
    contrast: '#ffffff', // Light text color for dark background
  },
};

export default lightPalette;
