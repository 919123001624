export function svgCarabiner (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 100 100" />
        <g>
          <path
            fill={color}
            className={className}
            d="M77.595,47.115c-0.995-0.757-1.991-1.515-2.983-2.275c-0.554-0.429-1.111-0.848-1.665-1.282   c-0.585-0.458-1.16-1.02-1.81-0.395c-0.405,0.387-0.715,0.852-1.043,1.294c-0.594,0.799-1.191,1.598-1.758,2.418   c-0.39,0.551-0.892,1.139-1.128,1.777c-0.261,0.717-1.138,1.73-1.982,2.319l7.791,5.756c0.018-0.15,0.04-0.3,0.062-0.428   c0.148-1.062,1.273-2.887,1.823-3.491c0.542-0.611,1.652-1.307,2.211-1.567c0.559-0.251,1.341-1.363,1.569-2.041   c0.232-0.672,0.044-1.191-0.46-1.603C78.023,47.437,77.806,47.277,77.595,47.115z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M74.526,58.923l-11.48-8.474l-0.79,0.27l-1.719,2.362c-0.383,0.021-0.771,0.193-1.128,0.676   c-0.42,0.572-8.053,10.797-8.628,11.834c-0.277,0.499-0.296,0.852-0.185,1.164l-2.03,2.792l0.592,1.437l10.189,7.532l1.495,0.108   l1.935-2.677c0.483,0.074,0.909-0.07,1.168-0.49c0.406-0.682,7.756-10.312,8.531-11.448c0.55-0.804,0.59-1.461,0.422-1.979   l1.691-2.331L74.526,58.923z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M84.614,32.792c-0.921-5.413-4.355-11.587-10.475-16.675c-6.217-5.178-15.932-9.702-23.906-10.863   c-7.978-1.153-21.327,0.978-25.965,19.823c-4.641,18.849-9.215,43.813-9.135,50.457c0.083,6.646,3.125,13.821,11.513,17.782   c0.179,0.083,0.375,0.148,0.554,0.225c1.466,0.591,2.955,1.096,4.521,1.315c1.427,0.191,2.877,0.175,4.313,0.045   c1.288-0.114,2.558-0.352,3.795-0.733c1.06-0.332,2.116-0.712,3.129-1.155c1.289-0.567,2.591-1.294,3.752-2.075   c0.358-0.236,0.7-0.494,1.023-0.772c0.157-0.123,0.306-0.26,0.447-0.403c0.095-0.103,0.234-0.276,0.188-0.434   c-0.071-0.2-0.535-0.265-0.714-0.35c-0.305-0.143-0.636-0.331-1.001-0.579c-0.045-0.021-4.447-2.973-4.973-3.77   c-0.329-0.492-0.463-1.069-0.385-1.652c0.038-0.288,0.123-0.561,0.234-0.833c0.049-0.136,0.425-0.896-0.06-0.586   c-0.189,0.12-0.376,0.241-0.556,0.364c-0.729,0.462-1.42,0.993-2.161,1.45c-1.413,0.866-3.257,1.214-4.894,1.146   c-1.108-0.048-2.205-0.319-3.21-0.791c-3.909-1.848-5.45-6.312-5.008-10.017c0.446-3.699,7.691-41.134,9.608-48.145   c1.915-7.003,6.662-11.545,15.292-9.379c8.632,2.16,14.185,5.133,19.12,10.327c0.914,0.969,1.79,1.963,2.548,3.05   c1.063,1.53,1.985,3.099,2.405,4.93c0.332,1.41,0.374,2.875,0.025,4.285c-0.145,0.583-0.279,1.166-0.483,1.732   c-0.18,0.491-0.535,0.995-0.595,1.517c-0.025,0.332,0.271,0.435,0.497,0.612c1.678,1.276,3.352,2.556,5.021,3.83   c0.017,0.013,0.027,0.023,0.04,0.031c0.5,0.413,0.846,0.946,1.006,1.571c0.108,0.417,0.291,0.474,0.706,0.318   c1.664-0.63,2.536-2.477,2.978-4.082c0.507-1.838,0.818-3.739,0.96-5.639C84.907,36.716,84.937,34.737,84.614,32.792z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M48.088,74.232c-0.407,0.674-0.117,0.987-0.554,1.732c-0.443,0.747-3.489,4.617-4.234,5.829s-0.705,1.672-0.441,2.049   c0.25,0.382,3.646,2.767,4.575,3.374c0.928,0.618,1.526,0.812,1.949,0.666c0.424-0.151,3.585-4.653,4.103-5.195   c0.52-0.538,1.64-2.177,2.336-2.376c0.702-0.188,0.946-0.332,1.287-0.689c0.132-0.144,0.444-0.609,0.777-1.125l-8.407-6.21   C48.947,73.002,48.31,73.864,48.088,74.232z M49.062,83.588c-0.268,0.771-1.101,1.185-1.87,0.92   c-0.771-0.262-1.178-1.096-0.918-1.868c0.262-0.769,1.098-1.178,1.869-0.914C48.909,81.986,49.317,82.819,49.062,83.588z"
          ></path>
        </g>
      </g>
    </svg>
  );
}