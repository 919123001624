import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Pagination,
  Typography,
} from '@mui/material';

import _ from 'lodash';
import React, { useState } from 'react';

// Memoized ListItem Component
const MemoizedListItem = ({ item, onCheck, listId, disabled, minDepth }) => {
  const labelId = `checkbox-list-secondary-label-${item.block_rid}`;

  return (
    <ListItem key={item.block_rid} sx={{ py: 0 }}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          p: 0,
          '& .MuiButtonBase-root': {
            p: 0,
            mr: 1,
          },
        }}
      >
        {/* <input
          type="checkbox"
          onChange={() => onCheck(listId, item, !item.completed)}
          checked={item.completed}
          disabled={disabled}
        /> */}
        <Checkbox
          onChange={() => onCheck(listId, item, !item.completed)}
          checked={item.completed}
          inputProps={{ 'aria-labelledby': labelId }}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={item.name}
        sx={{
          textOverflow: 'ellipsis',
          pl: (item.level - minDepth) * 3,
        }}
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </ListItem>
  );
};

const CheckList = ({
  onCheck = () => {},
  items,
  disabled = false,
  itemsPerPage = 7,
  listId,
}) => {
  if (!items) return <Typography>No items in list.</Typography>;

  // Use the progressive render hook with the provided items
  const [page, setPage] = useState(1);

  const minDepth = _.minBy(items, 'level')?.level || 0; // Using rendered items instead of items
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedItems = React.useMemo(
    () => items.slice(startIndex, endIndex), // Use renderedItems for pagination
    [items, page, itemsPerPage],
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box width={1}>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          height: `${36 * itemsPerPage}px`,
          overflowY: 'scroll',
        }}
      >
        {paginatedItems.map((item) => (
          <MemoizedListItem
            key={item.block_rid}
            item={item}
            onCheck={onCheck}
            listId={listId}
            disabled={disabled}
            minDepth={minDepth}
          />
        ))}
      </List>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(items.length / itemsPerPage)} // Use renderedItems for pagination
          page={page}
          onChange={handlePageChange}
          size="small"
          color="primary"
          siblingCount={0}
        />
      </Box>
    </Box>
  );
};

export default CheckList;
