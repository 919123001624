import React from 'react';
import { svgDownArrow } from 'components/svg_module';
export const ListButton = ({ block, isOpen, handleClick }) => {
  return (
    <div className="list-module-button-wrapper">
      {block.hasChildren ? (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick(!isOpen);
          }}
          className="list-module-open-button"
        >
          {isOpen ? (
            svgDownArrow('', 'list-button-svg', 'list-button-svg-outer')
          ) : (
            <span>{'>'}</span>
          )}
        </button>
      ) : (
        <div className="list-module-open-button--placeholder">•</div>
      )}
    </div>
  );
};

export const BridgeBetweenItems = ({ level }) => {};
