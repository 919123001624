import {
  RECEIVE_UNSPLASH_IMAGES,
  RECEIVE_FLUSH_MODAL,
  RECEIVE_PEXEL_IMAGES,
  CLOSE_MODAL,
} from '../../constants';
import merge from 'lodash/merge';

const PexelReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_UNSPLASH_IMAGES:
      newState['unsplashImages'] = action.data.unsplashImages;
      return newState;
    case RECEIVE_PEXEL_IMAGES:
      if (newState.pexelPhotos) {
        if (action.searchTerm === newState.query) {
          newState.pexelPhotos = [
            ...newState.pexelPhotos,
            ...action.data.photos,
          ];
        } else {
          newState['pexelPhotos'] = action.data.photos;
        }
      } else {
        newState['pexelPhotos'] = action.data.photos;
      }
      newState['query'] = action.data.query;
      newState['pexelNextPageNumber'] = action.data.page + 1;
      newState['pexelImageTotal'] = action.data.total_results;
      return newState;
    case RECEIVE_FLUSH_MODAL:
      newState = {};
      return newState;
    case CLOSE_MODAL:
      newState = {};
      return newState;
    default:
      return state;
  }
};

export default PexelReducer;
