export function work (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1254 1048"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path
        className={className}
        fill={color}
        d="M0 951.994C0 977.453 10.1145 1001.87 28.1147 1019.87C46.12 1037.88 70.536 1047.99 96 1047.99H1156.8C1182.26 1047.99 1206.68 1037.88 1224.69 1019.87C1242.69 1001.87 1252.8 977.453 1252.8 951.994V545.114H0V951.994Z"
      />
      <path
        className={className}
        fill={color}
        d="M1156.8 181.755H894.08C889.721 132.171 866.955 86.0093 830.268 52.3693C793.575 18.7293 745.617 0.0466667 695.841 0H557.601C507.825 0.0468747 459.867 18.7293 423.175 52.3693C386.487 86.0093 363.721 132.172 359.363 181.755H96.6426C71.1786 181.755 46.7626 191.869 28.7572 209.875C10.7572 227.88 0.642578 252.296 0.642578 277.755V473.115H1253.44V278.395C1253.61 252.712 1243.49 228.035 1225.33 209.873C1207.17 191.712 1182.48 181.581 1156.8 181.753L1156.8 181.755ZM443.36 181.755C452.001 117.755 491.36 83.676 557.6 83.676H695.84C762.72 83.676 801.439 117.113 810.08 181.755H443.36Z"
      />
    </svg>
  );
}