import React, { useRef, useEffect } from 'react';
import { TextArea, Comment, Button } from '../../modal_modules';
import { svgThumbsDown, svgThumbsUp } from 'components/svg_module';

import {
  deleteBlockComment,
  deleteAdventureBlockComment,
} from 'actions/comment_actions';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => ({
  deleteBlockComment: (itinerary_block_rid, explorer_comment_rid) =>
    dispatch(deleteBlockComment(itinerary_block_rid, explorer_comment_rid)),
  deleteAdventureBlockComment: (
    adventure_block_rid,
    adventure_block_comment_rid,
  ) =>
    dispatch(
      deleteAdventureBlockComment(
        adventure_block_rid,
        adventure_block_comment_rid,
      ),
    ),
});
const Comments = ({
  currentBlock,
  blockComments,
  comments,
  handleOnChangeComments,
  deleteBlockComment,
  deleteAdventureBlockComment,
  handleBlockCommentSubmit,
}) => {
  let bottomRef = useRef(null);
  const deleteHandler = (comment) => {
    if (currentBlock.itineriary_block_rid) {
      deleteBlockComment(
        currentBlock.itineriary_block_rid,
        comment.explorer_comment_rid,
      );
    } else {
      deleteAdventureBlockComment(
        currentBlock.adventure_block_rid,
        comment.adventure_block_comment_rid,
      );
    }
  };
  return (
    <div className="comments-wrapper">
      <div className="comment-view-wrapper">
        <div className="comments-comment-wrapper">
          {blockComments &&
            blockComments.map((ele, idx) => {
              return (
                <Comment
                  key={idx}
                  comment={ele}
                  deleteHandler={() => deleteHandler(ele)}
                />
              );
            })}
          <div ref={bottomRef} />
        </div>
      </div>

      <TextArea
        label="Comment.."
        name="explorer_comment"
        onChangeHandler={handleOnChangeComments}
        value={comments.explorer_comment}
        spacingTop="10px"
        spacingBottom="-35px"
      />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Comments);
