 export function trailer (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M78.272,60.912V38.73c0-7.391-5.99-13.381-13.378-13.381H44.832c-23.919,0-31.123,22.246-31.123,36.563  c0,3.029,2.456,5.484,5.484,5.484h0.984v1.773h2v-1.773c0-5.059,4.115-9.174,9.173-9.174c5.059,0,9.174,4.115,9.174,9.174h13.783  V43.535c0-2.093,1.702-3.795,3.794-3.795h9.494c2.093,0,3.795,1.702,3.795,3.795v23.861h1.397c3.029,0,5.484-2.455,5.484-5.484v1  h8.02v-2H78.272z M47.614,45.686c0,2.093-1.702,3.795-3.795,3.795H28.662c-1.594,0-2.844-0.619-3.517-1.746  c-0.718-1.201-0.688-2.831,0.077-4.255l2.955-5.495c0.648-1.201,1.854-3.439,4.78-3.439h10.861c2.093,0,3.795,1.703,3.795,3.795  V45.686z M67.596,41.24h-9.494c-1.265,0-2.294,1.029-2.294,2.295v23.861h14.083V54.693h-2.606v-1.5h2.606v-9.658  C69.891,42.27,68.861,41.24,67.596,41.24z M31.351,60.143c-4.004,0-7.254,3.249-7.254,7.254s3.25,7.254,7.254,7.254  c4.005,0,7.255-3.249,7.255-7.254S35.355,60.143,31.351,60.143z M31.351,69.648c-1.243,0-2.252-1.008-2.252-2.252  c0-1.246,1.009-2.254,2.252-2.254c1.245,0,2.253,1.008,2.253,2.254C33.604,68.641,32.596,69.648,31.351,69.648z M43.819,36.045  H32.958c-1.876,0-2.706,1.254-3.459,2.65l-2.954,5.494c-0.51,0.947-0.553,2.037-0.111,2.775c0.396,0.664,1.168,1.016,2.229,1.016  h15.157c1.266,0,2.295-1.029,2.295-2.295V38.34C46.114,37.074,45.085,36.045,43.819,36.045z M29.053,42.727l5.216-5.217l1.061,1.061  l-5.216,5.217L29.053,42.727z M33.885,44.266l-1.061-1.061l2.726-2.727l1.061,1.061L33.885,44.266z"
        ></path>
      </g>
    </svg>
  );
}