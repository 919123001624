export function roadTrip (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M16.95892,48.90088c0,1.28003,1.01001,2.32001,2.26001,2.32001s2.25995-1.03998,2.25995-2.32001   c0-0.17999-0.01996-0.35999-0.06-0.52997h-4.39996C16.97888,48.54089,16.95892,48.72089,16.95892,48.90088z"
        ></path>
        <ellipse
          className={className}
          fill={color}
          cx="53.1189"
          cy="48.90088"
          rx="2.26001"
          ry="2.33002"
        ></ellipse>
        <path
          className={className}
          fill={color}
          d="M65.75,54.11523h-9.80103c1.79993-1.01666,3.01996-2.9704,3.01996-5.21436c0-3.29999-2.62006-5.96997-5.85004-5.96997   s-5.84998,2.66998-5.84998,5.96997c0,2.24396,1.21997,4.19769,3.0199,5.21436H22.02148   c1.58856-0.88824,2.73157-2.49164,2.98743-4.39435l-0.78998-1.09003c-0.13-0.17999-0.29004-0.25995-0.47003-0.25995h-0.81   c0.03003,0.16998,0.03998,0.34998,0.03998,0.52997c0,2.09998-1.69,3.82001-3.75995,3.82001   c-2.07001,0-3.76001-1.72003-3.76001-3.82001c0-0.17999,0.01001-0.35999,0.03998-0.52997h-0.65997   c-0.17999,0-0.34003,0.07996-0.45001,0.23999l-0.92999,1.27997c0.29169,1.82794,1.41376,3.36255,2.95209,4.22437H3.25   c-0.41406,0-0.75,0.33594-0.75,0.75s0.33594,0.75,0.75,0.75h62.5c0.41406,0,0.75-0.33594,0.75-0.75   S66.16406,54.11523,65.75,54.11523z M53.1189,52.7309c-2.07001,0-3.76001-1.72003-3.76001-3.83002s1.69-3.83002,3.76001-3.83002   c2.08002,0,3.76001,1.72003,3.76001,3.83002S55.19891,52.7309,53.1189,52.7309z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M3.72888,45.71088c0.10004,0.41003,0.21002,0.81,0.32001,1.20001c0.47003,1.60999,1.98004,2.72998,3.67004,2.72998h3.67999   c0.25,0,0.46997-0.10999,0.60999-0.31l1.15002-1.58002c0.38995-0.56,1.00995-0.87994,1.67999-0.87994h8.90997   c0.66998,0,1.29999,0.31995,1.67999,0.87994l1.15002,1.58002c0.14001,0.20001,0.35999,0.31,0.59998,0.31h18.63   c-0.02997-0.23999-0.03998-0.48999-0.03998-0.73999c0-4.12,3.29999-7.46997,7.34998-7.46997   c4.06,0,7.35004,3.34998,7.35004,7.46997c0,0.25-0.01001,0.5-0.04004,0.73999h2.22003c2.13,0,3.84998-1.73999,3.84998-3.89001   v-7.90997c0-0.83002-0.08997-1.65002-0.28998-2.45001H2.50891c0.02002,3.59003,0.41998,7.09003,1.16998,10.10004   C3.67889,45.54089,3.69891,45.59088,3.72888,45.71088z M61.10889,42.31091c0-1.07001,0.85004-1.93005,1.89001-1.93005h2v4.65002h-2   c-1.03998,0-1.89001-0.87-1.89001-1.94V42.31091z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M65.53888,33.41089L60.95892,23.3009c-0.88-1.92999-2.81-3.17999-4.91003-3.17999H38.69238v-1.86798h1.6748   c0.41406,0,0.75-0.33594,0.75-0.75s-0.33594-0.75-0.75-0.75h-1.6748v-1.86816h1.6748c0.41406,0,0.75-0.33594,0.75-0.75   s-0.33594-0.75-0.75-0.75h-29.2998c-0.41406,0-0.75,0.33594-0.75,0.75s0.33594,0.75,0.75,0.75h1.67432v1.86768h-1.67432   c-0.41406,0-0.75,0.33594-0.75,0.75s0.33594,0.75,0.75,0.75h1.67432v1.86847H7.77893c-1.71002,0-3.24005,1.16998-3.71002,2.83997   c-0.92999,3.29999-1.45001,7.07001-1.53998,10.92999h63.20996C65.67889,33.72089,65.6189,33.56091,65.53888,33.41089z    M14.2417,14.88477h22.95068v1.8681L14.2417,16.7525V14.88477z M14.2417,18.2525l22.95068,0.00037v1.86804H14.2417V18.2525z    M19.4389,31.64087H7.46893c0.19995-2.01996,0.52997-5.96997,1-7.76996H19.4389V31.64087z M43.71893,31.64087H22.81891v-7.76996   h20.90002V31.64087z M47.09888,31.64087v-7.76996h8.94c0.26001,0,0.49005,0.14996,0.60004,0.40997l2.42999,7.35999H47.09888z"
        ></path>
      </g>
    </svg>
  );
}