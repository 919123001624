import React from 'react';
const Footer = () => {
  return (
    <div className="c-text-hub" id="c-text">
      <span>© 2021 &nbsp;</span> LODESTAR HUB, INC. ALL RIGHTS RESERVED.
    </div>
  );
};

export default Footer;
