import React from 'react';
import {
  svgComingUp,
  svgDonate,
  svgImpact,
  svgLinkedApps,
  svgLodestar,
  svgOffset,
  svgEdit,
  svgTripThisYear,
  svgVolunteer,
  svgCreate,
  svgComment,
  svgStart,
  svgInviteAccepted,
  svgInspiration,
  svgFriend,
  svgGroup,
  svgMap,
  svgInviteCircle,
  svgLibrary2,
} from 'components/svg_module';
import {
  openAccountGroupModal,
  openInviteNewModal,
} from 'actions/modal_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import urlencode from 'urlencode';

const RegularBlock = ({
  type,
  adventure,
  tripsThisYear,
  history,
  userInfo,
  displayAdd,
  addButtonHandler,
  handleTrip,
  handleInspiration,
  handleFriend,
  handleGroup,
  openAccountGroupModal,
  openInviteNewModal,
}) => {
  let bottomContent;
  // const renderContentTitle = () => {
  switch (type) {
    case 'coming up':
      bottomContent = (
        <span>
          {svgComingUp(
            '',
            'dashboard-svg',
            'dashboard-svg-outerlayer',
            'svg-darkblue',
            'svg-lightblue',
          )}
          NEXT TRIPS
        </span>
      );
      break;
    case 'build trip':
      bottomContent = (
        <div className="custom-spacing-container dashboard-block__dark-orange">
          <span className="custom-bottom-spacing">
            {svgComingUp(
              '',
              'dashboard-svg',
              'dashboard-svg-outerlayer',
              'svg-darkwhite',
              'svg-lightwhite',
            )}
            BUILD A TRIP
          </span>
          {/* {displayAdd && <button onClick={(e) => {
          e.preventDefault();
          addButtonHandler();
          }} className='svg-bottom-adder-button'>{svgCreate('color', 'dashboard-bottom-svg', 'dashboard-bottom-svg-outer')}</button>} */}
        </div>
      );
      break;
    case 'trip this year':
      bottomContent = (
        <div className="custom-spacing-container">
          <span className="custom-bottom-spacing">
            {svgTripThisYear('', 'dashboard-svg', 'dashboard-svg-outerlayer')}
            UPCOMING TRIPS
          </span>
          {/* {displayAdd && <button onClick={(e) => {
          e.preventDefault();
          addButtonHandler();
          }} className='svg-bottom-adder-button'>{svgCreate('color', 'dashboard-bottom-svg', 'dashboard-bottom-svg-outer')}</button>} */}
        </div>
      );
      break;
    case 'impact':
      bottomContent = (
        <span>
          {svgImpact('', 'dashboard-svg', 'dashboard-svg-outerlayer')} IMPACT
        </span>
      );
      break;
    case 'community':
      bottomContent = (
        <div className="dashboard-block-bottom-comming-soon">
          {' '}
          <span>
            {svgComment('', 'dashboard-svg', 'dashboard-svg-outerlayer')}{' '}
            COMMUNITY
          </span>
          <span className="coming-soon-text">— COMING SOON —</span>
        </div>
      );
      break;
    case 'linked apps':
      bottomContent = (
        <div className="dashboard-block-bottom-comming-soon">
          <div className="custom-spacing-container">
            <div></div>
            <span className="custom-bottom-spacing">
              {' '}
              {svgLinkedApps(
                '',
                'dashboard-svg',
                'dashboard-svg-outerlayer',
              )}{' '}
              LINKED APPS
            </span>
            {displayAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/hub/linkedapps');
                }}
                className="svg-bottom-adder-button"
              >
                {svgEdit(
                  'color',
                  'dashboard-bottom-svg',
                  'dashboard-bottom-svg-outer',
                )}
              </button>
            )}
          </div>
          {/* <span className="coming-soon-text">— COMING SOON —</span> */}
        </div>
      );
      break;
    case 'get started':
      bottomContent = (
        <div className="dashboard-block-bottom-comming-soon">
          <div className="modal--regular-block--getting-started ">
            <span>
              {svgStart(
                'color',
                'dashboard-bottom-svg',
                'dashboard-bottom-svg-outer',
              )}
              GETTING STARTED GUIDE
            </span>
          </div>
          {/* <span className="coming-soon-text">— COMING SOON —</span> */}
        </div>
      );
      break;
    case 'launchpad':
      bottomContent = (
        <div className="custom-spacing-container ">
          <span className="custom-bottom-spacing">
            {svgComingUp(
              '',
              'dashboard-svg',
              'dashboard-svg-outerlayer',
              'svg-darkwhite',
              'svg-darkorange',
            )}
            LAUNCH PAD
          </span>
          {/* {displayAdd && <button onClick={(e) => {
          e.preventDefault();
          addButtonHandler();
          }} className='svg-bottom-adder-button'>{svgCreate('color', 'dashboard-bottom-svg', 'dashboard-bottom-svg-outer')}</button>} */}
        </div>
      );
      break;
    default:
      bottomContent = null;
      break;
  }
  // }
  const clickHandler = (trip) => {
    history.push(
      `/hub/adventure/${urlencode(trip.adventure_name, 'gbk')}/${
        trip.adventure_rid
      }`,
    );
  };
  const renderTopContent = () => {
    switch (type) {
      case 'build trip':
        return (
          <div
            onClick={(e) => {
              e.preventDefault();
              addButtonHandler();
            }}
            className="dashboard-buildtrip-wrapper"
          >
            {svgCreate(
              '',
              'dashboard-buildtrip-svg',
              'dashboard-buildtrip-svg-outer',
            )}
          </div>
        );
      case 'coming up':
        return (
          <div
            className="dashboard-comingup-wrapper "
            style={{
              backgroundImage: `url(${adventure.ui_image_location})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'fill',
            }}
          >
            <div className="adventure-block-top">
              <div className="adventure-block-title">
                <span className="adventure-block-title-text">
                  {adventure.adventure_name}
                </span>
              </div>
              <div className={`adventure-block-status future`}>
                <span className="adventure-block-status-text">
                  {adventure.status_detail}
                </span>
              </div>
            </div>
          </div>
        );
      case 'trip this year':
        return (
          <div className="dashboard-tripsthisyear-wrapper ">
            {tripsThisYear.map((trip, idx) => {
              return (
                <div onClick={() => clickHandler(trip)}>
                  <span className="trip-list-wrapper">
                    <div className="trip-list-date">
                      {moment(trip.start_date).format("MMM 'YY")}{' '}
                    </div>
                    — <span className="trip-name">{trip.adventure_name}</span>
                  </span>
                </div>
              );
            })}
          </div>
        );
      case 'get started':
        return (
          <div className="dashboard-tripsthisyear-wrapper ">
            <div
              onClick={() => history.push('/hub/getting-started/build-a-trip')}
              className="dashboard--getting-started-ele notfilled-in"
            >
              <span>build a trip</span>
              {svgInviteCircle('color', 'mod-inv-svg', 'mod-inv-svg-outer')}
            </div>
            <div
              onClick={() => history.push('/hub/getting-started/tag-and-save')}
              className="dashboard--getting-started-ele notfilled-in"
            >
              <span>tag and save</span>
              {svgInviteCircle('color', 'mod-inv-svg', 'mod-inv-svg-outer')}
            </div>
            <div
              onClick={() =>
                history.push('/hub/getting-started/invite-a-friend')
              }
              className="dashboard--getting-started-ele notfilled-in"
            >
              <span>invite a friend</span>
              {svgInviteCircle('color', 'mod-inv-svg', 'mod-inv-svg-outer')}
            </div>
            <div
              onClick={() =>
                history.push('/hub/getting-started/create-a-group')
              }
              className="dashboard--getting-started-ele notfilled-in"
            >
              <span>create a group</span>
              {svgInviteCircle('color', 'mod-inv-svg', 'mod-inv-svg-outer')}
            </div>

            <div
              onClick={() => history.push('/hub/getting-started/share-a-trip')}
              className="dashboard--getting-started-ele notfilled-in"
            >
              <span>share a trip</span>
              {svgInviteCircle('color', 'mod-inv-svg', 'mod-inv-svg-outer')}
            </div>
          </div>
        );
      case 'impact':
        return (
          <div className="dashboard-impact-wrapper ">
            <div className="dashboard-impact-block">
              <div className="dashboard-impact-svg-wrapper">
                {svgDonate(
                  '',
                  'dashboard-impact-svg',
                  'dashboard-impact-svg-outer',
                )}
              </div>
              <div className="dashboard-impact-text-wrapper">
                <div className="dashboard-impact-text-top">Donate</div>
                <div className="dashboard-impact-text-bottom">$235!!</div>
              </div>
            </div>
            <div className="dashboard-impact-block ">
              <div className="dashboard-impact-svg-wrapper">
                {svgVolunteer(
                  '',
                  'dashboard-impact-svg',
                  'dashboard-impact-svg-outer',
                )}
              </div>
              <div className="dashboard-impact-text-wrapper">
                <div className="dashboard-impact-text-top">Volunteer</div>
                <div className="dashboard-impact-text-bottom">12 Hours</div>
              </div>
            </div>
            <div className="dashboard-impact-block">
              <div className="dashboard-impact-svg-wrapper">
                {svgOffset(
                  '',
                  'dashboard-impact-svg',
                  'dashboard-impact-svg-outer',
                )}
              </div>
              <div className="dashboard-impact-text-wrapper">
                <div className="dashboard-impact-text-top">Offset</div>
                <div className="dashboard-impact-text-bottom">
                  2.3 Tons CO 2
                </div>
              </div>
            </div>
          </div>
        );
      case 'community':
        return (
          <div className="dashboard-community-item-wrapper ">
            {/* placeholdesr replace later */}
            <ul>
              <li>
                <span>What is the best place to fish near Bishop?</span>
              </li>
              <li>
                <span>Alicia shared an itinerary.</span>
              </li>
              <li>
                <span>Is the gate to Big Calliente Hot Spring open yet?</span>
              </li>
            </ul>
          </div>
        );
      case 'linked apps':
        return (
          <div className="dashboard-linkedapps-wrapper ">
            {/* placeholdesr replace later */}

            <a
              className="linked-app-links"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.google.com/maps"
            >
              <div className="dashboard-linkedapps-blocks">
                <img src="/placeholder_image/googlemaps.png" />
                <span>Google</span>
              </div>
            </a>
            <a
              className="linked-app-links"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.alltrails.com/"
            >
              <div className="dashboard-linkedapps-blocks">
                <img src="/placeholder_image/all_trails.png" />
                <span>All Trails</span>
              </div>
            </a>
            <a
              className="linked-app-links"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.gaiagps.com/"
            >
              <div className="dashboard-linkedapps-blocks">
                <img src="/placeholder_image/giagps.png" />
                <span>Gia GPS</span>
              </div>
            </a>
            <a
              className="linked-app-links"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.hipcamp.com/"
            >
              <div className="dashboard-linkedapps-blocks">
                <img src="/placeholder_image/hipcamp.png" />
                <span>HIPCAMP</span>
              </div>
            </a>
          </div>
        );
      case 'launchpad':
        return (
          <div className="dashboard--launchpad--wrapper">
            <div
              onClick={() => {
                handleTrip();
              }}
              className="dashboard--launchpad--container trip-svg"
            >
              {svgMap(
                '',
                'dashboard--launchpad--svg',
                'dashboard--launchpad--svg__outer',
              )}
              <span>build a trip</span>
            </div>
            <div
              // onClick={() => history.push("/hub/library")}
              className="dashboard--launchpad--container"
              disabled={true}
            >
              {svgLibrary2(
                'rgba(255, 255, 255, 0.4)',
                'dashboard--launchpad--svg',
                'dashboard--launchpad--svg__outer-library2',
              )}
              <span>library</span>
              <span className="launchboard-coming-soon-text">
                – Coming soon –
              </span>
            </div>
            <div
              // onClick={() => {
              //   openInviteNewModal();
              // }}
              className="dashboard--launchpad--container friend-svg"
              disabled={true}
            >
              {svgFriend(
                ('',
                'dashboard--launchpad--svg',
                'dashboard--launchpad--svg__outer'),
              )}
              <span>invite a friend</span>
              <span className="launchboard-coming-soon-text">
                – Coming soon –
              </span>
            </div>
            <div
              // onClick={() => {
              //   openAccountGroupModal(userInfo, null, { isNew: true });
              // }}
              className="dashboard--launchpad--container"
              disabled={true}
            >
              {svgGroup(
                ('',
                'dashboard--launchpad--svg',
                'dashboard--launchpad--svg__outer'),
              )}
              <span>create a group</span>
              <span className="launchboard-coming-soon-text">
                – Coming soon –
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="dashboard-regular-block-wrapper">
      <div className="dashboard-regular-block-top">{renderTopContent()}</div>
      <div className="dashboard-regular-block-bottom">{bottomContent}</div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    openAccountGroupModal: (userInfo, account_group_rid, type) =>
      dispatch(openAccountGroupModal(userInfo, account_group_rid, type)),
    openInviteNewModal: () => dispatch(openInviteNewModal()),
  };
};
const mapStateToProps = (state) => {
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;

  return {
    userInfo,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegularBlock),
);
