export const svgListBlock = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      className={outerLayer}
      width={width ? width : 71}
      height={height ? height : 70}
      viewBox={viewBox ? viewBox : '0 0 71.419 69.377'}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M28.022,9.689 C28.022,7.963 29.421,6.564 31.147,6.564 L68.294,6.564 C70.02,6.564 71.419,7.963 71.419,9.689 C71.419,11.415 70.02,12.814 68.294,12.814 L31.147,12.814 C29.421,12.814 28.022,11.415 28.022,9.689 z M5.522,18.127 C6.105,18.903 7.014,19.365 7.985,19.377 L8.016,19.377 C8.973,19.376 9.877,18.938 10.469,18.186 L20.807,5.061 C21.876,3.706 21.644,1.741 20.29,0.672 C18.935,-0.397 16.97,-0.166 15.901,1.189 L8.057,11.136 L5.632,7.933 C4.59,6.552 2.627,6.277 1.246,7.319 C-0.135,8.36 -0.41,10.324 0.632,11.705 z M68.294,31.564 L31.147,31.564 C29.421,31.564 28.022,32.963 28.022,34.689 C28.022,36.415 29.421,37.814 31.147,37.814 L68.294,37.814 C70.02,37.814 71.419,36.415 71.419,34.689 C71.419,32.963 70.02,31.564 68.294,31.564 z M15.888,26.195 L8.057,36.136 L5.632,32.933 C4.59,31.552 2.627,31.277 1.246,32.319 C-0.135,33.36 -0.41,35.324 0.632,36.705 L5.513,43.127 C6.095,43.903 7.005,44.365 7.976,44.377 L8.007,44.377 C8.964,44.376 9.868,43.938 10.46,43.186 L20.801,30.061 C21.87,28.705 21.637,26.738 20.28,25.669 C18.924,24.6 16.957,24.833 15.888,26.189 z M68.294,56.564 L31.147,56.564 C29.421,56.564 28.022,57.963 28.022,59.689 C28.022,61.415 29.421,62.814 31.147,62.814 L68.294,62.814 C70.02,62.814 71.419,61.415 71.419,59.689 C71.419,57.963 70.02,56.564 68.294,56.564 z M15.888,51.195 L8.057,61.136 L5.632,57.933 C4.59,56.552 2.627,56.277 1.246,57.319 C-0.135,58.36 -0.41,60.324 0.632,61.705 L5.513,68.127 C6.095,68.903 7.005,69.365 7.976,69.377 L8.007,69.377 C8.964,69.376 9.868,68.938 10.46,68.186 L20.801,55.061 C21.87,53.705 21.637,51.738 20.28,50.669 C18.924,49.6 16.957,49.833 15.888,51.189 z"
          fill={color}
        />
      </g>
    </svg>
  );
};