export const svgComment = (color, className, outerLayer, width, height) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 248.4, 219.42"
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M20.7,-0 L227.7,-0 C239.085,-0 248.4,9.453 248.4,20.907 L248.4,146.625 C248.4,158.01 239.085,167.67 227.7,167.67 L189.75,167.67 L189.75,212.52 C189.75,215.073 188.301,217.35 186.093,218.592 C185.058,219.144 183.885,219.42 182.781,219.42 C181.47,219.42 180.159,219.213 179.055,218.523 L99.36,167.67 L20.7,167.67 C9.315,167.67 -0,158.079 -0,146.625 L-0,20.838 C-0,9.453 9.315,-0 20.7,-0 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};