import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {
  svgLibraryGreen1,
  svgLibrary2,
  svgTrip,
  svgCompass,
  svgDashboardFriends,
  svgTagAndSave,
  svgDashboardImpact,
  svgMessages2,
  svgDashboardCommunity,
  svgLinkedApps2,
} from 'components/svg_module';
import { RegularBlock, SmallBlock } from 'components/modules/dashboard_modules';
import { fetchUserAdventures } from '../../../actions/adventure_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  openAdventureModal,
  openBlockModal,
  openFriendsModal,
  openShareModal,
  openAccountGroupModal,
} from '../../../actions/modal_actions';
import { triggerMenuDrawerWithTab } from '../../../actions/ui_actions';

import { fetchUserRecentBlockUpdate } from '../../../actions/social_actions';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
  },
};
const mapStateToProps = (state) => {
  let account_rid = state.session?.user?.account_rid;

  let userAdventures = state.entities?.adventure?.user_adventures;

  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let userRecentBlockUpdates = state.entities.social.userRecentBlockUpdate
    ? state.entities.social.userRecentBlockUpdate
    : null;
  return {
    userAdventures,
    account_rid,
    userInfo,
    userRecentBlockUpdates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAdventures: (
      account_rid,
      inspiration_rid,
      userInfo,
      position_rid,
    ) =>
      dispatch(
        fetchUserAdventures(
          account_rid,
          inspiration_rid,
          userInfo,
          position_rid,
        ),
      ),
    openAdventureModal: (isInspirationAdventure, data, edit) =>
      dispatch(openAdventureModal(isInspirationAdventure, data, edit)),
    openFriendsModal: (data) => dispatch(openFriendsModal(data)),
    openShareModal: (data) => dispatch(openShareModal(data)),
    openBlockModal: (
      editView,
      data,
      addAdventureBlock,
      tabToDisplay,
      itineraryData,
    ) =>
      dispatch(
        openBlockModal(
          editView,
          data,
          addAdventureBlock,
          tabToDisplay,
          itineraryData,
        ),
      ),
    openAccountGroupModal: (data) => dispatch(openAccountGroupModal(data)),
    fetchUserRecentBlockUpdate: (account_rid) =>
      dispatch(fetchUserRecentBlockUpdate(account_rid)),
    triggerMenuDrawerWithTab: (tab) => dispatch(triggerMenuDrawerWithTab(tab)),
  };
};

const DashBoard = ({
  history,
  account_rid,
  fetchUserAdventures,
  userAdventures,
  userInfo,
  openAdventureModal,
  openFriendsModal,
  openShareModal,
  openBlockModal,
  openAccountGroupModal,
  fetchUserRecentBlockUpdate,
  userRecentBlockUpdates,
  triggerMenuDrawerWithTab,
}) => {
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'dashboard' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const getResult = async () => {
      Promise.all([fetchUserRecentBlockUpdate(account_rid)]);
    };
    getResult();
  }, []);
  const clickHandler = () => {
    history.push(
      `/hub/adventure/${userAdventures[0].adventure_name}/${userAdventures[0].adventure_rid}`,
    );
  };

  const dashboardBlockItem = (
    className,
    header,
    description,
    svg,
    svgClassName,
    svgOuterLayer,
    svgWidth,
    svgHeight,
    svgViewBox,
    directory,
    notALinkBool = false,
  ) => {
    if (notALinkBool) {
      return (
        <div
          className={className}
          onClick={() => directory()}
          id="dashboard-block-mobile"
        >
          {svg(
            '',
            svgClassName,
            svgOuterLayer,
            svgWidth,
            svgHeight,
            svgViewBox,
          )}
          <div className="dashboard-block-header">{header}</div>
          {/* <div className="dashboard-block-footer">{description}</div> */}
        </div>
      );
    }
    return (
      <NavLink className={className} to={directory} id="dashboard-block-mobile">
        {svg('', svgClassName, svgOuterLayer, svgWidth, svgHeight, svgViewBox)}
        <div className="dashboard-block-header">{header}</div>
        {/* <div className="dashboard-block-footer">{description}</div> */}
      </NavLink>
    );
  };
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  return (
    <div className="hub-page-wrapper" id="hub-page-wrapper">
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="dashboard-content-wrapper dashboard-content-wrapper-itinerary "
        id="dashboard-content-wrapper-mobile"
      >
        <div className="dashboard-contents-container">
          {dashboardBlockItem(
            'dashboard-block-wrapper',
            'TRIPS',
            '',
            svgTrip,
            'dashboard-block-svg',
            'outer-svg-layer',
            175,
            120,
            '0 -1 94 60',
            '/hub/my_adventures',
          )}
          {dashboardBlockItem(
            'dashboard-block-wrapper dashboard-block-wrapper--break',
            'LIBRARY',
            '',
            svgLibrary2,
            'dashboard-block-svg',
            'outer-svg-layer',
            120,
            120,
            '0 0 104 104',
            '/hub/library',
          )}
          {dashboardBlockItem(
            'dashboard-block-wrapper',
            'FRIENDS',
            '',
            svgDashboardFriends,
            'dashboard-block-svg',
            'outer-svg-layer',
            200,
            120,
            undefined,
            '/hub/friends',
          )}
          {/* {dashboardBlockItem(
            'dashboard-block-wrapper',
            'MESSAGES',
            '',
            svgMessages2,
            'dashboard-block-svg',
            'outer-svg-layer',
            120,
            120,
            undefined,
            () => triggerMenuDrawerWithTab('notification'),
            true,
          )} */}
          {/* {dashboardBlockItem(
            'dashboard-block-wrapper',
            'GET STARTED',
            '',
            svgCompass,
            'dashboard-block-svg',
            'outer-svg-layer',
            120,
            120,
            undefined,
            '/hub/getting-started',
          )} */}

          {dashboardBlockItem(
            'dashboard-block-wrapper dashboard-block-wrapper--break',
            'IMPACT',
            '',
            svgDashboardImpact,
            'dashboard-block-svg',
            //Impact block needs special sizing because feet svg are too tall
            'outer-svg-layer outer-svg-layer--impact',
            192.271,
            120,
            undefined,
            '/hub/my-impact-summary',
          )}

          {/* {dashboardBlockItem(
            'dashboard-block-wrapper',
            'TAG & SAVE',
            '',
            svgTagAndSave,
            'dashboard-block-svg',
            'outer-svg-layer',
            140,
            120,
            undefined,
            '/hub/download',
          )} */}

          {/* {dashboardBlockItem(
            'dashboard-block-wrapper',
            'LINKED APPS',
            '— Coming Soon —',
            svgLinkedApps2,
            'dashboard-block-svg',
            'outer-svg-layer',
            120,
            120,
            undefined,
            '/hub/linkedapps',
          )} */}
          {/* {dashboardBlockItem(
            'dashboard-block-wrapper',
            'Community',
            '— Coming Soon —',
            svgDashboardCommunity,
            'dashboard-block-svg',
            'outer-svg-layer',
            239,
            120,
            undefined,
            '/hub/community',
          )} */}
        </div>
      </div>
      <div className="dashboard-bottom-padder"></div>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashBoard),
);
