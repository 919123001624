import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TextBox = styled(TextField)({
  '& label.Mui-focused': {
    color: '#827700',
  },
  '& label': {
    color: '#827700',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#273A07',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#273A07',
    },
    '&:hover fieldset': {
      borderColor: '#273A07',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#273A07',
    },
    '& .MuiInputBase-input': {
      color: '#827700', // replace with your desired text color
    },
    '& .MuiInputLabel-root': {
      color: '#827700', // replace with your desired label color
    },
    '& .MuiInputLabel-shrink': {
      color: '#827700', // also set the color for the "shrink" state
    },
  },
});

export default TextBox;
