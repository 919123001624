import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
  fetchAccountDetails,
  updateAccountDetails,
} from '../../../actions/user_actions.js';
import {
  fetchCountries,
  fetchStates,
  fetchAccountRoles,
} from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { General } from './account_details_modules';
import {
  openInviteNewMemberModal,
  openEditProfileImageModal,
  openAccountExplorerEditModal,
} from '../../../actions/modal_actions';
import { svgGroup } from 'components/svg_module';
import { TextArea } from 'components/global_modal/modal_modules';
import { Headers } from './shared_modules';

import {
  NoAccess,
  UpgradeToFamily,
  MemberSummary,
  AddAndEditAccountMembers,
} from './account_members_modules';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchStates: () => dispatch(fetchStates()),
  fetchCountries: () => dispatch(fetchCountries()),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchAccountRoles: () => dispatch(fetchAccountRoles()),
  openAccountExplorerEditModal: (userInfo) =>
    dispatch(openAccountExplorerEditModal(userInfo)),
  openEditProfileImageModal: (data, originType) =>
    dispatch(openEditProfileImageModal(data, originType)),
  fetchAccountDetails: (accountRid) =>
    dispatch(fetchAccountDetails(accountRid)),
  updateAccountDetails: (data) => dispatch(updateAccountDetails(data)),
  openInviteNewMemberModal: () => dispatch(openInviteNewMemberModal()),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  let accountDetails = entities.users.accountDetails
    ? entities.users.accountDetails
    : null;
  let allAccountExplorers = entities.users.allAccountExplorers
    ? entities.users.allAccountExplorers
    : null;
  let accountRoles = ui.components.accountRoles
    ? ui.components.accountRoles
    : null;
  return {
    userInfo,
    currentExplorerDetails,
    countries,
    statesProvences,
    accountDetails,
    allAccountExplorers,
    accountRoles,
  };
};

const ComingSoonPage = (props) => {
  const accountInfoRef = useRef();
  const {
    title,
    userInfo,
    updateUserInfo,
    fetchAccountExplorer,
    currentExplorerDetails,
    fetchStates,
    fetchCountries,
    statesProvences,
    countries,
    accountDetails,
    openEditProfileImageModal,
    updateAccountDetails,
    fetchAccountDetails,
    allAccountExplorers,
    accountRoles,
    fetchAccountRoles,
    openAccountExplorerEditModal,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    // fetchCountries();
    // fetchStates();
    scrollTop();

    axios
      .patch('/api/ui/fetch_meta_data_new', {
        pageName: 'account',
      })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
    if (!accountDetails) fetchAccountDetails(userInfo.account_rid);
    if (!allAccountExplorers) fetchAccountExplorer(userInfo.account_rid, null);
    if (!currentExplorerDetails)
      fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
  }, []);
  useEffect(() => {
    fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
  }, []);
  const [accountValues, setAccountValues] = useState({});

  // const [objectProfile, setObjectProfile] = useState({
  // })
  useEffect(() => {
    if (!accountInfoRef.current) {
      accountInfoRef.current = accountDetails;
      setAccountValues(accountDetails);
    } else if (
      accountInfoRef.current &&
      accountInfoRef.current !== accountDetails
    ) {
      accountInfoRef.current = accountDetails;
      setAccountValues(accountDetails);
    }
  }, [accountDetails]);
  // useEffect(() => {
  //   let wrapper = document.getElementById(
  //     'hub-page-container-account-responsive',
  //   );
  //   if (wrapper) {
  //     debugger;
  //   }
  //   // .scrollTop(0, 'smooth');
  // }, []);
  const handleChange = useCallback((event) => {
    const { value, name } = event.currentTarget;
    setAccountValues({ ...accountValues, [name]: value });
  });

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  //TAKES IN SECTION DATA AND NAME, COPIES TO FULL OBJ AND SETS SAVED TRUE FOR SECTION
  const handleSave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    accountValues.account_rid = userInfo.account_rid;
    accountInfoRef.current = accountValues;
    updateAccountDetails(accountValues);
  });
  //TAKES IN SETTER STATE FUNCTION CALL TO RESET AND OBJECT TO RESET
  const handleCancel = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setAccountValues(accountInfoRef.current);
  });

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      {accountInfoRef.current && (
        <div
          className="hub-page-container hub-page-container--profile"
          id="hub-page-container-account-responsive"
        >
          <div
            className={`account-page-container  ${
              !isMobile
                ? 'account-pages-browser-settings'
                : 'account-page-container--mobile'
            }`}
            id="account-page-container-responsive"
          >
            <Headers text="ACCOUNT" />
            {/* <div className="account-profile-header-container">
              <div className="account-profile-header">
                <div className="account-profile-image-container">
                  {accountValues.ui_image_location ? (
                    <>
                      <img
                        src={
                          accountValues.ui_image_location
                            ? accountValues.ui_image_location
                            : '/svg_assets/person_light_orange.svg'
                        }
                        className="account-profile-image"
                      />
                    </>
                  ) : (
                    <div className="account-info-svg-wrapper">
                      {svgGroup(
                        '',
                        'account-info-svg-group',
                        'account-info-svg-group-outer',
                      )}
                    </div>
                  )}
                  <button
                    className="account-profile-image-change"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openEditProfileImageModal(null, { isAccount: true });
                    }}
                  >
                    CHANGE
                  </button>
                </div>
                <div className="account-header-username">
                  {accountInfoRef.current.account_name}
                </div>
              </div> */}
              {/* <div className="account-profile-header-description-container">
                <TextArea
                  name="account_about"
                  label="Account description"
                  value={accountValues.account_about}
                  onChangeHandler={handleChange}
                  parentClassName="account-management-description"
                />
              </div> */}
            {/* </div> */}
            {
            // currentExplorerDetails && (
            //   <div className="account-profile-body-container">
            //     <div className="account-profile-body">
            //       <General
            //         onChangeHandler={handleChange}
            //         values={accountValues}
            //         saved={accountInfoRef.current === accountValues}
            //         saveHandler={handleSave}
            //         cancelHandler={handleCancel}
            //       />

            //       {/* <Hobbies accountValues={accountValues}></Hobbies> */}
            //       {/* <Badges accountValues={accountValues}></Badges> */}
            //     </div>
            //   </div>
            // )
            }
            {allAccountExplorers && accountDetails && (
              <AddAndEditAccountMembers {...props} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonPage);
