export function flyFishing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 70.28"
      enableBackground="new 0 0 100 70.28"
      className={outerLayer}
    >
      <path
        fill="#000000"
        className={className}
        d="M99.785,35.214c-0.313-0.287-7.923-6.99-23.896-3.36c-11.349,2.574-17.636,3.803-23.332,3.369
c1.459-0.288,2.957-0.593,4.438-0.897c4.613-0.945,8.972-1.838,11.272-1.96c5.616-0.294,14.021-3.45,15.661-4.229
c0.867-0.412,2.027-0.552,2.875-0.655c0.963-0.116,1.494-0.18,1.729-0.633c0.146-0.276,0.156-0.604,0.027-0.923
c-0.301-0.736-1.332-1.397-3.072-1.965c-2.684-0.87-9.843,0.672-18.928,2.78c-3.705,0.861-6.91,1.861-9.736,2.745
c-3.847,1.202-6.885,2.152-9.33,2.164c-2.146,0.011-8.322,0.572-14.267,1.156c-1.32,0.012-2.585,0.048-3.761,0.096l16.058-11.079
c0.441-0.305,0.557-0.907,0.261-1.352c-0.297-0.445-0.896-0.575-1.348-0.284l-7.346,4.695l14.142-11.873
c0.412-0.346,0.469-0.961,0.125-1.375c-0.229-0.277-0.583-0.396-0.909-0.346l0.81-0.736c0.398-0.361,0.432-0.977,0.074-1.38
c-0.361-0.402-0.974-0.438-1.38-0.085l-2.095,1.832c0.093-0.322,0.018-0.685-0.229-0.947c-0.369-0.387-0.979-0.411-1.377-0.049
L25.755,28.688L38.101,7.787c0.275-0.463,0.125-1.06-0.336-1.34c-0.458-0.277-1.057-0.137-1.343,0.322L26.029,23.445L33.15,6.404
c0.206-0.495-0.023-1.066-0.514-1.279c-0.491-0.216-1.064,0.005-1.288,0.494c-0.693,1.531-1.439,3.178-2.211,4.874
c1.213-3.199,2.41-6.345,3.482-9.163c0.192-0.502-0.057-1.067-0.559-1.263c-0.497-0.194-1.068,0.044-1.269,0.544
c-0.024,0.058-0.536,1.326-1.352,3.336c0.24-0.73,0.379-1.154,0.391-1.187c0.166-0.509-0.104-1.058-0.609-1.234
c-0.505-0.176-1.059,0.084-1.246,0.584c-0.274,0.739-4.762,12.82-8.06,21.966c1.215-9.398,2.712-21.805,2.803-22.566
c0.064-0.528-0.305-1.012-0.833-1.088c-0.521-0.074-1.021,0.281-1.108,0.807l-0.128,0.755c-0.103-0.372-0.419-0.666-0.827-0.714
c-0.528-0.068-1.02,0.305-1.094,0.838c-0.012,0.083-0.393,2.822-0.924,6.665c-0.157-0.16-0.37-0.27-0.613-0.292
c-0.52-0.042-1.001,0.332-1.065,0.86l-0.792,6.601C15.413,9.423,15.48,3.67,15.48,3.574c0-0.542-0.439-0.982-0.981-0.982
c-0.523,0-0.755,0-0.904,0.98h-0.078c0,0.033-0.004,0.314-0.01,0.799c-0.071,0.853-0.129,2.111-0.214,3.972L12.627,22.93
c-0.387-3.068-0.717-5.672-0.772-6.119c-0.001-0.019-0.004-0.036-0.008-0.054l0.001-0.001h-0.001c0-0.001,0-0.003-0.001-0.004
l0.003-0.001c-0.066-0.487-0.483-0.869-0.975-0.869c-0.282,0-0.55,0.12-0.736,0.331c-0.177,0.199-0.257,0.525-0.234,0.796
c0,0.008,0,0.015,0.001,0.02c0.044,0.463,0.218,3.185,0.416,6.368c-1-4.24-1.561-6.587-1.944-7.891
c-0.074-0.389-0.122-0.655-0.142-0.768l-0.102,0.015c-0.317-0.867-0.519-0.867-0.869-0.867c-0.292,0-0.568,0.129-0.754,0.354
c-0.185,0.224-0.256,0.57-0.205,0.857c0.167,0.922,2.28,12.215,3.346,17.906c-1.326,0.158-2.837,0.621-4.044,1.939l-0.283,0.303
c-0.133,0.139-0.262,0.28-0.387,0.422c-0.018,0-0.028-0.008-0.044-0.006c-0.292-0.004-0.593-0.055-0.917-0.12
c-0.505-0.098-1.08-0.209-1.706-0.089c-0.877,0.17-1.833,1.025-2.132,1.904c-0.193,0.572-0.224,1.858,0.169,2.491
c0.471,0.771,1.437,1.185,2.185,1.31c0.03,0.008,0.395,0.068,0.859,0.068c0.424,0,0.93-0.053,1.348-0.246
c0.092,0.15,0.176,0.309,0.287,0.443c0.849,1.042,2.062,1.464,3.177,1.808c-1.299,4.144-3.125,9.9-3.757,11.769
c-0.28,0.725-0.394,1.027-0.098,1.461c0.184,0.266,0.484,0.428,0.808,0.428c0.001,0,0.003,0,0.003,0c0.328,0,0.639-0.167,0.819-0.44
c0.082-0.117,0.12-0.206,0.313-0.778c0.533-1.378,1.876-4.729,3.202-8.014c-0.785,4.394-1.743,9.68-2.511,13.885
c-0.096,0.524,0.242,1.027,0.761,1.137c0.521,0.117,1.033-0.213,1.159-0.732l2.991-12.454c-0.099,3.684-0.189,7.012-0.189,7.116
c0,0.26,0.104,0.513,0.289,0.695c0.184,0.184,0.434,0.286,0.693,0.286c0.001,0,0.002,0,0.002,0c0.418-0.001,0.639-0.003,0.875-0.977
h0.104c0-0.036,0.005-0.237,0.015-0.565c0.232-1.242,0.51-3.489,1.012-7.555l0.237-1.938l3.7,19.807
c0.088,0.473,0.5,0.804,0.964,0.804c0.052,0,0.106-0.006,0.16-0.015c0.527-0.087,0.888-0.579,0.812-1.107
c-0.211-1.493-1.379-9.77-2.364-16.633c1.763,4.77,3.605,9.738,3.75,10.128c0.184,0.497,0.727,0.751,1.23,0.59
c0.502-0.169,0.781-0.704,0.631-1.212l-3.723-12.53l10.854,15.272c0.311,0.434,0.912,0.543,1.353,0.243
c0.443-0.304,0.563-0.901,0.27-1.349l-8.783-13.466l7.284,6.936c0.385,0.365,0.994,0.36,1.368-0.015
c0.319-0.316,0.376-0.798,0.168-1.175l4.792,4.123c0.406,0.351,1.021,0.31,1.375-0.091c0.357-0.4,0.324-1.012-0.07-1.375
c-0.28-0.256-3.521-3.229-7.089-6.485c3.536,2.301,6.633,4.308,6.915,4.49c0.446,0.289,1.04,0.172,1.342-0.266
s0.201-1.033-0.225-1.35c-0.3-0.219-4.585-3.377-8.578-6.258c1.246,0.455,2.165,0.785,2.271,0.823
c0.467,0.164,0.999-0.044,1.218-0.5c0.217-0.457,0.056-1.003-0.376-1.263l-0.561-0.342c0.924-0.134,1.711-0.419,2.516-0.719
c0.931-0.349,1.495-0.798,2.092-1.271c0.163-0.131,0.331-0.262,0.513-0.398c0.309-0.232,0.531-0.519,0.697-0.826l26.872,0.582
c0.01,0,0.181-0.009,0.479-0.009c2.417,0,14.512,0.612,15.387,12.701c0.243,3.333-0.577,6.036-2.433,8.03
c-3.383,3.637-9.365,4.116-12.003,4.14l2.114-2.511c0.206-0.245,0.206-0.602-0.002-0.848c-0.21-0.242-0.565-0.3-0.836-0.135
c-0.035,0.021-3.575,2.139-7.407,3.004c-4.536,1.021-9.7,1.407-10.02,1.411c-0.345,0-0.634,0.271-0.654,0.617
c-0.02,0.346,0.236,0.646,0.577,0.688c0.128,0.015,12.876,1.483,18.961,1.483c0.383,0,0.741-0.006,1.07-0.018
c5.903-0.22,14.639-5.108,14.639-14.903c0-3.229-0.917-13.977-12.693-16.736c-1.438-0.336-3.641-0.625-6.335-0.873
c8.828-1.692,14.65-1.992,20.419-1.883c6.433,0.121,11.719,0.477,11.771,0.482c0.303,0.021,0.537-0.143,0.647-0.398
C100.056,35.698,99.992,35.402,99.785,35.214z"
      ></path>
    </svg>
  );
}