
export const svgWaze = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="122.709"
      height="122.883"
      viewBox="0, 0, 122.709, 122.883"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M55.142,104.213 C59.362,104.213 63.582,104.403 67.802,104.123 C71.642,103.933 75.682,103.563 79.432,102.623 C109.252,95.313 125.192,62.393 112.152,34.553 C104.272,17.763 90.772,8.193 72.302,6.223 C58.142,4.723 45.482,8.943 34.792,18.503 C24.292,27.973 18.852,39.783 18.482,53.943 C18.392,57.223 18.482,60.603 18.482,63.883 C18.382,71.023 14.352,76.553 7.502,78.703 C7.412,78.703 7.222,78.893 7.122,78.893 C9.752,85.833 20.432,96.053 27.092,98.583 C35.452,87.143 52.322,91.183 55.142,104.213 L55.142,104.213 z"
          fill="#FFFFFF"
        />
        <path
          className={className}
          d="M54.952,110.493 C53.922,115.183 51.392,118.653 47.262,120.803 C42.012,123.523 36.662,123.433 31.692,120.243 C26.532,116.963 24.282,111.993 24.662,105.893 C24.752,104.863 24.472,104.483 23.632,104.013 C14.532,99.233 7.322,92.573 2.352,83.573 C1.412,81.793 0.662,79.913 0.192,77.943 C-0.468,75.223 0.572,73.913 3.382,73.633 C6.762,73.253 9.762,71.943 11.262,68.753 C11.922,67.343 12.292,65.663 12.292,64.063 C12.482,60.033 12.292,56.003 12.482,51.963 C13.512,36.393 19.982,23.463 31.802,13.333 C42.672,3.973 55.422,-0.427 69.762,0.033 C94.802,0.973 116.272,18.603 121.332,43.263 C125.922,65.583 118.992,84.243 101.262,98.773 C100.232,99.613 99.102,100.463 97.882,101.213 C97.222,101.683 97.042,102.053 97.322,102.803 C99.662,109.933 96.382,117.803 89.822,121.183 C80.912,125.593 70.602,121.093 67.882,111.523 C67.792,111.143 67.322,110.683 67.042,110.683 C63.112,110.403 59.072,110.493 54.952,110.493 L54.952,110.493 z M55.142,104.213 C59.362,104.213 63.582,104.403 67.802,104.123 C71.642,103.933 75.682,103.563 79.432,102.623 C109.252,95.313 125.192,62.393 112.152,34.553 C104.272,17.763 90.772,8.193 72.302,6.223 C58.142,4.723 45.482,8.943 34.792,18.503 C24.292,27.973 18.852,39.783 18.482,53.943 C18.392,57.223 18.482,60.603 18.482,63.883 C18.382,71.023 14.352,76.553 7.502,78.703 C7.412,78.703 7.222,78.893 7.122,78.893 C9.752,85.833 20.432,96.053 27.092,98.583 C35.452,87.143 52.322,91.183 55.142,104.213 L55.142,104.213 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M74.922,79.743 C63.852,79.183 56.542,74.773 51.852,65.963 C50.722,63.803 51.762,61.653 53.912,61.183 C55.222,60.903 56.442,61.843 57.382,63.343 C58.602,65.223 59.822,67.093 61.412,68.593 C70.222,76.933 84.662,74.313 90.202,63.533 C90.862,62.223 91.702,61.193 93.292,61.193 C95.632,61.283 96.952,63.633 95.922,65.783 C93.012,71.693 88.422,76.003 82.232,78.063 C79.512,78.993 76.702,79.363 74.922,79.743 L74.922,79.743 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M55.322,48.983 C51.942,48.983 49.232,46.263 49.232,42.893 C49.232,39.523 51.952,36.803 55.322,36.803 C58.692,36.803 61.412,39.523 61.412,42.893 C61.422,46.173 58.702,48.983 55.322,48.983 L55.322,48.983 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M98.272,42.793 C98.272,46.173 95.552,48.883 92.272,48.983 C88.892,48.983 86.182,46.353 86.182,42.893 C86.182,39.513 88.812,36.803 92.182,36.703 C95.462,36.703 98.172,39.423 98.272,42.793 L98.272,42.793 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};
