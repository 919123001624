
export const svgCryingFace = (color, className, outerLayer) => {
  return (
    <svg viewBox="0 0 100 125" x="0px" y="0px" className={outerLayer}>
      <path
        className={className}
        d="M50,10.5A39.5,39.5,0,1,1,10.5,50,39.55,39.55,0,0,1,50,10.5m0-5A44.5,44.5,0,1,0,94.5,50,44.5,44.5,0,0,0,50,5.5Z"
      />
      <circle cx="35.17" cy="36.17" r="5" />
      <circle cx="64.83" cy="36.17" r="5" />
      <path
        className={className}
        d="M33.17,53.17a4,4,0,0,1-8,0c0-2.21,4-9,4-9S33.17,51,33.17,53.17Z"
      />
      <path
        className={className}
        d="M50,56.71a17,17,0,0,1,14.71,8.5,2.5,2.5,0,0,1-4.33,2.5,12,12,0,0,0-20.76,0,2.5,2.5,0,0,1-4.33-2.5A17,17,0,0,1,50,56.71Z"
      />
    </svg>
  );
};
