export function tubing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M29.9045,13.0001c-9.3366,0-1.0457,0-10.4052,0v0.0658c-6.9585,0.8124-9.763,6.6971-9.0767,14.1567   c0.05,0.59,0.35,1.1,0.8,1.42c-0.03-0.18-0.04-0.36-0.04-0.55c0-1.94,1.35-3.56,3.16-3.98   c0.1834-3.6216,1.6361-6.3661,5.1567-7.007v7.8945h-4.2188c-1.7087,0-3.0938,1.3851-3.0938,3.0938s1.3851,3.0938,3.0938,3.0938   h4.2659v15.771c0,1.1045,0.8955,2,2,2s2-0.8955,2-2v-15.771h2.9375v15.771c0,1.1045,0.8955,2,2,2s2-0.8955,2-2v-15.771h4.2341   c1.7086,0,3.0938-1.3851,3.0938-3.0938s-1.3851-3.0938-3.0938-3.0938h-4.2188v-8.0015c3.6927,0.1923,5.036,3.4498,5.1633,7.114   c1.8,0.43,3.15,2.05,3.15,3.98c0,0.18-0.01,0.36-0.04,0.54c0.44-0.31,0.74-0.82,0.8-1.41   C40.4971,16.8955,35.1813,12.6791,29.9045,13.0001z"
        ></path>
        <circle cx="24.9992" cy="5.5417" r="4.5"></circle>
      </g>
    </svg>
  );
}