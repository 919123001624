export const svgStart = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      width="100px"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 68"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <path
        fill={color}
        className={className}
        d="M91.2803688,243.536443 C90.5386769,244.154519 89.4613231,244.154519 88.7196312,243.536443 C72.3051626,229.857719 64,216.015781 64,202 C64,187.640597 75.6405965,176 90,176 C104.359403,176 116,187.640597 116,202 C116,216.015781 107.694837,229.857719 91.2803688,243.536443 Z M83,212 C83,213.564641 84.715655,214.523341 86.0481949,213.703317 L99.0481949,205.703317 C100.317268,204.922348 100.317268,203.077652 99.0481949,202.296683 L86.0481949,194.296683 C84.715655,193.476659 83,194.435359 83,196 L83,212 Z M87,208.420871 L87,199.579129 L94.1839156,204 L87,208.420871 Z"
        transform="translate(-64 -176)"
      ></path>
    </svg>
  );
};