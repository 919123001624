export const svgInstagram = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        className={className}
        id="Icon_simple-instagram"
        data-name="Icon simple-instagram"
        d="M18,0c-4.89,0-5.5.023-7.42.108A13.273,13.273,0,0,0,6.21.945,8.815,8.815,0,0,0,3.021,3.021,8.783,8.783,0,0,0,.945,6.21a13.234,13.234,0,0,0-.837,4.37C.018,12.5,0,13.11,0,18s.023,5.5.108,7.42a13.281,13.281,0,0,0,.837,4.37,8.827,8.827,0,0,0,2.076,3.189A8.8,8.8,0,0,0,6.21,35.055a13.289,13.289,0,0,0,4.37.837c1.92.09,2.53.108,7.42.108s5.5-.022,7.42-.108a13.32,13.32,0,0,0,4.37-.837,9.2,9.2,0,0,0,5.265-5.265,13.281,13.281,0,0,0,.837-4.369C35.982,23.5,36,22.89,36,18s-.022-5.5-.108-7.42a13.312,13.312,0,0,0-.837-4.37,8.834,8.834,0,0,0-2.076-3.189A8.77,8.77,0,0,0,29.79.945,13.242,13.242,0,0,0,25.421.108C23.5.018,22.89,0,18,0Zm0,3.24c4.8,0,5.378.024,7.275.106a9.917,9.917,0,0,1,3.34.622,5.923,5.923,0,0,1,3.417,3.415,9.938,9.938,0,0,1,.619,3.34c.085,1.9.1,2.469.1,7.275s-.022,5.378-.111,7.275a10.13,10.13,0,0,1-.632,3.34,5.715,5.715,0,0,1-1.348,2.073,5.616,5.616,0,0,1-2.07,1.344,10.011,10.011,0,0,1-3.353.619c-1.911.085-2.473.1-7.288.1s-5.379-.022-7.288-.111a10.207,10.207,0,0,1-3.354-.632,5.574,5.574,0,0,1-2.069-1.348,5.466,5.466,0,0,1-1.35-2.07,10.215,10.215,0,0,1-.63-3.353c-.068-1.89-.092-2.473-.092-7.266s.024-5.379.092-7.292a10.2,10.2,0,0,1,.63-3.351,5.336,5.336,0,0,1,1.35-2.071A5.324,5.324,0,0,1,7.313,3.917a9.963,9.963,0,0,1,3.332-.632c1.913-.068,2.475-.09,7.288-.09L18,3.24Zm0,5.517A9.243,9.243,0,1,0,27.243,18,9.242,9.242,0,0,0,18,8.757ZM18,24a6,6,0,1,1,6-6A6,6,0,0,1,18,24ZM29.769,8.392a2.16,2.16,0,1,1-2.16-2.158A2.162,2.162,0,0,1,29.769,8.392Z"
        fill={color}
      />
    </svg>
  );
};