import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
const PayPalButton = ({
  createSubscription,
  onApprove,
  isUpdate,
  isCancel,
}) => {
  const paypal = useRef();
  const buttonRef = useRef(null);
  useEffect(() => {
    if (paypal.current.innerHTML === '') {
      buttonRef.current = window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe',
        },
        createSubscription,
        onApprove,
      });
      buttonRef.current.render(paypal.current);
    } else {
      buttonRef.current.updateProps({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe',
        },
        createSubscription,
        onApprove,
      });
    }
  }, [createSubscription]);
  return (
    <div
      id="paypal-button-container-P-56K248403J703224GMFFGAEQ"
      ref={paypal}
    ></div>
  );
};

export default PayPalButton;
