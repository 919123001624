import {
  deleteNotification,
  fetchUserIncomingNotification,
  updateNotifications,
} from 'actions/social_actions';
import { svgClose2 } from 'components/svg_module';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserIncomingNotification: (explorer_rid) =>
      dispatch(fetchUserIncomingNotification(explorer_rid)),
    updateNotifications: (data) => dispatch(updateNotifications(data)),
    deleteNotification: (data, userInfo) =>
      dispatch(deleteNotification(data, userInfo)),
  };
};

const mapStateToProps = ({ entities, session }) => {
  const userInfo =
    session.user && session.user.userInfo ? session.user.userInfo[0] : null;
  return { userInfo };
};

const NotificationCard = ({
  notification,
  fetchUserIncomingNotification,
  updateNotifications,
  deleteNotification,
  history,
  userInfo,
  ...props
}) => {
  const {
    ui_notification_location,
    read_flag,
    sender_first_name,
    sender_last_name,
    sender_ui_image_location,
  } = notification;

  const renderFriendRequest = () => {
    const {
      sender_first_name,
      sender_last_name,
      sender_ui_image_location,
    } = notification;
    return (
      <div
        className="notification-friend-request-wrapper"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // history.push(ui_notification_location.action.clickToLoad);
        }}
      >
        {/* <div className="notification-friend-request-left">
          <img
            src={
              sender_ui_image_location
                ? sender_ui_image_location
                : '/svg_assets/person_light_orange.svg'
            }
            className="notificaiont-friend-request-profile-pic"
            alt="no-profile-pic"
          />
        </div> */}
        <div className="notification-friend-request-right">
          <div className="notification-friend-request-rt-context-wrapper">
            <span>
              You have been invited to connect by{' '}
              <span className="friend-request-name">
                {sender_first_name} {sender_last_name}
              </span>
            </span>
          </div>
          <div className="notification-friend-request-rt-buttons-wrapper">
            {/* <button className="notification-friend-request-button--decline">
              Decline
            </button> */}
            {/* <button
              className="notification-friend-request-button--accept"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push('/hub/community');
              }}
            >
              View
            </button> */}
          </div>
        </div>
      </div>
    );
  };
  const renderAcceptedFriendRequest = () => {
    const {
      sender_first_name,
      sender_last_name,
      sender_ui_image_location,
    } = notification;
    return (
      <div
        className="notification-friend-request-wrapper"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // history.push(ui_notification_location.action.clickToLoad);
        }}
      >
        {/* <div className="notification-friend-request-left">
          <img
            src={
              sender_ui_image_location
                ? sender_ui_image_location
                : '/svg_assets/person_light_orange.svg'
            }
            className="notificaiont-friend-request-profile-pic"
            alt="no-profile-pic"
          />
        </div> */}
        <div className="notification-friend-request-right">
          <div className="notification-friend-request-rt-context-wrapper">
            <span>
              <span className="friend-request-name">
                {sender_first_name} {sender_last_name}{' '}
              </span>
              has accepted your friend request.
            </span>
          </div>
          <div className="notification-friend-request-rt-buttons-wrapper">
            {/* <button className="notification-friend-request-button--decline">
              Decline
            </button> */}
            {/* <button
              className="notification-friend-request-button--accept"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push('/hub/community');
              }}
            >
              View
            </button> */}
          </div>
        </div>
      </div>
    );
  };
  const renderAdventureInvite = () => {
    const {
      sender_first_name,
      sender_last_name,
      sender_ui_image_location,
      ui_notification_location,
    } = notification;
    const { badgePayload } = ui_notification_location;
    let imageUrl = ui_notification_location.badgePayload.adventureImg
      ? ui_notification_location.badgePayload.adventureImg
      : '';

    return (
      <div
        className={`notification-friend-request-wrapper ${
          imageUrl && 'adventure-invite-notification-card-wrapper'
        }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // history.push(ui_notification_location.action.clickToLoad);
        }}
        // style={{
        //   // backgroundImage: `url(${imageUrl})`,
        //   // backgroundSize: 'cover',
        //   backgroundColor: '#B0cdc9'
        // }}
      >
        {/* {imageUrl && <div className="notification-card-opacity-layer" />} */}
        {/* <div className="notification-friend-request-left">
          <img
            src={
              sender_ui_image_location
                ? sender_ui_image_location
                : '/svg_assets/person_light_orange.svg'
            }
            className="notificaiont-friend-request-profile-pic"
            alt="no-profile-pic"
          />
        </div> */}
        <div className="notification-friend-request-right">
          <div className="notification-friend-request-rt-context-wrapper">
            <span>
              You have been invited to join '
              {badgePayload.tripData.adventure_name}'
              <span className="friend-request-name">
                by {sender_first_name} {sender_last_name}
              </span>
            </span>
          </div>
          <div className="notification-friend-request-rt-buttons-wrapper">
            {/* <button className="notification-friend-request-button--decline">
              Decline
            </button> */}
            {/* <button
              className="notification-friend-request-button--accept"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push('/hub/community');
              }}
            >
              View
            </button> */}
          </div>
        </div>
      </div>
    );
  };
  const renderAcceptedAdventureInvite = () => {
    const {
      sender_first_name,
      sender_last_name,
      sender_ui_image_location,
      ui_notification_location,
    } = notification;
    const { badgePayload } = ui_notification_location;
    let imageUrl = ui_notification_location.badgePayload.adventureImg
      ? ui_notification_location.badgePayload.adventureImg
      : '';
    return (
      <div
        className="notification-friend-request-wrapper"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // history.push(ui_notification_location.action.clickToLoad);
        }}
      >
        {/* {imageUrl && <div className="notification-card-opacity-layer" />} */}

        {/* <div className="notification-friend-request-left">
          <img
            src={
              sender_ui_image_location
                ? sender_ui_image_location
                : '/svg_assets/person_light_orange.svg'
            }
            className="notificaiont-friend-request-profile-pic"
            alt="no-profile-pic"
          />
        </div> */}
        <div className="notification-friend-request-right">
          <div className="notification-friend-request-rt-context-wrapper">
            <span>
              <span className="friend-request-name">
                {sender_first_name} {sender_last_name}{' '}
              </span>
              has joined your {badgePayload.tripData.adventure_name}'
            </span>
          </div>
          <div className="notification-friend-request-rt-buttons-wrapper">
            {/* <button className="notification-friend-request-button--decline">
              Decline
            </button> */}
            {/* <button
              className="notification-friend-request-button--accept"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push('/hub/community');
              }}
            >
              View
            </button> */}
          </div>
        </div>
      </div>
    );
  };

  const deleteHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = { ...notification, read_flag: true };
    updateNotifications(data).then(() => {
      fetchUserIncomingNotification(userInfo.explorer_rid);
    });
  });
  return (
    <div className="friend-block-list-wrapper" id="friend-block-list-mobile">
      <div className="friend-block-list-container">
        <div className="friend-block-list-contents-wrapper">
          <div className="friend-block-list-image-wrapper">
            <img
              src={sender_ui_image_location || '/logos_v2/lodestar-mark.svg'}
              className="friend-block-list-content-profile-pic"
              alt="no profile"
            />
          </div>
        </div>
        <div className="friend-block-list-right-content-wrapper">
          <div className="friend-block-list-contact-wrapper message-block-list-contact-wrapper">
            <div className="friend-block-listcontact--top">
              <span>{sender_first_name}</span>
              <span>{sender_last_name}</span>
            </div>
            <div className="friend-block-listcontact--bottom">
              {ui_notification_location.type === 'friendRequest' &&
                renderFriendRequest()}
              {ui_notification_location.type === 'adventureInviteRequest' &&
                renderAdventureInvite()}
              {ui_notification_location.type === 'acceptFriendRequest' &&
                renderAcceptedFriendRequest()}
              {ui_notification_location.type ===
                'acceptAdventureInviteRequest' &&
                renderAcceptedAdventureInvite()}
            </div>
          </div>
        </div>
        <button onClick={deleteHandler} className="delete-notification-button">
          {svgClose2(
            '',
            'delete-notification-svg',
            'delete-notification-svg-outer',
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NotificationCard));
