export function flipFlop (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;"></path>
        <g>
          <path
            fill={color}
            className={className}
            d="M30.33912,28.00284c1.92792-11.56061,0.65442-24.24646-10.98865-23.50415   C5.77937,5.38138-0.05437,19.10184,3.52028,31.65708c1.64264,5.77588,4.65057,8.08478,5.51685,19.00397   c1.04431,12.97168,15.27441,19.13422,21.44867,8.70532c2.16632-3.6543,1.05933-7.53815-0.1853-11.84772   C29.15693,43.57431,27.99965,42.13761,30.33912,28.00284z M24.21388,59.78653c-0.04492,0.03223-0.09375,0.05859-0.14551,0.08008   c-3.78487,1.54799-6.30578-0.28076-8.74609-3.69336c-0.24023-0.33691-0.16309-0.80518,0.17383-1.0459   c0.33984-0.2417,0.80664-0.16211,1.04686,0.17383c2.03452,2.84532,3.92143,4.38272,6.88087,3.20801   c3.24121-2.29004,2.99219-3.62256,1.32129-9.38916c-0.11523-0.39746,0.11426-0.81348,0.51172-0.92871   c0.40625-0.11816,0.81445,0.11426,0.92969,0.51172C27.92286,54.69718,28.31544,56.91642,24.21388,59.78653z M27.81569,35.22886   c-0.62762-0.1825-1.15118-0.66669-1.36047-1.33789c-1.82001-5.92999-4.33002-9.40997-6.87-9.40997h-0.04999   c-3.16003,0.06-7.66003,5.40997-10.23004,15.25c-0.1062,0.41223-0.34222,0.75079-0.64404,1.00647   c-0.68231-2.12091-1.44824-3.89728-2.16321-5.55524C9.0392,27.59385,12.88258,22.42051,17.08522,20.921v-4.67004   c0-2.68671,4-2.59534,4,0v4.39001c2.46576,0.53766,5.28314,2.54248,7.64899,7.88171   C28.39851,30.60154,28.04652,33.10129,27.81569,35.22886z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M66.33107,22.49894C64.24355,3.86454,47.29689,1.11796,41.41896,7.38859   c-5.18158,5.53149-3.59906,15.75446-2.33167,24.37518c0.68561,4.66681,1.27838,8.86584,0.6532,11.59491   c-1.83002,8.12-5.84003,14.31,2.90997,20.20001c0.03003,0.01996,0.07001,0.03998,0.11005,0.06   c5.03821,2.27863,9.69336,0.49921,12.88452-3.04022c2.38391-2.64172,3.95319-6.26294,4.24951-9.91693   c0.65649-8.30316,2.83093-12.40509,4.50934-16.22974C66.11629,30.53153,66.8885,27.45334,66.33107,22.49894z M53.60939,56.17423   c-2.43899,3.41114-4.97742,5.23521-8.74609,3.69385c-0.05176-0.021-0.10059-0.04785-0.14551-0.08008   c-4.10156-2.87012-3.70898-5.08936-1.97266-11.0835c0.11621-0.39648,0.52734-0.62793,0.92969-0.51172   c0.39746,0.11523,0.62695,0.53125,0.51172,0.92871c-1.6709,5.7666-1.91992,7.09912,1.32129,9.38916   c3.03163,1.20337,4.87721-0.40595,6.88086-3.2085c0.24023-0.33691,0.70703-0.4165,1.04688-0.17383   C53.77247,55.36905,53.84962,55.83731,53.60939,56.17423z M60.27528,40.74302c-0.30426-0.2561-0.54236-0.59619-0.64911-1.01074   c-2.57001-9.84002-7.07001-15.19-10.23004-15.25h-0.04999c-2.53998,0-5.04999,3.47998-6.87,9.40997   c-0.20905,0.67047-0.73181,1.15417-1.35846,1.33716c-0.26782-2.10498-0.62878-4.34088-0.9632-6.61255   c2.37457-5.41119,5.21118-7.43372,7.69165-7.97461v-4.39001c0-0.54999,0.22003-1.04999,0.59003-1.40997   c0.35999-0.35999,0.85999-0.59003,1.40997-0.59003c1.10004,0,2,0.90002,2,2v4.67004   c4.20447,1.50018,8.04926,6.67749,10.59052,14.27118C61.72248,36.85392,60.95716,38.62949,60.27528,40.74302z"
          ></path>
        </g>
      </g>
    </svg>
  );
}