import React, { useEffect, useState } from 'react';

const ImageCard = ({ image, onClick }) => {

  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      // Image loaded successfully
    };
    img.onerror = () => {
      // Image failed to load
      setImageError(true);
    };
    img.src = image;
  }, [image]);

  if (imageError) {
    return null; // Hide the img tag if there was an error loading the image
  }

  return (
    <>
      <img
        src={image}
        width="100%"
        style={{ objectFit: 'cover', cursor: 'zoom-in' }}
        onClick={onClick}
        loading='lazy'
      />
    </>
  );
};

export default ImageCard;
