import React, { useState, useCallback, useEffect, createRef } from 'react';
import { merge, set } from 'lodash';
import { Box } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { Input, TextArea } from '../../modal_modules';
import { DateSelectPicker, DropDownSelect } from '../../modal_modules';
import CurrencyFormat from 'components/ui_library/currency_format';
import TextBox from '../../../common/text-box';

import moment from 'moment';
import { svgClose2 } from 'components/svg_module';
import { useQueryClient } from '@tanstack/react-query';
const ListAddForm = ({
  currentBlock,
  upsertBlock,
  userInfo,
  actionPayload,
  cancelButtonHandler,
  parentItemRid,
  newBlock,
  fetchListDropDown,
  selectedItem,
}) => {
  const queryClient = useQueryClient();
  const scrollerRef = createRef(null);
  const listScrollerRef = createRef(null);
  const [page, setPage] = useState(1);
  const [listInput, setListInput] = useState({
    block_name: '',
    block_description: '',
    block_count: '',
    block_cost: '',
    parent_block_rid: parentItemRid
      ? parentItemRid
      : currentBlock
      ? currentBlock.block_rid
      : newBlock.block_rid,
    priority_rid: null,
    list_flag: true,
    start_date: null,
    end_date: null,
    display_start_date: null,
    display_end_date: null,
  });

  useEffect(() => {
    if (parentItemRid && selectedItem) {
      setListInput({
        ...listInput,
        ...selectedItem,
        block_cost:
          typeof selectedItem.block_cost === 'string'
            ? parseInt(selectedItem.block_cost.split('$').join(''))
            : selectedItem.block_cost,
      });
    } else if (parentItemRid && !selectedItem) {
      setListInput({ ...listInput, parent_block_rid: parentItemRid });
    }
  }, [parentItemRid, selectedItem]);

  const handleChildrenDropdownSelect = useCallback((event) => {
    if (event.length > 0) {
      setListInput({
        ...listInput,
        parent_block_rid: event[0].block_rid,
      });
    } else {
      setListInput({ ...listInput, parent_block_rid: null });
    }
  });

  const handleOnChange = useCallback((event) => {
    if (event.target) {
      const { name, value } = event.target;

      setListInput({ ...listInput, [name]: value });
    } else {
      setListInput({
        ...listInput,
        [event.formattedValue.includes('$')
          ? 'block_cost'
          : 'block_count']: event.value,
      });
    }
  });
  const handleOnChangeDate = useCallback((date) => {
    let dateFormatted = moment(date).format('MM-DD-yyyy');
    setListInput({
      ...listInput,
      start_date: dateFormatted,
      end_date: dateFormatted,
      display_start_date: date,
      display_end_date: date,
    });
  });
  const handleSubmit = useCallback((event) => {
    queryClient.invalidateQueries({
      queryKey: ['adventure', inputValues.adventure_rid, 'blocks'],
    });
    queryClient.invalidateQueries({
      queryKey: ['itinerary'],
    });
    event.preventDefault();
    event.stopPropagation();
    let blockType = {
      isListBlock: true,
    };
    let returnActionPayload = {
      parentBlockRid: currentBlock
        ? currentBlock.block_rid
        : newBlock.block_rid,
      adventureBlockRid: currentBlock
        ? currentBlock.adventure_block_rid
        : newBlock.adventure_block_rid,
    };
    let passOver = merge({}, listInput);
    if (passOver.block_count === '') passOver.block_count = null;
    if (passOver.block_cost === '') passOver.block_cost = null;
    upsertBlock(userInfo, passOver, blockType, returnActionPayload);
    setListInput({
      block_name: '',
      block_description: '',
      block_count: '',
      block_cost: '',
      parent_block_rid: currentBlock
        ? currentBlock.block_rid
        : newBlock.block_rid,
      priority_rid: null,
      list_flag: true,
    });
    cancelButtonHandler();
  });

  const priorityClickHandler = (value) => {
    setListInput({
      ...listInput,
      priority_rid: listInput.priority_rid === value ? null : value,
    });
  };
  const scrollHandler = useCallback((event) => {
    let maxNumb = 3;
    if (event.deltaY > 60 && page < maxNumb) {
      event.preventDefault();
      event.stopPropagation();
      // document.getElementById(`page-${page + 1}`).scrollIntoView();
      setPage(page + 1);
    } else if (event.deltaY < -60 && page > 1) {
      event.preventDefault();
      event.stopPropagation();
      // document.getElementById(`page-${page - 1}`).scrollIntoView();
      setPage(page - 1);
    }
  });
  return (
    <form
      className="modal-list-input-form--wrapper "
      ref={scrollerRef}
      // onWheel={scrollHandler}
      onWheelCapture={scrollHandler}
      onSubmit={handleSubmit}
    >
      <section className="modal-list-input-section-wrapper ">
        <div className="list-form-top-section">
          <button
            className="list-form-close-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              cancelButtonHandler();
            }}
          >
            {svgClose2('', 'list-form-close-svg', 'list-form-close-svg-outer')}
          </button>
          <span>List Item</span>
        </div>
        {/* <hr className="list-form-divider" /> */}
        <section id="page-1-listform" className="list-form-content-sections">
          <div className="modal-list-input--container modal-list-input--container--top">
            <TextBox
              className="text-field"
              name="block_name"
              label="Name*"
              variant="outlined"
              fullWidth
              onChange={handleOnChange}
              value={listInput.block_name}
              size="small"
            />
            {/* <Input
              className="modal-list-input-form--inputs modal-list-input-form--inputs--block_name"
              value={listInput.block_name}
              name="block_name"
              label="Name"
              onChangeHandler={handleOnChange}
              // placeHolder="Name"
              type="text"
            /> */}
          </div>
          <div className="modal-list-input--container modal-list-input--container--middle">
            {/* <TextArea
              className="modal-list-input-form--inputs modal-list-input-form--inputs--block_description"
              value={listInput.block_description}
              name="block_description"
              onChangeHandler={handleOnChange}
              label="Description"
              // placeHolder="Description"
              type="text"
            /> */}
            <TextBox
              name="block_description"
              label="Description"
              variant="outlined"
              multiline
              maxRows={4}
              onChange={handleOnChange}
              value={listInput.block_description}
              size="small"
            />
          </div>
          <div className="modal-list-input--container modal-list-input--container--middle">
            {/* <Input
              customParentClassName=" modal-list-input-form--inputs--block_cost"
              value={listInput.block_cost && listInput.block_cost}
              name="block_cost"
              label="Cost"
              onChangeHandler={handleOnChange}
              // placeHolder="Name"
              type="money"
              // step={0.01}
              // min={0.01}
              // spacingTop="10px"
              defaultActive
            /> */}
            <TextBox
              className="text-field"
              name="block_cost"
              label="Cost"
              variant="outlined"
              fullWidth
              onChange={handleOnChange}
              value={listInput.block_cost}
              size="small"
              type="number"
            />
          </div>
          <div className="modal-list-input--container modal-list-input--container--middle">
            {/* <Input
              customParentClassName=" modal-list-input-form--inputs--block_count"
              value={listInput.block_count && listInput.block_count}
              name="block_count"
              label="Quantity"
              onChangeHandler={handleOnChange}
              // placeHolder="Name"
              type="formattedNumber"
              // spacingTop="10px"
              defaultActive
            /> */}
            <TextBox
              className="text-field"
              name="block_count"
              label="Quantity"
              variant="outlined"
              fullWidth
              onChange={handleOnChange}
              value={listInput.block_count}
              size="small"
              type="number"
            />
          </div>
          <div className="modal-list-input--container modal-list-input--container--middle">
            <Box style={{ padding: '8.5px 14px', color: '#273A07' }}>
              <Typography sx={{ fontSize: '0.75rem' }}>Total</Typography>
              <Typography>
                $
                {Number.isNaN(
                  parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count),
                )
                  ? 0
                  : parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count)}
              </Typography>
            </Box>
            {/* <span className="modal-list-input-form--indicators modal-list-input-form--indicators--total">
              Total:
            </span> */}
            {/* {" "}
              {Number.isNaN(
                parseInt(listInput.block_cost) * parseInt(listInput.block_count)
              )
                ? ""
                : parseInt(listInput.block_cost) *
                  parseInt(listInput.block_count)} */}
            {/* <TextBox
              className="text-field"
              label="Total"
              variant="outlined"
              fullWidth
              defaultValue={
                Number.isNaN(
                  parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count),
                )
                  ? 0
                  : parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count)
              }
              size="small"
              InputProps={{
                readOnly: true,
              }}
            /> */}
            {/* <CurrencyFormat
              thousandSeparator={true}
              prefix={'$'}
              value={
                Number.isNaN(
                  parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count),
                )
                  ? 0
                  : parseFloat(listInput.block_cost) *
                    parseInt(listInput.block_count)
              }
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType="text"
            /> */}
          </div>
        </section>
        {/* <section id="page-2-listform">
          <div className="block-modal-list--right">
            <span className="list-form-priority-text">Select Priority:</span>

            <div
              value={"3"}
              className={`block-modal-list-scheduling-container ${
                listInput.priority_rid !== "3"
                  ? "modal-list-priority-button"
                  : "modal-list-priority-button modal-list-priority-button--selected"
              }`}
              onClick={() => priorityClickHandler("3")}
            >
              <div className="block-list block-list--high" />
            </div>
            <div
              value={"2"}
              className={`block-modal-list-scheduling-container ${
                listInput.priority_rid !== "2"
                  ? "modal-list-priority-button"
                  : "modal-list-priority-button modal-list-priority-button--selected"
              }`}
              onClick={() => priorityClickHandler("2")}
            >
              <div className="block-list block-list--medium" />
            </div>
            <div
              value={"3"}
              className={`block-modal-list-scheduling-container ${
                listInput.priority_rid !== "1"
                  ? "modal-list-priority-button"
                  : "modal-list-priority-button modal-list-priority-button--selected"
              }`}
              onClick={() => priorityClickHandler("1")}
            >
              <div className="block-list block-list--low" />
            </div>
          </div>
          <div className="modal-list-input--container modal-list-input--container--due-date">
            {" "}
            <span className="modal-list-input-form--indicators">Due date:</span>
            <DateSelectPicker
              value={listInput.display_start_date}
              onDateChange={handleOnChangeDate}
              name="start_date"
              showLabels={false}
            />
          </div>
          <div className="modal-list-input--container modal-list-input--container--assignment">
            {" "}
            <span className="modal-list-input-form--indicators">
              Assign to: (coming soon...)
            </span>
          </div>
        </section> */}
        {/* <section id="page-3-listform" className="list-form-content-sections">
        </section> */}
      </section>
      <div className="block-modal-list-input-form-scheduling">
        <div className="modal-list-input-form-top-buttons-container">
          <button
            type="submit"
            className="modal-list-input-form--buttons--save modal-list-input-form--buttons"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ListAddForm;
