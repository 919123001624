
export const svgAccountMenuAccount = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="26"
      height="26"
      viewBox="0, 0, 26, 26"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M5,23 C5,23.079 5.01,23.156 5.012,23.234 C9.699,26.922 16.301,26.922 20.988,23.234 C20.988,23.156 21,23.079 21,23 C21,18.582 17.418,15 13,15 C8.582,15 5,18.582 5,23 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M13,0 C7.925,-0.007 3.31,2.945 1.189,7.556 C-0.933,12.167 -0.173,17.592 3.134,21.442 C3.705,17.862 6.167,14.869 9.57,13.619 C8.08,12.213 7.597,10.04 8.354,8.136 C9.11,6.232 10.952,4.981 13.001,4.981 C15.05,4.981 16.891,6.232 17.648,8.136 C18.404,10.04 17.922,12.213 16.431,13.619 C19.834,14.87 22.295,17.862 22.866,21.442 C26.173,17.592 26.933,12.167 24.811,7.556 C22.69,2.945 18.076,-0.007 13,0 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M16,10 C16,11.657 14.657,13 13,13 C11.343,13 10,11.657 10,10 C10,8.343 11.343,7 13,7 C14.657,7 16,8.343 16,10 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};
