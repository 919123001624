"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spreadDateToObject = void 0;
/**
 * Parse date object into day, month, year state
 */
var spreadDateToObject = function (dateValue) {
    return {
        day: dateValue ? "" + dateValue.getDate() : '',
        month: dateValue ? "" + (dateValue.getMonth() + 1) : '',
        year: dateValue ? "" + dateValue.getFullYear() : '',
    };
};
exports.spreadDateToObject = spreadDateToObject;
