import React from 'react';
import { Provider } from 'react-redux';
import App from './app';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';

//ReactGA.initialize('G-7E4YVC2FQB');
const Root = ({ store }) => {
  const callback = (list) => {
    list.getEntries().forEach(() => {
      ReactGA.timing({
        category: 'Load Performace',
        variable: 'Some metric',
        value: 'Value of Metric',
      });
    });
  };

  var observer = new PerformanceObserver(callback);
  observer.observe({ entryTypes: ['navigation'] });
  ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Time to Interactive',
      value: tti,
    });
  });
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Router>
        <App />
      </Router>
      {/* </PersistGate> */}
    </Provider>
  );
};

export default Root;
