export function motorcycle (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 13.229166 13.229167"
      version="1.1"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g transform="translate(0,-283.77082)">
        <g transform="matrix(1.9429503,0,0,1.9429503,-5.3192746,-256.04854)">
          <path
            className={className}
            transform="matrix(0.26458333,0,0,0.26458333,0,283.77082)"
            d="m 23.748047,-16.599609 a 0.50004997,0.50004997 0 0 0 -0.03711,0.996093 l 2.125,0.285157 0.0098,0.01367 a 0.50004997,0.50004997 0 0 0 0,0.002 0.50004997,0.50004997 0 0 0 0.01953,0.03125 l 3.396485,5.115234 c -0.665426,0.6886921 -1.080085,1.6235717 -1.080078,2.6523438 1.1e-5,2.1051212 1.719092,3.8222278 3.824218,3.8222656 2.105127,-3.78e-5 3.824208,-1.7171444 3.824219,-3.8222656 1.3e-5,-2.1051591 -1.719075,-3.8242188 -3.824219,-3.8242188 -0.715006,0 -1.377528,0.210353 -1.951171,0.554687 l -1.185547,-1.785156 c 0.316127,-0.261067 0.677841,-0.687756 0.636718,-1.345703 -0.02702,-0.432642 -0.127903,-0.879257 -0.273437,-1.269531 -0.145535,-0.390312 -0.292228,-0.724064 -0.634766,-0.96875 -0.306448,-0.218835 -0.517635,-0.169668 -0.759765,-0.16211 -0.242132,0.0076 -0.501021,0.04197 -0.746094,0.08203 -0.2843,0.04626 -0.36459,0.06968 -0.539063,0.107422 a 0.50004997,0.50004997 0 0 0 -0.332031,-0.160157 l -2.376953,-0.316406 a 0.50004997,0.50004997 0 0 0 -0.0957,-0.0078 z m 4.119141,1.291015 c 0.07885,-0.0018 0.141568,0.01685 0.177734,0.02539 0.03612,0.05226 0.157899,0.216056 0.25,0.462891 0.110521,0.296313 0.19533,0.670157 0.214844,0.982421 0.01055,0.168945 -0.121355,0.192816 -0.244141,0.316407 l -1.144531,-1.681641 c 0.06631,-0.01134 0.0635,-0.01991 0.132812,-0.03125 0.220475,-0.03402 0.445146,-0.06893 0.613282,-0.07422 z m 4.138671,5.109375 c 1.492359,0 2.68751,1.2029285 2.6875,2.6953128 -8e-6,1.4923464 -1.195154,2.6855468 -2.6875,2.6855468 -1.492345,0 -2.695304,-1.1932004 -2.695312,-2.6855468 -4e-6,-0.6383661 0.22914,-1.2169781 0.597656,-1.6777344 l 1.613281,2.4296875 a 0.50058832,0.50058832 0 1 0 0.833985,-0.5527344 l -1.679688,-2.5332031 c 0.393215,-0.2239054 0.843568,-0.3613284 1.330078,-0.3613284 z"
          ></path>
          <path
            className={className}
            transform="matrix(0.26458333,0,0,0.26458333,0,283.77082)"
            d="m 13.884766,-11.328125 c -1.887658,0 -3.431641,1.5361431 -3.431641,3.4238281 0,1.8876473 1.543983,3.4335938 3.431641,3.4335938 1.887657,0 3.425781,-1.5459465 3.425781,-3.4335938 0,-0.4381256 -0.08943,-0.8527275 -0.240235,-1.2382812 L 17.6875,-9.4765625 a 0.50004998,0.50004998 0 0 0 -0.302734,-0.9374995 0.50004998,0.50004998 0 0 0 -0.177735,0.06055 l -0.638672,0.345704 c -0.627832,-0.798137 -1.59245,-1.320313 -2.683593,-1.320313 z m 0,1.011719 c 0.712953,0 1.34225,0.311134 1.78125,0.7988279 l -2.408204,1.3046875 a 0.50047049,0.50047049 0 1 0 0.480469,0.8769531 l 2.425781,-1.3144531 c 0.07632,0.2363425 0.126954,0.4833596 0.126954,0.7460937 0,1.3381796 -1.06807,2.4140625 -2.40625,2.4140625 -1.338181,0 -2.414062,-1.0758829 -2.414063,-2.4140625 0,-1.3381795 1.075882,-2.4121091 2.414063,-2.4121091 z"
          ></path>
          <path
            className={className}
            d="m 4.4220399,282.02171 c 0.7502226,0.10879 1.5555928,0.28143 2.3095355,0.26938 0.3978099,0.0165 0.4914804,-0.0526 0.5731751,-0.35861 0.019228,-0.0988 0.03945,-0.22576 0.06121,-0.39113 0.061588,-0.46807 -0.073906,-1.2564 -0.1601296,-1.36726 -0.5831378,-0.75035 -1.0904085,0.1641 -1.7244723,0.28331 -0.15622,0.0142 -0.3165334,0.0243 -0.4361031,0.0279 -0.1158548,0.0272 -0.2148681,-0.027 -0.304516,-0.0524 -0.070807,-0.0312 -0.1572245,-0.0716 -0.2256461,-0.10399 -0.1156075,-0.0774 -0.1415573,-0.0271 -0.2320655,-0.0349 l -0.2871308,0.0538 c -0.2231012,0.0259 -0.1860462,0.0429 -0.093203,0.22597 l 0.346899,0.68417 z"
            fill="#000000"
            stroke="none"
          ></path>
        </g>
      </g>
    </svg>
  );
}