export function scubaMask (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <rect
          className={className}
          fill={color}
          d="57.04688"
          y="9.89502"
          width="5"
          height="13.52002"
        ></rect>
        <path
          className={className}
          fill={color}
          d="M62.04688,33.185v15.23004c0,9.76001-7.95001,17.70996-17.71002,17.70996c-9.32001,0-16.97998-7.21997-17.66998-16.34998   h5.01996c0.67004,6.35999,6.09003,11.34998,12.63,11.34998c7.03003,0,12.73004-5.70996,12.73004-12.70996V33.185H62.04688z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M65.89686,26.91504V29.685c0,1.10004-0.90002,2-2,2h-7.67999c0.14001-0.58997,0.23999-1.19995,0.31-1.81   c0.19-1.69995,0.07001-3.37-0.33002-4.95996h7.70001C64.99683,24.91504,65.89686,25.80505,65.89686,26.91504z"
        ></path>
        <g>
          <g>
            <path
              className={className}
              fill={color}
              d="M50.56684,29.22504c-0.38995,3.53998-3.22998,6.46997-6.73999,6.96997c-3.10999,0.44-6.13-0.91998-7.83002-3.47998     c-1.53998-2.29999-4.06-3.66998-6.73999-3.66998h-0.28998c-2.67999,0-5.20001,1.37-6.73999,3.66998     c-1.48004,2.22998-3.97003,3.57001-6.64002,3.57001c-4.54327,0-8.19859-3.78876-7.96997-8.38     c0.19-4.17999,3.91998-7.59003,8.29999-7.59003h26.71997C47.30464,20.315,51.11317,24.35021,50.56684,29.22504z"
            ></path>
            <path
              className={className}
              fill={color}
              d="M54.58686,24.70502c-1.59003-5.32001-6.40002-8.89001-11.95001-8.89001H15.91688     c-6.85999,0-12.48004,5.22003-12.79999,11.88c-0.32795,7.06983,5.25168,13.09003,12.46997,13.09003     c4.17999,0,8.06-2.08002,10.38001-5.56c0.71002-1.06,1.83002-1.69,3-1.69h0.28998c1.17004,0,2.29004,0.63,3,1.67999     c2.69,4.03998,7.36005,6.13,12.21002,5.42999c4.75-0.66998,8.72998-4.10999,10.13-8.73999     c0.21002-0.70001,0.35999-1.44,0.44-2.19C55.22688,27.995,55.07685,26.30505,54.58686,24.70502z M52.05689,29.39502     c-0.46002,4.21002-3.84003,7.69-8.02002,8.28998c-3.98111,0.50817-7.36904-1.24759-9.29004-4.13995     c-1.25995-1.88-3.31-3-5.48999-3h-0.28998c-2.17999,0-4.22998,1.12-5.48999,3.01001     c-1.76001,2.63995-4.71002,4.22998-7.89002,4.22998c-5.39263,0-9.73939-4.49971-9.46997-9.95001     c0.22998-4.96997,4.62994-9.02002,9.79999-9.02002h26.71997C48.17216,18.815,52.71024,23.5991,52.05689,29.39502z"
            ></path>
          </g>
        </g>
        <path
          className={className}
          fill={color}
          d="M64.95686,3.875v3.52002c0,0.54999-0.45001,1-1,1h-8.82001c-0.54999,0-1-0.45001-1-1V3.875c0-0.54999,0.45001-1,1-1   h8.82001C64.50684,2.875,64.95686,3.32501,64.95686,3.875z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M34.52168,43.75259v3.52002c0,0.54999-0.44995,1-1,1h-8.81994c-0.55005,0-1-0.45001-1-1v-3.52002c0-0.54999,0.44995-1,1-1   h8.81994C34.07173,42.75259,34.52168,43.2026,34.52168,43.75259z"
        ></path>
      </g>
    </svg>
  );
}