import { openItineraryItemModal } from "actions/modal_actions";
import { useDispatch, useSelector } from "react-redux";



const useEditItineraryDayJournalModalButton = (itineraryItemData) => {

  const itineraryInfo = useSelector((state) => state?.entities?.itinerary?.itinerary_info);
  const dispatch = useDispatch();

  const openModal = async () => {
    let actionPayload = {
      // what is itineraryItemData?
      itineraryItemData,
      itineraryData: itineraryInfo,
    };
    let openType = {
      isEdit: true,
    };

    dispatch(openItineraryItemModal(openType, actionPayload));

    return;
  }

  return { openModal }
}

export default useEditItineraryDayJournalModalButton

