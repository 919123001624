import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_CREATE_BLOCK,
  OPEN_CREATE_BLOCK_FILE,
  OPEN_CREATE_INSPIRATION,
  OPEN_CREATE_ADVENTURE,
  OPEN_CREATE_ITINERARY,
  OPEN_CREATE_ITINERARY_ITEM,
  OPEN_DATE_PICKER_MODAL,
  OPEN_SEARCH_MODAL,
  RECEIVE_DATES,
  OPEN_SHARE_MODAL,
  OPEN_SHARE_PERMISSION_MODAL,
  OPEN_SHARE_TAGS_MODAL,
  OPEN_FRIENDS_MODAL,
  OPEN_ACCOUNT_GROUP_MODAL,
  OPEN_ADVENTURE_GROUP_MODAL,
  OPEN_ACCOUNT_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_TIME_PICKER_MODAL,
  RECEIVE_MODAL_BLOCK_FILES,
  RECEIVE_TIMES,
  OPEN_FETCH_UNSPLASH_MODAL,
  RECEIVE_LOADING_MODAL,
  RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL,
  RECEIVE_INVITE_NEW_MODAL,
  RECEIVE_OPEN_ASSIGN_USERS_MODAL,
  RECEIVE_OPEN_CHECK_LIST_MODAL,
  RECEIVE_OPEN_MAP_FILTER_MODAL,
  RECEIVE_OPEN_CAMPGROUND_MODAL,
  RECEIVE_OPEN_COPY_MODAL,
  RECEIVE_OPEN_IMAGE_OP_MODAL,
  RECEIVE_OPEN_ADVENTURE_COPY_MODAL,
  RECEIVE_MESSAGE_ALERT_MODAL,
  RECEIVE_OPEN_CHAT_ROOM_MODAL,
  RECEIVE_OPEN_FOLDER_MODAL,
  RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL,
  RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL,
  RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL,
  RECEIVE_OPEN_TRIP_INVITE_MODAL,
  RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES,
} from '../../constants';

import { merge } from 'lodash';
const defaultState = { action: CLOSE_MODAL, isLoading: false };

export default function modalReducer(state = defaultState, action) {
  Object.freeze(state);
  let newState = merge({}, state);
  const { openType, blockType, actionPayload } = action;
  switch (action.type) {
    case OPEN_MODAL:
      return { action: action.modal };

    case CLOSE_MODAL:
      newState = { action: CLOSE_MODAL, isLoading: false };
      return newState;
    case OPEN_CREATE_INSPIRATION:
      newState['action'] = action.type;
      if (action.edit) {
        newState['original_inspiration'] = action.data;
      }
      return newState;
    case OPEN_CREATE_BLOCK:
      newState['action'] = action.type;
      // if (openType.isTransitionWithTab) {
      //   newState["openType"] = openType;
      //   newState["currentTab"] = openType.tabName;
      //   return newState;
      // }
      // if (openType.isNew) {
      //   newState["openType"] = openType;
      //   newState["blockType"] = blockType;
      //   newState["actionPayload"] = actionPayload;
      //   if (openType.isNewWithTab) {
      //     newState["triggerUserLocation"] = openType.triggerUserLocation;
      //     newState["currentTab"] = openType.tabName;
      //   }
      // } else if (openType.isEditAndView) {
      //   newState["openType"] = openType;
      //   newState["blockType"] = blockType;
      //   newState["actionPayload"] = actionPayload;
      // } else if (openType.isEditWithTab) {
      //   newState["openType"] = openType;
      //   newState["blockType"] = blockType;
      //   newState["currentTab"] = openType.tabName;
      //   newState["actionPayload"] = actionPayload;
      // }
      //----- v2
      newState['openType'] = openType;
      // newState['blockType'] = blockType;
      newState['currentTab'] = openType.tabName;
      // newState['actionPayload'] = actionPayload;
      return newState;
    case RECEIVE_OPEN_FOLDER_MODAL:
      newState['action'] = action.type;
      if (openType.isTransitionWithTab) {
        newState['openType'] = openType;
        newState['currentTab'] = openType.tabName;
        return newState;
      }
      if (openType.isNew) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['actionPayload'] = actionPayload;
      } else if (openType.isEditAndView) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['actionPayload'] = actionPayload;
      } else if (openType.isEditWithTab) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['currentTab'] = openType.tabName;
        newState['actionPayload'] = actionPayload;
      }
      return newState;
    case OPEN_CREATE_BLOCK_FILE:
      newState['action'] = action.type;
      if (action.editView) {
        newState['currentBlockFile'] = action.data;
      } else {
        newState['currentBlockFile'] = null;
        newState['currentBlockRid'] = action.data;
      }
      // newState["current_block"] = action.data
      return newState;
    case OPEN_CREATE_ADVENTURE:
      const { edit, isInspirationAdventure, data, type } = action;
      newState['action'] = type;
      newState['isAdventureEdit'] = false;
      // debugger
      // debugger
      if (isInspirationAdventure) {
        newState['currentInspirationRid'] = data;
      }
      if (edit) {
        newState['current_adventure'] = data;
        newState['isAdventureEdit'] = true;
      }
      if (!edit && !isInspirationAdventure) {
        newState['current_adventure'] = null;
        newState['isAdventureEdit'] = false;
        newState['currentInspirationRid'] = null;
      }
      // debugger
      return newState;
    case OPEN_CREATE_ITINERARY:
      newState['action'] = action.type;
      newState['actionPayload'] = action.actionPayload;
      newState['itineraryOpenType'] = action.openType;
      return newState;
    case OPEN_CREATE_ITINERARY_ITEM:
      newState['action'] = action.type;
      newState['itineraryItemOpenType'] = action.openType;
      return newState;
    case OPEN_SEARCH_MODAL:
      newState['action'] = action.type;
      return newState;
    case OPEN_DATE_PICKER_MODAL:
      newState['action'] = action.type;
      newState['past_reducer'] = action.data;
      if (action.date) newState['defaultDates'] = action.date;
      return newState;
    case OPEN_TIME_PICKER_MODAL:
      newState['action'] = action.type;
      newState['past_reducer'] = action.data;
      return newState;
    case OPEN_SHARE_MODAL:
      newState['action'] = action.type;
      return newState;
    case OPEN_SHARE_PERMISSION_MODAL:
      newState['action'] = action.type;
      return newState;
    case OPEN_SHARE_TAGS_MODAL:
      newState['action'] = action.type;
      return newState;
    case OPEN_FRIENDS_MODAL:
      newState['action'] = action.type;
      newState['adventureData'] = action.data;
      return newState;
    case OPEN_ACCOUNT_GROUP_MODAL:
      newState['action'] = action.type;
      if (action.openType.isEdit) {
        newState['isAccountGroupEdit'] = true;
        newState['accountGroupRid'] = action.accountGroupRid;
      } else {
        newState['isAccountGroupEdit'] = false;
      }
      return newState;
    case OPEN_ADVENTURE_GROUP_MODAL:
      newState['action'] = action.type;
      newState['adventureGroupModalType'] = action.openType;
      return newState;
    case OPEN_ACCOUNT_MODAL:
      newState['action'] = action.type;
      return newState;
    case RECEIVE_MODAL_BLOCK_FILES:
      newState['blockFiles'] = action.data;
      return newState;
    case RECEIVE_DATES:
      newState['selectedDates'] = action.data;
      return newState;
    case RECEIVE_TIMES:
      newState['selectedTimes'] = action.data;
      return newState;
    case RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL:
      newState['action'] = action.type;
      return newState;
    case OPEN_DELETE_MODAL:
      newState['action'] = action.type;
      newState['message'] = action.data;
      newState['deleteFunc'] = action.func;
      return newState;
    case OPEN_FETCH_UNSPLASH_MODAL:
      newState['action'] = action.type;
      newState['searchTerm'] = action.data;
      newState['origin'] = action.origin;
      return newState;
    case RECEIVE_LOADING_MODAL:
      if (action.boolean) {
        newState['isLoading'] = action.boolean;
        newState['action'] = action.type;
      } else {
        newState['isLoading'] = false;
        newState['action'] = CLOSE_MODAL;
      }
      return newState;
    case RECEIVE_OPEN_ASSIGN_USERS_MODAL:
      newState['action'] = action.type;
      newState['currentList'] = action.data;
      return newState;
    case RECEIVE_INVITE_NEW_MODAL:
      newState['action'] = action.type;
      return newState;
    case RECEIVE_OPEN_CHECK_LIST_MODAL:
      newState['action'] = action.type;
      if (openType.isTransitionWithTab) {
        newState['openType'] = openType;
        newState['currentTab'] = openType.tabName;
        return newState;
      }
      if (openType.isNew) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['actionPayload'] = actionPayload;
      } else if (openType.isEditAndView) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['actionPayload'] = actionPayload;
      } else if (openType.isEditWithTab) {
        newState['openType'] = openType;
        newState['blockType'] = blockType;
        newState['currentTab'] = openType.tabName;
        newState['actionPayload'] = actionPayload;
      }
      return newState;
    case RECEIVE_OPEN_MAP_FILTER_MODAL:
      newState['action'] = action.type;
      newState['openType'] = openType;
      newState['blockType'] = blockType;
      newState['actionPayload'] = actionPayload;
      return newState;
    case RECEIVE_OPEN_CAMPGROUND_MODAL:
      newState['action'] = action.type;
      newState['openType'] = openType;
      newState['blockType'] = blockType;
      newState['actionPayload'] = actionPayload;
      return newState;
    case RECEIVE_OPEN_COPY_MODAL:
      newState['action'] = action.type;
      newState['openType'] = action.openType;
      newState['actionPayload'] = action.actionPayload;
      return newState;
    case RECEIVE_OPEN_IMAGE_OP_MODAL:
      newState['action'] = action.type;
      return newState;
    case RECEIVE_OPEN_ADVENTURE_COPY_MODAL:
      newState['action'] = action.type;
      newState['actionPayload'] = action.data;
      newState['openType'] = action.openType;
      return newState;

    case RECEIVE_MESSAGE_ALERT_MODAL:
      newState['action'] = action.type;
      newState['message'] = action.message;
      return newState;
    case RECEIVE_OPEN_CHAT_ROOM_MODAL:
      newState['action'] = action.type;
      return newState;
    case RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL:
      newState['action'] = action.type;
      newState['userToEdit'] = action.data;
      return newState;
    case RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL:
      newState['action'] = action.type;
      return newState;
    case RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL:
      newState['action'] = action.type;
      newState['imageCropperOrigin'] = action.origin;
      return newState;
    case RECEIVE_OPEN_TRIP_INVITE_MODAL:
      newState['action'] = action.type;
      newState['acceptAdventureInvitePayload'] = action.adventureFriendData;
      newState['invitedAdventureData'] = action.invitedAdventureData;
      return newState;
    case RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES:
      newState['itineraryItemsAndBlockNames'] = action.data;
      return newState;
    default:
      return state;
  }
}
