export function bucket (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 512 512;"></path>
        <path
          fill={color}
          className={className}
          d="M466.983,409.201c0-18.791-7.186-36.325-20.234-49.373l-42.302-42.302c-1.875-1.875-4.419-2.929-7.071-2.929   s-5.196,1.053-7.071,2.929l-42.302,42.302c-13.048,13.048-20.234,30.583-20.234,49.373s7.186,36.325,20.234,49.373   c0.039,0.039,0.078,0.077,0.117,0.115c13.406,12.974,31.002,20.119,49.544,20.119c18.604,0,36.037-7.186,49.085-20.234   C459.797,445.526,466.983,427.991,466.983,409.201z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M133.916,399.474c13.752,13.753,32.038,21.327,51.486,21.327c19.449,0,37.734-7.574,51.487-21.327l139.276-139.276   c3.905-3.905,3.905-10.237,0-14.142L235.738,105.629V85.163c0-28.657-23.314-51.971-51.971-51.971   c-28.657,0-51.971,23.314-51.971,51.971v78.244l-65.487,65.487c-28.389,28.389-28.389,74.583,0,102.973L133.916,399.474z    M224.103,220.611c11.317,0,20.524,9.207,20.524,20.524s-9.207,20.524-20.524,20.524s-20.524-9.207-20.524-20.524   S212.786,220.611,224.103,220.611z M151.796,85.163c0-17.629,14.342-31.971,31.971-31.971s31.971,14.342,31.971,31.971v2.021   c-0.987-0.32-2.025-0.494-3.082-0.494c-2.652,0-5.196,1.053-7.071,2.929l-53.789,53.789V85.163z"
        ></path>
      </g>
    </svg>
  );
}