/*
 * Copyright @2021 Lodestar Hub, Inc.All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: www.lodestarhub.com
 */
import {
  CLOSE_MODAL,
  OPEN_ACCOUNT_GROUP_MODAL,
  OPEN_ACCOUNT_MODAL,
  OPEN_ADVENTURE_GROUP_MODAL,
  OPEN_CREATE_ADVENTURE,
  OPEN_CREATE_BLOCK,
  OPEN_CREATE_BLOCK_FILE,
  OPEN_CREATE_INSPIRATION,
  OPEN_CREATE_ITINERARY,
  OPEN_CREATE_ITINERARY_ITEM,
  OPEN_DATE_PICKER_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_FETCH_UNSPLASH_MODAL,
  OPEN_FRIENDS_MODAL,
  OPEN_MODAL,
  OPEN_SEARCH_MODAL,
  OPEN_SHARE_MODAL,
  OPEN_TIME_PICKER_MODAL,
  RECEIVE_ADVENTURE_INVITE_FRIEND_MODAL,
  RECEIVE_DATA_PASSOVER,
  RECEIVE_DATES,
  RECEIVE_FLUSH_MODAL,
  RECEIVE_IMAGES_SELECTED,
  RECEIVE_INVITE_NEW_MODAL,
  RECEIVE_LOADING_MODAL,
  RECEIVE_MESSAGE_ALERT_MODAL,
  RECEIVE_OPEN_ADVENTURE_COPY_MODAL,
  RECEIVE_OPEN_ASSIGN_USERS_MODAL,
  RECEIVE_OPEN_CAMPGROUND_MODAL,
  RECEIVE_OPEN_CHAT_ROOM_MODAL,
  RECEIVE_OPEN_CHECK_LIST_MODAL,
  RECEIVE_OPEN_CONTACTS_MODAL,
  RECEIVE_OPEN_COPY_MODAL,
  RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL,
  RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL,
  RECEIVE_OPEN_FOLDER_MODAL,
  RECEIVE_OPEN_IMAGE_OP_MODAL,
  RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL,
  RECEIVE_OPEN_MAP_FILTER_MODAL,
  RECEIVE_OPEN_TRIP_INVITE_MODAL,
  RECEIVE_TIMES,
} from '../constants';
import * as blockApiUtil from '../util/api_util/block_api_util';
import * as socialApiUtil from '../util/api_util/social_api_util';

import {
  fetchBlockList,
  fetchUserBlockCategories,
  receiveBlockVotesAll,
  receiveIsAdventureBlock,
  receivePeopleAssigned,
  receiveUserBlockCategories,
} from './block_actions';
import {
  receiveAccountGroupPersister,
  receiveAdventureGroupPersister,
  receiveBlockModalFilesPersister,
  receiveBlockModalPersister,
  receiveItineraryItemModalPersister,
  receiveItineraryModalPersister,
  receivePersistAssignUserModalData,
} from './cache_actions';
import { receiveBlockComments } from './comment_actions';
import {
  receiveAdventureFriends,
  receiveUserAccountGroup,
  receiveUserFriends,
} from './social_actions';

import { receiveChatroomDetails } from './chatroom_actions';
import { fetchItineraryItemsAndBlockNames } from './itinerary_actions';

const conditionalChainedDispatchPromises = (call, target, dispatch) =>
  new Promise((resolve, reject) => {
    if (target) {
      resolve('success');
    } else {
      call().then((res) => resolve(dispatch(res.data)));
    }
  });

export const openModal = (type, data) => {
  return {
    type: OPEN_MODAL,
    modal: {
      type,
      data,
    },
  };
};

const receiveOpenTripInviteModal = (
  adventureFriendData,
  invitedAdventureData,
) => {
  return {
    type: RECEIVE_OPEN_TRIP_INVITE_MODAL,
    adventureFriendData,
    invitedAdventureData,
  };
};

const receiveOpenInviteNewMemberModal = () => {
  return {
    type: RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL,
  };
};
export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};

const receiveOpenCheckListModal = (openType, blockType, actionPayload) => {
  return {
    type: RECEIVE_OPEN_CHECK_LIST_MODAL,
    openType,
    blockType,
    actionPayload,
  };
};

const receiveOpenMapFilterModal = (actionPayload) => {
  return {
    type: RECEIVE_OPEN_MAP_FILTER_MODAL,
    actionPayload,
  };
};

const receiveOpenCampgroundModal = (actionPayload) => {
  return {
    type: RECEIVE_OPEN_CAMPGROUND_MODAL,
    actionPayload,
  };
};

const receiveOpenEditAccountExplorerModal = (data) => {
  return {
    type: RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL,
    data,
  };
};
const receiveOpenBlock = (openType) => {
  return {
    type: OPEN_CREATE_BLOCK,
    openType,
  };
};

const receiveOpenFolder = (openType, blockType, actionPayload) => {
  return {
    type: RECEIVE_OPEN_FOLDER_MODAL,
    openType,
    blockType,
    actionPayload,
  };
};

const receiveOpenAdventure = (isInspirationAdventure, data, edit) => {
  return {
    type: OPEN_CREATE_ADVENTURE,
    edit,
    data,
    isInspirationAdventure,
  };
};

const receiveOpenMessageModal = (message) => {
  return {
    type: RECEIVE_MESSAGE_ALERT_MODAL,
    message,
  };
};

const receiveOpenInspiration = (edit, data) => {
  return {
    type: OPEN_CREATE_INSPIRATION,
    edit,
    data,
  };
};
const receiveOpenItinerary = (openType, actionPayload) => {
  return {
    type: OPEN_CREATE_ITINERARY,
    openType,
    actionPayload,
  };
};

const receiveOpenItineraryItem = (openType) => {
  return {
    type: OPEN_CREATE_ITINERARY_ITEM,
    openType,
  };
};

const receiveOpenDatePicker = (data, date) => {
  return {
    type: OPEN_DATE_PICKER_MODAL,
    data,
    date,
  };
};

const receiveOpenTimePicker = (data) => {
  return {
    type: OPEN_TIME_PICKER_MODAL,
    data,
  };
};
const receiveDates = (data) => {
  return {
    type: RECEIVE_DATES,
    data,
  };
};

const receiveOpenInviteNewModal = (data) => {
  return {
    type: RECEIVE_INVITE_NEW_MODAL,
    data,
  };
};
const receiveTimes = (data) => {
  return {
    type: RECEIVE_TIMES,
    data,
  };
};

const receiveDataPassover = (data) => {
  return {
    type: RECEIVE_DATA_PASSOVER,
    data,
  };
};

const receiveOpenSearchModal = (data) => {
  return {
    type: OPEN_SEARCH_MODAL,
    data,
  };
};

const receiveOpenAccountGroupModal = (accountGroupRid, type) => {
  return {
    type: OPEN_ACCOUNT_GROUP_MODAL,
    accountGroupRid,
    openType: type,
  };
};

const receiveOpenAdventureGroupModal = (openType) => {
  return {
    type: OPEN_ADVENTURE_GROUP_MODAL,
    openType,
  };
};

const receiveOpenAccountModal = (data) => {
  return {
    type: OPEN_ACCOUNT_MODAL,
    data,
  };
};

const receiveOpenFriendsModal = (data) => {
  return {
    type: OPEN_FRIENDS_MODAL,
    data,
  };
};

const receiveOpenCopyModal = (actionPayload, openType) => {
  return {
    type: RECEIVE_OPEN_COPY_MODAL,
    actionPayload,
    openType,
  };
};
const receiveOpenBlockFileModal = (editView, data) => {
  return {
    type: OPEN_CREATE_BLOCK_FILE,
    editView,
    data,
  };
};

const receiveOpenChatroomModal = () => {
  return {
    type: RECEIVE_OPEN_CHAT_ROOM_MODAL,
  };
};

const receiveOpenDeleteModal = (data, func) => {
  return {
    type: OPEN_DELETE_MODAL,
    data,
    func,
  };
};

const receiveOpenShareModal = (data) => {
  return {
    type: OPEN_SHARE_MODAL,
    data,
  };
};

const receiveOpenUnsplashModal = (data, origin) => {
  return {
    type: OPEN_FETCH_UNSPLASH_MODAL,
    data,
    origin,
  };
};

const receiveImageSelected = (hasMulti, data) => {
  return {
    type: RECEIVE_IMAGES_SELECTED,
    hasMulti,
    data,
  };
};

const receiveLoadingBoolean = (boolean) => {
  return {
    type: RECEIVE_LOADING_MODAL,
    boolean,
  };
};

const receiveOpenInviteOpenFriendModal = (data) => {
  return {
    type: RECEIVE_ADVENTURE_INVITE_FRIEND_MODAL,
    data,
  };
};

const receiveOpenContanctsModal = (fromLocation) => {
  return {
    type: RECEIVE_OPEN_CONTACTS_MODAL,
    fromLocation,
  };
};
const receiveOpenImageOp = (data) => {
  return {
    type: RECEIVE_OPEN_IMAGE_OP_MODAL,
    data,
  };
};

const receiveOpenEditProfileImageModal = (data, origin) => {
  return {
    type: RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL,
    data,
    origin,
  };
};

export const receiveFlushModal = () => {
  return {
    type: RECEIVE_FLUSH_MODAL,
  };
};

export const openBlockFileModal = (editView, data) => (dispatch) => {
  return dispatch(receiveOpenBlockFileModal(editView, data));
};

export const openAdventureModal = (
  inspirationAdevnture = false,
  data,
  edit = false,
  origin,
) => (dispatch) => {
  return dispatch(receiveOpenAdventure(inspirationAdevnture, data, edit));
};

export const receiveOpenAdventureCopyModal = (data, openType) => ({
  type: RECEIVE_OPEN_ADVENTURE_COPY_MODAL,
  data,
  openType,
});

export const openInspirationModal = (edit = false, inspiration) => (
  dispatch,
) => {
  return dispatch(receiveOpenInspiration(edit, inspiration));
};
/*
 * Author       : 	Paul Choi
 * Date Created	: 	07-May-2021
 * Purpose      : opens itinerary modal
 * Pararmeters  :{
 * openType: {
 *  isNew:
 *  isEditAndView:
 *  isEditAndViewWithTab:
 *  tabName
 * }
 * data: **object** itinerary data
 * actionPaylod**object** needed data for creating an itinerary
 *   {
 *    adventureRid 
 * }
}
 */
export const openItineraryModal = (data, openType, actionPayload) => (
  dispatch,
) => {
  if (openType.isEditAndView || openType.isEditAndViewWithTab) {
    dispatch(receiveItineraryModalPersister(openType, actionPayload, data));
  }
  // openType.isNew doesnt need a separate logic
  return dispatch(receiveOpenItinerary(openType, actionPayload));
};

// export const openGroupModal = ()
/*
 * Author       : 	Paul Choi
 * Date Created	: 	13 May 2021
 * Purpose      : opens itinerary modal
 * Pararmeters  :{
 * openType: {
 *  isNew:
 *  isEdit,
 *  isTransitioning,
 *  isSaving
 * }
 * actionPaylod**object** needed data for creating an itinerary
 *   {
 *    itineraryData:
 *    itineraryItemData,
 *    parentItineraryItemData
 * }
}
*/
export const openItineraryItemModal = (openType, actionPayload) => (
  dispatch,
) => {
  dispatch(receiveItineraryItemModalPersister(openType, actionPayload));
  return dispatch(receiveOpenItineraryItem(openType));
};

export const openDatePickerModal = (data, date) => (dispatch) => {
  return dispatch(receiveOpenDatePicker(data, date));
};

export const openTimePickerModal = (data) => (dispatch) => {
  return dispatch(receiveOpenTimePicker(data));
};

export const sendDates = (data) => (dispatch) => {
  return dispatch(receiveDates(data));
};

export const sendTimes = (data) => (dispatch) => {
  return dispatch(receiveTimes(data));
};
export const sendDataPassovers = (data) => (dispatch) => {
  return dispatch(receiveDataPassover(data));
};

export const openSearchModal = (data) => (dispatch) => {
  return dispatch(receiveOpenSearchModal(data));
};
/*
 * Author       : 	Paul Choi
 * Date Created	: 	20-Apr-2021
 * Purpose      : open delete modal will be used for triggering a modal that asks if the user is sure they want to 
 * delete a certain element
 * Pararmeters  :{
 * data: 'name' of the element that is getting delete,
 * func: function to call when the button to click the elemet is triggered
 *    sample
 *    deleteHandler={() => {
          const passedFunc = (callBack) => {
            deleteAdventure(current_adventure_data).then();
            callBack();
            history.goBack();
          };

          openDeleteModal("trip", passedFunc);
        }}
 * }
 */
export const openDeleteModal = (data, func) => (dispatch) => {
  return dispatch(receiveOpenDeleteModal(data, func));
};

export const openFriendsModal = (userInfo, adventureData) => (dispatch) => {
  return dispatch(receiveOpenFriendsModal(adventureData));
  // Promise.all([
  //   socialApiUtil.fetchFriendsForAdventure(
  //     userInfo.account_rid,
  //     adventureData.adventure_rid,
  //   ),
  //   socialApiUtil.fetchAccountGroup(userInfo.account_rid),
  //   socialApiUtil.fetchAdventureFriend(adventureData.adventure_rid),
  // ]).then((res) => {
  //   dispatch(receiveUserFriends(res[0].data));
  //   dispatch(receiveUserAccountGroup(res[1].data));
  //   dispatch(receiveAdventureFriends(res[2].data));
  //   return dispatch(receiveOpenFriendsModal(adventureData));
  // });
};

export const openShareModal = (data) => (dispatch) => {
  return dispatch(receiveOpenShareModal(data));
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	20-Apr-2021
 * Purpose      : 	opens account group modal by use case
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 * account_group_rid: account group rid where it's triggered from
 * userInfo: **object** userInfo,
 * type: **object** {
 *    isNew: true or false,
 *    isEdit: true ro false
 *  }
 * }
 *
 *
 */
export const openAccountGroupModal = (
  userInfo,
  account_group_rid,
  type,
  accountGroupData,
) => (dispatch) => {
  if (account_group_rid) {
    socialApiUtil
      .fetchAccountGroupMembersAndFriends(
        userInfo.account_rid,
        account_group_rid,
      )
      .then((res) => {
        dispatch(receiveUserFriends(res.data));
        dispatch(receiveAccountGroupPersister(accountGroupData));
        return dispatch(receiveOpenAccountGroupModal(account_group_rid, type));
      });
  } else {
    socialApiUtil.fetchFriends(userInfo.account_rid).then((res) => {
      dispatch(receiveUserFriends(res.data));
      return dispatch(receiveOpenAccountGroupModal(account_group_rid, type));
    });
  }
};

export const openAdventureInviteFriendModal = (userInfo, data) => (
  dispatch,
) => {
  return dispatch(receiveOpenInviteOpenFriendModal());
};

/* 
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com  
 * Author       : 	Paul Choi
 * Date Created	: 	16-Apr-2021
 * Purpose      : 	open images unsplash action will do two things, 1. open unsplash modal and 2. pass default search term which  will be the trip title  
 * Updates 		: 	DD-MON-YYYY description of update  
 * Pararmeters  :{
 *  searchTermObject: object*optional, {
 *      inputDefaultText: string
 *    }
    }
 * }
*/
export const openUnsplashModal = (searchTermObject, origin) => (dispatch) => {
  return dispatch(receiveOpenUnsplashModal(searchTermObject, origin));
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	16-Apr-2021
 * Purpose      : 	sendImages action sends image selected
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 *  hasMulti: boolean, notes whether or note there are multiple images selected
 *  imageObject: object * required: {
 *    singleImage: 'string',
 *    multipleImages: ['image','image']
 * }
 *
 */
export const sendImages = (hasMulti, imageObject) => (dispatch) => {
  return dispatch(receiveImageSelected(hasMulti, imageObject));
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	18-Apr-2021
 * Purpose      : 	sets loading on and off
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 * boolean: accepts boolean true turns on loading false turns off loading
 * }
 *
 */
export const setLoading = (boolean) => (dispatch) => {
  return dispatch(receiveLoadingBoolean(boolean));
};

export const receiveOpenAssignUsersModal = (data) => {
  return {
    type: RECEIVE_OPEN_ASSIGN_USERS_MODAL,
    data,
  };
};

export const flushModal = () => (dispatch) => {
  return dispatch(receiveFlushModal());
};

/*

 * Author       : 	Paul Choi
 * Date Created	: 	18-Apr-2021
 * Purpose      : 	opens contacts modal with  other options to determine which modal to open back and where to send data
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 * prevLocation: **object** {
 *    fromAccountGroupModal: boolean,
 *    fromAdventureGroupMOdal: boolean,
 *    accountRid: number,
 *    adventureRid: number
 *  }
 * }
 *
 */
export const openContactsModal = (fromLocation) => (dispatch) => {
  return dispatch(receiveOpenContanctsModal(fromLocation));
};

/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 * Author       : 	Paul Choi
 * Date Created	: 	18-Apr-2021
 * Purpose      : open adventure group modal will open adventure
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 *  userInfo: **object** userInfo object
 *  adventureGroup: **object** adventureGroupObject
 *  openType: **object*** {
 *  isEdit: boolean,
 *  isNew: boolean
 *  adventureRid: number * passed if isNew is true
 * }
 *
 *
 */
export const openAdventureGroupModal = (userInfo, adventureGroup, openType) => (
  dispatch,
) => {
  if (openType.isEdit) {
    Promise.all([
      socialApiUtil.fetchAdventureGroupMembersAndFriends(
        userInfo.account_rid,
        adventureGroup.adventure_group_rid,
      ),
      socialApiUtil.fetchAccountGroup(userInfo.account_rid),
    ]).then((res) => {
      dispatch(receiveAdventureFriends(res[0].data));
      dispatch(receiveUserAccountGroup(res[1].data));
      dispatch(receiveAdventureGroupPersister(adventureGroup));
      return dispatch(receiveOpenAdventureGroupModal(openType));
    });
  } else {
    Promise.all([
      socialApiUtil.fetchAdventureFriend(openType.adventureRid),
      socialApiUtil.fetchAccountGroup(userInfo.account_rid),
    ]).then((res) => {
      dispatch(receiveAdventureFriends(res[0].data));
      dispatch(receiveUserAccountGroup(res[1].data));
      return dispatch(receiveOpenAdventureGroupModal(openType));
    });
  }
};

export const openInviteNewModal = () => (dispatch) => {
  return dispatch(receiveOpenInviteNewModal());
};

/* Purpose
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :
 *  openType: **object** {
 *  isEditAndView: boolean,
 *  isNew: boolean,
 *  isEditWithTab: boolean,
 *  tabNmae
 * }
 *  blockType: **object** {
 *  isAdventureBlock: boolean
 *  isNestedAdventureBlock: boolean,
 *  isLibraryBlock: boolean,
 *  isNestedLibraryBlock,
 *  isItineraryItemBlock: boolean,
 *  isNestedItineraryItemBlock: boolean,
 *  isLocationBlock: boolean
 *
 * }
 * actionPayload: **object** required data to call the right form of upsert
 * {
 * adventureRid,
 * itineraryItemRid,
 * nestedLevel,
 * parentBlockRid,
 * location
 * }
 *
 */

export const openBlockModal = (
  openType,
  activityModalTypeRid,
  uiUpdatePayload,
  blockPayload,
  userInfo,
) => (dispatch, getState) => {
  //base case not run the rest of the function for transitioning, if it's transition it will already have ran all the necessary data fetchers
  if (!activityModalTypeRid && !uiUpdatePayload && !blockPayload) {
    return dispatch(receiveOpenBlock(openType));
  }
  //optimized logically sequenced callers and receivers
  const { dataCache, entities } = getState();
  let actionPayload = dataCache?.modal?.actionPayload;
  let userBlockCategories = entities?.blocks?.user_block_categories;
  conditionalChainedDispatchPromises(
    () => blockApiUtil.fetchUserBlockCategory(userInfo.account_rid),
    userBlockCategories,
    (data) => dispatch(receiveUserBlockCategories(data)),
  ).then(() => {
    conditionalChainedDispatchPromises(
      () => blockApiUtil.fetchBlockFiles(blockPayload.block_rid),
      !blockPayload.block_rid || false, // requires false value for the funciton to run
      (data) => dispatch(receiveBlockModalFilesPersister(data)),
    ).then(() => {
      let func = () =>
        blockApiUtil.fetchAdventureBlockComments(
          blockPayload.adventure_block_rid,
        );
      if (blockPayload.itinerary_block_rid) {
        func = () =>
          blockApiUtil.fetchBlockComment(
            blockPayload.itineriary_block_rid ||
              blockPayload.itinerary_block_rid,
          );
      }
      let condition =
        blockPayload.adventure_block_rid || blockPayload.itinerary_block_rid;
      conditionalChainedDispatchPromises(func, !condition || false, (data) =>
        dispatch(receiveBlockComments(data)),
      ).then(() => {
        let voteFunc = () =>
          blockApiUtil.fetchAdventureBlockVotes(
            blockPayload.adventure_block_rid,
            userInfo.explorer_rid,
          );
        if (blockPayload.itinerary_block_rid) {
          func = () =>
            blockApiUtil.fetchBlockVotesAll(
              blockPayload.itineriary_block_rid ||
                blockPayload.itinerary_block_rid,
            );
        }
        conditionalChainedDispatchPromises(func, !condition || false, (data) =>
          dispatch(receiveBlockVotesAll(data)),
        ).then(() => {
          if (!actionPayload) {
            dispatch(
              receiveBlockModalPersister(openType, {
                currentBlock: blockPayload,
                blockPayload,
                activityModalTypeRid,
                uiUpdatePayload,
              }),
            );
          }
          if (
            uiUpdatePayload?.adventure_rid_exist_flag &&
            blockPayload.block_rid
          ) {
            blockApiUtil
              .checkIsAdventureBlock(
                blockPayload.block_rid,
                uiUpdatePayload.adventure_rid_exist_flag,
              )
              .then((res) => {
                dispatch(
                  receiveIsAdventureBlock(
                    res.data.isAdventureBlock[0]
                      .func_return_adventure_block_exist,
                  ),
                );
              });
            dispatch(receiveOpenBlock(openType));
          } else {
            dispatch(receiveOpenBlock(openType));
          }
        });
      });
    });
  });
};
export const openFolderModal = (
  openType,
  blockType,
  actionPayload,
  data,
  userInfo,
) => async (dispatch) => {
  if (openType.isTransitionWithTab) {
    return dispatch(receiveOpenFolder(openType, blockType, null));
  }
  if (openType.isNew) {
    return dispatch(receiveOpenFolder(openType, blockType, actionPayload));
  } else if (openType.isEditAndView) {
    if (blockType.isLibraryBlock || blockType.isNestedLibraryBlock) {
      let returnedData = await Promise.all([
        blockApiUtil.checkIsAdventureBlock(
          data.block_rid,
          actionPayload.adventureRid,
        ),
      ]);
      // let returnedData = await blockApiUtil.fetchBlockFiles(data.block_rid);
      // .then((res) => {
      dispatch(
        receiveIsAdventureBlock(
          returnedData[0].data.isAdventureBlock[0]
            .func_return_adventure_block_exist,
        ),
      );
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
      // });
    } else if (blockType.isAdventureBlock || blockType.isNestedAdventureBlock) {
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (
      blockType.isItineraryItemBlock ||
      blockType.isNestedItineraryItemBlock
    ) {
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    }
    return dispatch(receiveOpenFolder(openType, blockType, actionPayload));
  } else if (openType.isEditWithTab) {
    if (blockType.isAdventureBlock || blockType.isNestedAdventureBlock) {
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (
      blockType.isItineraryItemBlock ||
      blockType.isNestedItineraryItemBlock
    ) {
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (blockType.isLibraryBlock || blockType.isNestedLibraryBlock) {
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    }
    return dispatch(receiveOpenFolder(openType, blockType, actionPayload));
  }
};
export const openAssignUsersModal = (data, openType, returnActionPayload) => (
  dispatch,
) => {
  Promise.all([
    blockApiUtil.fetchBlockPeopleAssignment(
      returnActionPayload.adventureBlockRid,
    ),
    socialApiUtil.fetchAdventureFriend(returnActionPayload.adventureRid),
  ]).then((res) => {
    dispatch(receivePeopleAssigned(res[0].data));
    dispatch(receiveAdventureFriends(res[1].data));
    dispatch(receivePersistAssignUserModalData());
    return dispatch(receiveOpenAssignUsersModal(data));
  });
};

export const goBackPrevModal = (data) => (dispatch) => {
  return dispatch({
    type: data,
  });
};

export const openMapFilterModal = (data) => (dispatch) => {
  return dispatch(receiveOpenMapFilterModal(data));
};

export const openCampgroundModal = (data) => (dispatch) => {
  return dispatch(receiveOpenCampgroundModal(data));
};

export const openCheckListModal = (
  openType,
  blockType,
  actionPayload,
  data,
  userInfo,
) => async (dispatch) => {
  if (openType.isTransitionWithTab) {
    return dispatch(receiveOpenCheckListModal(openType, blockType, null));
  }

  await dispatch(fetchUserBlockCategories(userInfo.account_rid));
  if (data) {
    await dispatch(
      fetchBlockList(data.block_rid, data.adventure_block_rid, userInfo),
    );
  }
  if (actionPayload.itineraryInfo) {
    await fetchItineraryItemsAndBlockNames(
      actionPayload.itineraryInfo.itinerary_rid,
    );
  }

  if (openType.isNew) {
    return dispatch(
      receiveOpenCheckListModal(openType, blockType, actionPayload),
    );
  } else if (openType.isEditAndView) {
    if (blockType.isLibraryBlock || blockType.isNestedLibraryBlock) {
      let returnedData = await Promise.all([
        blockApiUtil.fetchBlockFiles(data.block_rid),
        blockApiUtil.checkIsAdventureBlock(
          data.block_rid,
          actionPayload.adventureRid,
        ),
      ]);
      dispatch(receiveBlockModalFilesPersister(returnedData[0].data));
      dispatch(
        receiveIsAdventureBlock(
          returnedData[1].data.isAdventureBlock[0]
            .func_return_adventure_block_exist,
        ),
      );
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
      // });
    } else if (blockType.isAdventureBlock || blockType.isNestedAdventureBlock) {
      let multipleRes = await Promise.all([
        !blockType.isNestedAdventureBlock &&
          blockApiUtil.fetchAdventureBlockComments(data.adventure_block_rid),
        !blockType.isNestedAdventureBlock &&
          blockApiUtil.fetchAdventureBlockVotes(
            data.adventure_block_rid,
            userInfo.explorer_rid,
          ),
        blockApiUtil.fetchBlockFiles(data.block_rid),
      ]);
      !blockType.isNestedAdventureBlock &&
        dispatch(receiveBlockComments(multipleRes[0].data));
      !blockType.isNestedAdventureBlock &&
        dispatch(receiveBlockVotesAll(multipleRes[1].data));
      dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (
      blockType.isItineraryItemBlock ||
      blockType.isNestedItineraryItemBlock
    ) {
      let multipleRes = await Promise.all([
        !blockType.isNestedItineraryItemBlock &&
          blockApiUtil.fetchBlockComment(data.itineriary_block_rid),
        !blockType.isNestedItineraryItemBlock &&
          blockApiUtil.fetchBlockVotesAll(data.itineriary_block_rid),
        blockApiUtil.fetchBlockFiles(data.block_rid),
      ]);
      !blockType.isNestedItineraryItemBlock &&
        dispatch(receiveBlockComments(multipleRes[0].data));
      !blockType.isNestedItineraryItemBlock &&
        dispatch(receiveBlockVotesAll(multipleRes[1].data));
      dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (openType.isEditWithTab) {
      if (blockType.isAdventureBlock || blockType.isNestedAdventureBlock) {
        let multipleRes = await Promise.all([
          !blockType.isNestedAdventureBlock &&
            blockApiUtil.fetchAdventureBlockComments(data.adventure_block_rid),
          !blockType.isNestedAdventureBlock &&
            blockApiUtil.fetchAdventureBlockVotes(
              data.adventure_block_rid,
              userInfo.explorer_rid,
            ),
          blockApiUtil.fetchBlockFiles(data.block_rid),
        ]);
        !blockType.isNestedAdventureBlock &&
          dispatch(receiveBlockComments(multipleRes[0].data));
        !blockType.isNestedAdventureBlock &&
          dispatch(receiveBlockVotesAll(multipleRes[1].data));
        dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
        dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
      } else if (
        blockType.isItineraryItemBlock ||
        blockType.isNestedItineraryItemBlock
      ) {
        let multipleRes = await Promise.all([
          !blockType.isNestedItineraryItemBlock &&
            blockApiUtil.fetchBlockComment(data.itineriary_block_rid),
          !blockType.isNestedItineraryItemBlock &&
            blockApiUtil.fetchBlockVotesAll(data.itineriary_block_rid),
          blockApiUtil.fetchBlockFiles(data.block_rid),
        ]);
        !blockType.isNestedItineraryItemBlock &&
          dispatch(receiveBlockComments(multipleRes[0].data));
        !blockType.isNestedItineraryItemBlock &&
          dispatch(receiveBlockVotesAll(multipleRes[1].data));
        dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
        dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
      } else if (blockType.isLibraryBlock || blockType.isNestedLibraryBlock) {
        let res3 = await blockApiUtil.fetchBlockFiles(data.block_rid);
        dispatch(receiveBlockModalFilesPersister(res3.data));
        dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
      }
      return dispatch(
        receiveOpenCheckListModal(openType, blockType, actionPayload),
      );
    }
    return dispatch(
      receiveOpenCheckListModal(openType, blockType, actionPayload),
    );
  } else if (openType.isEditWithTab) {
    if (blockType.isAdventureBlock || blockType.isNestedAdventureBlock) {
      let multipleRes = await Promise.all([
        !blockType.isNestedAdventureBlock &&
          blockApiUtil.fetchAdventureBlockComments(data.adventure_block_rid),
        !blockType.isNestedAdventureBlock &&
          blockApiUtil.fetchAdventureBlockVotes(
            data.adventure_block_rid,
            userInfo.explorer_rid,
          ),
        blockApiUtil.fetchBlockFiles(data.block_rid),
      ]);
      !blockType.isNestedAdventureBlock &&
        dispatch(receiveBlockComments(multipleRes[0].data));
      !blockType.isNestedAdventureBlock &&
        dispatch(receiveBlockVotesAll(multipleRes[1].data));
      dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (
      blockType.isItineraryItemBlock ||
      blockType.isNestedItineraryItemBlock
    ) {
      let multipleRes = await Promise.all([
        !blockType.isNestedItineraryItemBlock &&
          blockApiUtil.fetchBlockComment(data.itineriary_block_rid),
        !blockType.isNestedItineraryItemBlock &&
          blockApiUtil.fetchBlockVotesAll(data.itineriary_block_rid),
        blockApiUtil.fetchBlockFiles(data.block_rid),
      ]);
      !blockType.isNestedItineraryItemBlock &&
        dispatch(receiveBlockComments(multipleRes[0].data));
      !blockType.isNestedItineraryItemBlock &&
        dispatch(receiveBlockVotesAll(multipleRes[1].data));
      dispatch(receiveBlockModalFilesPersister(multipleRes[2].data));
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    } else if (blockType.isLibraryBlock || blockType.isNestedLibraryBlock) {
      let res3 = await blockApiUtil.fetchBlockFiles(data.block_rid);
      dispatch(receiveBlockModalFilesPersister(res3.data));
      dispatch(receiveBlockModalPersister(openType, { currentBlock: data }));
    }
    return dispatch(
      receiveOpenCheckListModal(openType, blockType, actionPayload),
    );
  }
};

export const openCopyModal = (actionPayload, type) => (dispatch) => {
  dispatch(receiveOpenCopyModal(actionPayload, type));
};

export const openImageOp = (data) => (dispatch) => {
  dispatch(receiveOpenImageOp(data));
};

export const openEditProfileImageModal = (data, origin) => (dispatch) => {
  // origin is either {isProfile, isAccount}
  dispatch(receiveOpenEditProfileImageModal(data, origin));
};
/*openType : {
  copySelf: boolean
  copyToOthers: boolean
}
*/
export const openAdventureCopyModal = (data, openType) => (dispatch) => {
  dispatch(receiveOpenAdventureCopyModal(data, openType));
};

export const openMessageModal = (message) => (dispatch) => {
  dispatch(receiveOpenMessageModal(message));
};

export const openChatroomModal = (receivingUser, sendingUser) => (dispatch) => {
  dispatch(receiveChatroomDetails(receivingUser, sendingUser));
  dispatch(receiveOpenChatroomModal());
};

export const openAccountExplorerEditModal = (user) => (dispatch) => {
  dispatch(receiveOpenEditAccountExplorerModal(user));
};

export const openInviteNewMemberModal = () => (dispatch) => {
  dispatch(receiveOpenInviteNewMemberModal());
};

//opens accept trip invite modal used for accepting trip invites to denote userss starting points
export const openAcceptTripInviteModal = (
  adventureFriendData,
  invitedAdventureData,
) => (dispatch) => {
  return dispatch(
    receiveOpenTripInviteModal(adventureFriendData, invitedAdventureData),
  );
};
