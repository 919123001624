export const SvgAddToList = (
  color = '#273906',
  className,
  outerLayer,
  width = 15,
  height = 15,
) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="250 250 750 750"
      className={outerLayer}
    >
      <path
        className={className}
        d="m712.5 562.5h-127.5c-12.426 0-22.5 10.074-22.5 22.5v28.156h-75v-28.156c0-53.848 43.652-97.5 97.5-97.5h127.5v-112.5c0-20.711-16.789-37.5-37.5-37.5h-300c-20.711 0-37.5 16.789-37.5 37.5v300c0 20.711 16.789 37.5 37.5 37.5h112.5v-61.844h75v61.844h112.5c20.711 0 37.5-16.789 37.5-37.5zm75 0v112.5c0 62.133-50.367 112.5-112.5 112.5h-112.5v90c0 12.426 10.074 22.5 22.5 22.5h93.812v75h-93.812c-53.848 0-97.5-43.652-97.5-97.5v-90h-112.5c-62.133 0-112.5-50.367-112.5-112.5v-300c0-62.133 50.367-112.5 112.5-112.5h300c62.133 0 112.5 50.367 112.5 112.5v112.5h90v75zm187.5 155.53v159.47c0 53.848-43.652 97.5-97.5 97.5h-161.19v-75h161.19c12.426 0 22.5-10.074 22.5-22.5v-159.47zm-97.5-230.53c53.848 0 97.5 43.652 97.5 97.5v95.531h-75v-95.531c0-12.426-10.074-22.5-22.5-22.5z"
        fill={color}
      />
    </svg>
  );
};