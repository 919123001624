export const svgSend = (color, className, outerLayer) => {
  return (
    <svg className={outerLayer} viewBox="0 0 48 60" x="0px" y="0px">
      <g>
        <path
          className={className}
          d="M18.67285,45.91992a.98623.98623,0,0,1-.375-.07324.99915.99915,0,0,1-.625-.92676V34.61133a1.00539,1.00539,0,0,1,.22754-.63574l11.791-14.32032L13.77734,33.208a1.00328,1.00328,0,0,1-1.15527.10058l-9.875-5.80468a1,1,0,0,1,.0127-1.73145L44.252,2.21094a.99946.99946,0,0,1,1.47657,1.05469L38.36621,42.16016a.99974.99974,0,0,1-1.42383.71191L26.85352,37.90918l-7.46192,7.706A.99786.99786,0,0,1,18.67285,45.91992Zm1-10.95019v7.47949l6.25-6.4541A1.0025,1.0025,0,0,1,27.083,35.793l9.5625,4.7041L43.3623,5.01563,5.25293,26.65625l7.76953,4.56738,23.97266-20.416a1.00043,1.00043,0,0,1,1.4209,1.39746Z"
        />
      </g>
    </svg>
  );
};