import { undisplayNotifcationBadge } from "../../actions/social_actions";
import { triggerMenuDrawerWithTab } from "../../actions/ui_actions";
export const mapStateToProps = ({ entities, session, ui }) => {
  // let badgesToDisplay = entities.social.
  let badgesToDisplay = entities.social.notificationToDisplay
    ? entities.social.notificationToDisplay
    : null;
  return {
    badgesToDisplay,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    undisplayNotifcationBadge: (data) =>
      dispatch(undisplayNotifcationBadge(data)),
    triggerMenuDrawerWithTab: (tab) => dispatch(triggerMenuDrawerWithTab(tab)),
  };
};
