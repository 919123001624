import React from 'react';
import styled from '@emotion/styled';

import Option from './Option';
import Input from './Input';
import { LIB_NAME } from '../constants';
import { getByPath } from '../util';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
const Content = ({ props, state, methods, required, displayCustomTripDates }) => {
  return (
    <ContentComponent
      className={`${LIB_NAME}-content ${
        props.multi ? `${LIB_NAME}-type-multi` : `${LIB_NAME}-type-single`
      }`}
      onClick={(event) => {
        event.stopPropagation();
        methods.dropDown('toggle', event);
      }}
    >
      {props.contentRenderer ? (
        props.contentRenderer({ props, state, methods })
      ) : (
        <React.Fragment>
          {props.multi
            ? state.values &&
              state.values.map((item) => (
                <Option
                  key={`${getByPath(item, props.valueField)}${getByPath(
                    item,
                    props.labelField,
                  )}`}
                  item={item}
                  state={state}
                  props={props}
                  methods={methods}
                  displayCustomTripDates={displayCustomTripDates}
                />
              ))
            : state.values &&
              state.values.length > 0 && (
                <span>{getByPath(state.values[0], props.labelField)}</span>
              )}
          <Input
            props={props}
            methods={methods}
            state={state}
            readonly
            required={required}
          />
        </React.Fragment>
      )}
    </ContentComponent>
  );
};

const ContentComponent = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

export default Content;
