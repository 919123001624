export function trailRunning (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 360 360"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M199.71,100.459c9.133,0,16.539-7.414,16.539-16.562c0-9.141-7.406-16.57-16.539-16.57s-16.539,7.43-16.539,16.57   C183.171,93.045,190.577,100.459,199.71,100.459z"
        ></path>
        <path
          className={className}
          d="M304.179,224.92l-54.039-7.938l-13.32-24.82l-16.016,4.375l11.781-12.273l-5.992-47.695l-9.672-12.562l19.977,12.297   l9.305,5.992l1.297,26.18c0.195,3.977,3.555,7.031,7.523,6.828c3.719-0.18,6.641-3.203,6.828-6.828l1.539-31.141   c0.133-2.523-1-4.891-2.828-6.383l-0.797-0.695l-28.094-22.992c-1.906-1.539-4.164-2.148-6.406-2.273v-0.125   c0,0-0.305,0.094-0.375,0.094c-2.109-0.031-29.711,8.141-34.055,9.586c-6.188,2.062-6.664,5.055-6.008,8.68   c0.211,1.148,6.93,33.734,6.93,33.734c2.258,10.836,4.211,20.18,4.211,20.18l0.18,0.047l-26.414,37.679l-0.992,1.391   c-0.992,1.438-1.516,3.078-1.594,4.734l-2.352,57.477c-0.18,4.5,3.305,8.32,7.828,8.5c4.25,0.18,7.859-2.93,8.461-7.055   l7.312-52.766l24.172-23.367l21.312,29.031l0.828,1.18c1.82,2.453,4.664,3.812,7.492,3.883l60.75,1.422   c4,0.094,7.312-3.086,7.406-7.062C310.437,228.545,307.772,225.42,304.179,224.92z"
        ></path>
        <path
          className={className}
          d="M184.897,137.155l-28.352-0.773c-3.703-0.117-6.797,2.812-6.898,6.5c-0.102,3.359,2.258,6.164,5.414,6.812l28.703,5.664   L184.897,137.155z"
        ></path>
        <polygon points="112.909,156.509 131.705,156.509 109.054,118.408 126.994,118.408 101.729,75.884 76.447,118.408 93.849,118.408    71.174,156.509 90.283,156.509 66.416,196.626 94.322,196.626 94.322,224.899 107.682,224.899 107.682,196.626 136.775,196.626     "></polygon>
      </g>
    </svg>
  );
}