export function longTermRental (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      className={outerLayer}
    >
      <g>
        <g>
          <g>
            <path
              className={className}
              d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
            ></path>
            <path
              className={className}
              d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"
            ></path>
            <path
              className={className}
              d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
            ></path>
            <path
              className={className}
              d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
            ></path>
          </g>
          <g>
            <path
              className={className}
              d="M41.8,72.6h6.9c0.5,0,1-0.4,1-1V57.9h2.3c1.3,0,1.9-1.5,1-2.5L38.9,41.4c-0.6-0.6-1.5-0.6-2,0L22.8,55.4     c-0.9,0.9-0.3,2.5,1,2.5h2.3v13.8c0,0.5,0.4,1,1,1h6.9c0.5,0,0.9-0.4,1-1V66c0-1.5,1-2.8,2.5-3c1-0.2,2,0.2,2.6,0.8     c0.5,0.5,0.9,1.3,0.9,2.1v5.8C40.8,72.2,41.3,72.6,41.8,72.6z"
            ></path>
            <path
              className={className}
              d="M66.9,81.6V86c0,0.9-0.7,1.6-1.6,1.6h-55c-0.8,0-1.6-0.7-1.6-1.6V14c0-0.5,0.3-0.9,0.5-1.1c0.1-0.2,0.5-0.5,1.1-0.5     l34.1,0V27c0,4.4,3.5,7.9,7.9,7.9h14.5v17.7l6.3-6.3V33.7c0-2.1-0.8-4.1-2.3-5.5L51.2,8.4c-1.5-1.5-3.4-2.3-5.6-2.3l-35.3,0     c-2.1,0-4.1,0.8-5.5,2.3C3.3,9.9,2.5,11.9,2.5,14v72c0,4.3,3.5,7.9,7.9,7.9h55c4.4,0,7.9-3.5,7.9-7.9v-9.7L69.5,80     C68.8,80.7,67.9,81.3,66.9,81.6z M50.7,16.9l11.7,11.7H52.3c-0.9,0-1.6-0.7-1.6-1.6V16.9z"
            ></path>
            <path
              className={className}
              d="M55.8,76.7c-0.3,1.2,0.8,2.4,2.1,2.1l7.4-1.7c0.3-0.1,0.6-0.2,0.8-0.5l2.1-2.1L60,66.3l-2.1,2.1     c-0.2,0.2-0.4,0.5-0.5,0.8L55.8,76.7z"
            ></path>
            <rect
              className={className}
              x="63.3"
              y="52.1"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.4432 71.1525)"
              width="26.8"
              height="11.6"
            ></rect>
            <path
              className={className}
              d="M97,43.2l-5.7-5.7c-0.7-0.7-1.8-0.7-2.5,0l-3.7,3.7l8.2,8.2l3.7-3.7C97.7,45,97.7,43.9,97,43.2z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}