import { Box } from '@mui/material';

function CardCarouselLayout({ children }) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {children}
    </Box>
  );
}

export default CardCarouselLayout;
