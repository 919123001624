export const svgNote = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      className={outerLayer}
      viewBox="0, 0, 78.125, 93.75"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M70.312,0 L7.812,0 C3.498,0 0,3.498 0,7.812 L0,85.938 C0,90.252 3.498,93.75 7.812,93.75 L70.312,93.75 C74.627,93.75 78.125,90.252 78.125,85.938 L78.125,7.812 C78.125,3.498 74.627,0 70.312,0 z M38.906,64.227 L15.781,64.227 C14.703,64.227 13.828,63.352 13.828,62.273 C13.828,61.195 14.703,60.32 15.781,60.32 L38.906,60.32 C39.985,60.32 40.859,61.195 40.859,62.273 C40.859,63.352 39.985,64.227 38.906,64.227 z M62.344,53.977 L15.781,53.977 C14.703,53.977 13.828,53.102 13.828,52.023 C13.828,50.945 14.703,50.07 15.781,50.07 L62.344,50.07 C63.422,50.07 64.297,50.945 64.297,52.023 C64.297,53.102 63.422,53.977 62.344,53.977 z M62.344,43.75 L15.781,43.75 C14.703,43.75 13.828,42.876 13.828,41.797 C13.828,40.718 14.703,39.844 15.781,39.844 L62.344,39.844 C63.422,39.844 64.297,40.718 64.297,41.797 C64.297,42.876 63.422,43.75 62.344,43.75 z M62.344,33.5 L15.781,33.5 C14.703,33.5 13.828,32.626 13.828,31.547 C13.828,30.468 14.703,29.594 15.781,29.594 L62.344,29.594 C63.422,29.594 64.297,30.468 64.297,31.547 C64.297,32.626 63.422,33.5 62.344,33.5 z M62.344,23.258 L15.781,23.258 C14.703,23.258 13.828,22.383 13.828,21.305 C13.828,20.226 14.703,19.352 15.781,19.352 L62.344,19.352 C63.422,19.352 64.297,20.226 64.297,21.305 C64.297,22.383 63.422,23.258 62.344,23.258 z"
          fill="#7c90a1"
        />
      </g>
    </svg>
  );
};