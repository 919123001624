export function picnicTable (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M22.847,45.212v-4.739c0.952,0.791,2.169,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.458  c0.952,0.791,2.169,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.454c0.952,0.789,2.169,1.27,3.5,1.27h1v-2h-1  c-1.93,0-3.5-1.57-3.5-3.5v-1h-2v1c0,1.93-1.57,3.5-3.5,3.5h-1v2h1c1.331,0,2.548-0.48,3.5-1.27v0.454c0,1.93-1.57,3.5-3.5,3.5h-1v2  h1c1.331,0,2.548-0.483,3.5-1.274v0.458c0,1.93-1.57,3.5-3.5,3.5h-1v2h1c1.331,0,2.548-0.483,3.5-1.274v4.739H22.847z   M82.653,32.379h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-1h-2v1c0,1.93-1.57,3.5-3.5,3.5h-1v2h1c1.331,0,2.548-0.483,3.5-1.274v0.459  c0,1.93-1.57,3.5-3.5,3.5h-1v2h1c1.331,0,2.548-0.483,3.5-1.274v0.458c0,1.93-1.57,3.5-3.5,3.5h-1v2h1  c1.331,0,2.548-0.483,3.5-1.274v4.739h2v-4.739c0.952,0.791,2.169,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.458  c0.952,0.791,2.169,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.459C80.105,31.896,81.322,32.379,82.653,32.379z   M49,32.271v4.739h2v-4.739c0.952,0.791,2.168,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.458  c0.952,0.791,2.168,1.274,3.5,1.274h1v-2h-1c-1.93,0-3.5-1.57-3.5-3.5v-0.459c0.952,0.791,2.168,1.274,3.5,1.274h1v-2h-1  c-1.93,0-3.5-1.57-3.5-3.5v-1h-2v1c0,1.93-1.57,3.5-3.5,3.5h-1v2h1c1.332,0,2.548-0.483,3.5-1.274v0.459c0,1.93-1.57,3.5-3.5,3.5h-1  v2h1c1.332,0,2.548-0.483,3.5-1.274v0.458c0,1.93-1.57,3.5-3.5,3.5h-1v2h1C46.832,33.546,48.048,33.063,49,32.271z M35.199,64.076  l3.033-7.274h-5.828l-3.032,7.274H35.199z M64.8,64.076h5.828l-3.032-7.274h-5.828L64.8,64.076z M73.287,49.922H26.713v5.38h46.574  V49.922z M20.918,70.956h58.164v-5.38H20.918V70.956z M68.292,72.456l4.112,9.866h5.828l-4.112-9.866H68.292z M21.768,82.322h5.827  l4.112-9.866H25.88L21.768,82.322z"
        ></path>
      </g>
    </svg>
  );
}