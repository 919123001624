export function explore (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1360 1360"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <path
        className={className}
        fill={color}
        d="M680 0C305.04 0 0 305.04 0 680C0 1054.96 305.04 1360 680 1360C1054.96 1360 1360 1054.96 1360 680C1360 305.04 1054.96 0 680 0ZM774.464 779.347L334.731 1008.45L585.517 580.667L1025.26 351.56L774.464 779.347ZM680 761.331C724.912 761.331 761.328 724.915 761.328 680.003C761.328 635.091 724.912 598.675 680 598.675C635.088 598.675 598.672 635.091 598.672 680.003C598.672 724.915 635.088 761.331 680 761.331Z"
      />
    </svg>
  );
}