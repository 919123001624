
export const svgDonate = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="60.226"
      height="63"
      viewBox="0 0 60.226 63"
    >
      <g id="Group_469" data-name="Group 469">
        <path
          className={className}
          id="Path_333"
          data-name="Path 333"
          d="M53.759,17.025A17.025,17.025,0,1,0,36.734,34.05,17.02,17.02,0,0,0,53.759,17.025ZM38.192,26.432v1.722a.735.735,0,0,1-.729.729H35.608a.735.735,0,0,1-.729-.729V26.3a11.935,11.935,0,0,1-3.71-1.987.816.816,0,0,1-.066-1.259l1.259-1.325a.824.824,0,0,1,1.126-.066,6.253,6.253,0,0,0,3.246,1.391c2.319,0,2.319-1.325,2.319-1.855,0-.795-.2-1.457-2.716-2.385C32.759,17.555,30.9,15.5,30.9,12.852a5.145,5.145,0,0,1,3.975-5.035V5.962a.735.735,0,0,1,.729-.729h1.855a.735.735,0,0,1,.729.729V7.618a8.555,8.555,0,0,1,3.114,1.391.945.945,0,0,1,.132,1.325l-1.259,1.325a.818.818,0,0,1-1.126.132,3.667,3.667,0,0,0-2.252-.729c-.53,0-2.319.2-2.319,1.722,0,.464,0,1.524,3.047,2.65,1.524.53,5.1,1.789,5.1,5.763A5.11,5.11,0,0,1,38.192,26.432Z"
          transform="translate(-6.653)"
          fill={color}
        />
        <path
          className={className}
          id="Path_334"
          data-name="Path 334"
          d="M23.325,56.9a38.635,38.635,0,0,0-4.5-5.83,57.792,57.792,0,0,1-4.306-5.233,2.428,2.428,0,0,0-3.312-.6c-1.325.729-2.186,2.517-.861,5.1a33.284,33.284,0,0,0,4.24,5.233c.132.132.2.265.331.331a1.016,1.016,0,0,1-.2,1.524,1.11,1.11,0,0,1-1.325-.2c-.066-.132-.2-.2-.265-.331-1.391-1.524-2.65-2.915-3.577-4.174A6.267,6.267,0,0,1,8.618,51.2a6.3,6.3,0,0,1-.53-5.233C7.161,43.912,6.3,41.991,5.9,40.733c-1.126-3.644-6.095-2.716-5.9,1.192S1.6,58.818,5.372,63.256a45.8,45.8,0,0,0,8.148,7.022,1.809,1.809,0,0,1,.795,1.59v2.65a1.47,1.47,0,0,0,1.457,1.457h9.274A1.47,1.47,0,0,0,26.5,74.518c-.066-1.59-.132-4.24-.265-6.492A28.363,28.363,0,0,0,23.325,56.9Z"
          transform="translate(0 -12.976)"
          fill={color}
        />
        <path
          className={className}
          id="Path_335"
          data-name="Path 335"
          d="M71.512,40.755a43.328,43.328,0,0,1-2.186,5.233,6.283,6.283,0,0,1-.53,5.233c-.265.464-.6.994-.927,1.524-.927,1.259-2.186,2.65-3.577,4.174-.066.132-.2.2-.265.331A1.01,1.01,0,1,1,62.5,55.925c.132-.132.2-.265.331-.331a28.171,28.171,0,0,0,4.24-5.233c1.325-2.65.464-4.372-.861-5.1a2.428,2.428,0,0,0-3.312.6,42.777,42.777,0,0,1-4.306,5.233,44.5,44.5,0,0,0-4.571,5.83,27.69,27.69,0,0,0-2.849,11.063c-.132,2.319-.2,4.9-.265,6.492a1.47,1.47,0,0,0,1.457,1.457h9.274A1.47,1.47,0,0,0,63.1,74.474v-2.65a2.185,2.185,0,0,1,.795-1.59,45.8,45.8,0,0,0,8.148-7.022c3.776-4.438,5.167-17.423,5.366-21.331A3.009,3.009,0,0,0,71.512,40.755Z"
          transform="translate(-17.184 -12.998)"
          fill={color}
        />
      </g>
    </svg>
  );
};