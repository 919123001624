import { Typography } from "@mui/material";
import { sxLineClamp } from "./effects/sx-text-effects";

function CardDescription({ lineCount = 4, children }) {
  return (
    <Typography
      variant="body1"
      sx={{
        ...(lineCount && sxLineClamp(lineCount)),
      }}
    >
      {children}
    </Typography>
  );
}

export default CardDescription;
