import { useState, useCallback, useEffect } from 'react';
import { DropDownSelect } from '../../modal_modules';
import { connect } from 'react-redux';
import {
  fetchAllBlocksAsLists,
  fetchAllAdventureBlocksAsLists,
} from 'actions/block_actions';
import { SectionSubTitle } from '../../../common/section-title';

const mapStateToProps = ({ entities, session }) => {
  let allBlockList =
    entities.blocks.blocksAsLists && entities.blocks.blocksAsLists;
  let userInfo = session.user ? session.user.userInfo[0] : null;
  return {
    allBlockList,
    userInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllBlocksAsLists: (account_rid, block_rid, list_flag) =>
      dispatch(fetchAllBlocksAsLists(account_rid, block_rid, list_flag)),
    fetchAllAdventureBlocksAsLists: (
      account_rid,
      adventure_rid,
      block_rid,
      list_flag,
    ) =>
      dispatch(
        fetchAllAdventureBlocksAsLists(
          account_rid,
          adventure_rid,
          block_rid,
          list_flag,
        ),
      ),
  };
};
const Category = ({
  user_block_categories,
  handleCategorySelect,
  inputValues,
  currentBlock,
  openType,
  setParentBlockRid,
  setParentTripBlockRid,
  uiUpdatePayload,
  ...props
}) => {
  const {
    fetchAllBlocksAsLists,
    fetchAllAdventureBlocksAsLists,
    userInfo,
  } = props;
  const [blockCategories, setBlockCategories] = useState([]);
  const [allTripBlockList, setAllTripBlockList] = useState([]);
  const [parentBlock, setParentBlock] = useState([]);
  const [parentTripBlock, setParentTripBlock] = useState([]);
  const [allBlockList, setAllBlockList] = useState([]);
  useEffect(() => {
    if (user_block_categories && inputValues.block_category_rid) {
      setBlockCategories(
        user_block_categories.filter(
          (ele) => ele.block_category_rid === inputValues.block_category_rid,
        ),
      );
    }
  }, [user_block_categories]);

  useEffect(() => {
    if (inputValues.adventure_rid || inputValues.adventure_block_rid) {
      Promise.all([
        fetchAllAdventureBlocksAsLists(
          userInfo.account_rid,
          inputValues.adventure_rid || uiUpdatePayload.adventure_rid,
          inputValues.block_rid,
          false,
        ),
        fetchAllBlocksAsLists(
          userInfo.account_rid,
          inputValues.block_rid,
          false,
        ),
      ]).then((res) => {
        setAllTripBlockList(res[0].data.libraryLists);
        setAllBlockList(res[1].data.libraryLists);
      });
    } else {
      Promise.all([
        fetchAllBlocksAsLists(
          userInfo.account_rid,
          inputValues.block_rid,
          false,
        ),
      ]).then((res) => {
        setAllBlockList(res[0].data.libraryLists);
      });
    }
  }, []);

  useEffect(() => {
    if (inputValues.parent_block_rid && allBlockList) {
      let foundParentBlock = allBlockList.filter(
        (ele) => ele.block_rid === inputValues.parent_block_rid,
      );
      if (foundParentBlock.length > 0) setParentBlock(foundParentBlock);
    }
  }, [allBlockList]);

  useEffect(() => {
    if (allTripBlockList && (inputValues.adventure_rid || inputValues.adventure_block_rid)) {
      let foundParentBlock = allTripBlockList.filter(
        (ele) => ele.block_rid === inputValues.parent_block_rid,
      );
      if (foundParentBlock.length > 0) setParentTripBlock(foundParentBlock);
    }
  }, [allTripBlockList]);
  const isAdventureBlock = inputValues.adventure_rid || inputValues.adventure_block_rid;
  return (
    <>
      <SectionSubTitle>ORGANIZE</SectionSubTitle>
      {isAdventureBlock && (
        <DropDownSelect
          values={parentTripBlock}
          placeholder="Select trip board folder..."
          className="create_edit_block_modal_drop_down_select"
          onChange={setParentTripBlockRid}
          options={allTripBlockList}
          parentClassName="create_edit_block_modal_drop_down_select_parent folder-dropdown"
          labelField={'block_name'}
          valueField={'block_rid'}
          clearable
        />
      )}
      {!isAdventureBlock && (
        <DropDownSelect
          values={parentBlock}
          placeholder="Select library folder..."
          className="create_edit_block_modal_drop_down_select"
          onChange={setParentBlockRid}
          options={allBlockList}
          parentClassName="create_edit_block_modal_drop_down_select_parent folder-dropdown"
          labelField={'block_name'}
          valueField={'block_rid'}
          clearable
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
