
export const svgMember = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="55"
      height="56.411"
      viewBox="0, 0, 55, 56.411"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M43.077,15.385 C43.077,23.882 36.189,30.77 27.692,30.77 C19.195,30.77 12.307,23.882 12.307,15.385 C12.307,6.888 19.195,0 27.692,0 C36.189,0 43.077,6.888 43.077,15.385 z"
          fill={color}
        />
        <path
          className={className}
          d="M55,56.411 L55,48.206 C55,41.437 49.846,35.411 43.077,35.411 L12.307,35.411 C5.539,35.411 0,41.437 0,48.206 L0,56.411 L55,56.411 z"
          fill={color}
        />
      </g>
    </svg>
  );
};