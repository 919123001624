import axios from 'axios';

export const fetchItineraryDetail = (itinerary_rid, explorer_rid) => {
  return axios.patch(
    `/api/it/itinerary_details/${itinerary_rid}/${explorer_rid}`,
  );
};

export const fetchItineraryBlocks = (data) => {
  return axios.patch('/api/it/itinerary_blocks', data);
};

export const moveBlockToItnerary = (
  itinerary_item_rid,
  block_rid,
  account_rid,
  explorer_rid,
  sort_order,
) => {
  return axios.patch(
    `/api/it/move_itinerary_block/${itinerary_item_rid}/${block_rid}/${account_rid}/${explorer_rid}/${sort_order}`,
  );
};

export const moveBlockToItneraryBetweenItem = (
  itinerary_item_rid,
  block_rid,
  account_rid,
  itinerary_block_rid,
  explorer_rid,
  sort_order,
) => {
  return axios.patch(
    `/api/it/move_itinerary_block_between_items/${itinerary_item_rid}/${block_rid}/${account_rid}/${itinerary_block_rid}/${explorer_rid}/${sort_order}`,
  );
};

export const deleteItineraryBlockItem = (
  itinerary_block_rid,
  itinerary_item_rid,
  explorer_rid,
) => {
  return axios.patch(
    `/api/it/delete_itinerary_block_item/${itinerary_block_rid}/${itinerary_item_rid}/${explorer_rid}`,
  );
};

export const editItineraryItem = (itinerary_item_rid, data) => {
  return axios.patch(`/api/it/edit_it_item/${itinerary_item_rid}`, data);
};

export const editItinerary = (data) => {
  return axios.patch('/api/it/edit_it', data);
};

export const postItinerary = (adventure_rid, data) => {
  return axios.post(`/api/it/post_adventure_itinerary/${adventure_rid}`, data);
};

export const postItineraryItem = (data) => {
  return axios.post(`/api/it/itinerary_item`, data);
};

export const deleteItinerary = (adventure_rid, itinerary_rid, explorer_rid) => {
  return axios.delete(
    `/api/it/itinerary/${adventure_rid}/${itinerary_rid}/${explorer_rid}`,
  );
};

export const deleteItineraryItem = (itinerary_item_rid, itinerary_rid) => {
  return axios.delete(
    `/api/it/itinerary_item/${itinerary_item_rid}/${itinerary_rid}`,
  );
};

export const deleteItineraryItemBlock = (
  itinerary_block_rid,
  itinerary_item_rid,
  explorer_rid,
) => {
  return axios.delete(
    `/api/it/itinerary_item_block/${itinerary_block_rid}/${itinerary_item_rid}/${explorer_rid}`,
  );
};

export const fetchItineraryItemBlocks = (explorer_rid, itinerary_item_rid) => {
  return axios.patch(
    `/api/it/itinerary_item_blocks/${itinerary_item_rid}/${explorer_rid}`,
  );
};

export const copyItineraryItemBlock = (data) => {
  // debugger
  return axios.post(`/api/it/copy_itinerary_block`, data);
};

// export const fetchItineraryItemBlocks = (explorer_rid, itinerary_item_rid) => {
//   return axios.patch(
//     `/api/it/itinerary-item-blocks/${explorer_rid}/${itinerary_item_rid}`
//   );
// };

export const fetchItineraryComments = (itineraryRid) => {
  return axios.patch(`/api/it/itinerary_comments/${itineraryRid}`);
};

export const upsertItineraryComment = (data) => {
  return axios.post(`/api/it/itinerary_comments`, data);
};

export const deleteItineraryComments = (itineraryRid, itineraryCommentRid) => {
  return axios.delete(
    `/api/it/itinerary_comments/${itineraryRid}/${itineraryCommentRid}`,
  );
};

export const fetchItineraryVotes = (itineraryRid, explorerRid) => {
  return axios.patch(`/api/it/itinerary_votes/${itineraryRid}/${explorerRid}`);
};

export const upsertItineraryVotes = (data) => {
  return axios.post(`/api/it/itinerary_votes`, data);
};

export const deleteItineraryVote = (
  itineraryVoteRid,
  explorerRid,
  itineraryRid,
) => {
  return axios.delete(
    `/api/it/itinerary_votes/${itineraryVoteRid}/${explorerRid}/${itineraryRid}`,
  );
};

export const updateItineraryStartDate = (data) => {
  return axios.patch(`/api/it/itinerary_start_date`, data);
};

export const copyItinerary = (data) => {
  return axios.patch(`/api/it/itinerary/copy`, data);
};

export const fetchLocationBlocks = (data) => {
  return axios.patch(`/api/it/itinerary/location_blocks`, data);
};

export const updateItineraryTransportationType = (data) => {
  return axios.post(`/api/it/itinerary-transportation-type`, data);
};

export const fetchItineraryAndItineraryItems = (
  adventure_rid,
  explorer_rid,
) => {
  return axios.get(
    `/api/it/itinerary-and-itinerary-items/${adventure_rid}/${explorer_rid}`,
  );
};

export const fetchItineraryItemsAndBlockNames = (itinerary_rid) => {
  return axios.get(`/api/it/itinerary-items-and-block-name/${itinerary_rid}`);
};

export const calculateImpactForTrip = (data) => {
  return axios.post('/api/it/itinerary-impact', data);
};
