import axios from 'axios';


export const postInspiration = (account_rid, data) => {
  return axios.post(`/api/i/post_inspiration/${account_rid}`, data)
} 

export const fetchUserInspirations = (account_rid) => {
  // debugger
  return axios.patch(`/api/i/fetch_user_inspirations/${account_rid}`)
}

export const deleteInspiration = (account_rid, inspiration_rid) => {
  return axios.patch(`/api/i/delete_inspiration/${account_rid}/${inspiration_rid}`)
}