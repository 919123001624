import React, { useState, useEffect, useCallback } from 'react';
import { postBlockFile } from 'actions/block_actions';
import { connect } from 'react-redux';
import {
  openBlockModal,
  goBackPrevModal,
  openCheckListModal,
} from 'actions/modal_actions';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import HubModal from '../../modal';
import { SectionSubTitle } from '../../../common/section-title';
import TextBox from '../../../common/text-box';
// import Screenshot from 'url-to-screenshot'
import {
  Input,
  TextArea,
  Button,
  Title,
  DropZone,
  Tab,
  Toggle,
} from '../../modal_modules';
import { svgDelete } from 'components/svg_module';
import {} from '../../../../actions/modal_actions';
// import { createScreenShot } from "../../../../util/block_api_util"
const mapStateToProps = ({ ui, dataCache, session }) => {
  let currentBlockFile = ui.modal.currentBlockFile
    ? ui.modal.currentBlockFile
    : null;
  let currentBlockRid = ui.modal.currentBlockRid
    ? ui.modal.currentBlockRid
    : null;
  let openType = ui.modal.openType ? ui.modal.openType : null;
  let blockType = ui.modal.blockType ? ui.modal.blockType : null;
  let actionPayload = ui.modal.actionPayload ? ui.modal.actionPayload : null;
  let currentTab = ui.modal.currentTab ? ui.modal.currentTab : null;

  //passed from modal data persisters
  let currentBlock = dataCache.modal.currentBlock
    ? dataCache.modal.currentBlock
    : null;
  let currentBlockFiles = dataCache.modal.currentBlockFiles
    ? dataCache.modal.currentBlockFiles
    : null;
  let userInfo = session.user ? session.user.userInfo[0] : null;
  return {
    currentBlockRid,
    currentBlockFile,
    openType,
    blockType,
    actionPayload,
    currentTab,

    currentBlock,
    currentBlockFiles,

    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postBlockFile: (data) => dispatch(postBlockFile(data)),
    openBlockModal: (
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    ) =>
      dispatch(
        openBlockModal(
          openType,
          activityModalTypeRid,
          uiUpdatePayload,
          blockPayload,
          userInfo,
        ),
      ),
    openCheckListModal: (openType, blockType, actionPayload, data, userInfo) =>
      dispatch(
        openCheckListModal(openType, blockType, actionPayload, data, userInfo),
      ),
  };
};

const BlockFileViewer = ({
  inspirations,
  account_rid,
  startDate,
  endDate,
  postItinerary,
  adventure_rid,
  persistedData,
  persistData,
  closeModal,
  currentBlockRid,
  currentBlockFile,
  currentBlock,
  postBlockFile,
  openBlockModal,
  openType,
  blockType,
  actionPayload,
  currentTab,
  userInfo,
  openCheckListModal,

  //—————————————— fetched from modal cache reducer
  currentBlockFiles,
  goBack,
}) => {
  const [inputValues, setInputValues] = useState({
    block_file_rid: currentBlockFile ? currentBlockFile.block_file_rid : null,
    block_rid: currentBlockFile ? currentBlockFile.block_rid : currentBlockRid,
    domain_url: currentBlockFile ? currentBlockFile.domain_url : null,
    file_location: currentBlockFile ? currentBlockFile.file_location : null,
    file_name: currentBlockFile ? currentBlockFile.file_name : null,
    file_note: currentBlockFile ? currentBlockFile.file_note : null,
    full_url: currentBlockFile ? currentBlockFile.full_url : null,
    media_type: currentBlockFile ? currentBlockFile.media_type : null,
    location_flag: currentBlockFile ? currentBlockFile.location_flag : false,
  });
  const [topLabel, setTopLabel] = useState('upload');
  const [blockFilePic, setBlockFilePic] = useState(null);
  const [domainLink, setDomainLInk] = useState({
    url: null,
    domain: null,
    screenShot: null,
  });
  const [blockPic, setBlockPic] = useState(
    currentBlockFile
      ? {
          file: currentBlockFile.file_location,
          file_name: currentBlockFile.file_location,
        }
      : null,
  );
  const [blockPicFile, setBlockPicFile] = useState(
    currentBlockFile
      ? {
          file: currentBlockFile.file_location,
          file_name: currentBlockFile.file_location,
        }
      : null,
  );

  useEffect(() => {
    if (persistedData) {
      setInputValues(persistedData);
    }
  }, []);

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = (e) => {
    if (blockPic) {
      if (
        currentBlockFile &&
        currentBlockFile.file_location === blockPic.file_name
      ) {
        postBlockFile(inputValues).then(() => {
          setInputValues({
            block_file_rid: null,
            block_rid: null,
            domain_url: null,
            file_location: null,
            file_name: null,
            file_note: null,
            full_url: null,
            media_type: null,
          });
          closeModalHandler();
        });
      } else {
        if (blockPic.file_name || blockPic.file) {
          const formData = new FormData();
          const image = blockPic;
          if (!image.file.slice(0, 30).includes('blob')) {
            if (image.file.slice(0, 30).includes('pdf')) {
              let typeName = image.file.slice(5, 15);

              const slicedData = image.file.slice(28);
              const byteCharacters = atob(slicedData);
              const byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: `${typeName}` });
              blob['originalname'] = image.file_name;
              formData.set('original_name', image.file_name);
              formData.set('file', blob);
              window.formData = formData;
            } else {
              let typeName = image.file.slice(5, 15);
              if (typeName.includes('png') || typeName.includes('jpg')) {
                typeName = typeName.slice(0, typeName.length - 1);
              }
              const slicedData =
                typeName.includes('png') || typeName.includes('jpg')
                  ? image.file.slice(22)
                  : image.file.slice(23);
              const byteCharacters = atob(slicedData);
              const byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: `${typeName}` });
              blob['originalname'] = image.file_name;
              formData.set('original_name', image.file_name);
              formData.set('file', blob);
              window.formData = formData;
            }
          } else {
            // let blob = await fetch(inspirationPic).then(r => r.blob());
            // let blob2 = await window.URL.revokeObjectURL(inspirationPic)
            let blob3 = blockPicFile;
            blob3.originalname = blob3.name;
            // formData.set("file", blob);
            formData.set('file', blob3);
            window.formData = formData;
          }

          axios.post('/api/bucket/uploads', formData).then((res) => {
            let picUrl = res.data.data;
            inputValues.file_location = picUrl;
            postBlockFile(inputValues).then(() => {
              setInputValues({
                block_file_rid: null,
                block_rid: null,
                domain_url: null,
                file_location: null,
                file_name: null,
                file_note: null,
                full_url: null,
                media_type: null,
              });
              closeModalHandler();
            });
          });
        } else {
          postBlockFile(inputValues).then(() => {
            setInputValues({
              block_file_rid: null,
              block_rid: null,
              domain_url: null,
              file_location: null,
              file_name: null,
              file_note: null,
              full_url: null,
              media_type: null,
            });
            closeModalHandler();
          });
        }
      }
    } else {
      if (domainLink.url) {
        inputValues.full_url = domainLink.url;
        inputValues.domain_url = domainLink.url;
      }
      postBlockFile(inputValues).then(() => {
        closeModalHandler();
      });
    }
  };

  const renderLink = () => (
    <div className="mod-blk-file-link-wrapper">
      <div className="mod-blk-file-link-top">
        {/* <Input
          label="URL"
          name="full_url"
          onChangeHandler={handleOnChange}
          value={inputValues.full_url}
          spacingTop="10px"
          spacingBottom="20px"
        /> */}
        <TextBox
          className="text-field"
          name="full_url"
          label="URL"
          variant="outlined"
          fullWidth
          onChange={handleOnChange}
          value={inputValues.full_url}
          size="small"
        />
      </div>
      <div className="mod-blk-file-link-bottom"></div>
    </div>
  );

  const renderUpload = () => (
    <DropZone
      image={blockPic}
      setImage={setBlockPic}
      spacingTop="10px"
      spacingBottom="10px"
    />
  );
  const closeModalHandler = () => {
    let openType = {
      isTransitionWithTab: true,
      tabName: 'ATTACHMENTS',
    };
    //may be used later leaving, as template
    let blockType = {};
    let actionPayload = {};
    if (currentBlock?.list_flag) {
      openCheckListModal(
        openType,
        blockType,
        actionPayload,
        currentBlock,
        userInfo,
      );
    } else {
      openBlockModal(openType, null, null, null, userInfo);
    }
  };

  return (
    <HubModal
      show={true}
      title="ATTACHMENTS"
      buttonTitle="SAVE"
      onClose={closeModalHandler}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <Stack spacing={2}>
          <TextBox
            className="text-field"
            name="file_name"
            label="Name"
            variant="outlined"
            fullWidth
            onChange={handleOnChange}
            value={inputValues.file_name}
            size="small"
          />
          <TextBox
            name="file_note"
            label="Description"
            variant="outlined"
            multiline
            maxRows={4}
            onChange={handleOnChange}
            value={inputValues.file_note}
            size="small"
          />
          {/* <Input
            label="Name"
            name="file_name"
            onChangeHandler={handleOnChange}
            value={inputValues.file_name}
            spacingTop="10px"
            spacingBottom="20px"
          /> */}
          {/* <TextArea
            label="Description"
            name="file_note"
            onChangeHandler={handleOnChange}
            value={inputValues.file_note}
            spacingTop="10px"
            spacingBottom="20px"
          /> */}
          {/* <Toggle
            setToggleState={switchHandler}
            name="location_flag"
            value={inputValues.location_flag}
            text="Mark as location."
          /> */}
          {/* <div className="mod-tabs-wrapper">
            <Tab
              handleClick={setTopLabel}
              selected={topLabel === 'LINK'}
              text="LINK"
            />
            <Tab
              handleClick={setTopLabel}
              selected={topLabel === 'UPLOAD'}
              text="UPLOAD"
            />
          </div> */}
          <RadioGroup
            row
            style={{ color: '#827700', padding: '0px 5px' }}
            name="row-radio-buttons-group"
            defaultValue="upload"
            value={topLabel}
            onChange={(e) => setTopLabel(e.target.value)}
          >
            <FormControlLabel value="upload" control={<Radio />} label="Upload" />
            <FormControlLabel value="link" control={<Radio />} label="Link" />
          </RadioGroup>
          {topLabel === 'link' ? renderLink() : renderUpload()}
          {/* <Button spacingBottom="20px" text="SAVE" /> */}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockFileViewer);
