import merge from "lodash/merge";
import {
  RECEIVE_BLOCK_COMMENTS,
  RECEIVE_BLOCK_COMMENT_COUNT,
} from "../../constants";
const CommentsReducer = (state = { adventureData: [] }, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_BLOCK_COMMENTS:
      newState["block_comments"] = action.data.blockComments;
      return newState;
    case RECEIVE_BLOCK_COMMENT_COUNT:
      newState["block_comment_count"] = action.data;
      return newState;
    default:
      return state;
  }
};

export default CommentsReducer;
