//adventure-constants

export const RECEIVE_ADVENTURES = 'RECEIVE_ADVENTURES';
export const RECEIVE_ADVENTURE = 'RECEIVE_ADVENTURE';
export const RECEIVE_ADVENTURE_DATA = 'RECEIVE_ADVENTURE_DATA';
export const REMOVE_ADVENTURE = 'REMOVE_ADVENTURE';
export const RECEIVE_ADDED_ADVENTURE = 'RECEIVE_ADDED_ADVENTURE';
export const RECEIVE_ADVENTURE_BLOCKS = 'RECEIVE_ADVENTURE_BLOCKS';
export const RECEIVE_ADVENTURE_ITINERARIES = 'RECEIVE_ADVENTURE_ITINERARIES';
export const RECEIVE_ADVENTURE_INFORMATIONS = 'REIVE_ADVENTURE_INFORMATIONS';
export const RECEIVE_ITINERARY_INFORMATION = 'RECEIVE_ITINERARY_INFORMATION';
export const RECEIVE_ADDED_BLOCK = 'RECEIVE_ADDED_BLOCK';
export const RECEIVE_LAZY_ADVENTURES = 'RECEIVE_LAZY_ADVENTURES';
export const RECEIVE_COPIED_ADVENTURE = 'RECEIVE_COPIED_ADVENTURE';
export const RECEIVE_TEMPLATE_BLOCKS = 'RECEIVE_TEMPLATE_BLOCKS';
export const RECEIVE_TEMPLATE_LISTS = 'RECEIVE_TEMPLATE_LISTS';
export const RECEIVE_FLUSH_ADVENTURES = 'RECEIVE_FLUSH_ADVENTURES';
export const RECEIVE_FLUSH_CURRENT_ADVENTURE = 'RECEIVE_FLUSH_CURRENT_ADVENTURE';
export const RECEIVE_SELECTED_ADVENTURE = 'RECEIVE_SELECTED_ADVENTURE';
//block-constants

export const RECEIVE_USER_BLOCK_CATEGORIES = 'RECEIVE_USER_BLOCK_CATEGORIES';
export const RECEIVE_USER_BLOCK_ACTIVITIES = 'RECEIVE_USER_BLOCK_ACTIVITIES';
export const RECEIVE_USER_BLOCK_ACTIVITY_DETAILS =
  'RECEIVE_USER_BLOCK_ACTIVITY_DETAILS';
export const RECEIVE_ITINERARY_ITEM_COPIED_BLOCK =
  'RECEIVE_ITINERARY_ITEM_COPIED_BLOCK';
export const RECEIVE_BLOCK_VOTE = 'RECEIVE_BLOCK_VOTE';
export const RECEIVE_NEW_LIBRARY_BLOCK = 'RECEIVE_NEW_LIBRARY_BLOCK';
export const RECEIVE_BLOCK_LISTS = 'RECEIVE_BLOCK_LISTS';
export const RECEIVE_LIBRARY_BLOCKS = 'RECEIVE_LIBRARY_BLOCKS';
export const RECEIVE_LIBRARY_LISTS = 'RECEIVE_LIBRARY_LISTS';
export const RECEIVE_ALL_BLOCKS_AS_LISTS = 'RECEIVE_ALL_BLOCKS_AS_LISTS';
export const RECEIVE_LIST_DROPDOWN = 'RECEIVE_LIST_DROPDOWN';
export const RECEIVE_BLOCK_FAMILY = 'RECEIVE_BLOCK_FAMILY';
export const RECEIVE_BLOCK_CHECK = 'RECEIVE_BLOCK_CHECK';
export const RECEIVE_ADVENTURE_RID_EXIST_FLAG =
  'RECEIVE_ADVENTURE_RID_EXIST_FLAG';
export const RECEIVE_FOLDER_CONTENTS = 'RECEIVE_FOLDER_CONTENTS';
export const RECEIVE_ITINERARY_BLOCK_SPECTATORS =
  'RECEIVE_ITINERARY_BLOCK_SPECTATORS';
export const RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES =
  'RECEIVE_TRAVEL_METHOD_BLOCK_CATEGORIES';
export const RECEIVE_ADDRESS_BOOK_BLOCKS = 'RECEIVE_ADDRESS_BOOK_BLOCKS';
//inspiration-constant

export const RECEIVE_INSPIRATION = 'RECEIVE_INSPIRATION';
export const REMOVE_INSPIRATION = 'REMOVE_INSPIRATION';
export const RECEIVE_INSPIRATIONS = 'RECEIVE_INSPIRATIONS';
export const RECEIVE_ADDED_INSPIRATION = 'RECEIVE_ADDED_INSPIRATION';
export const RECEIVE_USER_INSPIRATIONS = 'RECEIVE_USER_INSPIRATIONS';
export const RECEIVE_CHANGED_TREE = 'RECEIVE_CHANGED_TREE';
export const RECEIVE_PEOPLE_ASSIGNED = 'RECEIVE_PEOPLE_ASSIGNED';
export const RECEIVE_IS_ADVENTURE_BLOCK = 'RECEIVE_IS_ADVENTURE_BLOCK';
export const RECEIVE_FLUSH_LOCATION_BLOCKS = 'RECEIVE_FLUSH_LOCATION_BLOCKS';
export const RECEIVE_ADVENTURE_NOOP = 'RECEIVE_ADVENTURE_NOOP';
//itinerary-constants

export const RECEIVE_USER_ITINERARY_DETAILS = 'RECEIVE_USER_ITINERARY_DETAILS';
export const RECEIVE_MOVED_BLOCK = 'RECEIVE_MOVED_BLOCK';
export const RECEIVE_MOVED_BLOCK_BETWEEN_ITEM =
  'RECEIVE_MOVED_BLOCK_BETWEEN_ITEM';
export const RECEIVE_ITINERARY_ITEM = 'RECEIVE_ITINERARY_ITEM';
export const RECEIVE_EDITED_ITINERARY_ITEM = 'RECEIVE_EIDTED_ITINERARY_ITEM';
export const RECEIVE_USER_ITINERARY = 'RECEIVE_USER_ITINERARY';
export const RECEIVE_ITINERARY_ITEMS = 'RECEIVE_ITINERARY_ITEMS';
export const RECEIVE_BLOCK_VOTES_ALL = 'RECEIVE_BLOCK_VOTES_ALL';
export const RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED =
  'RECEIVE_ITINERARY_ITEM_BLOCKS_UPDATED';
export const RECEIVE_ITINERARY_ITEM_BLOCKS = 'RECEIVE_ITINERARY_ITEM_BLOCKS';
export const RECEIVE_ITINERARY_COMMENTS = 'RECEIVE_ITINERARY_COMMENTS';
export const RECEIVE_ITINERARY_VOTES = 'RECEIVE_ITINERARY_VOTES';
export const RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE =
  'RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE';
export const RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE =
  'RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE';
export const RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE =
  'RECEIVE_SINGLE_ITINERARY_ITEM_BLOCK_UPDATE';
export const RECEIVE_MOVE_ITINERARY_ITEM_ONLY =
  'RECEIVE_MOVE_ITINERARY_ITEM_ONLY';
export const RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST =
  'RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST';
export const RECEIVE_ITINERARY_LOCATION_BLOCKS =
  'RECEIVE_ITINERARY_LOCATION_BLOCKS';
export const RECEIVE_TRIP_BOARD_LOCATION_BLOCKS =
  'RECEIVE_TRIP_BOARD_LOCATION_BLOCKS';
export const RECEIVE_ITINERARY_MAP_FILTERS = 'RECEIVE_ITINERARY_MAP_FILTERS';
export const RECEIVE_FLUSH_ITINERARY_DATA = 'RECEIVE_FLUSH_ITINERARY_DATA';
export const RECEIVE_ITINERARY_AND_ITINERARY_ITEMS =
  'RECEIVE_ITINERARY_AND_ITINERARY_ITEMS';

export const RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES =
  'RECEIVE_ITINERARY_ITEMS_AND_BLOCK_NAMES';
//modal-contanstants

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_CREATE_BLOCK = 'OPEN_CREATE_BLOCK';
export const OPEN_CREATE_BLOCK_FILE = 'OPEN_CREATE_BLOCK_FILE';
export const OPEN_CREATE_INSPIRATION = 'OPEN_CREATE_INSPIRATION';
export const OPEN_CREATE_ITINERARY = 'OPEN_CREATE_ITINERARY';
export const OPEN_CREATE_ITINERARY_ITEM = 'OPEN_CREATE_ITINERARY_ITEM';
export const OPEN_CREATE_ADVENTURE = 'OPEN_CREATE_ADVENTURE';
export const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL';
export const OPEN_DATE_PICKER_MODAL = 'OPEN_DATE_PICKER_MODAL';
export const RECEIVE_DATES = 'RECEIVE_DATES';
export const RECEIVE_TIMES = 'RECEIVE_TIMES';
export const RECEIVE_DATA_PASSOVER = 'RECEIVE_DATA_PASSOVER';
export const OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL';
export const OPEN_SHARE_PERMISSION_MODAL = 'OPEN_SHARE_PERMISSION_MODAL';
export const OPEN_SHARE_TAGS_MODAL = 'OPEN_SHARE_TAGS_MODAL';
export const OPEN_FRIENDS_MODAL = 'OPEN_FRIENDS_MODAL';
export const OPEN_ACCOUNT_GROUP_MODAL = 'OPEN_ACCOUNT_GROUP_MODAL';
export const OPEN_ADVENTURE_GROUP_MODAL = 'OPEN_ADVENTURE_GROUP_MODAL';
export const OPEN_ACCOUNT_MODAL = 'OPEN_ACOUNT_MODAL';
export const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL';
export const RECEIVE_MODAL_BLOCK_FILES = 'RECEIVE_MODAL_BLOCK_FILES';
export const OPEN_TIME_PICKER_MODAL = 'OPEN_TIME_PICKER_MODAL';
export const OPEN_FETCH_UNSPLASH_MODAL = 'OPEN_FETCH_UNSPLASH_MODAL';
export const RECEIVE_IMAGES_SELECTED = 'RECEIVE_IMAGES_SELECTED';
export const RECEIVE_LOADING_MODAL = 'RECEIVE_LOADING_MODAL';
export const RECEIVE_FLUSH_MODAL = 'RECEIVE_FLUSH_MODAL';
export const RECEIVE_ADVENTURE_INVITE_FRIEND_MODAL =
  'RECEIVE_ADVENTURE_INVITE_FRIEND_MODAL';
export const RECEIVE_OPEN_CONTACTS_MODAL = 'RECEIVE_OPEN_CONTACTS_MODAL';
export const RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL =
  'RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL';
export const RECEIVE_INVITE_NEW_MODAL = 'RECEIVE_INVITE_NEW_MODAL';
export const RECEIVE_OPEN_ASSIGN_USERS_MODAL =
  'RECEIVE_OPEN_ASSIGN_USERS_MODAL';
export const RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL =
  'RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL';

export const RECEIVE_OPEN_CHECK_LIST_MODAL = 'RECEIVE_OPEN_CHECK_LIST_MODAL';
export const RECEIVE_OPEN_COPY_MODAL = 'RECEIVE_OPEN_COPY_MODAL';

export const RECEIVE_OPEN_IMAGE_OP_MODAL = 'RECEIVE_OPEN_IMAGE_OP_MODAL';
export const RECEIVE_OPEN_ADVENTURE_COPY_MODAL =
  'RECEIVE_OPEN_ADVENTURE_COPY_MODAL';
export const RECEIVE_MESSAGE_ALERT_MODAL = 'RECEIVE_MESSAGE_ALERT_MODAL';
export const RECEIVE_OPEN_CHAT_ROOM_MODAL = 'RECEIVE_OPEN_CHAT_ROOM_MODAL';
export const RECEIVE_OPEN_FOLDER_MODAL = 'RECEIVE_OPEN_FOLDER_MODAL';
export const RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL =
  'RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL';
export const RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL =
  'RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL';
export const RECEIVE_OPEN_TRIP_INVITE_MODAL = 'RECEIVE_OPEN_TRIP_INVITE_MODAL';
export const RECEIVE_OPEN_MAP_FILTER_MODAL = 'RECEIVE_OPEN_MAP_FILTER_MODAL';
export const RECEIVE_OPEN_CAMPGROUND_MODAL = 'RECEIVE_OPEN_CAMPGROUND_MODAL';

// resource-constants

export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES';
export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE';
export const REMOVE_RESOURCE = 'REMOVE_RESOURCE';
export const RECEIVE_RESOURCE_FILES = 'RECEIVE_RESOURCE_FILES';
export const RECEIVE_RESOURCE_FILE = 'RECEIVE_RESOURCE_FILE';
export const REMOVE_RESOURCE_FILE = 'REMOVE_RESOURCE_FILE';

//session-actions

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_ERRORS';
export const RECEIVE_USER_LOGOUT = 'RECEIVE_USER_LOGOUT';
export const RECEIVE_USER_SIGN_IN = 'RECEIVE_USER_SIGN_IN';
export const RECEIVE_USER_INCOMING_NOTIFICATION =
  'RECEIVE_USER_INCOMING_NOTIFICATION';

//site -actions
export const RECEIVE_USER_SITES = 'RECEIVE_USER_SITES';
export const RECEIVE_USER_SITE = 'RECEIVE_USER_SITE';
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const RECEIVE_SITE = 'RECEIVE_SITE';
export const REMOVE_SITE = 'REMOVE_SITE';
export const RECEIVE_NUM = 'RECEIVE_NUM';

//ui-constants
export const RECEIVE_PAGE_KEY_WORDS = 'RECEIVE_PAGE_KEY_WORDS';
export const RECEIVE_PAGE_META_DATA = 'RECEIVE_PAGE_META_DATA';
export const RECEIVE_PAGE_DATA = 'RECEIVE_PAGE_DATA';
export const RECEIVE_PAGE_TOOL = 'RECEIVE_PAGE_TOOL';
export const RECEIVE_ACCOUNT_ROLES = 'RECEIVE_ACCOUNT_ROLES';
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';
export const RECEIVE_STATES = 'RECEIVE_STATES';
export const RECEIVE_PLAN_DETAILS = 'RECEIVE_PLAN_DETAILS';
export const RECEIVE_PLAN_FINAL_PIRCE = 'RECEIVE_PLAN_FINAL_PRICE';
export const RECEIVE_UNSUBSCRIPTION_REASONS = 'RECEIVE_UNSUBSCRIPTION_REASONS';
export const RECEIVE_AMBASSADOR_CODES = 'RECEIVE_AMBASSADOR_CODES';
export const RECEIVE_OPEN_DRAWER_WITH_TAB = 'RECEIVE_OPEN_DRAWER_WITH_TAB';
export const RECEIVE_PROMOTION_BANNER = 'RECEIVE_PROMOTION_BANNER';
export const RECEIVE_PARTNER_PROMOTION_CODES =
  'RECEIVE_PARTNER_PROMOTION_CODES';
export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';
export const RECEIVE_SELECTED_PARTNER = 'RECEIVE_SELECTED_PARTNER';
export const RECEIVE_FLUSH_PARTNERS = 'RECEIVE_FLUSH_PARTNERS';
export const RECEIVE_FLUSH_SELECTED_PARTNERS =
  'RECEIVE_FLUSH_SELECTED_PARTNERS';
export const RECEIVE_PARTNER_DONATION_DISTRIBUTION =
  'RECEIVE_PARTNER_DONATION_DISTRIBUTION';
export const RECEIVE_TRANSPORTATION_TYPES = 'RECEIVE_TRANSPORTATION_TYPES';
export const SET_MODAL_POPUP_TOGGLE = 'SET_MODAL_POPUP_TOGGLE';
export const SET_DETAIL_POPUP_TOGGLE = 'SET_DETAIL_POPUP_TOGGLE';
export const SET_ACTIVE_MAP_LAYERS = 'SET_ACTIVE_MAP_LAYERS';
//user-constants

export const RECEIVE_USER = 'RECEIVE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const RECEIVE_ALL_USERS = 'RECEIVE_ALL_USERS';
export const RECEIVE_BOARDING_USER = 'RECEIVE_BOARDING_USER';
export const RECEIVE_EXPLORER_DETAIL = 'RECEIVE_EXPLORER_DETAIL';
export const RECEIVE_ALL_ACCOUNT_EXPLORERS = 'RECEIVE_ALL_ACCOUNT_EXPLORERS';
export const RECEIVE_ACCOUNT_DETAILS = 'RECEIVE_ACCOUNT_DETAILS';
export const RECEIVE_ACCOUNT_PARTNER_DONATIONS =
  'RECEIVE_ACCOUNT_PARTNER_DONATIONS';
export const RECEIVE_FLUSH_ACCOUNT_DONATIONS =
  'RECEIVE_FLUSH_ACCOUNT_DONATIONS';
//social constants

export const RECEIVE_USER_FRIENDS = 'RECEIVE_USER_FRIENDS';
export const RECEIVE_USER_UNFRIENDS = 'RECEIVE_USER_UNFRIENDS';
export const RECEIVE_ACCOUNT_GROUP = 'RECEIVE_ACCOUNT_GROUP';
export const RECEIVE_ACCOUNT_GROUP_FRIENDS = 'RECEIVE_ACCOUNT_GROUP_FRIENDS';
export const RECEIVE_ADVENTURE_FRIENDS = 'RECEIVE_ADVENTURE_FRIENDS';
export const RECEIVE_ADVENTURE_GROUP = 'RECEIVE_ADVENTURE_GROUP';
export const RECEIVE_ADVENTURE_GROUP_FRIENDS =
  'RECIEVE_ADVENTURE_GROUP_FRIENDS';
export const RECEIVE_USER_RECENT_BLOCK_UPDATE =
  'RECEIVE_USER_RECENT_BLOCK_UPDATE';
export const RECEIVE_USER_PAYMENT = 'RECEIVE_USER_PAYMENT';
export const RECEIVE_USER_SUBSCRIPTION = 'RECEIVE_USER_SUBSCRIPTION';
export const RECEIVE_USER_FRIEND_REQUESTS = 'RECEIVE_USER_FRIEND_REQUESTS';
export const RECEIVE_USER_ADVENTURE_INVITES = 'RECEIVE_USER_ADVENTURE_INVITES';
export const RECEIVE_DELETE_USER_FRIEND = 'RECEIVE_DELETE_USER_FRIEND';
export const RECEIVE_UPDATE_USER_UNFRIENDS = 'RECEIVE_UPDATE_USER_UNFRIENDS';
export const RECEIVE_USER_FRIEND_UPDATES = 'RECEIVE_USER_FRIEND_UPDATES';
export const RECEIVE_FLUSH_USER_UNFRIENDS = 'RECEIVE_FLUSH_USER_UNFRIENDS';
export const RECEIVE_FLUSH_USER_FRIENDS = 'RECEIVE_FLUSH_USER_FRIENDS';

//comments constant

export const RECEIVE_BLOCK_COMMENTS = 'RECEIVE_BLOCK_COMMENTS';
export const RECEIVE_BLOCK_COMMENT_COUNT = 'RECEIVE_BLOCK_COMMENT_COUNT';

//third party constants

export const RECEIVE_UNSPLASH_IMAGES = 'RECEIVE_UNSPLASH_IMAGES';
export const RECEIVE_PEXEL_IMAGES = 'RECEIVE_PEXEL_IMAGES';
export const RECEIVE_FLUSH_FETCHED_IMAGES = 'RECEIVE_FLUSH_FETCHED_IMAGES';

//data transition constants

export const PERSIST_UPSERT_ADVENTURE_MODAL = 'PERSIST_UPSERT_ADVENTURE_MODAL';
export const PERSIST_UPSERT_BLOCK_MODAL = 'PERSIST_UPSERT_BLOCK_MODAL';
export const PERSIST_UPSERT_BLOCK_MODAL_FILE =
  'PERSIST_UPSERT_BLOCK_MODAL_FILE';
export const PERSIST_UPSERT_BLOCK_FILE_MODAL =
  'PERSIST_UPSERT_BLOCK_FILE_MODAL';
export const PERSIST_UPSERT_ITINERARY_MODAL = 'PERSIST_UPSERT_ITINERARY_MODAL';
export const PERSIST_UPSERT_ITINERARY_ITEM_MODAL =
  'PERSIST_UPSERT_ITINERARY_ITEM_MODAL';

export const PERSIST_UPSERT_ACCOUNT_FRIEND_MODAL =
  'PERSIST_UPSERT_ACCOUNT_FRIEND_MODAL';
export const PERSIST_UPSERT_ACCOUNT_GROUP_MODAL =
  'PERSIST_UPSERT_ACCOUNT_GROUP_MODAL';
export const PERSIST_UPSERT_ADVENTURE_FRIEND_MODAL =
  'PERSIST_UPSERT_ADVENTURE_FRIEND_MODAL';
export const PERSIST_UPSERT_ADVENTURE_GROUP_MODAL =
  'PERSIS_UPSERT_ADVENTURE_GROUP_MODAL';
export const PERSIST_CONTACTS_MODAL = 'PERSIST_CONTACTS_MODAL';
export const PERSIST_ASSIGNED_USERS_FOR_LIST =
  'PERSIST_ASSIGNED_USERS_FOR_LIST';
export const UPDATE_FEATURE_MAP_RID = 'UPDATE_FEATURE_MAP_RID';
export const PERSIST_REC_GOV_CAMPGROUNDS = 'PERSIST_REC_GOV_CAMPGROUNDS';
export const PERSIST_REC_GOV_CAMPSITES = 'PERSIST_REC_GOV_CAMPSITES';
//chatroom constants

export const SEARCH_CHATROOM = 'SEARCH_CHATROOM';
export const CHAT_ROOM_SELECTED = 'CHAT_ROOM_SELECTED';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CHAT_ROOM_LIST = 'CHAT_ROOM_LIST';
export const USER_LIST = 'USER_LIST';
export const CHAT_SERVER_LOGIN = 'CHAT_LOGIN';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const NEW_USER = 'NEW_USER';
export const NEW_CHAT_ROOM = 'NEW_CHAT_ROOM';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const CHAT_PERSON_TYPE_USER = 'USER';
export const CHAT_PERSON_TYPE_CHAT_ROOM = 'CHAT_ROOM';
export const CURRENT_CHAT_ROOM_LIST = 'CURRENT_CHAT_ROOM_LIST';

// chatroom constants scratch

export const RECEIVE_ROOM_NAME = 'RECEIVE_ROOM_NAME';
export const RECEIVE_CHAT_ROOMS = 'RECEIVE_CHAT_ROOMS';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_CHATROOM_USERS = 'RECEIVE_CHATROOM_USERS';
export const RECEIVE_CHATROOM_DETAILS = 'RECEIVE_CHATROOM_DETAILS';

//notification constants

export const RECEIVE_NEW_NOTIFICATION = 'RECEIVE_NEW_NOTIFICATION';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const RECEIVE_NOTIFICATION_COUNT = 'RECEIVE_NOTIFICATION_COUNT';
export const RECEIVE_DISPLAY_NOTIFICATION_BADGE =
  'RECEIVE_DISPLAY_NOTIFICATION_BADGE';
export const RECEIVE_UNDISPLAY_NOTIFICATION_BADGE =
  'RECEIVE_UNDISPLAY_NOTIFICATION_BADGE';

// additional pop up constants

export const RECEIVE_OPEN_LIST_EDIT_POPUP = 'RECEIVE_OPEN_LIST_EDIT_POPUP';
export const RECEIVE_CLOSE_POP_UP = 'RECEIVE_CLOSE_POP_UP';

// Vote Up / Down Constants

export const UPDATE_VOTE_INFO = 'UPDATE_VOTE_INFO';

//impact constants

export const RECEIVE_CALCULATED_IMPACT_CARBON =
  'RECEIVE_CALCULATED_IMPACT_CARBON';
export const RECEIVE_SOCIAL_COST_OF_CARBON = 'RECEIVE_SOCIAL_COST_OF_CARBON';

// socket constants

//export const WS_BASE = 'http://localhost:8080';
export const WS_BASE = 'https://lodestar-vpc-host-nonprod.wl.r.appspot.com';
// export const WS_BASE =
//   'https://test-hub-web-dot-lodestar-vpc-host-nonprod.wl.r.appspot.com/';

//modal v2

export const USE_MODAL = 'USE_MODAL';
export const STOP_MODAL = 'STOP_MODAL';
export const IMPACT_MODAL = 'IMPACT_MODAL';

// map option
export const MAP_OPTION_ITINERARY_ONLY = 'MAP_OPTION_ITINERARY_ONLY';
export const MAP_OPTION_TRIP_BOARD = 'MAP_OPTION_TRIP_BOARD';
export const MAP_OPTION_MY_ROUTE = 'MAP_OPTION_MY_ROUTE';
export const MAP_STYLE_NAVIGATION = 'MAP_STYLE_NAVIGATION';
export const MAP_STYLE_SATELLITE = 'MAP_STYLE_SATELLITE';
export const MAP_STYLE_TERRAIN = 'MAP_STYLE_TERRAIN';
