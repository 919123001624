import merge from 'lodash/merge';

import {
  RECEIVE_ADVENTURE_BLOCKS,
  RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE,
  RECEIVE_EDITED_ITINERARY_ITEM,
  RECEIVE_FLUSH_ITINERARY_DATA,
  RECEIVE_FLUSH_LOCATION_BLOCKS,
  RECEIVE_ITINERARY_AND_ITINERARY_ITEMS,
  RECEIVE_ITINERARY_COMMENTS,
  RECEIVE_ITINERARY_INFORMATION,
  RECEIVE_ITINERARY_ITEMS,
  RECEIVE_ITINERARY_LOCATION_BLOCKS,
  RECEIVE_ITINERARY_MAP_FILTERS,
  RECEIVE_ITINERARY_VOTES,
  RECEIVE_MOVE_ITINERARY_ITEM_ONLY,
  RECEIVE_NEW_LIBRARY_BLOCK,
  RECEIVE_TRIP_BOARD_LOCATION_BLOCKS,
  RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST,
  RECEIVE_USER_ITINERARY_DETAILS,
} from '../../constants';
const ItineraryReducer = (state = { itineraryDetails: [] }, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_USER_ITINERARY_DETAILS:
      newState['itineraryDetails'] = action.data.itineraryDetails;
      // debugger
      return newState;
    case RECEIVE_ADVENTURE_BLOCKS:
      // debugger
      newState['adventure_blocks'] = action.data.userAdventureBlocks
        ? action.data.userAdventureBlocks
        : action.data.userBlocks;
      return newState;
    case RECEIVE_NEW_LIBRARY_BLOCK:
      newState.adventure_blocks.push(action.data);
      // newState["adventure_blocks"] = newState.adventure_blocks.push(action.data)
      return newState;
    case RECEIVE_ITINERARY_INFORMATION:
      newState['itinerary_info'] = action.data[0];
      return newState;
    case RECEIVE_EDITED_ITINERARY_ITEM:
      newState['itinerary_info'] = action.data.updatedItinerary;
      return newState;
    case RECEIVE_ITINERARY_ITEMS:
      newState['itineraryDetails'] = action.data.itineraryDetails;
      return newState;
    case RECEIVE_ITINERARY_COMMENTS:
      newState['itineraryComments'] = action.data.itineraryComments;
      return newState;
    case RECEIVE_ITINERARY_VOTES:
      newState['itineraryVotes'] = action.data.itineraryVotes;
      return newState;
    case RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE:
      newState.adventure_blocks[action.data.idx].comment_count += 1;
      return newState;
    case RECEIVE_MOVE_ITINERARY_ITEM_ONLY:
      newState['itineraryDetails'] = action.data.itineraryDetails;
      return newState;
    case RECEIVE_UPDATE_ADVENTURE_BLOCK_AND_ITINERARY_BLOCK_CHECKLIST:
      if (newState.adventure_blocks) {
        newState.adventure_blocks.forEach((ele, idx) => {
          if (ele.adventure_block_rid === action.data.adventureBlockRid)
            action.data.actionType === '+'
              ? (newState.adventure_blocks[idx].completed_checklist_total += 1)
              : (newState.adventure_blocks[idx].completed_checklist_total -= 1);
        });
      }
      return newState;
    case RECEIVE_ITINERARY_LOCATION_BLOCKS:
      newState['locationBlocks'] = action.data.locationBlocks;
      return newState;
    case RECEIVE_TRIP_BOARD_LOCATION_BLOCKS:
      newState['tripBoardLocationBlocks'] = action.data.locationBlocks;
      return newState;
    case RECEIVE_ITINERARY_MAP_FILTERS:
      newState['mapFilter'] = action.data;
      return newState;
    case RECEIVE_FLUSH_LOCATION_BLOCKS:
      delete newState.locationBlocks;
      return newState;
    case RECEIVE_FLUSH_ITINERARY_DATA:
      delete newState.itineraryDetails;
      delete newState.locationBlocks;
      delete newState.itineraryVOtes;
      delete newState.itineraryComments;
      delete newState.itinerary_info;
      delete newState.adventure_blocks;
      return newState;
    case RECEIVE_ITINERARY_AND_ITINERARY_ITEMS:
      newState['itineraryAndItineraryItems'] = action.data;
      return newState;
    default:
      return state;
  }
};

export default ItineraryReducer;
