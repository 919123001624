import React, { useState } from 'react';
import { svgTime } from 'components/svg_module';
import { openTimePickerModal } from '../../../actions/modal_actions';
import { connect } from 'react-redux';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    openTimePickerModal: (data) => dispatch(openTimePickerModal(data)),
  };
};

const DatePicker = ({
  spacingTop,
  spacingBottom,
  openTimePickerModal,
  persistParentData,
  startDate,
  endDate,
  originalStartDate,
  originalEndDate,
  origin, // pass the id of the html tag of the most outer div tag of the
  // component that is rendering this date picker
}) => {
  const [displayDate, toggleDisplayDate] = useState(false);
  const handleDisplay = () => {
    toggleDisplayDate(!displayDate);
  };
  if (originalStartDate || originalEndDate) {
    startDate = originalStartDate;
    endDate = originalEndDate;
  }

  return (
    <div
      className="mod-dte-pkr-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
    >
      <div
        className="mod-dte-pkr-untrig"
        // onClick={() => toggleDisplayDate(!displayDate)}
        onClick={() => {
          persistParentData();
          openTimePickerModal(origin);
        }}
      >
        {svgTime('white', 'mod-svg-cal', 'mod-svg-cal-wrapper')}
        <span>Choose your template</span>
      </div>
    </div>
  );
  // : <div className='modal-layer-two'>
  //
  //     </div>
};

export default connect(null, mapDispatchToProps)(DatePicker);
