import React, {useEffect, useCallback} from "react";

const GroupBlock = ({ group, selected, onClickHandler }) => {
  const clickHandler = useCallback((e) => {

  })
  return (
    <div
      className={`group-block-wrapper ${selected && "group-block-selected"}`}
      onClick={onClickHandler}
    >
      <div className="group-block--top">{group.group_name}</div>
      <div className="group-block--middle">{group.member_count}</div>
      <div className="group-block--bottom">MEMBERS</div>
    </div>
  );
};

export default GroupBlock;
