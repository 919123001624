import React from 'react';
import {
  svgTwitter,
  svgLinkedIn,
  svgInstagram,
  svgFacebook,
} from 'components/svg_module';
const SquareBlock = ({
  type,
  text,
  icon,
  topText,
  bottomText,
  clickHandler,
}) => {
  let svgIcon;
  switch (icon) {
    case 'facebook':
      svgIcon = svgFacebook('color', 'mod-sq-blk-svg', 'mod-sq-blk-svg-outer');
      break;
    case 'instagram':
      svgIcon = svgInstagram('color', 'mod-sq-blk-svg', 'mod-sq-blk-svg-outer');
      break;
    case 'linkedIn':
      svgIcon = svgLinkedIn('color', 'mod-sq-blk-svg', 'mod-sq-blk-svg-outer');
      break;
    case 'twitter':
      svgIcon = svgTwitter('color', 'mod-sq-blk-svg', 'mod-sq-blk-svg-outer');
      break;
    default:
      return null;
  }
  return (
    <div
      className={`mod-sqr-block-wrapper type${type}`}
      onClick={() => clickHandler()}
    >
      {type === 1 && svgIcon}
      {type === 3 && (
        <div className="mod-sq-blk-tp3-header">
          <span>{topText}</span>
        </div>
      )}
      {type === 2 && (
        <div className="mod-sq-blk-tp2-text">
          <span>{text}</span>
        </div>
      )}
      {(type === 1 || type === 3) && (
        <div className="mod-sq-blk-btm-text">
          <span>{bottomText}</span>
        </div>
      )}
    </div>
  );
};

export default SquareBlock;
