import React from 'react'

const LongRectBlock = ({
  title,
  text,
  value,
  clickHandler,
  currentValue,  
  name
}) => {
  return (
    <div name={name} value={value} className={`mod-long-rect-blk-wrapper ${currentValue === value && 'mod-long-rect-selected'}`} onClick={() => {clickHandler({name, value})}}>
      <div className="mod-long-rect-blk-top"><span>{title}</span></div>
      <div className="mod-long-rect-blk-btm"><span>{text}</span></div>
    </div>
  )
}
   
export default LongRectBlock