import Encrypter from 'crypto-js';
const secretOrKey = 'z52xcvxcv@#@$%$%#$%sfxcvsd';
const useMaskingHooks = () => {
  const maskURL = (string) => {
    return encodeURIComponent(Encrypter.AES.encrypt(string, secretOrKey));
  };

  const unmaskURL = (string) => {
    return Encrypter.AES.decrypt(
      decodeURIComponent(string),
      secretOrKey,
    ).toString(Encrypter.enc.Utf8);
  };

  return {
    maskURL,
    unmaskURL,
  };
};

export default useMaskingHooks;
