/*
functions for search:
 (optional) - search all > al
 - search blocks > onClick > ?
 - search friends > onClick > go to friend profile
 - search inspiration > onClick > go to inspiration_selected
 - search adventure > onClick > go to selected adventure_selected
 - search share > onClick > ?

*/

import React, { useState, useCallback, useEffect } from 'react';

import { connect } from 'react-redux';
import { postInspiration } from 'actions/inspiration_actions';

import { Input, TextArea, Button, DropZone, Title } from '../../modal_modules';
import axios from 'axios';
import { svgClose } from 'components/svg_module';

const SearchModal = ({ closeModal }) => {
  const [inputValues, setInputValues] = useState({
    searchText: '',
  });
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleSubmit = () => {
    return;
  };

  return (
    <div className="mod-type1-wrapper">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="SEARCH" />
        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Input
              label="Search"
              name="searchText"
              onChangeHandler={handleOnChange}
              value={inputValues.searchText}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <Button spacingBottom="20px" text="SEARCH" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, null)(SearchModal);
