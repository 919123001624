import { Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { useQueryClient } from '@tanstack/react-query';
import { svgAccept } from 'components/svg_module';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import { merge } from 'lodash';
import { createRef, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import useMaskingHooks from 'util/custom_hooks/maskUrl';
import { reformatItinerayItems } from 'util/helpers/itinerary_item_helper';
import useActivityModalHooks from '../../../../util/feature_systems/activityModal';
import HubModal from '../../modal';
import Category from './category';
import ColorPicker from './color_picker';
import { mapDispatchToProps, mapStateToProps } from './container';
import Files from './files';
import ItineraryItems from './itinerary_items';
import Location from './location';
import Social from './social';
import Summary from './summary';

const buttonTextArr = ['Organize', 'Set location', 'Add to Itinerary'];
const DEFAULT_INPUT = {
  account_rid: null,
  block_rid: null,
  inspiration_rid: null,
  adventure_rid: null,
  adventure_block: null,
  inspiration_block_rid: null,
  itinerary_item_rid: null,
  itinerary_rid: null,
  parent_block_rid: null,
  block_name: '',
  block_description: '',
  block_category_rid: null,
  block_activity_rid: null,
  activity_detail_rid: null,
  block_count: null,
  ui_color: null,
  ui_image_location: null,
  vote_flag: true,
  list_flag: false,
  completed_flag: false,
  comment_flag: false,
  region_rid: null,
  country_rid: null,
  state_providence_rid: null,
  meta_data: null,
  location_flag: false,
  location_point: { lat: null, lng: null },
  auto_sync_flag: true,
  folder_flag: false,
  template_flag: false,
};
const CreateBlock = ({
  user_block_categories,
  deleteAdventureBlock,
  closeModal,
  openBlockFileModal,
  deleteBlockFile,
  history,
  match,
  location,
  userInfo,
  postBlockComment,
  blockVotes,
  blockComments,
  blockLists,
  upsertItineraryItemBlock,
  postAdventureBlockComment,
  persistBlockModalData,

  //—————————————— fetched from modal reducer
  openType,
  currentTab,

  //—————————————— fetched from modal cache reducer
  currentBlockFiles,
  setPrevModal,
  persistedModalData,
  isAdventureBlock,
  moveToAdventureBlock,
  copyToTripBoard,
  copyBlockAndAddToItineraryItems,

  //---------------- used for updating modal cache
  updateActivityModalFeatureMapRid,

  //------------- used for itinerary block spectators
  adventureFriends,
  itineraryBlockSpectators,
  fetchItineraryBlockSpectators,
  upsertItineraryBlockSpectator,
  deleteItineraryBlockSpectator,
  // v2

  blockPayload,
  activityModalTypeRid,
  uiUpdatePayload,

  // used for itinerary items,
  itineraryItemsAndBlockNames,
  fetchItineraryItemsAndBlockNames,
  //others
  triggerUserLocation,

  //for airports
  searchAirports,
  ...props
}) => {
  useEffect(() => {
    if (blockPayload.itineriary_block_rid) {
      fetchItineraryBlockSpectators(blockPayload.itineriary_block_rid);
    }
    if (uiUpdatePayload.itineraryInfo) {
      fetchItineraryItemsAndBlockNames(
        uiUpdatePayload.itineraryInfo.itinerary_rid,
      );
    }
    function disableFocusinEvent(event) {
      if (event.currentTarget instanceof HTMLInputElement) {
      } else {
        event.stopPropagation();
      }
    }

    // Disable the "focusin" event for the document
    document.addEventListener('focusin', disableFocusinEvent, {
      capture: true,
    });
  }, []);
  const { invalidateQueries } = useInvalidateAdventureData();

  const { unmaskURL } = useMaskingHooks();
  const scrollerRef = createRef(null);

  const matched = matchPath(location.pathname, {
    path: location.pathname.includes('adventure')
      ? '/hub/adventure/:adventure/:adventure_rid'
      : '/hub/itinerary/:adventure_rid/:itinerary_rid',
    exact: true,
    strict: false,
  });
  let pageAdventureRid;
  let isAddingToAdventureBlock;
  if (matched) {
    isAddingToAdventureBlock = true;
    pageAdventureRid = unmaskURL(matched.params.adventure_rid);
  }
  const [triggerCommentsButton, setTriggerCommentsButton] = useState('load');
  const [distance, setDistance] = useState(0);
  const [parentBlockRid, setParentBlockRid] = useState(null);
  const [parentTripBlockRid, setParentTripBlockRid] = useState(null);
  const [triggerNext, setTriggerNext] = useState({
    display: false,
    newBlock: null,
  });
  const [inputValues, setInputValues] = useState(
    persistedModalData ? persistedModalData : updateInputValues(DEFAULT_INPUT),
  );

  const queryClient = useQueryClient();

  const [featureMapRid, setFeatureMapRid] = useState(activityModalTypeRid);
  const [uiToggle, setUiToggle] = useState({
    addToAdventure: inputValues.adventure_block_rid ? true : false,
  });
  const [buttonType, setButtonType] = useState('button');
  const [topLabel, setTopLabel] = useState('SUMMARY');
  const [comments, setComments] = useState({
    explorer_comment_rid: null,
    itinerary_block_rid: inputValues.itinerary_block_rid,
    explorer_rid: userInfo ? userInfo.explorer_rid : null,
    explorer_comment: null,
  });
  const {
    paginations,
    bottomText,
    submitFunc,
    addsToItinerary,
  } = useActivityModalHooks(
    inputValues,
    setInputValues,
    featureMapRid,
    setFeatureMapRid,
  );
  const [currentItineraryItem, setCurrentItineraryItem] = useState(null);
  const [selectedItineraryItems, setSelectedItineraryItems] = useState({});

  function updateInputValues(defaultValues) {
    defaultValues.account_rid = userInfo.account_rid;
    if (blockPayload) {
      let updated = merge({}, { ...defaultValues, ...blockPayload });
      delete updated.itineraryDetails;
      delete updated.itineraryInfo;
      return updated;
    }
    return defaultValues;
  }

  const setUiColor = (color) => {
    setInputValues({ ...inputValues, ui_color: color });
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleOnChangeComments = useCallback((event) => {
    const { name, value } = event.target;
    setComments({ ...comments, [name]: value });
  });

  const uiToggleHandler = (name, value) => {
    setUiToggle({ ...uiToggle, [name]: !value });
  };

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const handleSelectItineraryItem = (itineraryItem) => {
    if (selectedItineraryItems[itineraryItem.itinerary_item_rid]) {
      let cloned = merge({}, selectedItineraryItems);
      delete cloned[itineraryItem.itinerary_item_rid];
      setSelectedItineraryItems(cloned);
    } else {
      setSelectedItineraryItems({
        ...selectedItineraryItems,
        [itineraryItem.itinerary_item_rid]: itineraryItem,
      });
    }
  };

  const curItinerary = useSelector(
    (state) => state?.entities?.itinerary?.itinerary_info,
  );
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const { block_name, block_category_rid } = inputValues;

    if (!block_name || !block_category_rid) return;

    if (addsToItinerary) {
      let modifiedPayload = {
        blockData: inputValues,
        itineraryItems: Object.keys(selectedItineraryItems),
      };
      const data = await submitFunc(modifiedPayload, uiUpdatePayload);
      invalidateQueries();
      return data;
    }

    await submitFunc(inputValues, uiUpdatePayload).then(() => {
      invalidateQueries();
    });
  };

  const handleCategorySelect = useCallback((event) => {
    if (event.length > 0) {
      const { block_category_rid } = event[0];
      setInputValues({ ...inputValues, block_category_rid });
    } else {
      setInputValues({ ...inputValues, block_category_rid: null });
    }
  });

  const handleParentBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      // checking if the drop down for library block is being invoked while creating adventure block
      if (inputValues.adventure_rid) {
        setInputValues({
          ...inputValues,
          dual: true,
          library_parent_block_rid: event[0].block_rid,
        });
      } else {
        setInputValues({
          ...inputValues,
          parent_block_rid: event[0].block_rid,
        });
      }
      setParentBlockRid(event[0].block_rid);
    } else {
      if (inputValues.adventure_rid) {
        setInputValues({
          ...inputValues,
          dual: false,
          library_parent_block_rid: null,
        });
      } else {
        setInputValues({
          ...inputValues,
          parent_block_rid: null,
        });
      }
      setParentBlockRid(null);
    }
  });

  const handleParentTripBlockSelect = useCallback((event) => {
    if (event.length > 0) {
      // if (inputValues.adventure_block_rid) {
      setInputValues({
        ...inputValues,
        parent_block_rid: event[0].block_rid,
      });
      // }
      setParentTripBlockRid(event[0].block_rid);
    } else {
      if (inputValues.adventure_rid || inputValues.adventure_block_rid) {
        setInputValues({ ...inputValues, parent_block_rid: null });
      }
      setParentTripBlockRid(null);
    }
  });

  const handleBlockCommentSubmit = (e) => {
    if (inputValues.adventure_block_rid && !inputValues.itinerary_item_rid) {
      let passOver = merge({}, comments);
      passOver['adventure_block_rid'] = inputValues.adventure_block_rid;
      passOver['idx'] = inputValues.idx;

      postAdventureBlockComment(passOver);
    } else {
      let passOver = merge({}, comments);
      passOver['idx'] = inputValues.idx;
      passOver['itinerary_item_rid'] = inputValues.itinerary_item_rid;
      postBlockComment(passOver);
    }
    setComments({
      explorer_comment_rid: null,
      itinerary_block_rid: inputValues.itineriary_block_rid,
      explorer_rid: userInfo ? userInfo.explorer_rid : null,
      explorer_comment: '',
    });
  };

  const transitionHandler = () => {
    let openType = {
      isSaving: true,
    };

    let actionPayload = {
      savingModalData: inputValues,
    };
    updateActivityModalFeatureMapRid(featureMapRid);
    persistBlockModalData(openType, actionPayload);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const addToDestinationHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let passOver = merge({}, inputValues);
    if (inputValues.adventure_block_rid) {
      passOver.parent_block_rid = null;
      let returnType = {
        isLibraryBlock: true,
        isNestedLibraryBlock: inputValues.block_level > 1 ? true : false,
      };
      let _actionPayload = {
        adventureRid: null,
        parentBlockRid:
          inputValues.block_level > 1 ? inputValues.parent_block_rid : null,
      };
      copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
    } else {
      passOver.adventure_rid = actionPayload.adventureRid;
      let returnType = {
        isLibraryBlock: true,
        isNestedLibraryBlock: inputValues.block_level > 1 ? true : false,
      };
      let _actionPayload = {
        adventureRid: actionPayload.adventureRid,
        parentBlockRid:
          inputValues.block_level > 1 ? inputValues.parent_block_rid : null,
      };
      copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
    }
    setIsDisabled(true);
  });

  return (
    <HubModal
      show={true}
      title="ACTIVITY"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box className="create-adventure-wrapper">
        <Stack spacing={2}>
          {!addsToItinerary && (
            <>
              <Summary
                handleOnChange={handleOnChange}
                inputValues={inputValues}
                uiToggle={uiToggle}
                uiToggleHandler={uiToggleHandler}
                isAdventureBlock={isAdventureBlock}
                openType={openType}
                userInfo={userInfo}
                copyToTripBoard={copyToTripBoard}
                triggerNext={triggerNext}
                uiUpdatePayload={uiUpdatePayload}
                user_block_categories={user_block_categories}
                handleCategorySelect={handleCategorySelect}
                {...props}
              />
              <Category
                user_block_categories={user_block_categories}
                handleCategorySelect={handleCategorySelect}
                openType={openType}
                parentTripBlockRid={parentTripBlockRid}
                parentBlockRid={parentBlockRid}
                setParentBlockRid={handleParentBlockSelect}
                setParentTripBlockRid={handleParentTripBlockSelect}
                inputValues={inputValues}
                uiUpdatePayload={uiUpdatePayload}
              />

              {paginations['location'] && (
                <Location
                  inputValues={inputValues}
                  setInputValues={setInputValues}
                  searchAirports={searchAirports}
                  setDistance={setDistance}
                />
              )}
              {paginations['social'] && (
                <Social
                  comments={comments}
                  blockComments={blockComments}
                  handleOnChangeComments={handleOnChangeComments}
                  handleBlockCommentSubmit={handleBlockCommentSubmit}
                  triggerNext={triggerNext}
                  switchHandler={switchHandler}
                  inputValues={inputValues}
                />
              )}
              {paginations['color'] && (
                <ColorPicker
                  uiColor={inputValues.ui_color}
                  setUiColor={setUiColor}
                />
              )}
              {paginations['attachments'] && (
                <Files
                  openBlockFileModal={openBlockFileModal}
                  currentBlockFiles={currentBlockFiles}
                  deleteBlockFile={deleteBlockFile}
                  transitionHandler={transitionHandler}
                  blockRid={inputValues.block_rid}
                  currentBlock={inputValues}
                />
              )}
              {inputValues.block_rid && (
                <>
                  {inputValues.adventure_block_rid && (
                    <div className="add-to-adventure-button-container">
                      {isAdventureBlock || isDisabled ? (
                        <div className="svg-container">
                          {svgAccept(
                            'color',
                            'added-to-adventure-svg',
                            'added-to-adventure-svg-outer',
                          )}
                        </div>
                      ) : (
                        <button
                          className={`add-to-adventure-button ${
                            (isAdventureBlock || isDisabled) &&
                            'add-to-adventure-button--disabled'
                          }`}
                          name="addToAdventure"
                          disabled={isAdventureBlock || isDisabled}
                          value={
                            isAdventureBlock ? true : uiToggle.addToAdventure
                          }
                          onClick={addToDestinationHandler}
                          text={
                            inputValues.adventure_block_rid
                              ? 'Add to Library'
                              : 'Add to Adventure'
                          }
                        >
                          {inputValues.adventure_block_rid
                            ? 'Add to Library'
                            : 'Add to Adventure'}
                        </button>
                      )}
                      <span>
                        {inputValues.adventure_block_rid
                          ? isDisabled
                            ? 'Added To Library'
                            : ''
                          : isAdventureBlock || isDisabled
                          ? 'Added To Trip'
                          : ''}
                      </span>
                    </div>
                  )}
                  {!inputValues.adventure_block_rid &&
                    uiUpdatePayload.adventure_block_exist_flag && (
                      <div className="add-to-adventure-button-container">
                        {isAdventureBlock || isDisabled ? (
                          <div className="svg-container">
                            {svgAccept(
                              'color',
                              'added-to-adventure-svg',
                              'added-to-adventure-svg-outer',
                            )}
                          </div>
                        ) : (
                          <button
                            className={`add-to-adventure-button ${
                              (isAdventureBlock || isDisabled) &&
                              'add-to-adventure-button--disabled'
                            }`}
                            name="addToAdventure"
                            disabled={isAdventureBlock || isDisabled}
                            value={
                              isAdventureBlock ? true : uiToggle.addToAdventure
                            }
                            onClick={addToDestinationHandler}
                            text={
                              inputValues.adventure_block_rid
                                ? 'Add to Library'
                                : 'Add to Adventure'
                            }
                          >
                            {inputValues.adventure_block_rid
                              ? 'Add to Library'
                              : 'Add to Adventure'}
                          </button>
                        )}
                        <span>
                          {inputValues.adventure_block_rid
                            ? isDisabled
                              ? 'Added To Library'
                              : ''
                            : isAdventureBlock || isDisabled
                            ? 'Added To Trip'
                            : ''}
                        </span>
                      </div>
                    )}
                </>
              )}
            </>
          )}
          {addsToItinerary && (
            <ItineraryItems
              itineraryItems={reformatItinerayItems(
                itineraryItemsAndBlockNames,
              )}
              selectedItinerary={uiUpdatePayload.itinerary_item_rid}
              selectedItineraryItems={selectedItineraryItems}
              handleSelectItineraryItem={handleSelectItineraryItem}
            />
          )}

          {/* <div
          className={`activity-modal-buttons-wrapper ${triggerCommentsButton === 'load'
            ? ''
            : triggerCommentsButton === 'start'
              ? 'activity-modal-buttons-wrapper--comments-activated'
              : 'activity-modal-buttons-wrapper--comments-deactivate'
            }`}
          id="activity-button-wrapper"
        >
          <Button
            spacingTop="5px"
            text={'SUBMIT COMMENT'}
            isAltButton
            handleIsAltButton={handleBlockCommentSubmit}
            customClassName="activity-modal-comment-submit-button"
          />
          <Button
            spacingTop="5px"
            text={page > 0 ? 'NEXT' : 'SAVE'}
            buttonType={buttonType}
            handleClick={() => handleNextPage()}
            disabledCondition={inputValues.block_name.length === 0}
          />
        </div> */}

          {/* <div className="next-message-wrapper">
          <span>
            {page === 0
              ? bottomText
              : bottomText === 'Add to itineraries'
                ? buttonTextArr[page - 1]
                : bottomText}{' '}
            &#x2192;
          </span>
        </div> */}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateBlock),
);
