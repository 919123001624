import styled from 'styled-components';

export const Container = styled.div`
  background-color: #264562;
  height: 2px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 80%;
  overflow: hidden;
`;

export const ProgressBar = styled.div`
  height: 3px;
  background-color: #6ec6ed;
`;

export const VerticalContainer = styled.div`
  width: 5px;
  position: absolute;
  background-color: ${(props) => props.backgroundColor};
  overflow-y: hidden;
  height: 100%;
  z-index: 1;
  overflow-x: visible;

  ${(props) => (props.position.top ? `top: ${props.position.top};` : '')}
  ${(props) => (props.position.left ? `left: ${props.position.left};` : '')}
  ${(props) => (props.position.right ? `right: ${props.position.right};` : '')}
  ${(props) =>
    props.position.bottom ? `bottom: ${props.position.bottom};` : ''}
`;

export const VerticalProgressBar = styled.div`
  width: 5px;
  position: absolute;
  background-color: ${(props) => props.backgroundColor};

  ${(props) => (props.position.top ? `top: ${props.position.top};` : '')}
  ${(props) => (props.position.left ? `left: ${props.position.left};` : '')}
  ${(props) => (props.position.right ? `right: ${props.position.right};` : '')}
  ${(props) =>
    props.position.bottom ? `bottom: ${props.position.bottom};` : ''}
`;
