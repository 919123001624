export function tent2 (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M82.29,69.519l-2.355,3.174L51.228,33.997l5.324-7.178l-1.605-1.191l-4.98,6.715l-4.98-6.715l-1.604,1.191l5.343,7.199  l-28.66,38.676l-2.354-3.176l-1.604,1.191l2.715,3.662h21.088l10.092-15.275l10.096,15.274h21.086l2.715-3.662L82.29,69.519z   M61.081,73.371L49.999,56.603l-11.083,16.77L34.201,64.2l15.798-7.598L65.798,64.2L61.081,73.371z"
        ></path>
      </g>
    </svg>
  );
}