export function horsebackRiding (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="87.599"
      height="92.536"
      viewBox="0, 0, 87.599, 92.536"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M58.372,7.22 C58.372,11.207 55.139,14.44 51.152,14.44 C47.164,14.44 43.932,11.207 43.932,7.22 C43.932,3.233 47.164,0 51.152,0 C55.139,0 58.372,3.233 58.372,7.22 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M75.686,45.33 C72.33,42.488 67.276,41.652 63.634,42.352 C61.916,42.684 59.668,43.046 57.218,43.314 L49.894,53.588 L51.492,68.15 C54.142,67.762 55.602,67.192 56.172,67.182 L53.618,72.556 C53.048,73.29 52.876,74.258 53.158,75.144 L57.534,87.384 C57.916,88.592 59.034,89.368 60.24,89.368 C60.524,89.368 60.812,89.326 61.096,89.236 C62.59,88.764 63.418,87.17 62.948,85.676 L60.368,75.182 L66.106,67.756 L66.172,74.77 C66.104,75.132 66.106,75.504 66.18,75.864 L69.91,90.264 C70.184,91.608 71.368,92.534 72.688,92.536 C72.876,92.536 73.066,92.516 73.258,92.478 C74.796,92.164 75.786,90.664 75.47,89.128 L73.854,75.324 C73.854,75.324 78.702,62.79 79.178,54.222 C79.284,52.346 79.092,50.724 78.56,49.32 C80.236,50.52 81.478,52.572 80.9,56.138 C79.138,67.012 79.138,70.362 83.1,77.842 C83.1,77.842 82.66,72.562 86.474,62.884 C88.362,58.074 89.538,46.31 75.686,45.33 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M47.686,53.588 L56.194,41.65 C57.038,40.814 59.206,38.426 59.206,35.182 L59.206,21.398 C59.206,18.382 57.058,16.084 52.032,16.084 C50.452,16.084 47.532,16.89 46.232,18.526 C43.93,21.42 41.158,32.01 41.158,32.01 L33.606,36.698 C28.758,33.028 23.716,29.528 17.99,26.742 C14.89,25.236 15.058,23.866 10.722,21.818 C4.592,18.92 10.276,24.574 9.854,25.918 C8.436,30.41 0.25,39.278 0.014,40.934 C-0.222,42.588 2.616,46.61 5.69,43.772 C8.764,40.934 15.15,40.934 15.15,40.934 C19.642,42.588 19.642,50.866 21.534,57.034 C22.058,58.738 16.614,71.084 16.614,71.084 C15.98,71.818 15.762,72.828 16.044,73.758 L19.828,86.332 C20.198,87.562 21.326,88.354 22.544,88.354 C22.814,88.354 23.09,88.314 23.362,88.232 C24.864,87.78 25.716,86.196 25.262,84.696 L22.508,73.054 C22.508,73.054 30.342,65.406 30.852,65.6 L32.484,89.884 C32.584,91.382 33.832,92.53 35.312,92.53 C35.374,92.53 35.44,92.528 35.506,92.524 C37.07,92.418 38.252,91.066 38.146,89.502 L39.582,68.006 C40.632,68.15 41.602,68.244 42.536,68.32 L42.93,70.65 C43.222,72.516 44.832,73.848 46.662,73.848 C46.858,73.848 47.054,73.832 47.254,73.802 C49.318,73.478 50.73,71.54 50.406,69.476 L47.686,53.588 z M42.354,42.648 C41.658,42.428 41.12,41.95 40.53,41.642 L35.45,43.83 C34.974,44.138 34.438,44.286 33.908,44.286 C33.146,44.286 32.412,43.966 31.866,43.396 C32.128,43.474 32.398,43.524 32.67,43.524 C33.198,43.524 33.732,43.378 34.21,43.066 L44.14,37.89 C43.596,38.976 42.964,40.724 42.354,42.648 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}