import moment from 'moment';
const TripInfo = ({ invitedAdventureData, ...props }) => {
  const { tripData, badgePayload } = invitedAdventureData;
  return (
    <>
      <div className="color-separator-bar">WELCOME</div>
      <div className="accept-trip-invite-section-contents">
        <div
          className="accept-trip-invite-welcome-message-background
              "
          style={{
            backgroundImage: `url(${tripData.ui_image_location})`,
          }}
        >
          <div className="adventure-trip-invite-welcome-contents-wrapper">
            <div className="adventure-trip-invite-invitor-profile-image-wrapper">
              <img
                src={badgePayload.img}
                className="adventure-trip-invite-invitor-profile-image"
              />
            </div>
            <div className="adventure-trip-invite-message-wrapper">
              <p>{badgePayload.title}</p>
              <p>Duration: {tripData.duration}</p>
              <p>
                Dates:{' '}
                {moment(tripData.start_date)
                  .utcOffset(2)
                  .format('dddd, MMM DD, YYYY')}{' '}
                ~{' '}
                {moment(tripData.end_date)
                  .utcOffset(2)
                  .format('dddd, MMM DD, YYYY')}
              </p>
              <p>Press 'CONTINUE' to get started!</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TripInfo;
