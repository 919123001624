import React from 'react';
const PageLoading = ({ message, ...rest }) => {
  return (
    <div className="page-loading-wrapper">
      <div className="animated-ring">
        <span className="ring-light"></span>
        {/* <div className="component-loading-image-container"> */}
        <img
          src="/logos_v2/lodestar-mark.svg"
          layout="fill"
          // objectfit="contain"
          alt="lodestar-mark"
          className="loading-image-container"
        />
        {/* </div> */}
      </div>
      <div className="component-loading-message-container animate-text-grow-size-whole">
        {/* {message.split("").map((char, idx) => (
            <span key="laoding-message-char">{char}</span>
          ))} */}
        <span>Loading...</span>
      </div>
    </div>
  );
};

export default PageLoading;
