import { useQueryClient } from '@tanstack/react-query';
import { deleteItineraryItem } from 'actions/itinerary_actions';
import { closeModal, openDeleteModal } from 'actions/modal_actions';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import { useDispatch, useSelector } from 'react-redux';

const useDeleteItineraryItemModalButton = (itineraryItemData) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const adventure_rid = useSelector(
    (state) => state?.entities?.adventure?.selectedAdventure?.adventure_rid,
  );
  const itinerary_rid = useSelector(
    (state) => state?.entities?.itinerary?.itinerary_info?.itinerary_rid,
  );
  const explorer_rid = useSelector(
    (state) => state?.session?.user?.explorer_rid,
  );

  const { invalidateQueries } = useInvalidateAdventureData(adventure_rid);

  const itinerary_item_rid = itineraryItemData.itinerary_item_rid;

  const openModal = async () => {
    const onConfirm = async () => {
      dispatch(
        await deleteItineraryItem(
          adventure_rid,
          explorer_rid,
          itinerary_item_rid,
          itinerary_rid,
        ),
      ).then(() => {
        invalidateQueries();
        dispatch(closeModal());
      });
    };

    dispatch(
      openDeleteModal(
        {
          content: 'Remove from itinerary?',
          following: (
            <span>Items on your Trip Board will not be affected.</span>
          ),
        },
        onConfirm,
      ),
    );
  };

  return { openModal };
};

export default useDeleteItineraryItemModalButton;
