export function fishing (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;" />
        <g>
          <g>
            <path
              fill={color}
              className={className}
              d="M34.69917,28.2785c-4.55366,1.3114-8.68546,3.64446-11.94,6.51996h-0.01001    c-2.65997-0.48999-5.48999-0.37-8.22998,0.37006c-0.33002,0.08997-0.66998-0.05005-0.84998-0.34003    c-0.17004-0.28998-0.12-0.65997,0.10999-0.90002c1.33997-1.38,2.87-2.57996,4.53998-3.56    C23.42915,27.37848,29.47919,26.6485,34.69917,28.2785z"
            ></path>
          </g>
          <g>
            <path
              fill={color}
              className={className}
              d="M45.88917,47.65851c-1.19,5.26996-4.76001,10.10999-9.77002,13.04999c-1.67999,0.97998-3.46997,1.72998-5.31995,2.21997    c-0.06,0.02002-0.13,0.03003-0.19,0.03003c-0.26001,0-0.51001-0.14001-0.65002-0.37    c-0.16998-0.29004-0.12-0.66003,0.10999-0.90002c1.94-1.97998,3.40002-4.33997,4.29004-6.83997    C38.65979,53.27477,42.47451,50.98363,45.88917,47.65851z"
            ></path>
          </g>
          <g>
            <path
              fill={color}
              className={className}
              d="M46.1692,33.42847c-0.45764,0.24957-0.57935,0.8313-0.34003,1.27002c0.28619,0.46234,0.86517,0.56128,1.27002,0.33002    c0.47736-0.2821,0.57513-0.87396,0.32996-1.27002C47.16999,33.28339,46.55317,33.18164,46.1692,33.42847z"
            ></path>
            <path
              fill={color}
              className={className}
              d="M52.99915,29.8985c-4.22998-1.27002-8.51996-1.66998-12.76996-1.20001c-0.12,2.83002,0.57996,5.65002,2.01996,8.09998    c1.44,2.46002,3.56,4.45001,6.09003,5.72003c2.47998-3.48999,4.22998-7.44,5.16998-11.73999    C53.59919,30.3985,53.36915,30.00848,52.99915,29.8985z M47.8592,36.31848c-1.12347,0.65051-2.61359,0.32898-3.32001-0.87    c-0.68604-1.14331-0.27478-2.64539,0.87-3.32001c1.1228-0.65332,2.62097-0.31622,3.32001,0.87    C49.40748,34.18536,49.00813,35.64136,47.8592,36.31848z"
            ></path>
            <path
              fill={color}
              className={className}
              d="M40.94917,37.55847c-1.53998-2.62-2.29999-5.63-2.21997-8.64996c-0.65002,0.09998-1.29004,0.22998-1.90002,0.37    C27.17,31.46061,19.19531,38.26007,15.40919,47.68848L3.99915,47.72852c-0.33997,0-0.63995,0.22998-0.72998,0.56    c-0.08002,0.33997,0.07001,0.67999,0.37,0.84998c6.04999,3.34003,8.97998,8.32001,8.94,15.22998    c0,0.34003,0.22998,0.64001,0.56,0.72003c0.06,0.01996,0.13,0.02997,0.19,0.02997c0.27002,0,0.52002-0.13995,0.65002-0.38    l5.52997-9.83997c10.57002,1.41304,21.12508-2.63705,27.91998-11.16998C44.74915,42.32849,42.49915,40.18848,40.94917,37.55847z"
            ></path>
          </g>
          <g>
            <path
              fill={color}
              className={className}
              d="M65.10468,11.92255h-4.91504c-0.35742,0-0.64844,0.28711-0.64844,0.64063v1.23242    c0,0.35742,0.29102,0.64746,0.64844,0.64746h1.70996v12.64502c0,1.83594-1.49414,3.33008-3.33008,3.33008    c-1.8127,0-3.33008-1.45953-3.33008-3.33008v-1.375l1.52051,0.92578c0.35254,0.21582,0.81445,0.10352,1.03027-0.25098    c0.21582-0.35352,0.10352-0.81445-0.25098-1.03027l-2.66016-1.62012c-0.23047-0.1416-0.52051-0.14453-0.75684-0.01367    c-0.23633,0.13281-0.38281,0.38281-0.38281,0.6543v2.70996c0,2.70578,2.1954,4.83008,4.83008,4.83008    c2.66309,0,4.83008-2.16699,4.83008-4.83008V14.44305h1.70508c0.35742,0,0.64746-0.29004,0.64746-0.64746v-1.23242    C65.75214,12.20966,65.4621,11.92255,65.10468,11.92255z"
            ></path>
            <path
              fill={color}
              className={className}
              d="M65.10468,7.90204h-4.91504c-0.35742,0-0.64844,0.28711-0.64844,0.64063v1.23242    c0,0.35742,0.29102,0.64746,0.64844,0.64746h4.91504c0.35742,0,0.64746-0.29004,0.64746-0.64746V8.54266    C65.75214,8.18915,65.4621,7.90204,65.10468,7.90204z"
            ></path>
            <path
              fill={color}
              className={className}
              d="M65.10468,3.88153h-4.91504c-0.35742,0-0.64844,0.28711-0.64844,0.64063v1.23242    c0,0.35742,0.29102,0.64746,0.64844,0.64746h4.91504c0.35742,0,0.64746-0.29004,0.64746-0.64746V4.52216    C65.75214,4.16864,65.4621,3.88153,65.10468,3.88153z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}