export const MapTopo = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg className={outerLayer}
      width={width ? width : '27'}
      height={height ? height : '27'}
      viewBox={viewBox || "0 0 27 27"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="27" rx="5" fill="#FCCE13" />
      <path d="M5.76838 14.2631C5.83881 14.2652 5.90632 14.2918 5.95926 14.3383C6.60858 14.9058 7.78294 15.2579 9.02381 15.2579C10.2647 15.2579 11.4388 14.9058 12.088 14.3383H12.0881C12.1461 14.2878 12.2209 14.2609 12.2979 14.2629C12.3455 14.2644 12.3929 14.2653 12.4411 14.2653C13.4432 14.2653 14.2149 13.8988 14.3451 13.5323L12.3572 11.5444C11.5784 12.1504 10.3501 12.5092 9.0239 12.5092C7.91157 12.5395 6.81419 12.2474 5.86407 11.6681L4.00098 13.5312C4.12346 13.8729 4.8149 14.2318 5.76846 14.2632L5.76838 14.2631Z" fill="#273906" />
      <path d="M12.0881 17.6972C12.1466 17.6475 12.2211 17.6206 12.2979 17.6218C12.3455 17.6233 12.3929 17.6242 12.4411 17.6242C13.4432 17.6242 14.2149 17.2577 14.3451 16.8912L12.3572 14.9033C11.5784 15.5092 10.3501 15.8681 9.0239 15.8681C7.91157 15.8984 6.81419 15.6063 5.86407 15.027L4.00098 16.8901C4.12314 17.2306 4.81611 17.5895 5.7696 17.6209C5.84003 17.623 5.90754 17.6496 5.96048 17.6961C6.52855 18.1924 7.49823 18.524 8.56259 18.5992V22.5873H9.78424V18.5689C10.6198 18.5051 11.4195 18.2025 12.0879 17.6972L12.0881 17.6972Z" fill="#273906" />
      <path d="M5.76854 10.9033C5.83898 10.9054 5.90648 10.932 5.95943 10.9784C6.60874 11.5459 7.7831 11.898 9.02397 11.898C10.2648 11.898 11.4389 11.5459 12.0882 10.9784H12.0883C12.1469 10.929 12.2213 10.9022 12.298 10.903C12.3457 10.903 12.393 10.9054 12.4413 10.9054C13.4433 10.9054 14.2151 10.5389 14.3452 10.1725L9.17246 5L4 10.1725C4.12369 10.513 4.81513 10.8719 5.76862 10.9033L5.76854 10.9033Z" fill="#273906" />
      <path d="M21.9996 20.7541H19.8312L19.5563 19.8379H20.4726V18.6162H12.8373V19.8379H13.7535L13.4786 20.7541H11.3103V21.3649H13.2956L12.8374 22.892H14.0591L14.5172 21.3649H15.5862V20.7541H14.7005L14.9753 19.8379H18.3349L18.6097 20.7541H17.724V21.3649H18.793L19.2511 22.892H20.4727L20.0146 21.3649H21.9999L21.9996 20.7541Z" fill="#273906" />
    </svg>
  )
}
