import React from 'react';
const PopUpParent = ({ content, display }) => {
  return (
    <div
      className={`${
        display ? 'popup-wrapper--display' : 'popup-wrapper--notdisplayed'
      }`}
    >
      {content}
    </div>
  );
};

export default PopUpParent;
