const muiCardStyles = {
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: 0,
      }),
    },
  },
};

export default muiCardStyles;
