import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  NoAccess,
  UpgradeToFamily,
  MemberSummary,
  AddAndEditAccountMembers,
} from './account_members_modules';
import { Headers } from './shared_modules';
import {
  updateUserInfo,
  fetchAccountExplorer,
  fetchAccountDetails,
} from '../../../actions/user_actions.js';
import {
  openAccountExplorerEditModal,
  openInviteNewMemberModal,
} from '../../../actions/modal_actions';
import {
  fetchAccountRoles,
  fetchCountries,
  fetchStates,
} from '../../../actions/ui_actions';
import axios from 'axios';

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (data) => dispatch(updateUserInfo(data)),
    fetchAccountExplorer: (accountRid, explorerRid) =>
      dispatch(fetchAccountExplorer(accountRid, explorerRid)),
    openAccountExplorerEditModal: (userInfo) =>
      dispatch(openAccountExplorerEditModal(userInfo)),
    fetchAccountRoles: () => dispatch(fetchAccountRoles()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchStates: () => dispatch(fetchStates()),
    fetchAccountDetails: (accountRid) =>
      dispatch(fetchAccountDetails(accountRid)),
    openInviteNewMemberModal: () => dispatch(openInviteNewMemberModal()),
  };
};

const mapStateToProps = ({ entities, session, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let allAccountExplorers = entities.users.allAccountExplorers
    ? entities.users.allAccountExplorers
    : null;
  let accountRoles = ui.components.accountRoles
    ? ui.components.accountRoles
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let accountDetails = entities.users.accountDetails
    ? entities.users.accountDetails
    : null;
  return {
    userInfo,
    allAccountExplorers,
    accountRoles,
    countries,
    statesProvences,
    currentExplorerDetails,
    accountDetails,
  };
};
const validFamilyPass = {
  1: true,
  6: true,
  7: true,
  2: true,
  18: true,
};
const MembersLists = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const {
    allAccountExplorers,
    fetchAccountExplorer,
    userInfo,
    history,
    currentExplorerDetails,
    fetchAccountDetails,
    accountDetails,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    scrollTop();

    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  useEffect(() => {
    if (!allAccountExplorers) fetchAccountExplorer(userInfo.account_rid, null);
    if (!currentExplorerDetails)
      fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    if (!accountDetails) fetchAccountDetails(userInfo.account_rid);
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container  "
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-members  account-page-container`}
          id="account-page-container-responsive"
        >
          {/* <div
          className={`account-members  ${
            !isMobile && "account-pages-browser-settings"
          }`}
        > */}
          <Headers text="Account Explorers" />
          {/* {validFamilyPass[userInfo.plan_rid] &&
            userInfo.account_role_name !== "Account Administrator" && (
              <NoAccess />
            )}
          {!validFamilyPass[userInfo.plan_rid] && <UpgradeToFamily />}{" "} */}
          <>
            {accountDetails && (
              <MemberSummary accountDetails={accountDetails} />
            )}
          </>
          {allAccountExplorers && accountDetails && (
            <AddAndEditAccountMembers {...props} />
          )}
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembersLists),
);
