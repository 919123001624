export const MapSattelite = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
 ) => { 
  return (
  <svg className={outerLayer} 
    width={width ? width : '27' }
    height={height ? height: '27'}
    viewBox={ viewBox || "0 0 27 27"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="27" height="27" rx="5" fill="#FCCE13"/>
    <path d="M13.1656 22.119H7.14667C7.12465 22.119 7.10412 22.1283 7.08925 22.1432L7.0826 22.1497C7.0719 22.1636 7.06537 22.1813 7.06537 22.2006V22.9183C7.06537 22.941 7.07445 22.9618 7.08863 22.9763L7.08925 22.9758C7.10412 22.9905 7.12465 23 7.14667 23H14.0451C14.0675 23 14.088 22.9905 14.1026 22.9758L14.1093 22.9692C14.12 22.9554 14.1266 22.9376 14.1266 22.9183V22.2006C14.1266 22.1782 14.118 22.1577 14.1039 22.143L14.1026 22.1417C14.088 22.1277 14.0676 22.119 14.0451 22.119L13.1656 22.119ZM7 10.1901C7 12.115 7.72198 14.0405 9.16567 15.524C9.53879 15.1697 10.0424 14.9521 10.5962 14.9521C11.1703 14.9521 11.6907 15.1853 12.067 15.5621C12.4435 15.9387 12.6764 16.4593 12.6764 17.0339C12.6764 17.2173 12.6525 17.3953 12.6081 17.5644C13.8626 17.9096 15.1883 17.9288 16.4506 17.6227C17.6752 17.3254 18.842 16.72 19.8276 15.8072L14.4281 10.4039L9.02887 5C7.67641 6.46293 7.00016 8.32659 7.00016 10.19L7 10.1901ZM13.9439 9.06482L14.8551 9.97654L15.7661 10.8885C15.804 10.8275 15.8371 10.7631 15.8649 10.696C15.9287 10.5416 15.9643 10.3704 15.9643 10.1902C15.9643 9.83113 15.8217 9.50523 15.5906 9.26703L15.5769 9.25408C15.3378 9.01477 15.0069 8.86649 14.6415 8.86649C14.4615 8.86649 14.2907 8.90191 14.1363 8.96597C14.0691 8.99367 14.0049 9.02684 13.9439 9.06482ZM11.6401 15.9893C11.3731 15.722 11.0038 15.5565 10.5961 15.5565C10.1953 15.5565 9.83142 15.7163 9.56549 15.975L9.55211 15.9893C9.28524 16.2565 9.11989 16.6261 9.11989 17.0339C9.11989 17.4418 9.28524 17.8114 9.55211 18.0785L9.55168 18.0791C9.81868 18.3459 10.1883 18.5112 10.5961 18.5112C11.0038 18.5112 11.3731 18.3457 11.6401 18.0785C11.9071 17.8114 12.0725 17.4418 12.0725 17.0339C12.0725 16.6259 11.9071 16.2565 11.6401 15.9893ZM11.7649 18.756C11.4317 18.9831 11.0293 19.1155 10.5961 19.1155C10.1633 19.1155 9.76102 18.9827 9.4277 18.7553L8.45309 21.5146H12.739L11.7649 18.756Z" fill="#273906"/>
  </svg>
  )
}

