export const svgAdventureImpactFlag = (className, outerLayer) => {
  return (
    <svg
      x="0"
      y="0"
      width="8.974"
      height="6.239"
      viewBox="0, 0, 8.974, 6.239"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M0.003,6.239 C2.15,2.141 4.392,1.068 5.751,1.169 C2.908,1.983 0.56,5.652 0.633,5.637 C5.734,4.572 4.882,1.785 8.974,0.458 C1.196,-1.51 0.555,3.352 0,5.754 L0.003,6.239 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};
