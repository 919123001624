import React from 'react'

const ModuleTab = ({
  selected,
  icon,
  text,
  handleClick,
  type=1
}) => {
  return (
    <div className={`${selected ? 'mod-tab-selected' : 'mod-tab-unselected'} type${type}`} onClick={() => handleClick(text)}>
      {/* {icon("color", "mod-tab-svg", "mod-tab-svg-outerlayer")} */}
      {text}
    </div>
  )
}


export default ModuleTab 