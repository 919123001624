export const svgSearch = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      className={outerLayer}
      width="100px"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g>
        <g>
          <g>
            <path
              className={className}
              d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
            ></path>
            <path
              className={className}
              d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"
            ></path>
            <path
              className={className}
              d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
            ></path>
            <path
              className={className}
              d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
            ></path>
          </g>
          <g>
            <path
              className={className}
              d="M42.4,23.1c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4c4.7,0,8.6,3.9,8.6,8.6c0,3,2.4,5.4,5.4,5.4s5.4-2.4,5.4-5.4     C61.8,31.8,53.1,23.1,42.4,23.1z"
            ></path>
            <path
              className={className}
              d="M95.8,87.3L74.5,66.1c4.9-6.6,7.8-14.8,7.8-23.7c0-22-17.9-39.9-39.9-39.9S2.5,20.4,2.5,42.4s17.9,39.9,39.9,39.9     c8.9,0,17-2.9,23.7-7.8l21.2,21.2c1.2,1.2,2.7,1.7,4.2,1.7c1.5,0,3.1-0.6,4.2-1.7C98.1,93.4,98.1,89.6,95.8,87.3z M14.4,42.4     c0-15.4,12.6-28,28-28s28,12.6,28,28s-12.6,28-28,28S14.4,57.9,14.4,42.4z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
