import { adventureMotorcycle } from './components/adventure-motorcycle';
import { airplane } from './components/airplane';
import { allTerrainVehicle } from './components/all-terrain-vehicle';
import { atv } from './components/atv';
import { backpack } from './components/backpack';
import { backpacking } from './components/backpacking';
import { beach } from './components/beach';
import { bedAndBreakfast } from './components/bed-and-breakfast';
import { bicycle } from './components/bicycle';
import { bikePacking } from './components/bike-packing';
import { boatCruising } from './components/boat-cruising';
import { boatSightSeeing } from './components/boat-sight-seeing';
import { boatTravel } from './components/boat-travel';
import { bonfire1 } from './components/bonfire_1';
import { bonfire2 } from './components/bonfire_2';
import { booking } from './components/booking';
import { bowhunting } from './components/bowhunting';
import { brewery } from './components/brewery';
import { bucket } from './components/bucket';
import { bus } from './components/bus';
import { butterfly } from './components/butterfly';
import { camping } from './components/camping';
import { campingTent } from './components/camping-tent';
import { canoe } from './components/canoe';
import { catcus } from './components/catcus';
import { chill } from './components/chill';
import { climbing } from './components/climging';
import { coffeeShop } from './components/coffee-shop';
import { crossCountrySkiing } from './components/cross-country-skiing';
import { cutlery } from './components/cutlery';
import { dayHike } from './components/day-hike';
import { dirtBike } from './components/dirt-bike';
import { downhillSkiing } from './components/downhill-skiing';
import { drone } from './components/drone';
import { electricVehicle } from './components/electric-vehicle';
import { electronics } from './components/electronics';
import { equipment } from './components/equipment';
import { explore } from './components/explore';
import { fatBike } from './components/fat-bike';
import { fishing } from './components/finishing';
import { flipFlop } from './components/flipflops';
import { flyFishing } from './components/fly-fishing';
import { gondola } from './components/gondola';
import { guitar } from './components/guitar';
import { gun } from './components/gun';
import { hiking } from './components/hiking';
import { hillStation } from './components/hill-station';
import { horsebackRiding } from './components/horseback-riding';
import { hotel } from './components/hotel';
import { jeep } from './components/jeep';
import { kayak } from './components/kayak';
import { leaf } from './components/leaf';
import { lodging } from './components/lodging';
import { longTermRental } from './components/long-term-rental';
import { loungeChair } from './components/lounge-chair';
import { luggage } from './components/luggage';
import { mapLocator } from './components/map-locator';
import { meal } from './components/meal';
import { moped } from './components/moped';
import { motorcycle } from './components/motorcycle';
import { mountainBike } from './components/mountain-bike';
import { neighborhood } from './components/neighborhood';
import { nonFlyFishing } from './components/non-fly-fishing';
import { offRoad } from './components/off-road';
import { outdoorCooking } from './components/outdoor-cooking';
import { picnicTable } from './components/picnic-table';
import { planMap } from './components/plan-map';
import { rafting } from './components/rafting';
import { rafting2 } from './components/rafting2';
import { restaurant } from './components/restaurant';
import { roadBike } from './components/road-bike';
import { roadTrip } from './components/road-trip';
import { rockClimbing } from './components/rock-climging';
import { rusticLodge } from './components/rustic-lodge';
import { rv } from './components/rv';
import { scubaDiving } from './components/scuba-diving';
import { scubaMask } from './components/scuba-mask';
import { shortTermRental } from './components/short-term-rental';
import { skis } from './components/skis';
import { snorkeling } from './components/snorkeling';
import { snowBoard } from './components/snow-board';
import { snowMobile } from './components/snow-mobile';
import { snowshoe } from './components/snowshoe';
import { social } from './components/social';
import { sport } from './components/sport';
import { sprinterVan } from './components/sprinter-van';
import { standupPaddle } from './components/standup-paddle';
import { streetBike } from './components/streetBike';
import { sunBed } from './components/sun-bed';
import { surfBoard } from './components/surf-board';
import { suvTravel } from './components/suv-travel';
import { svgCarabiner } from './components/svgcarabiner';
import { tent1 } from './components/tent1';
import { tent2 } from './components/tent2';
import { torchLight } from './components/torch-light';
import { trailRunning } from './components/trail-running';
import { trailSports } from './components/trail-sports';
import { trailer } from './components/trailer';
import { train } from './components/train';
import { transportation } from './components/transportation';
import { travelDocument } from './components/travel-document';
import { tree } from './components/tree';
import { tubing } from './components/tubing';
import { utility } from './components/utility';
import { utilityTrailerVehicle } from './components/utility-trailer-vehicle';
import { vanityVan } from './components/vanity-van';
import { vehicle } from './components/vehicle';
import { wakeboarding } from './components/wakeboarding';
import { walk } from './components/walk';
import { waterskiing } from './components/waterskiing';
import { wildlifeViewing } from './components/wildlife-viewing';
import { winery } from './components/winery';
import { work } from './components/work';
import { yacht } from './components/yacht';
const svgArray = {
  airplane: airplane,
  work: work,
  explore: explore,
  chill: chill,
  walk: walk,
  'all terrain vehicle': allTerrainVehicle,
  backpack: backpack,
  beach: beach,
  bicycle: bicycle,
  bonfire_1: bonfire1,
  bonfire_2: bonfire2,
  bowhunting: bowhunting,
  bucket: bucket,
  bus: bus,
  butterfly: butterfly,
  cactus: catcus,
  svgCarabiner: svgCarabiner,
  Cutlery: cutlery,
  fishing: fishing,
  flipFlops: flipFlop,
  gondola: gondola,
  guitar: guitar,
  gun: gun,
  hiking: hiking,
  'hill Station': hillStation,
  jeep: jeep,
  kayak: kayak,
  yacht: yacht,
  'vanity van': vanityVan,
  utility: utility,
  'utility trailer vehicle': utilityTrailerVehicle,
  tree: tree,
  train: train,
  trailer: trailer,
  'torch light': torchLight,
  tent_1: tent1,
  tent_2: tent2,
  'surf board': surfBoard,
  'sun bed': sunBed,
  sport: sport,
  'snow board': snowBoard,
  snorkeling: snorkeling,
  skis: skis,
  'scuba mask': scubaMask,
  'road trip': roadTrip,
  'plan map': planMap,
  'picnic table': picnicTable,
  'outdoor cooking': outdoorCooking,
  'off road': offRoad,
  neighborhood: neighborhood,
  moped: moped,
  'map locator': mapLocator,
  luggage: luggage,
  'lounge chair': loungeChair,
  leaf: leaf,
  atv: atv,
  backpacking: backpacking,
  camping: camping,
  'cross country skiing': crossCountrySkiing,
  'day hike': dayHike,
  dirtbike: dirtBike,
  adventure_motorcycle: adventureMotorcycle,
  'downhill skiing': downhillSkiing,
  drone: drone,
  electronics: electronics,
  electric_vehicle: electricVehicle,
  equipment: equipment,
  fishing: fishing,
  'fly fishing': flyFishing,
  'horseback riding': horsebackRiding,
  lodging: lodging,
  'long term rental': longTermRental,
  meal: meal,
  motorcycle: motorcycle,
  'mountain bike': mountainBike,
  'fat bike': fatBike,
  'non fly fishing': nonFlyFishing,
  'road bike': roadBike,
  'rustic lodge': rusticLodge,
  rv: rv,
  'scuba diving': scubaDiving,
  'short term rental': shortTermRental,
  snowshoe: snowshoe,
  social: social,
  'stand up paddle': standupPaddle,
  streetbike: streetBike,
  transportation: transportation,
  'travel document': travelDocument,
  'wildlife viewing': wildlifeViewing,
  'boat cruising': boatCruising,
  //--here
  'boat sightseeing': boatSightSeeing,
  canoe: canoe,
  rafting: rafting,
  'rock climbing': rockClimbing,
  snowmobile: snowMobile,
  'trail running': trailRunning,
  'trail sports': trailSports,
  tubing: tubing,
  wakeboarding: wakeboarding,
  waterskiing: waterskiing,
  'camping tent': campingTent,
  'bed and breakfast': bedAndBreakfast,
  hotel: hotel,
  brewery: brewery,
  restaurant: restaurant,
  winery: winery,
  booking: booking,
  climbing: climbing,
  rafting: rafting2,
  'sprinter van': sprinterVan,
  vehicle: vehicle,
  'bike packing': bikePacking,
  'boat travel': boatTravel,
  'suv travel': suvTravel,
  coffee_shop: coffeeShop,
};

export default svgArray;
