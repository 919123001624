import React, { useState, useEffect, useCallback } from 'react';
import FloatingLabelInput from 'components/ui_library/floating_label_input';
import Footer from 'components/modules/footer';
import Banner from 'components/modules/banner_regularstack';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import { Helmet } from 'react-helmet';

const Homepage = (props) => {
  const [inputValues, setInputValues] = useState({
    email: '',
    verifyEmail: '',
  });

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;

    if (name === 'email') {
      setInputValues({ ...inputValues, [name]: value });
      let emailValidation = EmailValidator.validate(value);
      if (emailValidation === false) {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: 'Please enter a valid email',
        });
      } else {
        setSignUpErrors({ ...signUpErrors, emailErrors: '' });
        axios
          .patch('/api/e/verify_auth_email', { email: value })
          .then((res) => {
            setSignUpErrors({ ...signUpErrors, emailErrors: res.data.message });
          });
      }
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  });

  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: [],
    emailErrors: '',
    passwordErrors: [],
  });

  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data', {
        pageName: 'resend-verification-email',
      })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
    scrollTop();
  }, []);
  const renderEmailErrors = () => {
    if (
      inputValues.email.length > 0 &&
      inputValues.verifyEmail.length > 0 &&
      inputValues.email === inputValues.verifyEmail
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pics" src="./symbols/bluecheck.png" />
            <span>Emails Match</span>
          </div>
        </div>
      );
    } else if (
      inputValues.email.length > 0 &&
      inputValues.verifyEmail.length > 0 &&
      inputValues.email !== inputValues.verifyEmail
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pics" src="./symbols/red-alert.png" />
            <span>Emails do not match</span>
          </div>
        </div>
      );
    } else if (
      inputValues.email.length > 0 &&
      signUpErrors.emailErrors === 'Please enter a valid email'
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pics" src="./symbols/red-alert.png" />
            <span>{signUpErrors.emailErrors}</span>
          </div>
        </div>
      );
    } else if (
      inputValues.email.length > 0 &&
      signUpErrors.emailErrors === 'Email already exists'
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pics" src="./symbols/bluecheck.png" />
            <span>Email found</span>
          </div>
        </div>
      );
    } else if (
      inputValues.email.length > 0 &&
      signUpErrors.emailErrors === 'Email valid'
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img alt="alert-pics" src="./symbols/red-alert.png" />
            <span>Email not found</span>
          </div>
        </div>
      );
    } else {
      // return (<div className="error-placeholder" />)
      return <div />;
    }
  };
  const [transitionMsg, setTransitionMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios.patch('/api/e/resend-email', { email: inputValues.email });
    setTransitionMsg(
      `An email has been sent to ${inputValues.email}, please check your inbox. You will be redirected to the home page in 10 seconds.`,
    );
    setTimeout(() => {
      props.history.push('/');
    }, 10000);
  };
  const disableRender = () => {
    if (
      inputValues.email.length === 0 ||
      inputValues.verifyEmail.length === 0
    ) {
      return true;
    }
    if (
      inputValues.email.length > 0 &&
      inputValues.verifyEmail.length > 0 &&
      inputValues.email === inputValues.verifyEmail
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div className="page-content-wrapper">
        <Banner
          photo="/banner_images/lodestar_sign_up_resend_banner.jpeg"
          bigText={
            <span>
              OOPS! <br />
              DIDN'T RECEIVE YOUR EMAIL?
            </span>
          }
        />
        <form
          className="resend-form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="signup-block-top">
            <div className="resend-header-block">
              <h2>RESEND SIGN UP EMAIL</h2>
            </div>
            {transitionMsg ? (
              <span
                style={{
                  margin: '0 auto',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <br />
                {transitionMsg}
              </span>
            ) : (
              <div />
            )}
            <div className="newsletter-inputs">
              <FloatingLabelInput
                className="floating-lode-input"
                id="lode-float-input"
                type="text"
                onChange={handleOnChange}
                name="email"
                value={inputValues.email}
                label="Email"
              />
            </div>
            <div className="newsletter-inputs">
              <FloatingLabelInput
                className="floating-lode-input"
                id="lode-float-input"
                type="text"
                onChange={handleOnChange}
                name="verifyEmail"
                value={inputValues.verifyEmail}
                label="Verify Email"
              />
            </div>
            {renderEmailErrors()}

            <br />
            <br />
            <button
              type="submit"
              className="resend-button"
              disabled={disableRender()}
            >
              RESEND EMAIL
            </button>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
