export function restaurant (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 100 100"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M79.482,43.845 L49.608,19.378 C47.561,17.591 44.509,17.591 42.462,19.378 L12.601,43.833 C11.167,45.085 10.344,46.895 10.344,48.799 L10.344,82.957 C10.344,84.519 11.623,85.798 13.186,85.798 L78.884,85.798 C80.447,85.798 81.725,84.519 81.725,82.957 L81.725,48.781 C81.725,46.889 80.907,45.089 79.482,43.845 z M57.657,73.299 C56.583,74.373 54.841,74.373 53.763,73.299 L45.533,65.067 L37.295,73.309 C36.219,74.385 34.478,74.385 33.401,73.309 C32.323,72.233 32.323,70.489 33.401,69.413 L41.637,61.173 L38.822,58.36 C37,58.839 34.979,58.373 33.549,56.94 L24.882,48.273 C24.497,47.888 24.498,47.264 24.883,46.879 C25.268,46.496 25.891,46.496 26.275,46.88 L31.63,52.233 C32.116,52.72 32.906,52.72 33.389,52.233 C33.878,51.748 33.878,50.957 33.389,50.472 L28.038,45.117 C27.653,44.732 27.653,44.108 28.039,43.724 L28.086,43.676 C28.471,43.292 29.094,43.293 29.478,43.677 L34.831,49.033 C35.318,49.518 36.104,49.518 36.593,49.033 C37.078,48.545 37.078,47.759 36.593,47.272 L31.237,41.916 C30.853,41.532 30.853,40.908 31.237,40.524 L31.24,40.521 C31.625,40.136 32.248,40.136 32.633,40.521 L41.085,48.976 L41.3,49.191 C42.727,50.621 43.2,52.641 42.721,54.466 L57.657,69.405 C58.734,70.479 58.734,72.223 57.657,73.299 z M50.865,59.733 L46.971,55.841 L56.505,46.302 C56.505,46.302 60.557,41.269 64.944,45.658 L50.865,59.733 z"
          fill="#000000"
        />
        <path
          className={className}
          d="M90.634,35.693 L48.548,0.862 C47.816,0.257 46.92,-0.022 46.035,0.001 C45.15,-0.022 44.253,0.257 43.522,0.862 L28.066,13.654 C27.349,14.247 26.265,13.737 26.265,12.806 L26.265,6.435 C26.265,4.872 24.986,3.593 23.423,3.593 L15.847,3.593 C14.284,3.593 13.006,4.872 13.006,6.435 L13.006,24.884 C13.006,25.666 12.657,26.406 12.056,26.904 L1.436,35.693 C-0.053,36.926 -0.464,39.092 0.582,40.717 C1.829,42.655 4.467,43.02 6.189,41.594 L42.934,11.183 C44.733,9.694 47.337,9.694 49.136,11.183 L85.88,41.594 C87.603,43.02 90.241,42.655 91.487,40.717 C92.533,39.092 92.123,36.926 90.634,35.693 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}