export function electronics (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g data-name="Layer 2">
        <path
          className={className}
          d="M21,11H19V5a1,1,0,0,0-2,0v6H7V5A1,1,0,0,0,5,5v6H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V12A1,1,0,0,0,21,11ZM8,16a1,1,0,0,1-2,0V15a1,1,0,0,1,2,0Zm3,0a1,1,0,0,1-2,0V15a1,1,0,0,1,2,0Zm7,0a1,1,0,0,1-2,0V15a1,1,0,0,1,2,0Z"
        ></path>
      </g>
    </svg>
  );
}