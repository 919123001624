import merge from 'lodash/merge';
import { RECEIVE_SITE, RECEIVE_SITES, REMOVE_SITE, RECEIVE_USER_SITES, RECEIVE_USER_SITE, RECEIVE_NUM } from '../../constants';

const SitesReducer = (state={sites:[], userSites:[]}, action) => {
    Object.freeze(state);
    let newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_SITE:
            break
        case RECEIVE_SITES:
            let newSites = action.sites.data;
            newState.sites = newSites
            return newState;
        case RECEIVE_NUM:
            break
        case RECEIVE_USER_SITE:
            return newState;
        case RECEIVE_USER_SITES:
            let userSites;
            // debugger
            // console.log("action.sites.data: ", action.sites.data, "action.sites.data.data: ", action.sites.data.data);
            // debugger
            if (!action.sites.data.data) {
                return newState
            }
            if (action.sites.data.data['resource_rid']) {
                userSites = [action.sites.data.data];
            } else  {
                userSites = action.sites.data.data;   
            }
            state.entities.usersites.push(...userSites)
            return newState
        case REMOVE_SITE:
            break

        default:
            return state;
    }
}

export default SitesReducer;