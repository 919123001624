export function waterskiing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.0"
      x="0px"
      y="0px"
      viewBox="0 0 99.266 100"
      enableBackground="new 0 0 99.266 100"
      className={outerLayer}
    >
      <path
        className={className}
        d="M59.165,88.417c0.708-0.123,1.417-0.172,2.15-0.172c2.224,0,4.35,0.514,6.231,1.393c1.833,0.807,3.886,1.296,6.036,1.296
s4.228-0.489,6.061-1.296c1.882-0.879,4.008-1.393,6.207-1.393c2.225,0,4.35,0.514,6.232,1.393c1.832,0.807,3.91,1.296,6.035,1.296
V100c-2.125,0-4.203-0.489-6.035-1.319c-1.883-0.855-4.008-1.369-6.232-1.369c-2.199,0-4.325,0.514-6.207,1.369
c-1.833,0.83-3.91,1.319-6.061,1.319s-4.203-0.489-6.036-1.319c-1.882-0.855-4.008-1.369-6.231-1.369
c-2.199,0-4.326,0.514-6.232,1.369C53.25,99.511,51.198,100,49.047,100s-4.204-0.489-6.037-1.319
c-1.881-0.855-4.008-1.369-6.231-1.369s-4.35,0.514-6.231,1.369c-1.833,0.83-3.886,1.319-6.012,1.319
c-2.15,0-4.203-0.489-6.036-1.319c-1.906-0.855-4.032-1.369-6.231-1.369c-2.225,0-4.35,0.514-6.232,1.369
C4.204,99.511,2.15,100,0,100v-9.066c2.15,0,4.204-0.489,6.036-1.296c1.883-0.879,4.008-1.393,6.232-1.393
c2.199,0,4.325,0.514,6.231,1.393c1.833,0.807,3.886,1.296,6.036,1.296c2.126,0,4.179-0.489,6.012-1.296
c1.075-0.488,2.199-0.879,3.373-1.1l57.184-10.189c1.857-0.44,4.375-1.736,5.254-3.959c0.172-0.416,0.783-0.538,1.296-0.514
c0.929,0.049,1.612,0.855,1.612,1.783c0,1.467-1.123,5.572-7.379,6.844L59.165,88.417z"
      ></path>
      <path
        className={className}
        d="M9.555,16.544c4.57,0,8.284-3.714,8.284-8.284S14.125,0,9.555,0s-8.26,3.69-8.26,8.26S4.985,16.544,9.555,16.544z"
      ></path>
      <path
        className={className}
        d="M33.602,83.407c0.416,1.832,2.639,3.592,4.961,3.592l-0.024,0.024c2.908,0,5.278-2.346,5.278-5.278l-0.098-0.855
l-6.305-27.053c-0.538-1.735-2.176-3.373-3.861-4.057l0,0l-9.262-5.572l-4.229-18.231l26.051-0.049c2.127,0,3.838-1.71,3.838-3.837
c0-2.126-1.711-3.861-3.838-3.861H8.504c-3.764,0-5.498,2.688-5.059,5.034l5.327,24.169c0.415,2.273,1.906,4.179,3.91,5.18
l0.122,0.049l15.494,9.116L33.602,83.407z"
      ></path>
      <rect x="52.2" y="23.094" width="46.041" height="2.884"></rect>
    </svg>
  );
}