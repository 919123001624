import {
  updateUserInfo,
  updateUserPassword,
} from "../../../../actions/user_actions";

export const mapStateToProps = ({ entities, ui, session }) => {
  let user = ui.modal.userToEdit ? ui.modal.userToEdit : null;
  let accountRoles = ui.components.accountRoles
    ? ui.components.accountRoles
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  return {
    user,
    accountRoles,
    countries,
    statesProvences,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (userInfo) => dispatch(updateUserInfo(userInfo)),
    updateUserPassword: (data) => dispatch(updateUserPassword(data)),
  };
};
