import axios from 'axios';

export const fetchAdventureData = (explorer_rid) => {
  return axios.patch(`/api/r/${explorer_rid}`);
};

export const fetchAdventureResources = (adventure_rid) => {
  return axios.patch(`/api/r/resources/all/${adventure_rid}`);
};

export const postAdventureData = (account_rid, data) => {
  return axios.post(`/api/a/post_adventure/${account_rid}`, data);
};

export const fetchUserAdventures = (data) => {
  return axios.patch(`/api/a/adventures`, data);
};

export const fetchUserBlocks = (account_rid) => {
  return axios.patch(`/api/b/return_user_blocks/${account_rid}`);
};

export const fetchAdventureBlocks = (account_rid, adventure_rid) => {
  return axios.patch(
    `/api/b/return_adventure_blocks/${account_rid}/${adventure_rid}`,
  );
};

export const fetchAdventureItinerary = (adventure_rid, explorer_rid) => {
  return axios.patch(
    `/api/it/adventure_itinerary/${adventure_rid}/${explorer_rid}`,
  );
};

export const deleteAdventure = (adventure) => {
  return axios.patch(`/api/a/delete_adventure`, adventure);
};

export const lazyLoadAdventures = (page_number, page_size) => {
  return axios.patch(`/api/a/adventure_infinite/${page_number}/${page_size}`);
};

export const insertTripAndReturn = (data) => {
  return axios.post(`/api/a/single_adventure`, data);
};

export const copyAdventure = (data) => {
  return axios.post(`/api/a/copy-adventure`, data);
};

export const fetchSingleAdventure = (adventure_rid) => {
  return axios.get(`/api/a/adventure/${adventure_rid}`);
};

export const postAdventureDefaultTransportationType = (data) => {
  return axios.post('/api/a/adventure-transportation-type', data);
};

export const updateAdventureImpact = (data) => {
  return axios.post('/api/a/adventure-impact', data);
};

export const fetchAdventureVoteInfo = (adventure_rid, explorer_rid) => {
  return axios.get(`/api/a/adventure-vote/${adventure_rid}/${explorer_rid}`);
};

export const upsertAdventureVote = (data) => {
  return axios.patch(`/api/a/adventure-vote`, data);
};

export const returnAdventureTag = (account_rid, adventure_rid) => {
  return axios.get(`/api/a/adventure-tag/${account_rid}/${adventure_rid}`);
};

export const insertAdventureTag = (account_rid, adventure_rid) => {
  return axios.post(`/api/a/adventure-tag/${account_rid}/${adventure_rid}`);
};

export const deleteAdventureTag = (account_rid, adventure_rid) => {
  return axios.delete(`/api/a/adventure-tag/${account_rid}/${adventure_rid}`);
};
export const fetchAdventureImages = (adventureRid) => {
  return axios.get(`/api/ui/adventure-images/${adventureRid}`);
};

export const upsertAdventureImage = (adventureRid, data) => {
  return axios.post(`/api/a/adventure-images/${adventureRid}`, data);
};

export const deleteAdventureImage = (adventureRid, adventureImageRid) => {
  return axios.delete(
    `/api/a/adventure-images/${adventureRid}/${adventureImageRid}`,
  );
};

export const getAdventureHolderImage = (adventureRid) => {
  return axios
    .get(`/api/ui/current-user-image/${adventureRid}/0/5`)
    .then((res) => {
      return res?.data?.images[0] || undefined;
    });
}
