import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//import ReactTooltip from 'react-tooltip';
import { Tooltip } from '@mui/material';
import Collapsible from 'react-collapsible';
//import HubButton from '../../../../../components/hub-button';
//import { openAdventureModal } from '../../../../../actions/modal_actions';
import {
  svgEdit,
  svgDelete,
  svgCopy,
  //svgSettingsIcon,
} from 'components/svg_module';
import { GearIcon } from '../svgs';
import '../../../../../components/hub-button/tooltip-button.scss';

const DropDownButton = ({
  openAdventureModal,
  deleteAdventure,
  openDeleteModal,
  openAdventureCopyModal,
}) => {
  const history = useHistory();
  const current_adventure_data = useSelector(
    (state) => state.entities?.adventure?.selectedAdventure || null,
  );
  const [displaySettingsDropdown, toggleSettingsDropdown] = useState(false);
  const settingsButtonInnerRef = useRef();
  const settingsButtonOuterRef = useRef();
  const handleClickOutSide = useCallback((event) => {
    if (settingsButtonInnerRef.current && settingsButtonOuterRef.current) {
      if (
        !settingsButtonInnerRef.current.contains(event.target) &&
        !settingsButtonOuterRef.current.contains(event.target)
      ) {
        toggleSettingsDropdown(false);
      }
    }
  });
  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);
  return (
    <div
      className="itinerary_adventure_block_bottom_icon_dropdown tooltip-button-container"
      ref={settingsButtonOuterRef}
    >
      <Collapsible
        trigger={
          <Tooltip title="Trip Settings" arrow>
            {GearIcon}
          </Tooltip>
        }
        contentOuterClassName="trip-board-box-menu-closed--bottom_center edit-adventure-wrapper"
        contentInnerClassName="adventure-menu trip-board-box-menu-opened edit-adventure"
        open={displaySettingsDropdown}
        handleTriggerClick={() =>
          toggleSettingsDropdown(!displaySettingsDropdown)
        }
        transitionTime={10}
      >
        <div className="action-svg-wrapper" ref={settingsButtonInnerRef}>
          <div
            className="create-modal-svg-wrapper"
            onClick={() => {
              toggleSettingsDropdown(false);
              openAdventureModal(false, current_adventure_data, true);
            }}
            data-tip
            data-for="editToolTip"
          >
            <div className="buttonify">
              {svgEdit(
                '',
                'itinerary-trip-board-svg',
                'itinerary-trip-board-svg-outer',
              )}
              <span className="empty-block-text-high-lighter">EDIT</span>
            </div>
          </div>
          <div
            className="create-modal-svg-wrapper"
            onClick={() => {
              toggleSettingsDropdown(false);
              const passedFunc = (callBack) => {
                deleteAdventure(current_adventure_data).then(() => {
                  callBack();
                  history.push('/hub/my_adventures');
                });
              };

              openDeleteModal('trip', passedFunc);
            }}
            data-tip
            // data-for="deleteToolTip"
          >
            <div className="buttonify">
              {svgDelete(
                '',
                'itinerary-trip-board-svg',
                'itinerary-trip-board-svg-outer',
              )}
              <span className="empty-block-text-high-lighter">DELETE</span>
            </div>
          </div>
          <div
            className="create-modal-svg-wrapper"
            onClick={() => {
              toggleSettingsDropdown(false);
              let openType = { copySelf: true };
              return openAdventureCopyModal(current_adventure_data, openType);
            }}
            data-tip
            data-for="editToolTip"
          >
            <div className="buttonify">
              {svgCopy(
                '',
                'itinerary-trip-board-svg',
                'itinerary-trip-board-svg-outer',
              )}
              <span className="empty-block-text-high-lighter">COPY</span>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default DropDownButton;
