import { Box, makeStyles } from '@material-ui/core';
import { styleValues } from '../../pages/pwa_hub/adventure/components/constants';
import HubButton from '../hub-button';
import UserBar from '../user-bar';
import { ReactComponent as PlusButton } from './plus.svg';
const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
}));
const UserProfileCard = ({ user, onRemove, onClick, curUser, myRole }) => {
  const classes = useStyles();

  const isMyBlock =
    user && curUser && user?.explorer_rid == curUser?.explorer_rid;

  let isThisToBeShown = false;
  //  I'm a trip Host, so I can't remove myself and can remove others.
  if (myRole === 3) {
    isThisToBeShown = isMyBlock ? false : true;
  }
  // This is my info and I'm a trip Guest, so I can remove myself from this trip
  else if (myRole === 2) {
    isThisToBeShown = isMyBlock ? true : false;
  }
  // I'm a Trip Viewer, so I can't remove anyone in this trip.
  else {
    isThisToBeShown = false;
  }

  return (
    <Box
      className={[classes.flexCenter, classes.borderRadius_20_10].join(' ')}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        width: '184px',
        height: '184px',
        padding: '8px',
        backgroundColor: '#F5EDDF',
        cursor: 'pointer',
      }}
      onClick={() => user === null && onClick()}
    >
      {user === null ? (
        <PlusButton />
      ) : (
        <>
          <UserBar user={user} size="small" spacing={1} avatarposition="left" />
          {isThisToBeShown ? (
            <div style={{ margin: '16px' }}>
              <HubButton
                className={classes.spanSizeWithOutFont}
                onClick={() => onRemove()}
                mode={'dark'}
              >
                REMOVE
              </HubButton>
            </div>
          ) : (
            <div style={{ margin: '16px' }}>
              <div style={{ height: '35px', width: '100%' }}></div>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default UserProfileCard;
