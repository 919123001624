import React, { useEffect, useState, useCallback } from 'react';
import { Input, DropDownSelect } from 'components/global_modal/modal_modules';

const Contact = ({
  statesProvences,
  values,
  onChangeHandler,
  saved,
  saveHandler,
  cancelHandler,
  countries,
}) => {
  const [countryRid, setCountryRid] = useState([]);
  const [stateRid, setStateRid] = useState([]);
  const handleStateSelect = useCallback((event) => {
    if (values && statesProvences) {
      let target = {};
      if (event.length > 0) {
        const { state_provence_rid } = event[0];
        target = {
          currentTarget: {
            name: 'state_provence_rid',
            value: state_provence_rid,
            id: 'contact',
          },
        };
      } else {
        target = {
          currentTarget: {
            name: 'state_provence_rid',
            value: null,
            id: 'contact',
          },
        };
      }
      onChangeHandler(target);
    }
  });
  const handleCountryChange = useCallback((event) => {
    let target = {};
    if (values && countries) {
      if (event.length > 0) {
        const { country_rid } = event[0];
        target = {
          currentTarget: {
            name: 'country_rid',
            value: country_rid,
            id: 'contact',
          },
        };
      } else {
        target = {
          currentTarget: { name: 'country_rid', value: null, id: 'contact' },
        };
      }
      onChangeHandler(target);
    }
  });
  useEffect(() => {
    if (statesProvences && values.state_provence_rid) {
      setStateRid(
        statesProvences.filter(
          (ele) => ele.state_provence_rid === values.state_provence_rid,
        ),
      );
    }
    if (countries && values.country_name) {
      setCountryRid(
        countries.filter((ele) => ele.country_name === values.country_name),
      );
    }
  }, [values, saved]);
  return (
    <div className="contact-section-container" id="contact-section-container">
      <div className="general-header">CONTACT</div>
      <hr />
      <section className="account-info-contents-wrapper">
        <div className="contact-section-body">
          <Input
            customParentClassName="contact-address" // classname for div tag that wraps the input and label
            customClassName="contact-address-child" // class name for the inputbar
            label="Address" // placeholder
            name="address_1" // key
            value={values.address_1 ? values.address_1 : ''}
            onChangeHandler={onChangeHandler}
            id="contact"
          />
          <Input
            customParentClassName="contact-apt" // classname for div tag that wraps the input and label
            customClassName="contact-apt-child" // class name for the inputbar
            label="Apt/Suite" // placeholder
            name="address_2" // key
            value={values.address_2 ? values.address_2 : ''}
            onChangeHandler={onChangeHandler}
            id="contact"
          />
          <Input
            customParentClassName="contact-city" // classname for div tag that wraps the input and label
            customClassName="contact-city-child" // class name for the inputbar
            label="City" // placeholder
            name="city" // key
            id="contact"
            value={values.city ? values.city : ''}
            onChangeHandler={onChangeHandler}
          />
          <div className="contact-state-country-container">
            <DropDownSelect
              values={stateRid}
              placeholder="State"
              className="contact-state-select-child"
              onChange={handleStateSelect}
              options={statesProvences}
              parentClassName="contact-state-select-parent"
              labelField={'state_provence'}
              valueField={'state_provence_rid'}
              clearable
            />
            <DropDownSelect
              values={countryRid}
              placeholder="Country"
              className="contact-country-select-child"
              onChange={handleCountryChange}
              options={countries}
              parentClassName="contact-country-select-parent"
              labelField={'country_name'}
              valueField={'country_rid'}
              clearable
            />
          </div>
          <Input
            customParentClassName="contact-phone" // classname for div tag that wraps the input and label
            customClassName="contact-phone-child" // class name for the inputbar
            label="Phone" // placeholder
            name="phone" // key
            value={values.phone ? values.phone : ''}
            onChangeHandler={onChangeHandler}
            type="tel"
            id="contact"
          />
        </div>
        <div className="contact-section-footer-container">
          <div className="contact-section-footer">
            <button
              className="contact-button contact-button--cancel"
              onClick={cancelHandler}
            >
              Cancel
            </button>
            <button
              className="contact-button contact-button--save"
              onClick={saveHandler}
            >
              Save
            </button>
            {saved ? '' : <div className="contact-not-saved">NOT SAVED</div>}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
