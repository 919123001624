import { useDispatch } from 'react-redux';
import {
  copyItemsToItineraries,
  updateAdventureBlock,
  updateAdventureBlockAndItinerary,
  updateAdventureItineraries,
  updateLibraryBlock,
  upsertBlockNoReturn,
  upsertBlockReturnBlockRid,
} from '../../actions/block_actions';
import { closeModal } from '../../actions/modal_actions';
const useActivityModalHooks = (
  inputValues,
  setInputValues,
  featureMapRid,
  setFeatureMapRid,
) => {
  const dispatch = useDispatch();
  const CANT_BE_NULL = {
    block_name: "Block name can't be empty",
  };
  const ACTIVITY_MODAL_FEATURE_MAP = {
    //creating new top level library block from sub nav
    '1a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) => {
        uiUpdatePayload.uiUpdateFunction();
        return dispatch(updateLibraryBlock(uiUpdatePayload));
      },
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error creating new activity, please try again later!',
    },
    //creating new top level adventur block from sub nav - phase 1
    '2a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
      },
      bottomText: 'Add to itineraries',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (returnActionPayload) =>
        dispatch(updateAdventureBlock(returnActionPayload)),
      moveToNextPhase: true,
      nextPhase: '2b',
      addsToItinerary: false,
      errorMessage: 'Error creating new activity, please try again later!',
    },
    //creating new top level adventure block from sub nav - phase 2
    '2b': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and Close',
      handleSubmit: (data) => dispatch(copyItemsToItineraries(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureItineraries(uiUpdatePayload)),
      moveToNextPhase: false,
      addsToItinerary: true,
      errorMessage:
        'Error adding activity to itineraries, please try again later!',
    },

    // creating new location block from map(library)
    '3a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (returnActionPayload) => returnActionPayload(),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error creating location block, please try again later!',
    },

    //creating new location block from map(adventure / itinerary) - phase 1
    '4a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: null,
      },
      bottomText: 'Add to itineraries',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (uiUpdatePayload) => uiUpdatePayload.uiUpdateFunction(),
      moveToNextPhase: true,
      nextPhase: '4b',
      addsToItinerary: false,
      errorMessage: 'Error creating location block, please try again later!',
    },

    //creating new location block from map(adventure / itinerary) - phase 2
    '4b': {
      paginations: {
        summary: null,
        category: null,
        location: null,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(copyItemsToItineraries(data)),
      uiUpdates: (uiUpdatePayload) => {
        uiUpdatePayload.uiUpdateFunction();
        return dispatch(updateAdventureItineraries(uiUpdatePayload));
      },
      moveToNextPhase: false,
      addsToItinerary: true,
      errorMessage:
        'Error adding activity to itineraries, please try again later!',
    },

    //creating new location block from drawer(not on adventure / itinerary) - phase 1
    '5a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Save and add attachment',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (data) => dispatch(updateLibraryBlock(data)),
      moveToNextPhase: false,
      nextPhaseScrolled: false,
      nextPhasePage: 6,
      addsToItinerary: false,
      errorMessage: 'Error creating location point, please try again later!',
      nextPhase: '5b',
    },

    //creating new location block from drawer(not on adventure / itinerary) - phase 2
    '5b': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: null,
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage:
        'Error updating location point details, please try again later!',
    },
    //creating new location block from drawer(on adventure / itinerary) - phase 1

    '6a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (returnActionPayload) =>
        dispatch(updateAdventureBlock(returnActionPayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error creating location point, please try again later!',
    },
    //creating new location block from drawer(on adventure / itinerary) - phase 2

    '6b': {
      paginations: {
        summary: null,
        category: null,
        location: null,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: '',
      handleSubmit: '',
      uiUpdates: '',
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage:
        'Error updating location point details, please try again later!',
    },
    //creating new nested library block from sub-nav
    '7a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) => {
        uiUpdatePayload.uiUpdateFunction();
        return dispatch(updateLibraryBlock(uiUpdatePayload));
      },
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error creating activity, please try again later!',
    },

    //creating new nested adventure block from sub nav - phase 1
    '8a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Add to itineraries',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (returnActionPayload) =>
        dispatch(updateAdventureBlock(returnActionPayload)),
      moveToNextPhase: true,
      nextPhase: '8b',
      addsToItinerary: false,
      errorMessage: 'Error creating activity, please try again later!',
    },
    //creating new nested adventure block from sub nav - phase 2

    '8b': {
      paginations: {
        summary: null,
        category: null,
        location: null,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Save and Close',
      handleSubmit: (data) => dispatch(copyItemsToItineraries(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureItineraries(uiUpdatePayload)),
      moveToNextPhase: false,
      addsToItinerary: true,
      errorMessage:
        'Error adding activity to itineraries, please try again later!',
    },
    //editing library block
    '9a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (returnActionPayload) =>
        dispatch(updateLibraryBlock(returnActionPayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },
    //editing nested library block
    '10a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (returnActionPayload) =>
        dispatch(updateLibraryBlock(returnActionPayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },
    //editing trip-board adventure block
    '11a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureBlockAndItinerary()),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },

    //editing nested adventure block
    '12a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureBlockAndItinerary(uiUpdatePayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },

    //editing itinerary block (level === 1 adventure block)
    '13a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureBlockAndItinerary(uiUpdatePayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },

    //eiditing itinerary block (level > 1 adventure block)
    '14a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) =>
        dispatch(updateAdventureBlockAndItinerary(uiUpdatePayload)),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },

    //editing location block on map (library block elvel === 1)
    '15a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (returnActionPayload) => returnActionPayload(),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },

    //editing location block on map (library level > 1)
    '16a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: '',
      handleSubmit: '',
      uiUpdates: '',
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },
    // editiing location block on map adventure block level === 1
    '17a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (returnActionPayload) => returnActionPayload(),
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },
    //editing location block on map adventure block level > 1
    '18a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: 4,
      },
      bottomText: '',
      handleSubmit: '',
      uiUpdates: '',
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error updating activity, please try again later!',
    },
    // creating new location block from campground/campsite
    '19a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
        attachments: null,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockReturnBlockRid(data)),
      uiUpdates: (uiUpdatePayload, block) => {
        return uiUpdatePayload.uiUpdateFunction(block);
      },
      moveToNextPhase: true,
      nextPhase: '19b',
      addsToItinerary: false,
      errorMessage: 'Error creating location block, please try again later!',
    },

    //creating new location block from map(adventure / itinerary) - phase 2
    '19b': {
      paginations: {
        summary: null,
        category: null,
        location: null,
        social: null,
        color: null,
        attachments: null,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(copyItemsToItineraries(data)),
      uiUpdates: (uiUpdatePayload) => {
        uiUpdatePayload.uiUpdateFunction();
        return dispatch(updateAdventureItineraries(uiUpdatePayload));
      },
      moveToNextPhase: false,
      addsToItinerary: true,
      errorMessage:
        'Error adding activity to itineraries, please try again later!',
    },
    //creating new library location block from campground/campsites
    '20a': {
      paginations: {
        summary: 1,
        category: 2,
        location: 3,
      },
      bottomText: 'Save and close',
      handleSubmit: (data) => dispatch(upsertBlockNoReturn(data)),
      uiUpdates: (uiUpdatePayload) => {
        uiUpdatePayload.uiUpdateFunction();
        return dispatch(updateLibraryBlock(uiUpdatePayload));
      },
      moveToNextPhase: false,
      addsToItinerary: false,
      errorMessage: 'Error creating location block, please try again later!',
    },
  };
  const {
    paginations,
    bottomText,
    handleSubmit,
    uiUpdates,
    moveToNextPhase,
    addsToItinerary,
    errorMessage,
    nextPhase,
  } = ACTIVITY_MODAL_FEATURE_MAP[featureMapRid];

  const combinedSubmit = (
    moveToNextPhase,
    handleSubmitFunc,
    uiUpdatesFunc,
    errorMessage,
  ) => (data, returnActionPayload) =>
    new Promise(async (resolve, reject) => {
      // the code has to only run when user is upserting a block
      if (!addsToItinerary) {
        let errors = [];
        Object.keys(CANT_BE_NULL).forEach((ele) => {
          if (!data[ele] || data[ele] === '') {
            errors.push({ ele: CANT_BE_NULL[ele] });
          }
        });
        if (errors.length > 0) {
          reject({ errors, errorMessage });
        }
      }
      await handleSubmitFunc(data).then(async (res) => {
        if (moveToNextPhase) {
          setInputValues({ ...inputValues, block_rid: res.block_rid });
          await uiUpdatesFunc(returnActionPayload, res);
          return setFeatureMapRid(nextPhase);
        } else {
          if (!uiUpdatesFunc) {
            return dispatch(closeModal());
          }
          return await uiUpdatesFunc(returnActionPayload, res).then(() => {
            dispatch(closeModal());
          });
        }
      });
      resolve(undefined);
    });
  return {
    paginations,
    bottomText,
    submitFunc: combinedSubmit(
      moveToNextPhase,
      handleSubmit,
      uiUpdates,
      errorMessage,
    ),
    addsToItinerary,
  };
};

export default useActivityModalHooks;
