import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: calc(100% - 48px);
  height: 60px;
  @media (max-width: 600px) {
    height: 50px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 24px;
  background-color: #273a07;
  z-index: 9;
`;

export const LogoBar = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SubTitleBar = styled.div`
  min-width: 30%;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const UserIconBar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

export const StyledImg = styled.img`
  cursor: pointer;
`;
