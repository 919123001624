import { useState } from 'react';
import { ItineraryAccordion } from '../../modal_modules';

const SelectDate = ({
  startDates,
  invitedAdventureItineraries,
  handleSelectItineraryItem,
  ...props
}) => {
  return (
    <>
      <div className="color-separator-bar">
        WHICH DATE WILL YOU BE JOINING THE TRIP?
      </div>
      <div className="accept-trip-invite-section-contents">
        <div className="accept-trip-invite-select-starting-date-contents-wrapper">
          {invitedAdventureItineraries &&
          invitedAdventureItineraries.length > 0 ? (
            invitedAdventureItineraries.map((itinerary, idx) => {
              const handleClick = handleSelectItineraryItem(
                itinerary.itinerary_rid,
              );
              return (
                <ItineraryAccordion
                  itinerary={itinerary}
                  selectedItineraryItem={startDates[itinerary.itinerary_rid]}
                  handleSelectItineraryItem={handleClick}
                />
              );
            })
          ) : (
            <div className="no-itineraries-message-wrapper">
              <p>No planned itineraries yet</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectDate;
