export const svgAppleMaps = (color, className, outerLayer) => {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g clipPath="url(#clip0_118_36)">
        <rect x="259" y="381" width="253" height="131" fill="#F8C702" />
        <rect width="512" height="381" fill="url(#paint0_linear_118_36)" />
        <rect y="188" width="132" height="324" fill="#EC87C9" />
        <g clipPath="url(#clip1_118_36)">
          <circle
            cx="526.5"
            cy="-1.5"
            r="163.5"
            stroke="white"
            strokeWidth="86"
          />
          <path
            d="M526.5 132.519C600.516 132.519 660.518 72.5166 660.518 -1.49977C660.518 -75.5162 600.516 -135.518 526.5 -135.518C452.483 -135.518 392.481 -75.5162 392.481 -1.49977C392.481 72.5166 452.483 132.519 526.5 132.519ZM718.802 -1.49977C718.802 104.706 632.705 190.803 526.5 190.803C420.294 190.803 334.197 104.706 334.197 -1.49977C334.197 -107.705 420.294 -193.802 526.5 -193.802C632.705 -193.802 718.802 -107.705 718.802 -1.49977Z"
            fill="#D2D1D6"
            stroke="#CAC9CE"
            strokeWidth="8"
          />
        </g>
        <path
          className={className}
          d="M275.801 228.405V231.122L278.08 232.6L545.201 405.839L475.377 513.501L-63.0843 164.285L6.73907 56.6234L95.7271 114.336C104.376 119.945 115.801 113.738 115.801 103.429V-2H275.801V228.405Z"
          fill="#F0EFF5"
          stroke="white"
          strokeWidth="10"
        />
        <path
          className={className}
          d="M139.5 -7.5H196H252.5V248.5H139.5V-7.5Z"
          fill="url(#paint1_linear_118_36)"
          stroke="white"
          strokeWidth="15"
        />
        <path
          className={className}
          d="M132 256H196H260V512H132V256Z"
          fill="#EAE9EE"
        />
        <g filter="url(#filter0_d_118_36)">
          <circle
            cx="196"
            cy="317"
            r="119"
            fill="url(#paint2_linear_118_36)"
            stroke="white"
            strokeWidth="20"
          />
          <path
            className={className}
            d="M208.22 239.518C204.074 228.161 187.927 228.161 183.781 239.518L137.807 370.391C134.709 379.208 145.44 386.32 152.353 380.032L196 340.336L239.647 380.032C246.56 386.32 257.291 379.208 254.194 370.391L208.22 239.518Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_118_36"
          x="7"
          y="143"
          width="378"
          height="378"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="15" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_118_36"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_118_36"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_118_36"
          x1="256"
          y1="0"
          x2="256"
          y2="381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7FED7D" />
          <stop offset="1" stopColor="#2ED058" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_118_36"
          x1="196"
          y1="-15"
          x2="196"
          y2="256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#318DF8" />
          <stop offset="1" stopColor="#4081FA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_118_36"
          x1="196"
          y1="188"
          x2="196"
          y2="446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C87FB" />
          <stop offset="1" stopColor="#2D76F6" />
        </linearGradient>
        <clipPath id="clip0_118_36">
          <rect width="512" height="512" rx="112" fill="white" />
        </clipPath>
        <clipPath id="clip1_118_36">
          <rect x="320" width="192" height="205" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
