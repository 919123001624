export function hiking (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;" />
        <g>
          <path
            fill={color}
            className={className}
            d="M16.31024,46.63839H4.8703c-0.1001,0-0.21008,0-0.31006,0.02002V36.26839   C10.15021,37.21841,14.69012,41.29842,16.31024,46.63839z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M64.3703,49.26839v5.15002H45.92023c-8.82996,0-14.94007-7.78003-24.54004-7.78003H17.8703   c-1.69006-6.16998-6.89014-10.90002-13.31006-11.89001V10.54842c0-1.10004,0.8999-2,2-2h16.37v20.64996   c0,4.94,4.00989,8.96002,8.94995,8.96002h11.10999c0.05005,0,0.1001-0.01001,0.15002-0.02002   c2.10999,1.07001,4.5,1.66998,7.03003,1.66998h4.73999C60.1402,39.80837,64.3703,44.04842,64.3703,49.26839z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M66.125,57.39079v1.5899c0,0.80852-0.66151,1.47003-1.47003,1.47003h-2.4101c-0.55228,0-1-0.44772-1-1v-0.35999   c0-0.55228-0.44772-1-1-1H58.7749c-0.55228,0-1,0.44772-1,1v0.35999c0,0.55228-0.44772,1-1,1h-1.07996c-0.55228,0-1-0.44772-1-1   v-0.35999c0-0.55228-0.44772-1-1-1h-1.46008c-0.55228,0-1,0.44772-1,1v0.35999c0,0.55228-0.44772,1-1,1h-1.07996   c-0.55228,0-1-0.44772-1-1v-0.35999c0-0.55228-0.44772-1-1-1h-1.45996c-0.55228,0-1,0.44772-1,1v0.36003   c0,0.56249-0.45882,1.01645-1.02108,1.0004c-1.59053-0.04542-4.69326-0.32345-6.77884-1.72047L24.74487,52.9907v4.71002   c0,1.1-0.9,2-2,2h-1.52002c-0.55,0-1-0.45-1-1v-1.78003c0-0.55-0.45-1-1-1h-1.77991c-0.55,0-1,0.45-1,1v1.78003c0,0.55-0.45,1-1,1   h-3.28003c-0.55,0-1-0.45-1-1v-1.78003c0-0.55-0.45-1-1-1H8.38501c-0.55,0-1,0.45-1,1v1.78003c0,0.55-0.45,1-1,1H4.875   c-1.1,0-2-0.9-2-2v-7.56c0-1.1,0.9-2,2-2h16.51001c2.6499,0,5.27991,0.58997,7.67993,1.72998l9.17993,4.33002   c2.40015,1.13,5.02002,1.71997,7.68005,1.71997h18.72998C65.46346,55.92069,66.125,56.58223,66.125,57.39079z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M66.125,57.39079v1.5899c0,0.80852-0.66151,1.47003-1.47003,1.47003h-2.4101c-0.55228,0-1-0.44772-1-1v-0.35999   c0-0.55228-0.44772-1-1-1H58.7749c-0.55228,0-1,0.44772-1,1v0.35999c0,0.55228-0.44772,1-1,1h-1.07996c-0.55228,0-1-0.44772-1-1   v-0.35999c0-0.55228-0.44772-1-1-1h-1.46008c-0.55228,0-1,0.44772-1,1v0.35999c0,0.55228-0.44772,1-1,1h-1.07996   c-0.55228,0-1-0.44772-1-1v-0.35999c0-0.55228-0.44772-1-1-1h-1.45996c-0.55228,0-1,0.44772-1,1v0.36003   c0,0.56249-0.45882,1.01645-1.02108,1.0004c-1.59053-0.04542-4.69326-0.32345-6.77884-1.72047L24.74487,52.9907v4.71002   c0,1.1-0.9,2-2,2h-1.52002c-0.55,0-1-0.45-1-1v-1.78003c0-0.55-0.45-1-1-1h-1.77991c-0.55,0-1,0.45-1,1v1.78003c0,0.55-0.45,1-1,1   h-3.28003c-0.55,0-1-0.45-1-1v-1.78003c0-0.55-0.45-1-1-1H8.38501c-0.55,0-1,0.45-1,1v1.78003c0,0.55-0.45,1-1,1H4.875   c-1.1,0-2-0.9-2-2v-7.56c0-1.1,0.9-2,2-2h16.51001c2.6499,0,5.27991,0.58997,7.67993,1.72998l9.17993,4.33002   c2.40015,1.13,5.02002,1.71997,7.68005,1.71997h18.72998C65.46346,55.92069,66.125,56.58223,66.125,57.39079z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M39.50018,35.59841c-1.59753-1.49597-2.85156-3.3269-3.70654-5.37653h1.38599c0.27637,0,0.5-0.22363,0.5-0.5   s-0.22363-0.5-0.5-0.5h-1.77405c-0.54443-1.58112-0.84534-3.2666-0.84534-5.0235v-1.62201h2.61938c0.27637,0,0.5-0.22363,0.5-0.5   s-0.22363-0.5-0.5-0.5h-2.61938v-6.64648h2.61938c0.27637,0,0.5-0.22363,0.5-0.5s-0.22363-0.5-0.5-0.5h-2.61938v-3.38147   c0-1.10004-0.90002-2-2-2h-8.13001v20.64996c0,4.12,3.33997,7.46002,7.44995,7.46002h8.88001   C40.32025,36.32839,39.90021,35.96841,39.50018,35.59841z M30.00018,11.91842c1.21924,0,2.22974,0.86188,2.45996,2.01147h-2.46216   c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h2.46192c-0.2312,1.15033-1.24146,2.01849-2.45972,2.01849   c-1.38989,0-2.52002-1.13-2.52002-2.52002C27.48016,13.03841,28.61029,11.91842,30.00018,11.91842z M30.00018,19.55837   c1.21802,0,2.2284,0.86786,2.45972,2.01801h-2.46192c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h2.46228   c-0.2301,1.14996-1.24072,2.01202-2.46008,2.01202c-1.38989,0-2.52002-1.12-2.52002-2.51001S28.61029,19.55837,30.00018,19.55837z    M30.00018,32.23837c-1.38989,0-2.52002-1.12994-2.52002-2.51996c0-1.38,1.13013-2.51001,2.52002-2.51001   c1.21887,0,2.22949,0.86908,2.45996,2.01349h-2.46216c-0.27637,0-0.5,0.22363-0.5,0.5s0.22363,0.5,0.5,0.5h2.46167   C32.22772,31.37124,31.21771,32.23837,30.00018,32.23837z"
          ></path>
        </g>
      </g>
    </svg>
  );
}