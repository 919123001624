import React, { useEffect, useState, useCallback, useRef } from 'react';
import { List } from '../../modal_modules';
import { svgCreate, fullScreen, svgFullScreen } from 'components/svg_module';
import { lastLevelFinder } from 'util/helpers/lists_helper';
import { PopUpOverlay } from '../../../global_modules';
import ListAddForm from './list_form';
import TreeList from './dnd_tree_functional';
import { merge } from 'lodash';
import ScrollIndicator from '../../../modules/scroll_indicators';
import TooltipButton from '../../../hub-button/tooltip-button';
import { AddIcon } from '../../../../pages/pwa_hub/adventure/components/svgs';

const RenderList = ({
  list,
  userInfo,
  upsertBlock,
  actionPayload,
  currentBlock,
  newBlock,
  deleteBlock,
  triggerNext,
  fetchListDropDown,
  listScrollerRef,
  viewSimple,
  uiTransitionToggles,
  setUiTransitionToggles,
  currentTab,
  openType,
}) => {
  Element.prototype.scrollIntoViewPromise = function (options) {
    // "this" refers to the current element (el.scrollIntoViewPromise(options): this = el)
    this.scrollIntoView(options);

    // I create a variable that can be read inside the returned object ({ then: f() }) to expose the current element
    let parent = this;

    // I return an object with just a property inside called then
    // then contains a function which accept a function as parameter that will be execute when the scroll ends
    return {
      then: function (x) {
        // Check out https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API for more informations
        const intersectionObserver = new IntersectionObserver((entries) => {
          let [entry] = entries;

          // When the scroll ends (when our element is inside the screen)
          if (entry.isIntersecting) {
            // Execute the function into then parameter and stop observing the html element
            setTimeout(() => {
              x();
              intersectionObserver.unobserve(parent);
            }, 100);
          }
        });

        // I start to observe the element where I scrolled
        intersectionObserver.observe(parent);
      },
    };
  };

  const movedOnInitalRef = useRef(false);
  const [displayScrollIndicator, setDisplayScrollIndicator] = useState(false);

  const [localList, setLocalList] = useState([]);
  const [displayInput, setDisplayInput] = useState({
    display: false,
    displayIndex: 0,
    modifiedParentBlockRid: null,
    parentItemRid: null,
  });
  const [inputValues, setInputValues] = useState({
    block_name: '',
    block_description: '',
    block_count: '',
    block_cost: '',
    parent_block_rid: currentBlock
      ? currentBlock.block_rid
      : newBlock.block_rid,
    priority_rid: null,
    list_flag: true,
    start_date: null,
    end_date: null,
    display_start_date: null,
    display_end_date: null,
  });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setLocalList(list);
  }, [list]);
  const highestLevel = list ? lastLevelFinder(list) : null;
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.currentTarget;
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleKeyPress = useCallback((event) => {
    if (event.keyCode === 13) {
      addSimpleListEntryHandler(event);
    }
  });

  /* solution for creating lists: create a form for inputting additional list that looks exactly like the lists
   */

  const addUnderItemHandler = useCallback((e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    setDisplayInput({
      display: true,
      // displayIndex: index,
      parentItemRid: value,
    });
  });

  const listNodeCancelHandler = () => {
    setDisplayInput({
      display: false,
      displayIndex: 0,
      modifiedParentBlockRid: null,
      parentItemRid: null,
    });
  };
  const addSimpleListEntryHandler = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    let blockType = {
      isListBlock: true,
    };
    let returnActionPayload = {
      parentBlockRid: currentBlock
        ? currentBlock.block_rid
        : newBlock.block_rid,
      adventureBlockRid: currentBlock
        ? currentBlock.adventure_block_rid
        : newBlock.adventure_block_rid,
    };
    let passOver = merge({}, inputValues);
    if (passOver.block_count === '') passOver.block_count = null;
    if (passOver.block_cost === '') passOver.block_cost = null;
    upsertBlock(userInfo, passOver, blockType, returnActionPayload);
    setInputValues({
      block_name: '',
      block_description: '',
      block_count: '',
      block_cost: '',
      parent_block_rid: currentBlock
        ? currentBlock.block_rid
        : newBlock.block_rid,
      priority_rid: null,
      list_flag: true,
    });
  });
  const onDragUpdate = useCallback((value) => {});
  const onDragEnd = useCallback((value, allBlocks) => {}, []);
  const onDragStart = useCallback((value) => {});
  const displayScrollBar = () => {
    // window.onDocument

    return (
      <div className="checklist-scroll-indicator-wrapper">
        <div className="checklist-scroll-indicator-container">
          <ScrollIndicator
            documentId={'tree-wrapper'}
            documentTargeted={
              document.getElementsByClassName('check-list-tree')[0]
            }
            useClassName
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="entries-contents-wrapper">
        <div className="simple-list-entry-wrapper">
          <input
            value={inputValues.block_name}
            name="block_name"
            onChange={handleOnChange}
            onKeyDown={handleKeyPress}
            className="simple-list-entry-input"
            placeholder="enter list item..."
          />
          <TooltipButton
            icon={AddIcon}
            onClick={addSimpleListEntryHandler}
            isListAdd={true}
          />
        </div>
        <TreeList
          newBlock={newBlock}
          listScrollerRef={listScrollerRef}
          setDisplayScrollIndicator={setDisplayScrollIndicator}
        />
      </div>
    </>
  );
};
export default RenderList;
