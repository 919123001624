import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from 'components/modules/footer';
import { login } from '../../../actions/session_actions';
import axios from 'axios';
import FloatingLabelInput from 'components/ui_library/floating_label_input';
import passwordValidator from 'password-validator';
import { Helmet } from 'react-helmet';
const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
  };
};
const Homepage = (props) => {
  const [inputValues, setInputValues] = useState({
    password: '',
    verifyPassword: '',
  });

  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data', { pageName: 'enter-password-reset' })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
  }, []);

  let locationParse = props.location.pathname.split('/');

  let email = `${locationParse[locationParse.length - 3]}@${
    locationParse[locationParse.length - 2]
  }.${locationParse[locationParse.length - 1]}`;
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    let errors = schema.validate(value, { list: true });
    setSignUpErrors({ ...signUpErrors, passwordErrors: errors });
  });

  let schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(25) // Maximum length 25
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 number
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();
  const [transitionMsg, setTransitionMsg] = useState('');
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: [],
    emailErrors: '',
    passwordErrors: [],
  });

  const submitHanlder = (e) => {
    e.preventDefault();
    let user = { email: email, password: inputValues.password };
    axios.patch('/api/e/reset_password', user).then((res) => {
      // window.alert(`${res.data.message} Please log in with your new password.`)
      setTransitionMsg(
        `Please log in with your new password. You will be redirected to the login page in 10 seconds.`,
      );
      setTimeout(() => {
        props.history.push('/');
      }, 10000);

      // props.history.push("/log-in")
    });
  };

  const renderPasswordErrors = () => {
    if (
      inputValues.password.length > 0 &&
      inputValues.verifyPassword.length > 0 &&
      inputValues.password === inputValues.verifyPassword
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('min')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be minimum 8 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('symbols')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>
              Password must contain at least one symbol (i.e ! @ # $ % ^ & * )
            </span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('max')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be maximum 25 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('uppercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain uppercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('lowercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain lowercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('digits')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must have at least 1 number</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('spaces')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password cannot have spaces</span>
          </div>
          <div className="email-error-wrapper">
            <img alt="enter-pw-reset-media" src="/symbols/bluecheck.png" />
            <span>Passwords match</span>
          </div>
        </div>
      );
    } else if (
      inputValues.password.length > 0 &&
      inputValues.verifyPassword.length > 0 &&
      inputValues.password !== inputValues.verifyPassword
    ) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('min')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be minimum 8 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('symbols')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>
              Password must contain at least one symbol (i.e ! @ # $ % ^ & * )
            </span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('max')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be maximum 25 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('uppercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain uppercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('lowercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain lowercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('digits')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must have at least 1 number</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('spaces')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password cannot have spaces</span>
          </div>
          <div className="email-error-wrapper">
            <img alt="enter-pw-reset-media" src="/symbols/red-alert.png" />
            <span>Passwords not matched</span>
          </div>
        </div>
      );
    } else if (inputValues.password.length > 0) {
      return (
        <div className="email-error-container">
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('min')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be minimum 8 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('symbols')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>
              Password must contain at least one symbol (i.e ! @ # $ % ^ & * )
            </span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('max')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must be maximum 25 characters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('uppercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain uppercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('lowercase')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must contain lowercase letters</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('digits')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password must have at least 1 number</span>
          </div>
          <div className="email-error-wrapper">
            <img
              alt="enter-pw-reset-media"
              src={
                signUpErrors.passwordErrors.includes('spaces')
                  ? '/symbols/red-alert.png'
                  : '/symbols/bluecheck.png'
              }
            />
            <span>Password cannot have spaces</span>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };
  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div
        className="page-content-wrapper"
        style={{ justifyContent: 'center' }}
      >
        <img
          alt="enter-pw-reset-media"
          src="/banner_images/lodestar_help_banner.jpeg"
          className="login-page-background-image"
        />
        <form
          id="log-in-form"
          onSubmit={(e) => {
            submitHanlder(e);
          }}
        >
          <div className="log-in-block" id="log-in-block-mobile">
            {transitionMsg ? (
              <span
                style={{
                  margin: '0 auto',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <br />
                {transitionMsg}
              </span>
            ) : (
              <div />
            )}
            <div className="newsletter-inputs login-input-dist">
              <FloatingLabelInput
                className="floating-lode-input"
                id="lode-float-input"
                type="password"
                onChange={handleOnChange}
                name="password"
                value={inputValues.password}
                label="Enter new password"
              />
            </div>
            <div className="newsletter-inputs login-input-dist">
              <FloatingLabelInput
                className="floating-lode-input"
                id="lode-float-input"
                type="password"
                onChange={handleOnChange}
                name="verifyPassword"
                value={inputValues.verifyPassword}
                label="Verify new password"
              />
            </div>

            {renderPasswordErrors()}

            <button
              className="forgot-login"
              disabled={
                inputValues.password === '' || inputValues.verifyPassword === ''
              }
              type="submit"
            >
              <span>RESET PASSWORD</span>
            </button>
            <br />
            <div className="reset-password-undertext">
              <span>
                Still having trouble?
                <br /> Send us a message at{' '}
                <a
                  href="mailto:support@lodestarhub.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  support@lodestarhub.com
                </a>
              </span>
              .
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
// to insert into the email queue: hub_subscription.func_insert_subscription_email_queue(l_email, l_email_action_rid) << < now returns the subscription_email_queue_rid as the first returned item in the array for use in the function below after it's been sent.
// to update that same table with new status: hub_subscription.func_update_subscription_email_status(v_subscription_email_queue_rid integer)

export default connect(null, mapDispatchToProps)(Homepage);
