import React from 'react'
const PriorityBox = ({ priorityName, priorityRid }) => {
  let priorityColor;

  switch (priorityName) {
    case "High":
      priorityColor = { backgroundColor: "red" };
      break;
    case "Medium":
      priorityColor = { backgroundColor: "orange" };
      break;
    case "None":
      priorityColor = { backgroundColor: "green" };
      break;
    default:
      break;
  }
  return priorityColor ? (
    <div className="priority-box" style={priorityColor}>
      {/* <span>{priorityName}</span> */}
      {/* <span>{priorityRid}</span> */}
    </div>
  ) : (
    <div />
  );
};

export default PriorityBox