export const svgInviteCircle = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      width="100px"
      fill={color}
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <path
        className={className}
        d="M50.001,5.001C25.171,5.001,5,25.172,5,50c0,24.827,20.171,45,45.001,45C74.829,94.999,95,74.827,95,50  C95,25.172,74.829,5.001,50.001,5.001z"
      ></path>
    </svg>
  );
};