import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';
import { useState } from 'react';
import useDeleteListModalButton from '../hooks/use-delete-list-modal-button';
import useEditListModalButton from '../hooks/use-edit-list-modal-button';

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      // vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: -25,
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

/**
 * @param {object} props
 * @param {import('features/blocks/constants').ListBlock | import('../constants').List} props.listData
 */
const ListActionMenu = ({ listData }) => {
  const { openModal: openDeleteModal } = useDeleteListModalButton({
    block_rid: listData.block_rid,
    title: 'block_name' in listData ? listData.block_name : listData.title,
  });

  const { openModal: openEditModal } = useEditListModalButton(
    listData.block_rid,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    openDeleteModal();
  };

  const handleEdit = () => {
    handleClose();
    openEditModal();
  };

  const id = `list-action-menu-${listData.block_rid}`;
  return (
    <>
      <IconButton
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizIcon />
      </IconButton>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default ListActionMenu;
