import { useEffect, useState } from 'react';
import SwiperCarousel from './swiper-js/_swiper-carousel';

function CardCarousel({ children, className }) {
  const cardPerviewOffset = 0.2;
  const { deviceWidth } = useResize();

  let perViewCounts = 4;
  if (deviceWidth < 450) {
    perViewCounts = 1;
  } else if (deviceWidth < 600) {
    perViewCounts = 1.5;
  } else if (deviceWidth < 750) {
    perViewCounts = 2;
  } else if (deviceWidth < 900) {
    perViewCounts = 2.5;
  } else if (deviceWidth < 1050) {
    perViewCounts = 3;
  } else if (deviceWidth < 1200) {
    perViewCounts = 3.5;
  } else if (deviceWidth < 1350) {
    perViewCounts = 4;
  } else {
    perViewCounts = 4.5;
  }
  // const perViewCounts =
  //   deviceSize === "xs"
  //     ? 1
  //     : deviceSize === "sm"
  //     ? 1.4
  //     : deviceSize === "md"
  //     ? 3
  //     : 4;

  return (
    <SwiperCarousel
      className={className}
      slidesPerView={perViewCounts + cardPerviewOffset}
      spaceBetween={15}
      grabCursor={true}
      pagination={false}
    >
      {children}
    </SwiperCarousel>
  );
}
export const useResize = () => {
  const [deviceWidth, setDeviceWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setDeviceWidth(width);
    };

    handleResize(); // Initial call to set the value on component mount

    // Event listener to update the value on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { deviceWidth };
};

export default CardCarousel;
