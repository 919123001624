export const ItineraryIcon = ({ color = "#273A07", className, outerLayer, width = 27, height = 27 }) => {
  return (
    <svg
      className={outerLayer}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M23.0536 0C25.2284 0 27 1.66136 27 3.93746C27 4.46038 26.8538 5.30575 26.6837 5.76555L23.0627 13.4998L19.4415 5.76555C19.2723 5.30586 19.1252 4.46024 19.1252 3.93746C19.1252 1.66133 20.879 0 23.0537 0H23.0536ZM23.0623 2.24997C22.1303 2.24997 21.3748 3.0055 21.3748 3.9375C21.3748 4.8695 22.1303 5.62502 23.0623 5.62502C23.9943 5.62502 24.7499 4.8695 24.7499 3.9375C24.7499 3.0055 23.9943 2.24997 23.0623 2.24997ZM4.50862 11.8126C6.9941 11.8126 8.99986 13.7519 8.99986 16.3124C8.99986 16.9005 8.83282 17.7816 8.63947 18.2987L4.49993 26.9998L0.360385 18.2987C0.166017 17.7815 0 16.9008 0 16.3124C0 13.7518 2.02333 11.8126 4.50873 11.8126H4.50862ZM21.9987 12.5597L22.4997 13.6144C18.3136 14.3641 16.4654 15.3597 15.7583 16.2863C15.3869 16.773 15.277 17.2548 15.275 17.8156C15.2722 18.3765 15.4041 19.0076 15.5211 19.6789C15.6381 20.3504 15.7454 21.0675 15.6265 21.8146C15.5076 22.5617 15.1406 23.3207 14.4049 23.9766C12.9868 25.2408 10.307 26.2066 5.15895 26.9209L5.74786 25.6905C10.3113 24.9937 12.615 24.0636 13.6492 23.1416C14.2141 22.6381 14.4355 22.1653 14.5193 21.6387C14.603 21.1121 14.526 20.5169 14.4139 19.8721C14.3015 19.2274 14.1476 18.5329 14.1502 17.8068C14.1529 17.0806 14.3373 16.3088 14.8708 15.6096C15.8617 14.3108 17.9037 13.3185 21.9988 12.5598L21.9987 12.5597ZM4.49969 14.3438C3.41242 14.3438 2.53104 15.2252 2.53104 16.3124C2.53104 17.3997 3.41246 18.2811 4.49969 18.2811C5.58692 18.2811 6.46835 17.3997 6.46835 16.3124C6.46835 15.2252 5.58692 14.3438 4.49969 14.3438Z"
        fill={color}
      />
    </svg>
  )
};
