import { Box, Stack } from '@mui/material';
import TripPlannerButton from 'features/adventure/components/trip-builder-button';
import CreateTripFolderModalButton from 'features/folder/components/create-trip-folder-modal-button';
import CreateListModalButton from './create-list-modal-button';

const props = {
  size: 'small',
  variant: 'contained',
};
const ListActionButtons = () => {
  return (
    <Box display="flex" mt={1}>
      <Stack direction="row" gap={1}>
        <CreateListModalButton {...props} />
        <CreateTripFolderModalButton {...props} />
        <TripPlannerButton {...props} />
      </Stack>
    </Box>
  );
};

export default ListActionButtons;
