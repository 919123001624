import { copyAdventure } from "../../../../actions/adventure_actions";
import {
  setLoading,
  openMessageModal,
} from "../../../../actions/modal_actions";
export const mapStateToProps = (state) => {
  let actionPayload = state.ui.modal.actionPayload
    ? state.ui.modal.actionPayload
    : null;
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let openType = state.ui.modal.openType && state.ui.modal.openType;
  return {
    actionPayload,
    userInfo,
    openType,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    copyAdventure: (data) => dispatch(copyAdventure(data)),
    setLoading: (boolean) => dispatch(setLoading(boolean)),
    openMessageModal: (message) => dispatch(openMessageModal(message)),
  };
};
