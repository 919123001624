export const svgTwitter = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      width="36.001"
      height="29.26"
      viewBox="0 0 36.001 29.26"
    >
      <path
        className={className}
        id="Icon_simple-twitter"
        d="M35.931,6.853a15,15,0,0,1-4.237,1.162,7.437,7.437,0,0,0,3.244-4.084,15.244,15.244,0,0,1-4.691,1.776,7.378,7.378,0,0,0-12.767,5.037,7.552,7.552,0,0,0,.191,1.686A20.9,20.9,0,0,1,2.46,4.741a7.233,7.233,0,0,0-1,3.713A7.381,7.381,0,0,0,4.743,14.6,7.357,7.357,0,0,1,1.4,13.674v.092a7.384,7.384,0,0,0,5.919,7.24A7.448,7.448,0,0,1,4,21.134a7.4,7.4,0,0,0,6.906,5.125,14.8,14.8,0,0,1-9.153,3.157A15.665,15.665,0,0,1,0,29.316,20.993,20.993,0,0,0,11.336,32.63c13.581,0,21-11.244,21-20.979,0-.314,0-.63-.022-.945A14.9,14.9,0,0,0,36,6.884l-.07-.03Z"
        transform="translate(0 -3.369)"
        fill={color}
      />
    </svg>
  );
};