import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';

const TravelMethods = ({
  travelMethodBlockCategories,
  selectedCategoryRid,
  clickHandler,
  ...props
}) => {
  return (
    <>
      <div className="color-separator-bar">HOW ARE YOU TRAVELING?</div>
      <div className="accept-trip-invite-section-contents">
        <div className="accept-trip-invite-travel-method-contents-wrapper">
          {travelMethodBlockCategories &&
            travelMethodBlockCategories.length > 0 &&
            travelMethodBlockCategories.map((ele, idx) => {
              return (
                <TravelMethodBlock
                  blockCategory={ele}
                  selectedCategoryRid={selectedCategoryRid}
                  clickHandler={clickHandler}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

const TravelMethodBlock = ({
  blockCategory,
  selectedCategoryRid,
  clickHandler,
}) => {
  const { block_category_rid, icon_name, block_category_name } = blockCategory;
  return (
    <button
      className={`block-category-box-wrapper ${
        selectedCategoryRid === `${block_category_rid}` && '-selected'
      }`}
      value={block_category_rid}
      onClick={clickHandler}
    >
      <div className="block-category-box-padder">
        <div className="block-category-box-svg-wrapper">
          {typeof svgLibrary[icon_name] === 'function' &&
            svgLibrary[icon_name](
              '',
              'block-category-box-svg',
              'block-category-box-svg-outer',
            )}
        </div>
        <div className="block-category-box-name-wrapper">
          <span className="block-category-box-name">{block_category_name}</span>
        </div>
      </div>
    </button>
  );
};
export default TravelMethods;
