import { Typography, Box, Button } from "@mui/material"
import { useRef, useState } from 'react';

const JournalEntryPreview = ({ journalEntry }) => {

  return (
    <ExpandableText text={journalEntry} />
  );
}


const ExpandableText = ({ text, previewLength = 65 }) => {
  const [expanded, setExpanded] = useState(false);
  const textRef = useRef(null);

  const handleToggle = () => {
    if (expanded) {
      // If collapsing, scroll the container back to the top
      if (textRef.current) {
        textRef.current.scrollTop = 0; // Set scroll position to the top
      }
    }
    setExpanded(!expanded);
  };


  if (text.length <= previewLength) { // just show text if short enough
    return <Typography variant="body1" textAlign={"left"} sx={{ wordWrap: "break-word" }}>
      {text}
    </Typography>
  }

  return (
    <Box display="flex" flexDirection={"column"} justifyContent={"left"} gap={1}>
      <Typography variant="body1" textAlign={"left"} ref={textRef}
        maxHeight={300} sx={{
          overflowY: expanded ? 'auto' : "hidden", display: '-webkit-box',
          WebkitLineClamp: expanded ? 'none' : 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          wordWrap: "break-word"
        }}
      >
        {text}
      </Typography>
      <Box display="flex" justifyContent={"right"}>
        <Button onClick={handleToggle} >
          {expanded ? 'Read Less' : 'Read More'}
        </Button>
      </Box>
    </Box>
  );
};

export default JournalEntryPreview
