import { merge } from 'lodash';
export const reformatItinerayItems = (itineraryItemList) => {
  if (itineraryItemList) {
    let cloned = merge([], itineraryItemList);
    let indexes = {};
    for (let i = 0; i < itineraryItemList.length; i++) {
      let ele = itineraryItemList[i];
      indexes[ele.itinerary_item_rid] = i;
      if (ele.item_level !== 1) {
        if (cloned[indexes[ele.parent_itinerary_item_rid]]?.children) {
          cloned[indexes[ele.parent_itinerary_item_rid]].children.push(ele);
        } else if (cloned[indexes[ele.parent_itinerary_item_rid]]) {
          cloned[indexes[ele.parent_itinerary_item_rid]]['children'] = [ele];
          cloned[indexes[ele.parent_itinerary_item_rid]]['hasChildren'] = true;
        }
      }
    }

    return cloned.filter((ele) => ele.item_level === 1);
  }
};
