export function streetBike (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      className={outerLayer}
    >
      <path
        className={className}
        d="M80.8,46.8c-4.3,0-8.2,1.9-10.8,5h0l-1.9-1.4v0l1.8-1.1c2.6-1.6,4.8-3.9,6-6.7c0,0,0-0.1,0-0.1c0.7-1.5,1.9-2.6,3.4-3.1  l13.1-4.4c0.6-0.2,1.1-0.6,1.4-1.2c0,0,0,0,0,0c0.9-2.2-6.5-4.8-13.2-4.8l0,0c-0.4,0-0.7,1.8-4.1,3.2c-1.1,0.5-2.1,1.2-2.8,2.2  c-0.1,0.2-0.3,0.3-0.4,0.5l0,0c-0.2-0.2-0.5-0.3-1,0c-1.9,1.2-4,1.9-6,2.3l0,0L57,36.8l0,0c-2.2-5-6.1-5.4-7-5.6  c-2.6-0.4-6.4,0.5-9.6,1.5c-0.2-0.2-0.5-0.3-0.8-0.2l-1,0.3c-0.3,0.1-0.5,0.4-0.6,0.8c-2,0.8-3.5,1.4-3.8,1.6  c-0.4,0.3-0.6,0.6-0.7,0.8l-0.7,0c0,0,0.6-2,0.3-2.6c-0.4-1.2-4.3-1.6-4.3-1.6l0,0c0,0,2.8-2.5,3.7-3.3c0.9-0.9-5.6-3.2-5.6-3.2l0,0  c0,0-0.7,0.5-1.8,1.2h0l-1.1-1.6h0c-2,1.2-2.1,3.5-2,4v0c-1.7,1.3-3.4,2.8-4.7,4.2c-3.2,3.6-7.5,8.6-6.5,9.9  c1.2,1.6,4.9,0.4,10.6,4.4c-0.9-0.4-1.9-0.7-3.1-0.8c-2.4-0.2-5.9,1.6-5.9,1.6l1.2,0.9C8.6,50.9,5,55.7,5,61.5  c0,7.5,6.2,13.6,13.8,13.4c7-0.2,12.7-6,12.9-12.9l0.3,0c0.1,2.1,0.2,4.6,0.3,5.8l0,0.6c0,0,0,0.1,0,0.1h32.9c0.5,0,0.8-0.6,0.5-1  l0,0c-1.1-1.5-1.7-3.3-1.9-5.1l0-0.3l0,0c1.1,0.3,2,0.7,2.9,1v0c0.9,7.3,7.5,12.9,15.2,12.3C89,74.6,94.7,68.7,95,61.6  C95.3,53.5,88.8,46.8,80.8,46.8z M18.5,51.8c0.6,0,1.3,0.1,1.9,0.2c1,0.2,1.2,1.6,0.3,2.1l-3.4,2l0,0c-0.6,0.1-1.1,0.4-1.7,0.7h0  l-3-3l0,0C14.2,52.6,16.3,51.8,18.5,51.8z M8.8,61.5c0-2.2,0.7-4.2,2-5.8h0l3,3v0c-0.5,0.8-0.8,1.8-0.8,2.9c0,1.1,0.3,2,0.8,2.9v0  l-3,3h0C9.6,65.7,8.8,63.7,8.8,61.5z M18.5,71.1c-2.2,0-4.2-0.7-5.8-2h0l3-3h0c0.8,0.5,1.8,0.8,2.9,0.8s2-0.3,2.9-0.8h0l3,3l0,0  C22.7,70.4,20.6,71.1,18.5,71.1z M26.1,67.3l-3-3v0c0.3-0.4,0.5-0.9,0.6-1.4h0l4.3-0.6l0,0C27.9,64.2,27.2,65.9,26.1,67.3L26.1,67.3  z M80.9,51.3c0.8,0,1.6,0.1,2.3,0.3l0,0l-1.6,3.9v0l-6.9-2v0C76.5,52.1,78.6,51.3,80.9,51.3z M76.2,69.3c-2-1.1-3.6-3-4.3-5.2l0,0  l5.8,1.5v0L76.2,69.3L76.2,69.3z M80.9,70.6c-0.8,0-1.6-0.1-2.3-0.3h0l1.6-3.9l0,0c1,0.1,2,0,2.9-0.4c1-0.4,1.7-1.1,2.3-1.9h0  l3.9,1.6h0C87.6,68.6,84.5,70.6,80.9,70.6z M90.2,63.3l-3.9-1.6l0,0c0.1-1,0-2-0.4-2.9c-0.4-1-1.1-1.7-1.9-2.3v0l1.6-3.9l0,0  c2.9,1.7,4.9,4.8,4.9,8.4C90.5,61.7,90.4,62.5,90.2,63.3L90.2,63.3z"
      ></path>
    </svg>
  );
}