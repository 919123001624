import React, { useState, useCallback, useEffect } from 'react';
import {
  sendTimes,
  openAdventureModal,
  openItineraryModal,
  openItineraryItemModal,
  closeModal,
} from 'actions/modal_actions';
import { Title, Button } from '../../modal_modules';
import { connect } from 'react-redux';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
const mapDispatchToProps = (dispatch) => {
  return {
    sendTimes: (data) => dispatch(sendTimes(data)),
    openAdventureModal: () => dispatch(openAdventureModal()),
    openItineraryModal: () => dispatch(openItineraryModal()),
    openItineraryItemModal: (openType, actionPayload) =>
      dispatch(openItineraryItemModal(openType, actionPayload)),
    closeModal: () => dispatch(closeModal()),
  };
};

const mapStateToProps = ({ ui, dataCache }) => {
  let origin = ui.modal.past_reducer ? ui.modal.past_reducer : null;
  let originalItineraryItem = dataCache.modal.originalItineraryItem
    ? dataCache.modal.originalItineraryItem
    : null;
  let parentItineraryItemData =
    dataCache?.modal?.itineraryItemData?.parentItineraryItemData;
  return {
    origin,
    originalItineraryItem,
    parentItineraryItemData,
  };
};
const DatePickerModal = ({
  sendDates,
  openAdventureModal,
  openItineraryModal,
  openItineraryItemModal,
  origin,
  closeModal,
  sendTimes,
  originalItineraryItem,
  parentItineraryItemData,
}) => {
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleSelect = (ranges) => {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };
  const handleBack = () => {
    if (origin === 'ADVENTURE') openAdventureModal();
    if (origin === 'ITINERARY') openItineraryModal();
    if (origin === 'ITINERARY_ITEM')
      openItineraryItemModal({ isTransitioning: true }, null);
  };

  const [value, onChange] = useState('10:00');

  const [valueEnding, onChangeEnding] = useState('10:00');
  // const [value, onChange] = useState('10:00');
  const [inputValues, setInputValues] = useState({
    beginningTime: originalItineraryItem
      ? originalItineraryItem.item_start_time
      : '10:00',
    endingTime: originalItineraryItem
      ? originalItineraryItem.item_end_time
      : '11:00',
  });
  let startTimeModified;
  let endTimeModified;
  // if (originalItineraryItem) {
  //   startTimeModified = originalItineraryItem.item_start_date.split("T");
  //   startTimeModified.splice(1, 1, originalItineraryItem.item_start_time);
  //   startTimeModified = startTimeModified.join("T");
  //   endTimeModified = originalItineraryItem.item_end_date.split("T");
  //   endTimeModified.splice(1, 1, originalItineraryItem.item_end_time);
  //   endTimeModified = endTimeModified.join("T");
  // }
  // if (parentItineraryItemData) {
  //   startTimeModified = parentItineraryItemData.item_start_date.split("T");
  //   startTimeModified.splice(1, 1, parentItineraryItemData.item_start_time);
  //   startTimeModified = startTimeModified.join("T");
  //   endTimeModified = parentItineraryItemData.item_end_date.split("T");
  //   endTimeModified.splice(1, 1, parentItineraryItemData.item_end_time);
  //   endTimeModified = endTimeModified.join("T");
  // }
  const [selectedDate, handleDateChange] = useState(moment(startTimeModified));
  const [selectedDateEnding, handleDateChangeEnding] = useState(
    moment(endTimeModified),
  );

  const handleOnChange = useCallback((event) => {
    //  debugger
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  const handleSubmit = () => {
    // let dateObject = {
    //   startTime: selectedDate.format("HH:mm"),
    //   endTime: selectedDateEnding.format("HH:mm"),
    //   displayStartTime: selectedDate.format("hh:mm a"),
    //   displayEndTime: selectedDateEnding.format("hh:mm a"),
    // };
    let dateObject = {
      startTime: inputValues.beginningTime,
      endTime: inputValues.endingTime,
      displayStartTime: inputValues.beginningTime,
      displayEndTime: inputValues.endingTime,
    };
    sendTimes(dateObject);
    if (origin === 'ADVENTURE') openAdventureModal();
    if (origin === 'ITINERARY') openItineraryModal();
    if (origin === 'ITINERARY_ITEM')
      openItineraryItemModal({ isTransitioning: true }, null);
  };
  const returnDisabledCondition = () => {
    return moment(inputValues.beginningTime) >= moment(inputValues.endingTime)
      ? true
      : false;
  };
  return (
    <div
      // className="mod-type1-wrapper disable-max-width time-picker-modal"
      className="mod-type1-wrapper  time-picker-modal"
      id="cre-ad"
    >
      <div className="modal-float-padder">
        <Title closeModal={handleBack} title="PICK TIMES" />
        <div className="mod-cnt-def" style={{ height: '300px' }}>
          <form onSubmit={handleSubmit}>
            {/* <TimePickerThemeProvider useTheme>
              <div className="pick-time-section-headers">
                <span>Start time</span>{" "}
              </div>
              <TimePicker
                use12Hours
                value={selectedDate}
                onChange={handleDateChange}
                className="time-picker-modal-time-picker"
                popupClassName="time-picker-modal-popup"
                format="HH:mm a"
                hideDisabledOptions
                disabledSeconds={(selectedHour, selectedMinute) => {}}
                inputReadOnly
              />{" "}
              <div className="pick-time-section-headers">
                <span>End time</span>{" "}
              </div>{" "}
              <TimePicker
                use12Hours
                value={selectedDateEnding}
                onChange={handleDateChangeEnding}
                className="time-picker-modal-time-picker"
                popupClassName="time-picker-modal-popup"
                format="HH:mm a"
                hideDisabledOptions
                disabledSeconds={(selectedHour, selectedMinute) => {}}
                inputReadOnly
              />
            </TimePickerThemeProvider> */}
            {/* <Stack
            component="form"
            noValidate
            spacing={3}
            onSubmit={handleSubmit}
          > */}
            <TextField
              id="time"
              label="Start Time"
              name="beginningTime"
              type="time"
              defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              sx={{ width: 150 }}
              onChange={handleOnChange}
              value={inputValues.beginningTime}
              className="modal-time-picker-parent"
            />
            <TextField
              id="time"
              label="End Time"
              type="time"
              name="endingTime"
              defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              sx={{ width: 150 }}
              value={inputValues.endingTime}
              onChange={handleOnChange}
              className="modal-time-picker-parent"
            />
            <Button
              spacingBottom="5px"
              spacingTop="20px"
              text="Save"
              disabledCondition={returnDisabledCondition()}
            />
            {/* </Stack> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerModal);
