export const svgBoxMenu = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      className={outerLayer}
      width="100px"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      x="0px"
      y="0px"
    >
      <g data-name="Layer 2">
        <path
          className={className}
          d="M12.13,1H4A3,3,0,0,0,1,4v8.13a3,3,0,0,0,3,3H14.13a1,1,0,0,0,1-1V4A3,3,0,0,0,12.13,1Zm1,12.13H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3h8.13a1,1,0,0,1,1,1Z"
        ></path>
        <path
          className={className}
          d="M28.13,1h-8.4a2.87,2.87,0,0,0-2.86,2.87V14.13a1,1,0,0,0,1,1H28.13A2.87,2.87,0,0,0,31,12.27V3.87A2.88,2.88,0,0,0,28.13,1ZM29,12.27a.86.86,0,0,1-.87.86H18.87V3.87A.87.87,0,0,1,19.73,3h8.4a.87.87,0,0,1,.87.87Z"
        ></path>
        <path
          className={className}
          d="M14.13,16.87H4a3,3,0,0,0-3,3V28a3,3,0,0,0,3,3h8.13a3,3,0,0,0,3-3V17.87A1,1,0,0,0,14.13,16.87ZM13.13,28a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V19.87a1,1,0,0,1,1-1h9.13Z"
        ></path>
        <path
          className={className}
          d="M28.13,16.87H17.87a1,1,0,0,0-1,1V28.13A2.87,2.87,0,0,0,19.73,31h8.4A2.88,2.88,0,0,0,31,28.13v-8.4A2.87,2.87,0,0,0,28.13,16.87ZM29,28.13a.87.87,0,0,1-.87.87h-8.4a.87.87,0,0,1-.86-.87V18.87h9.26a.86.86,0,0,1,.87.86Z"
        ></path>
      </g>
    </svg>
  );
};
