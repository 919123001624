import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Headers } from './shared_modules';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchStates: () => dispatch(fetchStates()),
  fetchCountries: () => dispatch(fetchCountries()),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  return { userInfo, currentExplorerDetails, countries, statesProvences };
};

function sadFace(size = '200px') {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 497 497"
      height={size}
      viewBox="0 0 497 497"
      width={size}
    >
      <path
        d="m251.2.02v496.96c136-1.44 245.8-112.14 245.8-248.48s-109.8-247.04-245.8-248.48z"
        fill="#ffbb54"
      />
      <path
        d="m251.2.02c-.9-.01-1.8-.02-2.7-.02-137.24 0-248.5 111.26-248.5 248.5s111.26 248.5 248.5 248.5c.9 0 1.8-.01 2.7-.02 113.9-1.72 205.8-112.31 205.8-248.48s-91.9-246.76-205.8-248.48z"
        fill="#ffd45a"
      />
      <path
        d="m283.496 252.465c-1.518 0-3.048-.459-4.374-1.413-8.957-6.443-19.546-9.849-30.622-9.849-10.522 0-20.671 3.099-29.349 8.962-3.433 2.318-8.095 1.416-10.413-2.017-2.318-3.432-1.416-8.094 2.017-10.413 11.167-7.544 24.219-11.532 37.745-11.532 14.239 0 27.857 4.382 39.382 12.673 3.362 2.418 4.127 7.105 1.708 10.468-1.465 2.037-3.763 3.121-6.094 3.121z"
        fill="#fa71a3"
      />
      <path
        d="m256 282.464h-15c-4.143 0-7.5-3.357-7.5-7.5s3.357-7.5 7.5-7.5h15c4.143 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z"
        fill="#ffbb54"
      />
      <path
        d="m332 113.7c-8.25 0-15 6.75-15 15v45c0 8.25 6.75 15 15 15s15-6.75 15-15v-45c0-8.25-6.75-15-15-15z"
        fill="#474867"
      />
      <path
        d="m165 113.703c-8.25 0-15 6.75-15 15v45c0 8.25 6.75 15 15 15s15-6.75 15-15v-45c0-8.25-6.75-15-15-15z"
        fill="#474867"
      />
      <path
        d="m365.12 280.109-33.12-61.4v116.49c20.71 0 37.5-16.79 37.5-37.5 0-6.36-1.59-12.34-4.38-17.59z"
        fill="#3a9dd9"
      />
      <path
        d="m343.04 280.109c.93 5.25 1.46 11.23 1.46 17.59 0 20.71-5.6 37.5-12.5 37.5-20.71 0-37.5-16.79-37.5-37.5 0-6.36 1.59-12.34 4.38-17.59l33.12-61.4z"
        fill="#35b9ff"
      />
    </svg>
  );
}
const ComingSoonPage = ({ userInfo, currentExplorerDetails, ...props }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    fetchCountries();
    fetchStates();
    scrollTop();

    if (!currentExplorerDetails)
      fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  useEffect(() => {
    document
      .getElementById('hub-page-container-account-responsive')
      .scrollTo(0, 0, 'smooth');
  }, []);
  const planDetails = [
    'Create and store trips in your own Adventure Hub',
    'Build a library to save and organize your ideas',
    'Tag & Save trail maps, lodging, and other trips ideas from the web',
    'Invite friends to collaborate on trips',
    'Build detailed trip itineraries',
    'Map your route',
    'Create custom packing lists',
    "Keep track of where you've been",
    'Record and share your trips',
  ];

  const create_list = (str) => {
    return (
      <div className="CancelSubscribePage__body2__textbox-text">
        <div className="CancelSubscribePage__list_point">
          <div className="CancelSubscribePage__list_point-mid">
            <div className="CancelSubscribePage__list_point-inner" />
          </div>
        </div>
        {str}
      </div>
    );
  };
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="CANCEL SUBSCRIPTION" />
          <div className="CancelSubscribePage">
            <div className="CancelSubscribePage__body2">
              <div className="CancelSubscribePage__body2__box">
                <div className="CancelSubscribePage__header2">
                  Would you still like to cancel?
                </div>
                <div className="CancelSubscribePage__btn-cont">
                  <Link
                    className="CancelSubscribePage__btn-1"
                    to="/hub/account/manage-subscription"
                  >
                    <div className="CancelSubscribePage__btn-1__inner">
                      <span>Keep my membership active</span>
                    </div>
                  </Link>
                  <Link
                    className="CancelSubscribePage__btn-2"
                    to="/hub/account/cancel-subscription-reason"
                  >
                    <div className="CancelSubscribePage__btn-2__inner">
                      <span>Cancel my membership and close my account</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonPage);
