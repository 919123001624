import {
  RECEIVE_UNSPLASH_IMAGES,
  RECEIVE_FLUSH_MODAL,
  RECEIVE_PEXEL_IMAGES,
  RECEIVE_FLUSH_FETCHED_IMAGES,
  CLOSE_MODAL,
} from '../../constants';
import merge from 'lodash/merge';

const SessionErrorsReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_UNSPLASH_IMAGES:
      newState['unsplashImages'] = action.data.unsplashImages;
      return newState;
    case RECEIVE_PEXEL_IMAGES:
      newState['pexelImages'] = action.data.photos;
      return newState;

    case RECEIVE_FLUSH_FETCHED_IMAGES:
      newState['pexelImages'] = null;
      return newState;
    case RECEIVE_FLUSH_MODAL:
      newState = {};
      return newState;
    case CLOSE_MODAL:
      newState = {};
      return newState;
    default:
      return state;
  }
};

export default SessionErrorsReducer;
