import { useQuery } from '@tanstack/react-query';
import fetchRecommendedPartners from '../api/fetchRecommendedPartners';

const useAdventureDonations = (adventureRid) => {
  return useQuery({
    queryKey: ['adventure', adventureRid, 'partners', 'recommended'],
    queryFn: () =>
      fetchRecommendedPartners(adventureRid).then((res) => res.data),
  });
};

export default useAdventureDonations;
