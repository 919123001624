export function loungeChair (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M40.964,24.413l-0.353-1.005l-1.887,0.662l0.353,1.005L40.964,24.413z M56.757,35.695l0.167,0.272l10.81-3.791  c-5.742-5.811-14.016-8.71-22.323-7.525C49.225,26.057,53.223,29.905,56.757,35.695z M33.79,43.749  c-0.858-6.74-0.099-12.258,1.992-15.728c-7.244,4.261-11.905,11.7-12.763,19.836l10.811-3.791L33.79,43.749z M50.146,61.176  l-1.253-3.572H23.25c-1.381,0-2.5,1.119-2.5,2.5c0,0.385,0.095,0.746,0.25,1.072H50.146z M54.192,57.604l1.253,3.572h3.047  l13.904-14.061c-0.123-0.312-0.31-0.604-0.563-0.854c-0.981-0.973-2.565-0.963-3.535,0.02L57.102,57.604H54.192z M20.75,62.676  v5.152h31.729l-1.807-5.152H20.75z M24.06,76.592h3.076l2.376-7.264h-4.641L24.06,76.592z M70.486,69.328l2.376,7.264h3.078  l-0.812-7.264H70.486z M78.177,62.676l-8.073-5.865l4.372-4.42l-2.622-2.594L59.117,62.676h-3.146l1.808,5.152H79.25v-5.152H78.177z   M64.304,62.676l4.378-4.428l6.094,4.428H64.304z M46.319,39.686l9.156-3.211c-4.091-6.7-9.045-10.929-13.195-10.929  c-0.645,0-1.27,0.102-1.869,0.313c-4.461,1.563-6.278,8.703-5.133,17.699l9.153-3.21l12.711,36.244h2.119L46.319,39.686z"
        ></path>
      </g>
    </svg>
  );
}