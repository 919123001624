import { Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { persistItineraryData } from 'actions/cache_actions';
import {
  deleteItineraryComment,
  deleteItineraryVotes,
  fetchItineraryDetails,
  postItinerary,
  updateItineraryStartDate,
  upsertItineraryComment,
  upsertItineraryVotes,
} from 'actions/itinerary_actions';
import dayjs from 'dayjs';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import { merge } from 'lodash';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { SectionSubTitle } from '../../../common/section-title';
import HubModal from '../../modal';

const mapStateToProps = ({ session, ui, dataCache, entities }) => {
  let account_rid = session.user.account_rid ? session.user.account_rid : null;
  let startDate = ui.modal.selectedDates
    ? ui.modal.selectedDates.startDate
    : null;
  let endDate = ui.modal.selectedDates ? ui.modal.selectedDates.endDate : null;
  let actionPayload = ui.modal.actionPayload ? ui.modal.actionPayload : null;
  let openType = ui.modal.itineraryOpenType ? ui.modal.itineraryOpenType : null;
  let currentItinerary = dataCache.modal.currentItinerary
    ? dataCache.modal.currentItinerary
    : null;
  let itineraryComments = entities.itinerary.itineraryComments
    ? entities.itinerary.itineraryComments
    : null;
  let itineraryVotes = entities.itinerary.itineraryVotes
    ? entities.itinerary.itineraryVotes
    : null;
  let userInfo = session.user ? session.user.userInfo[0] : null;

  let persistedItineraryModalData = dataCache.modal.savedItineraryData
    ? dataCache.modal.savedItineraryData
    : null;

  return {
    account_rid,
    startDate,
    endDate,
    actionPayload,
    openType,
    currentItinerary,
    itineraryComments,
    itineraryVotes,
    userInfo,
    persistedItineraryModalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postItinerary: (adventure_rid, data) =>
      dispatch(postItinerary(adventure_rid, data)),
    upsertItineraryComment: (data) => dispatch(upsertItineraryComment(data)),
    deleteItineraryComment: (itineraryRid, itineraryCommentRid) =>
      dispatch(deleteItineraryComment(itineraryRid, itineraryCommentRid)),
    upsertItineraryVotes: (data) => dispatch(upsertItineraryVotes(data)),
    deleteItineraryVotes: (itineraryVoteRid, userInfo, itineraryRid) =>
      dispatch(deleteItineraryVotes(itineraryVoteRid, userInfo, itineraryRid)),
    persistItineraryData: (openType, actionPayload) =>
      dispatch(persistItineraryData(openType, actionPayload)),
    updateItineraryStartDate: (data) =>
      dispatch(updateItineraryStartDate(data)),
    fetchItineraryDetail: (itinerary_rid, account_rid, explorer_rid) =>
      dispatch(fetchItineraryDetails(itinerary_rid, account_rid, explorer_rid)),
  };
};
const CreateItinerary = ({
  startDate,
  endDate,
  postItinerary,
  closeModal,
  actionPayload,
  openType,
  currentItinerary,
  userInfo,
  itineraryComments,
  itineraryVotes,
  upsertItineraryComment,
  deleteItineraryComment,
  upsertItineraryVotes,
  deleteItineraryVotes,
  persistedItineraryModalData,
  persistItineraryData,
  updateItineraryStartDate,
  fetchItineraryDetail,
}) => {
  const { invalidateQueries } = useInvalidateAdventureData();
  const [inputValues, setInputValues] = useState(
    openType.isEditAndView
      ? {
          ...currentItinerary,
          item_start_date: dayjs(currentItinerary.start_date),
          item_end_date: dayjs(currentItinerary.end_date),
        }
      : {
          itinerary_title: null,
          itinerary_description: null,
          item_start_date: null,
          item_end_date: null,
          selected_flag: true,
          adventure_rid: openType.isNew
            ? actionPayload.adventureRid
            : openType.isSaving
            ? persistItineraryData.inputValues
            : null,
        },
  );

  const [comments, setComments] = useState({
    itinerary_comment_rid: null,
    itinerary_rid: currentItinerary ? currentItinerary.itinerary_rid : null,
    explorer_rid: userInfo ? userInfo.explorer_rid : null,
    explorer_comment: '',
  });

  const handleOnChangeComments = useCallback((event) => {
    const { name, value } = event.target;
    setComments({ ...comments, [name]: value });
  });

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !!value });
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const onDateChange = (dates) => {
    setInputValues({
      ...inputValues,
      item_start_date: dates[0],
      item_end_date: dates[1],
    });
  };

  const handleItineraryCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let passOver = merge({}, comments);
    passOver['adventure_rid'] = currentItinerary.adventure_rid;
    upsertItineraryComment(passOver);

    setComments({
      ...comments,
      itinerary_comment_rid: null,
      explorer_comment: '',
    });
  };

  const handleSubmit = async () => {
    const { itinerary_title, item_start_date, item_end_date } = inputValues;
    if (
      !itinerary_title ||
      !item_start_date ||
      (!openType.isEditAndView && !item_end_date)
    )
      return;
    if (openType.isEditAndView) {
      updateItineraryStartDate({
        adventure_rid: inputValues.adventure_rid,
        explorer_rid: userInfo.explorer_rid,
        itinerary_rid: inputValues.itinerary_rid,
        itinerary_start_date: inputValues.item_start_date,
      });
    }
    if (openType.isEditAndView) {
      await postItinerary(
        parseInt(currentItinerary.adventure_rid),
        inputValues,
      );
    } else if (openType.isNew || openType.isTransitioning) {
      let passOver = merge({}, inputValues);
      passOver['explorer_rid'] = userInfo.explorer_rid;
      await postItinerary(parseInt(inputValues.adventure_rid), inputValues);
    }
    if (inputValues.itinerary_rid) {
      fetchItineraryDetail(
        inputValues.itinerary_rid,
        userInfo.account_rid,
        userInfo.explorer_rid,
      );
    }
    invalidateQueries();
    closeModal();
  };

  return (
    <HubModal
      show={true}
      title="ITINERARY"
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box className="create-itinerary-wrapper">
        <Stack spacing={2}>
          {/* <SectionSubTitle>SUMMARY</SectionSubTitle>
          <TextBox
            className="text-field required"
            label="Name*"
            name="itinerary_title"
            onChange={handleOnChange}
            value={inputValues.itinerary_title}
            variant="outlined"
            fullWidth
            size="small"
          /> */}
          {/* <TextBox
            label="Description"
            name="itinerary_description"
            onChange={handleOnChange}
            value={inputValues.itinerary_description}
            variant="outlined"
            multiline
            maxRows={4}
            size="small"
          />
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Switch
              onChange={switchHandler}
              name="selected_flag"
              value={!inputValues.selected_flag}
            />
            <DescriptionText>Draft only</DescriptionText>
          </Box> */}
          <SectionSubTitle>START DATE</SectionSubTitle>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {currentItinerary ? (
              <MobileDatePicker
                label="Start*"
                value={inputValues.item_start_date}
                onChange={(v) => onDateChange([v, null])}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#273A07',
                        },
                        '&:hover fieldset': {
                          borderColor: '#273A07',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#273A07',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#da4431',
                      },
                      '& input': {
                        color: '#827700',
                      },
                    },
                  },
                }}
              />
            ) : (
              <MobileDateRangePicker
                value={[inputValues.item_start_date, inputValues.item_end_date]}
                onChange={onDateChange}
                localeText={{ start: 'Start*', end: 'End*' }}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#273A07',
                        },
                        '&:hover fieldset': {
                          borderColor: '#273A07',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#273A07',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#da4431',
                      },
                      '& input': {
                        color: '#827700',
                      },
                    },
                  },
                }}
              />
            )}
          </LocalizationProvider>
          {/* {currentItinerary && (
            <>
              <SectionSubTitle>COMMENTS</SectionSubTitle>
              <Comments
                itineraryComments={itineraryComments}
                itineraryVotes={itineraryVotes}
                comments={comments}
                inputValues={inputValues}
                handleItineraryCommentSubmit={handleItineraryCommentSubmit}
                handleOnChangeComments={handleOnChangeComments}
                deleteItineraryComment={deleteItineraryComment}
                upsertItineraryVotes={upsertItineraryVotes}
                deleteItineraryVotes={deleteItineraryVotes}
              />
            </>
          )} */}
          {/* {currentItinerary ? (
            <div className="itinerary-details-wrapper">
              <span className="itinerary-details-span">
                {currentItinerary ? (
                  <span className="banner-float-text-small">
                    {moment(currentItinerary.start_date)
                      .utcOffset(2)
                      .format('ddd, MMM DD, YYYY')}{' '}
                    —{' '}
                    {moment(currentItinerary.end_date)
                      .utcOffset(2)
                      .format('ddd, MMM DD, YYYY')}
                  </span>
                ) : (
                  <span />
                )}
              </span>
              <span className="itinerary-details-span">
                {currentItinerary || inputValues.itinerary_rid ? (
                  <span>
                    {' '}
                    {currentItinerary.duration || inputValues.duration}
                  </span>
                ) : (
                  <span />
                )}
              </span>
            </div>
          ) : (
            <div className="itinerary-details-wrapper"></div>
          )} */}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItinerary);
