import React, { useState } from 'react';
import { svgSearch } from 'components/svg_module';
import { openDatePickerModal } from '../../../actions/modal_actions';
import { connect } from 'react-redux';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    openDatePickerModal: (data) => dispatch(openDatePickerModal(data)),
  };
};

const TagsPicker = ({
  spacingTop,
  spacingBottom,
  openDatePickerModal,
  persistParentData,
  tags,
  origin, // pass the id of the html tag of the most outer div tag of the
  // component that is rendering this date picker
}) => {
  const [displayDate, toggleDisplayDate] = useState(false);
  const handleDisplay = () => {
    toggleDisplayDate(!displayDate);
  };

  return (
    <div
      className="mod-tag-pkr-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
    >
      {!tags ? (
        <div
          className="mod-tag-pkr-untrig"
          // onClick={() => toggleDisplayDate(!displayDate)}
          onClick={() => {
            persistParentData();
            openDatePickerModal(origin);
          }}
        >
          {svgSearch(
            'white',
            'mod-svg-search-tag',
            'mod-svg-search-tag-wrapper',
          )}
          <span>Choose search tags to help others find it</span>
        </div>
      ) : (
        <div
          className="mod-tag-pkr-set"
          onClick={() => {
            persistParentData();
            openDatePickerModal(origin);
          }}
        >
          {tags.map((ele, idx) => {
            return <div className="mod-tag-chip-wrapper"> </div>;
          })}
        </div>
      )}
    </div>
  );
  // : <div className='modal-layer-two'>
  //
  //     </div>
};

export default connect(null, mapDispatchToProps)(TagsPicker);
