import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Headers } from './shared_modules';
import { DataGrid } from '@mui/x-data-grid';

import {
  flushAccountPartnerDonations,
  fetchAccountPartnerDonations,
} from '../../../actions/user_actions';
import { svgCopyToTripBoard, svgPrint } from 'components/svg_module';
import { DropDownSelect } from 'components/global_modal/modal_modules';
// import {
//   fetchUserAdventures,
//   flushAdventures,
// } from '../../../actions/adventure_actions';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccountPartnerDonations: (account_rid) =>
      dispatch(fetchAccountPartnerDonations(account_rid)),
    // fetchUserAdventures: (data) => dispatch(fetchUserAdventures(data)),
    // flushAdventures: () => dispatch(flushAdventures()),
    flushAccountPartnerDonations: () =>
      dispatch(flushAccountPartnerDonations()),
  };
};

const mapStateToProps = ({ entities, session, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let accountPartnerDonations = entities?.users?.accountPartnerDonations;
  let userAdventures = entities?.adventure?.user_adventures;
  return {
    userInfo,
    accountPartnerDonations,
    userAdventures,
  };
};
const DONATION_COLUMNS = [
  { field: 'donation_amount', headerName: 'Amount', width: 70 },
  { field: 'donation_code', headerName: 'ID', width: 70 },
  { field: 'adventure_name', headerName: 'Adventure Name', width: 300 },
  { field: 'partner_name', headerName: 'Organization Name', width: 200 },
  { field: 'partner_tax_id', headerName: 'Organization Tax ID', width: 200 },
  // {
  //   field: 'annual_donation_running_total',
  //   headerName: 'Donation Running Total',
  // },
  // { field: 'donation_date', headerName: 'Date', width: 70 },
];

const DonationSummary = ({ accountPartnerDonations }) => {
  let donationSummaryWidth;
  let donationSummary = document.getElementById('my-impact-summary');
  let columns;
  function money_round(num) {
    return (Math.ceil(num * 100) / 100).toFixed(2);
  }
  function generateColumnWidth(clientWidth) {
    let columns = [
      { field: 'donation_date', headerName: 'Date', width: clientWidth * 0.13 },
      {
        field: 'donation_amount',
        headerName: 'Amount',
        width: clientWidth * 0.1,
      },
      {
        field: 'donation_code',
        headerName: 'Donation ID',
        width: clientWidth * 0.15,
      },
      {
        field: 'adventure_name',
        headerName: 'Adventure Name',
        width: clientWidth * 0.25,
      },
      {
        field: 'partner_name',
        headerName: 'Partner',
        width: clientWidth * 0.25,
      },
      {
        field: 'partner_tax_id',
        headerName: 'Tax ID',
        width: clientWidth * 0.1,
      },

      // {
      //   field: 'annual_donation_running_total',
      //   headerName: 'Donation Running Total',
      // },
      // {
      //   field: 'transportation_type_name',
      //   headerName: 'Transportation',
      //   width: clientWidth * 0.2,
      // },
    ];
    return columns;
  }
  if (donationSummary) {
    donationSummaryWidth = donationSummary.clientWidth;
    columns = generateColumnWidth(donationSummaryWidth);
  }
  return (
    <DataGrid
      rows={accountPartnerDonations.map((ele, idx) => {
        ele.id = idx;
        ele.donation_amount = `$${money_round(ele.donation_amount)}`;
        ele.annual_donation_running_total = `$${money_round(
          ele.annual_donation_running_total,
        )}`;
        ele.donation_date = moment(ele.donation_date)
          .utcOffset(2)
          .format('ddd, MMM DD, YYYY ');
        return ele;
      })}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[5]}
    />
  );
};
const MyImpact = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const {
    userInfo,
    history,
    fetchUserAdventures,
    fetchAccountPartnerDonations,
    flushAccountPartnerDonations,
    // accountPartnerDonations,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  const [accountPartnerDonations, setAccountPartnerDonations] = useState(null);
  useEffect(() => {
    scrollTop();

    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  useEffect(() => {
    fetchAccountPartnerDonations(userInfo.account_rid).then((res) => {
      setAccountPartnerDonations(res.data);
    });
    return () => {
      flushAccountPartnerDonations();
    };
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  const saveHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    var doc = new jsPDF();
    var elementHandler = {
      '#ignorePDF': function (element, renderer) {
        return true;
      },
    };
    doc.html(
      renderToString(
        <accountPartnerDonations
          accountPartnerDonations={accountPartnerDonations}
        />,
      ),
      {
        callback: function (doc) {
          doc.output('dataurlnewwindow');
        },
        x: 10,
        y: 10,
      },
    );
  });
  const printHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container  "
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-members  account-page-container`}
          id="account-page-container-responsive"
        >
          <Headers text="My impact" />
          {/* <div className="my-impact-call-to-action-wrapper">
            <span>Would you like to make a donation for your trip ?</span>
          </div> */}

          <>
            <div className="my-impact-summary-header">
              <span>Summary</span>
              <div className="my-impact-summary-header-buttons-wrapper">
                <button
                  className="my-impact-summary-buttons"
                  onClick={saveHandler}
                >
                  {svgCopyToTripBoard(
                    '',
                    'my-impact-svg',
                    'my-impact-svg-outer -download',
                  )}
                </button>
                <button
                  className="my-impact-summary-buttons"
                  onClick={printHandler}
                >
                  {svgPrint('', 'my-impact-svg', 'my-impact-svg-outer -print')}
                </button>
              </div>
            </div>
            <div className="my-impact-summary-page" id="my-impact-summary">
              {accountPartnerDonations &&
                accountPartnerDonations.length > 0 && (
                  <DonationSummary
                    accountPartnerDonations={accountPartnerDonations}
                  />
                )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyImpact),
);
