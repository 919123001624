import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {
  fetchRecGovCampgrounds,
  fetchRecGovCampsites,
} from '../../../../util/api_util/linked_app_api_util';
import { convertToGeolocationString } from '../../../../util/helpers/geolocation-helper';
import MediaSliderWithPreview from '../../../common/media-slider-with-preview/media-slider-with-preview';
import Spinner from '../../../common/spinner';
import HubButton from '../../../hub-button';
import {
  BookIcon,
  CalendarIcon,
  EmailIcon,
  HamburgerIcon,
  LocationIcon,
  PhoneIcon,
  SiteIcon,
} from '../../../svg_module';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    height: '100%',
    boxSizing: 'border-box',
    padding: '5px',
    overflow: 'auto',
  },
  rootWithNoBack: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    height: 'fit-content',
    boxSizing: 'border-box',
    padding: '5px',
    overflow: 'auto',
  },
  content: {
    height: 'calc(100% - 50px)',
    overflowY: 'auto',
  },
  crsRoot: {
    width: '100%',
    aspectRatio: '24 / 15',
    '& .crs-body': {
      height: '100%',
    },
    '& .crs-item': {
      height: '100%',
    },
    '& .crs-dot': {
      width: '4px',
      height: '4px',
    },
    '& .crs-arrow-left svg, & .crs-arrow-right svg': {
      width: '6px',
      height: '10px',
    },
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  blockName: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#273A07',
    marginTop: '5px',
  },
  blockDescription: {
    fontWeight: '400',
    fontSize: '12px',
    color: '#273A07',
    paddingLeft: '20px',
    width: '80%',
    '& *': {
      color: '#273A07',
    },
  },
  blockItemInfo: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#827700',
    width: '85%',
    overflowWrap: 'break-word',
  },
  iconRoot: {
    minWidth: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockTypeIcon: {
    width: '15px',
    height: '15px',
    color: '#273A07',
    fill: '#273A07',
    '&.book-icon': {
      width: '10px',
      height: '10px',
    },
  },
  hintText: {
    fontSize: '12px',
    color: '#827700',
  },
  campsites: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '200px',
    color: '#827700',
    fontSize: '12px',
    overflow: 'auto',
    paddingLeft: '40px',
    cursor: 'pointer',
    marginRight: '4px !important',
  },
  iconText: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  btnEdit: {
    fontSize: '10px',
    margin: 'inherit',
  },
  btnBack: {
    justifyContent: 'left !important',
    padding: '0 !important',
  },
  editBtnIcon: {
    width: '10px',
    height: '10px',
    color: 'white',
    fill: 'white',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  btn: {
    cursor: 'pointer',
  },
});

const CampGround = ({
  id,
  onClose,
  onViewCampsite,
  onGotoMap,
  addButton,
  noBack,
}) => {
  const classes = useStyles();
  const [campground, setCampground] = useState({});
  const [campsites, setCampsites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCampgroundsAndCampsites = async () => {
      try {
        setLoading(true);
        const res = await fetchRecGovCampgrounds({
          facility_id: id,
          page_number: 1,
          page_size: 12,
        });

        const campgrounds = res.data.campgrounds?.map((v) => {
          const { facility, campsite_count, recarea_name, recarea } = v;
          const {
            name,
            description,
            media,
            url,
            facility_id,
            reservation_url,
            lat,
            lng,
          } = facility;
          const sites = `${campsite_count} site${
            campsite_count !== 1 ? 's' : ''
          }`;

          return {
            facilityId: facility_id,
            title: name,
            description,
            images: media.map((item) => item['URL']),
            sites,
            url,
            location: { lat, lng },
            reservationURL: reservation_url,
            recareaName: recarea_name,
            recarea,
          };
        });

        if (campgrounds?.length > 0) {
          const camp = campgrounds[0];
          onGotoMap(camp.location);
          setCampground(camp);
          const res = await fetchRecGovCampsites({
            facility_id: id,
            page_number: 1,
            page_size: 1000,
          });
          setCampsites(res.data.campsites);
        }
        setLoading(false);
      } catch (_) {
        setLoading(false);
      }
    };

    fetchCampgroundsAndCampsites();
  }, [id]);

  const {
    title,
    description,
    images,
    sites,
    location,
    recareaName,
    recarea,
    url,
  } = campground;

  const isMediaExist = images?.length > 0;

  const hasLocation = (location) => {
    if (!location) return false;
    const { lat, lng } = location;
    if (!lat && !lng) return false;

    return true;
  };

  const onViewDetail = () => {
    window.open(url, '_blank');
  };

  return (
    <Box className={noBack ? classes.rootWithNoBack : classes.root}>
      {!noBack && (
        <div>
          <IconButton className={classes.btnBack} onClick={onClose}>
            <ArrowBackIcon
              className={classes.blockTypeIcon}
              fontSize="small"
              color="#273A07"
            />
          </IconButton>
        </div>
      )}
      <div className={classes.content}>
        {loading ? (
          <Spinner />
        ) : (
          <Stack spacing={1}>
            {isMediaExist && (
              <MediaSliderWithPreview
                images={images.slice(0, 5)}
                width={'100%'}
                className={'block-image-carousel'}
                uniqueKey={'campground'}
              />
              // <Carousel className={classes.crsRoot}>
              //   {images.slice(0, 5).map((image, index) => (
              //     <CarouselCard key={index}>
              //       {isVideoFile(image) && <video className={classes.img} src={image} autoPlay muted />}
              //       {isImageFile(image) && <img className={classes.img} src={image} loading="lazy" />}
              //     </CarouselCard>
              //   ))}
              // </Carousel>
            )}
            <div className={classes.blockName}>{title}</div>
            <div className={classes.iconText}>
              <div className={classes.iconRoot}>
                <HamburgerIcon outerLayer={classes.blockTypeIcon} />
              </div>
              <div className={classes.blockItemInfo}>{description}</div>
            </div>
            <div
              className={[classes.iconText, classes.btn].join(' ')}
              onClick={() => onGotoMap(location)}
            >
              <div className={classes.iconRoot}>
                <LocationIcon
                  outerLayer={classes.blockTypeIcon}
                  color="#273806"
                />
              </div>
              <div className={classes.blockItemInfo}>
                {hasLocation(location)
                  ? convertToGeolocationString(location?.lat, location?.lng)
                  : 'Not available'}
              </div>
            </div>
            {campsites && campsites?.length > 0 && (
              <>
                <div className={classes.iconText}>
                  <div className={classes.iconRoot}>
                    <SiteIcon outerLayer={classes.blockTypeIcon} />
                  </div>
                  <div className={classes.blockItemInfo}>{sites}</div>
                  <div className={classes.hintText}>
                    select one to see more detail
                  </div>
                </div>
                <ol className={classes.campsites}>
                  {campsites?.map((site, index) => {
                    return (
                      <li onClick={() => onViewCampsite(id, site.campsite_id)}>
                        <div className={classes.iconText}>
                          {`${site.campsite_name} - ${site.facility_loop}`}
                          {site.campsite_reservalbe && (
                            <BookIcon
                              outerLayer={[
                                classes.blockTypeIcon,
                                'book-icon',
                              ].join(' ')}
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </>
            )}
            <div className={classes.blockName}>{recareaName}</div>
            <div
              className={[classes.iconText, classes.btn].join(' ')}
              onClick={() =>
                onGotoMap({ lat: recarea?.lat, lng: recarea?.lng })
              }
            >
              <div className={classes.iconRoot}>
                <LocationIcon
                  outerLayer={classes.blockTypeIcon}
                  color="#273806"
                />
              </div>
              <div className={classes.blockItemInfo}>
                {hasLocation(recarea)
                  ? convertToGeolocationString(recarea?.lat, recarea?.lng)
                  : 'Not available'}
              </div>
            </div>
            <div className={classes.iconText}>
              <div className={classes.iconRoot}>
                <PhoneIcon outerLayer={classes.blockTypeIcon} />
              </div>
              <div className={classes.blockItemInfo}>
                {recarea?.phone || 'Not available'}
              </div>
            </div>
            <div className={classes.iconText}>
              <div className={classes.iconRoot}>
                <EmailIcon outerLayer={classes.blockTypeIcon} />
              </div>
              <div className={classes.blockItemInfo}>
                {recarea?.email ? (
                  <a
                    style={{ textDecoration: 'none', color: '#827700' }}
                    href={`mailto:${recarea?.email}`}
                  >
                    {recarea?.email}
                  </a>
                ) : (
                  <>Not available</>
                )}
              </div>
            </div>
            <div className={classes.iconText}>
              <div className={classes.iconRoot}>
                <CalendarIcon outerLayer={classes.blockTypeIcon} />
              </div>
              <div className={classes.blockItemInfo}>
                {recarea?.stay_limit || 'Not available'}
              </div>
            </div>
            <div
              className={classes.blockDescription}
              dangerouslySetInnerHTML={{ __html: recarea?.description ?? '' }}
            ></div>
          </Stack>
        )}
      </div>
      <div className={classes.btnContainer}>
        <HubButton
          className={classes.btnEdit}
          onClick={onViewDetail}
          title="Details"
          mode="red"
        />
        {addButton}
      </div>
    </Box>
  );
};

CampGround.defaultProps = {
  actionPayload: {},
  onGotoMap: () => {},
  onClose: () => {},
  onViewCampsite: () => {},
};

export default CampGround;
