export const svgCamera = (color, className, outerLayer) => {
  return (
    <svg
      className={outerLayer}
      height="100px"
      width="100px"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g transform="translate(0,-952.36218)">
        <path
          className={className}
          d="m 39,973.36218 -4,8 -15,0 c -3.324,0 -6,2.67596 -6,6 l 0,38.00002 c 0,3.324 2.676,6 6,6 l 60,0 c 3.324,0 6,-2.676 6,-6 l 0,-38.00002 c 0,-3.32404 -2.676,-6 -6,-6 l -15,0 -4,-8 -22,0 z m 11,18 c 8.2843,0 15,6.71576 15,15.00002 0,8.2842 -6.7157,15 -15,15 -8.2841,0 -15,-6.7158 -15,-15 0,-8.28426 6.7159,-15.00002 15,-15.00002 z"
          fill={color}
          stroke="none"
          marker="none"
          visibility="visible"
          display="inline"
          overflow="visible"
        ></path>
      </g>
    </svg>
  );
};