import { combineReducers } from 'redux';
import modal from './modal_reducer';
import modalv2 from './modal_v2.reducer';
import components from './ui_components';
import popup from './popup_reducer';
import impact from './impact_reducer';
import map from './map_reducer';

export default combineReducers({
  modal,
  components,
  popup,
  impact,
  map,
  modalv2,
});
