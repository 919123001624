export const svgCopy = (
  color,
  className,
  outerLayer,
  width = 15,
  height = 15,
) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 88, 88"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M39.5,8 L72.5,8 L80,8 L80,15.5 L80,48.5 L80,56 L72,56 L72,64 L80,64 C84.418,64 88,60.409 88,56 L88,48.5 L88,15.5 L88,8 C88,3.582 84.409,0 80,0 L72.5,0 L39.5,0 L32,0 C27.582,0 24,3.591 24,8 L24,16 L32,16 L32,8 L39.5,8 L39.5,8 L39.5,8 z M0,32.009 C0,27.586 3.578,24 8.009,24 L55.991,24 C60.414,24 64,27.578 64,32.009 L64,79.991 C64,84.414 60.422,88 55.991,88 L8.009,88 C3.586,88 0,84.422 0,79.991 L0,32.009 L0,32.009 L0,32.009 z M36,40 L28,40 L28,52 L16,52 L16,60 L28,60 L28,72 L36,72 L36,60 L48,60 L48,52 L36,52 L36,40 L36,40 z"
          fill={color}
        />
      </g>
    </svg>
  );
};