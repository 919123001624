import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  useTheme,
} from '@mui/material';

function UserListItemSkeleton() {
  const theme = useTheme();
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" width={'100%'} height={30} />}
      />
      <Button>
        <Skeleton variant="text" width={'100%'} height={30} />
      </Button>
    </ListItem>
  );
}

export default UserListItemSkeleton;
