import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as UnChecked } from './unchecked.svg';
import { renderToString } from 'react-dom/server';

const convertDomSvg = (svg) => {
  return encodeURIComponent(
    renderToString(svg)
      .replaceAll('stroke-width', 'strokeWidth')
      .replaceAll('fill-rule', 'fullRule')
      .replaceAll('enable-background', 'enableBackground'),
  );
};

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  position: absolute;
  cursor: pointer;
  opacity: 0;
  &:checked ~ span {
    width: ${({ width }) => width || '24px'};
    height: ${({ height }) => height || '24px'};
    background-image: url("data:image/svg+xml;charset=utf-8,${({
      checkedIcon,
    }) => convertDomSvg(checkedIcon || <Checked />)}");
    background-size: auto;
  background-position: left bottom;
  }
`;

const CheckboxContainer = styled.span`
  cursor: pointer;
  display: inline-block;
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  margin-left: 10px;
  overflow: visible;
  background-image: url("data:image/svg+xml;charset=utf-8,${({
    unCheckedIcon,
  }) => convertDomSvg(unCheckedIcon || <UnChecked />)}");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left bottom;
`;

const StyledDiv = styled.div`
  font-family: 'Albiona';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;

  color: #273a07;
`;

const Checkbox = (props) => (
  <StyledDiv>
    {props.title}
    <StyledCheckbox
      id="my-checkbox"
      {...props}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
    <CheckboxContainer htmlFor="my-checkbox" {...props} />
  </StyledDiv>
);

export default Checkbox;

