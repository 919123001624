export const svgEditNew = (
  color,
  className,
  outerLayer,
  width = 14,
  height = 14,
) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      style={{ overflow: 'visible' }}
    >
      <path
        className={className}
        d="M15 7.5C15 3.36316 11.6368 0 7.5 0C3.36316 0 0 3.36316 0 7.5C0 11.6368 3.36316 15 7.5 15C11.6368 15 15 11.6368 15 7.5ZM11.5263 7.72107C11.5263 8.17897 11.1474 8.55793 10.6894 8.55793H8.55787V10.6895C8.55787 11.1474 8.17891 11.5264 7.721 11.5264H7.27887C6.82096 11.5264 6.442 11.1474 6.442 10.6895V8.55793H4.31042C3.85251 8.55793 3.47355 8.17897 3.47355 7.72107V7.27893C3.47355 6.82103 3.85251 6.44207 4.31042 6.44207H6.442V4.31049C6.442 3.85258 6.82096 3.47362 7.27887 3.47362H7.721C8.17891 3.47362 8.55787 3.85258 8.55787 4.31049V6.44207H10.6894C11.1474 6.44207 11.5263 6.82103 11.5263 7.27893V7.72107Z"
        fill={color}
      />
    </svg>
  );
};
