
export const svgLearn = (color, className, outerLayer) => {
  return (
    <svg version="1.1" viewBox="0, 0, 100, 100" className={outerLayer}>
      <g id="Layer_1">
        <path
          className={className}
          d="M2.778,82.295 C-0.877,82.295 -0.877,77.534 2.778,77.534 L8.626,77.534 L8.626,70.94 L14.327,70.94 L14.327,77.534 L19.883,77.534 C23.684,77.534 23.684,82.295 19.883,82.295 L2.778,82.295 z M2.778,66.178 C-0.877,66.178 -0.877,61.416 2.778,61.416 L8.626,61.416 L8.626,54.701 L14.327,54.701 L14.327,61.416 L19.883,61.416 C23.684,61.416 23.684,66.178 19.883,66.178 C14.181,66.178 8.48,66.178 2.778,66.178 z M2.778,49.939 C-0.877,49.939 -0.877,45.177 2.778,45.177 L8.626,45.177 L8.626,38.584 L14.327,38.584 L14.327,45.177 L19.883,45.177 C23.684,45.177 23.684,49.939 19.883,49.939 C14.181,49.939 8.48,49.939 2.778,49.939 z M2.778,33.822 C-0.877,33.822 -0.877,29.06 2.778,29.06 L8.626,29.06 L8.626,22.466 L14.327,22.466 L14.327,29.06 L19.883,29.06 C23.684,29.06 23.684,33.822 19.883,33.822 C14.181,33.822 8.48,33.822 2.778,33.822 z M2.778,17.705 C-0.877,17.705 -0.877,12.943 2.778,12.943 L8.626,12.943 L8.626,11.722 C8.626,5.25 14.912,0 22.661,0 L85.965,0 C93.713,0 100,5.25 100,11.722 L100,88.278 C100,94.75 93.713,100 85.965,100 L22.661,100 C14.912,100 8.626,94.75 8.626,88.278 L8.626,87.057 L14.327,87.057 L14.327,88.278 C14.327,92.063 17.982,95.238 22.661,95.238 L85.965,95.238 C90.643,95.238 94.298,92.063 94.298,88.278 L94.298,11.722 C94.298,7.937 90.643,4.762 85.965,4.762 L22.661,4.762 C17.982,4.762 14.327,7.937 14.327,11.722 L14.327,12.943 L19.883,12.943 C23.684,12.943 23.684,17.705 19.883,17.705 L2.778,17.705 z"
          fill={color}
        />
        <path className={className} d="M36,28 L74,28" />
        <path className={className} d="M36,42.258 L74,42.258" />
        <path className={className} d="M36,54 L74,54" />
        <path className={className} d="M36,67.742 L74,67.742" />
      </g>
    </svg>
  );
};