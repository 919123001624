import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { useState } from 'react';
import useDeleteItineraryItemModalButton from '../hooks/use-delete-itinerary-day-modal-button';

const DeleteItineraryDayModalButton = ({ itineraryItemData }) => {
  const [loading, setLoading] = useState(false);

  const { openModal } = useDeleteItineraryItemModalButton(itineraryItemData);

  const handleClick = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    openModal().then(() => {
      setLoading(false);
    });
  };
  return (
    <Tooltip title="Delete Day">
      <IconButton onClick={handleClick} disabled={loading}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export const DeleteIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 72 90">
      <g id="Layer_1">
        <path d="M72,11.25 L72,15.75 C72,16.993 70.993,18 69.75,18 L2.25,18 C1.007,18 0,16.993 0,15.75 L0,11.25 C0,10.007 1.007,9 2.25,9 L22.5,9 L22.5,4.5 C22.5,2.015 24.515,0 27,0 L45,0 C47.485,0 49.5,2.015 49.5,4.5 L49.5,9 L69.75,9 C70.993,9 72,10.007 72,11.25 z M8.415,81.63 C8.746,86.353 12.68,90.012 17.415,90 L54.675,90 C59.41,90.012 63.344,86.353 63.675,81.63 L67.5,27 L4.5,27 L8.415,81.63 z" />
      </g>
    </SvgIcon>
  );
};

export default DeleteItineraryDayModalButton;
