import React from 'react';
import { closeModal, goBackPrevModal } from '../../actions/modal_actions';
import { connect } from 'react-redux';
import CreateBlock from './hub_modal_pages/create_edit_block/index';
import CreateInspiration from './hub_modal_pages/create_edit_inspiration/index';
import CreateAdventure2 from './hub_modal_pages/create_adventure_v2/index';
import CreateItinerary from './hub_modal_pages/create_itinerary/index';
import CreateItineraryItem from './hub_modal_pages/create_itinerary_item';
import CreatBlockFile from './hub_modal_pages/create_edit_view_block_file';
import DatePickerModal from './hub_modal_pages/pick_date';
import SearchModal from './hub_modal_pages/search_page';
import Share from './hub_modal_pages/share';
import SharePermissions from './hub_modal_pages/share_permission';
import ShareTags from './hub_modal_pages/share_tags';
import InviteFriends from './hub_modal_pages/invite_friends_to_adventure';
import InviteAdventureFrieds from './hub_modal_pages/invite_adventure_friends';
import AccountGroup from './hub_modal_pages/account_group';
import AdventureGroup from './hub_modal_pages/adventure_group';
import Account from './hub_modal_pages/account';
import Delete from './hub_modal_pages/delete_modal';
import TimePickerModal from './hub_modal_pages/pick_time';
import UnsplashImages from './hub_modal_pages/fetch_image_unsplash';
import LoadingScreen from './hub_modal_pages/loading';
import Contacts from './hub_modal_pages/contacts';
import InviteNewModal from './hub_modal_pages/invite_new_members';
import AssignUsersModal from './hub_modal_pages/assign_user';
import CheckListModal from './hub_modal_pages/create_check_list';
import MapFilterModal from './hub_modal_pages/map-filter';
import CampGroundModal from './hub_modal_pages/campground';
import CopyModal from './hub_modal_pages/copy_item';
import ImageOp from './hub_modal_pages/image_op';
import EditProfileImage from './hub_modal_pages/edit_profile_image';
import CopyAdventureModal from './hub_modal_pages/copy_adventure';
import MessageAlertModal from './hub_modal_pages/alert_message';
import Chatroom from './hub_modal_pages/chat';
import CreateEditFolder from './hub_modal_pages/create_edit_folder';
import EditAccountExplorerModal from './hub_modal_pages/edit_account_explorer';
import InviteNewAccountMembersModal from './hub_modal_pages/invite_member_to_account';
import AcceptTripInvite from './hub_modal_pages/accept_trip_invite';
import {
  CLOSE_MODAL,
  OPEN_CREATE_BLOCK,
  OPEN_CREATE_INSPIRATION,
  OPEN_CREATE_ADVENTURE,
  OPEN_CREATE_ITINERARY,
  OPEN_CREATE_ITINERARY_ITEM,
  OPEN_DATE_PICKER_MODAL,
  OPEN_CREATE_BLOCK_FILE,
  OPEN_SEARCH_MODAL,
  OPEN_SHARE_MODAL,
  OPEN_SHARE_PERMISSION_MODAL,
  OPEN_SHARE_TAGS_MODAL,
  OPEN_FRIENDS_MODAL,
  OPEN_ACCOUNT_GROUP_MODAL,
  OPEN_ADVENTURE_GROUP_MODAL,
  OPEN_ACCOUNT_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_TIME_PICKER_MODAL,
  OPEN_FETCH_UNSPLASH_MODAL,
  RECEIVE_IMAGES_SELECTED,
  RECEIVE_LOADING_MODAL,
  RECEIVE_OPEN_CONTACTS_MODAL,
  RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL,
  RECEIVE_INVITE_NEW_MODAL,
  RECEIVE_OPEN_ASSIGN_USERS_MODAL,
  RECEIVE_OPEN_CHECK_LIST_MODAL,
  RECEIVE_OPEN_COPY_MODAL,
  RECEIVE_OPEN_IMAGE_OP_MODAL,
  RECEIVE_OPEN_ADVENTURE_COPY_MODAL,
  RECEIVE_MESSAGE_ALERT_MODAL,
  RECEIVE_OPEN_CHAT_ROOM_MODAL,
  RECEIVE_OPEN_FOLDER_MODAL,
  RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL,
  RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL,
  RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL,
  RECEIVE_CLOSE_POP_UP,
  RECEIVE_OPEN_TRIP_INVITE_MODAL,
  RECEIVE_OPEN_MAP_FILTER_MODAL,
  RECEIVE_OPEN_CAMPGROUND_MODAL,
} from '../../constants';
import PopUpOverlay from '../global_modules/pop_up';
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPersister: null,
      modalAudit: null,
      prevModal: null,
    };
    this.persistData = this.persistData.bind(this);
    this.setPrevModal = this.setPrevModal.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  persistData(data) {
    this.setState({ dataPersister: data });
  }
  setPrevModal() {
    this.setState({ prevModal: this.props.modal.action });
  }
  goBack() {
    this.props.goBackPrevModal(this.state.prevModal);
  }

  render() {
    let component;
    switch (this.props.modal.action) {
      case OPEN_DELETE_MODAL:
        component = <Delete closeModal={this.props.closeModal} />;
        break;
      case OPEN_CREATE_BLOCK:
        component = (
          <CreateBlock
            closeModal={this.props.closeModal}
            setPrevModal={this.setPrevModal}
          />
        );
        break;
      case RECEIVE_OPEN_TRIP_INVITE_MODAL:
        component = <AcceptTripInvite closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_FOLDER_MODAL:
        component = (
          <CreateEditFolder
            closeModal={this.props.closeModal}
            setPrevModal={this.setPrevModal}
          />
        );
        break;
      case RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL:
        component = (
          <InviteNewAccountMembersModal closeModal={this.props.closeModal} />
        );
        break;
      case OPEN_CREATE_INSPIRATION:
        component = <CreateInspiration closeModal={this.props.closeModal} />;
        break;
      case OPEN_CREATE_ADVENTURE:
        component = (
          <CreateAdventure2
            persistedData={this.state.dataPersister}
            persistData={this.persistData}
            closeModal={this.props.closeModal}
          />
        );
        break;
      case OPEN_CREATE_ITINERARY_ITEM:
        component = (
          <CreateItineraryItem
            closeModal={this.props.closeModal}
            persistedData={this.state.dataPersister}
            persistData={this.persistData}
          />
        );
        break;
      case OPEN_CREATE_ITINERARY:
        component = (
          <CreateItinerary
            persistedData={this.state.dataPersister}
            persistData={this.persistData}
            closeModal={this.props.closeModal}
          />
        );
        break;
      case RECEIVE_OPEN_COPY_MODAL:
        component = <CopyModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_ADVENTURE_COPY_MODAL:
        component = <CopyAdventureModal closeModal={this.props.closeModal} />;
        break;
      case OPEN_DATE_PICKER_MODAL:
        component = <DatePickerModal />;
        break;
      case OPEN_TIME_PICKER_MODAL:
        component = <TimePickerModal />;
        break;
      case OPEN_CREATE_BLOCK_FILE:
        component = (
          <CreatBlockFile
            goBack={this.goBack}
            closeModal={this.props.closeModal}
          />
        );
        break;
      case OPEN_SEARCH_MODAL:
        component = <SearchModal closeModal={this.props.closeModal} />;
        break;
      case OPEN_SHARE_MODAL:
        component = <Share closeModal={this.props.closeModal} />;
        break;
      case OPEN_SHARE_PERMISSION_MODAL:
        component = <SharePermissions closeModal={this.props.closeModal} />;
        break;
      case OPEN_SHARE_TAGS_MODAL:
        component = <ShareTags closeModal={this.props.closeModal} />;
        break;
      case OPEN_FRIENDS_MODAL:
        component = <InviteFriends closeModal={this.props.closeModal} />;
        break;
      case OPEN_ACCOUNT_GROUP_MODAL:
        component = <AccountGroup closeModal={this.props.closeModal} />;
        break;
      case OPEN_ADVENTURE_GROUP_MODAL:
        component = <AdventureGroup closeModal={this.props.closeModal} />;
        break;
      case OPEN_ACCOUNT_MODAL:
        component = <Account closeModal={this.props.closeModal} />;
        break;
      case OPEN_FETCH_UNSPLASH_MODAL:
        component = <UnsplashImages closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_MESSAGE_ALERT_MODAL:
        component = <MessageAlertModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_LOADING_MODAL:
        component = <LoadingScreen />;
        break;
      case RECEIVE_OPEN_CONTACTS_MODAL:
        component = <Contacts closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_ADVENTURE_FRIENDS_MODAL:
        component = (
          <InviteAdventureFrieds closeModal={this.props.closeModal} />
        );
        break;
      case RECEIVE_INVITE_NEW_MODAL:
        component = <InviteNewModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_ASSIGN_USERS_MODAL:
        component = <AssignUsersModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_CHECK_LIST_MODAL:
        component = <CheckListModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_MAP_FILTER_MODAL:
        component = <MapFilterModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_CAMPGROUND_MODAL:
        component = <CampGroundModal closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_IMAGE_OP_MODAL:
        component = <ImageOp closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL:
        component = <EditProfileImage closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_CHAT_ROOM_MODAL:
        component = <Chatroom closeModal={this.props.closeModal} />;
        break;
      case RECEIVE_OPEN_EDIT_ACCOUNT_EXPLORER_MODAL:
        component = (
          <EditAccountExplorerModal closeModal={this.props.closeModal} />
        );
        break;
      case CLOSE_MODAL:
        component = <div />;
      default:
        component = <div />;
    }

    const modalChildStyle = {
      // height: "600px",
      // width: "65%"
    };
    const action = this.props.modal.action;
    if (action === OPEN_DELETE_MODAL
      || action === OPEN_CREATE_BLOCK
      || action === RECEIVE_OPEN_FOLDER_MODAL
      || action === OPEN_CREATE_ADVENTURE
      || action === OPEN_CREATE_ITINERARY_ITEM
      || action === OPEN_CREATE_ITINERARY
      || action === RECEIVE_OPEN_CHECK_LIST_MODAL
      || action === OPEN_CREATE_BLOCK_FILE
      || action === RECEIVE_OPEN_COPY_MODAL
      || action === RECEIVE_OPEN_EDIT_PROFILE_IMAGE_MODAL
      || action === RECEIVE_OPEN_INVITE_NEW_MEMBER_MODAL
      || action === RECEIVE_OPEN_ADVENTURE_COPY_MODAL
      || action === RECEIVE_MESSAGE_ALERT_MODAL
      || action === OPEN_FRIENDS_MODAL
      || action === RECEIVE_OPEN_MAP_FILTER_MODAL
      || action === RECEIVE_OPEN_CAMPGROUND_MODAL
    ) {
      return (
        <>
          {component}
          {this.props.popup.action !== RECEIVE_CLOSE_POP_UP && (
            <PopUpOverlay
              display={true}
              content={this.props.popup.popupContent}
            />
          )}
        </>
      )
    }
    return (
      <div
        className={
          this.props.modal.action === CLOSE_MODAL
            ? 'modal-background-closed'
            : 'modal-background'
        }
      // onClick={this.props.closeModal}
      >
        <div
          className="modal-child"
          style={modalChildStyle}
          onClick={(e) => e.stopPropagation()}
          id="modal-child"
        >
          {component}
        </div>
        {this.props.popup.action !== RECEIVE_CLOSE_POP_UP && (
          <PopUpOverlay
            display={true}
            content={this.props.popup.popupContent}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ ui, session }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;

  return {
    modal: ui.modal,
    currentUser: session.user,
    isAuthenticated: session.isAuthenticated,
    popup: ui.popup,
    userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    goBackPrevModal: (data) => dispatch(goBackPrevModal(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
