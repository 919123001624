
export const svgDownArrow = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      width="100px"
      fill={color}
      data-name="Layer 3"
      viewBox="0 0 36 36"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <path
        className={className}
        d="M18,27a1.992,1.992,0,0,1-1.414-.586l-14-14A2,2,0,0,1,5.414,9.586L18,22.171,30.586,9.586a2,2,0,1,1,2.828,2.828l-14,14A1.992,1.992,0,0,1,18,27Z"
      ></path>
    </svg>
  );
};