import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { postInspiration } from 'actions/inspiration_actions';

import { Input, TextArea, Button, DropZone, Title } from '../../modal_modules';
import axios from 'axios';
import { svgClose } from 'components/svg_module';
const mapStateToProps = (state) => {
  let inspirations = state.entities.inspiration.inspirations
    ? state.entities.inspiration.inspirations
    : null;
  let account_rid = state.session.user.account_rid
    ? state.session.user.account_rid
    : null;
  let original_inspiration = state.ui.modal.original_inspiration
    ? state.ui.modal.original_inspiration
    : null;
  return {
    inspirations,
    account_rid,
    original_inspiration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postInspiration: (account_rid, data) =>
      dispatch(postInspiration(account_rid, data)),
  };
};
const CreateInspiration = ({
  account_rid,
  closeModal,
  inspirations,
  original_inspiration,
  postInspiration,
}) => {
  const [inputValues, setInputValues] = useState({
    inspiration_rid: original_inspiration
      ? original_inspiration.inspiration_rid
      : null,
    parent_inspiration_rid: original_inspiration
      ? original_inspiration.parent_inspiration_rid
      : null,
    account_rid,
    inspiration_name: original_inspiration
      ? original_inspiration.inspiration_name
      : null,
    inspiration_note: original_inspiration ? original_inspiration.note : null,
    ui_image_location: original_inspiration
      ? original_inspiration.ui_image_location
      : null,
  });

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const [inspirationPic, setInspirationPic] = useState(
    original_inspiration
      ? {
          file: original_inspiration.ui_image_location,
          file_name: original_inspiration.ui_image_location,
        }
      : null,
  );
  const [inspirationPicFile, setInspirationPicFile] = useState(null);
  const originHandler = () => {
    setInputValues({ ...inputValues, ui_image_location: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(product)
    if (
      original_inspiration &&
      original_inspiration.ui_image_location === inspirationPic.file_name
    ) {
      postInspiration(account_rid, inputValues).then(() => {
        setInputValues({
          inspiration_rid: null,
          parent_inspiration_rid: null,
          account_rid,
          inspiration_name: null,
          inspiration_note: null,
          ui_image_location: null,
        });
        closeModal();
      });
    } else {
      try {
        if (inspirationPic.file_name || inspirationPic.file) {
          const formData = new FormData();
          const image = inspirationPic;
          if (!image.file.includes('blob')) {
            let typeName = image.file.slice(5, 15);
            if (typeName.includes('png') || typeName.includes('jpg')) {
              typeName = typeName.slice(0, typeName.length - 1);
            }
            const slicedData =
              typeName.includes('png') || typeName.includes('jpg')
                ? image.file.slice(22)
                : image.file.slice(23);
            const byteCharacters = atob(slicedData);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `${typeName}` });
            blob['originalname'] = image.file_name;
            formData.set('original_name', image.file_name);
            formData.set('file', blob);
            window.formData = formData;
          } else {
            // let blob = await fetch(inspirationPic).then(r => r.blob());
            // let blob2 = await window.URL.revokeObjectURL(inspirationPic)
            let blob3 = inspirationPicFile;
            blob3.originalname = blob3.name;
            // formData.set("file", blob);
            formData.set('file', blob3);
            window.formData = formData;
          }

          axios.post('/api/bucket/uploads', formData).then((res) => {
            let picUrl = res.data.data;
            inputValues['ui_image_location'] = picUrl;
            postInspiration(account_rid, inputValues).then(() => {
              setInputValues({
                inspiration_rid: null,
                parent_inspiration_rid: null,
                account_rid,
                inspiration_name: null,
                inspiration_note: null,
                ui_image_location: null,
              });
            });
          });
        } else {
          postInspiration(account_rid, inputValues).then(() => {
            setInputValues({
              inspiration_rid: null,
              parent_inspiration_rid: null,
              account_rid,
              inspiration_name: null,
              inspiration_note: null,
              ui_image_location: null,
            });
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
        // catchErrors(error, setError);
      } finally {
      }
    }
  };
  return (
    <div className="mod-type1-wrapper">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="INSPIRATION" />
        <div className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Input
              label="Name"
              name="inspiration_name"
              onChangeHandler={handleOnChange}
              value={inputValues.inspiration_name}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <TextArea
              label="Description"
              name="inspiration_note"
              onChangeHandler={handleOnChange}
              value={inputValues.inspiration_note}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <DropZone
              image={inspirationPic}
              original_image={
                original_inspiration
                  ? original_inspiration.ui_image_location
                  : null
              }
              setImage={setInspirationPic}
              inputHandler={originHandler}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInspiration);
