import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchPlanDetails } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Headers } from './shared_modules';
import { PlanBox } from './subscription_modules';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchPlanDetails: (planRid) => dispatch(fetchPlanDetails(planRid)),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user?.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let planDetails = ui.components.planDetails
    ? ui.components.planDetails
    : null;

  return { userInfo, currentExplorerDetails, planDetails };
};

const ManageSubscription = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const {
    currentExplorerDetails,
    fetchAccountExplorer,
    userInfo,
    planDetails,
    fetchPlanDetails,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  console.log(currentExplorerDetails.plan_rid, planDetails);
  useEffect(() => {
    scrollTop();

    // if (!currentExplorerDetails)
    fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    if (!planDetails)
      // fetchPlanDetails(process.env.NODE_ENV !== 'development' ? 1 : 5);
      fetchPlanDetails(userInfo.ambassador_flag ? 6 : 1, null);
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  useEffect(() => {
    document
      .getElementById('hub-page-container-account-responsive')
      .scrollTo(0, 0, 'smooth');
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile  "
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="MEMBERSHIP" />
          <div className="subscription-available-plans-section-divider">
            <header className="general-header">CURRENT PLAN</header>
            <hr />
          </div>
          <section className="account-info-contents-wrapper">
            {planDetails &&
              currentExplorerDetails &&
              planDetails.map(
                (plan) =>
                  // plan.plan_rid ===
                  //   (process.env.NODE_ENV !== 'development'
                  //     ? userInfo.plan_rid
                  //     : 4) && (
                  //   <PlanBox plan={plan} isUserPlan {...props} allowCancel />
                  // ),
                  plan.plan_rid === currentExplorerDetails.plan_rid && (
                    <PlanBox plan={plan} isUserPlan {...props} allowCancel />
                  ),
              )}
          </section>
          {/* <div className="subscription-available-plans-section-divider">
            <h3>Details</h3>
            <hr />
          </div>
          <div className="manage-subscriptions-details-wrapper"></div>
          <div className="manage-subscription-buttons-wrapper"></div> */}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);
