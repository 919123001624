import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchCountries, fetchStates } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Headers } from './shared_modules';

import General from './profile_modules/general_section';
// import Hobbies from './profile_modules/hobbies_section';
import Contact from './profile_modules/contact_section';
// import Badges from "./profile_modules/badges_section";
import * as EmailValidator from 'email-validator';
import Lo from 'lodash';
import { openEditProfileImageModal } from '../../../actions/modal_actions';
// import { FirstSection, SecondSection } from './profile_modules';
import { TextArea } from 'components/global_modal/modal_modules';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchStates: () => dispatch(fetchStates()),
  fetchCountries: () => dispatch(fetchCountries()),
  openEditProfileImageModal: (data, origin) =>
    dispatch(openEditProfileImageModal(data, origin)),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let countries = ui.components.countries ? ui.components.countries : null;
  let statesProvences = ui.components.statesProvences
    ? ui.components.statesProvences
    : null;
  return { userInfo, currentExplorerDetails, countries, statesProvences };
};

const ComingSoonPage = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const {
    title,
    userInfo,
    updateUserInfo,
    fetchAccountExplorer,
    currentExplorerDetails,
    fetchStates,
    fetchCountries,
    statesProvences,
    countries,
    openEditProfileImageModal,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    fetchCountries();
    fetchStates();
    scrollTop();

    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);
  useEffect(() => {
    fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
  }, []);
  const [accountValues, setAccountValues] = useState({
    about_me: null,
    account_about: null,
    account_name: null,
    account_rid: null,
    account_role_name: null,
    account_role_rid: null,
    address_1: null,
    address_2: null,
    city: null,
    country_name: null,
    country_rid: null,
    date_of_birth: null,
    email: null,
    explorer_rid: null,
    first_name: null,
    last_name: null,
    phone: null,
    plan_name: null,
    plan_rid: null,
    state_provence: null,
    subscription_end_date: null,
    subscription_rid: null,
    subscription_start_date: null,
    ui_image_location: null,
    username: null,
    about_me: null,
  });
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: '',
    emailErrors: '',
    passwordErrors: [],
  });
  const [generalValues, setGeneralValues] = useState({
    username: null,
    first_name: null,
    last_name: null,
    email: null,
    about_me: null,
  });
  const [contactValues, setContactValues] = useState({
    address_1: null,
    address_2: null,
    city: null,
    state_provence: null,
    country_name: null,
    country_rid: null,
    phone: null,
    state_provence_rid: null,
  });
  // const [objectProfile, setObjectProfile] = useState({
  // })
  useEffect(() => {
    if (currentExplorerDetails) {
      setAccountValues(currentExplorerDetails);
      const test = setGeneralValues(
        Lo.pick(currentExplorerDetails, Object.keys(generalValues)),
      );
      setContactValues(
        Lo.pick(currentExplorerDetails, Object.keys(contactValues)),
      );
    }
  }, [currentExplorerDetails]);

  const [generalSaved, setGeneralSaved] = useState(true);
  const [contactSaved, setContactSaved] = useState(true);
  const handleChange = useCallback((event) => {
    const { value, name, id } = event.currentTarget;
    if (id === 'general') {
      if (name === 'email') {
        let emailValidation = EmailValidator.validate(value);
        if (emailValidation === false) {
          setSignUpErrors({
            ...signUpErrors,
            emailErrors: 'Please enter a valid email',
          });
        } else {
          setSignUpErrors({ ...signUpErrors, emailErrors: '' });
          axios
            .patch('/api/e/verify_auth_email', { email: value })
            .then((res) => {
              setSignUpErrors({
                ...signUpErrors,
                emailErrors:
                  res.data.message === 'Email valid'
                    ? ''
                    : !res.data.message
                    ? ''
                    : res.data.message,
              });
            });
        }
      } else if (name === 'username') {
        if (value.length < 5) {
          setSignUpErrors({
            ...signUpErrors,
            usernameError: 'Username must be at least 5 characters',
          });
        } else {
          axios
            .patch('/api/e/verify_auth_username', { username: value })
            .then((res) => {
              setSignUpErrors({
                ...signUpErrors,
                usernameError: res.data.message,
              });
            });
        }
      }
      setGeneralValues({ ...generalValues, [name]: value });
      setGeneralSaved(false);
    }
    if (id === 'contact') {
      setContactValues({ ...contactValues, [name]: value });
      setContactSaved(false);
    }
  });

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  //TAKES IN SECTION DATA AND NAME, COPIES TO FULL OBJ AND SETS SAVED TRUE FOR SECTION
  const handleSave = (source, saved) => {
    const temp = Object.assign(accountValues, source);
    setAccountValues(temp);
    updateUserInfo(temp);
    saved(true);
  };
  //TAKES IN SETTER STATE FUNCTION CALL TO RESET AND OBJECT TO RESET
  const handleCancel = (cbToReset, obj, saved) => {
    cbToReset(Lo.pick(accountValues, Object.keys(obj)));
    setSignUpErrors({
      usernameError: '',
      emailErrors: '',
      passwordErrors: [],
    });
    saved(true);
  };
  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile"
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id="account-page-container-responsive"
        >
          <Headers text="PROFILE" />

          <div className="account-profile-header-container">
            <div className="account-profile-header">
              <div className="account-profile-image-container">
                <>
                  <img
                    src={
                      accountValues.ui_image_location
                        ? accountValues.ui_image_location
                        : '/svg_assets/person_light_orange.svg'
                    }
                    className="account-profile-image"
                  />
                  <button
                    className="account-profile-image-change"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openEditProfileImageModal(null, { isProfile: true });
                    }}
                  >
                    CHANGE
                  </button>
                </>
              </div>
              <div className="account-header-username">
                {accountValues.username}
              </div>
            </div>
            <div className="account-profile-header-description-container">
              <TextArea
                parentClassName="account-management-description"
                label="About me"
                value={generalValues.about_me}
                onChangeHandler={handleChange}
                name={'about_me'}
                id={'general'}
              />
            </div>
          </div>
          {currentExplorerDetails && (
            <div className="account-profile-body-container">
              <div className="account-profile-body">
                <General
                  values={generalValues}
                  onChangeHandler={handleChange}
                  saved={generalSaved}
                  saveHandler={() => handleSave(generalValues, setGeneralSaved)}
                  cancelHandler={() =>
                    handleCancel(
                      setGeneralValues,
                      generalValues,
                      setGeneralSaved,
                    )
                  }
                  signUpErrors={signUpErrors}
                />
                <Contact
                  {...props}
                  values={contactValues}
                  onChangeHandler={handleChange}
                  saved={contactSaved}
                  saveHandler={() => handleSave(contactValues, setContactSaved)}
                  cancelHandler={() => {
                    handleCancel(
                      setContactValues,
                      contactValues,
                      setContactSaved,
                    );
                  }}
                />
                {/* <Hobbies accountValues={accountValues}></Hobbies> */}
                {/* <Badges accountValues={accountValues}></Badges> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="version-wrapper">
        <span>V1.2.4</span>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoonPage);
