import React, { useState } from 'react';
import { ItineraryItemList } from '../../modal_modules';
import merge from 'lodash';
import { connect } from 'react-redux';

const ItineraryItemsList = ({
  selectedItinerary,
  itineraryItems,
  selectedItineraryItems,
  handleSelectItineraryItem,
}) => {
  return (
    <div className="block-module-itinerary-item-list-wrapper">
      <div
        className="block-module-itinerary-item-list block-module-itinerary-item-list--header"
        style={{ position: 'static' }}
      >
        Add to a day below or just click save to add to your Trip Board — you
        can drag into your itinerary later.
      </div>
      <div className="block-module-itinerary-item-list block-module-itinerary-item-list--contents">
        {itineraryItems &&
          itineraryItems.map((ele, idx) => {
            return (
              <ItineraryItemList
                selectedItineraryItems={selectedItineraryItems}
                handleSelectItineraryItem={handleSelectItineraryItem}
                itineraryItem={ele}
                selectedItinerary={selectedItinerary}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ItineraryItemsList;
