export const svgBackButton = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      className={outerLayer}
      version="1.1"
      x="0"
      y="0"
      width={width ? width : '27'}
      height={height ? height : '47'}
      viewBox={
        viewBox
          ? viewBox
          : `0 0 ${width ? width : '27'} ${height ? height : '47'}`
      }
    >
      <path
        className={className}
        d="M-4.5897e-07 10.5C-2.05491e-07 16.2989 4.70108 21 10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70108 16.2989 -7.12448e-07 10.5 -4.5897e-07C4.70108 -2.05491e-07 -7.12448e-07 4.70108 -4.5897e-07 10.5ZM13.3875 7.20008L10.0876 10.5L13.3875 13.7999L11.7376 15.4496L6.78763 10.5L11.7372 5.55042L13.3875 7.20008Z"
        fill="#DFD5C2"
      />
    </svg>
  );
};