import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { ensureProtocol } from './nav-util';

export const externalLinkProps = {
  rel: 'noopener noreferrer',
  target: '_blank',
};

function NavLink({ href, children, external = false, ...rest }) {
  if (external)
    return (
      <Link href={ensureProtocol(href)} {...externalLinkProps} {...rest}>
        {children}
      </Link>
    );
  else
    return (
      <Link component={RouterLink} to={href} {...rest}>
        {children}
      </Link>
    );
}

export default NavLink;
