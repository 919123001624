import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  updateUserInfo,
  fetchAccountExplorer,
} from '../../../actions/user_actions.js';
import { fetchPlanDetails } from '../../../actions/ui_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Headers } from './shared_modules';
import { PlanBox } from './subscription_modules';
import { withRouter } from 'react-router-dom';
const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data) => dispatch(updateUserInfo(data)),
  fetchAccountExplorer: (accountRid, explorerRid) =>
    dispatch(fetchAccountExplorer(accountRid, explorerRid)),
  fetchPlanDetails: (planRid) => dispatch(fetchPlanDetails(planRid)),
});
const mapStateToProps = ({ session, entities, ui }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;
  let currentExplorerDetails = entities.users.currentExplorerDetails
    ? entities.users.currentExplorerDetails
    : null;
  let planDetails = ui.components.planDetails
    ? ui.components.planDetails
    : null;

  return { userInfo, currentExplorerDetails, planDetails };
};

const ComingSoonPage = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const onboardingPlanRid = props.match.params.plan_rid;
  const {
    currentExplorerDetails,
    fetchAccountExplorer,
    userInfo,
    planDetails,
    fetchPlanDetails,
  } = props;
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    scrollTop();

    if (!currentExplorerDetails && userInfo)
      fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid);
    if (!planDetails)
      // fetchPlanDetails(process.env.NODE_ENV !== 'development' ? 1 : 5);
      fetchPlanDetails(process.env.NODE_ENV !== 'development' ? 1 : 1, null);
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'account' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper ">
      {/* contents of the page goes here */}
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>

      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="hub-page-container hub-page-container--profile  "
        id="hub-page-container-account-responsive"
      >
        <div
          className={`account-page-container  ${
            !isMobile
              ? 'account-pages-browser-settings'
              : 'account-page-container--mobile'
          }`}
          id={
            userInfo
              ? 'account-page-container-responsive'
              : 'account-page-onboard-responsive'
          }
        >
          <Headers text="AVAILABLE PLANS" />
          <div className="subscription-available-plans-section-divider">
            <h3>Current Plan</h3>
            <hr />
          </div>
          {planDetails &&
            (currentExplorerDetails || onboardingPlanRid) &&
            planDetails.map(
              (plan) =>
                // plan.plan_rid === currentExplorerDetails.plan_rid && (
                plan.plan_rid === currentExplorerDetails.plan_rid && (
                  <PlanBox plan={plan} isUserPlan isUpgradable {...props} />
                ),
            )}
          <div className="subscription-available-plans-section-divider">
            <h3>Available Plans</h3>
            <hr />
          </div>
          {planDetails &&
            currentExplorerDetails &&
            // planDetails.map(
            //   (plan) =>
            //     plan.plan_rid !==
            //       (process.env.NODE_ENV !== 'development'
            //         ? userInfo.plan_rid
            //         : 4) && (
            //       <PlanBox
            //         plan={plan}
            //         currentExplorerDetails={currentExplorerDetails}
            //         {...props}
            //         isUpdate={true} // needs condition here to check whether or not user already has a plan
            //       />
            //     ),
            planDetails.map(
              (plan) =>
                plan.plan_rid !== currentExplorerDetails.plan_rid &&
                plan.plan_rid !== 35 &&
                plan.plan_rid !== 29 && (
                  <PlanBox
                    plan={plan}
                    currentExplorerDetails={currentExplorerDetails}
                    {...props}
                    isUpdate={userInfo.plan_rid === 29 ? false : true} // needs condition here to check whether or not user already has a plan
                  />
                ),
            )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ComingSoonPage));
