export function vanityVan (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 69 69"
      enableBackground="new 0 0 69 69"
    >
      <g>
        <path
          className={className}
          fill={color}
          d="M66.49918,57.93335V45.44843c0-0.41998-0.33002-0.75-0.75-0.75c-0.40997,0-0.75,0.33002-0.75,0.75v4.97003h-7.10999   c0.85999-0.89001,1.39996-2.10004,1.39996-3.44V22.24847c0-3.72003-3.01996-6.75-6.73999-6.75H15.80917   c-4.67999,0-8.92999,2.38995-11.35999,6.39996c-1.28003,2.10004-1.95001,4.5-1.95001,6.95001v18.13   c0,2.71997,2.20001,4.92999,4.91003,4.92999h5.06c-0.03003-0.23999-0.04004-0.5-0.04004-0.75c0-5.90997,4.54004-9,9.02002-9   c4.84998,0,9.02002,3.40002,9.02002,9c0,0.25-0.01001,0.51001-0.04004,0.75h17.88v5.28308H25.93063   c1.84033-1.37427,3.03857-3.56403,3.03857-6.03308c0-4.4837-3.02448-6.95044-6.36029-7.41339   c-0.0202-0.00568-0.03638-0.01868-0.05731-0.02277c-0.65918-0.12793-1.54297-0.12793-2.20508,0   c-0.021,0.00403-0.03723,0.01709-0.0575,0.02283c-3.33557,0.46313-6.35986,2.92987-6.35986,7.41333   c0,2.46906,1.19824,4.65881,3.03857,6.03308H3.25131c-0.41406,0-0.75,0.33594-0.75,0.75s0.33594,0.75,0.75,0.75h62.49951   c0.41406,0,0.75-0.33594,0.75-0.75C66.50082,57.93866,66.49924,57.93622,66.49918,57.93335z M21.44919,54.89844   c-2.06,0-3.72998-1.66998-3.72998-3.73999c0-2.06,1.66998-3.73999,3.72998-3.73999s3.73999,1.67999,3.73999,3.73999   C25.18918,53.22845,23.50919,54.89844,21.44919,54.89844z M33.2692,35.28845H10.41916V24.23846H33.2692V35.28845z    M38.91916,50.40845V24.57843c0-1.26996,1.03003-2.28998,2.28003-2.28998h8.41998c1.25,0,2.28003,1.02002,2.28003,2.28998v25.83002   H38.91916z M64.99918,57.19153H51.8992v-5.28308c11.29999,0.01001,12.84998,0.01001,13.02997,0.01001c0,0,0.02002,0,0.07001,0   V57.19153z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M43.57919,12.73846c0-1.34003-1.08002-2.42999-2.41998-2.42999H20.2692c-1.34003,0-2.42999,1.08997-2.42999,2.42999   v1.26001h25.73999V12.73846z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M21.44919,48.91846c-1.22998,0-2.22998,1-2.22998,2.23999s1,2.23999,2.22998,2.23999c1.23999,0,2.23999-1,2.23999-2.23999   S22.68918,48.91846,21.44919,48.91846z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M48.75919,45.59845h-6.70001c-0.40997,0-0.75,0.33002-0.75,0.75c0,0.40997,0.34003,0.75,0.75,0.75h6.70001   c0.41998,0,0.75-0.34003,0.75-0.75C49.50919,45.92847,49.17917,45.59845,48.75919,45.59845z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M48.75919,41.93848h-6.70001c-0.40997,0-0.75,0.33997-0.75,0.75c0,0.40997,0.34003,0.75,0.75,0.75h6.70001   c0.41998,0,0.75-0.34003,0.75-0.75C49.50919,42.27844,49.17917,41.93848,48.75919,41.93848z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M45.41165,32.85925c1.7204,0,3.11505-1.39764,3.11505-3.1217s-1.39465-3.12164-3.11505-3.12164   s-3.11511,1.39758-3.11511,3.12164S43.69125,32.85925,45.41165,32.85925z"
        />
      </g>
    </svg>
  );
}