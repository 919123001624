export const svgFriend = (
  color,
  className,
  outerLayer,
  width,
  height,
  viewBox,
) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
      x="0"
      y="0"
      width={width ? width : '71'}
      height={height ? height : '71'}
      viewBox={viewBox ? viewBox : '0 0 71 71'}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M59.472,23.814 C59.472,36.966 48.81,47.627 35.658,47.627 C22.506,47.627 11.844,36.966 11.844,23.814 C11.844,10.662 22.506,0 35.658,0 C48.81,0 59.472,10.662 59.472,23.814 z"
            fill={color}
          />
          <path
            className={className}
            d="M55.316,46.619 C50.035,51.177 43.165,53.943 35.658,53.943 C28.151,53.943 21.281,51.177 16,46.619 C6.361,53.001 -0,63.939 -0,76.366 L71.316,76.366 C71.316,63.939 64.955,53.001 55.316,46.619 z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};