import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Pagination } from "swiper";

/**
 * TODO: Types
 * @param {*} param0
 * @returns
 */
const SwiperCarousel = ({ className, children, prev, next, ...rest }) => {
  /**
   * @type {ReturnType<typeof useState<import("swiper/react").SwiperRef>>}
   */
  const [swiperRef, setSwiperRef] = useState();

  const childrenArray = React.Children.toArray(children); // if passing 1 child need to make sure its an array

  // const handlePrevious = useCallback(() => {
  //   // @ts-ignore [ts typing version mismatch]
  //   swiperRef?.slidePrev();
  // }, [swiperRef]);

  // const handleNext = useCallback(() => {
  //   // @ts-ignore [ts typing version mismatch]
  //   swiperRef?.slideNext();
  // }, [swiperRef]);

  const renderBullet = (index, className) => {
    return renderToString(
      <span
        key={index}
        className={className}
        onClick={(e) => e.stopPropagation()}
      ></span>
    );
  };

  // @ts-ignore [ts typing version mismatch]
  // const slideActive = !(swiperRef?.isBeginning && swiperRef?.isEnd);

  return (
    <Box className={className} display="flex">
      {/* <Box className="swiper-button">
        {slideActive && (
          <Box
            className={`swiper-button-prev ${className}`}
            onClick={handlePrevious}
          ></Box>
        )}
      </Box> */}
      <Swiper
        // @ts-ignore [ts typing version mismatch]
        onSwiper={setSwiperRef}
        modules={[Navigation, Pagination]}
        className="mySwiper"
        pagination={{
          clickable: true,
          renderBullet: (index, cName) => renderBullet(index, `${cName} test`),
        }}
        {...rest}
        // navigation={true}
        onActiveIndexChange={(index) =>
          rest?.onActiveIndexChange && rest.onActiveIndexChange(index)
        }
      >
        {childrenArray.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>
      {/* <Box className="swiper-button">
        {slideActive && (
          <div
            className={`swiper-button-next ${className}`}
            onClick={handleNext}
          ></div>
        )}
      </Box> */}
    </Box>
  );
};

export const useResize = () => {
  const [deviceSize, setDeviceSize] = useState("");
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setDeviceSize("xs");
      } else if (width < 900) {
        setDeviceSize("sm");
      } else if (width < 1200) {
        setDeviceSize("md");
      } else if (width < 1536) {
        setDeviceSize("lg");
      } else {
        setDeviceSize("xl");
      }
    };

    handleResize(); // Initial call to set the value on component mount

    // Event listener to update the value on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { deviceSize };
};

export default SwiperCarousel;
