import {
  postAdventureBlock,
  deleteAdventureBlock,
  upsertItineraryItemBlock,
} from 'actions/adventure_actions';
import {
  postUserBlockCategory,
  fetchUserBlockActivity,
  postUserBlockActivity,
  fetchUserBlockActivityDetail,
  postUserBlockActivityDetail,
  fetchAllBlocksItinerary,
  deleteBlockFile,
  moveToAdventureBlock,
  copyToTripBoard,
  copyBlockAndAddToItineraryItems,
  fetchItineraryBlockSpectators,
  upsertItineraryBlockSpectator,
  deleteItineraryBlockSpectator,
} from 'actions/block_actions';

import { fetchItineraryItemsAndBlockNames } from 'actions/itinerary_actions';
import {
  postBlockComment,
  postAdventureBlockComment,
} from 'actions/comment_actions';
import {
  persistBlockModalData,
  updateActivityModalFeatureMapRid,
} from 'actions/cache_actions';
import { openBlockFileModal } from 'actions/modal_actions';
import { searchAirports } from 'actions/ui_actions';
export const mapStateToProps = ({ entities, session, ui, dataCache }) => {
  //data of curretn adventure where it's passed from
  let current_adventure = entities.adventure.current_adventure
    ? entities.adventure.current_adventure
    : null;
  // fetched items needed for block lables

  let user_block_categories = entities.blocks.user_block_categories
    ? entities.blocks.user_block_categories
    : null;
  let user_block_activites = entities.blocks.user_block_activities
    ? entities.blocks.user_block_activities
    : null;

  let user_block_activity_details = entities.blocks.user_block_activity_details
    ? entities.blocks.user_block_activity_details
    : null;
  // necessary infomration persisted for session
  let userInfo = session.user ? session.user.userInfo[0] : null;

  // fetched when viewing block votes, files, lists
  let blockVotes = entities.votes.all_block_votes
    ? entities.votes.all_block_votes
    : null;
  let blockComments = entities.comments.block_comments
    ? entities.comments.block_comments
    : null;

  // passed from modal reducers
  let openType = ui.modal.openType ? ui.modal.openType : null;
  let blockType = ui.modal.blockType ? ui.modal.blockType : null;
  let actionPayload = ui.modal.actionPayload ? ui.modal.actionPayload : null;
  let currentTab = ui.modal.currentTab ? ui.modal.currentTab : null;
  let triggerUserLocation = ui.modal.triggerUserLocation
    ? ui.modal.triggerUserLocation
    : null;

  //passed from modal data persisters
  let currentBlock = dataCache?.modal?.currentBlock;

  let currentBlockFiles = dataCache.modal.currentBlockFiles
    ? dataCache.modal.currentBlockFiles
    : null;

  let persistedModalData = dataCache.modal.savedBlockModalData
    ? dataCache.modal.savedBlockModalData
    : null;

  let isAdventureBlock = entities.blocks.isAdventureBlock
    ? entities.blocks.isAdventureBlock
    : null;
  let modalPayloads = dataCache?.modal?.actionPayload;
  const { blockPayload, activityModalTypeRid, uiUpdatePayload } =
    modalPayloads || {};

  // used for itinerary block spectators
  let adventureFriends = entities?.social?.adventureFriends;
  let itineraryBlockSpectators = entities?.blocks?.itineraryBlockSpectators;

  //used for adding to theitinerary
  let itineraryItemsAndBlockNames = ui?.modal?.itineraryItemsAndBlockNames;
  return {
    current_adventure,
    user_block_categories,
    user_block_activites,
    user_block_activity_details,

    userInfo,
    blockVotes,
    blockComments,

    openType,
    blockType,
    actionPayload,
    currentTab,
    triggerUserLocation,

    currentBlock,
    currentBlockFiles,
    persistedModalData,
    isAdventureBlock,
    // new for v2
    blockPayload,
    activityModalTypeRid,
    uiUpdatePayload,

    // itinerary block spectators
    adventureFriends,
    itineraryBlockSpectators,

    // used for adding to the itinerary
    itineraryItemsAndBlockNames,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    // fetchUserInspirations: (account_rid) => dispatch(fetchUserInspirations(account_rid)),

    openBlockFileModal: (editView, data) =>
      dispatch(openBlockFileModal(editView, data)),
    deleteBlockFile: (blockFileRid, blockRid) =>
      dispatch(deleteBlockFile(blockFileRid, blockRid)),
    postBlockComment: (data) => dispatch(postBlockComment(data)),
    deleteAdventureBlock: (adventure_block_rid, adventure_rid) =>
      dispatch(deleteAdventureBlock(adventure_block_rid, adventure_rid)),

    upsertItineraryItemBlock: (data, itineraryItemRid, userInfo, fetchType) =>
      dispatch(
        upsertItineraryItemBlock(data, itineraryItemRid, userInfo, fetchType),
      ),
    postAdventureBlockComment: (data) =>
      dispatch(postAdventureBlockComment(data)),

    persistBlockModalData: (openType, actionPayload) =>
      dispatch(persistBlockModalData(openType, actionPayload)),
    moveToAdventureBlock: (data, userInfo, returnType, actionPayload) =>
      dispatch(moveToAdventureBlock(data, userInfo, returnType, actionPayload)),

    copyToTripBoard: (data, userInfo, origin, returnActionPayload) =>
      dispatch(copyToTripBoard(data, userInfo, origin, returnActionPayload)),
    copyBlockAndAddToItineraryItems: (data, itineraryItems, func) =>
      dispatch(copyBlockAndAddToItineraryItems(data, itineraryItems, func)),
    updateActivityModalFeatureMapRid: (featureRid) =>
      dispatch(updateActivityModalFeatureMapRid(featureRid)),
    fetchItineraryBlockSpectators: (itinerary_block_rid) =>
      dispatch(fetchItineraryBlockSpectators(itinerary_block_rid)),
    upsertItineraryBlockSpectator: (data) =>
      dispatch(upsertItineraryBlockSpectator(data)),
    deleteItineraryBlockSpectator: (
      itinerary_block_spectator_rid,
      itinerary_block_rid,
    ) =>
      dispatch(
        deleteItineraryBlockSpectator(
          itinerary_block_spectator_rid,
          itinerary_block_rid,
        ),
      ),

    fetchItineraryItemsAndBlockNames: (itinerary_rid) =>
      dispatch(fetchItineraryItemsAndBlockNames(itinerary_rid)),
    searchAirports: (query) => dispatch(searchAirports(query)),
  };
};
