import React, { useState, useCallback } from 'react';
import {
  svgEdit,
  svgDelete,
  svgGroup,
  svgEye,
  svgCreate,
} from 'components/svg_module';
import styled from 'styled-components';
import { returnWidthDivisor } from '../../../util/helpers/lists_helper';
import { merge } from 'lodash';
import PriorityBox from './priority_box';
import { connect } from 'react-redux';
import { openAssignUsersModal } from '../../../actions/modal_actions';
import { ListButton } from './other_modules';
import { DateSelectPicker, DropDownSelect } from '../modal_modules';
import { fetchListDropDown } from '../../../actions/block_actions';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    openAssignUsersModal: (data, openType, returnActionPayload) =>
      dispatch(openAssignUsersModal(data, openType, returnActionPayload)),
    fetchListDropDown: (userInfo, adventureRid, blockRid) =>
      dispatch(fetchListDropDown(userInfo, adventureRid, blockRid)),
  };
};
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: default;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? '#F7B844' : 'rgba(255,255,255,0.5)'};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className} onClick={() => props.onChange()}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

const ModalList = ({
  block,
  highestLevel,
  editFunc,
  deleteFunc,
  addButtonHandler,
  currentBlock,
  upsertBlock,
  customDraggingStyling,
  userInfo,
  actionPayload,
  upsertBlockAssignment,
  openAssignUsersModal,
  deleteBlock,
  isDragging,
  cancelButtonHandler,
  providedDraggable,
  fetchListDropDown,
}) => {
  const [uiToggles, setUiToggles] = useState({
    isEditing: false,
    isOpen: false,
  });
  const {
    block_name,
    block_description,
    block_cost,
    block_count,
    block_cost_total,
    priority_name,
    priority_rid,
    parent_block_rid,
  } = block;

  const [activityLists, setActivityLists] = useState(null);
  const [childActivityLists, setChildActivityLists] = useState(null);
  const [childDropDownValue, setChildDropDownValue] = useState(null);
  const [parentDropDownValue, setParentDropDownValue] = useState(null);
  const handleChildrenDropdownSelect = useCallback((event) => {
    if (event.length > 0) {
      setInputValues({
        ...inputValues,
        parent_block_rid: event[0].block_rid,
      });
    } else {
      setInputValues({ ...inputValues, parent_block_rid: null });
    }
  });

  const [inputValues, setInputValues] = useState({
    block_name,
    block_description,
    block_cost: block_cost ? block_cost.replace('$', '') : null,
    block_count,
    block_cost_total,
    priority_rid: priority_rid ? `${priority_rid}` : null,
    list_flag: true,
    start_date: null,
    end_date: null,
    display_start_date: null,
    display_end_date: null,
    completed_flag: block.completed_flag ? block.completed_flag : false,
  });
  const [displayChildren, setDisplayChildren] = useState(false);
  const checkBoxOnChangeHanlder = () => {
    let blockType = {
      isAdventureListBlockCheck: currentBlock.adventure_block_rid
        ? true
        : false,
      isLibraryListBlockCheck: currentBlock.adventure_block_rid ? true : false,
      isListBlock: true,
    };
    let passObject = merge({}, block);
    let returnActionPayload = {
      parentBlockRid: currentBlock.block_rid,
      adventureBlockRid: currentBlock.adventure_block_rid,
    };
    passObject['completed_flag'] = !block.completed_flag;
    passObject['adventure_rid'] = actionPayload.adventureRid
      ? actionPayload.adventureRid
      : null;
    if (passObject.child_count > 0) {
      passObject.block_count = null;
      passObject.block_cost = null;
    }
    upsertBlock(userInfo, passObject, blockType, returnActionPayload);
  };

  let width = returnWidthDivisor(highestLevel, block.block_level);
  const handleOnChangeDate = useCallback((date) => {
    let dateFormatted = moment(date).format('MM-DD-yyyy');
    setInputValues({
      ...inputValues,
      start_date: dateFormatted,
      end_date: dateFormatted,
      display_start_date: date,
      display_end_date: date,
    });
  });
  const priorityClickHandler = (value) => {
    setInputValues({
      ...inputValues,
      priority_rid: inputValues.priority_rid === value ? null : value,
    });
  };
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  const deleteHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let blockType = {
      isListBlock: true,
      isNestedListBlock: block.block_level > 2 ? true : null,
    };
    let returnActionPayload = {
      parentBlockRid: currentBlock ? currentBlock.block_rid : null,
      adventureBlockRid:
        currentBlock && currentBlock.adventure_block_rid
          ? currentBlock.adventure_block_rid
          : null,
    };
    deleteBlock(block, userInfo, blockType, returnActionPayload);
  });

  const editHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    Promise.all([
      //this is for fetchin all child items for nesting
      fetchListDropDown(
        userInfo,
        currentBlock.adventure_block_rid ? actionPayload.adventureRid : null,
        currentBlock.block_rid,
      ),
      // this is for fetching all level 1 lists so it can be moved around to another list
      fetchListDropDown(
        userInfo,
        currentBlock.adventure_block_rid ? actionPayload.adventureRid : null,
        null,
      ),
    ]).then((res) => {
      setChildActivityLists(res[0].data.blockListDropDown);
      setActivityLists(res[1].data.blockListDropDown);
    });

    if (!uiToggles.isEditing) {
      setUiToggles({
        isEditing: true,
        isOpen: true,
      });
    } else if (uiToggles.isEditing) {
      setUiToggles({
        isEditing: true,
        // ...uiToggles,
        isEditing: false,
      });
    }
  });

  const assignHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { adventureRid, parentBlockRid } = actionPayload;
    let openType = {
      isListAssign: true,
    };
    let returnActionPayload = {
      adventureRid,
      parentBlockRid,
      adventureBlockRid: block.adventure_block_rid,
    };

    openAssignUsersModal(block, openType, returnActionPayload);
  });

  const viewHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setUiToggles({
      // ...uiToggles,
      isOpen: !uiToggles.isOpen,
    });
  });
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let blockType = {
      isListBlock: true,
    };
    let returnActionPayload = {
      parentBlockRid: currentBlock ? currentBlock.block_rid : null,
      adventureBlockRid:
        currentBlock && currentBlock.adventure_block_rid
          ? currentBlock.adventure_block_rid
          : null,
    };
    let passOver = merge(block, inputValues);
    if (passOver.block_count === '') passOver.block_count = null;
    if (passOver.block_cost === '') passOver.block_cost = null;
    upsertBlock(userInfo, passOver, blockType, returnActionPayload);
    setInputValues({
      block_name,
      block_description,
      block_cost: block_cost ? block_cost.replace('$', '') : null,
      block_count,
      block_cost_total,
      priority_rid: priority_rid ? `${priority_rid}` : null,
      list_flag: true,
    });
    // cancelButtonHandler();
    setUiToggles({
      // ...uiToggles,
      isOpen: false,
      isEditing: !uiToggles.isEditing,
    });
  });
  let listWrapperWidth =
    block.block_level === 2
      ? '99%'
      : `calc(99% - ${(block.block_level - 2) * 12}px)`;
  return (
    <>
      <div
        className="modal-list-wrapper-outer"
        ref={providedDraggable.innerRef}
        {...providedDraggable.draggableProps}
        {...providedDraggable.dragHandleProps}
      >
        <div
          className="block-list-wrapper"
          style={{
            width: listWrapperWidth,
          }}
        >
          <ListButton
            block={block}
            isOpen={displayChildren}
            handleClick={setDisplayChildren}
          />
          <div
            className="block-list-container"
            style={{ width: 'calc(100% - 20px)' }}
          >
            <div className="block-list--top">
              {uiToggles.isEditing ? (
                <>
                  <div className="block-modal-list--right--editing">
                    <span className="list-form-priority-text">Priority:</span>

                    <div
                      value={'3'}
                      className={`block-modal-list-scheduling-container ${
                        inputValues.priority_rid !== '3'
                          ? 'modal-list-priority-button'
                          : 'modal-list-priority-button modal-list-priority-button--selected'
                      }`}
                      onClick={() => priorityClickHandler('3')}
                    >
                      <div className="block-list block-list--high" />
                    </div>
                    <div
                      value={'2'}
                      className={`block-modal-list-scheduling-container ${
                        inputValues.priority_rid !== '2'
                          ? 'modal-list-priority-button'
                          : 'modal-list-priority-button modal-list-priority-button--selected'
                      }`}
                      onClick={() => priorityClickHandler('2')}
                    >
                      <div className="block-list block-list--medium" />
                      {/* <span>medium</span> */}
                    </div>
                    <div
                      value={'1'}
                      className={`block-modal-list-scheduling-container ${
                        inputValues.priority_rid !== '1'
                          ? 'modal-list-priority-button'
                          : 'modal-list-priority-button modal-list-priority-button--selected'
                      }`}
                      onClick={() => priorityClickHandler('1')}
                    >
                      <div className="block-list block-list--low" />
                      {/* <span>low</span> */}
                    </div>
                  </div>
                  <div className="modal-list-input-form-top-buttons-container">
                    <button
                      className="modal-list-input-form--buttons--cancel modal-list-input-form--buttons"
                      onClick={editHandler}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="modal-list-input-form--buttons--save modal-list-input-form--buttons"
                    >
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="block-list--top--left">
                    <PriorityBox
                      priorityName={priority_name}
                      priorityRid={priority_rid}
                    />

                    <span className="block-list--block-name">
                      {block.block_name}
                    </span>
                  </div>
                  <div className="block-list--top--right">
                    <button
                      className="block-list--buttons"
                      onClick={addButtonHandler}
                      value={block.block_rid}
                    >
                      {svgCreate(
                        'color',
                        'block-list-view-svg',
                        'block-list-view-svg-outerlayer',
                      )}
                    </button>
                    <button
                      className="block-list--buttons"
                      onClick={viewHandler}
                    >
                      {svgEye(
                        'color',
                        'block-list-view-svg',
                        'block-list-view-svg-outerlayer',
                      )}
                    </button>
                    <button
                      className="block-list--buttons"
                      onClick={editHandler}
                    >
                      {svgEdit(
                        'color',
                        'block-list-view-svg',
                        'block-list-view-svg-outerlayer',
                      )}
                    </button>
                    <button
                      className="block-list--buttons"
                      onClick={deleteHandler}
                    >
                      {svgDelete(
                        'color',
                        'block-list-view-svg',
                        'block-list-view-svg-outerlayer',
                      )}
                    </button>
                    {/* <button className="block-list--buttons" onClick={assignHandler}>
                {svgGroup(
                  "color",
                  "block-list-view-svg",
                  "block-list-view-svg-outerlayer"
                )}
              </button> */}
                  </div>
                </>
              )}
            </div>
            <div
              className={`block-list--middle ${
                uiToggles.isOpen && 'block-list--middle--open'
              } ${
                uiToggles.isEditing ? 'block-list--middle--is-editing' : 'null'
              }`}
            >
              <div
                className={`block-description-container ${
                  uiToggles.isOpen
                    ? 'block-description-open'
                    : 'block-description-closed'
                }'`}
              >
                {uiToggles.isOpen && uiToggles.isEditing && (
                  <div className="list-edit-inputs-container--block_name list-edit-inputs-container">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_name">
                      Name:
                    </span>
                    <input
                      type="text"
                      name="block_name"
                      value={inputValues.block_name}
                      onChange={handleOnChange}
                      className="list-edit-inputs list-edit-inputs--block_name"
                    />
                  </div>
                )}
                {uiToggles.isOpen && uiToggles.isEditing && (
                  <div className="list-edit-inputs-container--block_name list-edit-inputs-container">
                    <DropDownSelect
                      placeholder="Coming soon..."
                      className="modal-list-form-dropdown modal-list-form-dropdown--parentblocks"
                    />
                  </div>
                )}
                {uiToggles.isOpen && uiToggles.isEditing && (
                  <div className="list-edit-inputs-container--block_name list-edit-inputs-container">
                    <DropDownSelect
                      placeholder="Select a parent list.."
                      className="modal-list-form-dropdown modal-list-form-dropdown--childblocks"
                      parentClassName="modal-list-form-dropdown-parent modal-list-form-dropdown-parent--childblocks"
                      onChange={handleChildrenDropdownSelect}
                      options={childActivityLists}
                      labelField={'block_name'}
                      valueField={'block_rid'}
                    />
                  </div>
                )}
                {uiToggles.isOpen || uiToggles.isEditing ? (
                  <div className="list-edit-inputs-container--block_name list-edit-inputs-container">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_name">
                      {'Due date:'}
                    </span>
                    {uiToggles.isEditing ? (
                      <DateSelectPicker
                        value={inputValues.display_start_date}
                        onDateChange={handleOnChangeDate}
                        name="start_date"
                        showLabels={false}
                      />
                    ) : (
                      <span>
                        {block.end_date
                          ? moment(block.end_date).format('ddd MM yyyy')
                          : 'Not set'}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="list-edit-inputs-container--block_name list-edit-inputs-container">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_name">
                      {`Due date: ${
                        block.end_date
                          ? moment(block.end_date).format('ddd MM yyyy')
                          : 'Not set'
                      }`}
                    </span>
                  </div>
                )}
                {uiToggles.isOpen && uiToggles.isEditing && (
                  <div className="list-edit-inputs-container list-edit-inputs-container--block_description">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_description">
                      Description
                    </span>
                    <textarea
                      type="text"
                      name="block_description"
                      value={inputValues.block_description}
                      onChange={handleOnChange}
                      className="list-edit-inputs list-edit-inputs--block_description"
                    />
                  </div>
                )}
                {uiToggles.isOpen && !uiToggles.isEditing && (
                  <span>{block.block_description}</span>
                )}
              </div>
              <div className="block-people-assigned-container"></div>
            </div>
            <div
              className={`${
                uiToggles.isEditing && 'block-list--bottom--container--editing'
              } block-list--bottom`}
            >
              <div
                className={`${
                  uiToggles.isEditing && 'block-list--bottom--eiditing'
                } block-list--bottom--file-container`}
              ></div>

              <div
                className={`${
                  uiToggles.isEditing && 'block-list--bottom--eiditing'
                } block-list--bottom--count-container`}
              >
                {uiToggles.isOpen && uiToggles.isEditing ? (
                  <div className="list-edit-inputs-container list-edit-inputs-container--block_count">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_count">
                      count:
                    </span>
                    <input
                      type="number"
                      name="block_count"
                      value={inputValues.block_count}
                      onChange={handleOnChange}
                      className="list-edit-inputs list-edit-inputs--block_count"
                    />
                  </div>
                ) : (
                  <span>
                    count: <span>{block.block_count}</span>
                  </span>
                )}
              </div>
              <div
                className={`${
                  uiToggles.isEditing && 'block-list--bottom--eiditing'
                } block-list--bottom--cost-container`}
              >
                {uiToggles.isOpen && uiToggles.isEditing ? (
                  <div className="list-edit-inputs-container list-edit-inputs-container--block_cost ">
                    <span className="list-edit-inputs-indicator list-edit-inputs-indicator--block_cost">
                      cost:
                    </span>
                    <input
                      type="number"
                      name="block_cost"
                      value={inputValues.block_cost}
                      onChange={handleOnChange}
                      className="list-edit-inputs list-edit-inputs--block_cost"
                    />
                  </div>
                ) : (
                  <span>
                    cost: <span>{block.block_count}</span>
                  </span>
                )}
              </div>
              <div
                className={`${
                  uiToggles.isEditing && 'block-list--bottom--eiditing'
                } block-list--bottom--total-container`}
              >
                <span>
                  <span
                    className={`${
                      uiToggles.isEditing &&
                      'list-edit-inputs-indicator list-edit-inputs-indicator--block_total'
                    }`}
                  >
                    total:{' '}
                  </span>
                  {uiToggles.isEditing
                    ? Number.isNaN(
                        parseInt(inputValues.block_cost) *
                          parseInt(inputValues.block_count),
                      )
                      ? ''
                      : parseInt(inputValues.block_cost) *
                        parseInt(inputValues.block_count)
                    : block.block_cost_total}
                </span>
              </div>
              <div
                className={`${
                  uiToggles.isEditing && 'block-list--bottom--eiditing'
                } block-list--bottom--checkbox-container`}
              >
                {currentBlock && currentBlock.adventure_block_rid && (
                  <Checkbox
                    checked={block.completed_flag}
                    onChange={checkBoxOnChangeHanlder}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {displayChildren &&
        block.children.length > 0 &&
        block.children.map((item) => {
          return (
            <ModalList
              block={item}
              highestLevel={highestLevel}
              addButtonHandler={addButtonHandler}
              cancelButtonHandler={cancelButtonHandler}
              upsertBlock={upsertBlock}
              currentBlock={currentBlock}
              userInfo={userInfo}
              actionPayload={actionPayload}
              deleteBlock={deleteBlock}
              providedDraggable={providedDraggable}
            />
          );
        })}
    </>
  );
};
export default connect(null, mapDispatchToProps)(ModalList);
