import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import FloatingLabelInput from 'components/ui_library/floating_label_input';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../../public_home_page/modules/footer';
import passwordValidator from 'password-validator';
import { geolocated } from 'react-geolocated';
import { signup } from '../../../actions/session_actions';
import { sendBoardingUser } from '../../../actions/user_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import * as EmailValidator from 'email-validator';
import useMaskingHooks from '../../../util/custom_hooks/maskUrl';
import { fetchPlanFinalPrice } from '../../../actions/ui_actions';

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (user) => dispatch(signup(user)),
    fetchPlanFinalPrice: (data) => dispatch(fetchPlanFinalPrice(data)),
    sendBoardingUser: (user) => dispatch(sendBoardingUser(user)),
  };
};
// const SITE_KEY = "6Le2Z_IZAAAAADRCLs9ycHmbWHEBmNlbRW9Tjnx2"
const Homepage = (props) => {
  const { unmaskURL } = useMaskingHooks();
  let ambassador_code;
  let subscriptionType;
  let isAmbassadorInvite;
  let isPartner;
  let partnerCode;
  let isPromo;
  let promotionCode;
  //let isNewAmbassador;
  //let isInfluencer;
  const [ambassadorMessage, setAmbassadorMessage] = useState(null);
  if (!subscriptionType) {
    switch (props.match.params.type) {
      case 'freetrial':
        subscriptionType = 29;
        break;
      case 'monthly':
        subscriptionType = 20;
        break;
      case 'yearly':
        subscriptionType = 25;
        break;
      case 'partner':
        subscriptionType = 12;
        isPartner = true;
        partnerCode = unmaskURL(props.match.params.partnerCode).split('::')[1];
        break;
      case 'free':
        subscriptionType = 32;
        //isInfluencer = true;
        break;
      case 'ambassador':
        subscriptionType = 31;
        //isNewAmbassador = true;
        break;

      case 'promotion':
        let tail = props.match.params.promoCode.split('::');
        let term = tail[0];
        let maskedCode = tail[1];
        let unmasked = unmaskURL(maskedCode);
        subscriptionType = term === 'annual' ? 25 : 20;
        isPromo = true;
        promotionCode = unmasked;
        if (!ambassadorMessage) {
          axios
            .patch('/api/ui/plan/price', {
              plan_rid: subscriptionType,
              promotion_code_ambassador_code: promotionCode,
            })
            .then((res) => {
              // let newPlan = merge({}, planDataAndExplorer.plan);
              // newPlan.plan_price = res.data.planFinalPrice[0].final_price;
              const { promotion_description } = res.data.planFinalPrice[0];
              setAmbassadorMessage(promotion_description);
            });
        }
        break;
      default:
        ambassador_code = unmaskURL(
          props.match.params.type.split('ambCode:')[1],
        );
        isAmbassadorInvite = true;
        subscriptionType = 25;
        if (!ambassadorMessage) {
          axios
            .patch('/api/ui/plan/price', {
              plan_rid: subscriptionType,
              promotion_code_ambassador_code: ambassador_code,
            })
            .then((res) => {
              // let newPlan = merge({}, planDataAndExplorer.plan);
              // newPlan.plan_price = res.data.planFinalPrice[0].final_price;
              //const { promotion_description } = res.data.planFinalPrice[0];

              setAmbassadorMessage(true);
            });
        }
        break;
    }
  }

  const [plan, checkPlan] = useState('explorer');
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    verifyEmail: '',
    username: '',
    password: '',
    verifyPassword: '',
  });
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: [],
    emailErrors: '',
    passwordErrors: [],
  });

  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'sign-up' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  let schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(25) // Maximum length 25
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 number
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'password') {
      setInputValues({ ...inputValues, [name]: value });
      let errors = schema.validate(value, { list: true });
      setSignUpErrors({ ...signUpErrors, passwordErrors: errors });
    } else if (name === 'email') {
      setInputValues({ ...inputValues, [name]: value });
      let emailValidation = EmailValidator.validate(value);
      if (emailValidation === false) {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: 'Please enter a valid email',
        });
      } else {
        setSignUpErrors({ ...signUpErrors, emailErrors: '' });
        axios
          .patch('/api/e/verify_auth_email', { email: value })
          .then((res) => {
            setSignUpErrors({
              ...signUpErrors,
              emailErrors:
                res.data.message === 'Email valid' ? '' : res.data.message,
            });
          });
      }
    } else if (name === 'username') {
      setInputValues({ ...inputValues, [name]: value });
      if (value.length < 5) {
        setSignUpErrors({
          ...signUpErrors,
          usernameError: 'Username must be at least 5 characters',
        });
      } else {
        axios
          .patch('/api/e/verify_auth_username', { username: value })
          .then((res) => {
            setSignUpErrors({
              ...signUpErrors,
              usernameError: res.data.message,
            });
          });
      }
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  });

  const renderPasswordErrors = () => {
    if (
      inputValues.password.length > 0 ||
      inputValues.verifyPassword.length > 0
    ) {
      return (
        <div className="password-error-container sign-up-password-errors-wrapper">
          <div className="password-error-inner-wrapper">
            {signUpErrors.passwordErrors.includes('min') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('min')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password must be minimum 8 characters</span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('symbols') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('symbols')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>
                  Password must contain at least one symbol (i.e ! @ # $ % ^ & *
                  )
                </span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('max') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('max')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password must be maximum 25 characters</span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('uppercase') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('uppercase')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password must contain uppercase letters</span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('lowercase') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('lowercase')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password must contain lowercase letters</span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('digits') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('digits')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password must have at least 1 number</span>
              </div>
            )}
            {signUpErrors.passwordErrors.includes('spaces') && (
              <div className="email-error-wrapper">
                <img
                  alt="alert-pic"
                  src={
                    signUpErrors.passwordErrors.includes('spaces')
                      ? '/symbols/red-alert.png'
                      : '/symbols/bluecheck.png'
                  }
                />
                <span>Password cannot have spaces</span>
              </div>
            )}
            <div className="email-error-wrapper">
              <img
                alt="aler-pic"
                src={
                  inputValues.password === inputValues.verifyPassword
                    ? '/symbols/bluecheck.png'
                    : '/symbols/red-alert.png'
                }
              />
              <span>
                {inputValues.password === inputValues.verifyPassword
                  ? 'Passwords match'
                  : 'Passwords not matched'}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="password-error-container" />;
    }
  };
  const renderEmailErrors = () => {
    if (inputValues.email.length > 0) {
      return (
        <div className="password-error-container sign-up-email-errors-wrapper">
          <div className="email-error-container sign-up-email-error-container">
            {signUpErrors.emailErrors.length === 0 &&
              inputValues.email === inputValues.verifyEmail && (
                <div className="email-error-wrapper">
                  {/* <img alt="alert-pic" src="/symbols/bluecheck.png" />
                  <span>Valid Email</span> */}
                </div>
              )}
            {signUpErrors.emailErrors.length > 0 && (
              <div className="email-error-wrapper">
                <img alt="alert-pic" src={'/symbols/red-alert.png'} />
                <span>{signUpErrors.emailErrors}</span>
              </div>
            )}
            {inputValues.email.length > 0 &&
              signUpErrors.emailErrors.length === 0 &&
              inputValues.email !== inputValues.verifyEmail && (
                <div className="email-error-wrapper">
                  <img alt="alert-pic" src={'/symbols/red-alert.png'} />
                  <span>Email not matched</span>
                </div>
              )}
          </div>
        </div>
      );
    } else {
      // return (<div className="error-placeholder" />)
      return <div className="email-error-container" />;
    }
  };

  const renderUsernameErrors = () => {
    if (inputValues.username) {
      if (
        inputValues.username.length > 0 &&
        signUpErrors.usernameError !== 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container sign-up-username-error-container">
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.usernameError === 'Username already exists'
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else if (
        inputValues.username.length > 0 &&
        inputValues.username.length < 5 &&
        signUpErrors.usernameError === 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container sign-up-username-error-container">
            <div className="email-error-wrapper">
              <img alt="alert-pic" src="/symbols/red-alert.png" />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else {
      }
    } else {
      return <div className="email-error-container" />;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      inputValues.username === '' ||
      inputValues.password === '' ||
      inputValues.verifyPassword === ''
    ) {
      return window.alert('Please fill out all fields');
    }

    let user;
    user = {
      organization_rid: isPartner ? partnerCode : null,
      username: inputValues.username,
      password: inputValues.password,
      first_name: inputValues.firstName,
      last_name: inputValues.lastName,
      email: inputValues.email,
      plan_rid: parseInt(subscriptionType), // need to revisit for ambassador invited accounts
      promotion_code_ambassador_code: isAmbassadorInvite
        ? ambassador_code
        : null,
      auto_renew_flag:
        subscriptionType === 20 ||
        subscriptionType === 25 ||
        subscriptionType === 12
          ? true
          : false,
    };
    axios.get(`/api/ui/plans/1/${subscriptionType}`).then(({ data }) => {
      user.subscription_partner_code = null;
      props.signup(user).then((res2) => {
        if (res2.status === 201)
          if (props.match.params.type === 'freetrial') {
            //needs work for sequence
            let path = '/sign-up-success';

            const { subscription_rid, email } = res2.data.data;
            props.sendBoardingUser({
              subscription_rid,
              email,
            });
            props.history.push(path);
          } else if (props.match.params.type === 'partner') {
            //needs work for sequence
            let path = '/sign-up-success';
            const { subscription_rid, email } = res2.data.data;

            props.sendBoardingUser({
              subscription_rid,
              email,
            });
            props.history.push(path);
          } else if (props.match.params.type === 'ambassador') {
            let path = '/sign-up-success';
            const { subscription_rid, email } = res2.data.data;

            props.sendBoardingUser({
              subscription_rid,
              email,
            });
            props.history.push(path);
          } else if (props.match.params.type === 'free') {
            let path = '/sign-up-success';
            const { subscription_rid, email } = res2.data.data;

            props.sendBoardingUser({
              subscription_rid,
              email,
            });
            props.history.push(path);
          } else {
            let paymentPath = `/sign-up/checkout-subscription/${encodeURIComponent(
              subscriptionType,
            )}`;
            props.history.push(paymentPath, {
              plan: data.planDetails[0],
              account_info: res2.data.data,
              isAmbassadorInvite: isAmbassadorInvite ? true : false,
              ambassador_code: isAmbassadorInvite ? ambassador_code : null,
              isPromoted: isPromo ? true : false,
              promotionCode: promotionCode ? promotionCode : null,
            });
          }
      });
    });
  };

  const renderDisabledWithPlan = () => {
    if (
      inputValues.email === inputValues.verifyEmail &&
      signUpErrors.emailErrors === 'Email valid' &&
      inputValues.firstName.length > 0 &&
      inputValues.lastName.length > 0 &&
      signUpErrors.usernameError === 'Username valid' &&
      inputValues.password === inputValues.verifyPassword &&
      plan.length > 0 &&
      signUpErrors.passwordErrors.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  return (
    <div className="page-container sign-up-page-container">
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="signup-background-image-behind-content"
          style={{
            backgroundImage: `url(${ui_image_location})`,
          }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className="page-content-wrapper invited-sign-up-wrapper pwa-sign-up-wrapper"
        id="mobile-sign-up-wrapper"
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="signup-block-top hub-signup-size-adjustment">
            <>
              <div className="hub-sign-up-top" style={{ height: '30px' }} />

              <div className="newsletter-inputs">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="text"
                  onChange={handleOnChange}
                  autocomplete="first-name"
                  name="firstName"
                  value={inputValues.firstName}
                  label="First Name"
                />
              </div>
              <div className="newsletter-inputs">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="text"
                  onChange={handleOnChange}
                  autocomplete="last-name"
                  name="lastName"
                  value={inputValues.lastName}
                  label="Last Name"
                />
              </div>
              <div className="newsletter-inputs">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="text"
                  onChange={handleOnChange}
                  autocomplete="email"
                  name="email"
                  value={inputValues.email}
                  label="Email"
                />
              </div>
              <div className="newsletter-inputs">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="text"
                  onChange={handleOnChange}
                  autocomplete="verify-email"
                  name="verifyEmail"
                  value={inputValues.verifyEmail}
                  label="Verify Email"
                />
              </div>
              {renderEmailErrors()}
              <div
                className="input-divider"
                style={{
                  borderTop: '1px solid white',
                  width: '300px',
                  marginTop: '10px',
                }}
              ></div>

              <div className="newsletter-inputs ">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="text"
                  autocomplete="password"
                  onChange={handleOnChange}
                  name="username"
                  value={inputValues.username}
                  label="Username"
                />
              </div>
              {renderUsernameErrors()}
              <div className="newsletter-inputs">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="password"
                  autocomplete="off"
                  onChange={handleOnChange}
                  name="password"
                  value={inputValues.password}
                  label="Password"
                />
              </div>
              <div className="newsletter-inputs ">
                <FloatingLabelInput
                  className="floating-lode-input"
                  id="lode-float-input"
                  type="password"
                  autocomplete="off"
                  onChange={handleOnChange}
                  name="verifyPassword"
                  value={inputValues.verifyPassword}
                  label="Verify Password"
                />
              </div>
              <div
                className="input-divider"
                style={{
                  borderTop: '1px solid white',
                  width: '300px',
                  marginTop: '10px',
                }}
              ></div>
              {renderPasswordErrors()}
              {ambassadorMessage && (
                <div className="ambassador-code-message-wrapper">
                  <span style={{ textTransform: 'uppercase' }}>
                    {ambassadorMessage}
                  </span>
                </div>
              )}
              <button
                className="signup-page-signup"
                disabled={renderDisabledWithPlan}
              >
                <span>JOIN</span>
              </button>
            </>
          </div>
        </Form>
      </div>

      <Footer />
    </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
  geolocationProvider: navigator.geolocation,
})(connect(null, mapDispatchToProps)(withRouter(Homepage)));
