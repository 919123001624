export function bikePacking (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="89.9"
      height="53.518"
      viewBox="0, 0, 89.9, 53.518"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M29.7,12.718 L13.8,12.718 C12.1,12.718 10.5,11.618 10.2,9.918 C9.8,7.718 11.5,5.818 13.6,5.818 L27.4,5.818 C27.6,5.818 27.8,5.918 27.9,6.118 L30.1,12.118 C30.2,12.418 30,12.718 29.7,12.718 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M66,4.118 C66.9,3.718 67.9,3.518 68.9,3.818 C70.5,4.218 71.7,5.418 72.1,7.018 C72.7,9.818 70.6,12.318 67.9,12.318 C66,12.318 64.3,11.018 63.8,9.318 L62.6,6.118 C62.5,5.918 62.6,5.718 62.8,5.618 L66,4.118 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M71.7,16.918 C69.7,16.918 67.8,17.218 66.1,17.818 L61.4,5.218 L68.1,2.218 C68.7,1.918 69,1.218 68.7,0.718 C68.4,0.118 67.8,-0.182 67.2,0.118 L58.6,4.018 L60,7.818 L36.9,21.318 L30.9,5.118 L35.7,5.118 C36.3,5.118 36.9,4.618 36.9,3.918 C36.9,3.218 36.4,2.718 35.7,2.718 L26.4,2.718 C25.8,2.718 25.2,3.218 25.2,3.918 C25.2,4.618 25.7,5.118 26.4,5.118 L28.4,5.118 L34.9,22.518 L32.5,23.918 C29.2,19.618 24,16.918 18.2,16.918 C8.1,16.918 0,25.118 0,35.118 C0,45.218 8.2,53.318 18.2,53.318 C27.1,53.318 34.5,46.918 36.1,38.518 L38.7,38.818 C39.1,40.318 40.4,41.518 42,41.518 C43.9,41.518 45.4,40.018 45.4,38.118 C45.4,37.318 45.1,36.618 44.7,36.118 L60.9,10.918 L63.9,18.818 C57.7,21.718 53.5,28.018 53.5,35.318 C53.5,45.418 61.7,53.518 71.7,53.518 C81.8,53.518 89.9,45.318 89.9,35.318 C89.9,25.118 81.7,16.918 71.7,16.918 z M18.1,49.918 C10,49.918 3.4,43.318 3.4,35.218 C3.4,27.118 10,20.518 18.1,20.518 C22.6,20.518 26.7,22.618 29.4,25.818 L20,31.418 C19.4,31.118 18.8,30.918 18.1,30.918 C15.7,30.918 13.8,32.818 13.8,35.218 C13.8,37.618 15.7,39.518 18.1,39.518 C19.8,39.518 21.3,38.518 22,37.118 L32.6,38.318 C31.2,44.918 25.2,49.918 18.1,49.918 z M32.9,35.218 C32.9,35.418 32.9,35.718 32.9,35.918 L22.5,34.718 C22.4,34.118 22.2,33.518 21.9,32.918 L30.9,27.618 C32.1,29.818 32.9,32.418 32.9,35.218 z M38.9,36.618 L36.3,36.318 C36.3,35.918 36.4,35.618 36.4,35.218 C36.4,31.818 35.4,28.618 33.8,25.918 L35.7,24.718 L39.7,35.518 C39.4,35.818 39.1,36.118 38.9,36.618 z M57.5,11.818 L42.8,34.718 C42.5,34.618 42.3,34.618 42,34.618 C42,34.618 42,34.618 42,34.618 L37.8,23.518 L57.5,11.818 z M71.7,49.918 C63.6,49.918 57,43.318 57,35.218 C57,29.418 60.3,24.418 65.2,22.018 L68.8,31.718 C67.7,32.518 67,33.718 67,35.218 C67,37.618 68.9,39.518 71.3,39.518 C73.7,39.518 75.6,37.618 75.6,35.218 C75.6,32.818 73.7,30.918 71.3,30.918 C71.2,30.918 71.1,30.918 70.9,30.918 L67.2,21.118 C68.6,20.718 70.1,20.418 71.6,20.418 C79.7,20.418 86.3,27.018 86.3,35.118 C86.4,43.318 79.8,49.918 71.7,49.918 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M34.6,12.618 C34.5,12.418 34.7,12.118 34.9,12.018 C35.5,11.918 36.2,11.818 36.8,11.818 C39.7,11.818 42.4,13.118 44.2,15.318 C44.4,15.518 44.3,15.818 44.1,15.918 L37.8,19.618 C37.6,19.718 37.3,19.618 37.2,19.418 L34.6,12.618 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M39.2,23.718 L53.5,15.218 C53.8,15.018 54.1,15.418 53.9,15.618 L43,32.718 C42.8,33.018 42.3,33.018 42.2,32.618 L39.1,24.218 C39,24.018 39.1,23.818 39.2,23.718 z"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
}