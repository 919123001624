import { useState, useCallback } from 'react';
const StartingPoints = ({
  userAddressBookBlocks,
  selectedBlock,
  handleSelectStartingPoint,
  ...props
}) => {
  const [displayMap, setDisplayMap] = useState(null);
  const handleDisplayMap = useCallback((e) => {
    e.preventDefault();
    e.stopProagation();
  });

  return (
    <>
      <div className="color-separator-bar">WHERE WILL YOU START FROM?</div>
      <div className="accept-trip-invite-section-contents">
        <div className="accept-trip-invite-starting-point-contents-wrapper">
          {userAddressBookBlocks &&
            userAddressBookBlocks.length > 0 &&
            userAddressBookBlocks.map((block, idx) => {
              if (block.location_point && block.location_point.lat)
                return (
                  <AddressBlock
                    block={block}
                    handleSelectStartingPoint={handleSelectStartingPoint}
                    selectedBlock={selectedBlock}
                  />
                );
            })}
          <button className="accept-trip-invite-add-locatin-block-button">
            ADD NEW
          </button>
        </div>
      </div>
    </>
  );
};

const AddressBlock = ({ block, selectedBlock, handleSelectStartingPoint }) => {
  const { block_name, block_rid, location_point } = block;
  return (
    <button
      className={`address-block-wrapper ${
        `${block_rid}` === selectedBlock ? 'selected' : ''
      }`}
      value={block_rid}
      onClick={handleSelectStartingPoint}
    >
      <div className="address-block-title">{block_name}</div>
      <div className="address-block-address">
        {location_point.place_name_en_us}
      </div>
    </button>
  );
};
export default StartingPoints;
