export function travelDocument (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="7.5 7.5 50 50"
      className={outerLayer}
    >
      <path
        className={className}
        d="M29.134,37.208c0.019,1.03,0.09,2.001,0.208,2.899c1.011-0.116,2.069-0.185,3.158-0.185c1.09,0,2.147,0.068,3.158,0.185  c0.117-0.898,0.189-1.869,0.207-2.899H29.134z"
      ></path>
      <path
        className={className}
        d="M32.5,40.923c-1.04,0-2.047,0.063-3.006,0.172c0.603,3.404,1.851,5.551,3.006,5.551c1.156,0,2.404-2.146,3.007-5.551  C34.547,40.986,33.54,40.923,32.5,40.923z"
      ></path>
      <path
        className={className}
        d="M28.134,37.208h-5.546c0.079,1.581,0.522,3.066,1.259,4.369c1.176-0.599,2.727-1.057,4.498-1.337  C28.223,39.289,28.152,38.272,28.134,37.208z"
      ></path>
      <path
        className={className}
        d="M36.865,37.208c-0.018,1.064-0.088,2.081-0.209,3.032c1.771,0.28,3.322,0.738,4.497,1.337  c0.736-1.303,1.18-2.788,1.259-4.369H36.865z"
      ></path>
      <path
        className={className}
        d="M36.509,32.189c1.62-0.255,3.033-0.665,4.106-1.197c-1.42-2.01-3.561-3.474-6.048-4.002  C35.438,28.104,36.119,29.923,36.509,32.189z"
      ></path>
      <path
        className={className}
        d="M36.865,36.208h5.547c-0.079-1.581-0.522-3.065-1.259-4.369c-1.176,0.599-2.726,1.058-4.497,1.337  C36.777,34.127,36.848,35.144,36.865,36.208z"
      ></path>
      <polygon points="40.7,8.932 18.601,16.348 40.7,16.348 "></polygon>
      <path
        className={className}
        d="M28.134,36.208c0.018-1.064,0.088-2.081,0.21-3.032c-1.771-0.279-3.322-0.738-4.498-1.337  c-0.736,1.303-1.18,2.789-1.259,4.37H28.134z"
      ></path>
      <path
        className={className}
        d="M36.509,41.228c-0.391,2.266-1.071,4.084-1.941,5.198c2.486-0.528,4.628-1.991,6.048-4.001  C39.542,41.892,38.129,41.482,36.509,41.228z"
      ></path>
      <path
        className={className}
        d="M48.961,17.348H16.039v38.721h32.922V17.348z M32.5,47.646c-6.031,0-10.938-4.906-10.938-10.938  c0-6.031,4.907-10.938,10.938-10.938c6.031,0,10.938,4.907,10.938,10.938C43.438,42.739,38.531,47.646,32.5,47.646z"
      ></path>
      <path
        className={className}
        d="M28.492,41.228c-1.62,0.255-3.033,0.665-4.106,1.197c1.42,2.01,3.561,3.473,6.047,4.001  C29.562,45.312,28.881,43.493,28.492,41.228z"
      ></path>
      <path
        className={className}
        d="M32.5,32.494c1.04,0,2.047-0.063,3.007-0.172c-0.603-3.405-1.851-5.552-3.007-5.552c-1.156,0-2.404,2.147-3.007,5.552  C30.453,32.431,31.46,32.494,32.5,32.494z"
      ></path>
      <path
        className={className}
        d="M28.491,32.189c0.39-2.266,1.071-4.084,1.941-5.199c-2.487,0.528-4.628,1.992-6.047,4.001  C25.458,31.524,26.872,31.934,28.491,32.189z"
      ></path>
      <path
        className={className}
        d="M35.865,36.208c-0.018-1.03-0.09-2-0.207-2.899c-1.011,0.117-2.068,0.186-3.158,0.186c-1.089,0-2.147-0.068-3.158-0.186  c-0.117,0.899-0.189,1.869-0.208,2.899H35.865z"
      ></path>
    </svg>
  );
}