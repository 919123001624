
export const svgSwap = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="68.571"
      height="68.571"
      viewBox="0, 0, 68.571, 68.571"
      className={outerLayer}
    >
      <g id="Layer_1">
        <g>
          <path
            className={className}
            d="M18.479,36.543 C8.29,36.543 0,28.347 0,18.271 C0,8.196 8.29,0 18.479,0 C28.669,0 36.959,8.196 36.959,18.271 C36.959,28.347 28.669,36.543 18.479,36.543 z M18.479,2.857 C9.865,2.857 2.857,9.773 2.857,18.271 C2.857,26.77 9.865,33.686 18.479,33.686 C27.094,33.686 34.102,26.77 34.102,18.271 C34.102,9.773 27.094,2.857 18.479,2.857 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M18.299,18.497 C14.656,18.497 11.693,15.562 11.693,11.955 C11.693,8.347 14.656,5.412 18.299,5.412 C21.941,5.412 24.905,8.347 24.905,11.955 C24.905,15.562 21.941,18.497 18.299,18.497 z M18.299,8.269 C16.232,8.269 14.55,9.922 14.55,11.955 C14.55,13.987 16.232,15.64 18.299,15.64 C20.366,15.64 22.048,13.987 22.048,11.955 C22.048,9.922 20.366,8.269 18.299,8.269 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M5.937,31.131 C5.834,31.131 5.729,31.12 5.624,31.097 C4.854,30.925 4.369,30.162 4.541,29.392 C5.81,23.715 11.712,19.595 18.575,19.595 C25.63,19.595 31.193,23.278 32.418,28.76 C32.59,29.53 32.106,30.293 31.336,30.465 C30.563,30.638 29.803,30.153 29.63,29.382 C28.56,24.595 23.306,22.453 18.575,22.453 C13.128,22.453 8.293,25.703 7.33,30.014 C7.181,30.679 6.591,31.131 5.937,31.131 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M18.299,18.497 C14.656,18.497 11.693,15.562 11.693,11.955 C11.693,8.347 14.656,5.412 18.299,5.412 C21.941,5.412 24.905,8.347 24.905,11.955 C24.905,15.562 21.941,18.497 18.299,18.497 z M18.299,8.269 C16.232,8.269 14.55,9.922 14.55,11.955 C14.55,13.987 16.232,15.64 18.299,15.64 C20.366,15.64 22.048,13.987 22.048,11.955 C22.048,9.922 20.366,8.269 18.299,8.269 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M5.937,31.131 C5.834,31.131 5.729,31.12 5.624,31.097 C4.854,30.925 4.369,30.162 4.541,29.392 C5.81,23.715 11.712,19.595 18.575,19.595 C25.63,19.595 31.193,23.278 32.418,28.76 C32.59,29.53 32.106,30.293 31.336,30.465 C30.563,30.638 29.803,30.153 29.63,29.382 C28.56,24.595 23.306,22.453 18.575,22.453 C13.128,22.453 8.293,25.703 7.33,30.014 C7.181,30.679 6.591,31.131 5.937,31.131 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M50.092,68.571 C39.902,68.571 31.612,60.375 31.612,50.3 C31.612,40.225 39.902,32.028 50.092,32.028 C60.282,32.028 68.571,40.225 68.571,50.3 C68.571,60.375 60.282,68.571 50.092,68.571 z M50.092,34.886 C41.477,34.886 34.469,41.801 34.469,50.3 C34.469,58.799 41.477,65.714 50.092,65.714 C58.706,65.714 65.714,58.799 65.714,50.3 C65.714,41.801 58.706,34.886 50.092,34.886 z"
            fill="#000000"
          />
          <g>
            <path
              className={className}
              d="M49.921,51.222 C46.436,51.222 43.601,48.414 43.601,44.961 C43.601,41.508 46.436,38.7 49.921,38.7 C53.406,38.7 56.242,41.508 56.242,44.961 C56.242,48.414 53.406,51.222 49.921,51.222 z M49.921,41.557 C48.011,41.557 46.458,43.085 46.458,44.961 C46.458,46.837 48.011,48.365 49.921,48.365 C51.831,48.365 53.384,46.837 53.384,44.961 C53.384,43.085 51.831,41.557 49.921,41.557 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M38.24,63.16 C38.137,63.16 38.032,63.149 37.927,63.125 C37.157,62.953 36.673,62.19 36.844,61.42 C38.051,56.021 43.66,52.102 50.182,52.102 C56.886,52.102 62.174,55.607 63.339,60.823 C63.511,61.593 63.026,62.356 62.257,62.528 C61.484,62.705 60.722,62.215 60.55,61.445 C59.549,56.966 54.621,54.96 50.182,54.96 C45.07,54.96 40.535,58.005 39.633,62.042 C39.485,62.708 38.894,63.16 38.24,63.16 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M49.921,51.222 C46.436,51.222 43.601,48.414 43.601,44.961 C43.601,41.508 46.436,38.7 49.921,38.7 C53.406,38.7 56.242,41.508 56.242,44.961 C56.242,48.414 53.406,51.222 49.921,51.222 z M49.921,41.557 C48.011,41.557 46.458,43.085 46.458,44.961 C46.458,46.837 48.011,48.365 49.921,48.365 C51.831,48.365 53.384,46.837 53.384,44.961 C53.384,43.085 51.831,41.557 49.921,41.557 z"
              fill="#000000"
            />
            <path
              className={className}
              d="M38.24,63.16 C38.137,63.16 38.032,63.149 37.927,63.125 C37.157,62.953 36.673,62.19 36.844,61.42 C38.051,56.021 43.66,52.102 50.182,52.102 C56.886,52.102 62.174,55.607 63.339,60.823 C63.511,61.593 63.026,62.356 62.257,62.528 C61.484,62.705 60.722,62.215 60.55,61.445 C59.549,56.966 54.621,54.96 50.182,54.96 C45.07,54.96 40.535,58.005 39.633,62.042 C39.485,62.708 38.894,63.16 38.24,63.16 z"
              fill="#000000"
            />
          </g>
          <path
            className={className}
            d="M62.569,28.28 C61.78,28.28 61.14,27.641 61.14,26.851 L61.14,16.212 C61.14,13.658 59.028,11.579 56.432,11.579 L41.726,11.579 C40.937,11.579 40.297,10.94 40.297,10.151 C40.297,9.361 40.937,8.722 41.726,8.722 L56.432,8.722 C60.604,8.722 63.998,12.083 63.998,16.212 L63.998,26.851 C63.998,27.641 63.358,28.28 62.569,28.28 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M49.715,19.471 C49.353,19.471 48.99,19.335 48.712,19.06 L40.838,11.282 C40.566,11.013 40.413,10.647 40.413,10.265 C40.413,9.883 40.566,9.517 40.838,9.249 L48.712,1.47 C49.272,0.914 50.176,0.922 50.732,1.482 C51.286,2.044 51.281,2.948 50.719,3.502 L43.874,10.265 L50.719,17.026 C51.281,17.581 51.286,18.485 50.732,19.047 C50.452,19.329 50.084,19.471 49.715,19.471 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M28.756,56.989 L14.051,56.989 C9.879,56.989 6.484,53.629 6.484,49.499 L6.484,38.862 C6.484,38.072 7.124,37.433 7.913,37.433 C8.702,37.433 9.342,38.072 9.342,38.862 L9.342,49.499 C9.342,52.054 11.454,54.132 14.051,54.132 L28.756,54.132 C29.545,54.132 30.185,54.771 30.185,55.561 C30.185,56.35 29.545,56.989 28.756,56.989 z"
            fill="#000000"
          />
          <path
            className={className}
            d="M20.767,64.654 C20.398,64.654 20.03,64.512 19.75,64.23 C19.196,63.668 19.201,62.764 19.763,62.21 L26.608,55.446 L19.763,48.686 C19.201,48.131 19.196,47.227 19.75,46.664 C20.306,46.105 21.21,46.098 21.77,46.652 L29.644,54.429 C29.916,54.699 30.069,55.064 30.069,55.446 C30.069,55.829 29.916,56.194 29.644,56.462 L21.77,64.241 C21.492,64.517 21.129,64.654 20.767,64.654 z"
            fill="#000000"
          />
        </g>
      </g>
    </svg>
  );
};
