import { useQuery } from '@tanstack/react-query';
import useUserInfo from 'features/user/hooks/use-user-info';
import { fetchAdventureFriend } from 'util/api_util/social_api_util';
import useCurrentAdventure from './use-current-adventure';

const useAdventureAttendees = () => {
  const { explorer_rid } = useUserInfo();
  const { adventure_rid } = useCurrentAdventure();
  if (!adventure_rid) {
    throw new Error(
      'useAdventureAttendees must be used with selectedAdventure state',
    );
  }

  return useQuery({
    queryKey: [explorer_rid, 'adventure', adventure_rid, 'attendees'],
    queryFn: () =>
      fetchAdventureFriend(adventure_rid, 1, 100).then(
        (res) => res.data.userAdventureFriends,
      ),
  });
};

export default useAdventureAttendees;
