import axios from 'axios';

export const fetchPageComponentData = (page_rid, component_rid) => {
  return axios.patch(`/api/ui/fetch_page_content/${page_rid}/${component_rid}`);
};

export const fetchUiTools = (ui_tool_type_rid) => {
  return axios.patch(`/api/ui/fetch-ui-tool/${ui_tool_type_rid}`);
};

export const fetchAccountRoles = () => {
  return axios.get(`/api/e/account_roles`);
};
export const fetchCountries = () => {
  return axios.get('/api/e/countries');
};
export const fetchStates = () => {
  return axios.get('/api/e/states');
};

export const fetchPlanDetails = (plan_type_rid, plan_rid) => {
  return axios.get(`/api/ui/plans/${plan_type_rid}/${plan_rid}`);
};

export const fetchPlanFinalPirce = (data) => {
  return axios.patch(`/api/ui/plan/price`, data);
};

export const fetchUnsubscriptionReasons = () => {
  return axios.get('/api/ui/unsubscription_reasons');
};

export const fetchAmbassadorCodes = () => {
  return axios.get('/api/ui/ambassador_codes');
};

export const fetchUiPromotionBanner = (explorer_rid) => {
  return axios.get(`/api/ui/banner/${explorer_rid}`);
};

export const fetchPageContents = (
  ui_page_rid,
  ui_component_rid,
  explorer_rid,
) => {
  return axios.get(
    `/api/ui/page-content/${ui_page_rid}/${ui_component_rid}/${explorer_rid}`,
  );
};

export const sendEmail = (data) => {
  return axios.post('/api/email/send', data);
};

export const logUiErrors = () => {
  return axios.post(`/api/ui/errors`);
};

export const fetchPartnerPromotionCode = () => {
  return axios.get('/api/ui/partner-promotion-code');
};

export const fetchPartners = (
  partner_rid,
  partner_status_rid,
  adventure_rid,
) => {
  return axios.get(
    `/api/ui/partners/${partner_rid}/${partner_status_rid}/${adventure_rid}`,
  );
};

export const fetchTransportationTypes = () => {
  return axios.get('/api/ui/transportation-types');
};

export const fetchSocialCostOfCarbon = () => {
  return axios.get('/api/ui/impact-cost');
};

export const searchAirports = (query) => {
  return axios.get(`/api/ui/airports/${query}`);
};
