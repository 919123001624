import merge from 'lodash/merge';
import { RECEIVE_RESOURCES, RECEIVE_RESOURCE, REMOVE_RESOURCE, RECEIVE_RESOURCE_FILES, RECEIVE_RESOURCE_FILE, REMOVE_RESOURCE_FILE } from '../../constants';
const ResourcesReducer = (state = { resources: [] }, action) => {
    Object.freeze(state);
    let newState = merge({}, state);
    switch (action.type) {
        // case RECEIVE_ADVENTURE_DATA:
        //     debugger
        //     break;
        case RECEIVE_RESOURCE:
            // debugger
            break;
        case RECEIVE_RESOURCES:
            newState['currentAdventureResources'] = action.data
            return newState;

        case RECEIVE_RESOURCE_FILE:
            // debugger
            break;
        case RECEIVE_RESOURCE_FILES:
            // debugger
            break;
        case REMOVE_RESOURCE_FILE:
            // debugger
            break;
        case REMOVE_RESOURCE:
            // debugger
            break;
        // case RECEIVE_ADVENTURE_DATA:
        //     break
        default:
            return state;
    }
}
export default ResourcesReducer;