import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from 'components/modules/footer';
import { login } from '../../../actions/session_actions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import FloatingLabelInput from 'components/ui_library/floating_label_input';
const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(login(user)),
  };
};
const Homepage = (props) => {
  const [inputValues, setInputValues] = useState({
    username: '',
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const [pageInfos, setPageInfos] = useState({
    metaData: '',
    keywords: '',
    title: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data', { pageName: 'request-password-reset' })
      .then((res) => {
        let title = res.data.metaData[0];
        let keywords = res.data.metaData[1];
        let metaData = res.data.metaData[2];
        setPageInfos({ metaData, keywords, title });
      });
    scrollTop();
  }, []);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  const [transitionMsg, setTransitionMsg] = useState('');

  const submitHanlder = async (e) => {
    e.preventDefault();
    let user = { email: inputValues.username };
    await axios.patch('/api/e/send_reset_password', user).then((res) => {
      if (res.data.status === false) {
        return setErrorMessage(res.data.message);
      } else {
        setTransitionMsg(
          `An email has been sent to ${inputValues.username}, please check your inbox. You will be redirected to the home page in 10 seconds.`,
        );
        setTimeout(() => {
          props.history.push('/');
        }, 10000);
      }
    });

    // window.alert(`An email has been sent to ${inputValues.username}, please check your inbox.`)
  };
  return (
    <div className="page-container">
      <Helmet>
        <title>{pageInfos.title ? pageInfos.title : 'Loading'}</title>
        <meta
          name="description"
          content={pageInfos.metaData ? pageInfos.metaData : 'Loading'}
        />
        <meta
          name="keywords"
          content={pageInfos.keywords ? pageInfos.keywords : 'Loading'}
        />
      </Helmet>
      <div
        className="page-content-wrapper"
        style={{ justifyContent: 'center' }}
      >
        <img
          alt="request-pw-pics"
          src="/banner_images/lodestar_help_banner.jpeg"
          className="login-page-background-image"
        />
        <form
          id="log-in-form"
          onSubmit={(e) => {
            submitHanlder(e);
          }}
        >
          <div className="log-in-block" id="log-in-block-mobile">
            <div className="newsletter-inputs login-input-dist">
              <FloatingLabelInput
                className="floating-lode-input"
                id="lode-float-input"
                type="text"
                onChange={handleOnChange}
                name="username"
                value={inputValues.username}
                label="Username or Email"
              />
            </div>
            {transitionMsg ? (
              <span
                style={{
                  margin: '0 auto',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <br />
                {transitionMsg}
              </span>
            ) : (
              <div />
            )}
            {errorMessage ? (
              <span
                style={{
                  margin: '0 auto',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <br />
                {errorMessage}
              </span>
            ) : (
              <div />
            )}
            <button
              className="forgot-login"
              type="submit"
              disabled={inputValues.username === ''}
            >
              <span>RESET PASSWORD</span>
            </button>
            <br />
            <div className="reset-password-undertext">
              <span>
                Still having trouble?
                <br /> Send us a message at{' '}
                <a
                  href="mailto:support@lodestarhub.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="email-reset-under"
                >
                  support@lodestarhub.com
                </a>
              </span>
              .
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Homepage);
