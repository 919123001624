/*
functions for search:
 (optional) - search all > al
 - search blocks > onClick > ?
 - search friends > onClick > go to friend profile
 - search inspiration > onClick > go to inspiration_selected
 - search adventure > onClick > go to selected adventure_selected
 - search share > onClick > ?

*/

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { Title } from '../../modal_modules';
import { svgCalendar } from 'components/svg_module';
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
const ImageOp = ({ closeModal }) => {
  const [zPos, setzPos] = useState(101);
  const [xPos, setxPos] = useState(50);
  const [yPos, setyPos] = useState(50);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseStartX, setMouseStartX] = useState(0);
  const [mouseStartY, setMouseStartY] = useState(0);
  const SPEED = 10;
  const imgStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: `auto ${zPos}%`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `${xPos}% ${yPos}%`,
  };
  const log = () => {};
  const dragEvent = (e) => {
    if (document.selection) {
      document.selection.empty();
    } else {
      window.getSelection().removeAllRanges();
    }
    if (e.clientX != 0 && e.clientY != 0) {
      if (xPos >= 0 && xPos <= 100) {
        setxPos(xPos + (mouseStartX - e.clientX) / SPEED);
      }
      if (yPos >= 0 && yPos <= 100) {
        setyPos(yPos + (mouseStartY - e.clientY) / SPEED);
      }
      if (xPos > 100) setxPos(99.99);
      if (xPos < 0) setxPos(0.01);
      if (yPos > 100) setyPos(99.99);
      if (yPos < 0) setyPos(0.1);
    }

    setMouseStartX(e.clientX);
    setMouseStartY(e.clientY);
  };
  const setMousePos = (e) => {
    console.log(e);
    setMouseStartX(e.clientX);
    setMouseStartY(e.clientY);
  };

  return (
    // remove 4 squares here
    <div
      className="mod-type1-wrapper"
      style={{ height: '600px', width: '700px' }}
    >
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="RESIZE IMAGE" />
        <div
          className="mod-cnt-def"
          onMouseMove={(e) => {
            if (mouseDown) dragEvent(e);
          }}
          onMouseUp={() => setMouseDown(false)}
          //onMouseLeave={() => setMouseDown(false)}
        >
          <div
            draggable="false"
            onMouseDown={(e) => {
              setMousePos(e);
              setMouseDown(true);
            }}
            className="image-box-container-border"
            style={imgStyle}
          ></div>
          <div className="image-box-container"></div>
          <button onClick={() => setzPos(zPos + 100)}>Zoom In</button>
          <button
            onClick={() => {
              if (zPos > 101) {
                setzPos(zPos - 100);
              }
            }}
          >
            Zoom Out
          </button>
          <input
            type="range"
            min="100"
            max="500"
            value={zPos}
            className="slider"
            onChange={({ target: { value: radius } }) => {
              setzPos(radius);
            }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(ImageOp);
