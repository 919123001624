import React, { useState } from 'react';
import {
  Input,
  TextArea,
  Button,
  DropZone,
  Title,
  Tab,
  ProfileCircle,
} from '../../modal_modules';
import { connect } from 'react-redux';
import { upsertBlockAssignment } from 'actions/block_actions';
import { withRouter } from 'react-router-dom';
import MembersList from './render_member';
const mapDispatchToProps = (dispatch) => {
  return {
    upsertBlockAssignment: (data) => dispatch(upsertBlockAssignment(data)),
  };
};
const mapStateToProps = ({ entities, ui, dataCache, session }) => {
  let usersAssigned = entities.blocks.usersAssignedToListItem
    ? entities.blocks.usersAssignedToListItem.usersAssigned
    : null;
  let adventureFriends = entities.social.adventureFriends
    ? entities.social.adventureFriends
    : null;
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let currentList = ui.modal.currentList ? ui.modal.currentList : null;
  return { usersAssigned, adventureFriends, userInfo, currentList };
};
const AssignUsers = ({
  adventureBlockRid,
  closeModal,
  usersAssigned,
  adventureFriends,
  currentList,
  ...props
}) => {
  const [topLabel, setTopLabel] = useState('MEMBERS');
  // let locationParsed = props.history.location.pathname.split("/")
  // let adventureRid = locationParsed[locationParsed.length - 2]
  return (
    <div className="mod-type1-wrapper">
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="ASSIGN-USERS" />
        <div className="mod-cnt-def">
          <div className="assign-users--top">
            <div className="current-list-info--container">
              <span className="current-list-info--name">
                {currentList.block_name}
              </span>
              <span className="current-list-info--description">
                {currentList.block_description}
              </span>
            </div>
            <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'MEMBERS'}
                text="ASSIGNED MEMBERS"
              />
            </div>
            <div className="assign-users--contents--wrapper">
              {usersAssigned
                ? usersAssigned.map((user, idx) => (
                    <ProfileCircle profile={user} />
                  ))
                : null}
            </div>
          </div>
          <form>
            <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'MEMBERS'}
                text="MEMBERS"
              />
            </div>
            <div className="mod-inv-friends-contents--wrapper">
              <MembersList friends={adventureFriends} />
            </div>

            <Button spacingBottom="20px" spacingTop="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssignUsers),
);
