export function bonfire1 (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path
          fill={color}
          className={className}
          enableBackground="new 0 0 100 100"
          d="M67.553,59.07l4.492-2.561c1.756-1.002,3.018-2.624,3.551-4.572c0.532-1.947,0.275-3.986-0.726-5.742  c-1-1.754-2.624-3.015-4.571-3.548c-0.994-0.272-2.014-0.336-3.007-0.202c1.229,2.98,2.013,5.938,2.013,8.635  C69.305,53.933,68.667,56.633,67.553,59.07z M27.955,56.51l4.493,2.561c-1.115-2.438-1.753-5.138-1.753-7.988  c0-2.699,0.783-5.655,2.014-8.635c-0.992-0.135-2.012-0.072-3.008,0.201c-1.947,0.533-3.571,1.793-4.572,3.549  c-1,1.754-1.257,3.793-0.725,5.74C24.938,53.886,26.199,55.508,27.955,56.51z M33.514,61.069l-8.332,4.749  c-3.621,2.064-4.888,6.689-2.822,10.312c1.343,2.354,3.861,3.816,6.574,3.816c1.303,0,2.594-0.344,3.731-0.992  c0.002,0,0.004,0,0.006-0.002l15.241-8.688C41.805,69.605,36.561,66.078,33.514,61.069z M34.29,73.854  c-0.394,1.433-1.319,2.627-2.608,3.36c-0.84,0.479-1.789,0.73-2.748,0.73c-1.996,0-3.851-1.074-4.838-2.807  c-1.519-2.663-0.587-6.064,2.077-7.584c0.839-0.479,1.789-0.73,2.749-0.73c1.997,0,3.852,1.074,4.837,2.807  C34.494,70.92,34.684,72.42,34.29,73.854z M74.82,65.819c-0.002,0-0.002-0.001-0.002-0.001l-8.332-4.749  c-3.047,5.009-8.291,8.536-14.398,9.196l15.241,8.688c0.004,0.002,0.008,0.004,0.012,0.006c1.138,0.646,2.427,0.988,3.728,0.988l0,0  c2.711,0,5.23-1.463,6.574-3.816C79.705,72.508,78.438,67.885,74.82,65.819z M75.904,75.14c-0.987,1.731-2.842,2.808-4.836,2.808  c-0.002,0-0.002,0-0.002,0c-0.959,0-1.908-0.254-2.748-0.732c-1.291-0.732-2.217-1.928-2.608-3.359  c-0.394-1.434-0.204-2.934,0.531-4.225c0.985-1.73,2.84-2.807,4.837-2.807c0.96,0,1.91,0.254,2.75,0.731  C76.49,69.076,77.422,72.477,75.904,75.14z M67.805,51.082c0-11.767-16.023-29.117-16.707-29.85L50,20.053l-1.098,1.18  c-0.684,0.732-16.707,18.083-16.707,29.85c0,9.815,7.986,17.801,17.805,17.801S67.805,60.897,67.805,51.082z M50.001,65.824  c-4.83,0-8.749-3.92-8.749-8.752c0-4.83,8.749-15.469,8.749-15.469s8.747,10.639,8.747,15.469  C58.748,61.904,54.833,65.824,50.001,65.824z"
        ></path>
      </g>
    </svg>
  );
}