import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input, Button, Title, DropDownSelect } from '../../modal_modules';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './container';
import { svgBackButton } from 'components/svg_module';
import * as EmailValidator from 'email-validator';
import passwordValidator from 'password-validator';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { svgDelete, svgEdit, svgCamera, svgSwap } from 'components/svg_module';
const EditAccountExplorerModal = ({
  closeModal,
  user,
  accountRoles,
  countries,
  statesProvences,
  updateUserInfo,
  updateUserPassword,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingImage, setEditingImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(<img></img>);

  const originalUserRef = useRef();
  const [inputValues, setInputValues] = useState(
    user
      ? user
      : {
          about_me: '',
          account_about: '',
          account_name: '',
          account_rid: '',
          account_role_rid: '',
          account_role_name: '',
          address_1: '',
          address_2: '',
          city: '',
          country_name: '',
          country_rid: '',
          date_of_birth: '',
          email: '',
          explorer_rid: '',
          first_name: '',
          last_name: '',
          phone: '',
          plan_name: '',
          plan_rid: '',
          state_provence: '',
          state_provence_rid: '',
          subscription_end_date: '',
          subscription_rid: '',
          subscription_start_date: '',
          ui_image_location: '',
          username: '',
        },
  );
  let schema = new passwordValidator();
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(25) // Maximum length 25
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 number
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();
  const [passwordValues, setPasswordValues] = useState({
    currentPassword: '',
    password: '',
    verifyPassword: '',
  });
  useEffect(() => {
    if (user) {
      originalUserRef.current = user;
    }
  }, []);
  const [currentExplorerTab, setCurrentExplorerTab] = useState('explorerMenu');
  const [lastExplorerTab, setLastExplorerTab] = useState([]);
  const [stateRid, setStateRid] = useState([]);
  const [countryRid, setCountryRid] = useState([]);
  const [accountRoleRid, setAccountRoleRid] = useState([]);
  const [signUpErrors, setSignUpErrors] = useState({
    usernameError: '',
    emailErrors: '',
    passwordErrors: [],
  });
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };
  const editHandler = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { ui_image_location } = inputValues;

    setInputValues({ ...inputValues, ui_image_location: '' });
  });
  const verifyFile = (file) => {
    //filters file input
    const maxImgSize = 10000000000;
    const acceptedFileTypes =
      'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
    const acceptedFileTypesArr = acceptedFileTypes.split(',').map((item) => {
      return item.trim();
    });
    if (file && file.length > 0) {
      const currentFile = file[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > maxImgSize) {
        alert('File too big');
        return false;
      }
      // if (!acceptedFileTypesArr.includes(currentFileType)) {
      //   alert("File type not allowed");
      //   return false;
      // }
    }
    return true;
  };
  const handleOnDrop = (files, rejectedFiles) => {
    //handles image drop
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      const isVerified = verifyFile(files);

      if (isVerified) {
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.onloadend = () => {
          let fileObject = {
            file: myFileItemReader.result,
            file_name: currentFile.path,
          };
          // setInputValues({ ...inputValues, ui_image_location: fileObject });
          setEditingImage(fileObject);
          setIsEditing(true);
        };
        let file_name = myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };
  const renderDropZone = () => {
    const maxImgSize = 10000000000;
    return (
      <div className="mod-drpzn-padder">
        <Dropzone onDrop={handleOnDrop} maxSize={maxImgSize} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mod-drpzn">
                  <div className="mod-drpzn-inner">
                    <div className="mod-profile-edit-text-wrapper">
                      <span>Click to browse or drop images here.</span>
                    </div>
                  </div>
                  <div className="mod-drpzn-cam-wrapper">
                    {svgCamera(
                      'color',
                      'mod-drpzn-svg-cam',
                      'mod-drpzn-svg-cam-outerlayer',
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  const handleStateSelect = useCallback((event) => {
    if (event.length > 0) {
      const { state_provence_rid } = event[0];
      setInputValues({ ...inputValues, state_provence_rid });
    } else {
      setInputValues({ ...inputValues, state_provence_rid: null });
    }
  });

  const handleCountrySelect = useCallback((event) => {
    if (event.length > 0) {
      const { country_rid } = event[0];
      setInputValues({ ...inputValues, country_rid });
    } else {
      setInputValues({ ...inputValues, country_rid: null });
    }
  });

  const handleAccountRoleSelect = useCallback((event) => {
    if (event.length > 0) {
      const { account_role_rid } = event[0];
      setInputValues({ ...inputValues, account_role_rid });
    } else {
      setInputValues({ ...inputValues, account_role_rid: null });
    }
  });

  useEffect(() => {
    if (countries && user) {
      setCountryRid(
        countries.filter((ele) => ele.country_rid === user.country_rid),
      );
    }
  }, [countries]);

  useEffect(() => {
    if (statesProvences && user) {
      setStateRid(
        statesProvences.filter(
          (ele) => ele.state_provence_rid === user.state_provence_rid,
        ),
      );
    }
  }, [statesProvences]);

  useEffect(() => {
    if (accountRoles && user) {
      setAccountRoleRid(
        accountRoles.filter(
          (ele) => ele.account_role_rid === user.account_role_rid,
        ),
      );
    }
  }, [accountRoles]);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      let emailValidation = EmailValidator.validate(value);
      if (emailValidation === false) {
        setSignUpErrors({
          ...signUpErrors,
          emailErrors: 'Please enter a valid email',
        });
      } else {
        setSignUpErrors({ ...signUpErrors, emailErrors: '' });
        axios
          .patch('/api/e/verify_auth_email', { email: value })
          .then((res) => {
            setSignUpErrors({
              ...signUpErrors,
              emailErrors:
                res.data.message === 'Email valid' ? '' : res.data.message,
            });
          });
      }
    } else if (name === 'username') {
      setInputValues({ ...inputValues, [name]: value });
      if (value.length < 5) {
        setSignUpErrors({
          ...signUpErrors,
          usernameError: 'Username must be at least 5 characters',
        });
      } else {
        axios
          .patch('/api/e/verify_auth_username', { username: value })
          .then((res) => {
            setSignUpErrors({
              ...signUpErrors,
              usernameError: res.data.message,
            });
          });
      }
    }
    setInputValues({ ...inputValues, [name]: value });
  });
  const handlePasswordOnChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'password') {
      let errors = schema.validate(value, { list: true });
      setSignUpErrors({ ...signUpErrors, passwordErrors: errors });
    }
    setPasswordValues({ ...passwordValues, [name]: value });
  });

  const renderEmailErrors = () => {
    if (inputValues.email.length > 0) {
      return (
        <div className="email-error-container">
          {signUpErrors.emailErrors.length === 0 && (
            <div className="email-error-wrapper">
              {/* <img alt="alert-pic" src="/symbols/bluecheck.png" />
              <span>Valid Email</span> */}
            </div>
          )}
          {signUpErrors.emailErrors.length > 0 && (
            <div className="email-error-wrapper">
              <img alt="alert-pic" src={'/symbols/red-alert.png'} />
              <span>{signUpErrors.emailErrors}</span>
            </div>
          )}
        </div>
      );
    } else {
      // return (<div className="error-placeholder" />)
      return <div className="email-error-container" />;
    }
  };

  const renderUsernameErrors = () => {
    if (inputValues.username) {
      if (
        inputValues.username.length > 0 &&
        signUpErrors.usernameError !== 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container">
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.usernameError === 'Username already exists'
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else if (
        inputValues.username.length > 0 &&
        inputValues.username.length < 5 &&
        signUpErrors.usernameError === 'Username must be at least 5 characters'
      ) {
        return (
          <div className="email-error-container">
            <div className="email-error-wrapper">
              <img alt="alert-pic" src="/symbols/red-alert.png" />
              <span>
                {signUpErrors.usernameError
                  ? signUpErrors.usernameError
                  : 'Valid Username'}
              </span>
            </div>
          </div>
        );
      } else {
      }
    } else {
      return <div className="email-error-container" />;
    }
  };
  const renderPasswordErrors = () => {
    if (
      passwordValues.password.length > 0 ||
      passwordValues.verifyPassword.length > 0
    ) {
      return (
        <div className="email-error-container modal-password-error-wrapper">
          {signUpErrors.passwordErrors.includes('min') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('min')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must be minimum 8 characters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('symbols') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('symbols')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>
                Password must contain at least one symbol (i.e ! @ # $ % ^ & * )
              </span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('max') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('max')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must be maximum 25 characters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('uppercase') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('uppercase')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must contain uppercase letters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('lowercase') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('lowercase')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must contain lowercase letters</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('digits') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('digits')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password must have at least 1 number</span>
            </div>
          )}
          {signUpErrors.passwordErrors.includes('spaces') && (
            <div className="email-error-wrapper">
              <img
                alt="alert-pic"
                src={
                  signUpErrors.passwordErrors.includes('spaces')
                    ? '/symbols/red-alert.png'
                    : '/symbols/bluecheck.png'
                }
              />
              <span>Password cannot have spaces</span>
            </div>
          )}
          <div className="email-error-wrapper">
            <img
              alt="aler-pic"
              src={
                passwordValues.password === passwordValues.verifyPassword
                  ? '/symbols/bluecheck.png'
                  : '/symbols/red-alert.png'
              }
            />
            <span>
              {passwordValues.password === passwordValues.verifyPassword
                ? 'Passwords match'
                : 'Passwords not matched'}
            </span>
          </div>
        </div>
      );
    } else {
      return <div className="email-error-container" />;
    }
  };
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { password, verifyPassword } = passwordValues;
    const loadImage = () =>
      new Promise((resolve, reject) => {
        const formData = new FormData();
        const image = editingImage;
        image.file = croppedImage;
        if (!image.file.includes('blob')) {
          let typeName = image.file.slice(5, 15);
          if (typeName.includes('png') || typeName.includes('jpg')) {
            typeName = typeName.slice(0, typeName.length - 1);
          }
          const slicedData =
            typeName.includes('png') || typeName.includes('jpg')
              ? image.file.slice(22)
              : image.file.slice(23);
          const byteCharacters = atob(slicedData);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: `${typeName}` });

          blob['originalname'] = image.file_name;
          formData.set('original_name', image.file_name);
          formData.set('file', blob);
          window.formData = formData;
        } else {
          let blob3 = editingImage;
          blob3.originalname = blob3.name;
          // formData.set("file", blob);
          formData.set('file', blob3);
          window.formData = formData;
        }

        axios
          .post('/api/bucket/profile_image_uploads', formData)
          .then((res) => {
            let picUrl = res.data.data;
            inputValues.ui_image_location = picUrl;
            updateUserInfo(inputValues);
            closeModal();
          });
        resolve(true);
      });

    if (
      password.length > 0 &&
      verifyPassword.length > 0 &&
      password === verifyPassword
    ) {
      let data = {
        password,
        explorer_rid: inputValues.explorer_rid,
        fromAdmin: true,
      };
      updateUserPassword(data);
    }
    if (editingImage) {
      return await loadImage();
    }
    if (originalUserRef.current !== inputValues) {
      updateUserInfo(inputValues);
    }
    closeModal();
  });

  const renderExplorerMenu = () => {
    return (
      <div className="explorer-menu-wrapper">
        <div className="explorer-menu-title-tab">
          <div>
            <span>SETTINGS</span>
          </div>
        </div>
        <div className="explorer-menu-squares">
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerImage');
              setLastExplorerTab(lastTabs);
            }}
          >
            IMAGE
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerProfile');
              setLastExplorerTab(lastTabs);
            }}
          >
            PROFILE
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerAddress');
              setLastExplorerTab(lastTabs);
            }}
          >
            ADDRESS
          </div>
          <div
            className="menu-squares"
            onClick={() => {
              let lastTabs = lastExplorerTab;
              lastTabs.push('explorerMenu');
              setCurrentExplorerTab('explorerPassword');
              setLastExplorerTab(lastTabs);
            }}
          >
            PASSWORD
          </div>
        </div>
      </div>
    );
  };

  const renderExplorerImage = () => {
    return (
      <div className="explorer-image-wrapper">
        <div className="explorer-title-tab">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let lastTabOpened = lastExplorerTab[lastExplorerTab.length - 1];
              let lastTabs = lastExplorerTab;
              lastTabs.pop();
              setLastExplorerTab(lastTabs);
              setCurrentExplorerTab(lastTabOpened);
            }}
            className="edit-account-explorer-back-button"
          >
            {svgBackButton('', 'back-button-svg', 'backbutton-svg-outer')}
          </button>{' '}
          <div>
            <span>IMAGE</span>
          </div>
        </div>
        <div className="explorer-image-content-container">
          <div className="image-edit-body-container">
            {isEditing && editingImage && (
              <Cropper
                src={editingImage.file}
                style={{ height: '100%', width: '100%' }}
                // Cropper.js options
                initialAspectRatio={1 / 1}
                guides={false}
                crop={onCrop}
                center="true"
                ref={cropperRef}
                dragMode="move"
                viewMode={1}
                cropBoxMovable={false}
                cropBoxResizable={false}
                minCropBoxHeight={'100px'}
                minCropBoxWidth={'100px'}
                className="cropper-container"
                responsive="true"
              />
            )}
            {!isEditing && (
              <>
                {inputValues.ui_image_location && (
                  <div className="edit-profile-image-profile-image-wrapper">
                    <div className="edit-profile-image-profile-image-container">
                      <img
                        src={inputValues.ui_image_location}
                        className="edit-profile-image-profile-image"
                        id="edit-profile-image"
                        alt="no-profile-pic"
                      />
                    </div>
                    <div className="edit-profile-image-buttons-wrapper">
                      <div
                        className="edit-profile-image-edit-button"
                        onClick={editHandler}
                      >
                        {svgSwap(
                          'color',
                          'mod-edit-profile-image-svg',
                          'mod-edit-profile-image-svg-outer',
                        )}
                      </div>
                      <div
                        onClick={() => {
                          setInputValues({
                            ...inputValues,
                            ui_image_location: '',
                          });
                        }}
                        className="edit-profile-image-delete-button"
                      >
                        {svgDelete(
                          'color',
                          'mod-edit-profile-image-svg',
                          'mod-edit-profile-image-svg-outer',
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!inputValues.ui_image_location && renderDropZone()}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderExplorerProfile = () => {
    return (
      <div className="explorer-profile-wrapper">
        <div className="explorer-title-tab">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let lastTabOpened = lastExplorerTab[lastExplorerTab.length - 1];
              let lastTabs = lastExplorerTab;
              lastTabs.pop();
              setLastExplorerTab(lastTabs);
              setCurrentExplorerTab(lastTabOpened);
            }}
            className="edit-account-explorer-back-button"
          >
            {svgBackButton('', 'back-button-svg', 'backbutton-svg-outer')}
          </button>{' '}
          <div>
            <span>PROFILE</span>
          </div>
        </div>
        <div className="explorer-input-content-container">
          <Input
            label="First Name"
            name="first_name"
            onChangeHandler={handleOnChange}
            value={inputValues.first_name}
          />
          <Input
            label="Last Name"
            name="last_name"
            onChangeHandler={handleOnChange}
            value={inputValues.last_name}
          />
          <Input
            label="Username"
            name="username"
            onChangeHandler={handleOnChange}
            value={inputValues.username}
            spacingBottom={'0px!important'}
            customParentClassName="modal-account-profile-no-bottom-spacing"
          />
          {renderUsernameErrors()}
          <Input
            label="Email"
            name="email"
            onChangeHandler={handleOnChange}
            value={inputValues.email}
            spacingBottom={'0px'}
            customParentClassName="modal-account-profile-no-bottom-spacing"
          />
          {renderEmailErrors()}
          <Input
            label="Phone"
            name="phone"
            onChangeHandler={handleOnChange}
            value={inputValues.phone}
          />
          <DropDownSelect
            values={accountRoleRid}
            placeholder="Role"
            className="create_edit_block_modal_drop_down_select edit-account-explorer-dropdown-select edit-account-explorer-dropdown-select--left"
            onChange={handleAccountRoleSelect}
            options={accountRoles}
            parentClassName=" create_edit_block_modal_drop_down_select_parent edit-account-explorer-dropdown-parent"
            labelField={'account_role_name'}
            valueField={'account_role_rid'}
            clearable
          />
        </div>
      </div>
    );
  };

  const renderExplorerAddress = () => {
    return (
      <div className="explorer-address-wrapper">
        <div className="explorer-title-tab">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let lastTabOpened = lastExplorerTab[lastExplorerTab.length - 1];
              let lastTabs = lastExplorerTab;
              lastTabs.pop();
              setLastExplorerTab(lastTabs);
              setCurrentExplorerTab(lastTabOpened);
            }}
            className="edit-account-explorer-back-button"
          >
            {svgBackButton('', 'back-button-svg', 'backbutton-svg-outer')}
          </button>{' '}
          <div>
            <span>ADDRESS</span>
          </div>
        </div>
        <div className="explorer-input-content-container">
          <Input
            label="Address 1"
            name="address_1"
            onChangeHandler={handleOnChange}
            value={inputValues.address_1}
          />
          <Input
            label="Address 2"
            name="address_2"
            onChangeHandler={handleOnChange}
            value={inputValues.address_2}
          />
          <Input
            label="City"
            name="city"
            onChangeHandler={handleOnChange}
            // customParentClassName="half-inputs half-inputs--left"
            value={inputValues.city}
          />
          <Input
            label="Zip/Postal Code"
            name="zip_postal_code"
            onChangeHandler={handleOnChange}
            // customParentClassName="half-inputs half-inputs--right"
            value={inputValues.zip_postal_code}
          />
          <DropDownSelect
            values={stateRid}
            placeholder="State"
            className="create_edit_block_modal_drop_down_select edit-account-explorer-dropdown-select "
            onChange={handleStateSelect}
            options={statesProvences}
            parentClassName=" create_edit_block_modal_drop_down_select_parent edit-account-explorer-dropdown-parent --top"
            labelField={'state_provence'}
            valueField={'state_provence_rid'}
            clearable
          />
          <DropDownSelect
            values={countryRid}
            placeholder="Country"
            className="create_edit_block_modal_drop_down_select edit-account-explorer-dropdown-select "
            onChange={handleCountrySelect}
            options={countries}
            parentClassName=" create_edit_block_modal_drop_down_select_parent edit-account-explorer-dropdown-parent --bottom"
            labelField={'country_name'}
            valueField={'country_rid'}
            clearable
          />
        </div>
      </div>
    );
  };

  const renderExplorerPassword = () => {
    return (
      <div className="explorer-password-wrapper">
        <div className="explorer-title-tab">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let lastTabOpened = lastExplorerTab[lastExplorerTab.length - 1];
              let lastTabs = lastExplorerTab;
              lastTabs.pop();
              setLastExplorerTab(lastTabs);
              setCurrentExplorerTab(lastTabOpened);
            }}
            className="edit-account-explorer-back-button"
          >
            {svgBackButton('', 'back-button-svg', 'backbutton-svg-outer')}
          </button>{' '}
          <div>
            <span>RESET PASSWORD</span>
          </div>
        </div>
        <div className="explorer-input-content-container">
          {/* {inputValues.username && (
            <Input
              label="Current password"
              name="currentPassword"
              onChangeHandler={handlePasswordOnChange}
              spacingTop="5px"
              spacingBottom="5px"
              value={passwordValues.currentPassword}
              type="password"
            />
          )} */}
          <Input
            label="New password"
            name="password"
            onChangeHandler={handlePasswordOnChange}
            spacingTop="5px"
            spacingBottom="5px"
            value={passwordValues.password}
            type="password"
          />
          <Input
            label="Confirm new password"
            name="verifyPassword"
            onChangeHandler={handlePasswordOnChange}
            spacingTop="5px"
            spacingBottom="5px"
            value={passwordValues.verifyPassword}
            type="password"
          />
        </div>
        {renderPasswordErrors()}
      </div>
    );
  };
  const renderExplorers = () => {
    return (
      <div className="account-modal-explorers-wrapper">
        {currentExplorerTab === 'explorerMenu' && renderExplorerMenu()}
        {currentExplorerTab === 'explorerImage' && renderExplorerImage()}
        {currentExplorerTab === 'explorerProfile' && renderExplorerProfile()}
        {currentExplorerTab === 'explorerAddress' && renderExplorerAddress()}
        {currentExplorerTab === 'explorerPassword' && renderExplorerPassword()}
      </div>
    );
  };
  return (
    <div
      className="mod-type1-wrapper edit-account-explorer-modal-wrapper"
      id="cre-ad"
    >
      <div className="mod-type1-padder edit-account-explorer-modal-padder">
        <Title closeModal={closeModal} title="EXPLORER" />

        <div className="mod-cnt-def-edit-account-explorer">
          <form onSubmit={handleSubmit}>
            <div className="edit-account-explorer-modal-top-content">
              <div className="edit-account-explorer-modal-top-profile-pic-wrapper">
                <img
                  src={
                    user.ui_image_location
                      ? user.ui_image_location
                      : '/logos_v2/lodestar-mark.svg'
                  }
                  className="edit-account-explorer-modal-top-profile-pic"
                  alt="/svg_logos/nav_bar_logo.svg"
                />
              </div>
              <div className="edit-account-explorer-modal-top-user-info">
                <div className="edit-account-explorer-info edit-account-explorer-info--username ">
                  <span>Username: {user.username ? user.username : ''}</span>
                </div>
                <div className="edit-account-explorer-info edit-account-explorer-info--accountrolename ">
                  <span>
                    Role:
                    {user.account_role_name
                      ? user.account_role_name
                      : 'Not assigned'}
                  </span>
                </div>
                <div className="edit-account-explorer-info edit-account-explorer-info--name">
                  <span>
                    Name:
                    {user.first_name
                      ? `${user.first_name} ${user.last_name}`
                      : ''}
                  </span>
                </div>
                <div className="edit-account-explorer-info edit-account-explorer-info--aboutme ">
                  <span>About:{user.about_me ? user.about_me : ''}</span>
                </div>
              </div>
            </div>

            {renderExplorers()}
            <Button spacingBottom="20px" text="SAVE" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAccountExplorerModal);
