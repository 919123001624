export function bowhunting (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;"></path>
        <path
          fill={color}
          className={className}
          d="M60.4642,9.456l-1.38,9.91998c-0.08997,0.60999-0.83997,0.87-1.26996,0.42999l-2.69-2.67999l-4.83002,4.81   c-0.35999-0.65997-0.82001-1.28998-1.39001-1.84998c-0.52997-0.53003-1.14001-0.98999-1.79999-1.35004l4.82001-4.79999   l-2.66998-2.65997c-0.44-0.44-0.19-1.19,0.41998-1.28003l9.95001-1.38C60.11423,8.54597,60.53421,8.96601,60.4642,9.456z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M65.32419,51.34601c-0.5-1.41003-2.10999-2.12-3.28998-1.57001c-0.85999,0.40997-0.42999,1.69,0.47998,1.52997   c2.24005,0,0.19,3.54004-2.96997,0.69c-4.72998-4.16998-1.08002-12.06-3.15002-19.13   c-0.95996-3.09997-2.82996-5.29999-5.07001-6.57001c-0.06,2.02002-0.85999,4.01001-2.39996,5.54004   c-0.10004,0.09998-0.20001,0.19-0.29999,0.26001c5.33997,1.82996,1.95996,13.42999,6.71997,19.94995l-28.40771-6.80853   L39.00424,33.206c-0.68005-0.35999-1.30005-0.84003-1.86005-1.39001c-0.53998-0.53998-0.99994-1.14001-1.35999-1.77997   L23.35379,42.42908L16.90421,13.766c3.95001,2.90997,9.56,2.70001,13.07001,3.16998   c4.16003,0.53003,6.17004,2.04004,6.86999,3.42999c0.08002-0.09998,0.17999-0.19995,0.28003-0.29999   c1.5-1.48999,3.46997-2.33002,5.57996-2.39996C37.2742,7.49598,22.28421,15.60596,16.94425,9.54597   c-1.85004-2.03998-1.01001-4.07996,0.07001-3.88995c0.32996,0.03998,0.69,0.44,0.63995,0.76996l-0.02997,0.15002   c-0.16003,0.92999,1.14996,1.33002,1.53998,0.47998c1.17004-2.52997-2.73999-5.03998-5.03998-2.07996   c-1.39001,1.81-0.85999,4.40997,0.41998,6.32996c0.15002,0.23004,0.32001,0.45001,0.48999,0.66003l7.04108,31.73291   C3.69266,62.0299,12.41982,53.32726,9.97421,55.766c-0.84896,0.82964-0.92554,2.26447,0,3.19c0.88,0.88,2.31,0.88,3.20001,0   c2.88518-2.87599-6.94913,6.92701,12.49591-12.45612L57.10422,53.896c0.21002,0.16998,0.44,0.33002,0.66998,0.48999   C62.4642,57.42597,66.2742,54.24598,65.32419,51.34601z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M47.86423,30.77601c-2.70391,2.66782-7.02598,2.61403-9.66003-0.02002c-2.67542-2.67543-2.66646-6.98351-0.01996-9.63   c2.70898-2.69002,7.06071-2.57924,9.65997,0.02002C50.48214,23.78393,50.54334,28.09678,47.86423,30.77601z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M15.70426,47.93598l-6.78003,6.76001C8.76425,54.85596,8.62424,55.026,8.50424,55.206l-4.37-0.87   c-0.59003-0.10999-0.81-0.84003-0.38001-1.26001l6.27997-6.27002L15.70426,47.93598z"
        ></path>
        <path
          fill={color}
          className={className}
          d="M22.14426,58.87598L15.85422,65.146c-0.41998,0.41998-1.14996,0.20001-1.26001-0.38l-0.87-4.33002   c0.17999-0.12,0.35004-0.26001,0.51001-0.41998l6.78003-6.76001L22.14426,58.87598z"
        ></path>
      </g>
    </svg>
  );
}