import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import { Box } from '@material-ui/core';
import HubModal from '../../modal';
import { Button, Title, Tab } from '../../modal_modules';
import { copyItinerary } from 'actions/itinerary_actions';
const mapStateToProps = (state) => {
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let type = state.ui.modal.openType && state.ui.modal.openType;
  let actionPayload =
    state.ui.modal.actionPayload && state.ui.modal.actionPayload;
  return {
    type,
    userInfo,
    actionPayload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    copyItinerary: (data) => dispatch(copyItinerary(data)),
  };
};
const CopyItem = ({
  type,
  userInfo,
  actionPayload,
  copyItinerary,
  closeModal,
}) => {
  // debugger;
  const handleSubmit = useCallback((e) => {
    if (type === 'ITINERARY') copyItinerary(actionPayload);
    closeModal();
  });
  return (
    <HubModal
      show={true}
      title={`COPY ${type}`}
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
      >
        <Stack spacing={2}>
          <div className="copy-item-modal">
            <span className="copy-item-wrapper">
              Copy{' '}
              <span className="copy-item-description">
                {type}{' '}
                {type === 'ITINERARY' ? actionPayload.itinerary_title : ''}
              </span>{' '}
              ?
            </span>
          </div>
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyItem);
