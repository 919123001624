import useNavMenuGroup from 'hooks/useNavMenuGroup';

function DynamicPublicSiteMenuLink({ type }) {
  const { data, isLoading, isError } = useNavMenuGroup(type);

  if (isLoading) return;
  if (isError) return;
  if (!data) return; // don't display if no content

  // data actuall returns the children of each item,
  // but we don't need it here
  return (
    <a href={data.URL} className="menu-navigation-button">
      <div className="menu-navigation-text">{data.Title.toUpperCase()}</div>
    </a>
  );
}

export default DynamicPublicSiteMenuLink;
