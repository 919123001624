import React from 'react';
import { svgClose2, svgSummary } from 'components/svg_module';

const ModalTitle = ({ closeModal, title, triggerEdit, triggerDelete }) => {
  return (
    <div className="mod-type1-title">
      <div className="mod-type1-mod-closer" onClick={() => closeModal()}>
        {svgClose2('color', 'mod-insp-svg-close', 'mod-insp-svg-close-outer')}
      </div>
      <div className="mod-type1-title-encloser">
        <span style={{ textTransform: 'uppercase' }}>{title}</span>
      </div>
      <div className="mod-type1-mod-menu">
        {/* {svgSummary("color", "mod-insp-svg-close", "mod-insp-svg-close-outer")} */}
      </div>
    </div>
  );
};

export default ModalTitle;
