export function cutlery (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 69 69"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 69 69;" />
        <g>
          <path
            fill={color}
            className={className}
            d="M30.52168,33.82272l-4.26001,3.88c-1.89001-0.22998-3.39001,0.75-6.62-0.23999   c-1.54999-0.47003-2.98999-1.32001-4.16998-2.5l-9.07001-9.02002H6.39168c-0.39001-0.38995-0.76001-0.79999-1.09003-1.21997   c-5.12-6.41003,0.57001-12.88001,4.55005-9.98999c0.08997,0.06,0.17999,0.13,0.25995,0.19995L29.4117,32.79275L30.52168,33.82272z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M54.80165,61.11275c-1.37566,1.37566-3.62479,1.32669-4.92999-0.10004h-0.01001   c-1.46933-1.56544,5.32271,5.67089-14.37-15.31c1.12-1.22998,2.32001-2.53998,3.60004-3.94   c21.392,19.80236,14.01387,12.9725,15.60999,14.45001C56.14444,57.53691,56.15805,59.77662,54.80165,61.11275z"
          ></path>
          <path
            fill={color}
            className={className}
            d="M65.34169,22.58273L53.94167,33.98275c-1.57001,1.57001-3.92999,2.04999-5.98999,1.20996   c-2.16998-0.88-4.65002-0.29999-6.21002,1.44l-3.75,4.10004v0.00995C13.52997,67.50674,21.96683,58.27579,20.14168,60.27273   c-2.12,2.37-5.79999,2.46997-8.03998,0.21997c-2.25-2.23999-2.15002-5.91998,0.21997-8.03998   c21.93015-20.00984-7.49133,6.83574,23.65002-21.58002c1.75-1.56,2.32996-4.03998,1.44995-6.20996H37.4117   c-0.84003-2.06-0.36005-4.42004,1.20996-5.98999L50.03169,7.27273c0.50995-0.52002,1.35999-0.52002,1.87,0h0.01001   c0.50995,0.52002,0.51996,1.33002,0.01996,1.84998l-7.17999,7.56c-0.57001,0.61005-0.57001,1.57001,0.03003,2.17004   c0.59998,0.58997,1.56,0.59998,2.15997,0.01996l7.57001-7.16998c0.52002-0.5,1.33002-0.48999,1.84998,0.02002l0.04004,0.03998   c0.51001,0.52002,0.52997,1.33002,0.01996,1.85004l-7.17999,7.56995c-0.57001,0.60004-0.56,1.56,0.03003,2.16003   c0.59998,0.58997,1.56,0.59998,2.16998,0.02997l7.55994-7.17999c0.52002-0.5,1.34009-0.48999,1.8501,0.02002l0.04004,0.04999   c0.51001,0.51001,0.52991,1.33002,0.02002,1.85004l-7.1701,7.56c-0.57996,0.59998-0.57001,1.56,0.02002,2.15997   c0.59998,0.59998,1.56,0.59998,2.16998,0.03003l7.55994-7.18005c0.52002-0.5,1.34009-0.48999,1.8501,0.02002   C65.86171,21.22274,65.86171,22.06271,65.34169,22.58273z"
          ></path>
        </g>
      </g>
    </svg>
  );
}