export function campingTent (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 279 180"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <g>
        <path
          className={`${className} fil0`}
          d="M246 145l-26 0 0 35 -17 0c8,-42 -15,-97 -64,-97 -48,0 -72,55 -64,97l-16 0 0 -35 -25 0 -28 35 -6 0 140 -180 139 180 -6 0 -27 -35z"
        ></path>
        <path
          className={`${className} fil0`}
          d="M198 180l-118 0c-7,-39 14,-92 59,-92 46,0 67,53 59,92z"
        ></path>
      </g>
    </svg>
  );
}