import React, { useState } from 'react';
import { svgLock } from 'components/svg_module';
import { openDatePickerModal } from '../../../actions/modal_actions';
import { connect } from 'react-redux';
import moment from 'moment';
const mapDispatchToProps = (dispatch) => {
  return {
    openDatePickerModal: (data) => dispatch(openDatePickerModal(data)),
  };
};

const PermissionPicker = ({
  spacingTop,
  spacingBottom,
  openDatePickerModal,
  persistParentData,
  permissionType,
  origin, // pass the id of the html tag of the most outer div tag of the
  // component that is rendering this date picker
}) => {
  const [displayDate, toggleDisplayDate] = useState(false);
  const handleDisplay = () => {
    toggleDisplayDate(!displayDate);
  };

  let permissionText;
  switch (permissionType) {
    case 'observer':
      permissionText = 'Can only view what is shared.';
      break;
    case 'copier':
      permissionText = 'Can view and make a copy of what is shared.';
      break;
    case 'editor':
      permissionText = ' Can view and edit what is shared.';
      break;
    case 'owner':
      permissionText =
        'Can view eidt delete what is shared and set sharing permissions';
      break;
    default:
      permissionText = null;
      break;
  }

  return (
    <div
      className="mod-perm-pkr-wrapper"
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
    >
      {!permissionType ? (
        <div
          className="mod-perm-pkr-untrig"
          // onClick={() => toggleDisplayDate(!displayDate)}
          onClick={() => {
            persistParentData();
            openDatePickerModal(origin);
          }}
        >
          {svgLock('white', 'mod-svg-perm', 'mod-svg-perm-wrapper')}
          <span>Choose your shared permission</span>
        </div>
      ) : (
        <div
          className="mod-perm-pkr-set"
          onClick={() => {
            persistParentData();
            openDatePickerModal(origin);
          }}
        >
          <div className="mod-perm-dur-tp">
            <span>{permissionText}</span>
          </div>
          {/* duration change to amount of days */}
          <div className="mod-perm-dur-btm">
            <span>{permissionText}</span>
          </div>
        </div>
      )}
    </div>
  );
  // : <div className='modal-layer-two'>
  //
  //     </div>
};

export default connect(null, mapDispatchToProps)(PermissionPicker);
