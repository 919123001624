export function catcus (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g>
        <path enableBackground="new 0 0 100 100" />
        <path
          fill={color}
          className={className}
          d="M61.625,76.108c-3.25-0.875-3.625-4.135-8.125-4.135c0,0,0-48.602,0-48.715c0-1.932-1.567-3.5-3.5-3.5s-3.5,1.568-3.5,3.5  c0,0.113,0,48.715,0,48.715c-4.5,0-4.875,3.26-8.125,4.135s-5,4.133-5,4.133h33.25C66.625,80.241,64.875,76.983,61.625,76.108z   M36,51.808h8.996v-7H39.5V31.425c0-1.932-1.567-3.5-3.5-3.5c-1.934,0-3.5,1.568-3.5,3.5v16.883  C32.5,50.241,34.066,51.808,36,51.808z M64,33.323c-1.934,0-3.5,1.568-3.5,3.5v14.984h-5.496v7H64c1.933,0,3.5-1.566,3.5-3.5V36.823  C67.5,34.892,65.933,33.323,64,33.323z"
        ></path>
      </g>
    </svg>
  );
}