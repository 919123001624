export const svgShare = (color, className, outerLayer) => {
  return (
    <svg
      height="100px"
      width="100px"
      className={outerLayer}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <g transform="translate(0,-952.36218)">
        <path
          className={className}
          d="m 60,975.36215 32,25.00005 -32,25 0,-13.0001 c -1.7024,-0.08 -31.3771,-1.0334 -52,17.0001 8.253,-25.4274 34.1738,-36.48725 52,-41.00005 z"
          overflow="visible"
          fill={color}
          stroke="none"
        ></path>
      </g>
    </svg>
  );
};