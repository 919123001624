import merge from 'lodash/merge';
import {
  PERSIST_REC_GOV_CAMPGROUNDS,
  PERSIST_REC_GOV_CAMPSITES,
} from '../../constants';

const RecGovDataReducer = (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case PERSIST_REC_GOV_CAMPGROUNDS:
      newState['recGovCampGrounds'] = {updated_at: new Date().toDateString(), data: action.data};
      return newState;
    case PERSIST_REC_GOV_CAMPSITES:
      newState['recGovCampSites'] = {updated_at: new Date().toDateString(), data: action.data};
      return newState;
    default:
      return state;
  }
};
export default RecGovDataReducer;
