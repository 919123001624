import {
  RECEIVE_BLOCK_COMMENTS,
  RECEIVE_BLOCK_COMMENT_COUNT,
  RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE,
  RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE,
} from '../constants';
import * as BlockApiUtil from '../util/api_util/block_api_util';
export const receiveBlockComments = (data) => {
  return {
    type: RECEIVE_BLOCK_COMMENTS,
    data,
  };
};
const receiveBlockCommentCount = (payload) => {
  return {
    type: RECEIVE_BLOCK_COMMENT_COUNT,
    // payload will be two parts, itineary_item_rid, and itinerary_block_rid to update the count by +1
    payload,
  };
};

const receiveItineraryBlockCommentUpdate = (data) => ({
  type: RECEIVE_ITINERARY_BLOCK_COMMENT_UPDATE,
  data,
});

const receiveAdventureBlockCommentUpdate = (data) => ({
  type: RECEIVE_ADVENTURE_BLOCKS_COMMENT_UPDATE,
  data,
});

export const fetchBlockComments = (itinerary_block_rid) => (dispatch) => {
  return BlockApiUtil.fetchBlockComment(itinerary_block_rid).then((res) => {
    return dispatch(receiveBlockComments(res.data));
  });
};

export const postBlockComment = (data) => (dispatch) => {
  return BlockApiUtil.postBlockComment(data).then((res) => {
    dispatch(receiveItineraryBlockCommentUpdate(data));
    return dispatch(receiveBlockComments(res.data));
  });
};
//this is for itinerary block comments
export const deleteBlockComment = (
  itinerary_block_rid,
  explorer_comment_rid,
) => (dispatch) => {
  return BlockApiUtil.deleteBlockComment(
    itinerary_block_rid,
    explorer_comment_rid,
  ).then((res) => {
    return dispatch(receiveBlockComments(res.data));
  });
};

// this is for deleting adventure block comments

export const deleteAdventureBlockComment = (
  adventure_block_rid,
  adventure_block_comment_rid,
) => (dispatch) => {
  return BlockApiUtil.deleteAdventureBlockComment(
    adventure_block_rid,
    adventure_block_comment_rid,
  ).then((res) => {
    dispatch(receiveBlockComments(res.data));
    // return dispatch(receiveAdventureBlockCommentUpdate(res.data));
  });
};

export const fetchCommentBlockCount = (itinerary_block_rid) => (dispatch) => {
  return BlockApiUtil.fetchBlockCommentCount(itinerary_block_rid).then(
    (res) => {
      return dispatch(receiveBlockCommentCount(res.data));
    },
  );
};

export const postAdventureBlockComment = (data) => (dispatch) => {
  return BlockApiUtil.upsertAdventureBlockComment(data).then((res) => {
    dispatch(receiveAdventureBlockCommentUpdate(data));
    return dispatch(receiveBlockComments(res.data));
  });
};
