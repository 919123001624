import {
  RECEIVE_CALCULATED_IMPACT_CARBON,
  RECEIVE_SOCIAL_COST_OF_CARBON,
} from '../../constants';

import { merge } from 'lodash';
const defaultState = {};

export default function modalReducer(state = defaultState, action) {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_CALCULATED_IMPACT_CARBON:
      newState['impactCarbon'] = action.data;
      return newState;
    case RECEIVE_SOCIAL_COST_OF_CARBON:
      newState['socialCostOfCarbon'] = action.data;
      return newState;
    default:
      return state;
  }
}
