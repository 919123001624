export function boatSightSeeing (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      className={outerLayer}
    >
      <g>
        <path
          className={className}
          d="M94.9,73.3c-0.3-0.8-1-1.3-1.9-1.3h-2c-0.5-32.5-13.2-48.2-23.8-55.6C55.3,8.1,43.5,8,43,8c0,0,0,0,0,0c-1.1,0-2,0.9-2,2   c0,18.9-31,46.2-31.3,46.5c-0.6,0.5-0.8,1.4-0.6,2.2s1,1.3,1.9,1.3h30v2H19c-1.1,0-2,0.9-2,2v8H7c-0.6,0-1.2,0.3-1.6,0.8   s-0.5,1.1-0.4,1.7l4,16c0.2,0.9,1,1.5,1.9,1.5h62c0.5,0,0.9-0.2,1.2-0.4l20-16C94.9,75,95.2,74.1,94.9,73.3z M64.9,19.7   c12.4,8.6,19.8,23.7,21.7,43.8c-5.3-6-14.3-9.1-26.7-9.1c-3.8,0-7.9,0.3-12.3,0.9c12-17.9,4.1-35.4-0.5-42.9   C51.2,13.1,58.1,14.9,64.9,19.7z M45,51.8V16.7C49,24,53.7,37.6,45,51.8z M16.1,56c6.2-6,18.5-18.6,24.9-31.5V56H16.1z M45,59.7   c5.4-0.8,10.4-1.3,14.9-1.3c21.1,0,25.8,9.4,26.8,13.6H70.4l-3.5-8.7c-0.3-0.8-1-1.3-1.9-1.3H45V59.7z M45,72v-6h8v6H45z M57,66   h6.6l2.4,6h-9V66z M41,72h-8v-6h8V72z M21,66h8v6h-8V66z M72.3,88H12.6l-1.5-6h68.7L72.3,88z M84.8,78H10.1l-0.5-2h77.7L84.8,78z"
        ></path>
      </g>
    </svg>
  );
}