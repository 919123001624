
export const svgClose2 = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      viewBox="0, 0, 41.605, 41.605"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M5.04,0.655 L5.335,0.915 L20.8,16.381 L36.271,0.915 C37.491,-0.305 39.47,-0.305 40.69,0.915 C41.817,2.042 41.903,3.814 40.95,5.04 L40.69,5.335 L25.222,20.8 L40.69,36.271 C41.91,37.491 41.91,39.47 40.69,40.69 C39.564,41.817 37.791,41.903 36.565,40.95 L36.271,40.69 L20.8,25.222 L5.335,40.69 C4.114,41.91 2.136,41.91 0.915,40.69 C-0.211,39.564 -0.298,37.791 0.655,36.565 L0.915,36.271 L16.381,20.8 L0.915,5.335 C-0.305,4.114 -0.305,2.136 0.915,0.915 C2.042,-0.211 3.814,-0.298 5.04,0.655 z"
          fill={color}
        />
      </g>
    </svg>
  );
};