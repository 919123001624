export function jeep (color, className, outerLayer) {
  return (
    <svg
      fill={color}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="-346.098 280 100 80"
      xmlns="http://www.w3.org/2000/svg"
      className={outerLayer}
    >
      <g fill={color} className={className}>
        <path enableBackground="new -346.098 285.873 100 70.194" />
        <path d="M-305.321,286.143c-22.309,1.229-35.574,6.358-35.574,9.076c0,2.633,13.266,7.758,35.574,9.053V286.143z"></path>
        <path
          d="M-279.597,286.741c-4.889-0.524-10.169-0.868-15.751-0.868c-2.377,0-4.643,0.058-6.862,0.142v18.395
              c2.219,0.092,4.487,0.158,6.862,0.164c5.774,0.021,11.021-0.166,15.751-0.479V286.741z M-290.523,299.922
              c-4.178,0-7.567-2.031-7.567-4.54c0-2.507,3.39-4.54,7.567-4.54c4.181,0,7.567,2.031,7.567,4.54S-286.343,299.922-290.523,299.922z"
        ></path>
        <path
          d="M-249.288,293.112c0,0-10.975-3.952-27.193-6.014v16.763c17.752-1.443,27.193-4.588,27.193-4.588
              c2.485-0.702,3.19-1.354,3.19-3.025C-246.098,295.113-247.099,293.924-249.288,293.112z"
        ></path>
        <path
          d="M-265.171,340.235c-1.792-1.786-4-2.694-6.567-2.694c-2.493,0-4.666,0.907-6.455,2.694
              c-1.788,1.782-2.695,3.955-2.695,6.454c0,2.574,0.907,4.782,2.695,6.567c1.789,1.784,3.962,2.692,6.455,2.692
              c2.567,0,4.775-0.906,6.567-2.692c1.788-1.784,2.695-3.992,2.695-6.567C-262.476,344.19-263.383,342.02-265.171,340.235z
              M-269.02,349.409c-0.757,0.756-1.664,1.133-2.718,1.133c-1.054,0-1.961-0.377-2.716-1.133c-0.754-0.755-1.135-1.659-1.135-2.718
              c0-1.051,0.381-1.961,1.135-2.717c0.755-0.747,1.663-1.133,2.716-1.133c1.054,0,1.961,0.386,2.718,1.133
              c0.756,0.756,1.127,1.666,1.127,2.717C-267.893,347.748-268.264,348.654-269.02,349.409z"
        ></path>
        <path
          d="M-320.745,340.399c-1.785-1.748-3.957-2.634-6.449-2.634c-2.569,0-4.778,0.886-6.569,2.634
              c-1.792,1.764-2.698,3.951-2.698,6.511c0,2.495,0.907,4.669,2.695,6.454c1.788,1.795,4,2.703,6.572,2.703
              c2.498,0,4.666-0.908,6.449-2.7c1.792-1.786,2.699-3.956,2.699-6.457C-318.046,344.351-318.956,342.157-320.745,340.399z
              M-324.48,349.639c-0.752,0.755-1.659,1.123-2.713,1.123c-1.059,0-1.961-0.368-2.715-1.123c-0.756-0.757-1.135-1.659-1.135-2.729
              c0-1.049,0.379-1.95,1.135-2.705c0.754-0.759,1.656-1.143,2.715-1.143c1.054,0,1.961,0.384,2.713,1.143
              c0.754,0.755,1.132,1.656,1.132,2.705C-323.348,347.979-323.726,348.882-324.48,349.639z"
        ></path>
        <path
          d="M-249.017,340.674l-5.946-24.214c-0.54-2.16-0.899-4.515-4.107-7.35c-2.813-2.484-7.086-2.379-8.216-2.379h-41.02
              c-1.515,0-3.242,0.756-3.998,2.164l-8.68,13.584h-15.848c-3.624,0-6.564,2.699-6.564,6.036v0.968h0.015c0,0.96,0,2.865,0,2.865
              l-0.002,4.266h-2.716v5.323c0,1.879,0.941,2.826,2.833,2.826h2.602c0.226-3.167,1.68-5.867,4.355-8.095
              c2.68-2.221,5.68-3.332,8.999-3.332c3.323,0,6.338,1.111,9.055,3.332c2.718,2.228,4.188,4.928,4.419,8.095h28.743
              c0.229-3.167,1.683-5.885,4.358-8.15c2.681-2.264,5.68-3.389,8.999-3.389c3.32,0,6.335,1.125,9.054,3.389
              c2.716,2.266,4.185,4.983,4.411,8.15h6.321C-249.559,344.672-248.586,342.835-249.017,340.674z M-298.451,321.217
              c0,1.433-0.719,2.151-2.156,2.151h-12.745c-0.781,0-1.176-0.388-1.176-1.166c0-0.273,0.068-0.491,0.196-0.684l4.699-8.165
              c0.501-0.806,1.157-1.048,2.065-1.048h6.961c1.437,0,2.157,0.719,2.157,2.152v6.759H-298.451z M-278.136,321.522
              c0,1.022-0.826,1.852-1.847,1.852h-12.889c-1.023,0-1.85-0.828-1.85-1.852v-7.427c0-1.025,0.826-1.852,1.85-1.852h12.889
              c1.021,0,1.847,0.825,1.847,1.852V321.522z M-263.312,329.025c-1.434,0-2.594-1.16-2.594-2.595c0-1.434,1.16-2.594,2.594-2.594
              c1.435,0,2.594,1.16,2.594,2.594C-260.719,327.865-261.878,329.025-263.312,329.025z"
        ></path>
      </g>
    </svg>
  );
}