import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Input, TextArea, Button, Title, Tab } from '../../modal_modules';
import {
  upsertAccountGroup,
  upsertAccountGroupAndAddMembers,
} from 'actions/social_actions';
import LodestarMembers from '../adventure_group/render_members';
import { merge } from 'lodash';
import { convertToObjectString } from 'util/helpers/database_friendly_converter';
import CustomScroller from 'react-custom-scroller';

const mapStateToProps = (state) => {
  let userFriends = state.entities.social.userFriends
    ? state.entities.social.userFriends
    : null;
  let isAccountGroupEdit = state.ui.modal.isAccountGroupEdit
    ? state.ui.modal.isAccountGroupEdit
    : null;
  let accountGroupRid = state.ui.modal.accountGroupRid
    ? state.ui.modal.accountGroupRid
    : null;
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let currentAccountGroup = state.dataCache.modal.currentAccountGroup
    ? state.dataCache.modal.currentAccountGroup
    : null;
  return {
    userFriends,
    isAccountGroupEdit,
    accountGroupRid,
    userInfo,
    currentAccountGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upsertAccountGroupAndAddMembers: (data) =>
      dispatch(upsertAccountGroupAndAddMembers(data)),
    upsertAccountGroup: (data) => dispatch(upsertAccountGroup(data)),
  };
};
const BlockFileViewer = ({
  closeModal,
  userFriends,
  isAccountGroupEdit,
  accountGroupRid,
  userInfo,
  currentAccountGroup,
  upsertAccountGroupAndAddMembers,
  upsertAccountGroup,
}) => {
  const [inputValues, setInputValues] = useState({
    group_name: currentAccountGroup ? currentAccountGroup.group_name : '',
    group_description: currentAccountGroup
      ? currentAccountGroup.group_description
      : '',
    account_group_rid: currentAccountGroup
      ? currentAccountGroup.account_group_rid
      : null,
  });
  const [topLabel, setTopLabel] = useState('MEMBERS');
  const [localListOfUserFriends, setLocalListOfUserFriends] = useState(null);
  const [listOfFriendsToAdd, setListOfFriendsToAdd] = useState([]);
  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });
  useEffect(() => {
    let localized = merge([], userFriends);
    setLocalListOfUserFriends(localized);
  }, [userFriends]);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let listOfMemberToAdd = convertToObjectString(listOfFriendsToAdd);
    let passOver = merge({}, inputValues);
    passOver['friend_rid'] = listOfMemberToAdd;
    passOver['account_rid'] = userInfo.account_rid;
    passOver['sender_rid'] = userInfo.explorer_rid;
    isAccountGroupEdit
      ? upsertAccountGroup(passOver)
      : upsertAccountGroupAndAddMembers(passOver);
    closeModal();
  };
  const handleAddMember = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    const { value } = e.currentTarget;
    let modifiEdFriendsList = merge([], listOfFriendsToAdd);
    modifiEdFriendsList.push(value);
    let modifiedLocalList = localListOfUserFriends.map((ele, idx) => {
      if (ele.friend_rid === parseInt(value)) {
        ele['placeholder_status'] = 'pending';
        return ele;
      } else return ele;
    });
    setListOfFriendsToAdd(modifiEdFriendsList);
    setLocalListOfUserFriends(modifiedLocalList);
  });

  const renderInvite = () => {
    return (
      <div className="mod-inv-friends-lodestar-wrapper">
        <div className="mod-inv-frnds-rslts-wrapper">
          <Input
            label="Name"
            name="group_name"
            onChangeHandler={handleOnChange}
            value={inputValues.group_name}
            spacingTop="10px"
            spacingBottom="20px"
          />
          <Input
            label="Name"
            name="group_name"
            onChangeHandler={handleOnChange}
            value={inputValues.group_name}
            spacingTop="10px"
            spacingBottom="20px"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="mod-type1-wrapper" id="cre-ad" style={{ height: '440px' }}>
      <div className="mod-type1-padder ">
        <Title closeModal={closeModal} title="CREATE A GROUP" />

        <CustomScroller className="mod-cnt-def">
          <form onSubmit={handleSubmit}>
            <Input
              label="Name"
              name="group_name"
              onChangeHandler={handleOnChange}
              value={inputValues.group_name}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <TextArea
              abel="Name"
              name="group_description"
              onChangeHandler={handleOnChange}
              value={inputValues.group_description}
              spacingTop="10px"
              spacingBottom="20px"
            />
            <div className="mod-tabs-wrapper">
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'MEMBERS'}
                text="MEMBERS"
              />
              <Tab
                handleClick={setTopLabel}
                selected={topLabel === 'INVITE'}
                text="INVITE"
              />
            </div>
            {topLabel === 'MEMBERS' ? (
              <LodestarMembers
                friends={localListOfUserFriends}
                isAccountGroupEdit={isAccountGroupEdit}
                accountGroupRid={accountGroupRid}
                handleAddMember={handleAddMember}
              />
            ) : (
              renderInvite()
            )}
          </form>
        </CustomScroller>
        <Button spacingBottom="20px" spacingTop="10px" text="SAVE" />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockFileViewer);
