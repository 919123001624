/*
 * Copyright @ 2021 Lodestar Hub, Inc. All rights reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * URL       	: 	www.lodestarhub.com
 */

import {
  PERSIST_UPSERT_ADVENTURE_MODAL,
  PERSIST_UPSERT_BLOCK_MODAL,
  PERSIST_UPSERT_BLOCK_FILE_MODAL,
  PERSIST_UPSERT_ITINERARY_MODAL,
  PERSIST_UPSERT_ITINERARY_ITEM_MODAL,
  PERSIST_UPSERT_ACCOUNT_GROUP_MODAL,
  PERSIST_UPSERT_ADVENTURE_GROUP_MODAL,
  PERSIST_UPSERT_BLOCK_MODAL_FILE,
  PERSIST_ASSIGNED_USERS_FOR_LIST,
  UPDATE_FEATURE_MAP_RID,
  PERSIST_REC_GOV_CAMPGROUNDS,
  PERSIST_REC_GOV_CAMPSITES,
} from '../constants';

const receiveTripPersister = (status, data) => {
  return {
    type: PERSIST_UPSERT_ADVENTURE_MODAL,
    status,
    data,
  };
};

const receiveActivityModalFeatureMapRid = (featureRid) => {
  return {
    type: UPDATE_FEATURE_MAP_RID,
    featureRid,
  };
};

export const receiveItineraryItemModalPersister = (openType, actionPayload) => {
  return {
    type: PERSIST_UPSERT_ITINERARY_ITEM_MODAL,
    actionPayload,
    openType,
  };
};

export const receiveItineraryModalPersister = (
  openType,
  actionPayload,
  data,
) => {
  return {
    type: PERSIST_UPSERT_ITINERARY_MODAL,
    actionPayload,
    openType,
    data,
  };
};
export const receiveAccountGroupPersister = (data) => {
  return {
    type: PERSIST_UPSERT_ACCOUNT_GROUP_MODAL,
    data,
  };
};
export const receiveAdventureGroupPersister = (data) => {
  return {
    type: PERSIST_UPSERT_ADVENTURE_GROUP_MODAL,
    data,
  };
};

export const receiveBlockModalPersister = (openType, actionPayload) => {
  return {
    type: PERSIST_UPSERT_BLOCK_MODAL,
    openType,
    actionPayload,
  };
};

export const receiveBlockModalFilesPersister = (data) => {
  return {
    type: PERSIST_UPSERT_BLOCK_MODAL_FILE,
    data,
  };
};
export const receiveBlockFileModalPersister = (data) => {
  return {
    type: PERSIST_UPSERT_BLOCK_FILE_MODAL,
    data,
  };
};

export const receivePersistAssignUserModalData = (data) => {
  return {
    type: PERSIST_ASSIGNED_USERS_FOR_LIST,
    data,
  };
};

export const receivePersistRecGovCampGroundsData = (data) => {
  return {
    type: PERSIST_REC_GOV_CAMPGROUNDS,
    data,
  }
}

export const receivePersistRecGovCampSitesData = (data) => {
  return {
    type: PERSIST_REC_GOV_CAMPSITES,
    data,
  }
}
/*
 * Author       : 	Paul Choi
 * Date Created	: 	20-Apr-2021
 * Purpose      : 	this function will be used for making persisting data when a user is using a modal to create trip,
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 *  status: **boolea** , true means to hold data while false means dont
 *  data: where data will be held, when it's not holding ,data should be flushed
 *
 * }
 */
export const persistTripData = (status, data) => (dispatch) => {
  return dispatch(receiveTripPersister(status, data));
};

/*
 * Author       : 	Paul Choi
 * Date Created	: 	20-Apr-2021
 * Purpose      : 	this function is used to persist data of account group modal,
 * Updates 		: 	DD-MON-YYYY description of update
 * Pararmeters  :{
 *  data: object data of account group
 * }
 */

export const persistAccountGroupData = (data) => (dispatch) => {
  return dispatch(receiveAccountGroupPersister(data));
};

export const persistAdventureGroupData = (data) => (dispatch) => {
  return dispatch(receiveAdventureGroupPersister(data));
};

export const persistRecGovCampGrounds = (data) => (dispatch) => {
  return dispatch(receivePersistRecGovCampGroundsData(data));
}

export const persistRecGovCampSites = (data) => (dispatch) => {
  return dispatch(receivePersistRecGovCampSitesData(data));
}

/*
 openType{
  isTransitioning,
  isNew,
  isEdit,
  isSaving
 }
 actionPayload
{
  parentItineraryData,
  itineraryItemData,
  persistedItineraryItemModalData
 }
*/
export const persistItineraryItemData = (openType, actionPayload) => (
  dispatch,
) => {
  return dispatch(receiveItineraryItemModalPersister(openType, actionPayload));
};

export const persistItineraryData = (openType, actionPayload) => (dispatch) => {
  // let test = receiveItineraryModalPersister(openType, actionPayload);
 
  return dispatch(receiveItineraryModalPersister(openType, actionPayload));
};

export const persistBlockModalData = (openType, actionPayload) => (
  dispatch,
) => {
  dispatch(receiveBlockModalPersister(openType, actionPayload));
};


//used to update feature map rid between transitions of activioty modal to other modals
export const updateActivityModalFeatureMapRid = (featureRid) => (dispatch) => {
  dispatch(receiveActivityModalFeatureMapRid(featureRid));
};
