import { upsertItineraryItem } from 'actions/itinerary_actions';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const useCreateItineraryDayButton = () => {
  const itinerary_info = useSelector(
    (state) => state.entities?.itinerary?.itinerary_info,
  );
  const userInfo = useSelector((state) => state.session.user?.userInfo[0]);

  const { invalidateQueries } = useInvalidateAdventureData();
  const dispatch = useDispatch();
  const addDay = async () => {
    await dispatch(
      upsertItineraryItem(
        {
          adventure_rid: itinerary_info.adventure_rid,
          itinerary_item_rid: null,
          parent_itinerary_item_rid: null,
          itinerary_rid: itinerary_info.itinerary_rid,
          explorer_rid: userInfo.explorer_rid,
          item_name: '',
          item_description: '',
          item_start_date: moment(itinerary_info.end_date)
            .utcOffset(2)
            .add(1, 'days')
            .format('dddd D MMM YYYY'),
          item_end_date: moment(itinerary_info.end_date)
            .utcOffset(2)
            .add(1, 'days')
            .format('dddd D MMM YYYY'),
          item_start_time: null,
          item_end_time: null,
          all_day_flag: true,
        },
        userInfo,
      ),
    );
    invalidateQueries();
  };

  return { addDay };
};

export default useCreateItineraryDayButton;
