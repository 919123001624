export const svgChildCount = (color, className, outerLayer, width, height) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0, 0, 15.18, 16.56"
    className={outerLayer}
  >
    <g id="Layer_1">
      <g>
        <path
          d="M13.283,16.56 L4.658,16.56 C3.61,16.56 2.76,15.71 2.76,14.662 L2.76,13.455 L9.143,13.455 C10.761,13.451 12.071,12.141 12.075,10.523 L12.075,3.45 L13.283,3.45 C14.33,3.45 15.18,4.3 15.18,5.347 L15.18,14.662 C15.18,15.71 14.33,16.56 13.283,16.56 z"
          fill="#000000"
          className={className}
        />
        <path
          d="M9.142,-0 L1.898,-0 C0.85,-0 0,0.85 0,1.897 L0,10.523 C0,11.57 0.85,12.42 1.898,12.42 L9.142,12.42 C10.19,12.42 11.04,11.57 11.04,10.523 L11.04,1.897 C11.04,0.85 10.19,-0 9.142,-0 z"
          fill="#000000"
          className={className}
        />
      </g>
    </g>
  </svg>
);
