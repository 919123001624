export function utility (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g>
        <title>69</title>
        <path
          className={className}
          fill={color}
          d="M20.8,12,17.44,5.8c-.76-1.38-3-1.58-3.05-1.59a.52.52,0,0,0-.49.27c-.11.21-1,2.1-.31,3.41l1.95,3.55H10.09a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h8.72a3.18,3.18,0,1,1,0,6.35H5.19a3.18,3.18,0,1,1,0-6.35H7.8A.51.51,0,0,0,8.3,12c.06-.41.13-.89.21-1.35a.47.47,0,0,0-.13-.4.5.5,0,0,0-.4-.18.61.61,0,0,1-.61-.61.64.64,0,0,1,1-.5.46.46,0,0,0,.41.07.52.52,0,0,0,.32-.26,20.45,20.45,0,0,0,.71-2.33.51.51,0,0,0-.25-.56L7.89,5a.5.5,0,0,0-.56.06L5.89,6.33a.45.45,0,0,0-.12.16s-2.11,4.21-2.83,5.6a4.17,4.17,0,0,0,2.25,7.7H18.81a4.17,4.17,0,0,0,2-7.84Zm-2-.51H16.68l-2.21-4a2.93,2.93,0,0,1,.18-2.15,2.82,2.82,0,0,1,1.91,1l2.85,5.21A3.83,3.83,0,0,0,18.81,11.44ZM6.62,7l1.1-.95,1,.53c-.1.39-.24.91-.38,1.31A1.64,1.64,0,0,0,6.37,9.48,1.61,1.61,0,0,0,7.45,11c0,.15,0,.31-.08.45H5.19a3.7,3.7,0,0,0-.83.09Z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M9.7,16.12h5.17a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H9.7a.5.5,0,0,0-.5.5A.51.51,0,0,0,9.7,16.12Z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M3.41,15.62a1.74,1.74,0,1,0,1.74-1.74A1.74,1.74,0,0,0,3.41,15.62Zm2.48,0a.74.74,0,1,1-.74-.74A.74.74,0,0,1,5.89,15.62Z"
        ></path>
        <path
          className={className}
          fill={color}
          d="M20.86,15.62a1.74,1.74,0,1,0-1.74,1.73A1.74,1.74,0,0,0,20.86,15.62Zm-2.48,0a.74.74,0,0,1,1.48,0,.74.74,0,0,1-1.48,0Z"
        ></path>
      </g>
    </svg>
  );
}