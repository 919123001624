import React from 'react';

import Switch from 'react-switch';

const Toggle = ({
  text,
  setToggleState,
  name,
  spacingTop,
  spacingBottom,
  value,
  disabled,
  customClassName,
  onColor,
  offColor,
  id,
  ...rest
}) => {
  // debugger
  return (
    <div
      className={`mod-togl-wrapper ${customClassName && customClassName}`}
      style={{ marginTop: spacingTop, marginBottom: spacingBottom }}
      id={id}
    >
      <label>
        <Switch
          onChange={() => {
            setToggleState(name, value);
          }}
          checked={value}
          onColor={onColor ? onColor : '#F7B844'}
          offColor={offColor}
          checkedIcon={false}
          uncheckedIcon={false}
          disabled={disabled ? true : false}
        />
        <span>{text}</span>
      </label>
    </div>
  );
};

export default Toggle;
