export const svgEdit = (
  color,
  className,
  outerLayer,
  width = 14,
  height = 14,
) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      viewBox="0, 0, 90, 90.001"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M34.886,46.304 L32.116,56.673 C31.875,57.585 32.417,58.124 33.329,57.88 L43.696,55.112 C45.251,54.698 47.452,53.425 48.59,52.292 L79.158,21.72 L68.277,10.841 L37.707,41.413 C36.568,42.548 35.3,44.749 34.886,46.304 z"
          fill={color}
        />
        <path
          className={className}
          d="M87.744,13.132 C90.751,10.126 90.753,5.257 87.746,2.253 C84.743,-0.751 79.869,-0.751 76.865,2.253 L71.691,7.427 L82.57,18.306 L87.744,13.132 z"
          fill={color}
        />
        <path
          className={className}
          d="M84.707,29.56 C81.783,29.56 79.413,31.931 79.413,34.855 L79.413,78.884 C79.413,79.175 79.176,79.413 78.884,79.413 L11.118,79.413 C10.826,79.413 10.589,79.175 10.589,78.884 L10.589,11.118 C10.589,10.825 10.826,10.589 11.118,10.589 L55.148,10.589 C58.07,10.589 60.441,8.218 60.441,5.296 C60.441,2.37 58.07,0.001 55.148,0.001 L10.588,0.001 C4.75,0.001 0,4.751 0,10.589 L0,79.413 C0,85.251 4.75,90.001 10.588,90.001 L79.412,90.001 C85.25,90.001 90,85.251 90,79.414 L90,34.855 C90,31.931 87.631,29.56 84.707,29.56 z"
          fill={color}
        />
      </g>
    </svg>
  );
};