export function social (color, className, outerLayer) {
  return (
    <svg version="1.1" viewBox="0, 0, 90, 90" className={outerLayer}>
      <g id="Layer_1">
        <path
          className={className}
          d="M46.175,12.654 C46.187,19.657 40.517,25.343 33.514,25.351 C26.511,25.359 20.829,19.686 20.825,12.682 C20.821,5.679 26.497,0 33.5,0 C40.495,-0.006 46.17,5.66 46.175,12.654 z M22.5,65.241 C29.486,65.23 35.141,59.559 35.133,52.573 C35.125,45.587 29.458,39.929 22.472,39.933 C15.486,39.937 9.825,45.601 9.825,52.587 C9.83,59.582 15.505,65.247 22.5,65.241 z M34.862,63.128 C31.777,66.749 27.258,68.836 22.5,68.836 C17.742,68.836 13.223,66.749 10.138,63.128 C3.217,67.605 -0,76.134 -0,85.938 C-0,91.354 45,91.354 45,85.938 C45,76.134 41.783,67.605 34.862,63.128 z M79.862,46.156 C76.777,49.778 72.258,51.864 67.5,51.864 C62.742,51.864 58.223,49.778 55.138,46.156 C48.217,50.633 45,59.163 45,68.966 C45,74.382 90,74.382 90,68.966 C90,59.163 86.783,50.633 79.862,46.156 z M53.112,43.188 C50.947,39.129 50.629,34.336 52.238,30.026 C50.691,27.271 48.504,24.928 45.862,23.195 C42.777,26.816 38.258,28.903 33.5,28.903 C28.742,28.903 24.223,26.816 21.138,23.195 C15.474,26.858 12.295,33.237 11.325,40.806 C15.69,36.659 21.991,35.267 27.697,37.189 C33.403,39.111 37.578,44.031 38.545,49.974 C41.493,49.865 44.317,49.657 46.805,49.353 C48.489,46.91 50.631,44.816 53.112,43.188 z M67.5,48.27 C74.486,48.258 80.141,42.588 80.133,35.602 C80.125,28.616 74.458,22.958 67.472,22.962 C60.486,22.965 54.825,28.63 54.825,35.616 C54.83,42.61 60.505,48.276 67.5,48.27 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}