const GearIcon = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8393 17.1888C29.9438 16.4742 30 15.7436 30 15C30 14.2564 29.9438 13.5258 29.8393 12.8112L26.4502 11.8426C26.1871 10.887 25.8083 9.98006 25.3283 9.13706L27.0409 6.05427C26.1654 4.87784 25.1222 3.83414 23.9457 2.95905L20.8629 4.67169C20.0199 4.19218 19.113 3.81289 18.1574 3.54981L17.1888 0.160715C16.4742 0.0562043 15.7436 0 15 0C14.2564 0 13.5258 0.0562025 12.8112 0.160715L11.8426 3.54981C10.887 3.81289 9.98006 4.19169 9.13706 4.67169L6.05427 2.95905C4.87784 3.83463 3.83414 4.87784 2.95905 6.05427L4.67169 9.13706C4.19218 9.98012 3.81289 10.887 3.54981 11.8426L0.160715 12.8112C0.0562043 13.5258 0 14.2564 0 15C0 15.7436 0.0562025 16.4742 0.160715 17.1888L3.54981 18.1574C3.81289 19.113 4.19169 20.0199 4.67169 20.8629L2.95905 23.9457C3.83463 25.1222 4.87784 26.1659 6.05427 27.041L9.13706 25.3283C9.98012 25.8078 10.887 26.1871 11.8431 26.4502L12.8117 29.8393C13.5258 29.9438 14.2565 30 15 30C15.7435 30 16.4742 29.9438 17.1888 29.8393L18.1574 26.4502C19.113 26.1871 20.0199 25.8083 20.8634 25.3283L23.9462 27.041C25.1227 26.1654 26.1664 25.1217 27.0414 23.9457L25.3288 20.8629C25.8083 20.0199 26.1876 19.113 26.4507 18.1574L29.8393 17.1888ZM14.9996 21.4286C11.4493 21.4286 8.57103 18.5503 8.57103 15C8.57103 11.4497 11.4493 8.5714 14.9996 8.5714C18.5499 8.5714 21.4282 11.4497 21.4282 15C21.4282 18.5503 18.5499 21.4286 14.9996 21.4286Z"
      fill="#273A07"
    />
  </svg>
);

const ShareIcon = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 11.82L11.66 16.98L28.6399 0L0 11.82Z" fill="#273806" />
    <path
      d="M13.0133 18.3332L18.1333 29.9998L29.9866 1.35986L13.0133 18.3332Z"
      fill="#273806"
    />
  </svg>
);

const LikeIcon = (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.8445 10.832H0.834987C0.367147 10.832 0 11.1992 0 11.667V25.0256C0 25.4935 0.367173 25.8606 0.834987 25.8606H5.8445C6.31234 25.8606 6.679 25.4934 6.679 25.0256L6.6791 11.667C6.6796 11.1992 6.31234 10.832 5.8446 10.832H5.8445Z"
      fill="#273A07"
    />
    <path
      d="M29.9996 12.4147C29.9996 11.0541 29.0654 9.67724 27.2814 9.67724H19.1928C20.3484 7.61022 20.6881 4.70289 19.8848 2.57558C19.2948 1.01097 18.1672 0.0980804 16.708 0.00524132L16.6844 0.00333632C15.7349 -0.0549159 14.9128 0.656444 14.8339 1.60455C14.626 3.71438 13.7016 7.4462 12.3766 8.77115C11.2611 9.88668 10.3062 10.354 8.72336 11.1279C8.49425 11.24 8.24399 11.3623 7.97931 11.4942C7.98412 11.5515 7.98703 11.6087 7.98703 11.6675V24.8898C8.17813 24.9552 8.36673 25.0202 8.55202 25.0837C11.1642 25.9842 13.4219 26.7614 16.8726 26.7614H23.4119C25.1964 26.7614 26.1301 25.384 26.1301 24.024C26.1301 23.6202 26.0483 23.2154 25.8861 22.8448C26.4804 22.738 27.0007 22.4521 27.3862 22.0147C27.8236 21.5176 28.0648 20.8567 28.0648 20.1541C28.0648 19.7517 27.983 19.347 27.8212 18.9774C29.254 18.73 30 17.5004 30 16.2846C30 15.5796 29.7487 14.8697 29.2588 14.3499C29.7479 13.8296 29.9997 13.1197 29.9997 12.4147L29.9996 12.4147Z"
      fill="#273A07"
    />
  </svg>
);

const SaveIcon = (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17925 0H0.743736L0 0.751915V14.4367L1.23647 15L4.96157 11.6699L8.68667 15L9.92308 14.4367V0.751915L9.17925 0Z"
      fill="#273A07"
    />
  </svg>
);

const SavedIcon = (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17925 0H0.743736L0 0.751915V14.4367L1.23647 15L4.96157 11.6699L8.68667 15L9.92308 14.4367V0.751915L9.17925 0Z"
      fill="#B34104"
    />
  </svg>
);

const AddIcon = (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{width: "100%", height: "100%"}}
  >
    <path
      d="M30 15C30 6.72632 23.2737 0 15 0C6.72632 0 0 6.72632 0 15C0 23.2737 6.72632 30 15 30C23.2737 30 30 23.2737 30 15ZM23.0526 15.4421C23.0526 16.3579 22.2947 17.1159 21.3789 17.1159H17.1157V21.379C17.1157 22.2948 16.3578 23.0528 15.442 23.0528H14.5577C13.6419 23.0528 12.884 22.2948 12.884 21.379V17.1159H8.62084C7.70503 17.1159 6.9471 16.3579 6.9471 15.4421V14.5579C6.9471 13.6421 7.70503 12.8841 8.62084 12.8841H12.884V8.62097C12.884 7.70516 13.6419 6.94724 14.5577 6.94724H15.442C16.3578 6.94724 17.1157 7.70516 17.1157 8.62097V12.8841H21.3789C22.2947 12.8841 23.0526 13.6421 23.0526 14.5579V15.4421Z"
      fill="#273906"
    />
  </svg>
);

export { GearIcon, ShareIcon, LikeIcon, SaveIcon, AddIcon, SavedIcon };
