export function vehicle (color, className, outerLayer) {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="92.475"
      height="46.66"
      viewBox="0, 0, 92.475, 46.66"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M88.4,21.21 C86.6,20.31 83.95,20.16 80.88,19.99 C76.29,19.73 70.57,19.41 68.07,16.26 C66.69,14.51 66.3,12.31 65.9,9.98 C65.12,5.53 64.15,-0 54.75,-0 L21.7,-0 C21.63,-0 21.56,0.01 21.49,0.02 C21.43,0.03 21.36,0.04 21.3,0.05 C21.28,0.06 21.27,0.06 21.25,0.06 C21.2,0.07 21.15,0.1 21.1,0.12 C21.04,0.14 20.98,0.16 20.92,0.18 C20.85,0.22 20.78,0.26 20.72,0.3 C20.67,0.33 20.62,0.35 20.57,0.39 C20.5,0.44 20.44,0.49 20.38,0.55 C20.35,0.58 20.32,0.6 20.29,0.64 L7.88,14 L2,14 C0.9,14 0,14.89 0,16 L0,37.25 C0,38.35 0.9,39.25 2,39.25 L11.44,39.25 C12.36,43.48 16.13,46.66 20.63,46.66 C25.14,46.66 28.91,43.48 29.83,39.25 L56.3,39.25 C57.22,43.48 60.99,46.66 65.5,46.66 C70,46.66 73.77,43.48 74.69,39.25 L90.25,39.25 C91.28,39.25 92.14,38.47 92.24,37.45 C92.57,34.25 93.26,23.64 88.4,21.21 z M43.5,4 L54.75,4 C60.79,4 61.2,6.37 61.96,10.67 C62.14,11.74 62.35,12.86 62.65,14 L43.5,14 L43.5,4 z M39.5,4 L39.5,14 L26.93,14 L24.34,4 L39.5,4 z M20.73,6.04 L22.8,14 L13.34,14 L20.73,6.04 z M4,18 L6.75,18 L6.75,21.5 L4,21.5 L4,18 z M20.63,42.66 C17.65,42.66 15.22,40.23 15.22,37.25 C15.22,34.26 17.65,31.83 20.63,31.83 C23.62,31.83 26.05,34.26 26.05,37.25 C26.05,40.23 23.62,42.66 20.63,42.66 z M70.91,37.25 C70.91,40.24 68.48,42.66 65.5,42.66 C62.52,42.66 60.09,40.25 60.08,37.27 C60.08,37.26 60.08,37.25 60.08,37.25 C60.08,37.24 60.08,37.23 60.08,37.23 C60.09,34.25 62.52,31.83 65.5,31.83 C68.48,31.83 70.91,34.26 70.91,37.24 L70.91,37.25 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
}