
export const svgTime = (color, className, outerLayer) => {
  return (
    <svg
      id="time"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      className={outerLayer}
      viewBox="0 0 32 32"
    >
      <path
        className={className}
        id="Path_347"
        data-name="Path 347"
        d="M12,5.231a1.231,1.231,0,1,1,2.462,0v9.336l3.323,3.324a1.231,1.231,0,0,1-1.74,1.741L12.36,15.947A1.227,1.227,0,0,1,12,15.022Z"
        transform="translate(2.769 0.923)"
        fill={color}
      />
      <path
        className={className}
        id="Path_348"
        data-name="Path 348"
        d="M16,32A16,16,0,1,0,0,16,16,16,0,0,0,16,32Zm0-2.462A13.538,13.538,0,1,0,2.462,16,13.538,13.538,0,0,0,16,29.538Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
