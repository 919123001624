export function trailSports (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      viewBox="0 0 100 100"
      x="0px"
      y="0px"
      className={outerLayer}
    >
      <path
        className={className}
        d="M68.80908,26.44141a3.876,3.876,0,0,0,2.76123,1.14453H82.5625a3.90446,3.90446,0,0,0,.90918-7.70264,3.88812,3.88812,0,0,0-1.23877-1.4585,3.99371,3.99371,0,0,0-2.82617-.686,5.2519,5.2519,0,0,0-1.52637-1.19482,5.33563,5.33563,0,0,0-5.84473.7168,5.20981,5.20981,0,0,0-1.62109,2.68652,3.907,3.907,0,0,0-1.60547,6.49463Zm1.31543-4.00488a1.9,1.9,0,0,1,1.15625-.6416,1.17453,1.17453,0,0,0,1.00781-1.03516,3.22125,3.22125,0,0,1,5.82275-1.43848,1.19153,1.19153,0,0,0,1.30762.45605,1.883,1.883,0,0,1,.26953-.05859,1.94816,1.94816,0,0,1,1.38184.3335,1.92571,1.92571,0,0,1,.69775.93457,1.176,1.176,0,0,0,1.00439.79834,1.90625,1.90625,0,0,1-.21,3.80078H71.57031a1.89053,1.89053,0,0,1-1.34717-.55859l-.001-.001a1.8877,1.8877,0,0,1-.55762-1.34619A1.907,1.907,0,0,1,70.12451,22.43652Z"
      ></path>
      <path
        className={className}
        d="M48.874,16.51123c8.61377,2.00586,13.603,4.958,14.83008,8.7749,1.88867,5.87354-5.01758,13.75-11.11133,20.69873-2.03174,2.31689-3.95068,4.50537-5.56982,6.61768a.935.935,0,0,0-.07617.11475c-5.60742,9.87988-6.7334,17.71875-3.34668,23.29785,4.90918,8.0874,17.55957,8.60107,20.68945,8.60107.37842,0,.61816-.00732.688-.00977a1.00018,1.00018,0,1,0-.07324-1.999c-.14648.00342-14.69482.44287-19.59473-7.63037C42.3335,70.07471,43.4585,62.938,48.65186,53.76465c1.57129-2.04346,3.45313-4.18994,5.44482-6.46143,6.7583-7.707,13.74707-15.67676,11.51172-22.62939-1.47754-4.59619-6.80322-7.90332-16.28076-10.11084a1.00018,1.00018,0,1,0-.45361,1.94824Z"
      ></path>
      <path
        className={className}
        d="M30.46533,16.51123C42.13623,19.229,48.231,22.44141,49.09717,26.332c1.28516,5.77051-8.43848,13.9668-21.30127,24.09619C19.874,56.9541,16.02881,63.63672,16.36719,70.29c.46631,9.1792,8.71045,14.77148,9.06152,15.00537a1.00011,1.00011,0,0,0,1.1084-1.665c-.07764-.05127-7.75684-5.25732-8.17285-13.44189-.3042-5.98633,3.29688-12.11523,10.686-18.20264,13.85352-10.90918,23.541-19.1665,21.99951-26.08838-1.08008-4.84814-7.28857-8.34375-20.13135-11.33447a1.00012,1.00012,0,1,0-.45312,1.94824Z"
      ></path>
      <path
        className={className}
        d="M14.53174,39.49854H22.1499V50.147a1,1,0,0,0,2,0V39.49854h7.61816a1.00017,1.00017,0,0,0,.91553-1.40234L30.775,33.75391h.05219a.99989.99989,0,0,0,.90527-1.4248L29.65527,27.901a.98828.98828,0,0,0,.51465-1.45471l-6.167-10.0874a1.0387,1.0387,0,0,0-1.70605,0l-6.167,10.0874a.99986.99986,0,0,0,.853,1.52148h.04254L14.98,32.3291a.988.988,0,0,0,.57312,1.36053l-1.93689,4.40656a1.00017,1.00017,0,0,0,.91553,1.40234ZM23.1499,18.79736l4.38379,7.17041H18.76611ZM19.2345,27.96777h8.243l1.77594,3.78613H17.459Zm-1.525,5.78613H28.59027l1.64606,3.74463H16.06348Z"
      ></path>
      <path
        className={className}
        d="M62.06055,64.04639a.99919.99919,0,0,0,.8374.45361h9.04736V77.08008a1,1,0,0,0,2,0V64.5h9.04736a1.00017,1.00017,0,0,0,.91553-1.40234l-2.32794-5.29443H81.895a.99989.99989,0,0,0,.90527-1.4248l-2.50574-5.34229a.98973.98973,0,0,0,.69324-1.5L73.79834,37.77588a1.0387,1.0387,0,0,0-1.70605,0L64.90283,49.53613a.99986.99986,0,0,0,.853,1.52148H66.066l-2.49567,5.3208a.99053.99053,0,0,0,.74847,1.40485l-2.33636,5.31439A1.00011,1.00011,0,0,0,62.06055,64.04639Zm10.88477-23.832,5.40625,8.84326H67.53906ZM68.27509,51.05762h9.82043l2.22577,4.74561h-14.272Zm-1.78027,6.74561H79.39532L81.46045,62.5H64.43018Z"
      ></path>
    </svg>
  );
}