"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react/no-array-index-key */
var react_1 = __importStar(require("react"));
var dateValidation_1 = require("./dateValidation");
var dateMap_1 = require("./dateMap");
var helpers_1 = require("./helpers");
var styles_1 = require("./styles");
var SelectDatepicker = function (props) {
    var _a = react_1.useState(true), isDirty = _a[0], setIsDirty = _a[1];
    var _b = react_1.useState(false), hasError = _b[0], setHasError = _b[1];
    var _c = react_1.useState(), error = _c[0], setError = _c[1];
    var _d = react_1.useState(helpers_1.spreadDateToObject(props.value)), date = _d[0], setDate = _d[1];
    var orderArray = react_1.useMemo(function () { return props.format && props.format.split('/'); }, [
        props.format,
    ]);
    /**
     * Call onDateChange prop with the provided date object
     */
    var onDateChange = react_1.useCallback(function (newDate) {
        if (newDate)
            props.onDateChange(newDate);
    }, [props]);
    /**
     * Build a Date object and call the onDateChange function
     */
    var validDateChange = react_1.useCallback(function () {
        var newDate = dateValidation_1.buildDateFromInput(date.day, date.month, date.year);
        onDateChange(newDate);
    }, [date.day, date.month, date.year, onDateChange]);
    /**
     * Sets the error state and calls the onDateChange function with a null value
     */
    var renderError = react_1.useCallback(function (err, hasErr) {
        setError(err);
        setHasError(hasErr);
        onDateChange(null);
    }, [onDateChange]);
    /**
     * Validates if a the inputs form a valid date
     * Returns null if values are not set
     * Returns null and errors if date is not valid
     * Returns a valid date object when everything passes
     */
    var validate = react_1.useCallback(function () {
        var day = date.day, month = date.month, year = date.year;
        // Must contain values
        if (!day || !month || !year) {
            onDateChange(null);
            return;
        }
        // Validate date input
        var errorString = dateValidation_1.isValidDate(day, month, year, props);
        if (errorString !== '') {
            renderError(errorString, true);
            return;
        }
        validDateChange();
    }, [date, onDateChange, props, renderError, validDateChange]);
    /**
     * Sets the date state when an input value changes
     */
    var onInputChange = react_1.useCallback(function (e) {
        var _a;
        setDate(__assign(__assign({}, date), (_a = {}, _a[e.target.name] = e.target.value, _a)));
        setIsDirty(true);
    }, [date]);
    /**
     * Create an input field with a form label
     */
    var inputField = react_1.useCallback(function (id, label, value, options) {
        var className = "rsd_" + id + "-container";
        return (react_1.default.createElement("div", { className: "" + className, style: styles_1.flexColumn },
            props.showLabels ? react_1.default.createElement("label", { htmlFor: id }, label) : null,
            react_1.default.createElement("select", { className: "" + (hasError ? 'has-error' : ''), id: id, name: id, value: value, onChange: onInputChange }, options.map(function (option, i) {
                return (react_1.default.createElement(react_1.default.Fragment, { key: option + "-" + i }, option));
            }))));
    }, [hasError, onInputChange, props.showLabels]);
    /**
     * Creates an object with all input field elements
     */
    var dateField = react_1.useMemo(function () {
        var showPlaceholders = props.showPlaceholders, monthNames = props.monthNames, maxDate = props.maxDate, minDate = props.minDate, labels = props.labels;
        var dayLabel = (labels && labels.day) || 'Day';
        var monthLabel = (labels && labels.month) || 'Month';
        var yearLabel = (labels && labels.year) || 'Year';
        var fields = {
            day: inputField('day', dayLabel, date.day, dateMap_1.getDays(showPlaceholders && showPlaceholders, dayLabel)),
            month: inputField('month', monthLabel, date.month, dateMap_1.getMonths(showPlaceholders && showPlaceholders, monthLabel, monthNames)),
            year: inputField('year', yearLabel, date.year, dateMap_1.getYears(maxDate, minDate, showPlaceholders && showPlaceholders, date.year, yearLabel)),
        };
        return fields;
    }, [date.day, date.month, date.year, inputField, props]);
    /**
     * When ever the date state changes then clear errors and validate the date
     */
    react_1.useEffect(function () {
        if (isDirty) {
            setError('');
            setHasError(false);
            validate();
            setIsDirty(false);
        }
    }, [isDirty]);
    react_1.useEffect(function () {
        var value = props.value;
        var day = date.day, month = date.month, year = date.year;
        if (value !== null && value !== dateValidation_1.buildDateFromInput(day, month, year)) {
            setDate(helpers_1.spreadDateToObject(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    return (react_1.default.createElement("div", { className: "date-select-picker-module--wrapper" },
        react_1.default.createElement("div", { className: "date-select-picker-module--container", style: styles_1.flex }, orderArray &&
            orderArray.map(function (key, i) {
                return (react_1.default.createElement(react_1.default.Fragment, { key: key + "-" + i }, dateField[key]));
            })),
        props.showErrors && hasError && (react_1.default.createElement("div", { className: "error-message" }, error))));
};
exports.default = SelectDatepicker;
SelectDatepicker.defaultProps = {
    value: null,
    showLabels: true,
    showPlaceholders: true,
    showErrors: true,
    format: 'month/day/year',
    className: '',
};
