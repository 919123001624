import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ListButton } from './other_modules';
import { convertTime } from '../../../util/helpers/time_funcs';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: default;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? '#F7B844' : 'rgba(255,255,255,0.5)'};
  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className} onClick={() => props.onChange()}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox className="itinerary-item-checkbox" checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
const ItineraryItemList = ({
  itineraryItem,
  selectedItineraryItems,
  handleSelectItineraryItem,
  selectedItinerary,
}) => {
  const [displayChildren, setDisplayChildren] = useState(true);
  const {
    all_day_flag,
    item_description,
    item_end_date,
    item_end_time,
    item_header,
    item_level,
    item_name,
    item_start_date,
    item_start_time,
    itinerary_item_rid,
    itinerary_rid,
    parent_itinerary_rid,
    parent_itinerary_item_rid,
    block_name,
  } = itineraryItem;
  if (itinerary_item_rid === selectedItinerary) {
    selectedItineraryItems[itinerary_item_rid] = true;
    if (parent_itinerary_item_rid) selectedItineraryItems[parent_itinerary_item_rid] = true;
  }
  if (itineraryItem.children) {
    if (itineraryItem.children.some(e => e.itinerary_item_rid === selectedItinerary)) {
      selectedItineraryItems[itinerary_item_rid] = true;
    }
  }
  let listWrapperWidth =
    itineraryItem.item_level === 1
      ? '99%'
      : `calc(99% - ${(itineraryItem.item_level - 1) * 20}px)`;
  return (
    <>
      <div
        className="itinerary-item-list-item-wrapper"
        style={{
          width: listWrapperWidth,
        }}
      >
        <div className="itinerary-item-list-section itinerary-item-list-section--left ">
          <div className="itinerary-item-list-item itinerary-item-list-item--left">
            {/* <ListButton
              block={itineraryItem}
              isOpen={displayChildren}
              handleClick={setDisplayChildren}
            /> */}
            <div className="itinerary-item-list-item itinerary-item-list-item--middle">
              <Checkbox
                onChange={() => {
                  handleSelectItineraryItem(itineraryItem);
                }}
                checked={selectedItineraryItems[itinerary_item_rid]}
              />
            </div>
          </div>
        </div>
        <div className="itinerary-item-list-section itinerary-item-list-section--right ">
          <div className="itinerary-item-list-right-section itinerary-item-list-right-section--top">
            <div className="itinerary-item-list-item itinerary-item-list-item--right">
              <div className="itineray-itme-list-item-right itineray-itme-list-item-right--top">
                {all_day_flag ? item_header : item_description}
                {': '}
                {item_level === 1
                  ? moment(item_end_date).utcOffset(2).format('ddd, MMM D, YYYY')
                  : `${convertTime(
                    itineraryItem.item_start_time,
                  )} - ${convertTime(itineraryItem.item_end_time)}`}
              </div>
            </div>
          </div>
          <div
            className="itinerary-item-list-right-section itinerary-item-list-right-section--bottom"
            style={{
              display: block_name && block_name[0] ? 'flex' : 'none',
            }}
          >
            <span>{block_name.join(', ')}</span>
          </div>
          {/* <div className="itineray-itme-list-item-right itineray-itme-list-item-right--bottom">
            {item_level === 1
              ? moment(item_end_date).utcOffset(2).format("D MMM YYYY")
              : `${convertTime(itineraryItem.item_start_time)} - ${convertTime(
                  itineraryItem.item_end_time
                )}`}
          </div> */}
        </div>
      </div>
      {displayChildren &&
        itineraryItem.children &&
        itineraryItem.children.length > 0 &&
        itineraryItem.children.map((ele, idx) => (
          <ItineraryItemList
            itineraryItem={ele}
            selectedItineraryItems={selectedItineraryItems}
            handleSelectItineraryItem={handleSelectItineraryItem}
            selectedItinerary={selectedItinerary}
          />
        ))}
    </>
  );
};

export default ItineraryItemList;
