import Box from '@material-ui/core/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { svgDelete, svgDocument, svgEdit, svgEye } from 'components/svg_module';
const FileAccordion = ({ file, deleteFunc, editFunc }) => {
  const viewFunc = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      file.full_url ? file.full_url : file.file_location,
      '_blank', // <- This is what makes it open in a new window.
    );
  };
  function isImageType(url) {
    if (!url) return false;
    return (
      url.includes('jpeg') ||
      url.includes('jpg') ||
      url.includes('png') ||
      url.includes('svg') ||
      url.includes('screen_shot')
    );
  }

  const image_url = file.file_location ? file.file_location : file.full_url;
  console.log(file);
  return (
    <Card sx={{ maxWidth: 345 }}>
      {isImageType(image_url) ? (
        <CardMedia
          sx={{ height: 140 }}
          image={image_url}
          title={file.file_name}
        />
      ) : (
        <a target="_blank" href={image_url}>
          <Box
            height={140}
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
          >
            {svgDocument('#FFFFFF', 'pdf-file-svg', 'pdf-file-svg-outer')}
          </Box>
        </a>
      )}
      <CardContent>
        <Typography>{file.file_note || 'No description'}</Typography>
      </CardContent>
      <CardActions className="file-actions">
        <Button onClick={editFunc} size="small">
          {svgEdit(
            'color',
            'file-accordion-edit-svg',
            'file-accordion-edit-svg-outerlayer',
          )}
        </Button>
        <Button size="small">
          <a
            className="file-accordion-link"
            rel="noopener noreferrer"
            href={`${file.full_url ? file.full_url : file.file_location}`}
            target="_blank"
          >
            {svgEye(
              'color',
              'file-accordion-view-svg',
              'file-accordion-view-svg-outerlayer',
            )}
          </a>
        </Button>
        <Button onClick={deleteFunc} size="small">
          {svgDelete(
            'color',
            'file-accordion-edit-svg',
            'file-accordion-edit-svg-outerlayer',
          )}
        </Button>
      </CardActions>
    </Card>
    // <Accordion className="file-accordion-wrapper">
    //   <AccordionSummary
    //     expandIcon={<ExpandMoreIcon />}
    //     aria-label="Expand"
    //     aria-controls="additional-actions1-content"
    //     id="additional-actions1-header"
    //     className="file-accordion-container"
    //   >
    //     <FormControlLabel
    //       aria-label="Acknowledge"
    //       onClick={(event) => {
    //         event.preventDefault();
    //         event.stopPropagation();
    //       }}
    //       onFocus={(event) => {
    //         event.preventDefault();
    //         event.stopPropagation();
    //       }}
    //       control={<div className="file-accordion-custom-button"></div>}
    //       label={<span>{file.file_name}</span>}
    //       className="file-accordion-custom-form-label"
    //     />
    //   </AccordionSummary>
    //   <AccordionDetails>
    //     <Typography
    //       color="textSecondary"
    //       className="file-accordion-detail-wrapper"
    //     >
    //       <>{file.file_location && checkIfImageExists(file.file_location)}</>
    //       <div className="file-accordion-file-wrapper-buttons">
    //         <button onClick={editFunc} className="file-accordion-button">
    //           {svgEdit(
    //             'color',
    //             'file-accordion-edit-svg',
    //             'file-accordion-edit-svg-outerlayer',
    //           )}
    //         </button>
    //         <button
    //           className="file-accordion-button"
    //           onClick={(e) => viewFunc(e)}
    //         >
    //           <a
    //             className="file-accordion-link"
    //             rel="noopener noreferrer"
    //             href={`${file.full_url ? file.full_url : file.file_location}`}
    //             target="_blank"
    //           >
    //             {svgEye(
    //               'color',
    //               'file-accordion-view-svg',
    //               'file-accordion-view-svg-outerlayer',
    //             )}
    //           </a>
    //         </button>
    //         <button onClick={deleteFunc} className="file-accordion-button">
    //           {svgDelete(
    //             'color',
    //             'file-accordion-edit-svg',
    //             'file-accordion-edit-svg-outerlayer',
    //           )}
    //         </button>
    //       </div>
    //     </Typography>
    //   </AccordionDetails>
    // </Accordion>
  );
};

export default FileAccordion;
