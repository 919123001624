// import Accordion from '@material-ui/core/Accordion';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel } from '@mui/material';
import moment from 'moment';
const ItineraryAccordion = ({
  itinerary,
  handleSelectItineraryItem,
  selectedItineraryItem,
  ...props
}) => {
  const {
    itinerary_title,
    start_date,
    end_date,
    duration,
    itinerary_items,
  } = itinerary;
  let counter = 0;
  return (
    <Accordion className="itinerary-accordion-wrapper">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // expandIcon={svgEye('color', 'file-accordion-view-svg', 'file-accordion-view-svg-outerlayer')}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        className={`itinerary-accordion-container ${
          selectedItineraryItem ? 'completed' : ''
        }`}
      >
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onHover={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          control={<div className="itinerary-accordion-custom-button"></div>}
          // control={<div className="itiinerary-accordion-custom-button"></div>}
          label={
            <>
              {/* <span>
                <a
                  className="file-accordion-link"
                  rel="noopener noreferrer"
                  href={`${file.full_url}`}
                  target="_blank"
                >
                  {svgEye(
                    "color",
                    "file-accordion-view-svg",
                    "file-accordion-view-svg-outerlayer"
                  )}
                </a>
              </span> */}
              <span>{`${itinerary_title}: ${duration}, ${moment(start_date)
                .utcOffset(2)
                .format('MMM DD, YYYY')} ~ ${moment(end_date)
                .utcOffset(2)
                .format('MMMM DD, YYYY')}`}</span>
            </>
          }
          className="itinerary-accordion-custom-form-label"
        />
      </AccordionSummary>
      <AccordionDetails>
        <section className="itinerary-accordion-body">
          <span className="itinerary-accordion-contents-text">
            {' '}
            Please select a date for this itinerary
          </span>
          <div className="itinerary-accordion-contents-wrapper">
            {itinerary_items.length > 0 &&
              itinerary_items
                .sort(function (a, b) {
                  return (
                    new Date(a.item_start_date) - new Date(b.item_start_date)
                  );
                })
                .map((itineraryItem, idx) => {
                  if (itineraryItem.all_day_flag) {
                    counter += 1;
                  }
                  const handleClick = handleSelectItineraryItem(
                    itineraryItem.itinerary_item_rid,
                  );
                  return (
                    <ItineraryContent
                      itineraryItem={itineraryItem}
                      itemTitle={
                        itineraryItem.all_day_flag
                          ? `Day ${counter}`
                          : itineraryItem.item_name
                      }
                      dayNumber={`Day ${counter}`}
                      handleSelectItineraryItem={handleClick}
                      selectedItineraryItem={selectedItineraryItem}
                    />
                  );
                })}
          </div>
        </section>
      </AccordionDetails>
    </Accordion>
  );
};

const ItineraryContent = ({
  itineraryItem,
  itemTitle,
  selectedItineraryItem,
  dayNumber,
  handleSelectItineraryItem,
}) => {
  const {
    all_day_flag,
    item_start_date,
    item_end_date,
    itinerary_item_rid,
  } = itineraryItem;
  let startTime, endTime;
  if (!all_day_flag) {
    startTime = item_start_date.split('T')[1];
    endTime = item_end_date.split('T')[1];
  }
  return (
    <button
      className={`itinerary-accordion-itinerary-item-button ${
        selectedItineraryItem === itinerary_item_rid
          ? 'itinerary-accordion-selected'
          : ''
      }`}
      onClick={(e) => handleSelectItineraryItem(e)}
    >
      <p>
        {all_day_flag ? itemTitle : `${dayNumber} - ${itemTitle}`}:{' '}
        {all_day_flag
          ? moment(item_start_date).utcOffset(2).format('dddd, MMM DD, YYYY')
          : startTime}{' '}
      </p>
    </button>
  );
};
export default ItineraryAccordion;
