export const svgCreate = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      className={outerLayer}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="64"
      height="64"
      viewBox="0, 0, 64, 64"
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M0,8.009 C0,3.586 3.578,0 8.009,0 L55.991,0 C60.414,0 64,3.578 64,8.009 L64,55.991 C64,60.414 60.422,64 55.991,64 L8.009,64 C3.586,64 0,60.422 0,55.991 L0,8.009 L0,8.009 L0,8.009 z M36,16 L28,16 L28,28 L16,28 L16,36 L28,36 L28,48 L36,48 L36,36 L48,36 L48,28 L36,28 L36,16 L36,16 z"
          fill={color}
        />
      </g>
    </svg>
  );
};