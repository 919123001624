export function rv (color, className, outerLayer) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 62.582"
      enableBackground="new 0 0 100 62.582"
      className={outerLayer}
    >
      <path
        className={className}
        d="M35.987,23.397c0.886,0,1.613-0.727,1.613-1.613l0.023-10.153c0-0.886-0.727-1.613-1.613-1.613H12.703
c-0.886,0-1.613,0.727-1.613,1.613l-0.023,10.153c0,0.886,0.727,1.613,1.613,1.613H35.987z M69.356,9.973
c0.409,0,0.728-0.341,0.728-0.75c0-0.159-0.046-0.295-0.137-0.432l-3.68-5.293c-0.137-0.159-0.342-0.272-0.592-0.272h-8.109
c-0.727,0-1.316,0.591-1.316,1.317v4.112c0,0.727,0.59,1.318,1.316,1.318H69.356z M66.903,0.022c0.749,0,1.431,0.341,1.886,0.863
l7.019,10.086c0.159,0.25,0.272,0.522,0.272,0.841c0,0.75-0.636,1.386-1.408,1.386l-12.062-0.023c-4.156,0-7.519,3.362-7.519,7.519
v12.221H0.005V7.678c0,0-0.454-7.678,7.474-7.678L66.903,0.022z"
      ></path>
      <path
        className={className}
        d="M83.645,34.551l-15.537-0.022c-1.387,0-2.499-1.113-2.499-2.499v-7.996c0-1.386,1.112-2.499,2.476-2.499h8.451
c0.432,0,0.817,0.205,1.09,0.522l7.133,10.291c0.159,0.227,0.272,0.499,0.272,0.817C85.03,33.938,84.417,34.551,83.645,34.551z
 M97.16,43.138v-5.293c0-2.135-1.362-3.953-3.248-4.634l-4.588-1.545l-9.155-13.221c-0.454-0.545-1.136-0.886-1.886-0.886H63.768
c-2.361,0-4.27,1.908-4.27,4.271l0.022,15.492H0.005v11.176c0,1.613,1.317,2.931,2.93,2.931h7.86
c0.432-6.52,6.974-11.699,13.607-11.699c6.633,0,13.175,5.18,13.629,11.699h29.167c0.478-6.475,6.997-11.607,13.63-11.607
c6.609,0,13.129,5.133,13.606,11.607h2.612c1.636,0,2.953-1.317,2.953-2.931v-5.36H97.16z"
      ></path>
      <path
        className={className}
        d="M80.783,57.494c2.157,0,3.906-1.749,3.906-3.907s-1.749-3.907-3.906-3.907c-2.158,0-3.885,1.749-3.885,3.907
S78.625,57.494,80.783,57.494z M71.787,53.587c0-4.975,4.043-8.996,8.996-8.996c4.975,0,9.018,4.021,9.018,8.996
s-4.043,8.995-9.018,8.995C75.83,62.582,71.787,58.562,71.787,53.587z"
      ></path>
      <path
        className={className}
        d="M24.402,57.335c2.158,0,3.907-1.749,3.907-3.907s-1.75-3.907-3.907-3.907c-2.158,0-3.907,1.749-3.907,3.907
S22.244,57.335,24.402,57.335z M15.406,53.428c0-4.975,4.043-8.995,8.996-8.995c4.975,0,8.995,4.021,8.995,8.995
s-4.021,8.995-8.995,8.995C19.45,62.423,15.406,58.402,15.406,53.428z"
      ></path>
    </svg>
  );
}