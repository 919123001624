export const svgImpactBig = (color, className, outerLayer) => {
  return (
    <svg version="1.1" viewBox="0, 0, 68.491, 62.978" className={outerLayer}>
      <g id="Layer_1">
        <path
          className={className}
          d="M35.386,30.082 C35.387,30.083 35.401,30.084 35.408,30.086 C35.408,30.093 35.407,30.102 35.407,30.108 C35.636,30.391 35.845,30.685 36.038,30.995 L36.038,30.995 C38.215,34.477 38.387,39.438 38.383,41.022 C38.381,39.388 38.102,34.166 34.825,30.888 C30.903,26.967 24.175,27.338 24.175,27.338 C24.175,27.338 23.804,34.067 27.724,37.989 C29.999,40.263 33.216,41.092 35.518,41.386 C35.473,41.379 35.431,41.375 35.385,41.367 L35.385,48.279 L38.398,48.279 L38.398,30.82 C40.69,30.51 43.83,29.671 46.061,27.437 C49.982,23.516 49.611,16.787 49.611,16.787 C49.611,16.787 42.882,16.416 38.961,20.338 C37.247,22.052 36.355,24.298 35.892,26.285"
          fill={color}
        />
        <path
          className={className}
          d="M37.001,-0 L37.001,3 C52.71,3 65.49,15.78 65.49,31.489 C65.49,47.198 52.71,59.978 37.001,59.978 C21.357,59.978 8.622,47.301 8.519,31.681 L14.49,31.681 L7.245,24.436 L-0,31.681 L5.52,31.681 C5.624,48.955 19.706,62.978 37.002,62.978 C54.365,62.978 68.491,48.852 68.491,31.489 C68.491,14.126 54.364,-0 37.001,-0 z"
          fill={color}
        />
      </g>
    </svg>
  );
};
