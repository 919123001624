import DynamicPublicSiteMenuLink from 'components/nav/dynamic-public-site-menu-link';
import {
  svgActivity,
  svgDropPin,
  svgFolder2,
  svgListBlock,
  svgMap,
  svgTrip,
} from 'components/svg_module';
import { isMobile } from 'react-device-detect';
import { matchPath } from 'react-router';
import { NavLink, withRouter } from 'react-router-dom';
import useMaskingHooks from 'util/custom_hooks/maskUrl';

const DrawerMenu = ({
  sendDataToParent,
  userInfo,
  checkActive,
  setTab,
  location,
  userNotifications,
  ...props
}) => {
  window.process = process;
  const { unmaskURL } = useMaskingHooks();
  const matched = matchPath(location.pathname, {
    path: location.pathname.includes('adventure')
      ? '/hub/adventure/:adventure/:adventure_rid'
      : '/hub/itinerary/:adventure_rid/:itinerary_rid',
    exact: true,
    strict: false,
  });
  let pageAdventureRid;
  let isAddingToAdventureBlock;
  if (matched) {
    isAddingToAdventureBlock = true;
    pageAdventureRid = unmaskURL(matched.params.adventure_rid);
  }
  return (
    <>
      <div className="custom-line-element-wrapper">
        <hr />
        <div className="center-text">CREATE</div>
        <hr />
      </div>
      <div className="action-buttons-section-wrapper">
        <a
          className="action-button-wrapper"
          onClick={() => {
            sendDataToParent(false);
            props.openAdventureModal();
          }}
        >
          {svgTrip(
            '',
            'menu-drawer-svg',
            'svg-outer-layer',
            80,
            50,
            '-4 0 100 60',
          )}
          <div className="block-text-description">TRIP</div>
        </a>
        {/* <a className="action-button-wrapper">
          {svgFriend("", "menu-drawer-svg", "svg-outer-layer", 50, 50)}
          <div className="block-text-description">FRIEND</div>
        </a> */}
        <a
          className="action-button-wrapper"
          onClick={() => {
            sendDataToParent(false);
            props.openFolderModal();
          }}
        >
          {svgFolder2('', 'menu-drawer-svg', 'svg-outer-layer', 50, 50)}
          <div className="block-text-description">FOLDER</div>
        </a>
        {/* <a className="action-button-wrapper">
          {svgGroup("", "menu-drawer-svg", "svg-outer-layer", 70, 50)}

          <div className="block-text-description">GROUP</div>
        </a> */}
        <a
          className="action-button-wrapper"
          onClick={() => {
            sendDataToParent(false);
            let openType = { isNew: true, isFromMenu: true };
            let activityModalTypeRid = '5a';

            let blockPayload = {};
            let uiUpdatePayload = {
              account_rid: userInfo.account_rid,
            };
            if (isAddingToAdventureBlock) {
              activityModalTypeRid = '6a';
              blockPayload.adventure_rid = pageAdventureRid;
              uiUpdatePayload.adventure_rid = pageAdventureRid;
            }
            props.openBlockModal(
              openType,
              activityModalTypeRid,
              uiUpdatePayload,
              blockPayload,
              userInfo,
            );
          }}
        >
          {svgActivity('', 'menu-drawer-svg', 'svg-outer-layer', 50, 50)}
          <div className="block-text-description">ACTIVITY</div>
        </a>
        <a
          className="action-button-wrapper"
          onClick={() => {
            sendDataToParent(false);
            props.openCheckListModal(
              { isNew: true, isFromMenu: true },
              {
                isAdventureBlock: null,
                isNestedAdventureBlock: null,
                isLibraryBlock: true,
                isNestedLibraryBlock: false,
              },
              {
                adventureRid: null,
                nestedLevel: null,
                parentBlockRid: null,
              },
              null,
              userInfo,
            );
          }}
        >
          {svgListBlock('', 'menu-drawer-svg', 'svg-outer-layer', 50, 50)}
          <div className="block-text-description">LIST</div>
        </a>
      </div>
      <div className="custom-line-element-wrapper">
        <hr />
        <div className="center-text">EXPLORE</div>
        <hr />
      </div>
      <div className="map-buttons-section-wrapper">
        <NavLink
          to={{ pathname: '/hub/library', state: { setViewing: 'Maps' } }}
          className="action-button-wrapper"
          onClick={() => sendDataToParent(false)}
        >
          {svgMap('', 'menu-drawer-svg', 'svg-outer-layer', 50, 50)}

          <div className="block-text-description">MAPS</div>
        </NavLink>
        <a
          className="action-button-wrapper"
          onClick={() => {
            sendDataToParent(false);

            let openType = { isNew: true, tabName: 'POI', isFromMenu: true };
            let activityModalTypeRid = '5a';

            let blockPayload = {
              location_flag: true,
            };
            let uiUpdatePayload = {
              account_rid: userInfo.account_rid,
            };
            if (isAddingToAdventureBlock) {
              activityModalTypeRid = '6a';
              blockPayload.adventure_rid = pageAdventureRid;
              uiUpdatePayload.adventure_rid = pageAdventureRid;
            }
            props.openBlockModal(
              openType,
              activityModalTypeRid,
              uiUpdatePayload,
              blockPayload,
              userInfo,
            );
          }}
        >
          {svgDropPin(
            '',
            'menu-drawer-svg  pin',
            'svg-outer-layer',
            50,
            50,
            '-15 -8 90 100',
          )}
          <div className="block-text-description">DROP PIN</div>
        </a>
      </div>

      <div className="custom-line-element-wrapper">
        <hr />
        <div className="center-text">NAVIGATE</div>
        <hr />
      </div>
      <div className="navigation-section-wrapper">
        {/* <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/getting-started"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">GETTING STARTED</div>
        </NavLink> */}
        <a
          className="menu-navigation-button"
          href={
            process.env.REACT_APP_RUN_MODE === 'PROD'
              ? process.env.REACT_APP_PROD_PUB_URL
              : process.env.REACT_APP_RUN_MODE === 'TEST'
              ? process.env.REACT_APP_TEST_PUB_URL
              : process.env.REACT_APP_DEV_PUB_URL
          }
        >
          <div className="menu-navigation-text">HOME</div>
        </a>
        <DynamicPublicSiteMenuLink type={'how-to'} />
        <DynamicPublicSiteMenuLink type={'community'} />
        <DynamicPublicSiteMenuLink type={'our-partners'} />
        {/* <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to={
            process.env.REACT_APP_RUN_MODE === 'PROD'
              ? process.env.REACT_APP_PROD_PUB_URL
              : process.env.REACT_APP_RUN_MODE === 'TEST'
              ? process.env.REACT_APP_TEST_PUB_URL
              : process.env.REACT_APP_DEV_PUB_URL
          }
          onClick={() => sendDataToParent(false)}
        ></NavLink> */}
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/my_adventures"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">TRIPS</div>
        </NavLink>
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/library"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">LIBRARY</div>
        </NavLink>
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/friends"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text" style={{ display: 'flex' }}>
            <div>FRIENDS</div>
            {userNotifications?.length > 0 && (
              <div
                className="bottom-menu-notification-count-wrapper"
                style={{ marginLeft: '0px' }}
              >
                {userNotifications.length}
              </div>
            )}
          </div>
        </NavLink>
        {/* <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          // to="/hub/account/messages"
          onClick={() => sendDataToParent(false)}
        > */}
        {/* <div
          className="menu-navigation-button"
          onClick={(e) => {
            setTab('Notification');
          }}
        >
          <div className="menu-navigation-text menu-navigation-text--small messages-wrapper">
            MESSAGES
            {props.userNotifications &&
              props.userNotifications.filter((e) => !e.read_flag).length >
                0 && (
                <div className="messages-notification-count-wrapper">
                  <div className="messages-body">
                    <span>
                      {
                        props.userNotifications.filter((e) => !e.read_flag)
                          .length
                      }
                    </span>
                  </div>
                  <div className="messages-tail" />
                </div>
              )}
          </div>
        </div> */}
        {/* </NavLink> */}
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/my-impact-summary"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">IMPACT</div>
        </NavLink>
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/download"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">TAG & SAVE</div>
        </NavLink>

        {/* <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/linkedapps"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text menu-navigation-text--small">
            LINKED APPS
          </div>
        </NavLink>
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/community"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">COMMUNITY</div>
        </NavLink> */}
      </div>
      {/* <div className="custom-line-element-wrapper">
        <hr />
        <div className="center-text">LODESTAR</div>
        <hr />
      </div> */}
      {/* <div className="navigation-section-wrapper"> */}

      {/* <div
          className="menu-navigation-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location = 'https://lodestarhub.com/install-app';
          }}
        >
          <div className="menu-navigation-text menu-navigation-text--small messages-wrapper">
            INSTALL APP
          </div>
        </div> */}
      {/* <div
          className="menu-navigation-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location = "https://lodestarhub.com/app-install";
          }}
        >
          <div className="menu-navigation-text menu-navigation-text--small messages-wrapper">
            TAG & SAVE BROWSER BUTTON
          </div>
        </div> */}
      {/* <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to="/hub/download"
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text">BROWSER BUTTON</div>
        </NavLink> */}
      {/* </div> */}
      <div className="custom-line-element-wrapper">
        <hr />
        <div className="center-text">SETTINGS</div>
        <hr />
      </div>
      <div className="account-section-wrapper">
        <NavLink
          className="menu-navigation-button"
          activeClassName="main-nav-active"
          to={isMobile ? '/hub/account' : '/hub/account/profile'}
          onClick={() => sendDataToParent(false)}
        >
          <div className="menu-navigation-text menu-navigation-text--small">
            ACCOUNT
          </div>
        </NavLink>
        {userInfo && userInfo.ambassador_flag && (
          <NavLink
            className="menu-navigation-button"
            activeClassName="main-nav-active"
            to="/hub/shareqr"
            onClick={() => sendDataToParent(false)}
          >
            <div className="menu-navigation-text menu-navigation-text--small">
              MY PROMO CODE
            </div>
          </NavLink>
        )}
        {/* {userInfo && userInfo.ambassador_flag && (
          <NavLink
            className="menu-navigation-button"
            activeClassName="main-nav-active"
            to="/hub/shareqr-free"
            onClick={() => sendDataToParent(false)}
          >
            <div className="menu-navigation-text menu-navigation-text--small">
              SHARE VIA QR
            </div>
          </NavLink>
        )} */}
        {userInfo && userInfo.account_rid === 150 && (
          <NavLink
            className="menu-navigation-button"
            activeClassName="main-nav-active"
            to="/hub/ambassador_codes"
            onClick={() => sendDataToParent(false)}
          >
            <div className="menu-navigation-text menu-navigation-text--small">
              AMB CODES
            </div>
          </NavLink>
        )}
        {userInfo &&
          (userInfo.account_rid === 151 || userInfo.account_rid === 150) && (
            <NavLink
              className="menu-navigation-button"
              activeClassName="main-nav-active"
              to="/hub/partner_codes"
              onClick={() => sendDataToParent(false)}
            >
              <div className="menu-navigation-text menu-navigation-text--small">
                PARTNER CODES
              </div>
            </NavLink>
          )}

        <div
          className="menu-navigation-text menu-navigation-text--small"
          onClick={() => {
            props.logout();
            props.history.push('/');
          }}
        >
          LOG OUT
        </div>
        <div className="version">
          <span>v{process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </>
  );
};

export default withRouter(DrawerMenu);
