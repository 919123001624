export const svgCopyNew = (
  color,
  className,
  outerLayer,
  width = 15,
  height = 15,
) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={outerLayer}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
    >
      <g id="style=fill">
        <g id="copy">
          <path
            className={className}
            fill={color}
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1667 0.25C8.43733 0.25 6.25 2.50265 6.25 5.25H12.8333C15.5627 5.25 17.75 7.50265 17.75 10.25V18.75H17.8333C20.5627 18.75 22.75 16.4974 22.75 13.75V5.25C22.75 2.50265 20.5627 0.25 17.8333 0.25H11.1667Z"
          />
          <path
            className={className}
            fill={color}
            id="rec"
            d="M2 10.25C2 7.90279 3.86548 6 6.16667 6H12.8333C15.1345 6 17 7.90279 17 10.25V18.75C17 21.0972 15.1345 23 12.8333 23H6.16667C3.86548 23 2 21.0972 2 18.75V10.25Z"
          />
        </g>
      </g>
    </svg>
  );
};
