
export const svgAccountMenuMembership = (color, className, outerLayer) => {
  return (
    <svg
      version="1.1"
      x="0"
      y="0"
      width="95"
      height="60.7"
      viewBox="0, 0, 95, 60.7"
      className={outerLayer}
    >
      <g id="Layer_1">
        <path
          className={className}
          d="M88.1,-0 L6.9,-0 C3.1,-0 0,3.1 0,6.9 L0,53.8 C0,57.6 3.1,60.7 6.9,60.7 L88.1,60.7 C91.9,60.7 95,57.6 95,53.8 L95,6.9 C95,3 91.9,-0 88.1,-0 z M45.8,28 L39.2,33.7 C38.6,34.2 38.3,35 38.5,35.8 L40.5,44.3 C40.9,46.1 38.9,47.6 37.3,46.6 L29.9,42.1 C29.2,41.7 28.4,41.7 27.7,42.1 L20.3,46.6 C18.7,47.6 16.7,46.1 17.1,44.3 L19.1,35.8 C19.3,35 19,34.2 18.4,33.7 L11.8,28 C10.4,26.8 11.1,24.4 13,24.3 L21.7,23.6 C22.5,23.5 23.5,22.3 23.5,22.3 L26.9,14.3 C27.6,12.6 30.1,12.6 30.8,14.3 L34.2,22.3 C34.5,23 35.2,23.5 36,23.6 L44.7,24.3 C46.5,24.4 47.2,26.7 45.8,28 z M80,48.2 L55.9,48.2 C54.4,48.2 53.2,47 53.2,45.5 C53.2,44 54.4,42.8 55.9,42.8 L80,42.8 C81.5,42.8 82.7,44 82.7,45.5 C82.7,47 81.5,48.2 80,48.2 z M80,33 L55.9,33 C54.4,33 53.2,31.8 53.2,30.3 C53.2,28.8 54.4,27.6 55.9,27.6 L80,27.6 C81.5,27.6 82.7,28.8 82.7,30.3 C82.8,31.8 81.5,33 80,33 z M80,17.9 L55.9,17.9 C54.4,17.9 53.2,16.7 53.2,15.2 C53.2,13.7 54.4,12.5 55.9,12.5 L80,12.5 C81.5,12.5 82.7,13.7 82.7,15.2 C82.7,16.7 81.5,17.9 80,17.9 z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};