import {
  RECEIVE_OPEN_LIST_EDIT_POPUP,
  RECEIVE_CLOSE_POP_UP,
} from '../constants';

const receiveClosePopup = () => {
  return {
    type: RECEIVE_CLOSE_POP_UP,
  };
};

const receiveOpenEditListEntryPopup = (data) => {
  return {
    type: RECEIVE_OPEN_LIST_EDIT_POPUP,
    data,
  };
};

export const openEditListEntryPopup = (data) => (dispatch) => {
  dispatch(receiveOpenEditListEntryPopup(data));
};

export const closePopup = () => (dispatch) => {
  dispatch(receiveClosePopup());
};
